import { faArrowRightToLine, faMemo, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Form, Modal, Row, Table, Tooltip, notification } from 'antd';
import React, { useContext, useState } from 'react';
import { ContainerWrapper } from '../../../../../common/components/containerWrapper';
import { PageTitle } from '../../../../../common/components/pageTitle';
import { useParams } from 'react-router-dom';
import { BillingContext } from '../../../../../contexts/billingContext';
import { IMSNameParams } from '../../../../dashboard';
import { CardForm, FormHeading, ModalFormWrapper, PrimaryButton } from '../../../../../common/components/form';
import { useSmsTemplateAdd, useSmsTemplateDelete, useSmsTemplateList, useSmsTemplatePut } from '../../../../../services/areas/billing/smsTemplate';
import { SmsTemplate } from '../../../../../interfaces/forms/areas/billing/smsTemplate';
import SMSTemplateForm from './shared/form';
import { useTranslation } from 'react-i18next';

const List = () => {
  const { clientName } = useParams<IMSNameParams>();
  const { deployment, service } = useContext(BillingContext);
  const [pagination, setPagination] = useState<{ limit: number; page: number }>({ limit: 50, page: 1 });
  const {t} = useTranslation();
  const { data, refetch: refetchData } = useSmsTemplateList({
    params: { instance: clientName, cms: deployment?.name || '', crm: service?.name || '', ...pagination },
  });

  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-md-6 mb-2">
          <PageTitle title={t('sms-templates')} subtitle={t('manage-the-content-of-your-sms-messages-to-customers')} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-5 offset-md-7">
          <ActionBar onChangesMade={refetchData} />
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-4">
          <Table
            dataSource={data?.data.docs}
            columns={[
              { title: t('name'), dataIndex: 'name', width: '100%' },
              {
                title: t('actions'),

                render: (row: SmsTemplate) => <TableActions template={row} onChangesMade={refetchData} />,
              },
            ]}
            pagination={{
              onChange: (page, pageSize) => setPagination({ page, limit: pageSize }),
            }}
          />
        </div>
      </div>
    </ContainerWrapper>
  );
};

export default List;

type TableActionsProps = {
  template: SmsTemplate;
  onChangesMade: () => void;
};

const TableActions = ({ template, onChangesMade }: TableActionsProps) => {
  const { clientName } = useParams<{ clientName: string }>();
  const { deployment, service } = useContext(BillingContext);
  const [modalOpen, setModalOpen] = React.useState(false);
  const {t} = useTranslation();
  const { isLoading, mutate } = useSmsTemplateDelete(
    { params: { instance: clientName, cms: deployment?.name || '', crm: service?.name || '' } },
    {
      onSuccess: () => {
        notification.success({ message: t('template-deleted') });
        onChangesMade();
      },
      onError: () => {
        notification.success({ message: t('template-could-not-be-deleted-please-try-again')});
      },
    }
  );

  return (
    <>
      <Col style={{ display: 'flex', flexDirection: 'row', height: 60 }}>
        {template._id !== undefined && (
          <Button
            type="text"
            className="mx-2"
            disabled={isLoading}
            onClick={() => {
              mutate(template._id as string);
            }}
          >
            <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
            <div>{t('delete')}</div>
          </Button>
        )}

        <Button
          type="text"
          className="mx-2"
          disabled={isLoading}
          onClick={() => {
            setModalOpen(true);
          }}
        >
          <FontAwesomeIcon className="action-icon" icon={faMemo} />
          <div>{t('open-details')}</div>
        </Button>
      </Col>
      <EditFormModal
        template={template}
        open={modalOpen}
        onCancel={(refetch) => {
          setModalOpen(false);
          if (refetch) {
            onChangesMade();
          }
        }}
      />
    </>
  );
};

type ActionBarProps = {
  onChangesMade: () => void;
};

const ActionBar = ({ onChangesMade }: ActionBarProps) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const {t} = useTranslation();
  return (
    <>
      <div className="d-flex justify-content-center justify-content-md-end align-items-start">
        <PrimaryButton className="btn btn-secondary rounded-pill btn-default float-right width-240 height-48" onClick={() => setModalOpen(true)}>
          {t('add-template')}
        </PrimaryButton>
      </div>
      <AddFormModal
        open={modalOpen}
        onCancel={(refetch) => {
          setModalOpen(false);
          if (refetch) {
            onChangesMade();
          }
        }}
      />
    </>
  );
};

type AddFormModalProps = {
  open: boolean;
  onCancel: (refetch: boolean) => void;
};

const AddFormModal = ({ open, onCancel }: AddFormModalProps) => {
  const { clientName } = useParams<IMSNameParams>();
  const { deployment, service } = useContext(BillingContext);
  const [form] = Form.useForm();
  const {t} = useTranslation();
  const { isLoading, mutate } = useSmsTemplateAdd(
    { params: { instance: clientName, cms: deployment?.name || '', crm: service?.name || '' } },
    {
      onSuccess: () => {
        notification.success({ message: t('sms-template-added') });
        form.resetFields();
        onCancel(true);
      },
      onError: () => {
        notification.error({ message: t('something-went-wrong-please-try-again') });
      },
    }
  );

  return (
    <Modal width={720} {...{ open }} onCancel={() => onCancel(false)} okButtonProps={{ hidden: true }} cancelButtonProps={{ hidden: true }}>
      <ModalFormWrapper>
        <CardForm
          buttonBgWhite
          layout="vertical"
          {...{ form }}
          disabled={isLoading}
          onFinish={(values) => {
            mutate(values);
          }}
        >
          <FormHeading>{t('new-sms-template')}</FormHeading>
          <SMSTemplateForm />
        </CardForm>
      </ModalFormWrapper>
    </Modal>
  );
};

type EditFormModalProps = {
  open: boolean;
  onCancel: (refetch: boolean) => void;
  template: SmsTemplate;
};

const EditFormModal = ({ open, onCancel, template }: EditFormModalProps) => {
  const { clientName } = useParams<{ clientName: string }>();
  const { deployment, service } = useContext(BillingContext);
  const [form] = Form.useForm();
  const {t} = useTranslation();
  const { isLoading, mutate } = useSmsTemplatePut(
    { params: { instance: clientName, cms: deployment?.name || '', crm: service?.name || '' }, id: template._id as string },
    {
      onSuccess: () => {
        notification.success({ message: t('template-updated') });
        onCancel(true);
      },
      onError: () => {
        notification.error({ message: t('something-went-wrong-please-try-again-0') });
      },
    }
  );

  

  return (
    <Modal width={720} {...{ open }} onCancel={() => onCancel(false)} okButtonProps={{ hidden: true }} cancelButtonProps={{ hidden: true }}>
      <div className="mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <CardForm
              buttonBgWhite
              layout="vertical"
              form={form}
              disabled={isLoading}
              initialValues={template}
              onFinish={(values) => {
                mutate(values);
              }}
            >
              <FormHeading>{t('edit-template')}</FormHeading>
              <SMSTemplateForm />
            </CardForm>
          </div>
        </div>
      </div>
    </Modal>
  );
};
