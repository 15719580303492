import { Button, Form, Input, Modal, Switch } from 'antd';
import { Spin } from '../../spinner';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';
type IUpdateLanguageModalProps = {
  visible: boolean;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  initialData: {
    name: string;
    locked: boolean;
  };
};

const RatingModal = (props: IUpdateLanguageModalProps) => {
  const { visible, onSubmit, onCancel, initialData } = props;
  let [form] = useForm();
  const {t} = useTranslation();

  const [isFetchingData] = useState<boolean>(false);
  useEffectOnce(() => {
    form.setFieldsValue(initialData);
  });
  return (
    <Modal visible={visible} style={{  borderRadius:5}} width={640} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4>{t('add-update-rating')}</h4>
            {!isFetchingData && (
              <Form form={form} onFinish={(values) => onSubmit(values)} onFinishFailed={(errorInfo) => {}}>
                <label className="col-form-label">{t('rating-identifier')}</label>
                <Form.Item
                  name="name"
                  extra={t('this-identifier-needs-to-match-the-original-identifiers-for-you-region-to-match-up-with-automated-metadata-such-as-epg-movies-and-series-imports')}
                  className="form-group mb-0"
                  rules={[
                    {
                      required: true,
                      message: t('description-is-required-1'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('your-rating-identifier-here')} />
                </Form.Item>
                <label className="col-form-label">{t('show-parental')}</label>
                <Form.Item name="locked" valuePropName={'checked'} extra={t('turn-on-the-switch-if-the-app-should-block-the-content-with-a-parental-code-the-user-profile-age-rating-will-detirmine-if-the-parental-code-is-requested')} className="form-group mb-0">
                  <Switch className="ch-switch" checked={true} defaultChecked={true} />
                </Form.Item>

                <div className="col-md-12 p-0">
                  <Button htmlType="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                    {t('add-update-rating-0')}
                  </Button>
                </div>
              </Form>
            )}
            {isFetchingData && (
              <div className="row text-center h-100 w-100">
                <div className="col-12 align-self-center">
                  <Spin size="large" spinning={true} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RatingModal;
