import { Button, Form, Input, notification, Select, Switch } from 'antd';

import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { StatusCodes } from 'http-status-codes';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CustomNotification } from '../../../../../../common/components/Notification';
import { IMSNameParams } from '../../../../../dashboard';
import clientService from '../../../../../../services/client';

const AddNewClient = (data: any) => {
  const params = useParams<IMSNameParams>();
  const [form] = useForm();
const { t } = useTranslation();
  const [formDetails, setFormDetails] = useState([] as any);
  const [isAddingClient, setIsAddingClient] = useState(false);
  const [switch1, setSwitch1] = useState(false);
  const [switch2, setSwitch2] = useState(false);

  const [device1, setDevice1] = useState(false);
  const [device2, setDevice2] = useState(false);
  const [device3, setDevice3] = useState(false);
  const [device4, setDevice4] = useState(false);
  const [device5, setDevice5] = useState(false);
  const [device6, setDevice6] = useState(false);
  const [device7, setDevice7] = useState(false);
  const [device8, setDevice8] = useState(false);
  const [device9, setDevice9] = useState(false);
  const [device10, setDevice10] = useState(false);
  const [device11, setDevice11] = useState(false);

  const [switch5, setSwitch5] = useState(false);
  const [switch6, setSwitch6] = useState(false);
  const [status, setStatus] = useState('');
  const [onboarding, setOnboarding] = useState('');

  const onFinish = async (values: any) => {
    setIsAddingClient(true);
    var values_ = {
      ...values,
      _id: params._id,
    };
    let response = await clientService.updateClient(values_);
    if (response.status_code === StatusCodes.OK)
      notification.success({
        message: 'Success',
        description: 'Client updated successfully',
      });
    else
      CustomNotification({
        error: response.errors,
      });
    setIsAddingClient(false);
  };

  useEffect(() => {
    setFormDetails(data.data);
    form.setFieldsValue(data.data);
  }, [data]);

  return (
    <>
      <section className="content">
        <div className=" m-auto p-0">
          <div style={{ background: 'white', margin: '30px', padding: '30px' }}>
            <Form
              form={form}
              initialValues={{
                ...formDetails,
              }}
              onFinish={onFinish}
              onError={(errors) => {}}
            >
              <div className="card-bg-light p-4 mb-5">
                <div className="p-4">
                  <h4 className="text-primary mb-3">Details</h4>
                  <div className="row">
                    <label className="col-md-3 col-form-label">Status</label>
                    <Form.Item initialValue={status} name={'status'} className="col-md-5">
                      <Select className="default-select" onSelect={setStatus} defaultActiveFirstOption={true} defaultValue={status}>
                        <Select.Option value={'Trial'} key={'Trial'}>
                          Trial
                        </Select.Option>
                        <Select.Option value={'Onboarding'} key={'Onboarding'}>
                          Onboarding
                        </Select.Option>
                        <Select.Option value={'Live'} key={'Live'}>
                          Live
                        </Select.Option>
                        <Select.Option value={'Suspended'} key={'Suspended'}>
                          Suspended
                        </Select.Option>
                        <Select.Option value={'Stopped'} key={'Stopped'}>
                          Stopped
                        </Select.Option>
                        <Select.Option value={'Archived'} key={'Archived'}>
                          Archived
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="row">
                    <label className="col-md-3 col-form-label">Onboarding</label>
                    <Form.Item initialValue={onboarding} name={'onboarding'} className="col-md-5">
                      <Select className="default-select" onSelect={setOnboarding} defaultActiveFirstOption={true} defaultValue={onboarding}>
                        <Select.Option value={'Content & Streaming'} key={'Content & Streaming'}>
                          Content & Streaming
                        </Select.Option>
                        <Select.Option value={'Billing & Apps'} key={'Billing & Apps'}>
                          Billing & Apps
                        </Select.Option>
                        <Select.Option value={'Testing'} key={'Testing'}>
                          Testing
                        </Select.Option>
                        <Select.Option value={'Handover'} key={'Handover'}>
                          Handover
                        </Select.Option>
                        <Select.Option value={'Finished'} key={'Finished'}>
                          Finished
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="row">
                    <label className="col-md-3 col-form-label">Name</label>
                    <Form.Item
                      name="name"
                      className="col-md-5"
                      rules={[
                        {
                          required: true,
                          message: 'Name is required!',
                        },
                      ]}
                    >
                      <Input disabled={true} className="form-control bg-white font-size-14 text-default" placeholder="Name" />
                    </Form.Item>
                  </div>
                  <div className="row">
                    <label className="col-md-3 col-form-label">Alias Name</label>
                    <Form.Item
                      name="alias_name"
                      className="col-md-5"
                      rules={[
                        {
                          required: true,
                          message: 'Alias name is required!',
                        },
                      ]}
                    >
                      <Input disabled={true} className="form-control bg-white font-size-14 text-default" placeholder="Alias Name" />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="card-bg-light p-4 mb-5">
              <h4 className="text-primary">Client Scope</h4>
                <div className="p-4 mt-5">
                  <div className="row">
                    <label className="col-md-3 col-form-label">Target Amount</label>
                    <Form.Item
                      extra={'Put the amount of customers the client expects to get within a set period'}
                      name={['scope', 'target']}
                      className="col-md-5"
                   
                    >
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="Name" />
                    </Form.Item>
                  </div>
                  <div className="row">
                    <label className="col-md-3 col-form-label">Target Deadline</label>
                    <Form.Item
                      extra={'Set the deadline date for when the target amount should be reached'}
                      name={['scope', 'duration']}
                      className="col-md-5"
                    
                    >
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="Name" />
                    </Form.Item>
                  </div>
                  <div className="row">
                    <label className="col-md-3 col-form-label">How?</label>
                    <Form.Item
                      extra={'Explain how this deadline and target can be reached'}
                      name={['scope', 'how']}
                      className="col-md-5"
                    
                    >
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="Name" />
                    </Form.Item>
                  </div>
                  <div className="row">
                    <label className="col-md-3 col-form-label">Softlaunch Date</label>
                    <Form.Item
                      extra={'Set the expected soft launch date the service goes live'}
                      name={['scope', 'softlaunch']}
                      className="col-md-5"
                   
                    >
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="Name" />
                    </Form.Item>
                  </div>
                  <div className="row">
                    <label className="col-md-3 col-form-label">Live Date</label>
                    <Form.Item
                      extra={'Set the expected date the service goes live'}
                      name={['scope', 'live']}
                      className="col-md-5"
                 
                    >
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="Name" />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="card-bg-light p-4 mb-5">
                <h4 className="text-primary">Akamai</h4>
                <div className="row">
                  <label className="col-md-3 col-form-label">Group ID LiveTV Streaming</label>
                  <Form.Item name="akamai_cp_code_cdn" className="col-md-5">
                    <Input className="form-control bg-white font-size-14 text-default" placeholder="Group ID" />
                  </Form.Item>
                </div>
                <div className="row">
                  <label className="col-md-3 col-form-label">Group ID Storage</label>
                  <Form.Item name="akamai_cp_code_storage" className="col-md-5">
                    <Input className="form-control bg-white font-size-14 text-default" placeholder="Group ID" />
                  </Form.Item>
                </div>
                <div className="row">
                  <label className="col-md-3 col-form-label">Group ID VoD Streaming</label>
                  <Form.Item name="akamai_cp_code" className="col-md-5">
                    <Input className="form-control bg-white font-size-14 text-default" placeholder="Group ID" />
                  </Form.Item>
                </div>
              </div>

     

              <div className="card-bg-light p-4 mb-5">
                <h4 className="text-primary">Products</h4>
                <div className="row">
                  <label className="col-md-3 col-form-label">CDN</label>
                  <Form.Item className="col-md-5" valuePropName="checked" name={['products', 'cdn']}>
                    <Switch className="ch-switch" defaultChecked={false} onChange={() => setSwitch1(!switch1)} />
                  </Form.Item>
                </div>
                <div className="row">
                  <label className="col-md-3 col-form-label">Storage</label>
                  <Form.Item className="col-md-5" valuePropName="checked" name={['products', 'storage']}>
                    <Switch className="ch-switch" defaultChecked={false} onChange={() => setSwitch2(!switch2)} />
                  </Form.Item>
                </div>
                {/* <div className="row">
                  <label className="col-md-3 col-form-label">Billing</label>
                  <Form.Item
                    className="col-md-5"
                    valuePropName="checked"
                    name={["products", "billing"]}>
                    <Switch className="ch-switch" defaultChecked={false} onChange={() => setSwitch3(!switch3)} />
                  </Form.Item>
                </div> */}
                {/* <div className="row">
                  <label className="col-md-3 col-form-label">Flussonic</label>
                  <Form.Item
                    className="col-md-5"
                    valuePropName="checked"
                    name={["products", "flussonic"]}>
                    <Switch className="ch-switch" defaultChecked={false} onChange={() => setSwitch4(!switch4)} />
                  </Form.Item>
                </div> */}
                <div className="row">
                  <label className="col-md-3 col-form-label">Streaming Support</label>
                  <Form.Item className="col-md-5" valuePropName="checked" name={['products', 'streaming_support']}>
                    <Switch className="ch-switch" defaultChecked={false} onChange={() => setSwitch5(!switch5)} />
                  </Form.Item>
                </div>
                <div className="row">
                  <label className="col-md-3 col-form-label">Managed Service</label>
                  <Form.Item className="col-md-5" valuePropName="checked" name={['products', 'managed_service']}>
                    <Switch className="ch-switch" defaultChecked={false} onChange={() => setSwitch6(!switch6)} />
                  </Form.Item>
                </div>
              </div>
              <div className="card-bg-light p-4 mb-5">
                <h4 className="text-primary">Devices</h4>
                <div className="row">
                  <div className="col">
                    <div className="row">
                      <label className="col-md-5 col-form-label">iPhone & iPad</label>
                      <Form.Item className="col-md-1" valuePropName="checked" name={['devices', 'ios']}>
                        <Switch className="ch-switch" defaultChecked={false} onChange={() => setDevice1(!device1)} />
                      </Form.Item>
                    </div>
                    <div className="row">
                      <label className="col-md-5 col-form-label">tvOS</label>
                      <Form.Item className="col-md-1" valuePropName="checked" name={['devices', 'tvos']}>
                        <Switch className="ch-switch" defaultChecked={false} onChange={() => setDevice11(!device11)} />
                      </Form.Item>
                    </div>
                    <div className="row">
                      <label className="col-md-5 col-form-label">Android Phone & Tablet</label>
                      <Form.Item className="col-md-1" valuePropName="checked" name={['devices', 'android']}>
                        <Switch className="ch-switch" defaultChecked={false} onChange={() => setDevice2(!device2)} />
                      </Form.Item>
                    </div>
                    <div className="row">
                      <label className="col-md-5 col-form-label">AndroidTV</label>
                      <Form.Item className="col-md-1" valuePropName="checked" name={['devices', 'androidtv']}>
                        <Switch className="ch-switch" defaultChecked={false} onChange={() => setDevice3(!device3)} />
                      </Form.Item>
                    </div>
                    <div className="row">
                      <label className="col-md-5 col-form-label">FireTV</label>
                      <Form.Item className="col-md-1" valuePropName="checked" name={['devices', 'firetv']}>
                        <Switch className="ch-switch" defaultChecked={false} onChange={() => setDevice4(!device4)} />
                      </Form.Item>
                    </div>
                    <div className="row">
                      <label className="col-md-5 col-form-label">LG WebOS</label>
                      <Form.Item className="col-md-1" valuePropName="checked" name={['devices', 'lg']}>
                        <Switch className="ch-switch" defaultChecked={false} onChange={() => setDevice5(!device5)} />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col">
                    <div className="row">
                      <label className="col-md-5 col-form-label">Samsung Tizen</label>
                      <Form.Item className="col-md-1" valuePropName="checked" name={['devices', 'tizen']}>
                        <Switch className="ch-switch" defaultChecked={false} onChange={() => setDevice6(!device6)} />
                      </Form.Item>
                    </div>
                    <div className="row">
                      <label className="col-md-5 col-form-label">Vidaa</label>
                      <Form.Item className="col-md-1" valuePropName="checked" name={['devices', 'vidaa']}>
                        <Switch className="ch-switch" defaultChecked={false} onChange={() => setDevice7(!device7)} />
                      </Form.Item>
                    </div>
                    <div className="row">
                      <label className="col-md-5 col-form-label">WebTV</label>
                      <Form.Item className="col-md-1" valuePropName="checked" name={['devices', 'webtv']}>
                        <Switch className="ch-switch" defaultChecked={false} onChange={() => setDevice8(!device8)} />
                      </Form.Item>
                    </div>
                    <div className="row">
                      <label className="col-md-5 col-form-label">KaiOS</label>
                      <Form.Item className="col-md-1" valuePropName="checked" name={['devices', 'kaios']}>
                        <Switch className="ch-switch" defaultChecked={false} onChange={() => setDevice9(!device9)} />
                      </Form.Item>
                    </div>
                    <div className="row">
                      <label className="col-md-5 col-form-label">Roku</label>
                      <Form.Item className="col-md-1" valuePropName="checked" name={['devices', 'roku']}>
                        <Switch className="ch-switch" defaultChecked={false} onChange={() => setDevice10(!device10)} />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Form.Item style={{ textAlign: 'right' }}>
                    <Button loading={isAddingClient} disabled={isAddingClient} type="primary" htmlType="submit" className="rounded-pill width-240 height-54">
                      <span className="px-5 font-weight-bold">Update Client</span>
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddNewClient;
