import { Form, Modal, notification } from 'antd';
import React, { useState } from 'react';
import { useParams, useRouteMatch } from 'react-router';
import { BackButton } from '../../../../common/components/BackButton';
import { ContainerWrapper } from '../../../../common/components/containerWrapper';
import { Button } from '../../../../common/components/form';
import { PageTitle } from '../../../../common/components/pageTitle';
import { CustomersContext } from '../../../../contexts/customersContext';
import { usePatchMutation } from '../../../../services/areas/customers/customerService';
import { STORAGE_ENDPOINTS } from '../../../../services/storage_paths';
import { RenewChangeFormFields } from './shared/form';
import { ErrorModal } from '../../../../common/components/ErrorModal/ErrorModal';
import { useTranslation } from 'react-i18next';

const Change = (params:{customer: any, onClose}) => {
  const { url } = useRouteMatch();
  const { clientName, service: serviceName } = useParams<{ clientName: string; service: string }>();
  const { deployment } = React.useContext(CustomersContext);
  const path = STORAGE_ENDPOINTS(clientName, deployment?.name, '').CUSTOMERS.CUSTOMERS;
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [modalData, setModalData] = useState([] as any);
  const id = params.customer._id ?? '';
  const {t} = useTranslation();
  
  const {isLoading, mutate } = usePatchMutation(
    { params: { instance: clientName, cms: deployment?.name || '', crm: serviceName, path: path }, id },
    {
      onSuccess: () => {
         notification.success({ message: t('customer-updated') })
         params.onClose();
      },
      onError: () => notification.error({ message: t('something-went-wrong-updating-the-customer-please-try-again-later') }),
    }
  );
  const [form] = Form.useForm();

  return (
    <Modal visible={true} style={{ border: '1px solid #ebebeb' }} width={'90%'} footer={false} closable={true} onCancel={()=> params.onClose()}>
    <ContainerWrapper>
      <div className="row">
        <div className="col-12">
          <BackButton text={t('back-to-customer-list')} url={url.split('/').slice(0, -1).join('/')} />
        </div>
        <div className="col-md-6 mb-2">
          <PageTitle title={t('change-subscription')} subtitle={t('manually-change-the-subscription-for-this-customer')} />
        </div>
      </div>
      <ErrorModal
        isOpenModal={openErrorModal}
        data={modalData}
        setIsOpen={(isOpen) => {
          setOpenErrorModal(isOpen);
        }}
      />
      <div className="row">
        <div className="col-12">
          <Form
            {...{ form }}
            onFinish={(values) => {
              

         
              
             



              mutate({
                ...params.customer,
                ...values,
        
              });
            }}
            onFinishFailed={(errorInfo) => {
              setModalData(errorInfo?.errorFields);
              setOpenErrorModal(true);
            }}
          >
              <RenewChangeFormFields customer={params.customer} hideDates={true} />

            <div className="text-right">
              <Button htmlType="submit" loading={isLoading} disabled={isLoading} className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
                {t('change-subscription')}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </ContainerWrapper>
    </Modal>
  );
};

export default Change;
