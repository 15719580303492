import { Button, Form, notification, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { StatusCodes } from 'http-status-codes';
import { useContext, useState } from 'react';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { CustomNotification } from '../../../../../common/components/Notification';
import { AppBuilderContext } from '../../../../../contexts/appbuilderContext';
import { IMSNameParams } from '../../../../../pages/dashboard';
import appService from '../../../../../services/areas/appbuilder/applications/application';

import standard from '../../../../../assets/images/appbuilder/Standard_TVGuide.png';
import columns from '../../../../../assets/images/appbuilder/Column_TVGuide.png';

import EditInfobar from '../../../../../common/components/AppBuilder/Edit/Edit_Infobar';
import EditTVGuide from '../../../../../common/components/AppBuilder/Edit/Edit_Tvguide';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRectangleWide, faThList } from '@fortawesome/pro-regular-svg-icons';
import { STORAGE_ENDPOINTS } from '../../../../../services/storage_paths';

const Authentication = () => {
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(AppBuilderContext);
  const [theme, setTheme] = useState([] as any);
  const [form] = useForm();
  const { id } = useParams<any>();
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -2).join('/');
  const [formDetails, setFormDetails] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);
  const [template, setTemplate] = useState('Standard');
  const [data, setData] = useState([] as any);
const {t} = useTranslation();
  const [application, setApplication] = useState([] as any);
  const [settings, setSettings] = useState({
    type: 'Standard', //internal use
    tvguide: {
      general: {
        background_color: '#000000', // '#222222',
        loader_color: '#ffffff', // '#ffffff'
      },
      times: {
        text_size: 'Normal', //  Ratio(14),
        background_color: '#000000', // '#222222',
        text_color: '#ffffff', //'#999999',
        border_color: '#ffffff', // '#ffffff',
        border_width: 0, //  2,
        height: 50, // Ratio(50)
      },
      programs: {
        background_color: '#000000', //'#333333',
        active_color: '#ffffff', //'green',
        border_color: '#ffffff', //'#111111',
        border_width: 0, // 1,
        text_color: '#ffffff', //'#ffffff',
        font_size: 'Normal', //Ratio(14),
        program_height: 50, // Ratio(50),
      },
      days: {
        days_height: 50, //  Ratio(50),
        days_width: 150, // Ratio(150),
        background_color: '#000000', //'#444444',
        text_color: '#ffffff', //'#999999',
        text_size: 'Normal', // Ratio(14),
      },
      channels: {
        channels_width: 250, //  Ratio(250),
        image_size: 74, //Ratio(75),
        background_color: '#000000', //
        font_size: 'Normal', // Ratio(14)
      },
      marker: {
        line_color: '#ffffff', //
        line_width: 1, //  2
      },
    },
    infobar: {
      general: {
        background_color: '#000000',
      },

      texts: {
        title_color: '#ffffff',
        title_size: 'Normal', //Small, Normal, Large, Extra Large
        title_bold: false,

        subtext_color: '#ffffff',
        subtext_size: 'Normal', //Small, Normal, Large, Extra Large
        subtext_bold: false,

        show_title: true,
        show_subtext: true,
      },
    },
  });
  const [editData, setEditData] = useState([] as any);
  const [editType, setEditType] = useState('');

  const [EditShowTvguideModal, setEditShowTvguideModal] = useState(false);
  const [EditShowInfobarModal, setEditShowInfobarModal] = useState(false);

  const editComponent = async (data: any) => {
    setIsLoading(true);
    let response = await appService.Update_Application({
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, '', application.branding.packageid).APPBUILDER.APP,
      },
      _id: id,
      client: clientName,
      deployment: deployment?.name ?? '',
      ...application,
      tvguide: {
        ...data,
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      notification.success({
        message: 'Updated Successfully',
      });
    }
    setIsLoading(false);
  };
  const handleSubmit = async (values: any) => {
    setIsLoading(true);
    let response = await appService.Update_Application({
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, '', application.branding.packageid).APPBUILDER.APP,
      },
      _id: id,
      client: clientName,
      deployment: deployment?.name ?? '',
      ...application,
      tvguide: settings,
    });
    if (response && response.status_code === StatusCodes.OK) {
      notification.success({
        message: 'Updated Successfully',
      });
    } else CustomNotification({ error: response.errors });
    setIsLoading(false);
  };

  const getAppItems = async () => {
    setIsLoading(true);
    let response = await appService.Get_Application({
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
      },
      _id: id,
    });
    if (response && response.status_code === StatusCodes.OK) {
      var resp = response.data as any;
      setApplication(resp);
      setTheme(resp.settings.theme);
    }
    setIsLoading(false);
  };
  useEffectOnce(() => {
    getAppItems();
  });

  const selectTemplate = (value: any) => {
    setTemplate(value);
  };

  const getEditButtons = (element: any) => {
    return (
      <div className="row w-100 h-100" style={{ marginLeft: 15, marginBottom: 10 }}>
        <div className="row w-100 h-100" style={{ height: '100vh', width: '100vh', backgroundColor: '#333333', borderRadius: 5 }}>
          <div className="col"></div>
          <div style={{ width: 5, height: 45, cursor: 'pointer', borderTopLeftRadius: 5, borderBottomLeftRadius: 5, backgroundColor: '#fff', opacity: '1', marginTop: 5, marginBottom: 5 }}></div>
          {template != 'Columns' && (
            <div style={{ padding: 0, width: 60, height: 45, borderRight: '1px solid #333333', cursor: 'pointer', backgroundColor: '#fff', opacity: '1', marginTop: 5, marginBottom: 5 }}>
              <div
                onClick={() => {
                  setEditData(element);
                  setEditType(element.type);
                  setEditShowInfobarModal(true);
                }}
                className="h-100 d-flex align-items-center justify-content-center text-center"
              >
                <div>
                  <FontAwesomeIcon style={{ cursor: 'pointer', color: '#333333' }} size={'1x'} icon={faRectangleWide} />
                  <div style={{ color: '#333333', fontSize: 8, fontWeight: 'bold' }}>INFOBAR</div>
                </div>
              </div>
            </div>
          )}
          <div style={{ padding: 0, width: 60, height: 45, cursor: 'pointer', backgroundColor: '#fff', opacity: '1', marginTop: 5, marginBottom: 5 }}>
            <div
              onClick={() => {
                setEditData(element);
                setEditType(element.type);
                setEditShowTvguideModal(true);
              }}
              className="h-100 d-flex align-items-center justify-content-center text-center"
            >
              <div>
                <FontAwesomeIcon style={{ cursor: 'pointer', color: '#333333' }} size={'1x'} icon={faThList} />
                <div style={{ color: '#333333', fontSize: 8, fontWeight: 'bold' }}>TVGUIDE</div>
              </div>
            </div>
          </div>
          <div style={{ width: 5, height: 45, cursor: 'pointer', borderTopRightRadius: 5, borderBottomRightRadius: 5, backgroundColor: '#fff', opacity: '1', marginTop: 5, marginBottom: 5, marginRight: 5 }}></div>
        </div>
      </div>
    );
  };

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <EditInfobar
          theme={theme}
          visible={EditShowInfobarModal}
          onSubmit={editComponent}
          onCancel={() => {
            setEditShowInfobarModal(false);
          }}
          data={editData}
          type={editType}
          index={0}
        />
        <EditTVGuide
          theme={theme}
          visible={EditShowTvguideModal}
          onSubmit={editComponent}
          onCancel={() => {
            setEditShowTvguideModal(false);
          }}
          data={editData}
          type={editType}
          index={0}
        />
        <div className="col-md-12 mb-2">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 mb-2">
                <Link to={`${redirectUrl}`}>Back to Applications</Link>
                <div className="row-md-12" style={{ borderBottom: '1px solid #cfcfcf' }}></div>
                <h2 className="page-title mt-4 ">TV Guide</h2>
                <p>Blazing fast highly configurable TV Guide that works well on all device platform</p>
                <p className="h6 font-weight-normal line-height-26 mb-4"></p>
              </div>
            </div>
            <Form
              form={form}
              initialValues={{
                ...formDetails,
              }}
              onFinish={async (values) => {
                await handleSubmit(values);
              }}
              onFinishFailed={(errorInfo) => {
                
              }}
            >
              <div className="card p-4">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4" style={{ paddingLeft: 0 }}>
                      <div className="card-bg-light p-3">
                        <h5 className="text-primary mb-4" style={{ paddingLeft: 0 }}>
                          TV Guide Template
                        </h5>
                        <div className="col">
                          <Form.Item style={{ padding: 0 }} className="col-md-12" name={'type'}>
                            <Select defaultValue={template} onChange={selectTemplate} className="default-select" placeholder="- Select Type -">
                              <Select.Option value={'Standard'} key={'Standard'}>
                                Standard
                              </Select.Option>
                              <Select.Option value={'Columns'} key={'Columns'}>
                                Columns
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="text-right mr-3 mt-3">
                          <Button loading={isLoading} htmlType="submit" className="mb-4 ant-btn btn btn-secondary rounded-pill width-200 height-48">
                            Update
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="card-bg-light pl-5 pt-3 mb-4 p-5">
                        <div className="row">
                          {getEditButtons(settings)}
                          {template == 'Standard' && <img src={standard} alt={''} width={'100%'}></img>}
                          {template == 'Columns' && <img src={columns} alt={''} width={'100%'}></img>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Authentication;
