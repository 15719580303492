import { Tabs } from 'antd';
import { useState } from 'react';

import Tab1 from './tabs/details';
import Tab2 from './tabs/list_users';

import { useEffectOnce } from 'react-use';
import { StatusCodes } from 'http-status-codes';
import { useParams } from 'react-router-dom';
import { IMSNameParams } from '../../../../dashboard';
import clientService from '../../../../../services/client';
import { Spin } from '../../../../../common/components/spinner';

enum EnumTabKeys {
  TAB1 = 'tab1',
  TAB2 = 'tab2',
}

const UserInterfaceAppDetails = () => {
  const [activeKey, setActiveKey] = useState<string>(EnumTabKeys.TAB1);
  const { TabPane } = Tabs;

  const params = useParams<IMSNameParams>();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([] as any);

  const handleFormFill = async () => {
    setIsLoading(true);
    let response = await clientService.detailsClient({
      _id: params._id,
    });
    if (response && response.status_code === StatusCodes.OK) {
      var resp = response.data as any;
      setData(resp);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    (async () => await handleFormFill())();
  });

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-8">
          <h2 className="page-title">Client Details</h2>
          <p className="h6 font-weight-normal line-height-26"></p>
        </div>
      </div>
      <div className="row">
        {isLoading && (
          <div className="row text-center h-100 w-100">
            <div className="col-12 align-self-center">
              <Spin size="large" spinning={true} />
            </div>
          </div>
        )}
        {!isLoading && (
          <div className="col-12  mt-3">
            <Tabs activeKey={activeKey} className="default-tab" onChange={(activeKey) => setActiveKey(activeKey)} defaultActiveKey={EnumTabKeys.TAB1}>
              <TabPane tab="Details" key={EnumTabKeys.TAB1}>
                {activeKey === EnumTabKeys.TAB1 && <Tab1 data={data} />}
              </TabPane>
              <TabPane tab="Users" key={EnumTabKeys.TAB2}>
                {activeKey === EnumTabKeys.TAB2 && <Tab2 data={data} />}
              </TabPane>
            </Tabs>
          </div>
        )}
      </div>
    </div>
  );
};
export default UserInterfaceAppDetails;
