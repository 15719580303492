import { Button, Row, Table, Tooltip, notification } from 'antd';
import { StatusCodes } from 'http-status-codes';
import { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { CustomNotification } from '../../Notification';
import { AppBuilderContext } from '../../../../contexts/appbuilderContext';
import { IMSNameParams } from '../../../../pages/dashboard';
import appService from '../../../../services/areas/appbuilder/applications/application';
import EditButton from '../Edit/Edit_Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import { STORAGE_ENDPOINTS } from '../../../../services/storage_paths';
import { useTranslation } from 'react-i18next';

const Button_ = () => {
  const { clientName } = useParams<IMSNameParams>();
  const [theme, setTheme] = useState([] as any);
  const { deployment } = useContext(AppBuilderContext);
  const { id } = useParams<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [buttons, setButtons] = useState([] as any);
  const history = useHistory();
  const { t } = useTranslation();

  const newButton = {
    name: '',

    background_color: '#000000',

    border_radius: 0,
    border_width: 0,
    border_color: '#999999',

    text_color: '#ffffff',
    text_bold: false,
  };

  const [editData, setEditData] = useState([] as any);
  const [editType, setEditType] = useState('');
  const [application, setApplication] = useState([] as any);

  const [EditShowButtonModal, setEditShowButtonModal] = useState(false);

  const editComponent = async (data: any, type: any) => {
    setIsLoading(true);
    var buttonsOut = buttons;
    if (type == "Add") {
      buttonsOut.push(data);
    } else {
      var index = buttonsOut.findIndex((b) => b._id == data._id);
      if (index > -1) {
        buttonsOut[index] = data;
      } else {
        buttonsOut.push(data);
      }
    }
    setButtons(buttonsOut);
    let response = await appService.Update_Application({
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, '', application.branding.packageid).APPBUILDER.APP,
      },
      _id: id,
      client: clientName,
      deployment: deployment?.name ?? '',
      ...application,
      components: {
        ...application.components,
        buttons: buttonsOut,
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      setEditShowButtonModal(false);
      setIsLoading(false);
      history.go(0);
    } else {
      CustomNotification({ error: response.errors });
      setIsLoading(false);
    }
  };

  const getAppItems = async () => {
    setIsLoading(true);
    let response = await appService.Get_Application({
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
      },
      _id: id,
    });
    if (response && response.status_code === StatusCodes.OK) {
      var resp = response.data as any;
      setButtons(resp.components.buttons);
      setApplication(resp);
      setTheme(resp.theme?.colors);
    }
    setIsLoading(false);
  };
  useEffectOnce(() => {
    getAppItems();
  });

  const columns = [
    {
      title: t('name'),
      dataIndex: 'name',
    },
    {
      title: t('width-0'),
      dataIndex: 'border_width',
      width: 100,
      render: (row: any, record: any) => {
        return <div>{row}</div>;
      },
    },
    {
      title: t('radius-3'),
      dataIndex: 'border_radius',
      width: 100,
      render: (row: any, record: any) => {
        return <div>{row}</div>;
      },
    },
    {
      title: t('border-2'),
      dataIndex: 'border_color',
      width: 100,
      render: (row: any, record: any) => {
        return (
          <div
            className="height-48 font-size-14 text-default"
            style={{
              backgroundColor: row,
              borderRadius: '5px',
              border: '#e9edf4 1px solid',
              width: 48,
            }}
          ></div>
        );
      },
    },
    {
      title: t('background-1'),
      dataIndex: 'background_color',
      width: 100,
      render: (row: any, record: any) => {
        return (
          <div
            className="height-48 font-size-14 text-default"
            style={{
              backgroundColor: row,
              borderRadius: '5px',
              border: '#e9edf4 1px solid',
              width: 48,
            }}
          ></div>
        );
      },
    },
    {
      title: t('action-0'),
      width: 85,
      render: (row: any) => {
        return (
          <Row className="align-items-center">
            <Tooltip placement="top" title={`Edit Button`}>
              <Button
                onClick={() => {
                  setEditData(row);
                  setEditType('Update');
                  setEditShowButtonModal(true);
                }}
                className="mx-2 cursor-pointer"
                style={{ height: '100%' }}
                type="text"
              >
                <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faEdit} />
                <div>{t('edit-0')}</div>
              </Button>
            </Tooltip>
          </Row>
        );
      },
    },
  ];
  const addButton = () => {};

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <EditButton
          theme={theme}
          visible={EditShowButtonModal}
          onSubmit={editComponent}
          onCancel={() => {
            setEditShowButtonModal(false);
          }}
          data={editData}
          type={editType}
          index={0}
        />
        <div className="col-md-12 mb-2">
          <div className="container-fluid">
            <div className="row-md-12">
              <div className="row">
                <div className="col-md-12 mb-2">
                  <h2 className="page-title pt-4">{t('buttons-3')}</h2>
                  <p className="h6 font-weight-normal line-height-26">{t('buttons-are-used-throughout-the-app-you-can-think-of-authentication-hero-elements-etc')}</p>
                </div>
                <div className="col-md-6 text-right"></div>
              </div>
              <div className="row mt-3">
                <div className="col-md-7 mb-4 mb-md-2 d-flex justify-content-center justify-content-md-start align-items-start"></div>
                <div className="col-md-5 d-flex justify-content-center justify-content-md-end align-items-start mb-3">
                  <Button
                    loading={isLoading}
                    className="btn btn-secondary rounded-pill btn-default float-right width-240 height-48"
                    onClick={() => {
                      setEditData(newButton);
                      setEditType('Add');
                      setEditShowButtonModal(true);
                    }}
                  >
                    {t('add-button')}
                  </Button>
                </div>
              </div>
              <Table pagination={false} rowClassName="editable-row" loading={isLoading} columns={columns} dataSource={buttons} key={JSON.stringify(buttons)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Button_;
