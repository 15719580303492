import { Layout, Menu } from 'antd';
import React from 'react';
import { Route, Switch, useParams, useRouteMatch } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import IMSDashboardHeader from '../../../common/components/IMSDashboardHeader';
import { DrawerContext } from '../../../context_api/DrawerContext';
import MWare_Logo from '../../../assets/images/logo.png';
import Dashboard from './dashboard';
import * as DeploymentService from '../../../services/deployments/deployment';
import { useQuery } from '@tanstack/react-query';
import { ContentContextProvider } from '../../../contexts/contentContext';
import { faDashboard, faShieldCheck, faTv, faChartTreeMap, faCctv, faFilm, faVideo, faSchool, faMusic, faPodcast, faCreditCard, faCamcorder, faFileArrowUp, faArrowProgress, faWebhook, faRadio, faCogs, faGamepad, faGamepadModern } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DRM from './drm';
import Actions from './actions';
import Channels from './channels';
import Radios from './radios';
import Epg from './epg';
import Cams from './cams';
import Movies from './movies';
import Series from './series';
import Albums from './albums';
import Podcasts from './podcasts';
import Games from './games';
import Courses from './courses';
import PPV from './ppv';
import Providers from './providers';
import Tags from './tags';
import Nests from './nests';
import Player from './player';
import { getExpandedMenu, handleOnClick } from '../../../utils/menu';
import Shorts from './shorts';
import { useEffectOnce, useLocation } from 'react-use';
import { AuthContext } from '../../../context_api/AuthContext';
import { useContext } from 'react';
import { hasRole } from '../../../utils/Auth/role.auth';
import Transcoders from './transcoders';
import Params from './params';
import { useTranslation } from 'react-i18next';

const Content = () => {
  const location = useLocation();
  const { state: DrawerState } = React.useContext(DrawerContext);
  const { path, url } = useRouteMatch();
  const { clientName, deployment, service } = useParams<{ clientName: string; deployment: string; service: string }>();
  const { state: AuthState } = useContext(AuthContext);
  const isClient = hasRole(AuthState.user!, clientName, ['superadmin', 'admin']);
  const isMware = hasRole(AuthState.user!, clientName, ['superadmin']);
  const isProvider = AuthState.user?.role === 'Provider' ? true : false;
  const history = useHistory();
  var locSplit = location?.pathname?.split('/');
  var redirectPath = '';
  if (locSplit != undefined) {
    redirectPath = locSplit.slice(0, 4 - locSplit.length).join('/');
  }
  const { t } = useTranslation();

  useEffectOnce(() => {
    const str = window.location.href;
    const parts = str.split('/');
    const lastPart = parts[parts.length - 1];
    if (!isClient && lastPart == 'content') {
      history.push(url + '/channels/channels');
    }
  });

  const { data, isLoading, isError } = useQuery(['deployment', deployment], async () => {
    return DeploymentService.find(clientName, { name: deployment })
      .then((r) => r.data)
      .catch((e) => Promise.reject(e));
  });
  console.log(isProvider);
  return (
    <Layout>
      <Layout.Sider width="250" trigger={null} collapsible collapsed={DrawerState.is_collapsed}>
        <div style={{ justifyContent: 'center', alignContent: 'center', display: 'flex', padding: 40, backgroundColor: '#444', paddingTop: 70, paddingBottom: 70, marginBottom: 17 }}>
          {!isClient && (
            <Link to={'/'} onClick={() => {}} key="Mware">
              <img className="expand" src={DrawerState.white_labeling.logo || MWare_Logo} title={t('mware-2')} alt={t('mware-2')} style={DrawerState.white_labeling.logo ? { maxWidth: 150, maxHeight: 50 } : {}} />
            </Link>
          )}
          {isClient && (
            <a href={redirectPath} key="Mware">
              <img className="expand" src={DrawerState.white_labeling.logo || MWare_Logo} title={t('mware-2')} alt={t('mware-2')} style={DrawerState.white_labeling.logo ? { maxWidth: 150, maxHeight: 50 } : {}} />
            </a>
          )}
        </div>
        {isProvider && (
          <Menu
            mode="inline"
            defaultOpenKeys={getExpandedMenu()}
            onClick={handleOnClick}
            items={[
              isClient
                ? {
                    label: (
                      <div className="height-60 d-flex align-items-center">
                        <FontAwesomeIcon icon={faDashboard} size="lg" style={{ width: '20', height: '20' }} />
                        <span className="ml-3">{t('dashboard-0')}</span>
                      </div>
                    ),

                    key: 'dashboard',
                    children: [
                      {
                        label: (
                          <Link className="height-60 d-flex align-items-center ml-3" to={`${url}`}>
                            <span className="ml-3">{t('dashboard-0')}</span>
                          </Link>
                        ),
                        key: 'content-dashboard',
                      },
                    ],
                  }
                : {
                    label: <></>,
                    key: 'empty',
                  },
              {
                label: (
                  <div className="height-60 d-flex align-items-center">
                    <FontAwesomeIcon icon={faTv} style={{ width: '20', height: '20' }} />
                    <span className="ml-3">{t('channels')}</span>
                  </div>
                ),
                key: 'channels',
                children: [
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/channels/channels`}>
                        <span className="ml-3">{t('channels')}</span>
                      </Link>
                    ),
                    key: 'channels-channels-channels',
                  },
                ],
              },
              {
                label: (
                  <div className="height-60 d-flex align-items-center">
                    <FontAwesomeIcon icon={faRadio} style={{ width: '20', height: '20' }} />
                    <span className="ml-3">{t('radio')}</span>
                  </div>
                ),
                key: 'radio',
                children: [
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/radios/radios`}>
                        <span className="ml-3">{t('radio')}</span>
                      </Link>
                    ),
                    key: 'radio-radios-radios',
                  },
                ],
              },

              {
                label: (
                  <div className="height-60 d-flex align-items-center">
                    <FontAwesomeIcon icon={faCctv} style={{ width: '20', height: '20' }} />
                    <span className="ml-3">{t('cams')}</span>
                  </div>
                ),
                key: 'cams',
                children: [
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/cams/cams`}>
                        <span className="ml-3">{t('cams')}</span>
                      </Link>
                    ),
                    key: 'cams-cams',
                  },
                ],
              },

              {
                label: (
                  <div className="height-60 d-flex align-items-center">
                    <FontAwesomeIcon icon={faFilm} style={{ width: '20', height: '20' }} />
                    <span className="ml-3">{t('movies-4')}</span>
                  </div>
                ),
                key: 'movies',
                children: [
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/movies/movies`}>
                        <span className="ml-3">{t('movies-4')}</span>
                      </Link>
                    ),
                    key: 'movies-movies',
                  },
                ],
              },
              {
                label: (
                  <div className="height-60 d-flex align-items-center">
                    <FontAwesomeIcon icon={faGamepadModern} style={{ width: '20', height: '20' }} />
                    <span className="ml-3">{t('games')}</span>
                  </div>
                ),
                key: 'games',
                children: [
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/games/games`}>
                        <span className="ml-3">{t('games')}</span>
                      </Link>
                    ),
                    key: 'games-movies',
                  },
                ],
              },
              {
                label: (
                  <div className="height-60 d-flex align-items-center">
                    <FontAwesomeIcon icon={faCamcorder} style={{ width: '20', height: '20' }} />
                    <span className="ml-3">{t('shorts-4')}</span>
                  </div>
                ),
                key: 'shorts',
                children: [
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/shorts/shorts`}>
                        <span className="ml-3">{t('shorts-4')}</span>
                      </Link>
                    ),
                    key: 'shorts-movies',
                  },
                ],
              },
              {
                label: (
                  <div className="height-60 d-flex align-items-center">
                    <FontAwesomeIcon icon={faVideo} style={{ width: '20', height: '20' }} />
                    <span className="ml-3">{t('series-4')}</span>
                  </div>
                ),
                key: 'series',
                children: [
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/series`}>
                        <span className="ml-3">{t('series-4')}</span>
                      </Link>
                    ),
                    key: 'series-series',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/series/seasons`}>
                        <span className="ml-3">{t('seasons')}</span>
                      </Link>
                    ),
                    key: 'series-seasons',
                  },
                ],
              },
              {
                label: (
                  <div className="height-60 d-flex align-items-center">
                    <FontAwesomeIcon icon={faSchool} style={{ width: '20', height: '20' }} />
                    <span className="ml-3">{t('courses-4')}</span>
                  </div>
                ),
                key: 'courses',
                children: [
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/courses/courses`}>
                        <span className="ml-3">{t('courses-4')}</span>
                      </Link>
                    ),
                    key: 'course-course',
                  },
                ],
              },
              {
                label: (
                  <div className="height-60 d-flex align-items-center">
                    <FontAwesomeIcon icon={faMusic} style={{ width: '20', height: '20' }} />
                    <span className="ml-3">{t('albums-2')}</span>
                  </div>
                ),
                key: 'albums',
                children: [
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/albums/albums`}>
                        <span className="ml-3">{t('albums-2')}</span>
                      </Link>
                    ),
                    key: 'albums-albums',
                  },
                ],
              },
              {
                label: (
                  <div className="height-60 d-flex align-items-center">
                    <FontAwesomeIcon icon={faPodcast} style={{ width: '20', height: '20' }} />
                    <span className="ml-3">{t('podcasts-4')}</span>
                  </div>
                ),
                key: 'podcasts',
                children: [
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/podcasts/podcasts`}>
                        <span className="ml-3">{t('podcasts-4')}</span>
                      </Link>
                    ),
                    key: 'podcasts-podcasts',
                  },
                ],
              },
            ]}
          />
        )}
        {!isProvider && (
          <Menu
            mode="inline"
            defaultOpenKeys={getExpandedMenu()}
            onClick={handleOnClick}
            items={[
              isClient
                ? {
                    label: (
                      <div className="height-60 d-flex align-items-center">
                        <FontAwesomeIcon icon={faDashboard} size="lg" style={{ width: '20', height: '20' }} />
                        <span className="ml-3">{t('dashboard-0')}</span>
                      </div>
                    ),

                    key: 'dashboard',
                    children: [
                      {
                        label: (
                          <Link className="height-60 d-flex align-items-center ml-3" to={`${url}`}>
                            <span className="ml-3">{t('dashboard-0')}</span>
                          </Link>
                        ),
                        key: 'content-dashboard',
                      },
                    ],
                  }
                : {
                    label: <></>,
                    key: 'empty',
                  },
              {
                label: (
                  <div className="height-60 d-flex align-items-center">
                    <FontAwesomeIcon icon={faTv} style={{ width: '20', height: '20' }} />
                    <span className="ml-3">{t('channels')}</span>
                  </div>
                ),
                key: 'channels',
                children: [
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/channels/channels`}>
                        <span className="ml-3">{t('channels')}</span>
                      </Link>
                    ),
                    key: 'channels-channels-channels',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/channels/bundles`}>
                        <span className="ml-3">{t('bundles')}</span>
                      </Link>
                    ),
                    key: 'channels-channels-bundles',
                  },
                  // {
                  //   label: (
                  //     <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/channels/mosaic`}>
                  //       <span className="ml-3">Channel Mosaic</span>
                  //     </Link>
                  //   ),
                  //   key: 'channels-mosaic',
                  // },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/channels/lists`}>
                        <span className="ml-3">{t('lists')}</span>
                      </Link>
                    ),
                    key: 'channels-lists',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/channels/providers`}>
                        <span className="ml-3">{t('providers')}</span>
                      </Link>
                    ),
                    key: 'channel-providers',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/channels/settings`}>
                        <span className="ml-3">{t('settings')}</span>
                      </Link>
                    ),
                    key: 'channels-settings',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/transcoders/transcoders`}>
                        <span className="ml-3">{t('transcoders')}</span>
                      </Link>
                    ),
                    key: 'channel-transcoders',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/actions/importer-excel`}>
                        <span className="ml-3">{t('import-0')}</span>
                      </Link>
                    ),
                    key: 'channel-import',
                  },
                ],
              },
              {
                label: (
                  <div className="height-60 d-flex align-items-center">
                    <FontAwesomeIcon icon={faRadio} style={{ width: '20', height: '20' }} />
                    <span className="ml-3">{t('radio')}</span>
                  </div>
                ),
                key: 'radio',
                children: [
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/radios/radios`}>
                        <span className="ml-3">{t('radio')}</span>
                      </Link>
                    ),
                    key: 'radio-radios-radios',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/radios/bundles`}>
                        <span className="ml-3">{t('bundles-0')}</span>
                      </Link>
                    ),
                    key: 'radio-radios-bundles',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/radios/lists`}>
                        <span className="ml-3">{t('lists-0')}</span>
                      </Link>
                    ),
                    key: 'radio-lists',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/radio/providers`}>
                        <span className="ml-3">{t('providers')}</span>
                      </Link>
                    ),
                    key: 'radio-providers',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/actions/importer-excel`}>
                        <span className="ml-3">{t('import-0')}</span>
                      </Link>
                    ),
                    key: 'radio-import',
                  },
                ],
              },
              {
                label: (
                  <div className="height-60 d-flex align-items-center">
                    <FontAwesomeIcon icon={faChartTreeMap} style={{ width: '20', height: '20' }} />
                    <span className="ml-3">{t('guide-1')}</span>
                  </div>
                ),
                key: 'tvguide',
                children: [
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/epg/epg_urls`}>
                        <span className="ml-3">{t('sources')}</span>
                      </Link>
                    ),
                    key: 'tvguide-urls',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/epg/epg_linking`}>
                        <span className="ml-3">{t('linking')}</span>
                      </Link>
                    ),
                    key: 'tvguide-linking',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/epg/epg_settings`}>
                        <span className="ml-3">{t('settings')}</span>
                      </Link>
                    ),
                    key: 'tvguide-settings',
                  },
                ],
              },

              {
                label: (
                  <div className="height-60 d-flex align-items-center">
                    <FontAwesomeIcon icon={faCctv} style={{ width: '20', height: '20' }} />
                    <span className="ml-3">{t('cams')}</span>
                  </div>
                ),
                key: 'cams',
                children: [
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/cams/cams`}>
                        <span className="ml-3">{t('cams')}</span>
                      </Link>
                    ),
                    key: 'cams-cams',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/cams/bundles`}>
                        <span className="ml-3">{t('bundles-1')}</span>
                      </Link>
                    ),
                    key: 'cams-bundles',
                  },
                  // {
                  //   label: (
                  //     <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/channels/lists`}>
                  //       <span className="ml-3">Cam Mosaic</span>
                  //     </Link>
                  //   ),
                  //   key: 'cams-mosaic',
                  // },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/cams/lists`}>
                        <span className="ml-3">{t('lists-1')}</span>
                      </Link>
                    ),
                    key: 'cams-lists',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/cams/providers`}>
                        <span className="ml-3">{t('providers')}</span>
                      </Link>
                    ),
                    key: 'cams-providers',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/cams/settings`}>
                        <span className="ml-3">{t('settings')}</span>
                      </Link>
                    ),
                    key: 'cams-settings',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/transcoders/transcoders`}>
                        <span className="ml-3">{t('transcoders-0')}</span>
                      </Link>
                    ),
                    key: 'cams-transcoders',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/actions/importer-excel`}>
                        <span className="ml-3">{t('import-0')}</span>
                      </Link>
                    ),
                    key: 'cams-import',
                  },
                ],
              },

              {
                label: (
                  <div className="height-60 d-flex align-items-center">
                    <FontAwesomeIcon icon={faFilm} style={{ width: '20', height: '20' }} />
                    <span className="ml-3">{t('movies-4')}</span>
                  </div>
                ),
                key: 'movies',
                children: [
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/movies/movies`}>
                        <span className="ml-3">{t('movies-4')}</span>
                      </Link>
                    ),
                    key: 'movies-movies',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/movies/bundles`}>
                        <span className="ml-3">{t('bundles-2')}</span>
                      </Link>
                    ),
                    key: 'movies-bundles',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/movies/lists`}>
                        <span className="ml-3">{t('lists-2')}</span>
                      </Link>
                    ),
                    key: 'movies-lists',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/movies/providers`}>
                        <span className="ml-3">{t('providers')}</span>
                      </Link>
                    ),
                    key: 'movies-providers',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/movies/search`}>
                        <span className="ml-3">{t('search')}</span>
                      </Link>
                    ),
                    key: 'movies-search',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/actions/importer-excel`}>
                        <span className="ml-3">{t('import-0')}</span>
                      </Link>
                    ),
                    key: 'movies-import',
                  },

                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/movies/upload`}>
                        <span className="ml-3">{t('upload')}</span>
                      </Link>
                    ),
                    key: 'movies-upload',
                  },
                ],
              },
              {
                label: (
                  <div className="height-60 d-flex align-items-center">
                    <FontAwesomeIcon icon={faGamepadModern} style={{ width: '20', height: '20' }} />
                    <span className="ml-3">{t('games')}</span>
                  </div>
                ),
                key: 'games',
                children: [
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/games/games`}>
                        <span className="ml-3">{t('games')}</span>
                      </Link>
                    ),
                    key: 'games-movies',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/games/bundles`}>
                        <span className="ml-3">{t('bundles-3')}</span>
                      </Link>
                    ),
                    key: 'games-bundles',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/games/lists`}>
                        <span className="ml-3">{t('lists-3')}</span>
                      </Link>
                    ),
                    key: 'games-lists',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/games/providers`}>
                        <span className="ml-3">{t('providers')}</span>
                      </Link>
                    ),
                    key: 'games-providers',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/actions/importer-excel`}>
                        <span className="ml-3">{t('import-0')}</span>
                      </Link>
                    ),
                    key: 'games-import',
                  },
                ],
              },
              {
                label: (
                  <div className="height-60 d-flex align-items-center">
                    <FontAwesomeIcon icon={faCamcorder} style={{ width: '20', height: '20' }} />
                    <span className="ml-3">{t('shorts-4')}</span>
                  </div>
                ),
                key: 'shorts',
                children: [
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/shorts/shorts`}>
                        <span className="ml-3">{t('shorts-4')}</span>
                      </Link>
                    ),
                    key: 'shorts-movies',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/shorts/bundles`}>
                        <span className="ml-3">{t('bundles-4')}</span>
                      </Link>
                    ),
                    key: 'shorts-bundles',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/shorts/lists`}>
                        <span className="ml-3">{t('lists-4')}</span>
                      </Link>
                    ),
                    key: 'shorts-lists',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/shorts/providers`}>
                        <span className="ml-3">{t('providers')}</span>
                      </Link>
                    ),
                    key: 'shorts-providers',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/actions/importer-excel`}>
                        <span className="ml-3">{t('import-0')}</span>
                      </Link>
                    ),
                    key: 'shorts-import',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/shorts/upload`}>
                        <span className="ml-3">{t('upload')}</span>
                      </Link>
                    ),
                    key: 'shorts-upload',
                  },
                ],
              },
              {
                label: (
                  <div className="height-60 d-flex align-items-center">
                    <FontAwesomeIcon icon={faVideo} style={{ width: '20', height: '20' }} />
                    <span className="ml-3">{t('series-4')}</span>
                  </div>
                ),
                key: 'series',
                children: [
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/series`}>
                        <span className="ml-3">{t('series-4')}</span>
                      </Link>
                    ),
                    key: 'series-series',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/series/seasons`}>
                        <span className="ml-3">{t('seasons')}</span>
                      </Link>
                    ),
                    key: 'series-seasons',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/series/bundles`}>
                        <span className="ml-3">{t('bundles-5')}</span>
                      </Link>
                    ),
                    key: 'series-bundles',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/series/lists`}>
                        <span className="ml-3">{t('lists-5')}</span>
                      </Link>
                    ),
                    key: 'series-lists',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/series/providers`}>
                        <span className="ml-3">{t('providers')}</span>
                      </Link>
                    ),
                    key: 'series-providers',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/series/search`}>
                        <span className="ml-3">{t('search')}</span>
                      </Link>
                    ),
                    key: 'series-search',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/actions/importer-excel`}>
                        <span className="ml-3">{t('import-0')}</span>
                      </Link>
                    ),
                    key: 'series-import',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/series/season/upload`}>
                        <span className="ml-3">{t('upload')}</span>
                      </Link>
                    ),
                    key: 'seasons-upload',
                  },
                ],
              },
              {
                label: (
                  <div className="height-60 d-flex align-items-center">
                    <FontAwesomeIcon icon={faSchool} style={{ width: '20', height: '20' }} />
                    <span className="ml-3">{t('courses-4')}</span>
                  </div>
                ),
                key: 'courses',
                children: [
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/courses/courses`}>
                        <span className="ml-3">{t('courses-4')}</span>
                      </Link>
                    ),
                    key: 'course-course',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/courses/bundles`}>
                        <span className="ml-3">{t('bundles-6')}</span>
                      </Link>
                    ),
                    key: 'course-bundles',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/courses/lists`}>
                        <span className="ml-3">{t('lists-6')}</span>
                      </Link>
                    ),
                    key: 'course-lists',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/courses/providers`}>
                        <span className="ml-3">{t('providers')}</span>
                      </Link>
                    ),
                    key: 'courses-providers',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/actions/importer-excel`}>
                        <span className="ml-3">{t('import-0')}</span>
                      </Link>
                    ),
                    key: 'course-import',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/courses/upload`}>
                        <span className="ml-3">{t('upload')}</span>
                      </Link>
                    ),
                    key: 'course-upload',
                  },
                ],
              },
              {
                label: (
                  <div className="height-60 d-flex align-items-center">
                    <FontAwesomeIcon icon={faMusic} style={{ width: '20', height: '20' }} />
                    <span className="ml-3">{t('albums-2')}</span>
                  </div>
                ),
                key: 'albums',
                children: [
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/albums/albums`}>
                        <span className="ml-3">{t('albums-2')}</span>
                      </Link>
                    ),
                    key: 'albums-albums',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/albums/bundles`}>
                        <span className="ml-3">{t('bundles-7')}</span>
                      </Link>
                    ),
                    key: 'albums-bundles',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/albums/lists`}>
                        <span className="ml-3">{t('lists-7')}</span>
                      </Link>
                    ),
                    key: 'albums-lists',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/albums/providers`}>
                        <span className="ml-3">{t('providers')}</span>
                      </Link>
                    ),
                    key: 'albums-providers',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/actions/importer-excel`}>
                        <span className="ml-3">{t('import-0')}</span>
                      </Link>
                    ),
                    key: 'album-import',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/albums/upload`}>
                        <span className="ml-3">{t('upload')}</span>
                      </Link>
                    ),
                    key: 'albums-upload',
                  },
                ],
              },
              {
                label: (
                  <div className="height-60 d-flex align-items-center">
                    <FontAwesomeIcon icon={faPodcast} style={{ width: '20', height: '20' }} />
                    <span className="ml-3">{t('podcasts-4')}</span>
                  </div>
                ),
                key: 'podcasts',
                children: [
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/podcasts/podcasts`}>
                        <span className="ml-3">{t('podcasts-4')}</span>
                      </Link>
                    ),
                    key: 'podcasts-podcasts',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/podcasts/bundles`}>
                        <span className="ml-3">{t('bundles-8')}</span>
                      </Link>
                    ),
                    key: 'podcasts-bundles',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/podcasts/lists`}>
                        <span className="ml-3">{t('lists-8')}</span>
                      </Link>
                    ),
                    key: 'podcasts-lists',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/podcasts/providers`}>
                        <span className="ml-3">{t('providers')}</span>
                      </Link>
                    ),
                    key: 'podcasts-providers',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/actions/importer-excel`}>
                        <span className="ml-3">{t('import-0')}</span>
                      </Link>
                    ),
                    key: 'podcasts-import',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/podcasts/upload`}>
                        <span className="ml-3">{t('upload')}</span>
                      </Link>
                    ),
                    key: 'podcasts-upload',
                  },
                ],
              },
              {
                label: (
                  <div className="height-60 d-flex align-items-center">
                    <FontAwesomeIcon icon={faCogs} style={{ width: '20', height: '20' }} />
                    <span className="ml-3">{t('settings')}</span>
                  </div>
                ),
                key: 'settings',
                children: [
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/providers/providers`}>
                        <span className="ml-3">{t('providers')}</span>
                      </Link>
                    ),
                    key: 'providers',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/tags/tags`}>
                        <span className="ml-3">{t('tags')}</span>
                      </Link>
                    ),
                    key: 'tags',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/nests/nests`}>
                        <span className="ml-3">{t('nests')}</span>
                      </Link>
                    ),
                    key: 'nests',
                  },
                  {
                    label: isMware ? (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/params/params`}>
                        <span className="ml-3">{t('parameters')}</span>
                      </Link>
                    ) : (
                      <></>
                    ),
                    key: 'params',
                  },
                  // {
                  //   label: (
                  //     <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/player/player`}>
                  //       <span className="ml-3">{t('player')}</span>
                  //     </Link>
                  //   ),
                  //   key: 'nestssss',
                  // },
                ],
              },
              isMware
                ? {
                    label: (
                      <div className="height-60 d-flex align-items-center">
                        <FontAwesomeIcon icon={faShieldCheck} style={{ width: '20', height: '20' }} />
                        <span className="ml-3">{t('drm-and-tokens')}</span>
                      </div>
                    ),
                    key: 'drm-tokens',
                    children: [
                      {
                        label: (
                          <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/drm/buydrm`}>
                            <span className="ml-3">BuyDRM</span>
                          </Link>
                        ),
                        key: 'drm-tokens-buydrm',
                      },
                      {
                        label: (
                          <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/drm/irdeto`}>
                            <span className="ml-3">{t('irdeto')}</span>
                          </Link>
                        ),
                        key: 'drm-tokens-irdeto',
                      },
                      {
                        label: (
                          <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/drm/token-settings`}>
                            <span className="ml-3">{t('tokens')}</span>
                          </Link>
                        ),
                        key: 'drm-token-settings',
                      },
                    ],
                  }
                : {
                    label: <></>,
                    key: 'empty',
                  },
              {
                label: (
                  <div className="height-60 d-flex align-items-center">
                    <FontAwesomeIcon icon={faCreditCard} style={{ width: '20', height: '20' }} />
                    <span className="ml-3">{t('pay-per-view')}</span>
                  </div>
                ),
                key: 'ppv-settings',
                children: [
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/ppv/ppvrules`}>
                        <span className="ml-3">{t('rules')}</span>
                      </Link>
                    ),
                    key: 'ppv',
                  },
                ],
              },
              {
                label: (
                  <div className="height-60 d-flex align-items-center">
                    <FontAwesomeIcon icon={faFileArrowUp} style={{ width: '20', height: '20' }} />
                    <span className="ml-3">{t('importer')}</span>
                  </div>
                ),
                key: 'import',
                children: [
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/actions/importer-excel`}>
                        <span className="ml-3">{t('importer-0')}</span>
                      </Link>
                    ),
                    key: 'content-import',
                  },
                ],
              },
              // {
              //   label: (
              //     <div className="height-60 d-flex align-items-center">
              //       <FontAwesomeIcon icon={faWebhook} style={{ width: '20', height: '20' }} />
              //       <span className="ml-3">Workflows</span>
              //     </div>
              //   ),
              //   key: 'workflow',
              //   children: [
              //     {
              //       label: (
              //         <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/actions/workflows`}>
              //           <span className="ml-3">Workflows</span>
              //         </Link>
              //       ),
              //       key: 'content-workflow',
              //     },
              //   ],
              // },
              // {
              //   label: (
              //     <div className="height-60 d-flex align-items-center">
              //       <FontAwesomeIcon icon={faArrowProgress} style={{ width: '20', height: '20' }} />
              //       <span className="ml-3">Automations</span>
              //     </div>
              //   ),
              //   key: 'automations',
              //   children: [
              //     {
              //       label: (
              //         <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/actions/automations`}>
              //           <span className="ml-3">Automations</span>
              //         </Link>
              //       ),
              //       key: 'content-automation',
              //     },
              //   ],
              // },
            ]}
          />
        )}
      </Layout.Sider>
      <div style={{ paddingLeft: 0, width: '100%' }}>
        <div>
          <IMSDashboardHeader
            {...{
              isSideBarShow: true,
              path,
            }}
          />
        </div>
        {/* {isLoading && <Spin />} */}
        {isError && <span>{t('something-went-wrong')}</span>}
        {!isLoading && !isError && data && (
          <ContentContextProvider value={{ deployment: data, service: data.services.find((x) => x.name === service) }}>
            <Switch>
              <Route path={`${path}/podcasts`}>
                <Podcasts />
              </Route>
              <Route path={`${path}/albums`}>
                <Albums />
              </Route>
              <Route path={`${path}/series`}>
                <Series />
              </Route>
              <Route path={`${path}/movies`}>
                <Movies />
              </Route>
              <Route path={`${path}/shorts`}>
                <Shorts />
              </Route>
              <Route path={`${path}/games`}>
                <Games />
              </Route>
              <Route path={`${path}/cams`}>
                <Cams />
              </Route>
              <Route path={`${path}/courses`}>
                <Courses />
              </Route>
              <Route path={`${path}/epg`}>
                <Epg />
              </Route>
              <Route path={`${path}/radios`}>
                <Radios />
              </Route>
              <Route path={`${path}/channels`}>
                <Channels />
              </Route>
              {isMware && (
                <Route path={`${path}/drm`}>
                  <DRM />
                </Route>
              )}
              <Route path={`${path}/ppv`}>
                <PPV />
              </Route>
              <Route path={`${path}/actions`}>
                <Actions />
              </Route>
              <Route path={`${path}/transcoders`}>
                <Transcoders />
              </Route>
              <Route path={`${path}/providers`}>
                <Providers />
              </Route>
              <Route path={`${path}/tags`}>
                <Tags />
              </Route>
              <Route path={`${path}/nests`}>
                <Nests />
              </Route>
              <Route path={`${path}/params`}>
                <Params />
              </Route>
              <Route path={`${path}/player`}>
                <Player />
              </Route>
              <Route path={`${path}`}>{isClient && <Dashboard />}</Route>
            </Switch>
          </ContentContextProvider>
        )}
      </div>
    </Layout>
  );
};

export default Content;
