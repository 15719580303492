import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { baseOptions } from './BaseOptions';

export const PieChart = ({ options = {}, series, horizontal = false, categories = [] }: { options?: any; series: ApexNonAxisChartSeries; horizontal?: boolean; categories?: string[] }) => {
  const chartOptions: ApexOptions = {
    ...baseOptions,
    labels: categories,
    ...options,
  };

  return <ReactApexChart options={chartOptions} series={series} type="pie" width={400} />;
};
