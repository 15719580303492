export const API_ENDPOINTS = {
  V1: {
    //CLIENT_BASEDB
    Client_Settings: {
      ADD_LANGUAGES: 'v1/client/languages/add',
      LIST_LANGUAGES: 'v1/client/languages/list',
      ADD_CURRENCY: 'v1/client/currencies/add',
      LIST_CURRENCIES: 'v1/client/currencies/list',
      ADD_UPDATE_SMTP: 'v1/client/settings/smtp',
      GET_SMTP_DETAILS: 'v1/client/settings/smtp',
      ADD_UPDATE_SENDGRID: 'v1/client/settings/sendgrid',
      GET_SENDGRID_DETAILS: 'v1/client/settings/sendgrid',
      GET_TIMEZONES_LIST: 'v1/client/settings/timezone',
      GET_TIMEZONE_DETAIL: 'v1/client/settings/timezone',
      ADD_UPDATE_TIMEZONE: 'v1/client/settings/timezone',
      ADD_UPDATE_WHITE_LABEL_SETTINGS: 'v1/client/settings/whitelabel',
      GET_WHTIE_LABEL_SETTINGS: 'v1/client/settings/whitelabel',
      ADD_SUPER_HOSTNAME: 'v1/client/settings/superhostname',
      DELETE_SUPER_HOSTNAME: 'v1/client/settings/superhostname',
      UPDATE_SUPER_HOSTNAME: 'v1/client/settings/superhostname',
      GET_SUPER_HOSTNAME_LIST: 'v1/client/settings/superhostname/list',
      GET_SUPER_HOSTNAME_DETAILS: 'v1/client/settings/superhostname/',
      GET_HOSTNAME_LIST: 'v1/uit/hostnames/list_by_crm',
      ADD_SUB_HOSTNAME: 'v1/client/settings/superhostname/sub',
      GET_SUB_HOSTNAME_LIST: 'v1/client/settings/superhostname/sub/list',
      GET_AKAMAI_SETTING_DETAILS: 'v1/client/settings/akamai',
      ADD_UPDATE_AKAMAI_SETTING_DETAILS: 'v1/client/settings/akamai',
    },
    //AUTH ENDPOINTS
    Auth: {
      LOGIN_ENDPOINT: 'v1/auth/login',
      REFRESH_ENDPOINT: 'v1/auth/authorize_with_refresh_token',
      OTP_VERIFY: 'v1/auth/verify_login',
    },
    //AUTH USER
    AuthUser: {
      ASSIGN_DATASTORE_TO_USER: 'v1/datastores/assign_datastore_to_user',
      REVOKE_DATASTORE_ACCESS_TO_USER: 'v1/datastores/revoke_datastore_access_to_user',
    },
    //USER ENDPOINTS
    User: {
      LIST: 'v1/users/list',
      ADD: 'v1/users/add',
      UPDATE: 'v1/users/update',
      DETAILS: 'v1/users/details',
      ME: 'v1/users/me',

      GET_USER_INFO: 'v1/users/get_user_details',
      ADD_USER: 'v1/users/add_client_users',
      GET_USERS_LIST: 'v1/users/get_user_list',
      GET_USERS_LIST_WITH_ROLE: 'v1/users/fetch_user_with_role',
      GET_USER_DETAILS_BY_ID: 'v1/users/get_user_details_by_id',
      DELETE_USER_BY_ID: 'v1/users/delete_user_by_id',
      DELETE_USER: 'v1/users/delete',
      UPDATE_USER_DETAILS: 'v1/users/update_user_details',
      MFA: {
        ADD_TOTP: 'v1/users/add_mfa/totp',
        VERIFY_TOTP: 'v1/users/verify_mfa/totp',
        ADD_EMAIL: 'v1/users/add_mfa/email',
        VERIFY_EMAIL: 'v1/users/verify_mfa/email',
        ALL_ACTIVE_MODES: 'v1/users/mfa/active_modes',
        MAKE_PRIMARY: 'v1/users/mfa/primary',
      },
    },
    //CLIENT ENDPOINTS
    WEFACT: {
      GET_PRODUCTS: 'v1/client/get_products',
    },

    Client: {
      ADD_CLIENT: 'v1/client/add_client',
      GET_CLIENT: 'v1/client/get_client',
      DETAILS_CLIENT: 'v1/client/details_client',
      UPDATE_CLIENT: 'v1/client/update_client',
      CHECK_CLIENT: 'v1/client/check_client',
      COPY_CLIENT: 'v1/client/copytoobjectstorage',

      //okld
      ///this needs to be in client settings
      //GET_CLIENT_SUPER_AND_SUB_HOSTNAMES: 'v1/client/client_related_super_sub_hostnames/list',
      GET_AKAMAI_TRAFFIC_USAGE_STATS: 'v1/client/akamai/usage',
    },
    //CRM ENDPOINTS
    CRM: {
      PRODUCTS: {
        GET_PRODUCTS_LIST: 'v1/crm/products/list',
        ADD_PRODUCT: 'v1/crm/products',
        UPDATE_PRODUCT: 'v1/crm/products',
        UPDATE_PRODUCT_CURRENCY: 'v1/crm/products/currency',
        DELETE_PRODUCT: 'v1/crm/products',
        GET_PRODUCT_DETAILS: 'v1/crm/products',
        PLANS_CURRENCY_UPDATE: 'v1/crm/products/payment_plan/currency',
        GET_PRODUCTS_WEBSHOPS_LIST: 'v1/crm/product/webshops/list',
        USER_INTERFACE: {
          UPDATE_USER_INTERFACE: 'v1/crm/products/user_interface',
        },
        RESELLER: {
          LIST: 'v1/crm/product',
          ADD: 'v1/crm/reseller_product',
          UPDATE: 'v1/crm/reseller_product',
          PLANS_LIST: 'v1/crm/reseller_product_plan/list',
          POSTPAID_ADD_BALANCE: 'v1/crm/reseller/add_balance',
          POSTPAID_DEDUCT_BALANCE: 'v1/crm/reseller/deduct_balance',
        },
        PLANS: {
          GET_PAYMENT_PLANS_LIST: 'v1/crm/products/payment_plan/list',
          GET_PAYMENT_PLANS_LIST_FOR_CUSTOMER: 'v1/crm/products/subscription/list',
          ADD_PAYMENT_PLAN: 'v1/crm/products/payment_plan',
          UPDATE_PAYMENT_PLAN: 'v1/crm/products/payment_plan',
          DELETE_PAYMENT_PLAN: 'v1/crm/products/payment_plan',
        },
        PACKAGES: {
          ADD_PACKAGES_TO_PRODUCT: 'v1/crm/products/packages',
          GET_DETAILS: 'v1/crm/products/package',
          UPDATE_PACKAGES: 'v1/crm/products/package',
        },
        CATCHUP_PLANS: {
          GET_LIST: 'v1/crm/product/catchup_plans/list',
          GET_TYPES_LIST: 'v1/crm/product/catchup_plans/types',
          UPDATE_TYPE: 'v1/crm/product/catchup_plans/types',
          ADD: 'v1/crm/product/catchup_plans',
          UPDATE: 'v1/crm/product/catchup_plans',
          DELETE: 'v1/crm/product/catchup_plans',
        },
        DVR_PLANS: {
          GET_LIST: 'v1/crm/product/dvr_plans/list',
          GET_TYPES_LIST: 'v1/crm/product/dvr_plans/types',
          UPDATE_TYPE: 'v1/crm/product/dvr_plans/types',
          ADD: 'v1/crm/product/dvr_plans',
          UPDATE: 'v1/crm/product/dvr_plans',
          DELETE: 'v1/crm/product/dvr_plans',
        },
        IP_RANGES: {
          GET_LIST: 'v1/crm/product/ip_ranges/list',
          ADD: 'v1/crm/product/ip_ranges',
          UPDATE: 'v1/crm/product/ip_ranges',
          DELETE: 'v1/crm/product/ip_ranges',
        },
        PROMOTIONS: {
          GET_LIST: 'v1/crm/products/promotion/list',
          ADD: 'v1/crm/products/promotion',
          DELETE: 'v1/crm/products/promotion',
        },
        EXTRA_PACKAGE: {
          GET_LIST: 'v1/crm/products/extra_package/list',
          SUB_LIST: 'v1/crm/products/associated_extra_package/list',
          UPDATE: 'v1/crm/products/extra_package',
          ADD: 'v1/crm/products/extra_package',
          //:id
          GET: 'v1/crm/products/extra_package',
          DELETE: 'v1/crm/products/extra_package',
          GET_PACAKGES_FOR_CUSTOMER: 'v1/crm/customer/extratvpackages',
        },
        API_PROVISION: {
          ADD: 'v1/crm/products/api_provision',
          LIST: 'v1/crm/products/api_provision/list',
          UPDATE: 'v1/crm/products/api_provision',
          DELETE: 'v1/crm/products/api_provision',
          //keys
          KEYS_ADD: 'v1/crm/products/api_provision/keys',
          KEYS_UPDATE: 'v1/crm/products/api_provision/keys',
          KEYS_DELETE: 'v1/crm/products/api_provision/keys',
          KEYS_LIST: 'v1/crm/products/api_provision/keys',
        },
        SUPPORT_PAGE: {
          ADD: 'v1/crm/product/support_page',
          UPDATE: 'v1/crm/product/support_page',
          GET_LIST: 'v1/crm/product/support_page',
          //:_id
          GET_DETAILS: 'v1/crm/product/support_page',
          DELETE: 'v1/crm/product/support_page',
        },
        APP_OTA: {
          ADD: 'v1/crm/product/app_update',
          GET_LIST: 'v1/crm/product/app_update',
          //:_id
          DELETE: 'v1/crm/product/app_update',
        },
        DEVICES: {
          ADD_UPDATE: 'v1/crm/product/devices',
          GET_ALL_PRODUCT_DEVICES: 'v1/crm/product/devices',
          GET_ALL_DEVICES_LIST: 'v1/crm/product/devices',
        },
      },
      RESELLERS: {
        GET_RESELLERS_LIST: 'v1/crm/reseller/list',
        GET_SUB_RESELLER_LIST: 'v1/crm/reseller/sub_reseller/list',
        ADD_RESELLER: 'v1/crm/reseller',
        CHECK_RESELLER_TYPE: 'v1/crm/reseller/type',
        UPDATE_RESELLER: 'v1/crm/reseller',
        UPDATE_RESELLER_TYPE: 'v1/crm/reseller/type',
        UPDATE_RESELLER_BRANDING: 'v1/crm/reseller/branding',
        GET_RESELLERS_LIST_OF_CURRENT_USER: 'v1/crm/reseller/sub_reseller_of_reseller_user/list',
        GET_RESELLER_BALANCE_LOGS: 'v1/crm/reseller/balance/logs',
        ADD_RESELLER_BALANCE: 'v1/crm/reseller/balance/add',
        DEDUCT_RESELLER_BALANCE: 'v1/crm/reseller/balance/deduct',

        // /:reseller_id
        GET_RESELLER_DETAILS: 'v1/crm/reseller',
        DELETE_RESELLER: 'v1/crm/reseller',
        PRODUCT: {
          LIST: 'v1/crm/reseller/product/list',
          LIST_ALL_PRODUCT_PLANS: 'v1/crm/reseller/product/payment_plan/list',
          LIST_FOR_POSTPAID: 'v1/crm/products/list/reseller',
          PLANS_LIST_FOR_POSTPAID: 'v1/crm/products/subscription/list',

          ADD: 'v1/crm/reseller/product',
          UPDATE: 'v1/crm/reseller/product',
          DELETE: 'v1/crm/reseller/product',
          AVAILABLE_PLANS_FOR_PRODUCT: 'v1/crm/reseller',
          ADD_PLAN_TO_RESELLER_PRODUCT: 'v1/crm/reseller/product/payment_plan',
          DELETE_PLAN_FROM_RESELLER_PRODUCT: 'v1/crm/reseller/product/payment_plan',
        },
        PREORDER: {
          PREORDER_PRODUCTS_LIST: 'v1/crm/reseller/pre_order/product/list',
          ADD_PREORDER: 'v1/crm/reseller/pre_order/add_preorder',
          LIST_PREORDER: 'v1/crm/reseller/pre_order/list',
          ALL_PREORDERS_PRODUCTS_LIST: 'v1/crm/reseller/pre_order/product/all_products',
        },
        EXTRA_PACKAGES: {
          LIST: 'v1/crm/reseller/extra_packages/list',
          ADD: 'v1/crm/reseller/extra_packages',
          DELETE: 'v1/crm/reseller/extra_packages',
          //:_id
          LIST_FOR_RESELLER: 'v1/crm/reseller/extra_packages',
        },
        DOMAIN_MAPPING: {
          GET_AVAILABLE_HOSTNAMES: 'v1/crm/reseller/availablehostnames',
          DELETE_HOSTNAME: 'v1/crm/reseller/availablehostnames',
          LIST_HOSTNAMES: 'v1/crm/reseller/hostnames/list',
          ADD_HOSTNAME: 'v1/crm/reseller/hostnames',
        },
      },
      CUSTOMERS: {
        GET_PRODUCTS_LIST_FOR_CUSTOMER: 'v1/crm/customer',
        ADD_CUSTOMER: 'v1/crm/customer',
        UPDATE_CUSTOMER: 'v1/crm/customer',
        GET_CUSTOMERS_LIST: 'v1/crm/customer/list',
        GET_CUSTOMER_DETAILS: 'v1/crm/customer',
        DELETE_CUSTOMER: 'v1/crm/customer',
        REGENERATE_PASSWORD: 'v1/crm/customer/new_password',
        RESEND_REGISTERATION_DETAILS: 'v1/crm/customer/resend_registeration_details',
        UPDATE_COMMENT: 'v1/crm/customer/update/comment',
        UPDATE_MESSAGE: 'v1/crm/customer/update/message',
        EXTEND_SUBSCRIPTION: 'v1/crm/customer/subscription',
        SUBSCRIPTIONS_LIST: 'v1/crm/customer/subscription',
        EXTEND_SUBSCRIPTION_DATE: 'v1/crm/customer/subscription/extend_date',
        UPDATE_STATUS: 'v1/crm/customer/status',
        STATS: 'v1/crm/customer/stats',
        //Extrapackages
        ADD_EXTRA_PACKAGE: 'v1/crm/customer/subscription/extratvpackage',
        DELETE_EXTRA_PACKAGE: 'v1/crm/customer/subscription/extratvpackage',
        GET_EXTRA_PACKAGES: 'v1/crm/customer/subscription/extratvpackage',
        //Tickets
        ADD_TICKET: 'v1/crm/customer/ticket',
        ADD_TICKET_RESPONSE: 'v1/crm/customer/ticket/response',
        GET_TICKETS: 'v1/crm/customer/ticket/list',
        GET_TICKET_RESPONSES: 'v1/crm/tickets/response/list',
      },
      SETTINGS: {
        CURRENCIES: {
          GET_ALL_CURRENCIES: 'v1/crm/currencies/list',
          GET_AVAILABLE_CURRENCIES: 'v1/crm/currencies/available/list',
          ADD_CURRENCY: 'v1/crm/currency',
          UDPATE_CURRENCY: 'v1/crm/currency',
          DELETE_CURRENCY: 'v1/crm/currency',
        },
        COMPANY_INFO: {
          GET_COMPANY_INFO: 'v1/crm/settings/company_info',
          GENERATE_API_KEY: 'v1/crm/settings/company_info/generate_api_key',
          ADD_UPDATE_COMPANY_INFO: 'v1/crm/settings/company_info',
          ADD_UPDATE_COMPANY_DISCLAIMER: 'v1/crm/settings/company_info/disclaimer',
        },
        GENERAL: {
          GET_DETAILS: 'v1/crm/settings/general',
          ADD_UPDATE_DETAILS: 'v1/crm/settings/general',
        },
        LOG_TRANSLATIONS: {
          LIST: 'v1/crm/settings/log_translation',
          UPDATE_LOG_TRANSLATION: 'v1/crm/settings/log_translation',
        },
        CRM_RULES: {
          LIST: 'v1/crm/settings/crm_rules/list',
          UPDATE: 'v1/crm/settings/crm_rules',
          DELETE: 'v1/crm/settings/crm_rules',
          DETAILS: 'v1/crm/settings/crm_rules',
          ADD: 'v1/crm/settings/crm_rules',
        },
      },
      ADVERTISEMENTS: {
        BANNER_ADS: {
          GET_LIST: 'v1/crm/advertisement/banner_ad/list',
          ADD: 'v1/crm/advertisement/banner_ad',
          UPDATE: 'v1/crm/advertisement/banner_ad',
          UPDATE_CAMPAIGN: 'v1/crm/advertisement/banner_ad/update_campaign',
          DELETE: 'v1/crm/advertisement/banner_ad',
          //_id
          DETAILS: 'v1/crm/advertisement/banner_ad',
        },
        PREROL_ADS: {
          GET_LIST: 'v1/crm/advertisement/preroll/list',
          ADD: 'v1/crm/advertisement/preroll',
          UPDATE: 'v1/crm/advertisement/preroll',
          //_id
          DELETE: 'v1/crm/advertisement/preroll',
          DETAILS: 'v1/crm/advertisement/preroll',
        },
        OVERLAY_ADS: {
          GET_LIST: 'v1/crm/advertisement/overlay_ad/list',
          ADD: 'v1/crm/advertisement/overlay_ad',
          UPDATE: 'v1/crm/advertisement/overlay_ad',
          DELETE: 'v1/crm/advertisement/overlay_ad',
          //_id
          DETAILS: 'v1/crm/advertisement/overlay_ad',
        },
        TICKER_ADS: {
          GET_LIST: 'v1/crm/advertisement/ticker/list',
          ADD: 'v1/crm/advertisement/ticker',
          UPDATE: 'v1/crm/advertisement/ticker',
          //_id
          DELETE: 'v1/crm/advertisement/ticker',
          DETAILS: 'v1/crm/advertisement/ticker',
        },
      },
      COMMUNICATIONS: {
        MASS_MAIL_SETTINGS: {
          GET: 'v1/crm/communication/mass_mail_setting',
          REMOVE: 'v1/crm/communication/mass_mail_setting',
          ADD_UPDATE: 'v1/crm/communication/mass_mail_setting',
        },
        MASS_MAIL: {
          GET: 'v1/crm/communication/mass_mail/list',
          ADD: 'v1/crm/communication/mass_mail',
        },
        MASS_SMS: {
          GET_LIST: 'v1/crm/communication/mass_sms/list',
          ADD: 'v1/crm/communication/mass_sms',
          DELETE: 'v1/crm/communication/mass_sms',
        },
        NOTIFICATIONS: {
          GET_CONTENT_BY_TYPE: 'v1/crm/communication/notifications/content_items',
          ADD: 'v1/crm/communication/notifications',
          GET_LIST: 'v1/crm/communication/notifications/list',
          DELETE: 'v1/crm/communication/notifications',
        },
        SUBSCRIPTION_NOTIFICATIONS: {
          ADD: 'v1/crm/communication/subscription',
          UPDATE: 'v1/crm/communication/subscription',
          GET_LIST: 'v1/crm/communication/subscription/list',
          GET_DETAILS: 'v1/crm/communication/subscription',
          DELETE: 'v1/crm/communication/subscription',
        },

        MASS_SMS_SETTINGS: {
          GET: 'v1/crm/communication/mass_sms_setting',
          DELETE: 'v1/crm/communication/mass_sms_setting',
          ADD_UPDATE: 'v1/crm/communication/mass_sms_setting',
        },
        TEMPLATES: {
          GET: 'v1/crm/communication/template/details',
          ADD_UPDATE: 'v1/crm/communication/template',
        },
      },
      TICKETS: {
        LIST: 'v1/crm/tickets/list',
        LIST_RESPONSES: 'v1/crm/tickets/response/list',
        ADD_RESPONSE: 'v1/crm/tickets/response',
        UPDATE_STATUS: 'v1/crm/tickets',
        DETAILS: 'v1/crm/tickets',
      },
      DEALERS: {
        GET_LIST: 'v1/crm/dealer/list',
      },
      WEBSHOPS: {
        ADD_SETTINGS: 'v1/crm/webshops',
        UPDATE_SETTINGS: 'v1/crm/webshops',
        GET_SETTINGS: 'v1/crm/webshops',
        VERIFY_WEBSHOP_WITH_PRODUCT: 'v1/crm/webshops/verify_webshop',
        GET_SETTINGS_LIST: 'v1/crm/webshops/list',
        GET_AVAILABLE_WEBSHOPS_LIST: 'v1/crm/webshops/available_list',
        GET_AVAILABLE_WEBHOOK_TYPES: 'v1/crm/webshops/available_types',
        ADD_PRODUCT_TO_WEBSHOP: 'v1/crm/webshops/product',
        GET_WEBSHOPS_PRODUCTS_LIST: 'v1/crm/webshops/product/list',
        GET_WEBSHOP_PRODUCT_DETAILS: 'v1/crm/webshops/product/details',
        DELETE_WEBSHOP_PRODUCT_IMAGE: 'v1/crm/webshops/product/images',
      },
    },
    //MARKETING
    MARKETING: {
      AFFILIATES: {
        DETAILS: 'v1/marketing/affiliates/affiliates',
        UPDATE: 'v1/marketing/affiliates/affiliates',
        ADD: 'v1/marketing/affiliates/affiliates',
        DELETE: 'v1/marketing/affiliates/affiliates',
        LIST: 'v1/marketing/affiliates/affiliates/list',
      },
      COUPONS: {
        DETAILS: 'v1/marketing/coupons/coupons',
        UPDATE: 'v1/marketing/coupons/coupons',
        ADD: 'v1/marketing/coupons/coupons',
        DELETE: 'v1/marketing/coupons/coupons',
        LIST: 'v1/marketing/coupons/coupons/list',
      },
    },
    //REPORTING
    REPORTING: {
      CHART_DESIGNS: {
        LIST: 'v1/reporting/chartdesigns/chartdesign/list',
        DETAILS: 'v1/reporting/chartdesigns/chartdesign',
        UPDATE: 'v1/reporting/chartdesigns/chartdesign',
        ADD: 'v1/reporting/chartdesigns/chartdesign',
        DELETE: 'v1/reporting/chartdesigns/chartdesign',
      },
      DASHBOARDS: {
        GENERATE: 'v1/reporting/dashboards/dashboard',
      }
    },

    //CMS ENDPOINTS
    CMS: {
      EPG: {
        URLS: {
          LIST: 'v1/content/epg/urls/list',
          DETAILS: 'v1/content/epg/urls',
          UPDATE: 'v1/content/epg/urls',
          ADD: 'v1/content/epg/urls',
          XMLTV: 'v1/content/epg/xmltv',
          DELETE: 'v1/content/epg/urls',
          DELETE_GROUP: 'v1/content/epg/urls/group',
          IMPORT: 'v1/content/epg/urls/import',
        },
        META: {
          LIST: 'v1/content/epg/meta/list',
          DETAILS: 'v1/content/epg/meta',
          DELETE: 'v1/content/epg/meta',
          UPDATE: 'v1/content/epg/meta',
          CLEAR: 'v1/content/epg/meta/meta',
          IMPORT: 'v1/content/epg/meta/import',
        },
        DATA: {
          LIST: 'v1/content/epg/data/list',
          UPDATE: 'v1/content/epg/data',
          CLEAR: 'v1/content/epg/data/clear',
          IMPORT: 'v1/content/epg/data/import',
          IMPORTDAY: 'v1/content/epg/data/import/day',
          DETAILS: 'v1/content/epg/data/details',
        },
        SETTINGS: {
          DETAILS: 'v1/content/epg/settings',
          UPDATE: 'v1/content/epg/settings',
          ADD: 'v1/content/epg/settings',
        },
      },
      SCHEDULER: {
        LIST: 'v1/content/scheduler/list',
        UPDATE: 'v1/content/scheduler/update',
        ADD: 'v1/content/scheduler/add',
        DELETE: 'v1/content/scheduler/delete',
      },
      PPV: {
        PPV: {
          DETAILS: 'v1/content/ppv/ppv',
          UPDATE: 'v1/content/ppv/ppv',
          ADD: 'v1/content/ppv/ppv',
          DELETE: 'v1/content/ppv/ppv',
          LIST: 'v1/content/ppv/ppv/list',
        },
      },
      TAGS: {
        TAGS: {
          DETAILS: 'v1/content/tags/tags',
          UPDATE: 'v1/content/tags/tags',
          ADD: 'v1/content/tags/tags',
          DELETE: 'v1/content/tags/tags',
          LIST: 'v1/content/tags/tags/list',
        },
      },
      NESTS: {
        NESTS: {
          DETAILS: 'v1/content/nests/nests',
          UPDATE: 'v1/content/nests/nests',
          ADD: 'v1/content/nests/nests',
          DELETE: 'v1/content/nests/nests',
          LIST: 'v1/content/nests/nests/list',
        },
      },
      PARAMS: {
        PARAMS: {
          DETAILS: 'v1/content/params/params',
          UPDATE: 'v1/content/params/params',
          ADD: 'v1/content/params/params',
          DELETE: 'v1/content/params/params',
          LIST: 'v1/content/params/params/list',
        },
      },
      PROVIDER: {
        PROVIDER: {
          DETAILS: 'v1/content/provider/provider',
          UPDATE: 'v1/content/provider/provider',
          ADD: 'v1/content/provider/provider',
          DELETE: 'v1/content/provider/provider',
          LIST: 'v1/content/provider/provider/list',
        },
      },
      DRM: {
        DRM: {
          DETAILS: 'v1/content/drm/drm',
          UPDATE: 'v1/content/drm/drm',
          ADD: 'v1/content/drm/drm',
          DELETE: 'v1/content/drm/drm',
          LIST: 'v1/content/drm/drm/list',
        },
      },
      PLAYER: {
        PLAYER: {
          DETAILS: 'v1/content/player/player',
          UPDATE: 'v1/content/player/player',
          ADD: 'v1/content/player/player',
          DELETE: 'v1/content/player/player',
          LIST: 'v1/content/player/player/list',
        },
      },
      WORKFLOWS: {
        WORKFLOWS: {
          LIST: 'v1/content/workflows/workflows/list',
          DETAILS: 'v1/content/workflows/workflows',
          TEST: 'v1/content/workflows/test',
          UPDATE: 'v1/content/workflows/workflows',
          ADD: 'v1/content/workflows/workflows',
          DELETE: 'v1/content/workflows/workflows',
        },
      },
      AUTOMATIONS: {
        AUTOMATIONS: {
          LIST: 'v1/content/automations/automations/list',
          DETAILS: 'v1/content/automations/automations',
          UPDATE: 'v1/content/automations/automations',
          ADD: 'v1/content/automations/automations',
          DELETE: 'v1/content/automations/automations',
        },
      },
      TRANSCODERS: {
        TRANSCODERS: {
          LIST: 'v1/content/transcoders/transcoders/list',
          DETAILS: 'v1/content/transcoders/transcoders',
          UPDATE: 'v1/content/transcoders/transcoders',
          ADD: 'v1/content/transcoders/transcoders',
          DELETE: 'v1/content/transcoders/transcoders',
        },
      },
      ALBUMS: {
        ALBUMS: {
          LIST: 'v1/content/albums/albums/list',
          AUTOCOMPLETE_LIST: 'v1/content/albums/albums/autocomplete_list',
          DETAILS: 'v1/content/albums/albums',
          UPDATE: 'v1/content/albums/albums',
          ADD: 'v1/content/albums/albums',
          DELETE: 'v1/content/albums/albums',
          DELETE_GROUP: 'v1/content/albums/albums/group',
          IMPORT: 'v1/content/albums/albums/import',
          EXPORT: 'v1/content/albums/albums/export',
        },
        LIST: {
          ADD: 'v1/content/albums/list',
          UPDATE: 'v1/content/albums/list',
          DELETE: 'v1/content/albums/list',
          DETAILS: 'v1/content/albums/list',
          LIST: 'v1/content/albums/list/list',
          PUBLISH: 'v1/content/albums/list/publish',
        },
        PACKAGES: {
          LIST: 'v1/content/albums/bundles/list',
          DETAILS: 'v1/content/albums/bundles',
          UPDATE: 'v1/content/albums/bundles',
          PUBLISH: 'v1/content/albums/publish',
          ADD: 'v1/content/albums/bundles',
          DELETE: 'v1/content/albums/bundles',
        },
      },
      COURSES: {
        LIST: {
          ADD: 'v1/content/courses/list',
          UPDATE: 'v1/content/courses/list',
          DELETE: 'v1/content/courses/list',
          DETAILS: 'v1/content/courses/list',
          LIST: 'v1/content/courses/list/list',
          PUBLISH: 'v1/content/courses/list/publish',
        },
        COURSES: {
          LIST: 'v1/content/courses/courses/list',
          AUTOCOMPLETE_LIST: 'v1/content/courses/courses/autocomplete_list',
          DETAILS: 'v1/content/courses/courses',
          UPDATE: 'v1/content/courses/courses',
          ADD: 'v1/content/courses/courses',
          DELETE: 'v1/content/courses/courses',
          IMPORT: 'v1/content/courses/courses/import',
          EXPORT: 'v1/content/courses/courses/export',
          THUMBNAILS: 'v1/content/courses/publish/thumbnails',
        },
        PACKAGES: {
          LIST: 'v1/content/courses/bundles/list',
          DETAILS: 'v1/content/courses/bundles',
          UPDATE: 'v1/content/courses/bundles',
          ADD: 'v1/content/courses/bundles',
          PUBLISH: 'v1/content/courses/publish',
          DELETE: 'v1/content/courses/bundles',
        },
      },
      SERIES: {
        SERIES: {
          LIST: 'v1/content/series/series/list',
          AUTOCOMPLETE_LIST: 'v1/content/series/series/autocomplete_list',
          DETAILS: 'v1/content/series/series',
          UPDATE: 'v1/content/series/series',
          ADD: 'v1/content/series/series',
          DELETE: 'v1/content/series/series',
          DELETE_GROUP: 'v1/content/series/series/group',
          IMPORT: 'v1/content/series/series/import',
          EXPORT: 'v1/content/series/series/export',
        },
        LIST: {
          ADD: 'v1/content/series/list',
          UPDATE: 'v1/content/series/list',
          DELETE: 'v1/content/series/list',
          DETAILS: 'v1/content/series/list',
          LIST: 'v1/content/series/list/list',
          PUBLISH: 'v1/content/series/list/publish',
        },
        SEASONS: {
          LIST: 'v1/content/series/seasons/list',
          AUTOCOMPLETE_LIST: 'v1/content/seasons/seasons/autocomplete_list',
          DETAILS: 'v1/content/series/seasons',
          UPDATE: 'v1/content/series/seasons',
          ADD: 'v1/content/series/seasons',
          DELETE: 'v1/content/series/seasons',
          IMPORT: 'v1/content/series/seasons/import',
          EXPORT: 'v1/content/series/seasons/export',
          DELETE_GROUP: 'v1/content/series/seasons/group',
        },
        EPISODES: {
          LIST: 'v1/content/series/episodes/list',
          DETAILS: 'v1/content/series/episodes',
          UPDATE: 'v1/content/series/episodes',
          ADD: 'v1/content/series/episodes',
          DELETE: 'v1/content/series/episodes',
          IMPORT: 'v1/content/series/seasons/episodes',
          EXPORT: 'v1/content/series/seasons/episodes',
        },
        // LIST: {
        //   ADD: 'v1/content/seasons/list',
        //   UPDATE: 'v1/content/seasons/list',
        //   DELETE: 'v1/content/seasons/list',
        //   DETAILS: 'v1/content/seasons/list',
        //   LIST: 'v1/content/seasons/list/list',
        // },
        PACKAGES: {
          LIST: 'v1/content/series/bundles/list',
          DETAILS: 'v1/content/series/bundles',
          UPDATE: 'v1/content/series/bundles',
          ADD: 'v1/content/series/bundles',
          PUBLISH: 'v1/content/series/publish',
          DELETE: 'v1/content/series/bundles',
        },
      },
      PODCASTS: {
        PODCASTS: {
          LIST: 'v1/content/podcasts/podcasts/list',
          AUTOCOMPLETE_LIST: 'v1/content/podcasts/podcasts/autocomplete_list',
          DETAILS: 'v1/content/podcasts/podcasts',
          UPDATE: 'v1/content/podcasts/podcasts',
          ADD: 'v1/content/podcasts/podcasts',
          DELETE: 'v1/content/podcasts/podcasts',
          DELETE_GROUP: 'v1/content/podcasts/podcasts/group',
          IMPORT: 'v1/content/podcasts/podcasts/import',
          EXPORT: 'v1/content/podcasts/podcasts/export',
        },
        LIST: {
          ADD: 'v1/content/podcasts/list',
          UPDATE: 'v1/content/podcasts/list',
          DELETE: 'v1/content/podcasts/list',
          DETAILS: 'v1/content/podcasts/list',
          LIST: 'v1/content/podcasts/list/list',
          PUBLISH: 'v1/content/podcasts/list/publish',
        },
        PACKAGES: {
          LIST: 'v1/content/podcasts/bundles/list',
          DETAILS: 'v1/content/podcasts/bundles',
          UPDATE: 'v1/content/podcasts/bundles',
          ADD: 'v1/content/podcasts/bundles',
          PUBLISH: 'v1/content/podcasts/publish',
          DELETE: 'v1/content/podcasts/bundles',
        },
      },
      CAMS: {
        CAMS: {
          LIST: 'v1/content/cams/cams/list',
          AUTOCOMPLETE_LIST: 'v1/content/cams/cams/autocomplete_list',
          DETAILS: 'v1/content/cams/cams',
          UPDATE: 'v1/content/cams/cams',
          ADD: 'v1/content/cams/cams',
          DELETE: 'v1/content/cams/cams',
          DELETE_GROUP: 'v1/content/cams/cams/group',
          IMPORT: 'v1/content/cams/cams/import',
          EXPORT: 'v1/content/cams/cams/export',
        },
        LIST: {
          ADD: 'v1/content/cams/list',
          UPDATE: 'v1/content/cams/list',
          DELETE: 'v1/content/cams/list',
          DETAILS: 'v1/content/cams/list',
          LIST: 'v1/content/cams/list/list',
          PUBLISH: 'v1/content/cams/list/publish',
        },
        PACKAGES: {
          LIST: 'v1/content/cams/bundles/list',
          DETAILS: 'v1/content/cams/bundles',
          UPDATE: 'v1/content/cams/bundles',
          ADD: 'v1/content/cams/bundles',
          PUBLISH: 'v1/content/cams/publish',
          DELETE: 'v1/content/cams/bundles',
        },
        SETTINGS: {
          MANAGE: 'v1/content/cams/settings',
          GET: 'v1/content/cams/settings',
        },
      },
      RADIO: {
        RADIO: {
          LIST: 'v1/content/radio/radio/list',
          AUTOCOMPLETE_LIST: 'v1/content/radio/radio/autocomplete_list',
          DETAILS: 'v1/content/radio/radio',
          UPDATE: 'v1/content/radio/radio',
          ADD: 'v1/content/radio/radio',
          DELETE: 'v1/content/radio/radio',
          DELETE_GROUP: 'v1/content/radio/radio/group',
          IMPORT: 'v1/content/radio/radio/import',
          EXPORT: 'v1/content/radio/radio/export',
        },
        LIST: {
          ADD: 'v1/content/radio/list',
          UPDATE: 'v1/content/radio/list',
          DELETE: 'v1/content/radio/list',
          DETAILS: 'v1/content/radio/list',
          LIST: 'v1/content/radio/list/list',
          PUBLISH: 'v1/content/radio/list/publish',
        },
        PACKAGES: {
          LIST: 'v1/content/radio/bundles/list',
          DETAILS: 'v1/content/radio/bundles',
          UPDATE: 'v1/content/radio/bundles',
          ADD: 'v1/content/radio/bundles',
          PUBLISH: 'v1/content/radio/publish',
          DELETE: 'v1/content/radio/bundles',
        },
        EPG: {
          PUBLISH: 'v1/content/radio/epg/publish',
          PUBLISHALL: 'v1/content/radio/epgall/publish',
        },
        SETTINGS: {
          MANAGE: 'v1/content/radio/settings',
          GET: 'v1/content/radio/settings',
        },
      },
      CHANNELS: {
        CHANNELS: {
          LIST: 'v1/content/channels/channels/list',
          AUTOCOMPLETE_LIST: 'v1/content/channels/channels/autocomplete_list',
          DETAILS: 'v1/content/channels/channels',
          UPDATE: 'v1/content/channels/channels',
          ADD: 'v1/content/channels/channels',
          DELETE: 'v1/content/channels/channels',
          DELETE_GROUP: 'v1/content/channels/channels/group',
          IMPORT: 'v1/content/channels/channels/import',
          EXPORT: 'v1/content/channels/channels/export',
        },
        RECORDINGS: {
          DETAILS: 'v1/content/channels/recordings',
          LIST: 'v1/content/channels/recordings/list',
          DELETE: 'v1/content/channels/recordings',
        },
        LIST: {
          ADD: 'v1/content/channels/list',
          UPDATE: 'v1/content/channels/list',
          DELETE: 'v1/content/channels/list',
          DETAILS: 'v1/content/channels/list',
          LIST: 'v1/content/channels/list/list',
          PUBLISH: 'v1/content/channels/list/publish',
        },
        PACKAGES: {
          LIST: 'v1/content/channels/bundles/list',
          DETAILS: 'v1/content/channels/bundles',
          UPDATE: 'v1/content/channels/bundles',
          ADD: 'v1/content/channels/bundles',
          PUBLISH: 'v1/content/channels/publish',
          DELETE: 'v1/content/channels/bundles',
        },
        EPG: {
          PUBLISH: 'v1/content/channels/epg/publish',
          PUBLISHALL: 'v1/content/channels/epgall/publish',
        },
        SETTINGS: {
          MANAGE: 'v1/content/channels/settings/manage',
          GET: 'v1/content/channels/settings/get',
        },
      },
      MOVIES: {
        MOVIES: {
          LIST: 'v1/content/movies/movies/list',
          AUTOCOMPLETE_LIST: 'v1/content/movies/movies/autocomplete_list',
          DETAILS: 'v1/content/movies/movies',
          UPDATE: 'v1/content/movies/movies',
          ADD: 'v1/content/movies/movies',
          DELETE: 'v1/content/movies/movies',
          DELETE_GROUP: 'v1/content/movies/movies/group',
          IMPORT: 'v1/content/movies/movies/import',
          EXPORT: 'v1/content/movies/movies/export',
        },
        LIST: {
          ADD: 'v1/content/movies/list',
          UPDATE: 'v1/content/movies/list',
          DELETE: 'v1/content/movies/list',
          DETAILS: 'v1/content/movies/list',
          LIST: 'v1/content/movies/list/list',
          PUBLISH: 'v1/content/movies/list/publish',
        },
        PACKAGES: {
          LIST: 'v1/content/movies/bundles/list',
          DETAILS: 'v1/content/movies/bundles',
          UPDATE: 'v1/content/movies/bundles',
          ADD: 'v1/content/movies/bundles',
          PUBLISH: 'v1/content/movies/publish',
          DELETE: 'v1/content/movies/bundles',
        },
      },
      SHORTS: {
        SHORTS: {
          LIST: 'v1/content/shorts/shorts/list',
          AUTOCOMPLETE_LIST: 'v1/content/shorts/shorts/autocomplete_list',
          DETAILS: 'v1/content/shorts/shorts',
          UPDATE: 'v1/content/shorts/shorts',
          ADD: 'v1/content/shorts/shorts',
          DELETE: 'v1/content/shorts/shorts',
          DELETE_GROUP: 'v1/content/shorts/shorts/group',
          IMPORT: 'v1/content/shorts/shorts/import',
          EXPORT: 'v1/content/shorts/shorts/export',
        },
        LIST: {
          ADD: 'v1/content/shorts/list',
          UPDATE: 'v1/content/shorts/list',
          DELETE: 'v1/content/shorts/list',
          DETAILS: 'v1/content/shorts/list',
          LIST: 'v1/content/shorts/list/list',
          PUBLISH: 'v1/content/shorts/list/publish',
        },
        PACKAGES: {
          LIST: 'v1/content/shorts/bundles/list',
          DETAILS: 'v1/content/shorts/bundles',
          UPDATE: 'v1/content/shorts/bundles',
          ADD: 'v1/content/shorts/bundles',
          PUBLISH: 'v1/content/shorts/publish',
          DELETE: 'v1/content/shorts/bundles',
        },
      },
      GAMES: {
        GAMES: {
          LIST: 'v1/content/games/games/list',
          AUTOCOMPLETE_LIST: 'v1/content/games/games/autocomplete_list',
          DETAILS: 'v1/content/games/games',
          UPDATE: 'v1/content/games/games',
          ADD: 'v1/content/games/games',
          DELETE: 'v1/content/games/games',
          DELETE_GROUP: 'v1/content/games/games/group',
          IMPORT: 'v1/content/games/games/import',
          EXPORT: 'v1/content/games/games/export',
        },
        LIST: {
          ADD: 'v1/content/games/list',
          UPDATE: 'v1/content/games/list',
          DELETE: 'v1/content/games/list',
          DETAILS: 'v1/content/games/list',
          LIST: 'v1/content/games/list/list',
          PUBLISH: 'v1/content/games/list/publish',
        },
        PACKAGES: {
          LIST: 'v1/content/games/bundles/list',
          DETAILS: 'v1/content/games/bundles',
          UPDATE: 'v1/content/games/bundles',
          ADD: 'v1/content/games/bundles',
          PUBLISH: 'v1/content/games/publish',
          DELETE: 'v1/content/games/bundles',
        },
      },
      APPS: {
        APPS: {
          LIST: 'v1/content/apps/apps/list',
          DETAILS: 'v1/content/apps/apps',
          UPDATE: 'v1/content/apps/apps',
          ADD: 'v1/content/apps/apps',
          DELETE: 'v1/content/apps/apps',
          DELETE_GROUP: 'v1/content/apps/apps/group',
          IMPORT: 'v1/content/apps/apps/import',
          EXPORT: 'v1/content/apps/apps/export',
        },
        PACKAGES: {
          LIST: 'v1/content/apps/bundles/list',
          DETAILS: 'v1/content/apps/bundles',
          UPDATE: 'v1/content/apps/bundles',
          ADD: 'v1/content/apps/bundles',
          PUBLISH: 'v1/content/apps/publish',
          DELETE: 'v1/content/appsbundles',
        },
      },
      //CMS SETTINGS
      SETTINGS: {
        CURRENCIES: {
          GET_ALL_CURRENCIES: 'v1/content/currencysettings',
          GET_AVAILABLE_CURRENCIES: 'v1/content/currencysettings/available/list',
          ADD_CURRENCY: 'v1/content/currencysettings',
          UDPATE_CURRENCY: 'v1/content/currencysettings',
          DELETE_CURRENCY: 'v1/content/currencysettings',
        },
        LANGUAGES: {
          GET_ALL_LANGUAGES: 'v1/content/languagesettings',
          GET_AVAILABLE_LANGUAGES: 'v1/content/languagesettings/available/list',
          ADD_LANGUAGE: 'v1/content/languagesettings',
          UDPATE_LANGUAGE: 'v1/content/languagesettings',
          DELETE_LANGUAGE: 'v1/content/languagesettings',
        },
        DRM: {
          ADD_DRM_KEY: 'v1/content/drm_buydrm',
          UPDATE_DRM_KEY: 'v1/content/drm_buydrm',
          DRM_KEY_PACKAGES_LIST: 'v1/content/drm/list',
          DELETE_DRM_KEY: 'v1/content/drm_buydrm',
          GET_DRM_KEY: 'v1/content/drm',
          ADD_DRM_SETTINGS_KEY: 'v1/content/drm_settings',
          UPDATE_DRM_SETTINGS_KEY: 'v1/content/drm_settings',
          DELETE_DRM_SETTINGS_KEY: 'v1/content/drm_settings',
          GET_DRM_SETTINGS_KEY: 'v1/content/drm',
        },
        TOKEN_SETTINGS: {
          UPDATE_TOKEN_SETTINGS: 'v1/content/tokensettings',
          GET_TOKEN_SETTINGS: 'v1/content/tokensettings',
        },
        IRDETO: {
          ADD_IRDETO: 'v1/content/drm_irdeto',
          GET_DEATAILS_IRDETO: 'v1/content/drm_irdeto',
          UPDATE_IRDETO: 'v1/content/drm_irdeto',
        },
      },
      //CURRENCY SETTINGS
      CURRENCY_SETTING: {},
    },
    //DATASTORE ENDPOINTS
    Datastore: {
      ADD_DATASTORE: 'v1/datastores',
      GET_ALL_CRM_LIST: 'v1/datastores/all_crm_list',
      ADD_DATASTORE_WITH_COMPANY_DATA: 'v1/datastores/add_datastore_with_company_info',
      ADD_NEW_SERVICE_AND_COMPANY_DATA_WITH_CMS: 'v1/datastores/add_new_service_and_company_info_with_cms',
      DELETE_DATASTORE_SERVICE: 'v1/datastores/cms_service',
      DATASTORE_NAME_UNIQUENESS_CHECK: 'v1/datastores/check_for_datastore_name_uniqueness',
      GET_LIST_OF_AUTHORISED_DATASTORE_USERS: 'v1/datastores/get_associated_users_with_datastore',
      GET_CRM_DATASTORE_CUSTOMER_STATS: 'v1/datastores/crm/customer_stats',
    },
    Common: {
      // PUBLISH: {
      //   PACKAGE: 'v1/common/publish/package',
      // },
      TRANSLATE_TEXT: 'v1/common/translate_text',
      BASE_MENUS_LIST: 'v1/common/base_menus/list',

      FETCH_COUNTRIES: 'v1/common/countries',
      FETCH_CITIES: 'v1/common/cities',
      FETCH_CURRENCIES: 'v1/common/currencies/list',
      FETCH_LANGUAGES: 'v1/common/languages',

      UPLOAD_IMAGE: 'v1/common/upload_image',
      UPLOAD_FILE: 'v1/common/upload_file',
      UPLOAD_OBJECTSTORAGE: 'v1/common/upload_objectstorage',

    },

    APB: {
      BILLING: {
        TRANSLATIONS: {
          INSTALL: 'v1/billing/translation/install',
          RESET: 'v1/billing/translation/reset',
          EDIT: 'v1/billing/translation/edit',
        },
      },
      CONTENT: {
        CONTENT: {
          CHANNELS: {
            LIST: 'v1/appbuilder/content/content/channels/list',
          },
          MOVIES: {
            LIST: 'v1/appbuilder/content/content/movies/list',
          },
          SERIES: {
            LIST: 'v1/appbuilder/content/content/series/list',
          },
          SEASONS: {
            LIST: 'v1/appbuilder/content/content/seasons/list',
          },
          EPISODES: {
            LIST: 'v1/appbuilder/content/content/episodes/list',
          },
          COURSES: {
            LIST: 'v1/appbuilder/content/content/courses/list',
          },
          LESSONS: {
            LIST: 'v1/appbuilder/content/content/lessons/list',
          },
          ALBUMS: {
            LIST: 'v1/appbuilder/content/content/albums/list',
          },
          PODCASTS: {
            LIST: 'v1/appbuilder/content/content/podcasts/list',
          },
          APPS: {
            LIST: 'v1/appbuilder/content/content/apps/list',
          },
          PROGRAMS: {
            LIST: 'v1/appbuilder/content/content/programs/list',
          },
          CAMS: {
            LIST: 'v1/appbuilder/content/content/cams/list',
          },
        },
        CHANNELLIST: {
          LIST: 'v1/appbuilder/content/list/channels/list',
          DETAILS: 'v1/appbuilder/content/channels',
          UPDATE: 'v1/appbuilder/content/channels',
          ADD: 'v1/appbuilder/content/channels',
          DELETE: 'v1/appbuilder/content/channels',
        },
        CAMLIST: {
          LIST: 'v1/appbuilder/content/list/cams/list',
          DETAILS: 'v1/appbuilder/content/cams',
          UPDATE: 'v1/appbuilder/content/cams',
          ADD: 'v1/appbuilder/content/cams',
          DELETE: 'v1/appbuilder/content/cams',
        },
        MOVIELIST: {
          LIST: 'v1/appbuilder/content/list/movies/list',
          DETAILS: 'v1/appbuilder/content/movies',
          UPDATE: 'v1/appbuilder/content/movies',
          ADD: 'v1/appbuilder/content/movies',
          DELETE: 'v1/appbuilder/content/movies',
        },
        SERIESLIST: {
          LIST: 'v1/appbuilder/content/list/series/list',
          DETAILS: 'v1/appbuilder/content/series',
          UPDATE: 'v1/appbuilder/content/series',
          ADD: 'v1/appbuilder/content/series',
          DELETE: 'v1/appbuilder/content/series',
        },
        SEASONLIST: {
          LIST: 'v1/appbuilder/content/list/seasons/list',
          DETAILS: 'v1/appbuilder/content/seasons',
          UPDATE: 'v1/appbuilder/content/seasons',
          ADD: 'v1/appbuilder/content/seasons',
          DELETE: 'v1/appbuilder/content/seasons',
        },
        EPISODELIST: {
          LIST: 'v1/appbuilder/content/list/episodes/list',
          DETAILS: 'v1/appbuilder/content/episodes',
          UPDATE: 'v1/appbuilder/content/episodes',
          ADD: 'v1/appbuilder/content/episodes',
          DELETE: 'v1/appbuilder/content/episodes',
        },
        COURSESLIST: {
          LIST: 'v1/appbuilder/content/list/courses/list',
          DETAILS: 'v1/appbuilder/content/courses',
          UPDATE: 'v1/appbuilder/content/courses',
          ADD: 'v1/appbuilder/content/courses',
          DELETE: 'v1/appbuilder/content/courses',
        },
        COURSELIST: {
          LIST: 'v1/appbuilder/content/list/courses/list',
          DETAILS: 'v1/appbuilder/content/courses',
          UPDATE: 'v1/appbuilder/content/courses',
          ADD: 'v1/appbuilder/content/courses',
          DELETE: 'v1/appbuilder/content/courses',
        },
        LESSONLIST: {
          LIST: 'v1/appbuilder/content/list/lessons/list',
          DETAILS: 'v1/appbuilder/content/lessons',
          UPDATE: 'v1/appbuilder/content/lessons',
          ADD: 'v1/appbuilder/content/lessons',
          DELETE: 'v1/appbuilder/content/lessons',
        },
        ALBUMLIST: {
          LIST: 'v1/appbuilder/content/list/albums/list',
          DETAILS: 'v1/appbuilder/content/albums',
          UPDATE: 'v1/appbuilder/content/albums',
          ADD: 'v1/appbuilder/content/albums',
          DELETE: 'v1/appbuilder/content/albums',
        },
        PODCASTLIST: {
          LIST: 'v1/appbuilder/content/list/podcasts/list',
          DETAILS: 'v1/appbuilder/content/podcasts',
          UPDATE: 'v1/appbuilder/content/podcasts',
          ADD: 'v1/appbuilder/content/podcasts',
          DELETE: 'v1/appbuilder/content/podcasts',
        },
        APPLIST: {
          LIST: 'v1/appbuilder/content/list/apps/list',
          DETAILS: 'v1/appbuilder/content/apps',
          UPDATE: 'v1/appbuilder/content/apps',
          ADD: 'v1/appbuilder/content/apps',
          DELETE: 'v1/appbuilder/content/apps',
        },
        PROGRAMLIST: {
          LIST: 'v1/appbuilder/content/list/programs/list',
          DETAILS: 'v1/appbuilder/content/programs',
          UPDATE: 'v1/appbuilder/content/programs',
          ADD: 'v1/appbuilder/content/programs',
          DELETE: 'v1/appbuilder/content/programs',
        },
      },
      AUTHENTICATION: {
        BRANDING: {
          LIST: 'v1/appbuilder/authentication/branding/list',
          DETAILS: 'v1/appbuilder/authentication/branding',
          UPDATE: 'v1/appbuilder/authentication/branding',
          ADD: 'v1/appbuilder/authentication/branding',
          DELETE: 'v1/appbuilder/authentication/branding',
        },
        SETTINGS: {
          ADD: 'v1/appbuilder/authentication/settings',
          DETAILS: 'v1/appbuilder/authentication/settings',
          UPDATE: 'v1/appbuilder/authentication/settings',
        },
        FORGOT: {
          ADD: 'v1/appbuilder/authentication/forgot',
          DETAILS: 'v1/appbuilder/authentication/forgot',
          UPDATE: 'v1/appbuilder/authentication/forgot',
        },
        CHECK: {
          ADD: 'v1/appbuilder/authentication/check',
          DETAILS: 'v1/appbuilder/authentication/check',
          UPDATE: 'v1/appbuilder/authentication/check',
        },
        DISCLAIMER: {
          ADD: 'v1/appbuilder/authentication/disclaimer',
          DETAILS: 'v1/appbuilder/authentication/disclaimer',
          UPDATE: 'v1/appbuilder/authentication/disclaimer',
        },
        SIGNIN: {
          ADD: 'v1/appbuilder/authentication/signin',
          DETAILS: 'v1/appbuilder/authentication/signin',
          UPDATE: 'v1/appbuilder/authentication/signin',
        },
        SIGNUP: {
          ADD: 'v1/appbuilder/authentication/signup',
          DETAILS: 'v1/appbuilder/authentication/signup',
          UPDATE: 'v1/appbuilder/authentication/signup',
        },
        LANGUAGES: {
          ADD: 'v1/appbuilder/authentication/languages',
          DETAILS: 'v1/appbuilder/authentication/languages',
          UPDATE: 'v1/appbuilder/authentication/languages',
        },
        SERVICES: {
          ADD: 'v1/appbuilder/authentication/services',
          DETAILS: 'v1/appbuilder/authentication/services',
          UPDATE: 'v1/appbuilder/authentication/services',
        },
      },
      BUILDER: {
        ELEMENTS: {
          DETAILS: 'v1/appbuilder/elements/all',
          LIST: 'v1/appbuilder/elements/list',
        },
      },
      HERO: {
        SINGLE: {
          LIST: 'v1/appbuilder/hero/single/list',
          DETAILS: 'v1/appbuilder/hero/single',
          UPDATE: 'v1/appbuilder/hero/single',
          ADD: 'v1/appbuilder/hero/single',
          DELETE: 'v1/appbuilder/hero/single',
        },
        SLIDER: {
          LIST: 'v1/appbuilder/hero/slider/list',
          DETAILS: 'v1/appbuilder/hero/slider',
          UPDATE: 'v1/appbuilder/hero/slider',
          ADD: 'v1/appbuilder/hero/slider',
          DELETE: 'v1/appbuilder/hero/slider',
        },

        PLAYER: {
          LIST: 'v1/appbuilder/hero/player/list',
          DETAILS: 'v1/appbuilder/hero/player',
          UPDATE: 'v1/appbuilder/hero/player',
          ADD: 'v1/appbuilder/hero/player',
          DELETE: 'v1/appbuilder/hero/player',
        },
      },
      LANGUAGES: {
        LIST: 'v1/appbuilder/languages/general_languages/list',
        DETAILS: 'v1/appbuilder/languages/general_languages',
        UPDATE: 'v1/appbuilder/languages/general_languages',
        ADD: 'v1/appbuilder/languages/general_languages',
        DELETE: 'v1/appbuilder/languages/general_languages',
      },
      AGERATING: {
        LIST: 'v1/appbuilder/ageratings/age_rating/list',
        DETAILS: 'v1/appbuilder/ageratings/age_rating',
        UPDATE: 'v1/appbuilder/ageratings/age_rating',
        ADD: 'v1/appbuilder/ageratings/age_rating',
        DELETE: 'v1/appbuilder/ageratings/age_rating',
      },
      TEMPLATESAPP: {
        LIST: 'v1/appbuilder/templates/templates_app/list',
        DETAILS: 'v1/appbuilder/templates/templates_app',
        UPDATE: 'v1/appbuilder/templates/templates_app',
        ADD: 'v1/appbuilder/templates/templates_app',
        DELETE: 'v1/appbuilder/templates/templates_app',
      },
      TEMPLATESAUTH: {
        LIST: 'v1/appbuilder/templates/templates_auth/list',
        DETAILS: 'v1/appbuilder/templates/templates_auth',
        UPDATE: 'v1/appbuilder/templates/templates_auth',
        ADD: 'v1/appbuilder/templates/templates_auth',
        DELETE: 'v1/appbuilder/templates/templates_auth',
      },
      ELEMENTS: {
        LIST: 'v1/appbuilder/templates/elements/list',
        DETAILS: 'v1/appbuilder/templates/elements',
        UPDATE: 'v1/appbuilder/templates/elements',
        ADD: 'v1/appbuilder/templates/elements',
        DELETE: 'v1/appbuilder/templates/elements',
      },
      USERINTERFACES: {
        LIST: 'v1/appbuilder/templates/userinterfaces/list',
        DETAILS: 'v1/appbuilder/templates/userinterfaces',
        UPDATE: 'v1/appbuilder/templates/userinterfaces',
        ADD: 'v1/appbuilder/templates/userinterfaces',
        ADDTEMPLATE: 'v1/appbuilder/templates/userinterfaces/template',
        DELETE: 'v1/appbuilder/templates/userinterfaces',
      },
      AUTHFLOWS: {
        LIST: 'v1/appbuilder/templates/authflows/list',
        DETAILS: 'v1/appbuilder/templates/authflows',
        UPDATE: 'v1/appbuilder/templates/authflows',
        ADD: 'v1/appbuilder/templates/authflows',
        ADDTEMPLATE: 'v1/appbuilder/templates/authflows/template',
        DELETE: 'v1/appbuilder/templates/authflows',
      },
      CONVERT: {
        CONVERT: 'v1/appbuilder/templates/convert',
      },
      INSTALL: {
        INSTALL: 'v1/appbuilder/templates/install',
      },
      APPLICATIONS: {
        LANG: {
          LIST: 'v1/appbuilder/applications/lang/list',
        },
        MENUS: {
          LIST: 'v1/appbuilder/applications/menus/list',
        },
        APPLICATION: {
          LISTAPPS: 'v1/appbuilder/applications/applications/listapps',
          LIST: 'v1/appbuilder/applications/applications/list',
          LISTJSON: 'v1/appbuilder/applications/applications/listjsons',
          LISTDIR: 'v1/appbuilder/applications/applications/listdirs',
          DETAILS: 'v1/appbuilder/applications/applications',
          DETAILSJSON: 'v1/appbuilder/applications/applications/json',
          UPDATE: 'v1/appbuilder/applications/applications',
          ADD: 'v1/appbuilder/applications/applications',
          DELETE: 'v1/appbuilder/applications/applications',
          PUBLISH: 'v1/appbuilder/applications/publish',
        },
        MESSAGES: {
          LIST: 'v1/appbuilder/applications/messages/list',
          DETAILS: 'v1/appbuilder/applications/messages',
          UPDATE: 'v1/appbuilder/applications/messages',
          ADD: 'v1/appbuilder/applications/messages',
          DELETE: 'v1/appbuilder/applications/messages',
          PUBLISH: 'v1/appbuilder/applications/messages/publish',
        },
        TRANSLATIONS: {
          OVERIDE: {
            ADD: 'v1/appbuilder/applications/translation/overide/add',
            UPDATE: 'v1/appbuilder/applications/translation/overide/update',
            DETAILS: 'v1/appbuilder/applications/translation/overide/details',
            DELETE: 'v1/appbuilder/applications/translation/overide/delete',
            LIST: 'v1/appbuilder/applications/translation/overide/list',
          },
          INSTALL: 'v1/appbuilder/applications/translation/install',
          RESET: 'v1/appbuilder/applications/translation/reset',
          EDIT: 'v1/appbuilder/applications/translation/edit',
        },
        AGERATING: {
          LIST: 'v1/appbuilder/applications/ageratings/list',
          DETAILS: 'v1/appbuilder/applications/ageratings',
          UPDATE: 'v1/appbuilder/applications/ageratings',
          ADD: 'v1/appbuilder/applications/ageratings',
          DELETE: 'v1/appbuilder/applications/ageratings',
          PUBLISH: 'v1/appbuilder/applications/ageratings/publish',
        },
        DISCLAIMER: {
          LIST: 'v1/appbuilder/applications/disclaimer/list',
          DETAILS: 'v1/appbuilder/applications/disclaimer',
          UPDATE: 'v1/appbuilder/applications/disclaimer',
          ADD: 'v1/appbuilder/applications/disclaimer',
          DELETE: 'v1/appbuilder/applications/disclaimer',
          PUBLISH: 'v1/appbuilder/applications/disclaimer/publish',
        },
        APPUPDATE: {
          LIST: 'v1/appbuilder/applications/appupdate/list',
          DETAILS: 'v1/appbuilder/applications/appupdate',
          UPDATE: 'v1/appbuilder/applications/appupdate',
          ADD: 'v1/appbuilder/applications/appupdate',
          DELETE: 'v1/appbuilder/applications/appupdate',
          PUBLISH: 'v1/appbuilder/applications/appupdate/publish',
        },
        LOGIN: {
          LIST: 'v1/appbuilder/applications/login/list',
          DETAILS: 'v1/appbuilder/applications/login',
          UPDATE: 'v1/appbuilder/applications/login',
          ADD: 'v1/appbuilder/applications/login',
          DELETE: 'v1/appbuilder/applications/login',
          PUBLISH: 'v1/appbuilder/applications/login/publish',
        },
        REGISTRATION: {
          LIST: 'v1/appbuilder/applications/registration/list',
          DETAILS: 'v1/appbuilder/applications/registration',
          UPDATE: 'v1/appbuilder/applications/registration',
          ADD: 'v1/appbuilder/applications/registration',
          DELETE: 'v1/appbuilder/applications/registration',
          PUBLISH: 'v1/appbuilder/applications/registration/publish',
        },
        SERVICES: {
          LIST: 'v1/appbuilder/applications/services/list',
          DETAILS: 'v1/appbuilder/applications/services',
          UPDATE: 'v1/appbuilder/applications/services',
          ADD: 'v1/appbuilder/applications/services',
          DELETE: 'v1/appbuilder/applications/services',
          PUBLISH: 'v1/appbuilder/applications/services/publish',
        },
        PLAYER: {
          CONTROL: {
            ADD: 'v1/appbuilder/applications/player/control',
            DETAILS: 'v1/appbuilder/applications/player/control',
            UPDATE: 'v1/appbuilder/applications/player/control',
          },
        },
        HOME: {
          CONTENT: {
            ADD: 'v1/appbuilder/applications/home/content',
            DETAILS: 'v1/appbuilder/applications/home/content',
            UPDATE: 'v1/appbuilder/applications/home/content',
          },
          COLUMN: {
            ADD: 'v1/appbuilder/applications/home/column',
            DETAILS: 'v1/appbuilder/applications/home/column',
            UPDATE: 'v1/appbuilder/applications/home/column',
          },
          ROW: {
            ORDER: 'v1/appbuilder/applications/home/row/order',
            ADD: 'v1/appbuilder/applications/home/row',
            DETAILS: 'v1/appbuilder/applications/home/row',
            UPDATE: 'v1/appbuilder/applications/home/row',
            DELETE: 'v1/appbuilder/applications/home/row',
            LIST: 'v1/appbuilder/applications/home/row/list',
          },
          SETTINGS: {
            ADD: 'v1/appbuilder/applications/home/settings',
            DETAILS: 'v1/appbuilder/applications/home/settings',
            UPDATE: 'v1/appbuilder/applications/home/settings',
          },
          HEADER: {
            ADD: 'v1/appbuilder/applications/home/header',
            DETAILS: 'v1/appbuilder/applications/home/header',
            UPDATE: 'v1/appbuilder/applications/home/header',
          },
        },
        MISC: {
          MESSAGES: {
            ADD: 'v1/appbuilder/applications/misc/messages',
            DETAILS: 'v1/appbuilder/applications/misc/messages',
            UPDATE: 'v1/appbuilder/applications/misc/messages',
          },
          SUPPORT: {
            ADD: 'v1/appbuilder/applications/misc/support',
            DETAILS: 'v1/appbuilder/applications/misc/support',
            UPDATE: 'v1/appbuilder/applications/misc/support',
          },
          SETTINGS: {
            ADD: 'v1/appbuilder/applications/misc/settings',
            DETAILS: 'v1/appbuilder/applications/misc/settings',
            UPDATE: 'v1/appbuilder/applications/misc/settings',
          },
          PROFILES: {
            ADD: 'v1/appbuilder/applications/misc/profiles',
            DETAILS: 'v1/appbuilder/applications/misc/profiles',
            UPDATE: 'v1/appbuilder/applications/misc/profiles',
          },
        },
        SETTINGS: {
          ADD: 'v1/appbuilder/applications/settings',
          DETAILS: 'v1/appbuilder/applications/settings',
          UPDATE: 'v1/appbuilder/applications/settings',
        },
        NAVIGATION: {
          TELEVISION: {
            ADD: 'v1/appbuilder/applications/navigation/television',
            DETAILS: 'v1/appbuilder/applications/navigation/television',
            UPDATE: 'v1/appbuilder/applications/navigation/television',
          },
          PHONE: {
            ADD: 'v1/appbuilder/applications/navigation/phone',
            DETAILS: 'v1/appbuilder/applications/navigation/phone',
            UPDATE: 'v1/appbuilder/applications/navigation/phone',
          },
          BROWSER: {
            ADD: 'v1/appbuilder/applications/navigation/browser',
            DETAILS: 'v1/appbuilder/applications/navigation/browser',
            UPDATE: 'v1/appbuilder/applications/navigation/browser',
          },
          TABLET: {
            ADD: 'v1/appbuilder/applications/navigation/tablet',
            DETAILS: 'v1/appbuilder/applications/navigation/tablet',
            UPDATE: 'v1/appbuilder/applications/navigation/tablet',
          },
        },

        CONTENT: {
          ALBUMS: {
            ADD: 'v1/appbuilder/applications/content/albums',
            DETAILS: 'v1/appbuilder/applications/content/albums',
            UPDATE: 'v1/appbuilder/applications/content/albums',
          },
          ALBUM_DETAILS: {
            ADD: 'v1/appbuilder/applications/content/album_details',
            DETAILS: 'v1/appbuilder/applications/content/album_details',
            UPDATE: 'v1/appbuilder/applications/content/album_details',
          },
          ALBUM_PLAYER: {
            ADD: 'v1/appbuilder/applications/content/album_player',
            DETAILS: 'v1/appbuilder/applications/content/album_player',
            UPDATE: 'v1/appbuilder/applications/content/album_player',
          },
          APPS: {
            ADD: 'v1/appbuilder/applications/content/apps',
            DETAILS: 'v1/appbuilder/applications/content/apps',
            UPDATE: 'v1/appbuilder/applications/content/apps',
          },
          CAMS: {
            ADD: 'v1/appbuilder/applications/content/cams',
            DETAILS: 'v1/appbuilder/applications/content/cams',
            UPDATE: 'v1/appbuilder/applications/content/cams',
          },
          CATCHUPTV: {
            ADD: 'v1/appbuilder/applications/content/catchuptv',
            DETAILS: 'v1/appbuilder/applications/content/catchuptv',
            UPDATE: 'v1/appbuilder/applications/content/catchuptv',
          },
          CHANNELS: {
            ADD: 'v1/appbuilder/applications/content/channels',
            DETAILS: 'v1/appbuilder/applications/content/channels',
            UPDATE: 'v1/appbuilder/applications/content/channels',
          },
          EDUCACTION_STORES: {
            ADD: 'v1/appbuilder/applications/content/courses_stores',
            DETAILS: 'v1/appbuilder/applications/content/courses_stores',
            UPDATE: 'v1/appbuilder/applications/content/courses_stores',
          },
          EDUCACTION_DETAILS: {
            ADD: 'v1/appbuilder/applications/content/courses_details',
            DETAILS: 'v1/appbuilder/applications/content/courses_details',
            UPDATE: 'v1/appbuilder/applications/content/courses_details',
          },
          MOVIE_STORES: {
            ADD: 'v1/appbuilder/applications/content/movie_stores',
            DETAILS: 'v1/appbuilder/applications/content/movie_stores',
            UPDATE: 'v1/appbuilder/applications/content/movie_stores',
          },
          MOVIE_DETAILS: {
            ADD: 'v1/appbuilder/applications/content/movie_details',
            DETAILS: 'v1/appbuilder/applications/content/movie_details',
            UPDATE: 'v1/appbuilder/applications/content/movie_details',
          },
          MOVIE_CATEGORIES: {
            ADD: 'v1/appbuilder/applications/content/movie_categories',
            DETAILS: 'v1/appbuilder/applications/content/movie_categories',
            UPDATE: 'v1/appbuilder/applications/content/movie_categories',
          },
          PODCASTS: {
            ADD: 'v1/appbuilder/applications/content/podcasts',
            DETAILS: 'v1/appbuilder/applications/content/podcasts',
            UPDATE: 'v1/appbuilder/applications/content/podcasts',
          },
          PODCAST_DETAILS: {
            ADD: 'v1/appbuilder/applications/content/podcast_details',
            DETAILS: 'v1/appbuilder/applications/content/podcast_details',
            UPDATE: 'v1/appbuilder/applications/content/podcast_details',
          },
          PODCAST_PLAYER: {
            ADD: 'v1/appbuilder/applications/content/podcast_player',
            DETAILS: 'v1/appbuilder/applications/content/podcast_player',
            UPDATE: 'v1/appbuilder/applications/content/podcast_player',
          },
          PROGRAMS: {
            ADD: 'v1/appbuilder/applications/content/programs',
            DETAILS: 'v1/appbuilder/applications/content/programs',
            UPDATE: 'v1/appbuilder/applications/content/programs',
          },
          RECORDINGS: {
            ADD: 'v1/appbuilder/applications/content/recordings',
            DETAILS: 'v1/appbuilder/applications/content/recordings',
            UPDATE: 'v1/appbuilder/applications/content/recordings',
          },
          SEARCH: {
            ADD: 'v1/appbuilder/applications/content/search',
            DETAILS: 'v1/appbuilder/applications/content/search',
            UPDATE: 'v1/appbuilder/applications/content/search',
          },
          SERIES_STORES: {
            ADD: 'v1/appbuilder/applications/content/series_stores',
            DETAILS: 'v1/appbuilder/applications/content/series_stores',
            UPDATE: 'v1/appbuilder/applications/content/series_stores',
          },
          SERIES_DETAILS: {
            ADD: 'v1/appbuilder/applications/content/series_details',
            DETAILS: 'v1/appbuilder/applications/content/series_details',
            UPDATE: 'v1/appbuilder/applications/content/series_details',
          },
          TVGUIDE: {
            ADD: 'v1/appbuilder/applications/content/tvguide',
            DETAILS: 'v1/appbuilder/applications/content/tvguide',
            UPDATE: 'v1/appbuilder/applications/content/tvguide',
          },
        },
      },
      COMPONENTS: {
        ANIMATOR: {
          LIST: 'v1/appbuilder/components/animator/list',
          DETAILS: 'v1/appbuilder/components/animator',
          UPDATE: 'v1/appbuilder/components/animator',
          ADD: 'v1/appbuilder/components/animator',
          ADDTEMPLATE: 'v1/appbuilder/components/animator/template',
          DELETE: 'v1/appbuilder/components/animator',
        },
        BUTTON: {
          LIST: 'v1/appbuilder/components/button/list',
          DETAILS: 'v1/appbuilder/components/button',
          UPDATE: 'v1/appbuilder/components/button',
          ADD: 'v1/appbuilder/components/button',
          ADDTEMPLATE: 'v1/appbuilder/components/button/template',
          DELETE: 'v1/appbuilder/components/button',
        },
        CARD: {
          LIST: 'v1/appbuilder/components/card/list',
          DETAILS: 'v1/appbuilder/components/card',
          UPDATE: 'v1/appbuilder/components/card',
          ADD: 'v1/appbuilder/components/card',
          ADDTEMPLATE: 'v1/appbuilder/components/card/template',
          DELETE: 'v1/appbuilder/components/card',
        },
        INPUT: {
          LIST: 'v1/appbuilder/components/input/list',
          DETAILS: 'v1/appbuilder/components/input',
          UPDATE: 'v1/appbuilder/components/input',
          ADD: 'v1/appbuilder/components/input',
          ADDTEMPLATE: 'v1/appbuilder/components/input/template',
          DELETE: 'v1/appbuilder/components/input',
        },
        KEYBOARD: {
          LIST: 'v1/appbuilder/components/keyboard/list',
          DETAILS: 'v1/appbuilder/components/keyboard',
          UPDATE: 'v1/appbuilder/components/keyboard',
          ADD: 'v1/appbuilder/components/keyboard',
          ADDTEMPLATE: 'v1/appbuilder/components/keyboard/template',
          DELETE: 'v1/appbuilder/components/keyboard',
        },
        MESSAGE: {
          LIST: 'v1/appbuilder/components/message/list',
          DETAILS: 'v1/appbuilder/components/message',
          UPDATE: 'v1/appbuilder/components/message',
          ADD: 'v1/appbuilder/components/message',
          ADDTEMPLATE: 'v1/appbuilder/components/message/template',
          DELETE: 'v1/appbuilder/components/message',
        },
        MODAL: {
          LIST: 'v1/appbuilder/components/modal/list',
          DETAILS: 'v1/appbuilder/components/modal',
          UPDATE: 'v1/appbuilder/components/modal',
          ADD: 'v1/appbuilder/components/modal',
          ADDTEMPLATE: 'v1/appbuilder/components/modal/template',
          DELETE: 'v1/appbuilder/components/modal',
        },
        PICKER: {
          LIST: 'v1/appbuilder/components/picker/list',
          DETAILS: 'v1/appbuilder/components/picker',
          UPDATE: 'v1/appbuilder/components/picker',
          ADD: 'v1/appbuilder/components/picker',
          ADDTEMPLATE: 'v1/appbuilder/components/picker/template',
          DELETE: 'v1/appbuilder/components/picker',
        },
        SWITCH: {
          LIST: 'v1/appbuilder/components/switch/list',
          DETAILS: 'v1/appbuilder/components/switch',
          UPDATE: 'v1/appbuilder/components/switch',
          ADD: 'v1/appbuilder/components/switch',
          ADDTEMPLATE: 'v1/appbuilder/components/switch/template',
          DELETE: 'v1/appbuilder/components/switch',
        },
        TEXT: {
          LIST: 'v1/appbuilder/components/text/list',
          DETAILS: 'v1/appbuilder/components/text',
          UPDATE: 'v1/appbuilder/components/text',
          ADD: 'v1/appbuilder/components/text',
          ADDTEMPLATE: 'v1/appbuilder/components/text/template',
          DELETE: 'v1/appbuilder/components/text',
        },
      },
      CONTROLS: {
        BAR: {
          LIST: 'v1/appbuilder/controls/details/list',
          DETAILS: 'v1/appbuilder/controls/details',
          UPDATE: 'v1/appbuilder/controls/details',
          ADD: 'v1/appbuilder/controls/details',
          ADDTEMPLATE: 'v1/appbuilder/controls/details/template',
          DELETE: 'v1/appbuilder/controls/details',
        },
      },
      NAVIGATORS: {
        DRAWER: {
          LIST: 'v1/appbuilder/navigators/drawer/list',
          DETAILS: 'v1/appbuilder/navigators/drawer',
          UPDATE: 'v1/appbuilder/navigators/drawer',
          ADD: 'v1/appbuilder/navigators/drawer',
          ADDTEMPLATE: 'v1/appbuilder/navigators/drawer/template',
          DELETE: 'v1/appbuilder/navigators/drawer',
        },
        TABBAR: {
          LIST: 'v1/appbuilder/navigators/tabbar/list',
          DETAILS: 'v1/appbuilder/navigators/tabbar',
          UPDATE: 'v1/appbuilder/navigators/tabbar',
          ADD: 'v1/appbuilder/navigators/tabbar',
          ADDTEMPLATE: 'v1/appbuilder/navigators/tabbar/template',
          DELETE: 'v1/appbuilder/navigators/tabbar',
        },
        MENUBAR: {
          LIST: 'v1/appbuilder/navigators/menubar/list',
          DETAILS: 'v1/appbuilder/navigators/menubar',
          UPDATE: 'v1/appbuilder/navigators/menubar',
          ADD: 'v1/appbuilder/navigators/menubar',
          ADDTEMPLATE: 'v1/appbuilder/navigators/menubar/template',
          DELETE: 'v1/appbuilder/navigators/menubar',
        },
      },
      PLAYERS: {
        CONTROLS: {
          LIST: 'v1/appbuilder/player/controls/list',
          DETAILS: 'v1/appbuilder/player/controls',
          UPDATE: 'v1/appbuilder/player/controls',
          ADD: 'v1/appbuilder/player/controls',
          ADDTEMPLATE: 'v1/appbuilder/player/controls/template',
          DELETE: 'v1/appbuilder/player/controls',
        },
      },
      PLAYER: {
        AUDIO: {
          LIST: 'v1/appbuilder/player/audio/list',
          DETAILS: 'v1/appbuilder/player/audio',
          UPDATE: 'v1/appbuilder/player/audio',
          ADD: 'v1/appbuilder/player/audio',
          ADDTEMPLATE: 'v1/appbuilder/player/audio/template',
          DELETE: 'v1/appbuilder/player/audio',
        },
        SLEEK: {
          LIST: 'v1/appbuilder/player/sleek/list',
          DETAILS: 'v1/appbuilder/player/sleek',
          UPDATE: 'v1/appbuilder/player/sleek',
          ADD: 'v1/appbuilder/player/sleek',
          ADDTEMPLATE: 'v1/appbuilder/player/sleek/template',
          DELETE: 'v1/appbuilder/player/sleek',
        },
        MINIMAL: {
          LIST: 'v1/appbuilder/player/minimal/list',
          DETAILS: 'v1/appbuilder/player/minimal',
          UPDATE: 'v1/appbuilder/player/minimal',
          ADD: 'v1/appbuilder/player/minimal',
          ADDTEMPLATE: 'v1/appbuilder/player/minimal/template',
          DELETE: 'v1/appbuilder/player/minimal',
        },
        CENTRIC: {
          LIST: 'v1/appbuilder/player/centric/list',
          DETAILS: 'v1/appbuilder/player/centric',
          UPDATE: 'v1/appbuilder/player/centric',
          ADD: 'v1/appbuilder/player/centric',
          ADDTEMPLATE: 'v1/appbuilder/player/centric/template',
          DELETE: 'v1/appbuilder/player/centric',
        },
        BIGBUTTONS: {
          LIST: 'v1/appbuilder/player/bigbuttons/list',
          DETAILS: 'v1/appbuilder/player/bigbuttons',
          UPDATE: 'v1/appbuilder/player/bigbuttons',
          ADD: 'v1/appbuilder/player/bigbuttons',
          ADDTEMPLATE: 'v1/appbuilder/player/bigbuttons/template',
          DELETE: 'v1/appbuilder/player/bigbuttons',
        },
      },
      MISC: {
        DVRPROGRESS: {
          LIST: 'v1/appbuilder/misc/dvrprogress/list',
          DETAILS: 'v1/appbuilder/misc/dvrprogress',
          UPDATE: 'v1/appbuilder/misc/dvrprogress',
          ADD: 'v1/appbuilder/misc/dvrprogress',
          ADDTEMPLATE: 'v1/appbuilder/misc/dvrprogress/template',
          DELETE: 'v1/appbuilder/misc/dvrprogress',
        },
        DETAILSBAR: {
          LIST: 'v1/appbuilder/misc/detailsbar/list',
          DETAILS: 'v1/appbuilder/misc/detailsbar',
          UPDATE: 'v1/appbuilder/misc/detailsbar',
          ADD: 'v1/appbuilder/misc/detailsbar',
          ADDTEMPLATE: 'v1/appbuilder/misc/detailsbar/template',
          DELETE: 'v1/appbuilder/misc/detailsbar',
        },
        LINGUIX: {
          LIST: 'v1/appbuilder/misc/linguix/list',
          DETAILS: 'v1/appbuilder/misc/linguix',
          UPDATE: 'v1/appbuilder/misc/linguix',
          ADD: 'v1/appbuilder/misc/linguix',
          ADDTEMPLATE: 'v1/appbuilder/misc/linguix/template',
          DELETE: 'v1/appbuilder/misc/linguix',
        },
        FILTERBAR: {
          LIST: 'v1/appbuilder/misc/filterbar/list',
          DETAILS: 'v1/appbuilder/misc/filterbar',
          UPDATE: 'v1/appbuilder/misc/filterbar',
          ADD: 'v1/appbuilder/misc/filterbar',
          ADDTEMPLATE: 'v1/appbuilder/misc/filterbar/template',
          DELETE: 'v1/appbuilder/misc/filterbar',
        },
        SEARCHBAR: {
          LIST: 'v1/appbuilder/misc/searchbar/list',
          DETAILS: 'v1/appbuilder/misc/searchbar',
          UPDATE: 'v1/appbuilder/misc/searchbar',
          ADD: 'v1/appbuilder/misc/searchbar',
          ADDTEMPLATE: 'v1/appbuilder/misc/searchbar/template',
          DELETE: 'v1/appbuilder/misc/searchbar',
        },
        LOGOTIME: {
          LIST: 'v1/appbuilder/misc/logotime/list',
          DETAILS: 'v1/appbuilder/misc/logotime',
          UPDATE: 'v1/appbuilder/misc/logotime',
          ADD: 'v1/appbuilder/misc/logotime',
          ADDTEMPLATE: 'v1/appbuilder/misc/logotime/template',
          DELETE: 'v1/appbuilder/misc/logotime',
        },
        WEBVIEW: {
          LIST: 'v1/appbuilder/misc/webview/list',
          DETAILS: 'v1/appbuilder/misc/webview',
          UPDATE: 'v1/appbuilder/misc/webview',
          ADD: 'v1/appbuilder/misc/webview',
          ADDTEMPLATE: 'v1/appbuilder/misc/webview/template',
          DELETE: 'v1/appbuilder/misc/webview',
        },
        LOGOWIDE: {
          LIST: 'v1/appbuilder/misc/logowide/list',
          DETAILS: 'v1/appbuilder/misc/logowide',
          UPDATE: 'v1/appbuilder/misc/logowide',
          ADD: 'v1/appbuilder/misc/logowide', 
          ADDTEMPLATE: 'v1/appbuilder/misc/logowide/template',
          DELETE: 'v1/appbuilder/misc/logowide',
        },
      },
      PROMOTIONS: {
        ADS: {
          LIST: 'v1/appbuilder/promotions/ads/list',
          DETAILS: 'v1/appbuilder/promotions/ads',
          UPDATE: 'v1/appbuilder/promotions/ads',
          ADD: 'v1/appbuilder/promotions/ads',
          ADDTEMPLATE: 'v1/appbuilder/promotions/ads/template',
          DELETE: 'v1/appbuilder/promotions/ads',
        },
        CASTOOLAADS: {
          LIST: 'v1/appbuilder/promotions/castoolaads/list',
          DETAILS: 'v1/appbuilder/promotions/castoolaads',
          UPDATE: 'v1/appbuilder/promotions/castoolaads',
          ADD: 'v1/appbuilder/promotions/castoolaads',
          ADDTEMPLATE: 'v1/appbuilder/promotions/castoolaads/template',
          DELETE: 'v1/appbuilder/promotions/castoolaads',
        },
        CASTOOLAVAST: {
          LIST: 'v1/appbuilder/promotions/castoolavast/list',
          DETAILS: 'v1/appbuilder/promotions/castoolavast',
          UPDATE: 'v1/appbuilder/promotions/castoolavast',
          ADD: 'v1/appbuilder/promotions/castoolavast',
          ADDTEMPLATE: 'v1/appbuilder/promotions/castoolavast/template',
          DELETE: 'v1/appbuilder/promotions/castoolavast',
        },
        CASTOOLAFULLSCREEN: {
          LIST: 'v1/appbuilder/promotions/castoolafullscreen/list',
          DETAILS: 'v1/appbuilder/promotions/castoolafullscreen',
          UPDATE: 'v1/appbuilder/promotions/castoolafullscreen',
          ADD: 'v1/appbuilder/promotions/castoolafullscreen',
          ADDTEMPLATE: 'v1/appbuilder/promotions/castoolafullscreen/template',
          DELETE: 'v1/appbuilder/promotions/castoolafullscreen',
        },
        SURVEY: {
          LIST: 'v1/appbuilder/promotions/survey/list',
          DETAILS: 'v1/appbuilder/promotions/survey',
          UPDATE: 'v1/appbuilder/promotions/survey',
          ADD: 'v1/appbuilder/promotions/survey',
          ADDTEMPLATE: 'v1/appbuilder/promotions/survey/template',
          DELETE: 'v1/appbuilder/promotions/survey',
        },
        KAIADS: {
          LIST: 'v1/appbuilder/promotions/kaiads/list',
          DETAILS: 'v1/appbuilder/promotions/kaiads',
          UPDATE: 'v1/appbuilder/promotions/kaiads',
          ADD: 'v1/appbuilder/promotions/kaiads',
          ADDTEMPLATE: 'v1/appbuilder/promotions/kaiads/template',
          DELETE: 'v1/appbuilder/promotions/kaiads',
        },
        KAIFULLSCREEN: {
          LIST: 'v1/appbuilder/promotions/kaifullscreen/list',
          DETAILS: 'v1/appbuilder/promotions/kaifullscreen',
          UPDATE: 'v1/appbuilder/promotions/kaifullscreen',
          ADD: 'v1/appbuilder/promotions/kaifullscreen',
          ADDTEMPLATE: 'v1/appbuilder/promotions/kaifullscreen/template',
          DELETE: 'v1/appbuilder/promotions/kaifullscreen',
        },
        OTA: {
          LIST: 'v1/appbuilder/promotions/ota/list',
          DETAILS: 'v1/appbuilder/promotions/ota',
          UPDATE: 'v1/appbuilder/promotions/ota',
          ADD: 'v1/appbuilder/promotions/ota',
          ADDTEMPLATE: 'v1/appbuilder/promotions/ota/template',
          DELETE: 'v1/appbuilder/promotions/ota',
        },
        MESSAGES: {
          LIST: 'v1/appbuilder/promotions/messages/list',
          DETAILS: 'v1/appbuilder/promotions/messages',
          UPDATE: 'v1/appbuilder/promotions/messages',
          ADD: 'v1/appbuilder/promotions/messages',
          ADDTEMPLATE: 'v1/appbuilder/promotions/messages/template',
          DELETE: 'v1/appbuilder/promotions/messages',
        },
      },
      NAVIGATION: {
        DROPDOWNS: {
          LIST: 'v1/appbuilder/navigation/dropdown/list',
          DETAILS: 'v1/appbuilder/navigation/dropdown',
          UPDATE: 'v1/appbuilder/navigation/dropdown',
          ADD: 'v1/appbuilder/navigation/dropdown',
          ADDTEMPLATE: 'v1/appbuilder/navigation/dropdown/template',
          DELETE: 'v1/appbuilder/navigation/dropdown',
        },
        MENUS: {
          LIST: 'v1/appbuilder/navigation/menu/list',
          DETAILS: 'v1/appbuilder/navigation/menu',
          UPDATE: 'v1/appbuilder/navigation/menu',
          ADD: 'v1/appbuilder/navigation/menu',
          ADDTEMPLATE: 'v1/appbuilder/navigation/menu/template',
          DELETE: 'v1/appbuilder/navigation/menu',
        },
      },
      GRIDS: {
        TILE: {
          LIST: 'v1/appbuilder/grids/tile/list',
          DETAILS: 'v1/appbuilder/grids/tile',
          UPDATE: 'v1/appbuilder/grids/tile',
          ADD: 'v1/appbuilder/grids/tile',
          ADDTEMPLATE: 'v1/appbuilder/grids/tile/template',
          DELETE: 'v1/appbuilder/grids/tile',
        },
        WIDESCREEN: {
          LIST: 'v1/appbuilder/grids/widescreen/list',
          DETAILS: 'v1/appbuilder/grids/widescreen',
          UPDATE: 'v1/appbuilder/grids/widescreen',
          ADD: 'v1/appbuilder/grids/widescreen',
          ADDTEMPLATE: 'v1/appbuilder/grids/widescreen/template',
          DELETE: 'v1/appbuilder/grids/widescreen',
        },
        PLAYER: {
          LIST: 'v1/appbuilder/grids/player/list',
          DETAILS: 'v1/appbuilder/grids/player',
          UPDATE: 'v1/appbuilder/grids/player',
          ADD: 'v1/appbuilder/grids/player',
          ADDTEMPLATE: 'v1/appbuilder/grids/player/template',
          DELETE: 'v1/appbuilder/grids/player',
        },
        SQUARE: {
          LIST: 'v1/appbuilder/grids/square/list',
          DETAILS: 'v1/appbuilder/grids/square',
          UPDATE: 'v1/appbuilder/grids/square',
          ADD: 'v1/appbuilder/grids/square',
          ADDTEMPLATE: 'v1/appbuilder/grids/square/template',
          DELETE: 'v1/appbuilder/grids/square',
        },
        RECTANGLE: {
          LIST: 'v1/appbuilder/grids/rectangle/list',
          DETAILS: 'v1/appbuilder/grids/rectangle',
          UPDATE: 'v1/appbuilder/grids/rectangle',
          ADD: 'v1/appbuilder/grids/rectangle',
          ADDTEMPLATE: 'v1/appbuilder/grids/rectangle/template',
          DELETE: 'v1/appbuilder/grids/rectangle',
        },
        LONG: {
          LIST: 'v1/appbuilder/grids/long/list',
          DETAILS: 'v1/appbuilder/grids/long',
          UPDATE: 'v1/appbuilder/grids/long',
          ADD: 'v1/appbuilder/grids/long',
          ADDTEMPLATE: 'v1/appbuilder/grids/long/template',
          DELETE: 'v1/appbuilder/grids/long',
        },
      },
      TVGUIDE: {
        TVGUIDE: {
          LIST: 'v1/appbuilder/tvguide/tvguide/list',
          DETAILS: 'v1/appbuilder/tvguide/tvguide',
          UPDATE: 'v1/appbuilder/tvguide/tvguide',
          ADD: 'v1/appbuilder/tvguide/tvguide',
          ADDTEMPLATE: 'v1/appbuilder/tvguide/tvguide/template',
          DELETE: 'v1/appbuilder/tvguide/tvguide',
        },
      },
      CATCHUPTV: {
        CATCHUPTV: {
          LIST: 'v1/appbuilder/catchuptv/catchuptv/list',
          DETAILS: 'v1/appbuilder/catchuptv/catchuptv',
          UPDATE: 'v1/appbuilder/catchuptv/catchuptv',
          ADD: 'v1/appbuilder/catchuptv/catchuptv',
          ADDTEMPLATE: 'v1/appbuilder/catchuptv/catchuptv/template',
          DELETE: 'v1/appbuilder/catchuptv/catchuptv',
        },
      },
      RAILS: {
        TILE: {
          LIST: 'v1/appbuilder/rails/tile/list',
          DETAILS: 'v1/appbuilder/rails/tile',
          UPDATE: 'v1/appbuilder/rails/tile',
          ADD: 'v1/appbuilder/rails/tile',
          ADDTEMPLATE: 'v1/appbuilder/rails/tile/template',
          DELETE: 'v1/appbuilder/rails/tile',
        },
        WIDESCREEN: {
          LIST: 'v1/appbuilder/rails/widescreen/list',
          DETAILS: 'v1/appbuilder/rails/widescreen',
          UPDATE: 'v1/appbuilder/rails/widescreen',
          ADD: 'v1/appbuilder/rails/widescreen',
          ADDTEMPLATE: 'v1/appbuilder/rails/widescreen/template',
          DELETE: 'v1/appbuilder/rails/widescreen',
        },
        PLAYER: {
          LIST: 'v1/appbuilder/rails/player/list',
          DETAILS: 'v1/appbuilder/rails/player',
          UPDATE: 'v1/appbuilder/rails/player',
          ADD: 'v1/appbuilder/rails/player',
          ADDTEMPLATE: 'v1/appbuilder/rails/player/template',
          DELETE: 'v1/appbuilder/rails/player',
        },
        MORPHING: {
          LIST: 'v1/appbuilder/rails/morphing/list',
          DETAILS: 'v1/appbuilder/rails/morphing',
          UPDATE: 'v1/appbuilder/rails/morphing',
          ADD: 'v1/appbuilder/rails/morphing',
          ADDTEMPLATE: 'v1/appbuilder/rails/morphing/template',
          DELETE: 'v1/appbuilder/rails/morphing',
        },
        SQUARE: {
          LIST: 'v1/appbuilder/rails/square/list',
          DETAILS: 'v1/appbuilder/rails/square',
          UPDATE: 'v1/appbuilder/rails/square',
          ADD: 'v1/appbuilder/rails/square',
          ADDTEMPLATE: 'v1/appbuilder/rails/square/template',
          DELETE: 'v1/appbuilder/rails/square',
        },
        TOP10: {
          LIST: 'v1/appbuilder/rails/top10/list',
          DETAILS: 'v1/appbuilder/rails/top10',
          UPDATE: 'v1/appbuilder/rails/top10',
          ADD: 'v1/appbuilder/rails/top10',
          ADDTEMPLATE: 'v1/appbuilder/rails/top10/template',
          DELETE: 'v1/appbuilder/rails/top10',
        },
        GENRE: {
          LIST: 'v1/appbuilder/rails/genre/list',
          DETAILS: 'v1/appbuilder/rails/genre',
          UPDATE: 'v1/appbuilder/rails/genre',
          ADD: 'v1/appbuilder/rails/genre',
          ADDTEMPLATE: 'v1/appbuilder/rails/genre/template',
          DELETE: 'v1/appbuilder/rails/genre',
        },
        MENU: {
          LIST: 'v1/appbuilder/rails/menu/list',
          DETAILS: 'v1/appbuilder/rails/menu',
          UPDATE: 'v1/appbuilder/rails/menu',
          ADD: 'v1/appbuilder/rails/menu',
          ADDTEMPLATE: 'v1/appbuilder/rails/menu/template',
          DELETE: 'v1/appbuilder/rails/menu',
        },
        ROUND: {
          LIST: 'v1/appbuilder/rails/round/list',
          DETAILS: 'v1/appbuilder/rails/round',
          UPDATE: 'v1/appbuilder/rails/round',
          ADD: 'v1/appbuilder/rails/round',
          ADDTEMPLATE: 'v1/appbuilder/rails/round/template',
          DELETE: 'v1/appbuilder/rails/round',
        },
        RECTANGLE: {
          LIST: 'v1/appbuilder/rails/rectangle/list',
          DETAILS: 'v1/appbuilder/rails/rectangle',
          UPDATE: 'v1/appbuilder/rails/rectangle',
          ADD: 'v1/appbuilder/rails/rectangle',
          ADDTEMPLATE: 'v1/appbuilder/rails/rectangle/template',
          DELETE: 'v1/appbuilder/rails/rectangle',
        },
        LONG: {
          LIST: 'v1/appbuilder/rails/long/list',
          DETAILS: 'v1/appbuilder/rails/long',
          UPDATE: 'v1/appbuilder/rails/long',
          ADD: 'v1/appbuilder/rails/long',
          ADDTEMPLATE: 'v1/appbuilder/rails/long/template',
          DELETE: 'v1/appbuilder/rails/long',
        },
      },
      LISTS: {
        EPISODES: {
          LIST: 'v1/appbuilder/lists/episodes/list',
          DETAILS: 'v1/appbuilder/lists/episodes',
          UPDATE: 'v1/appbuilder/lists/episodes',
          ADD: 'v1/appbuilder/lists/episodes',
          ADDTEMPLATE: 'v1/appbuilder/lists/episodes/template',
          DELETE: 'v1/appbuilder/lists/episodes',
        },
        DOWNLOADS: {
          LIST: 'v1/appbuilder/lists/downloads/list',
          DETAILS: 'v1/appbuilder/lists/downloads',
          UPDATE: 'v1/appbuilder/lists/downloads',
          ADD: 'v1/appbuilder/lists/downloads',
          ADDTEMPLATE: 'v1/appbuilder/lists/downloads/template',
          DELETE: 'v1/appbuilder/lists/downloads',
        },
     
      },
      DETAILS: {
        TOP: {
          LIST: 'v1/appbuilder/details/top/list',
          DETAILS: 'v1/appbuilder/details/top',
          UPDATE: 'v1/appbuilder/details/top',
          ADD: 'v1/appbuilder/details/top',
          ADDTEMPLATE: 'v1/appbuilder/details/top/template',
          DELETE: 'v1/appbuilder/details/top',
        },
        BOTTOM: {
          LIST: 'v1/appbuilder/details/bottom/list',
          DETAILS: 'v1/appbuilder/details/bottom',
          UPDATE: 'v1/appbuilder/details/bottom',
          ADD: 'v1/appbuilder/details/bottom',
          ADDTEMPLATE: 'v1/appbuilder/details/bottom/template',
          DELETE: 'v1/appbuilder/details/bottom',
        },
        CENTERED: {
          LIST: 'v1/appbuilder/details/centered/list',
          DETAILS: 'v1/appbuilder/details/centered',
          UPDATE: 'v1/appbuilder/details/centered',
          ADD: 'v1/appbuilder/details/centered',
          ADDTEMPLATE: 'v1/appbuilder/details/centered/template',
          DELETE: 'v1/appbuilder/details/centered',
        },
        MINIMAL: {
          LIST: 'v1/appbuilder/details/minimal/list',
          DETAILS: 'v1/appbuilder/details/minimal',
          UPDATE: 'v1/appbuilder/details/minimal',
          ADD: 'v1/appbuilder/details/minimal',
          ADDTEMPLATE: 'v1/appbuilder/details/minimal/template',
          DELETE: 'v1/appbuilder/details/minimal',
        },
        STANDARD: {
          LIST: 'v1/appbuilder/details/standard/list',
          DETAILS: 'v1/appbuilder/details/standard',
          UPDATE: 'v1/appbuilder/details/standard',
          ADD: 'v1/appbuilder/details/standard',
          ADDTEMPLATE: 'v1/appbuilder/details/standard/template',
          DELETE: 'v1/appbuilder/details/standard',
        },
        AUDIOSTANDARD: {
          LIST: 'v1/appbuilder/details/audiostandard/list',
          DETAILS: 'v1/appbuilder/details/audiostandard',
          UPDATE: 'v1/appbuilder/details/audiostandard',
          ADD: 'v1/appbuilder/details/audiostandard',
          ADDTEMPLATE: 'v1/appbuilder/details/audiostandard/template',
          DELETE: 'v1/appbuilder/details/audiostandard',
        },
      },
      HOSTNAMES: {},
    },

    UIT: {
      // ENDPOINTS
      LANGUAGE: {
        GET_ENGLISH_TEXT: 'v1/uit/languages/get_english_text',
        GET_LANGUAGES_LIST: 'v1/uit/languages',
        GET_LANGUAGE_DETAILS: 'v1/uit/languages',
        UPDATE_LANGUAGE: 'v1/uit/languages',
        UPDATE_LANGUAGE_SERVER: 'v1/uit/languages/update_from_server',
        ADD_LANGUAGE: 'v1/uit/languages',
        DELETE_LANGUAGE: 'v1/uit/languages',
      },
      MENU_APP: {
        GET_MENUAPP_LIST: 'v1/uit/menu_apps/list',
        GET_MENUAPP_DETAILS: 'v1/uit/menu_apps',
        UPDATE_MENUAPP: 'v1/uit/menu_apps',
        ADD_MENUAPP: 'v1/uit/menu_apps',
        DELETE_MENUAPP: 'v1/uit/menu_apps',
      },
      HOSTNAMES: {
        GET_HOSTNAME_LIST: 'v1/uit/hostnames/list',
        GET_HOSTNAME_DETAILS: 'v1/uit/hostnames',
        UPDATE_HOSTNAME: 'v1/uit/hostnames',
        ADD_HOSTNAME: 'v1/uit/hostnames',
        DELETE_HOSTNAME: 'v1/uit/hostnames',
      },
      USER_INTERFACES: {
        GET_UI_LIST: 'v1/uit/user_interfaces/list',
        GET_UI_DETAILS: 'v1/uit/user_interfaces',
        UPDATE_UI: 'v1/uit/user_interfaces',
        ADD_UI: 'v1/uit/user_interfaces',
        DELETE_UI: 'v1/uit/user_interfaces',
      },
      UI_TEMPLATES: {
        GET_TEMPLATE_LIST: 'v1/uit/ui_templates/list',
        GET_GUI_TEMPLATES_LIST: 'v1/uit/fetch_gui_templates_list',

        GET_TEMPLATE_DETAILS: 'v1/uit/ui_templates',
        UPDATE_TEMPLATE: 'v1/uit/ui_templates',
        UPDATE_UI_APP_BRANDING: 'v1/uit/update_ui_app_branding',
        UPDATE_UI_MENU: 'v1/uit/update_ui_menu',
        UPDATE_UI_SETTINGS: 'v1/uit/update_ui_settings',
        UPDATE_UI_TEMPLATE_VERSION: 'v1/uit/update_ui_template_version',
        UPDATE_TO_LATEST_VERSION: 'v1/uit/update_with_latest_ui_version',
        FETCH_PRODUCT_LIST_USER_TEMPLATE: 'v1/uit/ui_templates/product_list',
        FETCH_UI_VERSIONS: 'v1/uit/fetch_ui_template_versions',
        FETCH_UI_CHANGE_LOG: 'v1/uit/fetch_ui_change_log',
        UPDATE_UI_STYLING: 'v1/uit/update_ui_styling',
        UPDATE_UI_LANGUAGES: 'v1/uit/update_ui_languages',
        ADD_TEMPLATE: 'v1/uit/ui_templates',
        DELETE_GUI_TEMPLATE: 'v1/uit/ui_templates',
        UPDATE_PRODUCT: 'v1/uit/ui_templates/product',
        DELETE_PRODUCT: 'v1/uit/ui_templates/product',
        //purchase settings
        ADD_UPDATE_PURCHASE_SETTINGS: 'v1/uit/ui_templates/purchase_settings',
      },
    },
    DEPLOYMENTS: {
      GET: 'v1/deployments',
      ADD: 'v1/deployments',
      LIST: 'v1/deployments',
      PATCH: 'v1/deployments',
    },
  },

  CUSTOMERS: {
    CUSTOMERS: {
      SEARCH: 'v1/customers/customers/search',
      PAGINATION: 'v1/customers/customers/pagination',
      LIST: 'v1/customers/customers',
      ADD: 'v1/customers/customers',
      DELETE: 'v1/customers/customers',
      PUT: 'v1/customers/customers',
      PATCH: 'v1/customers/customers',
      GET: 'v1/customers/customers',
    },
    RESELLERS: {
      LIST: 'v1/customers/resellers',
      ADD: 'v1/customers/resellers',
      DELETE: 'v1/customers/resellers',
      PUT: 'v1/customers/resellers',
      PATCH: 'v1/customers/resellers',
      GET: 'v1/customers/resellers',
    },
    TICKETS: {
      LIST: 'v1/customers/tickets',
      ADD: 'v1/customers/tickets',
      DELETE: 'v1/customers/tickets',
      PUT: 'v1/customers/tickets',
      PATCH: 'v1/customers/tickets',
      GET: 'v1/customers/tickets',
    },
  },

  BILLING: {
    SERVICES: {
      LIST: 'v1/billing/services',
      ADD: 'v1/billing/services',
      DELETE: 'v1/billing/services',
      PUT: 'v1/billing/services',
      PATCH: 'v1/billing/services',
      GET: 'v1/billing/services',
      PUBLISH: 'v1/billing/services/publish',
    },
    TESTER: {
      EMAIL: 'v1/billing/test/email',
      SMS: 'v1/billing/test/sms',
    },
    APIBUILDER: {
      LIST: 'v1/billing/apibuilder/list',
      DETAILS: 'v1/billing/apibuilder',
      UPDATE: 'v1/billing/apibuilder',
      ADD: 'v1/billing/apibuilder',
      DELETE: 'v1/billing/apibuilder',
    },
    REGISTRATION_REMINDERS: {
      LIST: 'v1/billing/registration-reminders',
      ADD: 'v1/billing/registration-reminders',
      DELETE: 'v1/billing/registration-reminders',
      PUT: 'v1/billing/registration-reminders',
      PATCH: 'v1/billing/registration-reminders',
      GET: 'v1/billing/registration-reminders',
    },
    WELCOME_MESSAGES: {
      LIST: 'v1/billing/welcome-messages',
      ADD: 'v1/billing/welcome-messages',
      DELETE: 'v1/billing/welcome-messages',
      PUT: 'v1/billing/welcome-messages',
      PATCH: 'v1/billing/welcome-messages',
      GET: 'v1/billing/welcome-messages',
    },
    PAYMENT_FAILED_MESSAGES: {
      LIST: 'v1/billing/payment-failed-messages',
      ADD: 'v1/billing/payment-failed-messages',
      DELETE: 'v1/billing/payment-failed-messages',
      PUT: 'v1/billing/payment-failed-messages',
      PATCH: 'v1/billing/payment-failed-messages',
      GET: 'v1/billing/payment-failed-messages',
    },
    PASSWORD_RECOVERY_MESSAGES: {
      LIST: 'v1/billing/password-recovery-messages',
      ADD: 'v1/billing/password-recovery-messages',
      DELETE: 'v1/billing/password-recovery-messages',
      PUT: 'v1/billing/password-recovery-messages',
      PATCH: 'v1/billing/password-recovery-messages',
      GET: 'v1/billing/password-recovery-messages',
    },
    RENEWAL_REMINDERS: {
      LIST: 'v1/billing/renewal-reminders',
      ADD: 'v1/billing/renewal-reminders',
      DELETE: 'v1/billing/renewal-reminders',
      PUT: 'v1/billing/renewal-reminders',
      PATCH: 'v1/billing/renewal-reminders',
      GET: 'v1/billing/renewal-reminders',
    },
    DUNNING_REMINDERS: {
      LIST: 'v1/billing/dunning-reminders',
      ADD: 'v1/billing/dunning-reminders',
      DELETE: 'v1/billing/dunning-reminders',
      PUT: 'v1/billing/dunning-reminders',
      PATCH: 'v1/billing/dunning-reminders',
      GET: 'v1/billing/dunning-reminders',
    },
    PROMOTION_REMINDERS: {
      LIST: 'v1/billing/promotion-reminders',
      ADD: 'v1/billing/promotion-reminders',
      DELETE: 'v1/billing/promotion-reminders',
      PUT: 'v1/billing/promotion-reminders',
      PATCH: 'v1/billing/promotion-reminders',
      GET: 'v1/billing/promotion-reminders',
    },
    PAYMENT_RECEIVED_MESSAGES: {
      LIST: 'v1/billing/payment-received-messages',
      ADD: 'v1/billing/payment-received-messages',
      DELETE: 'v1/billing/payment-received-messages',
      PUT: 'v1/billing/payment-received-messages',
      PATCH: 'v1/billing/payment-received-messages',
      GET: 'v1/billing/payment-received-messages',
    },
    RENEWED_SUBSCRIPTION_MESSAGES: {
      LIST: 'v1/billing/renewed-subscription-messages',
      ADD: 'v1/billing/renewed-subscription-messages',
      DELETE: 'v1/billing/renewed-subscription-messages',
      PUT: 'v1/billing/renewed-subscription-messages',
      PATCH: 'v1/billing/renewed-subscription-messages',
      GET: 'v1/billing/renewed-subscription-messages',
    },
    CHANGE_BASE_SUBSCRIPTION_MESSAGES: {
      LIST: 'v1/billing/change-base-subscription-messages',
      ADD: 'v1/billing/change-base-subscription-messages',
      DELETE: 'v1/billing/change-base-subscription-messages',
      PUT: 'v1/billing/change-base-subscription-messages',
      PATCH: 'v1/billing/change-base-subscription-messages',
      GET: 'v1/billing/change-base-subscription-messages',
    },
    ADD_EXTRA_SUBSCRIPTION_MESSAGES: {
      LIST: 'v1/billing/add-extra-subscription-messages',
      ADD: 'v1/billing/add-extra-subscription-messages',
      DELETE: 'v1/billing/add-extra-subscription-messages',
      PUT: 'v1/billing/add-extra-subscription-messages',
      PATCH: 'v1/billing/add-extra-subscription-messages',
      GET: 'v1/billing/add-extra-subscription-messages',
    },
    REMOVE_EXTRA_SUBSCRIPTION_MESSAGES: {
      LIST: 'v1/billing/remove-extra-subscription-messages',
      ADD: 'v1/billing/remove-extra-subscription-messages',
      DELETE: 'v1/billing/remove-extra-subscription-messages',
      PUT: 'v1/billing/remove-extra-subscription-messages',
      PATCH: 'v1/billing/remove-extra-subscription-messages',
      GET: 'v1/billing/remove-extra-subscription-messages',
    },
    PACKAGES: {
      LIST: 'v1/billing/packages',
      ADD: 'v1/billing/packages',
      DELETE: 'v1/billing/packages',
      PUT: 'v1/billing/packages',
      PATCH: 'v1/billing/packages',
      GET: 'v1/billing/packages',
      PUBLISH: 'v1/billing/packages/publish',
    },
    HARDWARE: {
      LIST: 'v1/billing/hardware',
      ADD: 'v1/billing/hardware',
      DELETE: 'v1/billing/hardware',
      PUT: 'v1/billing/hardware',
      PATCH: 'v1/billing/hardware',
      GET: 'v1/billing/hardware',
    },
    PRODUCTS: {
      LIST: 'v1/billing/products',
      ADD: 'v1/billing/products',
      DELETE: 'v1/billing/products',
      PUT: 'v1/billing/products',
      PATCH: 'v1/billing/products',
      GET: 'v1/billing/products',
      PUBLISH: 'v1/billing/products/publish',
    },
    WALLETS: {
      LIST: 'v1/billing/wallets',
      ADD: 'v1/billing/wallets',
      DELETE: 'v1/billing/wallets',
      PUT: 'v1/billing/wallets',
      PATCH: 'v1/billing/wallets',
      GET: 'v1/billing/wallets',
    },
    SHOPPING_CARTS: {
      LIST: 'v1/billing/shopping-carts',
      ADD: 'v1/billing/shopping-carts',
      DELETE: 'v1/billing/shopping-carts',
      PUT: 'v1/billing/shopping-carts',
      PATCH: 'v1/billing/shopping-carts',
      GET: 'v1/billing/shopping-carts',
      FONTS: {
        GET: 'v1/billing/shopping-carts/fonts',
      },
    },
    CUSTOMER_PORTALS: {
      LIST: 'v1/billing/customer-portals',
      ADD: 'v1/billing/customer-portals',
      DELETE: 'v1/billing/customer-portals',
      PUT: 'v1/billing/customer-portals',
      PATCH: 'v1/billing/customer-portals',
      GET: 'v1/billing/customer-portals',
      FONTS: {
        GET: 'v1/billing/customer-portals/fonts',
      },
    },
    DOMAINS: {
      LIST: 'v1/billing/domains',
      ADD: 'v1/billing/domains',
      DELETE: 'v1/billing/domains',
      PUT: 'v1/billing/domains',
      PATCH: 'v1/billing/domains',
      GET: 'v1/billing/domains',
    },
    TAXES: {
      LIST: 'v1/billing/settings/taxes',
      ADD: 'v1/billing/settings/taxes',
      DELETE: 'v1/billing/settings/taxes',
      PUT: 'v1/billing/settings/taxes',
      PATCH: 'v1/billing/settings/taxes',
      GET: 'v1/billing/settings/taxes',
    },
    SUBSCRIPTION_RULES: {
      ADD: 'v1/billing/settings/subscription-rules',
      GET: 'v1/billing/settings/subscription-rules',
    },
    EMAIL_GATEWAYS: {
      LIST: 'v1/billing/settings/email-gateways',
      ADD: 'v1/billing/settings/email-gateways',
      DELETE: 'v1/billing/settings/email-gateways',
      PUT: 'v1/billing/settings/email-gateways',
      PATCH: 'v1/billing/settings/email-gateways',
      GET: 'v1/billing/settings/email-gateways',
    },
    SMS_GATEWAYS: {
      LIST: 'v1/billing/settings/sms-gateways',
      ADD: 'v1/billing/settings/sms-gateways',
      DELETE: 'v1/billing/settings/sms-gateways',
      PUT: 'v1/billing/settings/sms-gateways',
      PATCH: 'v1/billing/settings/sms-gateways',
      GET: 'v1/billing/settings/sms-gateways',
    },
    SMS_TEMPLATES: {
      LIST: 'v1/billing/settings/sms-templates',
      ADD: 'v1/billing/settings/sms-templates',
      DELETE: 'v1/billing/settings/sms-templates',
      PUT: 'v1/billing/settings/sms-templates',
      PATCH: 'v1/billing/settings/sms-templates',
      GET: 'v1/billing/settings/sms-templates',
    },
    APP_TEMPLATES: {
      LIST: 'v1/billing/settings/app-templates',
      ADD: 'v1/billing/settings/app-templates',
      DELETE: 'v1/billing/settings/app-templates',
      PUT: 'v1/billing/settings/app-templates',
      PATCH: 'v1/billing/settings/app-templates',
      GET: 'v1/billing/settings/app-templates',
    },
    EMAIL_TEMPLATES: {
      LIST: 'v1/billing/settings/email-templates',
      ADD: 'v1/billing/settings/email-templates',
      DELETE: 'v1/billing/settings/email-templates',
      PUT: 'v1/billing/settings/email-templates',
      PATCH: 'v1/billing/settings/email-templates',
      GET: 'v1/billing/settings/email-templates',
    },
    PAYMENT_METHOD_SETTINGS: {
      LIST: 'v1/billing/payment-method-settings',
      ADD: 'v1/billing/payment-method-settings',
      DELETE: 'v1/billing/payment-method-settings',
      PUT: 'v1/billing/payment-method-settings',
      PATCH: 'v1/billing/payment-method-settings',
      GET: 'v1/billing/payment-method-settings',
    },
    PAYMENT_PROVIDERS: {
      LIST: 'v1/billing/payment-providers',
      ADD: 'v1/billing/payment-providers',
      DELETE: 'v1/billing/payment-providers',
      PUT: 'v1/billing/payment-providers',
      PATCH: 'v1/billing/payment-providers',
      GET: 'v1/billing/payment-providers',
    },
    TRANSACTION_PRODUCTS: {
      GET: 'v1/billing/transaction-products',
      LIST: 'v1/billing/transaction-products',
    },
    COMMUNICATIONS: {
      GET: 'v1/billing/communications',
      LIST: 'v1/billing/communications',
    },
  },
};
