import React from 'react';
import { Alert, Form as AntdForm, notification, Tabs } from 'antd';
import { ContainerWrapper } from '../../../../common/components/containerWrapper';
import { PageTitle } from '../../../../common/components/pageTitle';
import { Product } from '../../../../interfaces/forms/areas/billing/products/product';
import { GeneralFields, SettingsFields, ExternalFields, ItemFields, PlanFields, Form } from './shared/form';
import { useParams, useRouteMatch } from 'react-router';
import { useProduct, useProductPatch } from '../../../../services/areas/billing/productService';
import { BillingContext } from '../../../../contexts/billingContext';
import { IMSNameParams } from '../../../dashboard';
import { STORAGE_ENDPOINTS } from '../../../../services/storage_paths';
import { BackButton } from '../../../../common/components/BackButton';
import { Spin } from '../../../../common/components/spinner';
import { useTranslation } from 'react-i18next';

const ProductDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { clientName } = useParams<IMSNameParams>();
  const { deployment, service } = React.useContext(BillingContext);
  const { url } = useRouteMatch();
  const { isLoading, isError, data } = useProduct({ params: { cms: deployment?.name || '', crm: service?.name || '', instance: clientName }, id });
  const {t} = useTranslation();
  
  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-12">
          <BackButton text={'Back to subscription list'} url={url.split('/').slice(0, -1).join('/')} />
        </div>
        <div className="col-md-6 mb-2">
          <PageTitle title={'Edit subscription'} subtitle="" />
        </div>
      </div>

      {data?.data.plans?.plans.length == 0 && (
        <div className="mt-3 ml-2 mr-3 mb-5">
          <Alert className="mt-4" message={t('plans')} showIcon description={t('you-have-not-yet-defined-any-plans-you-need-plans-before-you-can-use-your-product-in-a-cart')} type="error" />
        </div>
      )}

      <div className="row ">
        <div className="col-12 mr-2">
          {isLoading && <Spin />}
          {isError && <span>{t('something-went-wrong')}</span>}
          {!isLoading && !isError && <Details product={data.data} />}
        </div>
      </div>
    </ContainerWrapper>
  );
};

type DetailsProps = {
  product: Product;
};

const Details = ({ product }: DetailsProps) => {
  const { id } = useParams<{ id: string }>();
  const { clientName } = useParams<IMSNameParams>();
  const { service, deployment } = React.useContext(BillingContext);
  const {t} = useTranslation();
  const [activeTab, setActiveTab] = React.useState<string>('general');
  const [generalForm] = AntdForm.useForm<Partial<Product>>();
  const [settingsForm] = AntdForm.useForm<Partial<Product>>();
  //const [notificationForm] = AntdForm.useForm<Partial<Product>>();
  const [externalForm] = AntdForm.useForm<Partial<Product>>();
  const [itemForm] = AntdForm.useForm<Partial<Product>>();
  const [planForm] = AntdForm.useForm<Partial<Product>>();
  const path = STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).PRODUCTS.PRODUCTS;

  const { isLoading, mutate } = useProductPatch(
    { params: { cms: deployment?.name || '', crm: service?.name || '', instance: clientName, path: path }, id },
    {
      onError: () => {
        notification.error({ message: t('something-went-wrong-while-updating-the-product') });
      },
      onSuccess: () => {
        notification.success({ message: t('package-updated') });
      },
    }
  );

  return (
    <Tabs
      activeKey={activeTab}
      className="default-tab mr-3"
      onChange={(activeKey: string) => setActiveTab(activeKey)}
      items={[
        {
          label: t('general'),
          key: 'general',
          children: (
            <Form form={generalForm} disabled={isLoading} onFinish={(values: Partial<Product>) => mutate(values)} initialValues={product}>
              <GeneralFields />
            </Form>
          ),
        },
        product.type == 'BASE'
          ? {
              label: t('settings'),
              key: 'settings',
              children: (
                <Form form={settingsForm} disabled={isLoading} onFinish={(values: Partial<Product>) => mutate(values)} initialValues={product}>
                  <SettingsFields />
                </Form>
              ),
            }
          : {
              label: <></>,
              key: 'empty',
            },
        {
          label: t('cart-setup'),
          key: 'external',
          children: (
            <Form form={externalForm} disabled={isLoading} onFinish={(values: Partial<Product>) => mutate(values)} initialValues={product}>
              <ExternalFields />
            </Form>
          ),
        },
        {
          label: t('services-and-bundles'),
          key: 'items',
          children: (
            <Form form={itemForm} disabled={isLoading} onFinish={(values: Partial<Product>) => mutate(values)} initialValues={product}>
              <ItemFields />
            </Form>
          ),
        },
        {
          label: t('payment-plans'),
          key: 'pricing',
          children: (
            <Form form={planForm} disabled={isLoading} onFinish={(values: Partial<Product>) => mutate(values)} initialValues={product}>
              <PlanFields />
            </Form>
          ),
        },
      ]}
    />
  );
};

export default ProductDetails;
