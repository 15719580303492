import { Button, Form, Input, Modal, Select } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';


type AddModalProps = {
  visible: boolean;
  onSubmit: (values: any, transcoder: any) => void;
  onCancel: () => void;
  isLoading: boolean;
};
const AddModal = (props: AddModalProps) => {
  const { visible, onSubmit, onCancel, isLoading } = props;
  const [transcoder, setTranscoder] = useState('');
  const {t} = useTranslation();
  const handleSubmit = (e: any) => {
    onSubmit(e, transcoder);
  };

  return (
    <Modal visible={visible} style={{  borderRadius:5}} width={800} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="mb-4">{t('add-transcoder')}</h4>
            <Form onFinish={handleSubmit} onFinishFailed={(errorInfo) => {}}>
              <div className="row-md-12 ml-4">
                <label className="col-md-5 col-form-label"></label>
                <div className="col">
                  <div className="row">
                    <label className="col-md-5 col-form-label">{t('transcoder-name')}</label>
                    <Form.Item className="col-md-6" name={['name']}>
                      <Input type={'http'} className="form-control bg-white font-size-14 text-default" placeholder={t('like-usa-1')} />
                    </Form.Item>
                  </div>
                  <div className="row">
                    <label className="col-md-5 col-form-label">{t('transcoder-url-0')}</label>
                    <Form.Item className="col-md-6" name={['url']}>
                      <Input type={'http'} className="form-control bg-white font-size-14 text-default" placeholder={'https://transcoder.com'} />
                    </Form.Item>
                  </div>
                  <div className="row">
                    <label className="col-md-5 col-form-label">{t('transcoder-username')}</label>
                    <Form.Item className="col-md-6" name={['user']}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="" />
                    </Form.Item>
                  </div>
                  <div className="row">
                    <label className="col-md-5 col-form-label">{t('transcoder-password')}</label>
                    <Form.Item className="col-md-6" name={['pass']}>
                      <Input type={'password'} className="form-control bg-white font-size-14 text-default" placeholder="" />
                    </Form.Item>
                  </div>
                  <div className="row">
                    <label className="col-md-5 col-form-label">{t('transcoder-retention')}</label>
                    <Form.Item extra={t('number-of-days-the-recording-stays-on-the-server-recordings-set-by-customers-older-than-the-set-retention-will-automatically-be-deleted')} className="col-md-6" name={['retention']}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="" />
                    </Form.Item>
                  </div>
                  <div className="row">
                    <label className="col-md-5 col-form-label">{t('transcoder-type')}</label>
                    <Form.Item className="col-md-4" name={['translations', 'type']}>
                      <Select onChange={(value) => setTranscoder(value)} className="default-select" placeholder={t('select-type')}>
                        <Select.Option value={'Flussonic V2'} key={'Flussonic V2'}>
                          {t('flussonic-v2-0')}
                        </Select.Option>
                        <Select.Option value={'Flussonic V3'} key={'Flussonic V3'}>
                          {t('flussonic-v3-0')}
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>

              <div className="col-md-12 p-0">
                <Button loading={isLoading} disabled={isLoading} htmlType="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                  {t('add-transcoder-0')}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddModal;
