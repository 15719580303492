import { Link, useParams, useRouteMatch } from 'react-router-dom';
import Table from '../../../../../common/components/Content/Table';
import { IMSNameParams } from '../../../../dashboard';
import cmsShorts from '../../../../../services/areas/content/shorts/shorts';
import { useContext, useState } from 'react';
import { ContentContext } from '../../../../../contexts/contentContext';
import { StatusCodes } from 'http-status-codes';
import * as XLSX from 'xlsx';
import moment from 'moment-timezone';
import { Button } from 'antd';
import { MW_BASE_DB_LANGUAGE } from '@mwaretv/database/build/backend/models/base/language';
import { useTranslation } from 'react-i18next';
import { NotVisibleForRole } from '../../../../../common/components/VisibleForRole';
import { TVMSRoles } from '../../../../../constants/TVMSRoles';

const Albums = () => {
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -1).join('/');
  const params = useParams<IMSNameParams & { deployment: string }>();
  const { clientName } = useParams<IMSNameParams>();
  const [exporting, setExporting] = useState(false);
  const { deployment } = useContext(ContentContext);
  const languagesDeployment = (deployment?.languages as unknown as MW_BASE_DB_LANGUAGE[]) || [];
  const {t} = useTranslation();
  
  const exportContent = async () => {
    setExporting(true);
    const values = {
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    };
    const response = await cmsShorts.Export_Shorts(values as any);
    if (response.status_code === StatusCodes.OK) {
      const shorts = response.data.data as any;
      const translations = [] as any;
      const languages = [] as any;
      const currencies = [] as any;
      const tags = [] as any;

      languagesDeployment.forEach((langauge: any) => {
        languages.push({
          language: langauge.name,
        });
      });

      shorts.forEach((short: any, index: any) => {
        const shortTranslations = short.translations;
        shortTranslations.forEach((shortTranslation: any) => {
          translations.push({
            name: shortTranslation.name,
            description: shortTranslation.description,
          });
        });
        delete shorts[index].translations;

        const tagsTranslations = short.tags;
        if (tagsTranslations != undefined && tagsTranslations[0] != undefined && tagsTranslations[0].tag != undefined) {
          tagsTranslations.forEach((trans: any) => {
            trans.tag.forEach((tag: any) => {
              tags.push({
                _id: short.name,
                name: tag.name,
                description: tag.description,
              });
            });
          });
        }
        delete short.tags;

        if (short.payperview_currencies) {
          short.payperview_currencies.forEach((currency: any) => {
            currencies.push({
              short_id: short.name,
              ...currency,
            });
          });
        }
        delete shorts[index].payperview_currencies;
      });

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(response.data.data);
      const worksheet1 = XLSX.utils.json_to_sheet(currencies);
      const worksheet2 = XLSX.utils.json_to_sheet(languages);

      XLSX.utils.book_append_sheet(workbook, worksheet, t('shorts-4'))
      XLSX.utils.book_append_sheet(workbook, worksheet1, t('currencies'));
      XLSX.utils.book_append_sheet(workbook, worksheet2, t('languages'));

      languages.forEach((language: any) => {
        const descriptionPerLanguage = translations.filter((l: any) => l.name === language.language);
        const tagsPerLanguage = tags.filter((l: any) => l.name === language.language);

        if (descriptionPerLanguage !== undefined) {
          const result = descriptionPerLanguage.map((obj: any, index: number) => ({ description: obj['description'], tag: tags[index] }));
          XLSX.utils.book_append_sheet(workbook, XLSX.utils.json_to_sheet(result), language.language);
        }
        if (tagsPerLanguage !== undefined) {
          const result2 = tagsPerLanguage.map((obj: any) => ({ _id: obj['_id'], tags: obj['description'] }));
          XLSX.utils.book_append_sheet(workbook, XLSX.utils.json_to_sheet(result2), 'tags_' + language.language);
        }
      });

      XLSX.writeFile(workbook, 'Shorts_' + clientName + '_' + deployment?.name + '_' + moment().format('YYYY-MM-DD') + '.xlsx');
    }
    setExporting(false);
  };

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-6 mb-2">
          <h2 className="page-title">{t('shorts-4')}</h2>
          <p className="h6 font-weight-normal line-height-26">{t('all-your-shorts-in-the-system-you-can-add-as-many-as-you-want')}</p>
        </div>
        <div className="col-md-6"></div>
      </div>
      <div className="row mt-4">
        <div className="col-md-7 mb-4 mb-md-2 d-flex justify-content-center justify-content-md-start align-items-start"></div>
        <div className="col-md-5 d-flex justify-content-center justify-content-md-end align-items-start">
        <NotVisibleForRole roles={[TVMSRoles.Provider]}>
          <Link className="btn btn-secondary rounded-pill btn-default float-right width-240 height-48" to={`${redirectUrl}/actions/importer-excel`}>
            {t('import-shorts')}
          </Link>
          </NotVisibleForRole>
          <NotVisibleForRole roles={[TVMSRoles.Provider]}>
          <Button disabled={exporting} loading={exporting} className="btn btn-secondary rounded-pill btn-default float-right width-150 height-48" onClick={exportContent}>
            {t('export-shorts')}
          </Button>
          </NotVisibleForRole>
          <Link className="btn btn-secondary rounded-pill btn-default float-right width-240 height-48" to={`${url}/add`}>
            {t('add-shorts-0')}
          </Link>
        </div>
      </div>
 
      <div className="row">
        <div className="col-12 mt-4">
          <Table service_name={params.deployment} clientName={clientName} type={'shorts'} variant={'normal'}></Table>
        </div>
      </div>
    </div>
  );
};

export default Albums;
