import { Layout, Menu } from 'antd';
import React from 'react';
import { Route, Switch, useParams, useRouteMatch } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import IMSDashboardHeader from '../../../common/components/IMSDashboardHeader';
import { DrawerContext } from '../../../context_api/DrawerContext';
import MWare_Logo from '../../../assets/images/logo.png';
import * as DeploymentService from '../../../services/deployments/deployment';
import { useQuery } from '@tanstack/react-query';
import { CustomersContextProvider } from '../../../contexts/customersContext';
import { faSquareUser, faHeadset, faDashboard } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Customers from './customers';
import Tickets from './tickets';
import Dashboard from './dashboard';
import Actions from './actions';
import { getExpandedMenu, handleOnClick } from '../../../utils/menu';
import { useEffectOnce, useLocation } from 'react-use';
import { AuthContext } from '../../../context_api/AuthContext';
import { useContext } from 'react';
import { hasRole } from '../../../utils/Auth/role.auth';
import { NotVisibleForRole, VisibleForRole } from '../../../common/components/VisibleForRole';
import { TVMSRoles } from '../../../constants/TVMSRoles';
import { useTranslation } from 'react-i18next';

const Customer = () => {
  const location = useLocation();
  const { state: DrawerState } = React.useContext(DrawerContext);
  const { path, url } = useRouteMatch();
  const { clientName, deployment, service } = useParams<{ clientName: string; deployment: string; service: string }>();
  const { state: AuthState } = useContext(AuthContext);
  const isClient = hasRole(AuthState.user!, clientName, ['superadmin', 'admin']);
  var locSplit = location?.pathname?.split('/');
  const history = useHistory();
  const {t} = useTranslation();
  var redirectPath = '';
  if (locSplit != undefined) {
    redirectPath = locSplit.slice(0, 4 - locSplit.length).join('/');
  }

  useEffectOnce(() => {
    const str = window.location.href;
    const parts = str.split('/');
    const lastPart = parts[parts.length - 1];
    if (!isClient && lastPart == 'customers') {
      history.push(url + '/customers/list');
    }
  });

  const { data, isLoading, isError } = useQuery(['deployment', deployment], async () => {
    return DeploymentService.find(clientName, { name: deployment })
      .then((r) => r.data)
      .catch((e) => Promise.reject(e));
  });

  return (
    <Layout>
      <NotVisibleForRole roles={[TVMSRoles.Vendor]}>
        <Layout.Sider width="250" trigger={null} collapsible collapsed={DrawerState.is_collapsed}>
          <div style={{ justifyContent: 'center', alignContent: 'center', display: 'flex', padding: 40, backgroundColor: '#444', paddingTop: 70, paddingBottom: 70, marginBottom: 17 }}>
            {!isClient && (
              <Link to={'/'} onClick={() => {}} key="Mware">
                <img className="expand" src={DrawerState.white_labeling.logo || MWare_Logo} title={"MwareTV"} alt={"MwareTV"} style={DrawerState.white_labeling.logo ? { maxWidth: 150, maxHeight: 50 } : {}} />
              </Link>
            )}
            {isClient && (
              <a href={redirectPath} key="Mware">
                <img className="expand" src={DrawerState.white_labeling.logo || MWare_Logo} title={"MwareTV"} alt={"MwareTV"} style={DrawerState.white_labeling.logo ? { maxWidth: 150, maxHeight: 50 } : {}} />
              </a>
            )}
          </div>
          <Menu
            mode="inline"
            defaultOpenKeys={getExpandedMenu()}
            onClick={handleOnClick}
            items={[
              isClient
                ? {
                    label: (
                      <div className="height-60 d-flex align-items-center">
                        <FontAwesomeIcon icon={faDashboard} size="lg" style={{ width: '20', height: '20' }} />
                        <span className="ml-3">{t('dashboard-0')}</span>
                      </div>
                    ),
                    key: 'dashboard',
                    children: [
                      {
                        label: (
                          <Link className="height-60 d-flex align-items-center ml-3" to={`${url}`}>
                            <span className="ml-3">{t('dashboard-0')}</span>
                          </Link>
                        ),
                        key: 'appbuilder-dashboard',
                      },
                    ],
                  }
                : {
                    label: <></>,
                    key: 'empty',
                  },
              {
                label: (
                  <div className="height-60 d-flex align-items-center">
                    <FontAwesomeIcon icon={faSquareUser} size="lg" style={{ width: '20', height: '20' }} />
                    <span className="ml-3">{t('customers')}</span>
                  </div>
                ),
                key: 'customers',
                children: [
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/customers/list`}>
                        <span className="ml-3">{t('customers')}</span>
                      </Link>
                    ),
                    key: 'customers-customers',
                  },
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/actions/importer-excel`}>
                        <span className="ml-3">{t('import-0')}</span>
                      </Link>
                    ),
                    key: 'customers-import',
                  },
                ],
              },
              {
                label: (
                  <div className="height-60 d-flex align-items-center">
                    <FontAwesomeIcon icon={faHeadset} size="lg" style={{ width: '20', height: '20' }} />
                    <span className="ml-3">{t('tickets-0')}</span>
                  </div>
                ),
                key: 'tickets',
                children: [
                  {
                    label: (
                      <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/tickets`}>
                        <span className="ml-3">{t('tickets-0')}</span>
                      </Link>
                    ),
                    key: 'ticketspage',
                  },
                ],
              },
              // {
              //   label: (
              //     <div className="height-60 d-flex align-items-center">
              //       <FontAwesomeIcon icon={faDiagramProject} size="lg" style={{ width: '20', height: '20' }} />
              //       <span className="ml-3">Resellers</span>
              //     </div>
              //   ),
              //   key: 'resellers',
              //   children: [
              //     {
              //       label: (
              //         <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/resellers`}>
              //           <span className="ml-3">Resellers</span>
              //         </Link>
              //       ),
              //       key: 'resellers',
              //     },
              //   ],
              // },

              // {
              //   label: (<div className='height-60 d-flex align-items-center'><FontAwesomeIcon icon={faGears} size='lg' /><span className='ml-3'>Settings</span></div>),
              //   key: 'settings',
              //   children: [
              //     // { label: (<Link className='height-60 d-flex align-items-center' to={`${url}/settings/general`}><span className='ml-3'>General</span></Link>), key: 'settings-general' },
              //     // { label: (<Link className='height-60 d-flex align-items-center' to={`${url}/settings/currencies`}><span className='ml-3'>Currencies</span></Link>), key: 'settings-currencies' },
              //     { label: (<Link className='height-60 d-flex align-items-center' to={`${url}/settings/company-info`}><span className='ml-3'>Company Info</span></Link>), key: 'settings-company-info' },
              //     // { label: (<Link className='height-60 d-flex align-items-center' to={`${url}/settings/log-translations`}><span className='ml-3'>Log Translations</span></Link>), key: 'settings-log-translations' },
              //     // { label: (<Link className='height-60 d-flex align-items-center' to={`${url}/settings/crm-rules`}><span className='ml-3'>CRM Rules</span></Link>), key: 'settings-crm-rules' },
              //     // { label: (<Link className='height-60 d-flex align-items-center' to={`${url}/settings/crm-rules`}><span className='ml-3'>CRM Rules</span></Link>), key: 'settings-crm-rules' },

              //   ]
              // },
            ]}
          />
        </Layout.Sider>
      </NotVisibleForRole>
      <NotVisibleForRole roles={[TVMSRoles.Vendor]}>
        <div  style={{ paddingLeft: 0, width:'100%' }}>
          <div>
            <IMSDashboardHeader
              {...{
                isSideBarShow: true,
                path,
              }}
            />
          </div>
          <div>
            {/* {isLoading && <Spin />} */}
            {isError && <span>{t('something-went-wrong')}</span>}
            {!isLoading && !isError && data && (
              <CustomersContextProvider value={{ deployment: data, service: data.services.find((x) => x.name === service) }}>
                <Switch>
                  <Route path={`${path}/customers`}>
                    <Customers />
                  </Route>
                  <Route path={`${path}/tickets`}>
                    <Tickets />
                  </Route>
                  <Route path={`${path}/resellers`}>
                    <Customers />
                  </Route>
                  <Route path={`${path}/actions`}>
                    <Actions />
                  </Route>
                  <Route path={`${path}/`}>{isClient && <Dashboard />}</Route>
                </Switch>
              </CustomersContextProvider>
            )}
          </div>
        </div>
      </NotVisibleForRole>
      <VisibleForRole roles={[TVMSRoles.Vendor]}>
        <div className="col-md-12" style={{ paddingLeft: 0 }}>
          <div>
            <IMSDashboardHeader
              {...{
                isSideBarShow: true,
                path,
              }}
            />
          </div>
          <div>
            {/* {isLoading && <Spin />} */}
            {isError && <span>{t('something-went-wrong')}</span>}
            {!isLoading && !isError && data && (
              <CustomersContextProvider value={{ deployment: data, service: data.services.find((x) => x.name === service) }}>
                <Switch>
                  <Route path={`${path}/customers`}>
                    <Customers />
                  </Route>
                  <Route path={`${path}/tickets`}>
                    <Tickets />
                  </Route>
                  <Route path={`${path}/resellers`}>
                    <Customers />
                  </Route>
                  <Route path={`${path}/actions`}>
                    <Actions />
                  </Route>
                  <Route path={`${path}/`}>{isClient && <Dashboard />}</Route>
                </Switch>
              </CustomersContextProvider>
            )}
          </div>
        </div>
      </VisibleForRole>
    </Layout>
  );
};

export default Customer;
