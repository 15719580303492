import { useTranslation } from 'react-i18next';
import { Input, Item } from '../../../../../common/components/form';
import { Transaction } from '../../../../../interfaces/forms/areas/billing/transaction/transaction';
import { Card as AntdCard } from 'antd';

export const TransactionDetails = ({ data }: { data: Transaction }) => {
  const {t} = useTranslation();
    return (
      <>
        <AntdCard title={t('general')}>
          <Item className="col-md-8" label={t('date')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={new Date(data.date_unix * 1000).toString()} disabled />
          </Item>
          <Item className="col-md-8" label={t('transaction-type-0')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.transaction_type} disabled />
          </Item>
          <Item className="col-md-8" label={t('source')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.source} disabled />
          </Item>
          <Item className="col-md-8" label={t('amount-including-vat')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.amount_inc_vat?.toFixed(2)} disabled />
          </Item>
          <Item className="col-md-8" label={t('amount-excluding-vat')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.amount_ex_vat?.toFixed(2)} disabled />
          </Item>
          <Item className="col-md-8" label={t('amount-vat')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.amount_vat?.toFixed(2)} disabled />
          </Item>
          <Item className="col-md-8" label={t('payment-provider')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.payment_provider} disabled />
          </Item>
          <Item className="col-md-8" label={t('payment-reference-id')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Input value={data.payment_provider_ref_id} disabled />
          </Item>
        </AntdCard>
        {data.products?.length > 0 && (
          <AntdCard title={t('products')} style={{ marginTop: 16 }}>
            {data.products?.map((product) => {
              return (
                <AntdCard title={t('product')} style={{ marginTop: 16 }}>
                  <Item className="col-md-8" label={t('name')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                    <Input value={product.name} disabled />
                  </Item>
                  <Item className="col-md-8" label={t('type')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                    <Input value={product.type} disabled />
                  </Item>
                  <Item className="col-md-8" label={t('variant')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                    <Input value={product.variant} disabled />
                  </Item>
                  <Item className="col-md-8" label={t('amount-including-vat-0')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                    <Input value={product.amount_inc_vat?.toFixed(2)} disabled />
                  </Item>
                  <Item className="col-md-8" label={t('amount-excluding-vat-0')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                    <Input value={product.amount_ex_vat?.toFixed(2)} disabled />
                  </Item>
                  <Item className="col-md-8" label={t('amount-vat-0')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                    <Input value={product.amount_vat?.toFixed(2)} disabled />
                  </Item>
                  <Item className="col-md-8" label={t('plan-name')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                    <Input value={product.plan?.name} disabled />
                  </Item>
                  <Item className="col-md-8" label={t('duration-plan')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                    <Input value={product.plan?.duration} disabled />
                  </Item>
                  <Item className="col-md-8" label={t('duration-period-plan')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                    <Input value={product.plan?.duration_period_in} disabled />
                  </Item>
                </AntdCard>
              );
            })}
          </AntdCard>
        )}
      </>
    );
  };