import { useEffect, useState } from 'react';
import { Button, Table, Tooltip } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { IGetUsersList_User } from '../../../../../../interfaces/responses/users/IGetUsersListResponse';
import { User } from '../../../../../../interfaces/responses/users/user';
import { default as userService } from '../../../../../../services/user';
import { StatusCodes } from 'http-status-codes';
import UserSVG from '../../../../../../assets/images/user.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightToLine } from '@fortawesome/pro-regular-svg-icons';
import { useHistory } from 'react-router-dom';
import { TVMSRoles } from '../../../../../../constants/TVMSRoles';

interface TableParams {
  pagination?: TablePaginationConfig;
}

const ListUsers = (props) => {
  const history = useHistory();
  const [data, setData] = useState([] as any);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const columns: ColumnsType<IGetUsersList_User> = [
    {
      title: 'Username',
      dataIndex: 'email',
      render: (email) => (
        <span>
          <img className="mr-4" alt="user" style={{ height: '30px' }} src={UserSVG}  />
          {email}
        </span>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Role',
      dataIndex: 'role',
    },
    {
      title: 'Action',
      key: 'action',
      width: '5%',
      render: (row) => (
        <span className="justify-content-end">
          <Tooltip placement="top" title={`Details`}>
            <Button
              className="mx-2 cursor-pointer"
              type="text"
              onClick={() => {
                history.push(`/users/details/${row._id}`);
              }}
            >
              <FontAwesomeIcon className="action-icon" icon={faArrowRightToLine} />
            </Button>
          </Tooltip>
        </span>
      ),
    },
  ];

  const getUsersList = async () => {
    setLoading(true);

    const body = {
      pageNumber: tableParams.pagination?.current,
      pageSize: tableParams.pagination?.pageSize,
    };

    const response: User = await userService.GetUserList(body, {
      type: 'Client',
      client: props.data.name || 'none',
    });

    setTableParams((prevTableParams) => ({
      pagination: {
        ...prevTableParams.pagination,
        total: response.data?.count ?? 0,
      },
    }));

    if (response.status_code === StatusCodes.OK) {
      setData(response.data.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    getUsersList();
  }, [tableParams.pagination?.current]);

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setTableParams({
      pagination,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  return (
    <section className="content">
      <div className=" m-auto p-0">
        <div className="container-fluid wrapper p-3">
          <div className="row">
            <div className="col-md-7 p-3">
              <h2 className="page-title">Users</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Button
                type="primary"
                onClick={() => {
                  history.push('/users/client/add');
                }}
                className="rounded-pill width-240 height-54 float-right"
              >
                <span className="px-5 font-weight-bold">Add User</span>
              </Button>
            </div>
          </div>
          <div className="row">
            {/* <div className="col-12 mt-4">
              <Table columns={columns} dataSource={data} pagination={tableParams.pagination} loading={loading} onChange={handleTableChange} />
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ListUsers;
