import { faChevronsLeft, faChevronsRight, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, Image, Empty, Select } from 'antd';
import { StatusCodes } from 'http-status-codes';
import { useContext, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useEffectOnce } from 'react-use';
import { ICMS_Lists } from '../../../../interfaces/forms/common/ICMS_Lists';
import { useParams } from 'react-router-dom';
import { IMSNameParams } from '../../../../pages/dashboard';
import tagsService from '../../../../services/areas/content/tags/tags/index';
import providerService from '../../../../services/areas/content/provider/provider/index';

import cmsAlbumsPackage from '../../../../services/areas/content/albums/bundles';
import cmsAlbums from '../../../../services/areas/content/albums/albums';

import cmsCamsPackage from '../../../../services/areas/content/cams/bundles';
import cmsCams from '../../../../services/areas/content/cams/cams';

import cmsRadioPackage from '../../../../services/areas/content/radio/bundles';
import cmsRadio from '../../../../services/areas/content/radio/radio';

import cmsChannelsPackage from '../../../../services/areas/content/channels/bundles';
import cmsChannels from '../../../../services/areas/content/channels/channels';

import cmsMoviesPackage from '../../../../services/areas/content/movies/bundles';
import cmsMovies from '../../../../services/areas/content/movies/movies';

import cmsPodcastsPackage from '../../../../services/areas/content/podcasts/bundles';
import cmsPodcasts from '../../../../services/areas/content/podcasts/podcasts';

import cmsSeriesPackage from '../../../../services/areas/content/series/bundles';
import cmsSeries from '../../../../services/areas/content/series/series';

import cmsCoursesPackage from '../../../../services/areas/content/courses/bundles';
import cmsCourses from '../../../../services/areas/content/courses/courses';

import cmsGamesPackage from '../../../../services/areas/content/games/bundles';
import cmsGames from '../../../../services/areas/content/games/games';

import { ContentContext } from '../../../../contexts/contentContext';
import { Spin } from '../../spinner';
import { useTranslation } from 'react-i18next';

export const NestedContent = (params: { type: string; category: string }) => {
  const [items, setItems] = useState([] as any);
  const [itemsOriginal, setItemsOriginal] = useState([] as any);
  const [selected, setSelected] = useState([] as any);
  const [selectedOriginal, setSelectedOriginal] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);
  const [package_, setPackage_] = useState([] as any);
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(ContentContext);
  const { t } = useTranslation();
  let params_ = useParams<IMSNameParams>();

  const filterValidationAccepted = {
    id: 'provider_status',
    value: { filterValue: 'accepted', filterType: 'text' },
  } as any;

  const filterValidationEmpty = {
    id: 'provider_status',
    value: { filterValue: '', filterType: 'text' },
  } as any;

  const filterValidationUndefined = {
    id: 'provider_status',
    value: { filterValue: 'undefined', filterType: 'undefined' },
  } as any;

  const [tableSearchState] = useState<ICMS_Lists>({
    pageNumber: 1,
    pageSize: 100000,
    filters: [ filterValidationUndefined, filterValidationEmpty, filterValidationAccepted],
    query_params: {
      instance: clientName,
      cms: deployment?.name ?? '',
    },
  });

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const moveRight = (droppableSource: any, droppableDestination: any) => {
    var left = Array.from(items) as any;
    var right = Array.from(selected) as any;
    const [removed] = left.splice(droppableSource.index, 1);
    setItems(left);
    right.unshift(removed);
    if (params.type == 'channels' || params.type == 'cams' || params.type == 'radio') {
      right.sort((a, b) => a.number - b.number);
    }
    setSelected(right);
    updateContent(right);
  };
  const moveLeft = (droppableSource: any, droppableDestination: any) => {
    var left = Array.from(items) as any;
    var right = Array.from(selected) as any;
    const [removed] = right.splice(droppableSource.index, 1);
    left.push(removed);
    if (params.type == 'channels' || params.type == 'cams' || params.type == 'radio') {
      left.sort((a, b) => a.number - b.number);
    } else {
      left.sort(function (a, b) {
        return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
      });
    }
    setItems(left);
    setSelected(right);
    updateContent(right);
  };

  const onDragEnd = (result: { source: any; destination?: any }) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === 'destination') {
        const itemsReOrder = reorder(selected, source.index, destination.index);
        setSelectedOriginal(itemsReOrder);
        setSelected(itemsReOrder);
        updateContent(itemsReOrder);
      }
    } else {
      if (source.droppableId === 'source') {
        moveRight(source, destination);
      } else {
        moveLeft(source, destination);
      }
    }
  };

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    userSelect: 'none',
    padding: 7,
    margin: `0 0 8px 0`,
    background: isDragging ? '#ebebeb' : '#fff',
    border:  "1px solid #ebebeb",
    borderRadius: 3,
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver: any) => ({
    background: isDraggingOver ? '#667ca839' : '#fff',
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 8,
    paddingBottom: 1,
    marginBottom: 20,
    height: '100vh',
    borderRadius: 2,
    overflow: "Scroll",
  });

  const deleteItem = (index: any) => {
    const sourceClone = Array.from(selected);
    const destClone = Array.from(items);
    const [removed] = sourceClone.splice(index, 1);
    destClone.splice(index, 0, removed);
    setItems(destClone);
    setSelected(sourceClone);
    updateContent(sourceClone);
  };
  const moveAllRight = () => {
    const left = Array.from(items);
    const right = Array.from(selected);
    const newClone = right.concat(left);
    setItems([]);
    setSelected(newClone);
    updateContent(newClone);
  };
  const moveAllLeft = () => {
    const right = Array.from(selected);
    const left = Array.from(items);
    const newClone = left.concat(right);
    setItems(newClone);
    setSelected([]);
    updateContent([]);
  };

  const onChangeSource = (e: any) => {
    if (e.target.value == '') {
      setItems(itemsOriginal);
    } else {
      const filteredResult = itemsOriginal.filter((res) => {
        return res.name.toLowerCase().includes(e.target.value.toLowerCase());
      });
      setItems(filteredResult);
    }
  };
  const onChangeDestination = (e: any) => {
    if (e.target.value == '') {
      setSelected(selectedOriginal);
    } else {
      const filteredResult = selectedOriginal.filter((res) => {
        return res.name.toLowerCase().includes(e.target.value.toLowerCase());
      });
      setSelected(filteredResult);
    }
  };

  const getAlbums = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsAlbums.List_Albums(list);
    if (response && response.status_code === StatusCodes.OK) {
      setItemsOriginal(
        response.data.data.sort(function (a, b) {
          return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
        })
      );
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      getPackageAlbum(response.data.data);
    }
    setIsLoading(false);
  };
  const getPackageAlbum = async (items_: any) => {
    setIsLoading(true);
    let response = await cmsAlbumsPackage.Details_Album_Bundles({
      _id: params_._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      setPackage_(response.data);
      var resp = response.data as any;
      setSelected(resp.categories[Number(params_.index)].albums);

      var sel = resp.categories[Number(params_.index)].albums;
      var newArray = [] as any;
      items_.forEach((item: any) => {
        var test = sel.find((c: any) => c._id == item._id);
        if (test == undefined) {
          newArray.push(item);
        }
      });
      setItems(newArray);
    }
    setIsLoading(false);
  };
  const updateAlbumPackage = async (content: any) => {
    var categories_ = package_.categories;
    categories_[Number(params_.index)] = {
      ...categories_[Number(params_.index)],
      albums: content,
    };
    let response = await cmsAlbumsPackage.Update_Album_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
      categories: categories_,
    });
  };

  const getCams = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsCams.List_Cams(list);
    if (response && response.status_code === StatusCodes.OK) {
      setItemsOriginal(response.data.data.sort((a, b) => a.number - b.number));
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      getPackageCam(response.data.data);
    }
    setIsLoading(false);
  };
  const getPackageCam = async (items_: any) => {
    setIsLoading(true);
    let response = await cmsCamsPackage.Details_Cam_Bundles({
      _id: params_._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      setPackage_(response.data);
      var resp = response.data as any;
      setSelected(resp.categories[Number(params_.index)].cams);

      var sel = resp.categories[Number(params_.index)].cams;
      var newArray = [] as any;
      items_.forEach((item: any) => {
        var test = sel.find((c: any) => c._id == item._id);
        if (test == undefined) {
          newArray.push(item);
        }
      });
      setItems(newArray);
    }
    setIsLoading(false);
  };
  const updateCamPackage = async (content: any) => {
    var categories_ = package_.categories;
    categories_[Number(params_.index)] = {
      ...categories_[Number(params_.index)],
      cams: content,
    };
    let response = await cmsCamsPackage.Update_Cam_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
      categories: categories_,
    });
  };

  const getRadio = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsRadio.List_Radios(list);
    if (response && response.status_code === StatusCodes.OK) {
      setItemsOriginal(response.data.data.sort((a, b) => a.number - b.number));
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      getPackageRadio(response.data.data);
    }
    setIsLoading(false);
  };
  const getPackageRadio = async (items_: any) => {
    setIsLoading(true);
    let response = await cmsRadioPackage.Details_Radio_Bundles({
      _id: params_._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      setPackage_(response.data);
      var resp = response.data as any;
      setSelected(resp.categories[Number(params_.index)].radios);

      var sel = resp.categories[Number(params_.index)].radios;
      var newArray = [] as any;
      items_.forEach((item: any) => {
        var test = sel.find((c: any) => c._id == item._id);
        if (test == undefined) {
          newArray.push(item);
        }
      });
      setItems(newArray);
    }
    setIsLoading(false);
  };
  const updateRadioPackage = async (content: any) => {
    var categories_ = package_.categories;
    categories_[Number(params_.index)] = {
      ...categories_[Number(params_.index)],
      radios: content,
    };
    let response = await cmsRadioPackage.Update_Radio_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
      categories: categories_,
    });
  };

  const getChannels = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsChannels.List_Channels(list);
    if (response && response.status_code === StatusCodes.OK) {
      setItemsOriginal(response.data.data.sort((a, b) => a.number - b.number));
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      getPackageChannel(response.data.data);
    }
    setIsLoading(false);
  };
  const getPackageChannel = async (items_: any) => {
    setIsLoading(true);
    let response = await cmsChannelsPackage.Details_Channel_Bundles({
      _id: params_._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      setPackage_(response.data);
      var resp = response.data as any;
      setSelected(resp.categories[Number(params_.index)].channels);

      var sel = resp.categories[Number(params_.index)].channels;
      var newArray = [] as any;
      items_.forEach((item: any) => {
        var test = sel.find((c: any) => c._id == item._id);
        if (test == undefined) {
          newArray.push(item);
        }
      });
      setItems(newArray);
    }
    setIsLoading(false);
  };
  const updateChannelPackage = async (content: any) => {
    var categories_ = package_.categories;
    categories_[Number(params_.index)] = {
      ...categories_[Number(params_.index)],
      channels: content,
    };
    let response = await cmsChannelsPackage.Update_Channel_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
      categories: categories_,
    });
  };

  const getPodcasts = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsPodcasts.List_Podcasts(list);
    if (response && response.status_code === StatusCodes.OK) {
      setItemsOriginal(
        response.data.data.sort(function (a, b) {
          return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
        })
      );
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      getPackagePodcast(response.data.data);
    }
    setIsLoading(false);
  };
  const getPackagePodcast = async (items_: any) => {
    setIsLoading(true);
    let response = await cmsPodcastsPackage.Details_Podcast_Bundles({
      _id: params_._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      setPackage_(response.data);
      var resp = response.data as any;
      setSelected(resp.categories[Number(params_.index)].podcasts);

      var sel = resp.categories[Number(params_.index)].podcasts;
      var newArray = [] as any;
      items_.forEach((item: any) => {
        var test = sel.find((c: any) => c._id == item._id);
        if (test == undefined) {
          newArray.push(item);
        }
      });
      setItems(newArray);
    }
    setIsLoading(false);
  };
  const updatePodcastPackage = async (content: any) => {
    var categories_ = package_.categories;
    categories_[Number(params_.index)] = {
      ...categories_[Number(params_.index)],
      podcasts: content,
    };
    let response = await cmsPodcastsPackage.Update_Podcast_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
      categories: categories_,
    });
  };

  const getSeries = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsSeries.List_Seriess(list);
    if (response && response.status_code === StatusCodes.OK) {
      setItemsOriginal(
        response.data.data.sort(function (a, b) {
          return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
        })
      );
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      getPackageSeries(response.data.data);
    }
    setIsLoading(false);
  };
  const getPackageSeries = async (items_: any) => {
    setIsLoading(true);
    let response = await cmsSeriesPackage.Details_Series_Bundles({
      _id: params_._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      setPackage_(response.data);
      var resp = response.data as any;
      setSelected(resp.categories[Number(params_.index)].series);

      var sel = resp.categories[Number(params_.index)].series;
      var newArray = [] as any;
      items_.forEach((item: any) => {
        var test = sel.find((c: any) => c._id == item._id);
        if (test == undefined) {
          newArray.push(item);
        }
      });
      setItems(newArray);
    }
    setIsLoading(false);
  };
  const updateSeriesPackage = async (content: any) => {
    var categories_ = package_.categories;
    categories_[Number(params_.index)] = {
      ...categories_[Number(params_.index)],
      series: content,
    };
    let response = await cmsSeriesPackage.Update_Series_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
      categories: categories_,
    });
  };

  const getMovies = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsMovies.List_Movies(list);
    if (response && response.status_code === StatusCodes.OK) {
      setItemsOriginal(
        response.data.data.sort(function (a, b) {
          return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
        })
      );
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      getPackageMovie(response.data.data);
    }
    setIsLoading(false);
  };
  const getPackageMovie = async (items_: any) => {
    setIsLoading(true);
    let response = await cmsMoviesPackage.Details_Movie_Bundles({
      _id: params_._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      setPackage_(response.data);
      var resp = response.data as any;
      setSelected(resp.categories[Number(params_.index)].movies);

      var sel = resp.categories[Number(params_.index)].movies;
      var newArray = [] as any;
      items_.forEach((item: any) => {
        var test = sel.find((c: any) => c._id == item._id);
        if (test == undefined) {
          newArray.push(item);
        }
      });
      setItems(newArray);
    }
    setIsLoading(false);
  };
  const updateMoviePackage = async (content: any) => {
    var categories_ = package_.categories;
    categories_[Number(params_.index)] = {
      ...categories_[Number(params_.index)],
      movies: content,
    };
    let response = await cmsMoviesPackage.Update_Movie_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
      categories: categories_,
    });
  };

  const getCoursess = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsCourses.List_Courses(list);
    if (response && response.status_code === StatusCodes.OK) {
      setItemsOriginal(
        response.data.data.sort(function (a, b) {
          return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
        })
      );
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      getPackageCourses(response.data.data);
    }
    setIsLoading(false);
  };
  const getPackageCourses = async (items_: any) => {
    setIsLoading(true);
    let response = await cmsCoursesPackage.Details_Course_Bundles({
      _id: params_._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      setPackage_(response.data);
      var resp = response.data as any;
      setSelected(resp.categories[Number(params_.index)].courses);

      var sel = resp.categories[Number(params_.index)].courses;
      var newArray = [] as any;
      items_.forEach((item: any) => {
        var test = sel.find((c: any) => c._id == item._id);
        if (test == undefined) {
          newArray.push(item);
        }
      });
      setItems(newArray);
    }
    setIsLoading(false);
  };
  const updateCoursesPackage = async (content: any) => {
    var categories_ = package_.categories;
    categories_[Number(params_.index)] = {
      ...categories_[Number(params_.index)],
      courses: content,
    };
    let response = await cmsCoursesPackage.Update_Course_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
      categories: categories_,
    });
  };

  const getGames = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsGames.List_Games(list);
    if (response && response.status_code === StatusCodes.OK) {
      setItemsOriginal(
        response.data.data.sort(function (a, b) {
          return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
        })
      );
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      getPackageGames(response.data.data);
    }
    setIsLoading(false);
  };
  const getPackageGames = async (items_: any) => {
    setIsLoading(true);
    let response = await cmsGamesPackage.Details_Games_Bundles({
      _id: params_._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      setPackage_(response.data);
      var resp = response.data as any;
      setSelected(resp.categories[Number(params_.index)].games);

      var sel = resp.categories[Number(params_.index)].games;
      var newArray = [] as any;
      items_.forEach((item: any) => {
        var test = sel.find((c: any) => c._id == item._id);
        if (test == undefined) {
          newArray.push(item);
        }
      });
      setItems(newArray);
    }
    setIsLoading(false);
  };
  const updateGamesPackage = async (content: any) => {
    var categories_ = package_.categories;
    categories_[Number(params_.index)] = {
      ...categories_[Number(params_.index)],
      games: content,
    };
    let response = await cmsGamesPackage.Update_Games_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
      categories: categories_,
    });
  };

  const updateContent = (content: any) => {
    switch (params.type) {
      case 'albums':
        (async () => await updateAlbumPackage(content))();
        break;
      case 'cams':
        (async () => await updateCamPackage(content))();
        break;
      case 'radio':
        (async () => await updateRadioPackage(content))();
        break;
      case 'channels':
        (async () => await updateChannelPackage(content))();
        break;
      case 'movies':
        (async () => await updateMoviePackage(content))();
        break;
      case 'series':
        (async () => await updateSeriesPackage(content))();
        break;
      case 'podcasts':
        (async () => await updatePodcastPackage(content))();
        break;
      case 'courses':
        (async () => await updateCoursesPackage(content))();
        break;
      case 'games':
        (async () => await updateGamesPackage(content))();
        break;
    }
  };

  useEffectOnce(() => {
    switch (params.type) {
      case 'albums':
        (async () => await getAlbums(tableSearchState))();
        break;
      case 'cams':
        (async () => await getCams(tableSearchState))();
        break;
      case 'radio':
        (async () => await getRadio(tableSearchState))();
        break;
      case 'channels':
        (async () => await getChannels(tableSearchState))();
        break;
      case 'movies':
        (async () => await getMovies(tableSearchState))();
        break;
      case 'series':
        (async () => await getSeries(tableSearchState))();
        break;
      case 'podcasts':
        (async () => await getPodcasts(tableSearchState))();
        break;

      case 'courses':
        (async () => await getCoursess(tableSearchState))();
        break;
      case 'games':
        (async () => await getGames(tableSearchState))();
        break;
    }
  });
  const sortArr = (arr) => {
    return arr.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  };
  const [tags, setTags] = useState([] as any);

  const [provider, setProvider] = useState('');
  const [tag, setTag] = useState('');

  const selectTag = (tag_) => {
    if (tag_ != 'AllContent' && provider == '') {
      var filteredByTag = itemsOriginal.filter((item) => {
        if (params.type == 'series') {
          return item?.seasons[0]?.tags?.some((t) => t.tag[0]?.description === tag_.split(',')[0]);
        } else {
          return item?.tags?.some((t) => t.tag[0]?.description === tag_.split(',')[0]);
        }
      });
      setTag(tag_);
      setItems(filteredByTag);
    } else if (tag_ != 'AllContent' && provider != '') {
      var filteredByTag = itemsOriginal.filter((item) => {
        if (params.type == 'series') {
          return item?.seasons[0]?.tags?.some((t) => t.tag[0]?.description === tag_.split(',')[0]);
        } else {
          return item?.tags?.some((t) => t.tag[0]?.description === tag_.split(',')[0]);
        }
      });
      filteredByTag = filteredByTag.filter((p) => p.provider?.name == provider);
      setTag(tag_);
      setItems(filteredByTag);
    } else {
      setItems(itemsOriginal);
    }
  };
  const getTags = async () => {
    let response = await tagsService.List_Tags({
      query_params: {
        instance: clientName,
        cms: deployment?.name || '',
      },
      pageNumber: 1,
      pageSize: 10000,
    });
    if (response && response.status_code === StatusCodes.OK) {
      var res = response.data.data;
      var tags = [] as any;
      res.forEach((element) => {
        if (element.tag != undefined) {
          if (element.tag.length > 0) {
            var length = element.tag.length;
            var pu = '';
            element.tag.forEach((el, index) => {
              pu += el.description + (index < length - 1 ? ', ' : '');
            });
            tags.push({ name: pu });
          }
        }
      });
      setTags(sortArr(tags));
    }
  };
  const selectProvider = (provider_) => {
    if (provider_ != 'AllContent' && tag == '') {
      var filteredByProvider = itemsOriginal.filter((p) => p.provider?.name == provider_);
      setProvider(provider_);
      setItems(filteredByProvider);
    } else if (provider_ != 'AllContent' && tag != '') {
      var filteredByProvider = itemsOriginal.filter((p) => p.provider?.name == provider_);
      filteredByProvider = filteredByProvider.filter((item) => {
        if (params.type == 'series') {
          return item?.seasons[0]?.tags?.some((t) => t.tag[0]?.description === tag.split(',')[0]);
        } else {
          return item?.tags?.some((t) => t.tag[0]?.description === tag.split(',')[0]);
        }
      });
      setProvider(provider_);
      setItems(filteredByProvider);
    } else {
      setProvider('');
      setItems(itemsOriginal);
    }
  };

  const [providers, setProviders] = useState([] as any);
  const getProviders = async () => {
    let response = await providerService.List_Provider({
      query_params: {
        instance: clientName,
        cms: deployment?.name || '',
      },
      pageNumber: 1,
      pageSize: 10000,
    });
    if (response && response.status_code === StatusCodes.OK) {
      setProviders(sortArr(response.data.data));
    }
  };

  useEffectOnce(() => {
    getProviders();
    getTags();
  });

  return (
    <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
      {isLoading && (
        <div className="row text-center h-100 w-100">
          <div className="col-12 align-self-center">
            <Spin size="large" spinning={true} />
          </div>
        </div>
      )}
      {!isLoading && (
        <div className="row">
          <div className="col-md-5">
            <h4 className="text-primary mt-3">{t('your-0')} {params.type}</h4>
            <p>{t('drag-items-to-the-right-to-add-them-to-your-category')}</p>
            <div className="row-md-12">
              <Select style={{ width: '100%' }} onChange={selectProvider} className="default-select" placeholder={t('filter-by-provider')}>
                <Select.Option value={'AllContent'} key={'AllContent'}>
                  {t('all-content')}
                </Select.Option>
                {providers.map((provider, index) => {
                  return (
                    <Select.Option value={provider.name} key={index}>
                      {provider.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
            <div className="row-md-12">
              <Select style={{ width: '100%' }} onChange={selectTag} className="default-select" placeholder={t('filter-by-tag')}>
                <Select.Option value={'AllContent'} key={'AllContent'}>
                  {t('all-content-0')}
                </Select.Option>
                {tags.map((tag, index) => {
                  return (
                    <Select.Option value={tag.name} key={index}>
                      {tag.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
            <Input allowClear={true} onChange={onChangeSource} className="bg-white form-control-extra font-size-14 text-default mb-2 mt-2" placeholder={t('filter') + params.type} />
            <Droppable droppableId="source">
              {(provided, snapshot) => (
                <div className="ant-table ant-table-override" ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                  {items.map((item: any, index: any) => (
                    <Draggable key={index} draggableId={'item-' + String(index)} index={index}>
                      {(provided, snapshot) => (
                        <div
                          //onClick={(e) => handleRowSelection(index, e.metaKey, e.shiftKey)}
                          className="row"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <Image style={{ backgroundColor: '#fafafa' }} src={'https://cloudtv.akamaized.net/' + (params.type == 'movies' ? item.images.poster : params.type == 'series' || params.type == 'courses' || params.type == 'shorts' || params.type == 'games' ? item.images.widescreen : item.images.square)} width={params.type == 'series' || params.type == 'courses' ? '150px' : '100px'} preview={false} />
                          {(params.type == 'radio' || params.type == 'channels' || params.type == 'cams') && (
                            <div style={{ alignSelf: 'center', marginLeft: 20 }}>
                              {item.number}. {item.name} <br />
                              {item.meta}
                            </div>
                          )}
                          {params.type != 'radio' && params.type != 'channels' && params.type != 'cams' && <div style={{ alignSelf: 'center', marginLeft: 20 }}>{item.name}</div>}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
          <div className="col-md-1 mt-5" style={{ textAlign: 'center', paddingTop: 100 }}>
            <h4 className="text-primary mt-3 mb-3">&nbsp;</h4>
            <div style={{ height: 100 }}></div>
            <div className="mb-2">
              <FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={() => moveAllRight()} color={'#999'} size={'2x'} className="align-left, align-center" icon={faChevronsRight} />
            </div>
            <div>
              <FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={() => moveAllLeft()} color={'#999'} size={'2x'} className="align-left, align-center" icon={faChevronsLeft} />
            </div>
          </div>
          <div className="col-md-6">
            <h4 className="text-primary mt-3">{t('your-category')} {params.category}</h4>
            <p>{t('drag-items-to-the-left-to-remove-them-and-up-down-to-alter-position')}</p>
            <div style={{ height: 100 }}></div>
            <Input allowClear={true} onChange={onChangeDestination} className="form-control-extra bg-white font-size-14 text-default mb-2 mt-2" placeholder={t('filter-0') + params.category} />
            <Droppable droppableId="destination">
              {(provided, snapshot) => (
                <div className="ant-table ant-table-override" ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                  {selected.length == 0 && (
                    <div className="row" style={{ justifyContent: 'center', alignItems: 'center', height: 200 }}>
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                  )}
                  {selected.map((item: any, index: any) => (
                    <Draggable key={index} draggableId={'item' + String(index)} index={index}>
                      {(provided, snapshot) => (
                        <div className="row" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                          <Image style={{ backgroundColor: '#fafafa' }} src={'https://cloudtv.akamaized.net/' + (params.type == 'movies' ? item.images.poster : params.type == 'series' || params.type == 'courses' || params.type == 'shorts' || params.type == 'games' ? item.images.widescreen : item.images.square)} width={params.type == 'series' || params.type == 'courses' ? '150px' : '100px'} preview={false} />
                          {(params.type == 'radio' || params.type == 'channels' || params.type == 'cams') && (
                            <div style={{ alignSelf: 'center', marginLeft: 20 }}>
                              {item.number}. {item.name} <br />
                              {item.meta}
                            </div>
                          )}
                          {params.type != 'radio' && params.type != 'channels' && params.type != 'cams' && <div style={{ alignSelf: 'center', marginLeft: 20 }}>{item.name}</div>}

                          <FontAwesomeIcon onClick={() => deleteItem(index)} color={'#999'} size={'xl'} className="align-right" style={{ position: 'absolute', right: 25, cursor: 'pointer', paddingLeft: 10, paddingRight: 10, paddingTop: 13 }} icon={faXmark} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </div>
      )}
    </DragDropContext>
  );
};
