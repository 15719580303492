import { Button, Form, Input, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { StatusCodes } from 'http-status-codes';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import cmsService from '../../../../../../../services/areas/content/series/series';
import { IMSNameParams } from '../../../../../../dashboard';
import { ContentContext } from '../../../../../../../contexts/contentContext';
import { Series } from '../../../../../../../interfaces/forms/areas/content/series/series';
import ParentalControl from '../../../../../../../common/components/Content/Parental/Parental';
import Placement from '../../../../../../../common/components/Content/Placement/Placement';
import Providers from '../../../../../../../common/components/Content/Providers/Providers';

import { AuthContext } from '../../../../../../../context_api/AuthContext';
import { TVMSRoles } from '../../../../../../../constants/TVMSRoles';

const AddNewMusicAlbum = (data: any) => {
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(ContentContext);
  const [isAdding, setIsAdding] = useState(false);
  const [placement, setPlacement] = useState([] as any);
  const [parental, setParental] = useState({
    enabled: false,
    rating: [] as any,
  });

  const [form] = useForm();
  const { t } = useTranslation();
  const [provider, setProvider] = useState('');
  const { state: AuthState } = useContext(AuthContext);
  const isProvider = AuthState.user?.role === TVMSRoles.Provider ? true : false;

  useEffect(() => {
    setPlacement(data.data.placement);
    setParental(data.data.parental);
    setProvider(data.data.provider?.name);
    form.setFieldsValue(data.data);
  }, [data]);

  const handleSubmit = async (values: Series) => {
    setIsAdding(true);
    if (data.data.payperview != undefined) {
      if (data.data.payperview.currencies != undefined) {
        if (data.data.payperview.currencies.length > 0) {
          if (data.data.payperview.currencies[0].price != undefined) {
            var curs = [] as any;
            deployment?.currencies.forEach((cur, index) => {
              curs.push({
                iso3: cur.isoCurrency,
                credits_rented: 0,
                credits_purchased: 0,
                key: index,
              });
            });
            data.data.payperview = {
              enable: data.data.payperview.enable,
              rule: '',
              currencies: curs,
            };
          } else {
            var curs = [] as any;
            deployment?.currencies.forEach((cur, index) => {
              curs.push({
                iso3: cur.isoCurrency,
                credits_rented: 0,
                credits_purchased: 0,
                key: index,
              });
            });
            data.data.payperview = {
              enable: data.data.payperview.enable,
              rule: '',
              currencies: curs,
            };
          }
        }
      }
    }
    values = {
      ...data.data,
      name: values.name,
      imdbid: values.imdbid ?? '',
      tmdbid: values.tmdbid ?? '',
      placement: placement,
      parental: parental,
      tags: [],
      provider: {
        name: isProvider ? AuthState?.user?.name : provider,
      },
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    };
    let response = await cmsService.Update_Series(values as any);
    if (response.status_code === StatusCodes.OK) {
      notification.success({
        message: t('added-successfully'),
      });
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
    setIsAdding(false);
  };

  return (
    <div className="container-fluid ">
      <Form
        form={form}
        onFinish={(values: any) => {
          handleSubmit(values);
        }}
        onFinishFailed={(errorInfo) => {}}
      >
        <div className="card pb-4">
          <div className="card-body card-ch-padding">
            <h4 className="text-primary mt-5 mb-3">{t('details')}</h4>
            <div className="card-bg-light pl-5 pt-3">
              <div className="row">
                <label className="col-md-3 col-form-label">{t('name')}</label>
                <Form.Item
                  className="col-md-7"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t('name-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('series-name')} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-3 col-form-label">{t('imdb-id')}</label>
                <Form.Item className="col-md-7" name="imdbid">
                  <Input className="form-control bg-white font-size-14 text-default" placeholder="ttasdf99" />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-3 col-form-label">{t('tmdb-id')}</label>
                <Form.Item className="col-md-7" name="tmdbid">
                  <Input className="form-control bg-white font-size-14 text-default" placeholder="123415" />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-3 col-form-label">{t('trailer')}</label>
                <Form.Item className="col-md-7" name="trailer">
                  <Input className="form-control bg-white font-size-14 text-default" placeholder="https://..." />
                </Form.Item>
              </div>
            </div>
            {!isProvider && <Providers data={provider} setProvider={setProvider} />}
            <ParentalControl data={parental} setParentalControl={setParental} />
            <Placement data={placement} setPlacement={setPlacement} />
          </div>
        </div>
        <div className="text-right">
          <Button htmlType="submit" disabled={isAdding} loading={isAdding} className="mt-4 mb-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
            {t('update-series')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddNewMusicAlbum;
