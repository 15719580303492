import {  faDashboard } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Layout, Menu } from 'antd';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import { Link, useParams } from 'react-router-dom';
import MWare_Logo from '../../../assets/images/logo.png';
import IMSDashboardHeader from '../../../common/components/IMSDashboardHeader';
import * as DeploymentService from '../../../services/deployments/deployment';
import { DrawerContext } from '../../../context_api/DrawerContext';
import { useQuery } from '@tanstack/react-query';
import DashboardIndex from './dashboard/index';
import { useLocation } from 'react-use';
import { getExpandedMenu, handleOnClick } from '../../../utils/menu';
import { hasRole } from '../../../utils/Auth/role.auth';
import { AuthContext } from '../../../context_api/AuthContext';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportingContextProvider } from '../../../contexts/reportingContext';
import DesignerIndex from './designer';
import { TVMSRoles } from '../../../constants/TVMSRoles';
import PrivateRoute from '../../../common/routes/PrivateRoute';
import { isInRoles } from '../../../common/components/VisibleForRole';

const Reporting = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { path, url } = useRouteMatch();
  const { state: DrawerState } = React.useContext(DrawerContext);
  const { clientName, deployment, service } = useParams<{ clientName: string; deployment: string; service: string }>();

  const { data, isLoading, isError } = useQuery(['deployment', deployment], async () => {
    return DeploymentService.find(clientName, { name: deployment })
      .then((r) => r.data)
      .catch((e) => Promise.reject(e));
  });

  const { state: AuthState } = useContext(AuthContext);
  const isClient = hasRole(AuthState.user!, clientName, ['superadmin', 'admin']);
  const hasEditPermissions = isInRoles(AuthState.user?.role, [TVMSRoles.SuperAdmin, TVMSRoles.Admin, TVMSRoles.Employee]);

  var locSplit = location?.pathname?.split('/');
  var redirectPath = '';
  if (locSplit != undefined) {
    redirectPath = locSplit.slice(0, 4 - locSplit.length).join('/');
  }

  let menuItems = [
    {
      label: (
        <div className="height-60 d-flex align-items-center">
          <FontAwesomeIcon icon={faDashboard} size="lg" style={{ width: '20', height: '20' }} />
          <span className="ml-3">{t('dashboard')}</span>
        </div>
      ),
      key: 'dashboard',
      children: [
        {
          label: (
            <Link className="height-60 d-flex align-items-center ml-3" to={`${url}`}>
              <span className="ml-3">{t('dashboard')}</span>
            </Link>
          ),
          key: 'reporting-dashboard',
        },
      ],
    },
  ];

  if (hasEditPermissions) {

    menuItems = menuItems.concat([
      {
        label: (
          <div className="height-60 d-flex align-items-center">
            <FontAwesomeIcon icon={faDashboard} size="lg" style={{ width: '20', height: '20' }} />
            <span className="ml-3">{t('chart-design-designer')}</span>
          </div>
        ),
        key: 'designer',
        children: [
          {
            label: (
              <Link className="height-60 d-flex align-items-center ml-3" to={`${url}/designer`}>
                <span className="ml-3">{t('chart-design-designer')}</span>
              </Link>
            ),
            key: 'reporting-designer',
          },
        ],
      },
    ]);

  }

  return (
    <Layout>
      <Layout.Sider width="250" trigger={null} collapsible collapsed={DrawerState.is_collapsed}>
        <div style={{ justifyContent: 'center', alignContent: 'center', display: 'flex', padding: 40, backgroundColor: '#444', paddingTop: 70, paddingBottom: 70, marginBottom: 17 }}>
          {!isClient && (
            <Link to={'/'} onClick={() => {}} key="Mware">
              <img className="expand" src={DrawerState.white_labeling.logo || MWare_Logo} title={t('mware')} alt={t('mware-0')} style={DrawerState.white_labeling.logo ? { maxWidth: 150, maxHeight: 50 } : {}} />
            </Link>
          )}
          {isClient && (
            <a href={redirectPath} key="Mware">
              <img className="expand" src={DrawerState.white_labeling.logo || MWare_Logo} title={t('mware-1')} alt={t('mware-2')} style={DrawerState.white_labeling.logo ? { maxWidth: 150, maxHeight: 50 } : {}} />
            </a>
          )}
        </div>
        <Menu
          mode="inline"
          defaultOpenKeys={getExpandedMenu()}
          onClick={handleOnClick}
          items={menuItems}
        />
      </Layout.Sider>
      <div style={{ paddingLeft: 0, width: '100%' }}>
        <div>
          <IMSDashboardHeader
            {...{
              isSideBarShow: true,
              path,
            }}
          />
        </div>
        {/* {isLoading && <Spin />}  */}
        {isError && <span>{t('something-went-wrong')}</span>}
        {!isLoading && !isError && data && (
          <ReportingContextProvider value={{ deployment: data, service: data.services.find((x) => x.name === service) }}>
            <Switch>
              <PrivateRoute path={`${path}/designer`} allowedRoles={[TVMSRoles.SuperAdmin, TVMSRoles.Admin, TVMSRoles.Employee]} component={DesignerIndex} />
              <Route path={`${path}`}>{isClient && <DashboardIndex />}</Route>
            </Switch>
          </ReportingContextProvider>
        )}
      </div>
    </Layout>
  );
};

export default Reporting;
