import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { useParams } from 'react-router';
import { CardForm, FormSection, Item, Input, Select, Switch, FormHeadingWithAddNewForm, Table, Button } from '../../../../../common/components/form';
import { AddressProfile } from '@mwaretv/database/build/backend/enums/billing/addressProfile';
import { Col, Form, FormInstance, Tooltip } from 'antd';
import { BillingContext } from '../../../../../contexts/billingContext';
import * as ProductService from '../../../../../services/areas/billing/productService';
import * as WalletService from '../../../../../services/areas/billing/walletService';
import { validEmail } from '../../../../../utils/validators';
import { DesignFields } from '../../shared/designFields';
import { usePaymentProviders } from '../../../../../services/areas/billing/paymentProviderService';
import { PaymentProvider } from '../../../../../interfaces/forms/areas/billing/paymentProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { Spin } from '../../../../../common/components/spinner';
import { useEmailGateways } from '../../../../../services/areas/billing/emailGatewayService';
import { useSmsGateways } from '../../../../../services/areas/billing/smsGatewayService';
import { usePromotionReminderList } from '../../../../../services/areas/billing/promotionService';
import { useRenewalReminderList } from '../../../../../services/areas/billing/renewalReminderService';
import { useDunningReminderList } from '../../../../../services/areas/billing/dunningReminderService';
import { useRegistrationReminderList } from '../../../../../services/areas/billing/registrationService';
import { CopyBlock, monoBlue } from 'react-code-blocks';
import { useWelcomeMessageList } from '../../../../../services/areas/billing/welcomeMessage';
import { usePasswordRecoveryMessageList } from '../../../../../services/areas/billing/passwordRecoveryService';
import { usePaymentReceivedMessageList } from '../../../../../services/areas/billing/paymentReceivedService';
import { useChangeBaseSubscriptionMessageList } from '../../../../../services/areas/billing/changeBaseSubscriptionService';
import { useAddExtraSubscriptionMessageList } from '../../../../../services/areas/billing/addExtraSubscriptionService';
import { useRemoveExtraSubscriptionMessageList } from '../../../../../services/areas/billing/removeExtraSubscriptionService';
import { usePaymentFailedMessageList } from '../../../../../services/areas/billing/paymentFailedMessage';
import { useTranslation } from 'react-i18next';

export { CardForm as Form, DesignFields };

export const FormFields = () => {
  const { clientName } = useParams<{ clientName: string }>();
  const { deployment, service } = useContext(BillingContext);
  const {t} = useTranslation();
  return (
    <>
      <GeneralFields clientName={clientName} deployment={deployment?.name ?? ''} service={service?.name ?? ''} _id={''} />
      <ProductFields />
      <CustomerFields />
      <FormSection title={t('design')} subtitle={t('create-a-design-for-your-shopping-cart-this-will-influence-the-look-and-feel-of-the-shopping-cart')}>
        <DesignFields whiteBackgrounds />
      </FormSection>
      <PaymentMethodFields />
      <NotificationFields />
    </>
  );
};

export const NotificationFields: React.FC = () => {
  const { clientName } = useParams<{ clientName: string }>();
  const { deployment, service } = useContext(BillingContext);
  const { data: emailGateways } = useEmailGateways({ params: { instance: clientName, cms: deployment?.name ?? '', crm: service?.name || '', page: 1, limit: 9999 } });
  const { data: smsGateways } = useSmsGateways({ params: { instance: clientName, cms: deployment?.name ?? '', crm: service?.name || '', page: 1, limit: 9999 } });
  const {t} = useTranslation();
  const emailGatewayOptions = emailGateways?.data.docs.map<{ label: string; value: string | undefined | null }>((x) => ({ label: x.name, value: x._id }));

  emailGatewayOptions?.splice(0, 0, {
    label: t('no-gateway'),
    value: null,
  });

  const smsGatewayOptions = smsGateways?.data.docs.map<{ label: string; value: string | undefined | null }>((x) => ({ label: x.name, value: x._id }));

  smsGatewayOptions?.splice(0, 0, {
    label: t('no-gateway-0'),
    value: null,
  });

  return (
    <FormSection title={t('message-gateways')} subtitle={t('select-the-message-gateways-that-will-be-used-to-sent-emails-or-sms-messages-on-registrations-renewals-etc')}>
      <Item label={t('gateway-for-sending-emails')} name={['email_gateway']} initialValue={null}>
        <Select options={emailGatewayOptions} />
      </Item>
      <Item label={t('gateway-for-sending-sms-messages')} name={['sms_gateway']} initialValue={null}>
        <Select options={smsGatewayOptions} />
      </Item>
    </FormSection>
  );
};

export const PaymentMethodFields = () => {
  const { clientName } = useParams<{ clientName: string }>();
  const { deployment, service } = useContext(BillingContext);
  const formInstance = Form.useFormInstance();
  const paymentGateways: PaymentProvider[] = Form.useWatch(['payment_gateways']);
  const paymentGatewayIds = (paymentGateways || []).map((x) => x._id);
  const { isLoading, isError, data } = usePaymentProviders({ params: { instance: clientName, cms: deployment?.name ?? '', crm: service?.name || '', page: 1, limit: 9999 } });
  const {t} = useTranslation();
  return (
    <>
      <Item hidden name={['payment_gateways']} />
      <FormHeadingWithAddNewForm
        title={t('payment-gateways-0')}
        subtitle={t('select-the-payment-gateways-your-customers-can-use-to-subcribe-or-renew-their-subscriptions-with')}
        buttonText={t('add-gateway')}
        onOk={(form: FormInstance<any>) => {
          const gatewayId = form.getFieldValue(['payment_gateway']);
          const gateway = (data?.data.docs || []).find((x) => x._id === gatewayId);

          if (gateway) formInstance.setFieldValue(['payment_gateways'], [...(paymentGateways || []), ...[gateway]]);
        }}
      >
        {isLoading && <Spin />}
        {isError && <span>{t('something-went-wrong-getting-the-payment-gateways-please-try-again-later')}</span>}
        {!isLoading && !isError && (
          <Item name={['payment_gateway']} wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} label={t('payment-gateway')} extra={t('select-a-payment-gateway-to-add-to-this-shopping-cart')}>
            <Select options={data?.data.docs.filter((x) => !paymentGatewayIds.includes(x._id)).map((x) => ({ label: x.name, value: x._id }))} />
          </Item>
        )}
      </FormHeadingWithAddNewForm>
      {isLoading && <Spin />}
      {isError && <span>{t('something-went-wrong-getting-the-payment-gateways-please-try-again-later-0')}</span>}
      {!isLoading && !isError && (
        <Table
          dataSource={paymentGateways || []}
          columns={[
            { title: t('name'), dataIndex: 'name', key: 'name' },
            {
              title: t('enabled-30'),
              key: 'enabled',
              render: (row) => {
                return <span>{row.enabled ? t('yes') : t('no')}</span>;
              },
            },
            // { title: 'Environment', dataIndex: 'environment', key: 'environment' },
            {
              title: t('actions'),

              render: (row) => {
                return (
                  <Col style={{ display: 'flex', flexDirection: 'row', height: 60 }}>
                    <Tooltip placement="top" title={t('delete')}>
                      <Button
                        type="text"
                        className="mx-2"
                        onClick={() => {
                          formInstance.setFieldValue(['payment_gateways'], [...paymentGateways.filter((x) => x._id !== row._id)]);
                        }}
                      >
                        <FontAwesomeIcon className="action-icon" icon={faTrashCan} />
                        <div>{t('delete')}</div>
                      </Button>
                    </Tooltip>
                  </Col>
                );
              },
            },
          ]}
        />
      )}
    </>
  );
};

export const ProductFields: React.FC = () => {
  const { clientName } = useParams<{ clientName: string }>();
  const { deployment, service } = useContext(BillingContext);
  const {t} = useTranslation();
  const { isLoading, isError, data } = useQuery(['products', deployment?.name, service?.name], async () => {
    var products = await ProductService.list({ params: { instance: clientName, cms: deployment?.name ?? '', crm: service?.name || '', limit: 9999, page: 1 } });
    var productsOut = [] as any;
    products.data.docs.forEach((product) => {
      if (product.plans != undefined) {
        if (product.plans?.plans.length > 0) {
          productsOut.push(product);
        }
      }
    });
    return productsOut;
  });

  return (
    <>
      <FormSection title={t('products')} subtitle={t('select-which-products-you-want-to-offer-in-this-shopping-cart')}>
        <div style={{ height: '100%' }}>
          <div className="mt-5 mb-5">
            <Item name="products" label={t('selected-base-products')} extra="">
              <Select mode="multiple" options={!isLoading && !isError ? data.map((x) => ({ label: x.name + ' - (' + x.type + ')', value: x._id, disabled: x.type == 'EXTRA' })) : []} />
            </Item>
          </div>
          <div className="mb-5">
            <Item name="products" label={t('selected-extra-products')} extra="">
              <Select mode="multiple" options={!isLoading && !isError ? data.map((x) => ({ label: x.name + ' - (' + x.type + ')', value: x._id, disabled: x.type == 'BASE' })) : []} />
            </Item>
          </div>
        </div>
      </FormSection>

      <WalletFields />
    </>
  );
};

export const WalletFields: React.FC = () => {
  const { clientName } = useParams<{ clientName: string }>();
  const { deployment, service } = useContext(BillingContext);
  const {t} = useTranslation();
  const { isLoading, isError, data } = useQuery(['wallets', deployment?.name, service?.name], async () => {
    return await WalletService.list({ params: { instance: clientName, cms: deployment?.name ?? '', crm: service?.name || '', limit: 9999, page: 1 } });
  });

  return (
    <FormSection title={t('wallet-package')} subtitle={t('select-a-wallet-package-to-use-offer-to-your-customers')}>
      <div className="mt-5" style={{ height: '100%' }}>
        <div className="mb-5">
          <Item name="wallet" label={t('selected-wallet-package')} extra="">
            <Select options={!isLoading && !isError ? data.data.docs.map((x) => ({ label: x.name, value: x._id })) : []} />
          </Item>
        </div>
      </div>
    </FormSection>
  );
};

export const ReminderFields: React.FC = () => {
  const { clientName } = useParams<{ clientName: string }>();
  const { deployment, service } = useContext(BillingContext);
  const {t} = useTranslation();

  const { data: registrationReminders } = useRegistrationReminderList({
    params: { instance: clientName, cms: deployment?.name || '', crm: service?.name || '' },
  });

  const { data: promotionReminders } = usePromotionReminderList({
    params: { instance: clientName, cms: deployment?.name ?? '', crm: service?.name || '' },
  });

  const { data: renewalReminders } = useRenewalReminderList({
    params: { instance: clientName, cms: deployment?.name ?? '', crm: service?.name || '' },
  });

  const { data: dunningReminders } = useDunningReminderList({
    params: { instance: clientName, cms: deployment?.name ?? '', crm: service?.name || '' },
  });

  const { data: welcomeMessage } = useWelcomeMessageList({
    params: { instance: clientName, cms: deployment?.name || '', crm: service?.name || '' },
  });

  const { data: passwordRecoveryMessage } = usePasswordRecoveryMessageList({
    params: { instance: clientName, cms: deployment?.name || '', crm: service?.name || '' },
  });

  const { data: paymentReceivedMessage } = usePaymentReceivedMessageList({
    params: { instance: clientName, cms: deployment?.name || '', crm: service?.name || '' },
  });

  const { data: paymentFailedMessage } = usePaymentFailedMessageList({
    params: { instance: clientName, cms: deployment?.name || '', crm: service?.name || '' },
  });

  const { data: changeBaseSubscription } = useChangeBaseSubscriptionMessageList({
    params: { instance: clientName, cms: deployment?.name || '', crm: service?.name || '' },
  });
  const { data: addExtraSubscription } = useAddExtraSubscriptionMessageList({
    params: { instance: clientName, cms: deployment?.name || '', crm: service?.name || '' },
  });
  const { data: removeExtraSubscription } = useRemoveExtraSubscriptionMessageList({
    params: { instance: clientName, cms: deployment?.name || '', crm: service?.name || '' },
  });

  return (
    <FormSection title={t('messages')} subtitle={t('select-the-messages-that-are-used-in-combination-with-the-apps-shopping-carts-and-customer-portals')}>
      <Item name="registration_reminders" label={t('registration-message')} extra={t('this-message-is-used-to-sent-customers-who-sign-up-via-the-app-an-email-or-sms-to-finish-the-registration-via-the-shopping-cart')}>
        <Select options={registrationReminders?.data.map((x) => ({ label: x.name ?? "No Name", value: x._id })) ?? []} />
      </Item>
      <Item name="welcome_message" label={t('welcome-message')} extra={t('this-message-is-used-to-sent-customers-who-sign-up-via-the-app-an-email-or-sms-to-finish-the-registration-via-the-shopping-cart-0')}>
        <Select options={welcomeMessage?.data.map((x) => ({ label: x.name ??  "No Name", value: x._id })) ?? []} />
      </Item>
      <Item name="payment_received_message" label={t('payment-received')} extra={t('this-message-is-used-to-sent-customers-who-paid-for-something-to-show-its-paid-and-how-it-was-paid')}>
        <Select options={paymentReceivedMessage?.data.map((x) => ({ label: x.name ??  "No Name", value: x._id })) ?? []} />
      </Item>
      <Item name="payment_failed_message" label={t('payment-failed')} extra={t('this-message-is-used-to-sent-customers-who-paid-for-something-to-show-its-failed-to-be-paid')}>
        <Select options={paymentFailedMessage?.data.map((x) => ({ label: x.name ??  "No Name", value: x._id })) ?? []} />
      </Item>
      <Item name="change_base_subscription_message" label={t('change-base-subscription')} extra={t('this-message-is-used-to-sent-customers-confirmation-they-changed-their-base-subscription')}>
        <Select options={changeBaseSubscription?.data.map((x) => ({ label: x.name ??  "No Name", value: x._id })) ?? []} />
      </Item>
      <Item name="add_extra_subscription_message" label={t('add-extra-subscription')} extra={t('this-message-is-used-to-sent-customers-confirmation-they-have-added-an-extra-subscription')}>
        <Select options={addExtraSubscription?.data.map((x) => ({ label: x.name ??  "No Name", value: x._id })) ?? []} />
      </Item>
      <Item name="remove_extra_subscription_message" label={t('remove-extra-subscription')} extra={t('this-message-is-used-to-sent-customers-confirmation-they-have-removed-cancelled-and-extra-subscription')}>
        <Select options={removeExtraSubscription?.data.map((x) => ({ label: x.name ??  "No Name", value: x._id })) ?? []} />
      </Item>
      <Item name="password_recovery_message" label={t('password-recovery-message')} extra={t('this-message-is-used-to-send-to-customers-who-are-resetting-their-password')}>
        <Select options={passwordRecoveryMessage?.data.map((x) => ({ label: x.name ??  "No Name", value: x._id })) ?? []} />
      </Item>
      <Item name="promotion_reminders" label={t('promotion-messages')} extra={t('these-are-used-to-promote-customers-from-trial-to-a-paid-subscription')}>
        <Select mode="multiple" options={promotionReminders?.data.map((x) => ({ label: `${x.time} ${x.period}`, value: x._id })) ?? []} />
      </Item>
      <Item name="renewal_reminders" label={t('renewal-messages')} extra={t('these-are-used-to-inform-customers-about-an-upcoming-expiration-and-let-them-renew')}>
        <Select mode="multiple" options={renewalReminders?.data.map((x) => ({ label: `${x.time} ${x.period}`, value: x._id })) ?? []} />
      </Item>
      <Item name="dunning_reminders" label={t('dunning-messages')} extra={t('these-are-used-to-try-get-the-customer-back-after-the-subscription-has-expired')}>
        <Select mode="multiple" options={dunningReminders?.data.map((x) => ({ label: `${x.time} ${x.period}`, value: x._id })) ?? []} />
      </Item>
    </FormSection>
  );
};

export const GeneralFields: React.FC<{
  name?: string;
  including_tax?: boolean;
  enable_coupons?: boolean;
  domain?: string;
  app_stores?: any;
  website_cart?: string;
  website_portal?: string;
  clientName: string;
  deployment: string;
  service: string;
  _id: string;
}> = ({ name, including_tax, website_cart, website_portal, clientName, deployment, service, _id, app_stores, enable_coupons }) => {
  const cartHash = btoa(JSON.stringify({ client: clientName, deployment: deployment, service: service, portalId: _id }));
  const {t} = useTranslation();
  return (
    <>
      <FormSection title={t('shopping-cart')}>
        <Item label={t('name')} name="name" initialValue={name} rules={[{ required: true, message: t('name-is-required') }]}>
          <Input placeholder={t('name')} />
        </Item>
      </FormSection>
      <FormSection title={t('taxation')}>
        <Item label={t('show-with-tax')} name="including_tax" valuePropName="checked">
          <Switch />
        </Item>
      </FormSection>
      <FormSection title={t('coupons')}>
        <Item extra={t('this-enables-the-coupons-field-that-can-be-used-for-affiliates-and-coupons-in-order-to-give-customers-a-discount')} label={t('enable-coupons')} name="enable_coupons" valuePropName="checked">
          <Switch />
        </Item>
      </FormSection>
      <FormSection title={t('website-domain-and-path')} subtitle={t('please-enter-the-full-domain-plus-path-to-the-page-you-are-going-to-serve-the-shopping-cart-iframe-on-this-url-is-used-to-guide-customers-to-your-website-who-are-interested-in-signing-up-or-renew')}>
        <Item label={t('shopping-cart-website-url')} name="website_cart" initialValue={website_cart} rules={[{ required: true, message: t('name-is-required') }]}>
          <Input type={'url'} placeholder="https://somedomain.com/products" />
        </Item>
      </FormSection>
      <FormSection title={t('shopping-cart-iframe')} subtitle={t('copy-the-code-below-and-paste-this-on-a-dedicated-shopping-page-named-products-for-example-on-your-website')}>
        <div className="row-md-12 ml-2 mr-2 mb-5">
          <CopyBlock
            wrapLines
            text={
             '<iframe id=\"myiframe\" sandbox=\"allow-top-navigation allow-forms allow-modals allow-orientation-lock allow-popups allow-same-origin allow-scripts allow-top-navigation\" scrolling autoplay fullscreen seamless allow=\"fullscreen\" referrerpolicy=\"unsafe-url\" style=\"border: none; display: block;background: #000;border: none;height: 200vh;width: 100vw;\" loading=\"lazy\" src=\"' +
              process.env.REACT_APP_SHOP_URL_BASE +
              '/' +
              '?o=shop' +
              '&h=' +
              cartHash +
              '/\"><p>Your browser does not support iframes.</p></iframe><script language=\"javascript\"> var iframe = document.getElementById(\"myiframe\");iframe.src = iframe.src + window.location.search;window.ScrollToTop = function(){scroll(0,0)};</script>'
            }
            language={'html'}
            theme={monoBlue}
          />
        </div>
      </FormSection>
      <FormSection title={t('website-domain-and-path-0')} subtitle={t('please-enter-the-full-domain-plus-path-to-the-page-you-are-going-to-serve-this-customer-portal-iframe-on-this-url-is-used-to-guide-customers-to-your-website-who-are-interested-in-signing-up-or-renew')}>
        <Item label={t('customer-portal-website-url')} name="website_portal" initialValue={website_portal} rules={[{ required: true, message: t('name-is-required')}]}>
          <Input type={'url'} placeholder="https://somedomain.com/portal" />
        </Item>
      </FormSection>
      <FormSection title={t('customer-portal-iframe')} subtitle={t('copy-the-code-below-and-paste-this-on-a-dedicated-customer-portal-page-named-customers-for-example-on-your-website')}>
        <div className="row-md-12 ml-2 mr-2 mb-5">
          <CopyBlock
            wrapLines
            text={
              '<iframe id=\"myiframe\" sandbox=\"allow-top-navigation allow-forms allow-modals allow-orientation-lock allow-popups allow-same-origin allow-scripts allow-top-navigation\" scrolling  autoplay fullscreen seamless allow=\"fullscreen\" referrerpolicy=\"unsafe-url\" style=\"border: none; display: block;background: #000;border: none;height: 200vh;width: 100vw;\" loading=\"lazy\" src=\"'
             + process.env.REACT_APP_CUSTOMER_PORTAL_BASE +
              '/' +
              '?o=home' +
              '&h=' +
              cartHash +
              '/\"><p>Your browser does not support iframes.</p></iframe><script language=\"javascript\"> var iframe = document.getElementById(\"myiframe\");iframe.src = iframe.src + window.location.search;window.ScrollToTop = function(){scroll(0,0)};</script>'
            }
            language={'html'}
            theme={monoBlue}
          />
        </div>
      </FormSection>
      <FormSection title={t('app-stores')} subtitle={t('set-the-app-store-urls-for-android-and-apple-and-explain-how-to-search-the-other-app-stores-to-find-the-app-this-information-is-presented-on-the-thank-you-page-of-the-shopping-cart-and-in-the-customer-portal-and-in-the-app-greater-than-settings-greater-than-other-app-menu')}>
        <Item className="m-2" label={t('google')} name={['app_stores', 'google_url']} initialValue={app_stores && app_stores.google_url ? app_stores?.google_url : ''}>
          <Input placeholder="https://appstore.url" />
        </Item>
        <Item className="m-2" label=" " name={['app_stores', 'google_text']} initialValue={app_stores && app_stores?.google_text ? app_stores?.google_text : ''}>
          <Input placeholder={t('explain-how-to-find-the-app')} />
        </Item>

        <Item className="m-2" label={t('amazon')} name={['app_stores', 'amazon_url']} initialValue={app_stores && app_stores?.amazon_url ? app_stores?.amazon_url : ''}>
          <Input placeholder="https://appstore.url" />
        </Item>
        <Item className="m-2" label=" " name={['app_stores', 'amazon_text']} initialValue={app_stores && app_stores?.amazon_text ? app_stores?.amazon_text : ''}>
          <Input placeholder={t('explain-how-to-find-the-app-0')} />
        </Item>

        <Item className="m-2" label={t('apple-0')} name={['app_stores', 'apple_url']} initialValue={app_stores && app_stores?.apple_url ? app_stores?.apple_url : ''}>
          <Input placeholder="https://appstore.url" />
        </Item>
        <Item className="m-2" label=" " name={['app_stores', 'apple_text']} initialValue={app_stores && app_stores?.apple_text ? app_stores?.apple_text : ''}>
          <Input placeholder={t('explain-how-to-find-the-app-1')} />
        </Item>

        <Item className="m-2" label={t('samsung')} name={['app_stores', 'samsung_url']} initialValue={app_stores && app_stores?.samsung_url ? app_stores?.samsung_url : ''}>
          <Input placeholder="https://appstore.url" />
        </Item>
        <Item className="m-2" label=" " name={['app_stores', 'samsung_text']} initialValue={app_stores && app_stores?.samsung_text ? app_stores?.samsung_text : ''}>
          <Input placeholder={t('explain-how-to-find-the-app-2')} />
        </Item>

        <Item className="m-2" label="LG" name={['app_stores', 'lg_url']} initialValue={app_stores && app_stores?.lg_url ? app_stores?.lg_url : ''}>
          <Input placeholder="https://appstore.url" />
        </Item>
        <Item className="m-2" label=" " name={['app_stores', 'lg_text']} initialValue={app_stores && app_stores?.lg_text ? app_stores?.lg_text : ''}>
          <Input placeholder={t('explain-how-to-find-the-app-3')} />
        </Item>

        <Item className="m-2" label={t('vidaa')} name={['app_stores', 'vidaa_url']} initialValue={app_stores && app_stores.vidaa_url ? app_stores.vidaa_url : ''}>
          <Input placeholder="https://appstore.url" />
        </Item>
        <Item className="m-2" label=" " name={['app_stores', 'vidaa_text']} initialValue={app_stores && app_stores.vidaa_text ? app_stores.vidaa_text : ''}>
          <Input placeholder={t('explain-how-to-find-the-app-4')} />
        </Item>

        <Item className="m-2" label={t('roku')} name={['app_stores', 'roku_url']} initialValue={app_stores && app_stores.roku_url ? app_stores.roku_url : ''}>
          <Input placeholder="https://appstore.url" />
        </Item>
        <Item className="m-2" label=" " name={['app_stores', 'roku_text']} initialValue={app_stores && app_stores.roku_text ? app_stores.roku_text : ''}>
          <Input placeholder={t('explain-how-to-find-the-app-5')} />
        </Item>

        <Item className="m-2" label={t('apk-sideload')} name={['app_stores', 'apk_url']} initialValue={app_stores && app_stores.apk_url ? app_stores.apk_url : ''}>
          <Input placeholder="https://linktoyourapk.url" />
        </Item>
        <Item className="m-2" label=" " name={['app_stores', 'apk_text']} initialValue={app_stores && app_stores?.apk_text ? app_stores?.apk_text : ''}>
          <Input placeholder={t('explain-how-to-install-the-app')} />
        </Item>
      </FormSection>
    </>
  );
};

export const EmailFields: React.FC<{
  invoices?: string;
  support?: string;
}> = ({ invoices, support }) => {
  const {t} = useTranslation();
  return (
    <FormSection title={t('emails')} subtitle={t('choose-email-addresses-that-will-be-used-to-communicatie-to-the-customer')}>
      <Item
        label={t('invoices')}
        name={['emails', 'invoices']}
        initialValue={invoices}
        rules={[
          { required: true, message: t('invoices-is-required') },
          { validator: validEmail, message: t('invoices-should-be-a-valid-email-address') },
        ]}
        extra={t('this-email-address-will-be-used-to-send-invoices-to-the-customer')}
      >
        <Input placeholder={t('choose-an-email-for-invoices')} />
      </Item>
      <Item
        label={t('support')}
        name={['emails', 'support']}
        initialValue={support}
        rules={[
          { required: true, message: t('support-is-required') },
          { validator: validEmail, message: t('support-should-be-a-valid-email-address') },
        ]}
        extra={t('email-address-used-for-support-emails')}
      >
        <Input placeholder={t('choose-an-email-for-support')} />
      </Item>
    </FormSection>
  );
};

export const CustomerFields: React.FC<{}> = () => {
  const {t} = useTranslation();
  return (
    <FormSection title={t('customer')} subtitle={t('configure-which-information-is-needed-from-the-user-to-complete-a-purchase')}>
      <Item label={t('address')} name={['customer', 'address']} rules={[{ required: true, message: t('address-is-required') }]} extra={t('how-much-information-is-asked-from-the-customer-no-address-will-only-ask-for-a-country-while-minimal-will-not-require-all-information-to-be-filled-in')}>
        <Select
          options={[
            { value: AddressProfile.FULL, label: t('full-address') },
            { value: AddressProfile.MINIMAL, label: t('minimal-address') },
            { value: AddressProfile.NONE, label: t('no-address')},
          ]}
        />
      </Item>
      <Item hidden label={t('enable-otp')} name={['customer', 'enable_otp']} valuePropName="checked" extra={t('enable-one-time-password-for-customers')}>
        <Switch />
      </Item>
      <Item label={t('mandatory-phone')} name={['customer', 'mandatory_phone']} valuePropName="checked" extra={t('is-a-phone-number-for-the-customer-required-either-a-phone-number-or-email-address-will-be-required')}>
        <Switch />
      </Item>
      <Item label={t('mandatory-email')} name={['customer', 'mandatory_email']} valuePropName="checked" extra={t('is-a-email-address-for-the-customer-required-either-a-phone-number-or-email-address-will-be-required')}>
        <Switch />
      </Item>
    </FormSection>
  );
};
