import { Button, Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';

const Translations = () => {
  const { t } = useTranslation();
  return (
    <div>
      <p>{t('to-give-the-stream-provider-more-details-about-the-customer-making-the-request-include-query-parameters-in-the-stream')}</p>
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <label className="col-md-3 col-form-label">{t('name')}</label>
            <Form.Item className="col-md-9" name={['name']} rules={[{ required: true }]}>
              <Input className="form-control bg-white font-size-14 text-default" placeholder={t('my-params')} />
            </Form.Item>
          </div>
          <div className="card-bg-light p-2 pt-4">
            <h5 className="text-primary ml-3 mb-3">{t('params-builder')}</h5>
            <Form.List name={'params'}>
              {(fields, { add, remove }) => {
                return (
                  <>
                    {fields.map((field, index) => {
                      return (
                        <div key={field.key} className="col-md-12 row">
                          <Form.Item className="col-md-5" name={[index, 'name']} rules={[{ required: true }]}>
                            <Input className="form-control bg-white font-size-14 text-default" placeholder={t('e-g-ip')} />
                          </Form.Item>
                          <Form.Item className="col-md-5" name={[index, 'type']} rules={[{ required: true }]}>
                            <Select>
                              <Select.Option value={'Random Number'} key={1}>
                                {t('random-number-0')}
                              </Select.Option>
                              <Select.Option value={'UUID'} key={2}>
                                {t('unique-device-id')}
                              </Select.Option>
                              <Select.Option value={'App Bundle'} key={3}>
                                {t('app-bundle-0')}
                              </Select.Option>
                              <Select.Option value={'App Store Url'} key={4}>
                                {t('app-store-url-0')}
                              </Select.Option>
                              <Select.Option value={'App Store ID'} key={5}>
                                {t('app-store-id-0')}
                              </Select.Option>
                              <Select.Option value={'App Store Name'} key={6}>
                                {t('app-store-name-0')}
                              </Select.Option>
                              <Select.Option value={'App Category'} key={7}>
                                {t('app-category-0')}
                              </Select.Option>
                              <Select.Option value={'App Version'} key={8}>
                                {t('app-version-0')}
                              </Select.Option>
                              <Select.Option value={'App Domain'} key={9}>
                                {t('app-domain-0')}
                              </Select.Option>
                              <Select.Option value={'Site Domain'} key={10}>
                                {t('site-domain-0')}
                              </Select.Option>
                              <Select.Option value={'Site Name'} key={11}>
                                {t('site-name-0')}
                              </Select.Option>
                              <Select.Option value={'Content ID'} key={12}>
                                {t('content-id')}
                              </Select.Option>
                              <Select.Option value={'Content Name'} key={13}>
                                {t('content-name')}
                              </Select.Option>
                              <Select.Option value={'Content Name Per Typ'} key={14}>
                                {t('content-name-per-type-0')}
                              </Select.Option>
                              <Select.Option value={'IAB Category'} key={15}>
                                {t('iab-category-0')}
                              </Select.Option>
                              <Select.Option value={'In App or Browser'} key={16}>
                                {t('in-app-or-browser-0')}
                              </Select.Option>
                              <Select.Option value={'Device User Agent'} key={17}>
                                {t('devices-user-agent')}
                              </Select.Option>
                              <Select.Option value={'Device Model'} key={18}>
                                {t('device-model-0')}
                              </Select.Option>
                              <Select.Option value={'Device OS'} key={19}>
                                {t('device-os-0')}
                              </Select.Option>
                              <Select.Option value={'Device Type'} key={20}>
                                {t('device-type-0')}
                              </Select.Option>
                              <Select.Option value={'Device OS Version'} key={36}>
                                {t('device-os-version-0')}
                              </Select.Option>
                              <Select.Option value={'Device Make'} key={21}>
                                {t('device-make-0')}
                              </Select.Option>
                              <Select.Option value={'Device ID Type'} key={22}>
                                {t('device-id-type-0')}
                              </Select.Option>
                              <Select.Option value={'Device Connection Type'} key={23}>
                                {t('device-connection-type-0')}
                              </Select.Option>
                              <Select.Option value={'User IP Address'} key={24}>
                                {t('user-ip-address-0')}
                              </Select.Option>
                              <Select.Option value={'User Country'} key={25}>
                                {t('user-country-0')}
                              </Select.Option>
                              <Select.Option value={'User Latitude'} key={26}>
                                {t('user-latitude-0')}
                              </Select.Option>
                              <Select.Option value={'User Longitude'} key={27}>
                                {t('user-longitude-0')}
                              </Select.Option>
                              <Select.Option value={'Geo Type'} key={28}>
                                {t('geo-type-0')}
                              </Select.Option>
                              <Select.Option value={'Height'} key={29}>
                                {t('height-0')}
                              </Select.Option>
                              <Select.Option value={'Width'} key={30}>
                                {t('width-0')}
                              </Select.Option>
                              <Select.Option value={'Output'} key={31}>
                                {t('output-0')}
                              </Select.Option>
                              <Select.Option value={'US Privacy'} key={32}>
                                {t('us-privacy-0')}
                              </Select.Option>
                              <Select.Option value={'Empty'} key={33}>
                                {t('empty-0')}
                              </Select.Option>
                              <Select.Option value={'Add a 0'} key={34}>
                                {t('add-a-0-0')}
                              </Select.Option>
                              <Select.Option value={'Add a 1'} key={35}>
                                {t('add-a-1-0')}
                              </Select.Option>
                            </Select>
                          </Form.Item>
                          {fields.length > 0 ? (
                            <Button style={{ backgroundColor: 'red', border: '1px solid red' }} className="ant-btn btn btn-primary rounded-pill col-md-2 height-48" onClick={() => remove(field.name)}>
                              {t('remove')}
                            </Button>
                          ) : null}
                        </div>
                      );
                    })}
                    <Form.Item>
                      <div className="text-left" style={{ marginLeft: 15 }}>
                        <Button block onClick={() => add()} className="ant-btn btn btn-secondary rounded-pill width-120 height-48">
                          {t('add')}
                        </Button>
                      </div>
                    </Form.Item>
                  </>
                );
              }}
            </Form.List>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Translations;
