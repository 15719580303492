import { Button, Form, Input, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { StatusCodes } from 'http-status-codes';
import { useCallback, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { ContentContext } from '../../../../../contexts/contentContext';
import { Epg_Settings } from '../../../../../interfaces/forms/areas/content/epg/epg_settings';
import cmsService from '../../../../../services/areas/content/epg/epg_settings';
import { IMSNameParams } from '../../../../dashboard';

const AddNewMusicAlbum = () => {
  const params = useParams<IMSNameParams & { deployment: string }>();
  const [isAdding, setIsAdding] = useState(false);
  const [settingsId, setSettingsId] = useState('');
  const [form] = useForm();
const { t } = useTranslation();
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(ContentContext);
  const [open, setOpen] = useState(false);
  const [timeZoneName, setTimeZoneName] = useState('');

  const handleClose = useCallback(
    (newTimeZoneName: string) => {
      setTimeZoneName(newTimeZoneName);
      form.setFieldValue('name', newTimeZoneName);
      setOpen(false);
    },
    [form]
  );

  const addSettings = async (values: Epg_Settings) => {
    
    if (settingsId !== '' && settingsId !== undefined) {
      setIsAdding(true);
      values = {
        _id: settingsId,
        ...values,
        query_params: {
          instance: clientName,
          cms: params.deployment ?? '',
        },
      };
      let response = await cmsService.Update_Epg_Settings(values as any);
      if (response.status_code === StatusCodes.OK) {
        notification.success({
          message: t('added-successfully'),
        });
      } else {
        notification.error({
          message: t('an-error-occured'),
        });
      }
      setIsAdding(false);
    } else {
      setIsAdding(true);
      values = {
        ...values,
        query_params: {
          instance: clientName,
          cms: deployment?.name ?? '',
        },
      };

      let response = await cmsService.Add_Epg_Settings(values as any);
      if (response.status_code === StatusCodes.OK) {
        notification.success({
          message: t('added-successfully'),
        });
      } else {
        notification.error({
          message: t('an-error-occured'),
        });
      }
      setIsAdding(false);
    }
  };

  const getSettings = async () => {
    setIsAdding(true);
    var values = {
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    };
    let response = await cmsService.Details_Epg_Settings(values as any);
    if (response !== undefined && response.status_code === StatusCodes.OK) {
      var resp = response.data as any;
      form.setFieldsValue(resp);
      setSettingsId(resp._id);
    }
    setIsAdding(false);
  };

  useEffectOnce(() => {
    (async () => await getSettings())();
  });

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-6">
          <h2 className="page-title">{t('guide-settings')}</h2>
          <p className="h6 font-weight-normal line-height-26">{t('set-your-guide-data-gap-text-and-define-your-retention-period')}</p>
        </div>
      </div>
      <Form
        form={form}
        onFinish={(values: any) => {
          addSettings(values);
        }}
        onFinishFailed={(errorInfo) => {}}
      >
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <div className="card-bg-light pl-5 pt-5 mt-3">
              <div className="row">
                <label className="col-md-3 col-form-label">{t('n-a-text-program-name')}</label>
                <Form.Item
                  extra={t('the-n-a-text-is-used-to-fill-the-gaps-in-your-guide-data-or-if-you-have-no-data-at-all')}
                  className="col-md-5"
                  name="na_text"
                  rules={[
                    {
                      required: true,
                      message: t('n-a-text-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('not-available')} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-3 col-form-label">{t('n-a-text-description')}</label>
                <Form.Item
                  extra={t('the-n-a-text-is-used-to-fill-the-gaps-in-your-guide-data-or-if-you-have-no-data-at-all-0')}
                  className="col-md-5"
                  name="na_desc"
                  rules={[
                    {
                      required: true,
                      message: t('n-a-text-is-required-0'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('not-available-0')} />
                </Form.Item>
              </div>

              <div className="row">
                <label className="col-md-3 col-form-label">{t('guide-retention-days')}</label>
                <Form.Item className="col-md-5" name="epg_days" extra={t('the-guide-data-retention-period-is-used-to-set-the-value-on-how-many-days-a-customer-can-go-back-in-time-to-see-guide-data-in-the-apps')}>
                  <Input defaultValue={3} type={'number'} className="form-control bg-white font-size-14 text-default p-3rem" placeholder="3" />
                </Form.Item>
              </div>
              <div className="row mb-4">
                <label className="col-md-3 col-form-label">{t('timezone-selection')}</label>
                <div className="col-md-5">
                  <Button className={'ant-btn btn btn-secondary rounded-pill height-48 mr-2'} onClick={() => setOpen(!open)}>
                    {t('select-timezone')}
                  </Button>
                  <Button className={'ant-btn btn btn-secondary rounded-pill height-48'} onClick={() => setTimeZoneName('')}>
                    {t('clear-timezone')}
                  </Button>
                  <p className='mt-3'>{t('when-using-tv-profile-as-epg-source-the-imported-epg-can-be-cropped-to-precisely-fit-the-requirements-of-your-area-by-choosing-a-timezone-which-will-speed-up-loading-the-app-by-50-leave-this-setting-unchecked-if-your-sales-are-international-or-over-many-time-zones')}</p>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {t('selected-timezone-and-nbsp')}<b>{timeZoneName}</b>
                  </div>
                </div>
              </div>
              {/* <TimeZoneSelectDialog open={open} timeZoneName={timeZoneName} onClose={handleClose} /> */}
            </div>
          </div>
        </div>
        <div className="text-right">
          <Button htmlType="submit" disabled={isAdding} loading={isAdding} className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
            {t('add-update-settings')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddNewMusicAlbum;
