import { AvailableChartTypesReporting, AvailableEntitiesReporting, ChartDesignFilterDataTypes, CommunicationSource, CommunicationType, CustomerProductStatus, CustomerStatus, CustomerSubscriptionStatus, OrderTypes, ProductType, ProductVariant, TransactionSource, actionType, contentType, mainType, pageType } from "@mwaretv/database/build/backend/models/reporting/chartdesigns/chartDesignEnums";
import { ICountry } from "../../../interfaces/responses/common/ICountriesResponse";

export class ChartDesignField {
  public name: string;
  public translationKey: string;
  public dataType: ChartDesignFilterDataTypes;
  public availableForFilter: boolean;
  public availableForGroupBy: boolean;
  public availableForAccumulator: boolean;
  public availableForDistinct: boolean;

  public elementFilterFields?: ChartDesignField[];
  public choices?: Choice[];

  constructor(name: string, translationKey: string, dataType: ChartDesignFilterDataTypes, availableForFilter: boolean, availableForGroupBy: boolean, availableForAccumulator: boolean, availableForDistinct: boolean, elementFilterFields?: ChartDesignField[], choices?: Choice[]) {
    this.name = name;
    this.translationKey = translationKey;
    this.dataType = dataType;
    this.availableForFilter = availableForFilter;
    this.availableForGroupBy = availableForGroupBy;
    this.availableForAccumulator = availableForAccumulator;
    this.availableForDistinct = availableForDistinct;
    this.elementFilterFields = elementFilterFields;
    this.choices = choices;
  }
}

export class Choice {
  public name?: string;
  public translationKey?: string;
  
  constructor(name: string, translationKey: string) {
    this.name = name;
    this.translationKey = translationKey;
  }
}

export class ChartDesignAvailability {

  public static getEntities = (countries?: ICountry[]): AvailableChartDesignEntity[] => {

    const entities: AvailableChartDesignEntity[] = [
      {
        name: AvailableEntitiesReporting.APP_ACTION,
        translationKey: "chart-design-appaction-entity",
        fields: [
          new ChartDesignField("actionType", "chart-design-action-type", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getChoices((Object.values(actionType) as Array<actionType>).map(value => value.toString()), "action-type")),
          new ChartDesignField("timestamp", "chart-design-date", ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
          new ChartDesignField("timestamp@dayOfMonth", "chart-design-date-per-day", ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
          new ChartDesignField("timestamp@week", "chart-design-date-per-week", ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
          new ChartDesignField("timestamp@month", "chart-design-date-per-month", ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
          new ChartDesignField("timestamp@year", "chart-design-date-per-year", ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
          new ChartDesignField("app.version", "chart-design-app-version", ChartDesignFilterDataTypes.STRING, true, false, false, false),
          new ChartDesignField("app.package", "chart-design-app-package", ChartDesignFilterDataTypes.STRING, true, false, false, false),
          new ChartDesignField("location.city", "chart-design-location-city", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("location.country", "chart-design-location-country", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getCountryChoices(countries)),
          new ChartDesignField("location.latitude", "chart-design-location-latitude", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("location.longitude", "chart-design-location-longitude", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("network.type", "chart-design-network-type", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("network.provider", "chart-design-network-provider", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("device.model", "chart-design-device-model", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("device.uuid", "chart-design-device-uuid", ChartDesignFilterDataTypes.STRING, false, false, false, true),
          new ChartDesignField("device.type", "chart-design-device-type", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("device.os", "chart-design-device-os", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("device.formFactor", "chart-design-device-formfactor", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("customer.username", "chart-design-customer-username", ChartDesignFilterDataTypes.STRING, false, false, false, true),
          new ChartDesignField("customer.status", "chart-design-customer-status", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getChoices((Object.values(CustomerStatus) as Array<CustomerStatus>).map(value => value.toString()), "customer-status")),
          new ChartDesignField("customer.zipcode", "chart-design-customer-zipcode", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("customer.state", "chart-design-customer-state", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("customer.city", "chart-design-customer-city", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("customer.country", "chart-design-customer-country", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getCountryChoices(countries)),
          new ChartDesignField("customer.is_deleted", "chart-design-customer-isdeleted", ChartDesignFilterDataTypes.BOOLEAN, true, false, false, false),
          new ChartDesignField("customer.productStatus", "chart-design-customer-product-status", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getChoices((Object.values(CustomerProductStatus) as Array<CustomerProductStatus>).map(value => value.toString()), "customer-product-status")),
          new ChartDesignField("customer.subscriptionStatus", "chart-design-customer-subscription-status", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getChoices((Object.values(CustomerSubscriptionStatus) as Array<CustomerSubscriptionStatus>).map(value => value.toString()), "customer-subscription-status")),
          new ChartDesignField("customer.renewedDate", "chart-design-renewed-date", ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
          new ChartDesignField("customer.renewalDate", "chart-design-renewal-date", ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
          new ChartDesignField("customer.payment_provider", "chart-design-customer-paymentprovider", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("customer.payment_auto_recurring", "chart-design-customer-paymentautorecurring", ChartDesignFilterDataTypes.BOOLEAN, true, false, false, false),
          new ChartDesignField("customer.plan.name", "chart-design-customer-plan-name", ChartDesignFilterDataTypes.STRING, true, true, false, false),
        ],
      } as AvailableChartDesignEntity,
      {
        name: AvailableEntitiesReporting.APP_ERROR,
        translationKey: "chart-design-apperror-entity",
        fields: [
          new ChartDesignField("errorType", "chart-design-error-type", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("timestamp", "chart-design-date", ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
          new ChartDesignField("timestamp@dayOfMonth", "chart-design-date-per-day", ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
          new ChartDesignField("timestamp@week", "chart-design-date-per-week", ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
          new ChartDesignField("timestamp@month", "chart-design-date-per-month", ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
          new ChartDesignField("timestamp@year", "chart-design-date-per-year", ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
          new ChartDesignField("app.version", "chart-design-app-version", ChartDesignFilterDataTypes.STRING, true, false, false, false),
          new ChartDesignField("app.package", "chart-design-app-package", ChartDesignFilterDataTypes.STRING, true, false, false, false),
          new ChartDesignField("location.city", "chart-design-location-city", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("location.country", "chart-design-location-country", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getCountryChoices(countries)),
          new ChartDesignField("network.type", "chart-design-network-type", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("network.provider", "chart-design-network-provider", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("device.model", "chart-design-device-model", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("device.uuid", "chart-design-device-uuid", ChartDesignFilterDataTypes.STRING, false, false, false, true),
          new ChartDesignField("device.type", "chart-design-device-type", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("device.os", "chart-design-device-os", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("device.formFactor", "chart-design-device-formfactor", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("customer.username", "chart-design-customer-username", ChartDesignFilterDataTypes.STRING, false, false, false, true),
          new ChartDesignField("customer.status", "chart-design-customer-status", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getChoices((Object.values(CustomerStatus) as Array<CustomerStatus>).map(value => value.toString()), "customer-status")),
          new ChartDesignField("customer.zipcode", "chart-design-customer-zipcode", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("customer.state", "chart-design-customer-state", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("customer.city", "chart-design-customer-city", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("customer.country", "chart-design-customer-country", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getCountryChoices(countries)),
          new ChartDesignField("customer.is_deleted", "chart-design-customer-isdeleted", ChartDesignFilterDataTypes.BOOLEAN, true, false, false, false),
          new ChartDesignField("customer.productStatus", "chart-design-customer-product-status", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getChoices((Object.values(CustomerProductStatus) as Array<CustomerProductStatus>).map(value => value.toString()), "customer-product-status")),
          new ChartDesignField("customer.subscriptionStatus", "chart-design-customer-subscription-status", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getChoices((Object.values(CustomerSubscriptionStatus) as Array<CustomerSubscriptionStatus>).map(value => value.toString()), "customer-subscription-status")),
          new ChartDesignField("customer.renewedDate", "chart-design-renewed-date", ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
          new ChartDesignField("customer.renewalDate", "chart-design-renewal-date", ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
          new ChartDesignField("customer.payment_provider", "chart-design-customer-paymentprovider", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("customer.payment_auto_recurring", "chart-design-customer-paymentautorecurring", ChartDesignFilterDataTypes.BOOLEAN, true, false, false, false),
          new ChartDesignField("customer.plan.name", "chart-design-customer-plan-name", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("content.contentType", "chart-design-content-type", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getChoices((Object.values(contentType) as Array<contentType>).map(value => value.toString()), "content-type")),
          new ChartDesignField("content.mainType", "chart-design-content-main-type", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getChoices((Object.values(mainType) as Array<mainType>).map(value => value.toString()), "content-main-type")),
          new ChartDesignField("content.name", "chart-design-content-name", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("content.provider", "chart-design-content-provider", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("content.subname", "chart-design-content-subname", ChartDesignFilterDataTypes.STRING, true, true, false, false),
        ],
      } as AvailableChartDesignEntity,
      {
        name: AvailableEntitiesReporting.APP_PAGE,
        translationKey: "chart-design-apppage-entity",
        fields: [
          new ChartDesignField("pageType", "chart-design-page-type", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getChoices((Object.values(pageType) as Array<pageType>).map(value => value.toString()), "page-type")),
          new ChartDesignField("timestamp", "chart-design-date", ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
          new ChartDesignField("timestamp@dayOfMonth", "chart-design-date-per-day", ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
          new ChartDesignField("timestamp@week", "chart-design-date-per-week", ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
          new ChartDesignField("timestamp@month", "chart-design-date-per-month", ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
          new ChartDesignField("timestamp@year", "chart-design-date-per-year", ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
          new ChartDesignField("app.version", "chart-design-app-version", ChartDesignFilterDataTypes.STRING, true, false, false, false),
          new ChartDesignField("app.package", "chart-design-app-package", ChartDesignFilterDataTypes.STRING, true, false, false, false),
          new ChartDesignField("location.city", "chart-design-location-city", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("location.country", "chart-design-location-country", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getCountryChoices(countries)),
          new ChartDesignField("network.type", "chart-design-network-type", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("network.provider", "chart-design-network-provider", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("device.model", "chart-design-device-model", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("device.uuid", "chart-design-device-uuid", ChartDesignFilterDataTypes.STRING, false, false, false, true),
          new ChartDesignField("device.type", "chart-design-device-type", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("device.os", "chart-design-device-os", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("device.formFactor", "chart-design-device-formfactor", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("customer.username", "chart-design-customer-username", ChartDesignFilterDataTypes.STRING, false, false, false, true),
          new ChartDesignField("customer.status", "chart-design-customer-status", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getChoices((Object.values(CustomerStatus) as Array<CustomerStatus>).map(value => value.toString()), "customer-status")),
          new ChartDesignField("customer.zipcode", "chart-design-customer-zipcode", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("customer.state", "chart-design-customer-state", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("customer.city", "chart-design-customer-city", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("customer.country", "chart-design-customer-country", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getCountryChoices(countries)),
          new ChartDesignField("customer.is_deleted", "chart-design-customer-isdeleted", ChartDesignFilterDataTypes.BOOLEAN, true, false, false, false),
          new ChartDesignField("customer.productStatus", "chart-design-customer-product-status", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getChoices((Object.values(CustomerProductStatus) as Array<CustomerProductStatus>).map(value => value.toString()), "customer-product-status")),
          new ChartDesignField("customer.subscriptionStatus", "chart-design-customer-subscription-status", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getChoices((Object.values(CustomerSubscriptionStatus) as Array<CustomerSubscriptionStatus>).map(value => value.toString()), "customer-subscription-status")),
          new ChartDesignField("customer.renewedDate", "chart-design-renewed-date", ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
          new ChartDesignField("customer.renewalDate", "chart-design-renewal-date", ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
          new ChartDesignField("customer.payment_provider", "chart-design-customer-paymentprovider", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("customer.payment_auto_recurring", "chart-design-customer-paymentautorecurring", ChartDesignFilterDataTypes.BOOLEAN, true, false, false, false),
          new ChartDesignField("customer.plan.name", "chart-design-customer-plan-name", ChartDesignFilterDataTypes.STRING, true, true, false, false),
        ],
      } as AvailableChartDesignEntity,
      {
        name: AvailableEntitiesReporting.CONTENT_WATCHED,
        translationKey: "chart-design-contentwatched-entity",
        fields: [
          new ChartDesignField("duration", "chart-design-content-watched-duration", ChartDesignFilterDataTypes.NUMBER, true, false, true, false),
          new ChartDesignField("timestamp", "chart-design-date", ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
          new ChartDesignField("timestamp@dayOfMonth", "chart-design-date-per-day", ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
          new ChartDesignField("timestamp@week", "chart-design-date-per-week", ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
          new ChartDesignField("timestamp@month", "chart-design-date-per-month", ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
          new ChartDesignField("timestamp@year", "chart-design-date-per-year", ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
          new ChartDesignField("content.contentType", "chart-design-content-type", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getChoices((Object.values(contentType) as Array<contentType>).map(value => value.toString()), "content-type")),
          new ChartDesignField("content.mainType", "chart-design-content-main-type", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getChoices((Object.values(mainType) as Array<mainType>).map(value => value.toString()), "content-main-type")),
          new ChartDesignField("content.name", "chart-design-content-name", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("content.provider", "chart-design-content-provider", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("content.subname", "chart-design-content-subname", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("app.version", "chart-design-app-version", ChartDesignFilterDataTypes.STRING, true, false, false, false),
          new ChartDesignField("app.package", "chart-design-app-package", ChartDesignFilterDataTypes.STRING, true, false, false, false),
          new ChartDesignField("location.city", "chart-design-location-city", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("location.country", "chart-design-location-country", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getCountryChoices(countries)),
          new ChartDesignField("network.type", "chart-design-network-type", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("network.provider", "chart-design-network-provider", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("device.model", "chart-design-device-model", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("device.uuid", "chart-design-device-uuid", ChartDesignFilterDataTypes.STRING, false, false, false, true),
          new ChartDesignField("device.type", "chart-design-device-type", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("device.os", "chart-design-device-os", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("device.formFactor", "chart-design-device-formfactor", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("customer.username", "chart-design-customer-username", ChartDesignFilterDataTypes.STRING, false, false, false, true),
          new ChartDesignField("customer.status", "chart-design-customer-status", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getChoices((Object.values(CustomerStatus) as Array<CustomerStatus>).map(value => value.toString()), "customer-status")),
          new ChartDesignField("customer.zipcode", "chart-design-customer-zipcode", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("customer.state", "chart-design-customer-state", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("customer.city", "chart-design-customer-city", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("customer.country", "chart-design-customer-country", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getCountryChoices(countries)),
          new ChartDesignField("customer.is_deleted", "chart-design-customer-isdeleted", ChartDesignFilterDataTypes.BOOLEAN, true, false, false, false),
          new ChartDesignField("customer.productStatus", "chart-design-customer-product-status", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getChoices((Object.values(CustomerProductStatus) as Array<CustomerProductStatus>).map(value => value.toString()), "customer-product-status")),
          new ChartDesignField("customer.subscriptionStatus", "chart-design-customer-subscription-status", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getChoices((Object.values(CustomerSubscriptionStatus) as Array<CustomerSubscriptionStatus>).map(value => value.toString()), "customer-subscription-status")),
          new ChartDesignField("customer.renewedDate", "chart-design-renewed-date", ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
          new ChartDesignField("customer.renewalDate", "chart-design-renewal-date", ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
          new ChartDesignField("customer.payment_provider", "chart-design-customer-paymentprovider", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("customer.payment_auto_recurring", "chart-design-customer-paymentautorecurring", ChartDesignFilterDataTypes.BOOLEAN, true, false, false, false),
          new ChartDesignField("customer.plan.name", "chart-design-customer-plan-name", ChartDesignFilterDataTypes.STRING, true, true, false, false),
        ],
      } as AvailableChartDesignEntity,
      {
        name: AvailableEntitiesReporting.COMMUNICATION,
        translationKey: "chart-design-communication-entity",
        fields: [
          new ChartDesignField("source", "chart-design-communication-source", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getChoices((Object.values(CommunicationSource) as Array<CommunicationSource>).map(value => value.toString()), "communication-source")),
          new ChartDesignField("type", "chart-design-communication-type", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getChoices((Object.values(CommunicationType) as Array<CommunicationType>).map(value => value.toString()), "communication-type")),
          new ChartDesignField("gateway", "chart-design-gateway", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("message_type", "chart-design-message-type", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("template_name", "chart-design-template-name", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("timestamp", "chart-design-date", ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
          new ChartDesignField("timestamp@dayOfMonth", "chart-design-date-per-day", ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
          new ChartDesignField("timestamp@week", "chart-design-date-per-week", ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
          new ChartDesignField("timestamp@month", "chart-design-date-per-month", ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
          new ChartDesignField("timestamp@year", "chart-design-date-per-year", ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
          new ChartDesignField("product.name", "chart-design-product-name", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("product.variant", "chart-design-product-variant", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getChoices((Object.values(ProductVariant) as Array<ProductVariant>).map(value => value.toString()), "product-variant")),
          new ChartDesignField("product.type", "chart-design-product-type", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getChoices((Object.values(ProductType) as Array<ProductType>).map(value => value.toString()), "product-type")),
          new ChartDesignField("customer.username", "chart-design-customer-username", ChartDesignFilterDataTypes.STRING, false, false, false, true),
          new ChartDesignField("customer.status", "chart-design-customer-status", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getChoices((Object.values(CustomerStatus) as Array<CustomerStatus>).map(value => value.toString()), "customer-status")),
          new ChartDesignField("customer.zipcode", "chart-design-customer-zipcode", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("customer.state", "chart-design-customer-state", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("customer.city", "chart-design-customer-city", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("customer.country", "chart-design-customer-country", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getCountryChoices(countries)),
          new ChartDesignField("customer.is_deleted", "chart-design-customer-isdeleted", ChartDesignFilterDataTypes.BOOLEAN, true, false, false, false),
          new ChartDesignField("customer.productStatus", "chart-design-customer-product-status", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getChoices((Object.values(CustomerProductStatus) as Array<CustomerProductStatus>).map(value => value.toString()), "customer-product-status")),
          new ChartDesignField("customer.subscriptionStatus", "chart-design-customer-subscription-status", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getChoices((Object.values(CustomerSubscriptionStatus) as Array<CustomerSubscriptionStatus>).map(value => value.toString()), "customer-subscription-status")),
          new ChartDesignField("customer.renewedDate", "chart-design-renewed-date", ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
          new ChartDesignField("customer.renewalDate", "chart-design-renewal-date", ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
          new ChartDesignField("customer.payment_provider", "chart-design-customer-paymentprovider", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("customer.payment_auto_recurring", "chart-design-customer-paymentautorecurring", ChartDesignFilterDataTypes.BOOLEAN, true, false, false, false),
          new ChartDesignField("customer.plan.name", "chart-design-customer-plan-name", ChartDesignFilterDataTypes.STRING, true, true, false, false),
        ],
      } as AvailableChartDesignEntity,
      {
        name: AvailableEntitiesReporting.TRANSACTION,
        translationKey: "chart-design-transaction-entity",
        fields: [
          new ChartDesignField("transaction_type", "chart-design-transaction-type", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getChoices((Object.values(OrderTypes) as Array<OrderTypes>).map(value => value.toString()), "transaction-type")),
          new ChartDesignField("source", "chart-design-transaction-source", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getChoices((Object.values(TransactionSource) as Array<TransactionSource>).map(value => value.toString()), "transaction-source")),
          new ChartDesignField("date_unix", "chart-design-date", ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
          new ChartDesignField("date_unix@dayOfMonth", "chart-design-date-per-day", ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
          new ChartDesignField("date_unix@week", "chart-design-date-per-week", ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
          new ChartDesignField("date_unix@month", "chart-design-date-per-month", ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
          new ChartDesignField("date_unix@year", "chart-design-date-per-year", ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
          new ChartDesignField("amount_inc_vat", "chart-design-amount-inc-vat", ChartDesignFilterDataTypes.NUMBER, true, false, true, false),
          new ChartDesignField("amount_ex_vat", "chart-design-amount-ex-vat", ChartDesignFilterDataTypes.NUMBER, true, false, true, false),
          new ChartDesignField("amount_vat", "chart-design-amount-vat", ChartDesignFilterDataTypes.NUMBER, true, false, true, false),
          new ChartDesignField("amount_discount", "chart-design-amount-discount", ChartDesignFilterDataTypes.NUMBER, true, false, true, false),
          new ChartDesignField("products", "chart-design-products", ChartDesignFilterDataTypes.ARRAY, true, false, false, false, [
            new ChartDesignField("@size", "chart-design-product-size", ChartDesignFilterDataTypes.NUMBER, true, false, false, false),
            new ChartDesignField("amount_inc_vat", "chart-design-amount-inc-vat", ChartDesignFilterDataTypes.NUMBER, true, false, true, false),
            new ChartDesignField("amount_ex_vat", "chart-design-amount-ex-vat", ChartDesignFilterDataTypes.NUMBER, true, false, true, false),
            new ChartDesignField("amount_vat", "chart-design-amount-vat", ChartDesignFilterDataTypes.NUMBER, true, false, true, false),
            new ChartDesignField("product.name", "chart-design-product-name", ChartDesignFilterDataTypes.STRING, true, true, false, false),
            new ChartDesignField("product.variant", "chart-design-product-variant", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getChoices((Object.values(ProductVariant) as Array<ProductVariant>).map(value => value.toString()), "product-variant")),
            new ChartDesignField("product.type", "chart-design-product-type", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getChoices((Object.values(ProductType) as Array<ProductType>).map(value => value.toString()), "product-type")),
          ]),
          new ChartDesignField("customer.username", "chart-design-customer-username", ChartDesignFilterDataTypes.STRING, false, false, false, true),
          new ChartDesignField("customer.status", "chart-design-customer-status", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getChoices((Object.values(CustomerStatus) as Array<CustomerStatus>).map(value => value.toString()), "customer-status")),
          new ChartDesignField("customer.zipcode", "chart-design-customer-zipcode", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("customer.state", "chart-design-customer-state", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("customer.city", "chart-design-customer-city", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("customer.country", "chart-design-customer-country", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getCountryChoices(countries)),
          new ChartDesignField("customer.is_deleted", "chart-design-customer-isdeleted", ChartDesignFilterDataTypes.BOOLEAN, true, false, false, false),
          new ChartDesignField("customer.productStatus", "chart-design-customer-product-status", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getChoices((Object.values(CustomerProductStatus) as Array<CustomerProductStatus>).map(value => value.toString()), "customer-product-status")),
          new ChartDesignField("customer.subscriptionStatus", "chart-design-customer-subscription-status", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getChoices((Object.values(CustomerSubscriptionStatus) as Array<CustomerSubscriptionStatus>).map(value => value.toString()), "customer-subscription-status")),
          new ChartDesignField("customer.shopping_cartName", "chart-design-customer-shoppingcart", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("customer.renewedDate", "chart-design-renewed-date", ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
          new ChartDesignField("customer.renewalDate", "chart-design-renewal-date", ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
          new ChartDesignField("customer.payment_provider", "chart-design-customer-paymentprovider", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("customer.payment_auto_recurring", "chart-design-customer-paymentautorecurring", ChartDesignFilterDataTypes.BOOLEAN, true, false, false, false),
          new ChartDesignField("customer.plan.name", "chart-design-customer-plan-name", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("coupon.name", "chart-design-coupon-name", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("coupon.start", "chart-design-coupon-start", ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
          new ChartDesignField("coupon.end", "chart-design-coupon-end", ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
          new ChartDesignField("coupon.vanity", "chart-design-coupon-vanity", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("coupon.discountAmount", "chart-design-coupon-discount-amount", ChartDesignFilterDataTypes.NUMBER, true, false, true, false),
          new ChartDesignField("affiliate.name", "chart-design-affiliate-name", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("affiliate.enabled", "chart-design-affiliate-enabled", ChartDesignFilterDataTypes.BOOLEAN, true, false, false, false),
          new ChartDesignField("affiliate.vanity", "chart-design-affiliate-vanity", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("affiliate.email", "chart-design-affiliate-email", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("affiliate.phone", "chart-design-affiliate-phone", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("affiliate.facebook", "chart-design-affiliate-facebook", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("affiliate.tiktok", "chart-design-affiliate-tiktok", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("affiliate.instagram", "chart-design-affiliate-instagram", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("affiliate.website", "chart-design-affiliate-website", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("affiliate.discountAmount", "chart-design-affiliate-discount-amount", ChartDesignFilterDataTypes.NUMBER, true, false, true, false),
          new ChartDesignField("affiliate.commission.type", "chart-design-affiliate-commission-type", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("affiliate.commission.amount", "chart-design-affiliate-commission-amount", ChartDesignFilterDataTypes.NUMBER, true, false, true, false),
        ],
      } as AvailableChartDesignEntity,
      {
        name: AvailableEntitiesReporting.CUSTOMER,
        translationKey: "chart-design-customer-entity",
        fields: [
          new ChartDesignField("username", "chart-design-customer-username", ChartDesignFilterDataTypes.STRING, false, false, false, true),
          new ChartDesignField("status", "chart-design-customer-status", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getChoices((Object.values(CustomerStatus) as Array<CustomerStatus>).map(value => value.toString()), "customer-status")),
          new ChartDesignField("zipcode", "chart-design-customer-zipcode", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("state", "chart-design-customer-state", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("city", "chart-design-customer-city", ChartDesignFilterDataTypes.STRING, true, true, false, false),
          new ChartDesignField("country", "chart-design-customer-country", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getCountryChoices(countries)),
          new ChartDesignField("is_deleted", "chart-design-customer-isdeleted", ChartDesignFilterDataTypes.BOOLEAN, true, false, false, false),
          new ChartDesignField("productStatus", "chart-design-customer-product-status", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getChoices((Object.values(CustomerProductStatus) as Array<CustomerProductStatus>).map(value => value.toString()), "customer-product-status")),
          new ChartDesignField("subscriptionStatus", "chart-design-customer-subscription-status", ChartDesignFilterDataTypes.CHOICE, true, true, false, false, undefined, this.getChoices((Object.values(CustomerSubscriptionStatus) as Array<CustomerSubscriptionStatus>).map(value => value.toString()), "customer-subscription-status")),
          new ChartDesignField("renewedDate", "chart-design-renewed-date", ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
          new ChartDesignField("renewalDate", "chart-design-renewal-date", ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
          new ChartDesignField("renewalDate@dayOfMonth", "chart-design-renewal-date-per-day", ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
          new ChartDesignField("renewalDate@week", "chart-design-renewal-date-per-week", ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
          new ChartDesignField("renewalDate@month", "chart-design-renewal-date-per-month", ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
          new ChartDesignField("renewalDate@year", "chart-design-renewal-date-per-year", ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
        ],
      } as AvailableChartDesignEntity,
      {
        name: AvailableEntitiesReporting.CDN_TRAFFIC,
        translationKey: "chart-design-cdn-traffic-entity",
        fields: [
          new ChartDesignField("gbMonthlyCumulative", "chart-design-cdn-gb", ChartDesignFilterDataTypes.NUMBER, true, false, true, false),
          new ChartDesignField("date_unix", "chart-design-date", ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
          new ChartDesignField("date_unix@dayOfMonth", "chart-design-date-per-day", ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
          new ChartDesignField("date_unix@week", "chart-design-date-per-week", ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
          new ChartDesignField("date_unix@month", "chart-design-date-per-month", ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
          new ChartDesignField("date_unix@year", "chart-design-date-per-year", ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
        ],
      } as AvailableChartDesignEntity,
      {
        name: AvailableEntitiesReporting.CDN_STORAGE,
        translationKey: "chart-design-cdn-storage-entity",
        fields: [
          new ChartDesignField("gbMonthlyCumulative", "chart-design-cdn-gb", ChartDesignFilterDataTypes.NUMBER, true, false, true, false),
          new ChartDesignField("date_unix", "chart-design-date", ChartDesignFilterDataTypes.DATETIME, true, false, false, false),
          new ChartDesignField("date_unix@dayOfMonth", "chart-design-date-per-day", ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
          new ChartDesignField("date_unix@week", "chart-design-date-per-week", ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
          new ChartDesignField("date_unix@month", "chart-design-date-per-month", ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
          new ChartDesignField("date_unix@year", "chart-design-date-per-year", ChartDesignFilterDataTypes.DATETIME, false, true, false, false),
        ],
      } as AvailableChartDesignEntity,
    ];

    return entities;

  }

  private static getChoices = (values: string[], fieldName: string): Choice[] => {

    const choices = values.map(value => {
  
      return new Choice(value, `chart-design-${fieldName}-${value.toLowerCase().replaceAll(' ', '-')}`);
  
    });
    
    return choices;
  
  }

  private static getCountryChoices = (countries?: ICountry[]): Choice[] => {

    if (!countries) {
      return [];
    }

    const choices = countries.map(country => {
      return new Choice(country.name, `${country.emoji} ${country.name}`);
    });
    
    return choices;

  }
  
}

export class AvailableChartDesignEntity {

  public name?: AvailableEntitiesReporting;
  public translationKey?: string;
  public fields?: ChartDesignField[];

}

export class AvailableOperator {

  public name?: string;
  public translationKey?: string;
  
}

export const AvailableStringOperators = [
  {
    name: "$eq",
    translationKey: "chart-design-eq-operator",
  } as AvailableOperator,
  {
    name: "$ne",
    translationKey: "chart-design-ne-operator",
  } as AvailableOperator,
];

export const AvailableArraySizeOperators = [
  {
    name: "$eq",
    translationKey: "chart-design-eq-operator",
  } as AvailableOperator,
];

export const AvailableNumberOperators = [
  {
    name: "$eq",
    translationKey: "chart-design-eq-operator",
  } as AvailableOperator,
  {
    name: "$ne",
    translationKey: "chart-design-ne-operator",
  } as AvailableOperator,
  {
    name: "$gt",
    translationKey: "chart-design-gt-operator",
  } as AvailableOperator,
  {
    name: "$lt",
    translationKey: "chart-design-lt-operator",
  } as AvailableOperator,
  {
    name: "$gte",
    translationKey: "chart-design-gte-operator",
  } as AvailableOperator,
  {
    name: "$lte",
    translationKey: "chart-design-lte-operator",
  } as AvailableOperator,
];

export const AvailableDateTimeOperators = [
  {
    name: "$gt",
    translationKey: "chart-design-gt-datetime-operator",
  } as AvailableOperator,
  {
    name: "$lt",
    translationKey: "chart-design-lt-datetime-operator",
  } as AvailableOperator,
  {
    name: "@today",
    translationKey: "chart-design-today-operator",
  } as AvailableOperator,
  {
    name: "@thisweek",
    translationKey: "chart-design-thisweek-operator",
  } as AvailableOperator,
  {
    name: "@thismonth",
    translationKey: "chart-design-thismonth-operator",
  } as AvailableOperator,
  {
    name: "@thisquarter",
    translationKey: "chart-design-thisquarter-operator",
  } as AvailableOperator,
  {
    name: "@thisyear",
    translationKey: "chart-design-thisyear-operator",
  } as AvailableOperator,
];

export const AvailableAccumulatorOperators = [
  {
    name: "$sum",
    translationKey: "chart-design-sum-operator",
  } as AvailableOperator,
  {
    name: "$avg",
    translationKey: "chart-design-avg-operator",
  } as AvailableOperator,
  {
    name: "$min",
    translationKey: "chart-design-min-operator",
  } as AvailableOperator,
  {
    name: "$max",
    translationKey: "chart-design-max-operator",
  } as AvailableOperator,
  {
    name: "$count",
    translationKey: "chart-design-count-operator",
  } as AvailableOperator,
  {
    name: "$distinctCount",
    translationKey: "chart-design-distinctcount-operator",
  } as AvailableOperator,
];

export const AvailablePercentageOperators = [
  {
    name: "total",
    translationKey: "chart-design-percentage-total",
  } as AvailableOperator,
  {
    name: "target",
    translationKey: "chart-design-percentage-target",
  } as AvailableOperator,
];

export const AvailableFilterLogicOperators = [
  {
    name: "$and",
    translationKey: "chart-design-filter-logic-and",
  } as AvailableOperator,
  {
    name: "$or",
    translationKey: "chart-design-filter-logic-or",
  } as AvailableOperator,
];

export const AvailableSortingOperators = [
  {
    name: "ascending",
    translationKey: "chart-design-sortby-ascending",
  } as AvailableOperator,
  {
    name: "descending",
    translationKey: "chart-design-sortby-descending",
  } as AvailableOperator,
  {
    name: "top",
    translationKey: "chart-design-sortby-top-results",
  } as AvailableOperator,
  {
    name: "bottom",
    translationKey: "chart-design-sortby-bottom-results",
  } as AvailableOperator,
];

export class AvailableChartType {

  public name?: AvailableChartTypesReporting;
  public translationKey?: string;
  
}

export class AvailableChartWidth {

  public value?: number;
  public translationKey?: string;

}

export const AvailableChartWidths = [
  {
    value: 12,
    translationKey: "chart-design-chart-width-full",
  } as AvailableChartWidth,
  {
    value: 9,
    translationKey: "chart-design-chart-width-3-4",
  } as AvailableChartWidth,
  {
    value: 8,
    translationKey: "chart-design-chart-width-2-3",
  } as AvailableChartWidth,
  {
    value: 6,
    translationKey: "chart-design-chart-width-half",
  } as AvailableChartWidth,
  {
    value: 4,
    translationKey: "chart-design-chart-width-1-3",
  } as AvailableChartWidth,
  {
    value: 3,
    translationKey: "chart-design-chart-width-1-4",
  } as AvailableChartWidth,
];

export const AvailableChartTypes = [
  {
    name: AvailableChartTypesReporting.BAR,
    translationKey: "chart-design-bar-chart",
  } as AvailableChartType,
  {
    name: AvailableChartTypesReporting.COLUMN,
    translationKey: "chart-design-column-chart",
  } as AvailableChartType,
  {
    name: AvailableChartTypesReporting.LINE,
    translationKey: "chart-design-line-chart",
  } as AvailableChartType,
  {
    name: AvailableChartTypesReporting.PIE,
    translationKey: "chart-design-pie-chart",
  } as AvailableChartType,
  {
    name: AvailableChartTypesReporting.PLAIN_VALUE,
    translationKey: "chart-design-plain-value-chart",
  } as AvailableChartType,
  {
    name: AvailableChartTypesReporting.GAUGE,
    translationKey: "chart-design-gauge-chart",
  } as AvailableChartType,
];

export const AvailableChartEntities = [
  {
    name: AvailableEntitiesReporting.APP_ACTION,
    translationKey: "chart-design-appaction-entity",
  } as AvailableChartDesignEntity,
  {
    name: AvailableEntitiesReporting.APP_ERROR,
    translationKey: "chart-design-apperror-entity",
  } as AvailableChartDesignEntity,
  {
    name: AvailableEntitiesReporting.APP_PAGE,
    translationKey: "chart-design-apppage-entity",
  } as AvailableChartDesignEntity,
  {
    name: AvailableEntitiesReporting.CONTENT_WATCHED,
    translationKey: "chart-design-contentwatched-entity",
  } as AvailableChartDesignEntity,
  {
    name: AvailableEntitiesReporting.COMMUNICATION,
    translationKey: "chart-design-communication-entity",
  } as AvailableChartDesignEntity,
  {
    name: AvailableEntitiesReporting.TRANSACTION,
    translationKey: "chart-design-transaction-entity",
  } as AvailableChartDesignEntity,
  {
    name: AvailableEntitiesReporting.CUSTOMER,
    translationKey: "chart-design-customer-entity",
  } as AvailableChartDesignEntity,
  {
    name: AvailableEntitiesReporting.CDN_TRAFFIC,
    translationKey: "chart-design-cdn-traffic-entity",
  } as AvailableChartDesignEntity,
  {
    name: AvailableEntitiesReporting.CDN_STORAGE,
    translationKey: "chart-design-cdn-storage-entity",
  } as AvailableChartDesignEntity,
];
