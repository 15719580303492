import { Form, Input, Modal, Select, Spin, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { FormSection } from '../../../../../../../common/components/form';
import { useMutation } from '@tanstack/react-query';
import * as deploymentService from '../../../../../../../services/deployments/deployment';
import { Deployment } from '@mwaretv/database/build/backend/models/deployment/deployment';
import { useEffect, useState } from 'react';
import moment from 'moment';

export type LayoutProps = {
  visible: boolean;
  clientName: string;
  deployment: Deployment;
  serviceName: string;
  onCancel: () => void;
  onSubmit: () => void;
};

const ServiceStorage = (props: LayoutProps) => {
  const { visible, onCancel, clientName, deployment, onSubmit, serviceName } = props;
  const [form] = useForm();
  const { t } = useTranslation();
  const [isLoadind, setLoading] = useState(false);
  const [service, setService] = useState([] as any);
  const [serviceIndex, setServiceIndex] = useState([] as any);

  const hosting = Form.useWatch(['storage', 'hosting'], form);
  const region = Form.useWatch(['storage', 'region'], form);

  useEffect(() => {
    var service = deployment.services.find((s) => s.name == serviceName);
    var serviceIndex = deployment.services.findIndex((s) => s.name == serviceName);
    setService(service);
    setServiceIndex(serviceIndex);
    form.setFieldsValue(service);
  }, [props]);

  const { mutate } = useMutation(
    (values: Omit<Deployment, 'id' | '_id'>) => {
      return deploymentService.patch({ params: { client: clientName }, data: values, id: deployment?._id.toString() ?? '' });
    },
    {
      onSuccess: () => {
        notification.success({ message: t('changes-have-been-saved') });
        onSubmit();
      },
      onError: () => {
        notification.error({ message: t('something-went-wrong-saving-the-changes') });
      },
    }
  );

  const handleSubmit = (values) => {
    setLoading(true);
    if (hosting === 'tvcloud' && service.storage) {
      deployment.services[serviceIndex] = {
        ...service,
        storage: {
          ...service.storage,
          region: region,
          accessKey: 'J493W5XNRYM3UGEESLUH',
          secretAccessKey: '9Ee0dMdfnySpiSyB9rIzZe7vWRr2mhgV342HelUQ',
        },
      };
      mutate(deployment);
    } else if (hosting === 'onpremise' && service.storage) {
      deployment.services[serviceIndex] = {
        ...service,
        storage: {
          ...service.storage,
          accessKey: values.accessKey,
          secretAccessKey: values.secretAccessKey,
        },
      };
      mutate(deployment);
    } else if (hosting === 'onpremise' && !service.storage) {
      deployment.services[serviceIndex] = {
        ...service,
        storage: {
          created: moment().unix(),
          hostname: values.hostname,
          label: values.label,
          region: region,
          accessKey: values.accessKey,
          secretAccessKey: values.secretAccessKey,
          hosting: values.hosting,
        },
      };
      mutate(deployment);
    } else {
      const url = 'https://api.linode.com/v4/object-storage/buckets';
      const options = {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: 'Bearer 0a3a53c82b269a99467970d7565c1ba77f72a83838d69ce30158c398c74f3e79',
        },
        body: JSON.stringify({
          label: clientName.toLowerCase().replace('_','-') + '-' + deployment.name.toLowerCase().replace('_','-') ,
          region: region,
          cors_enabled: true,
          acl: 'public-read-write',
        }),
      };

      fetch(url, options)
        .then((res) => res.json())
        .then((json) => {
          deployment.services[serviceIndex] = {
            ...service,
            storage: {
              created: json.created,
              hostname: json.hostname,
              label: json.label,
              region: region,
              accessKey: 'J493W5XNRYM3UGEESLUH',
              secretAccessKey: '9Ee0dMdfnySpiSyB9rIzZe7vWRr2mhgV342HelUQ',
              endpoint: json.hostname,
              hosting: values.hosting,
            },
          };
          mutate(deployment);
        })
        .catch((err) => console.error('error:' + err));
    }

  };

  return (
    <Modal visible={visible} style={{  borderRadius:5}} width={'60%'} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-2 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="row">
              <div className="col-12">
                <Form
                  form={form}
                  onFinish={(values: any) => {
                    handleSubmit(values);
                  }}
                  onFinishFailed={(errorInfo) => {}}
                >
                  <div className="col">
                    {isLoadind == true && (
                      <div className="text-center h-100 w-100" style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', position: 'absolute', zIndex: 9999 }}>
                        <div className="col-md-12 align-self-center" style={{ height: 495, paddingTop: 240 }}>
                          <Spin size="large" spinning={true} />
                        </div>
                      </div>
                    )}
                    <FormSection title={t('service_storage')}>
                      <div className="row-md-12 pt-3">
                        <div className="row">
                          <label className="col-md-3 col-form-label">{t('hosting')}</label>
                          <div className="col">
                            <Form.Item className="col-md-6" name={['storage', 'hosting']} style={{ marginLeft: -15 }}>
                              <Select disabled={service.storage ? true : false} className="default-select" placeholder={t('select_hosting')}>
                                <Select.Option value={'tvcloud'} key={1}>
                                  TVMS Cloud
                                </Select.Option>
                                {/* <Select.Option value={'onpremise'} key={2}>
                                  Self Hosted (On Premise)
                                </Select.Option> */}
                              </Select>
                            </Form.Item>
                          </div>
                        </div>
                        {hosting !== 'tvcloud' && (
                          <div className="row">
                            <label className="col-md-3 col-form-label">{t('hostname')}</label>
                            <Form.Item
                              className="col-md-7"
                              name={['storage', 'hostname']}
                              rules={[
                                {
                                  required: true,
                                  message: t('name-is-required'),
                                },
                              ]}
                            >
                              <Input disabled={service.storage ? true : false} className="form-control bg-white font-size-14 text-default" placeholder={t('hostname')} />
                            </Form.Item>
                          </div>
                        )}
                        {hosting !== 'tvcloud' && (
                          <div className="row">
                            <label className="col-md-3 col-form-label">{t('label')}</label>
                            <Form.Item
                              className="col-md-7"
                              name={['storage', 'label']}
                              rules={[
                                {
                                  required: true,
                                  message: t('name-is-required'),
                                },
                              ]}
                            >
                              <Input disabled={service.storage ? true : false} className="form-control bg-white font-size-14 text-default" placeholder={t('label')} />
                            </Form.Item>
                          </div>
                        )}
                        {hosting === 'tvcloud' && (
                          <div className="row">
                            <label className="col-md-3 col-form-label">{t('region')}</label>
                            <div className="col">
                              <Form.Item className="col-md-6" name={['storage', 'region']} style={{ marginLeft: -15 }}>
                                <Select disabled={service.storage ? true : false} className="default-select" placeholder={t('select-an-input-region')}>
                                  <Select.Option value={'nl-ams-1'} key={1}>
                                    Amsterdam (Netherlands)
                                  </Select.Option>
                                  <Select.Option value={'in-maa-1'} key={2}>
                                    Chennai (India)
                                  </Select.Option>
                                  <Select.Option value={'us-ord-1'} key={3}>
                                    Chicago, IL (USA)
                                  </Select.Option>
                                  <Select.Option value={'id-cgk-1'} key={4}>
                                    Jakarta (Indonesia)
                                  </Select.Option>
                                  <Select.Option value={'us-lax-1'} key={5}>
                                    Los Angeles, CA (USA)
                                  </Select.Option>
                                  <Select.Option value={'es-mad-1'} key={6}>
                                    Madrid (Spain)
                                  </Select.Option>
                                  <Select.Option value={'us-mia-1'} key={7}>
                                    Miami, FL (USA)
                                  </Select.Option>
                                  <Select.Option value={'it-mil-1'} key={8}>
                                    Milan (Italy)
                                  </Select.Option>
                                  <Select.Option value={'jp-osa-1'} key={9}>
                                    Osaka (Japan)
                                  </Select.Option>
                                  <Select.Option value={'fr-par-1'} key={10}>
                                    Paris (France)
                                  </Select.Option>
                                  <Select.Option value={'br-gru-1'} key={11}>
                                    São Paulo (Brazil)
                                  </Select.Option>
                                  <Select.Option value={'us-sea-1'} key={12}>
                                    Seattle, WA (USA)
                                  </Select.Option>
                                  <Select.Option value={'us-iad-1'} key={13}>
                                    Washington, DC (USA)
                                  </Select.Option>
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        )}
                        {hosting !== 'tvcloud' && (
                          <div className="row">
                            <label className="col-md-3 col-form-label">{t('region')}</label>
                            <Form.Item
                              className="col-md-7"
                              name={['storage', 'region']}
                              rules={[
                                {
                                  required: true,
                                  message: t('name-is-required'),
                                },
                              ]}
                            >
                              <Input className="form-control bg-white font-size-14 text-default" placeholder={t('region')} />
                            </Form.Item>
                          </div>
                        )}
                        {hosting !== 'tvcloud' && (
                          <div className="row">
                            <label className="col-md-3 col-form-label">{t('accesskey')}</label>
                            <Form.Item
                              className="col-md-7"
                              name={['storage', 'accessKey']}
                              rules={[
                                {
                                  required: true,
                                  message: t('name-is-required'),
                                },
                              ]}
                            >
                              <Input className="form-control bg-white font-size-14 text-default" placeholder={t('accesskey')} />
                            </Form.Item>
                          </div>
                        )}
                        {hosting !== 'tvcloud' && (
                          <div className="row">
                            <label className="col-md-3 col-form-label">{t('secretkey')}</label>
                            <Form.Item
                              className="col-md-7"
                              name={['storage', 'secretAccessKey']}
                              rules={[
                                {
                                  required: true,
                                  message: t('name-is-required'),
                                },
                              ]}
                            >
                              <Input className="form-control bg-white font-size-14 text-default" placeholder={t('secretkey')} />
                            </Form.Item>
                          </div>
                        )}
                      </div>
                    </FormSection>
                  </div>

                  {hosting !== 'tvcloud' && (
                    <div className="col-md-12 p-0 mt-3">
                      <button type="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-240 height-48">
                        {t('update')}
                      </button>
                    </div>
                  )}
                  {!service?.storage && (
                    <div className="col-md-12 p-0 mt-3">
                      <button type="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-240 height-48">
                        {t('create')}
                      </button>
                    </div>
                  )}
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ServiceStorage;

// const onSubmitLocal = () => {
//   setLoading(true);
//   if (countryData && currencyData && deploymentData) {
//     var serviceIndex = deploymentData.services.findIndex((d) => d.name === service);

//     if (form.getFieldValue('languages') != undefined) {
//       deploymentData.services[serviceIndex] = {
//         ...deploymentData.services[serviceIndex],
//         languages: form.getFieldValue('languages'),
//       };
//     }

//     if (form.getFieldValue('countries') != undefined) {
//       const countries = (form.getFieldValue('countries') as string[]).map((id) => countryData.countries.find((c) => c._id === id));
//       const formCurrencies = [...new Set(countries.map((c) => c!.currency))];
//       const formIds = formCurrencies.map((c) => currencyData.currencies.find((d) => d.isoCurrency.toLowerCase() === c.toLowerCase())?._id);

//       deploymentData.services[serviceIndex] = {
//         ...deploymentData.services[serviceIndex],
//         countries: form.getFieldValue('countries'),
//         currencies: formIds as any,
//       };
//     }
//     mutate(deploymentData);
//   }
// };
