import { Form, Modal, Switch } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ColorPicker } from '../../ColorPicker';
import Text1Png from '../../../../assets/images/appbuilder/ContentList_Bar1.png';
import Text2Png from '../../../../assets/images/appbuilder/ContentList_Bar2.png';

export type LayoutProps = {
  visible: boolean;
  onSubmit: (component: [], name: string, index:any) => void;
  onCancel: () => void;
  data: [] | any;
  type: string;
  theme: [] | any;
  index: any;
};

const LayoutModel = (props: LayoutProps) => {
  const { visible, onSubmit, onCancel, data, type, theme } = props;
  const [form] = useForm();
const { t } = useTranslation();
  const [textNormalColor, setTextNormalColor] = useState('#ffffff');
  const [shoTextNormalColorPicker, setTextNormalColorPicker] = useState(false);
  const [textSubColor, setTextSubColor] = useState('#ffffff');
  const [shoTextSubColorPicker, setTextSubColorPicker] = useState(false);
  const [textNormalType, setTextNormalType] = useState('ExtraExtraLarge');
  const [textSubType, setTextSubType] = useState("Large");
  const [textNormalEnabled, setTextNormalEnabled] = useState(true);
  const [textSubEnabled, setTextSubEnabled] = useState(true);
  const [textSubSubEnabled, setTextSubSubEnabled] = useState(true);
  const [textNormalBold, setTextNormalBold] = useState(false);
  const [textSubBold, setTextSubBold] = useState(false);
  const [textPlacement, setTextPlacement] = useState("Below");

  useEffect(() => {
    if (data.texts != undefined) {
      setTextSubBold(data.texts.subtext_bold);
      setTextSubColor(data.texts.subtext_color);
      setTextSubType(data.texts.subtext_size);

      setTextNormalBold(data.texts.title_bold);
      setTextNormalColor(data.texts.title_color);
      setTextNormalType(data.texts.title_size);

      setTextNormalEnabled(data.texts.show_title);
      setTextSubEnabled(data.texts.show_subtext);
      setTextSubSubEnabled(data.texts.show_subsubtext);

      setTextPlacement(data.texts.placement_text);

      form.setFieldsValue(data.texts);
    }
  }, [data, form]);

  const onSubmitLocal = (values: any) => {
    var texts = {
      title_color: textNormalColor,
      title_size: textNormalType, //Small, Normal, Large, Extra Large
      title_bold: textNormalBold,

      subtext_color: textSubColor,
      subtext_size: textSubType, //Small, Normal, Large, Extra Large
      subtext_bold: textSubBold,

      subsubtext_color: textSubColor,
      subsubtext_size: textSubType, //Small, Normal, Large, Extra Large
      subsubtext_bold: textSubBold,

      show_title: textNormalEnabled,
      show_subtext: textSubEnabled,
      show_subsubtext: textSubSubEnabled,

      placement_text: textPlacement, //on image, below image
    };
    let dataOut = {
      ...data,
      texts: texts,
    };
    onSubmit(dataOut, type, props.index);
  };

  return (
    <Modal visible={visible} style={{  borderRadius:5}} width={'80%'} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="text-primary">{t('edit-player-bar')}</h4>

            <div className="row mt-5">
              <div className="col-12">
                <Form form={form} onFinish={onSubmitLocal} onFinishFailed={(errorInfo) => {}}>
                  <div className="col">
                    <div className="row">
                      <div className="col card-bg-light p-4 mb-4 mr-4" style={{ paddingLeft: 0 }}>
                        <h5 className="text-primary">{t('title-text')}</h5>
                        <p>{t('settings-that-define-title-object-of-your-element')}</p>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('text-color-0')}</label>
                          <div className="col" style={{ paddingLeft: 0 }}>
                            <div className="form-group pb-0 col">
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setTextNormalColorPicker(!shoTextNormalColorPicker);
                                }}
                                style={{
                                  backgroundColor: textNormalColor,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={shoTextNormalColorPicker}
                                color={textNormalColor}
                                onChange={(color) => {
                                  setTextNormalColor(color);
                                  setTextNormalColorPicker(!shoTextNormalColorPicker);
                                }}
                                setVisible={setTextNormalColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                COLOR
                              </div>
                            </div>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>

                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('text-bold')}</label>

                          <div className="col">
                            <Form.Item className="form-group pb-0 " name="text_bold">
                              <Switch className="ch-texts mt-2" checked={textNormalBold} defaultChecked={false} onChange={() => setTextNormalBold(!textNormalBold)} />
                            </Form.Item>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('show-text-0')}</label>

                          <div className="col">
                            <Form.Item className="form-group pb-0" name="title_enabled">
                              <Switch className="ch-texts mt-2" checked={textNormalEnabled} defaultChecked={true} onChange={() => setTextNormalEnabled(!textNormalEnabled)} />
                            </Form.Item>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                      </div>
                      <div className="col card-bg-light p-4 mb-4 mr-1" style={{ paddingLeft: 0 }}>
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                          <img src={Text1Png} alt={''}></img>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col card-bg-light p-4 mb-4 mr-4" style={{ paddingLeft: 0 }}>
                        <h5 className="text-primary">{t('sub-text')}</h5>
                        <p>{t('settings-that-define-sub-text-object-of-your-element')}</p>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('text-color-0')}</label>
                          <div className="col" style={{ paddingLeft: 0 }}>
                            <div className="form-group pb-0 col">
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setTextSubColorPicker(!shoTextSubColorPicker);
                                }}
                                style={{
                                  backgroundColor: textSubColor,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={shoTextSubColorPicker}
                                color={textSubColor}
                                onChange={(color) => {
                                  setTextSubColor(color);
                                  setTextSubColorPicker(!shoTextSubColorPicker);
                                }}
                                setVisible={setTextSubColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                COLOR
                              </div>
                            </div>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>

                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('text-bold')}</label>

                          <div className="col">
                            <Form.Item className="form-group pb-0" name="subtext_bold">
                              <Switch className="ch-texts mt-2" checked={textSubBold} defaultChecked={false} onChange={() => setTextSubBold(!textSubBold)} />
                            </Form.Item>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('show-text-0')}</label>

                          <div className="col">
                            <Form.Item className="form-group pb-0" name="subtext_enabled">
                              <Switch className="ch-texts mt-2" checked={textSubEnabled} defaultChecked={true} onChange={() => setTextSubEnabled(!textSubEnabled)} />
                            </Form.Item>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                      </div>
                      <div className="col card-bg-light p-4 mb-4 mr-1" style={{ paddingLeft: 0 }}>
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                          <img src={Text2Png} alt={''}></img>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 p-0 mt-3">
                    <button type="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-240 height-48">
                      {t('update-text')}
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LayoutModel;
