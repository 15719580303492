import { Button } from 'antd';

import { useState } from 'react';
import { useParams } from 'react-router-dom';
import EpgUrlModal from '../../../../../common/components/Content/Epg/EpgUrlModal';
import Table from '../../../../../common/components/Content/Table';
import { IMSNameParams } from '../../../../dashboard';
import { useTranslation } from 'react-i18next';

const Albums = () => {
  const { clientName, deployment: service_name } = useParams<IMSNameParams & { deployment: string }>();
  const [showUrlModal, setShowUrlModal] = useState(false);
  const { t } = useTranslation();
  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-6 mb-2">
          <h2 className="page-title">{t('source-urls')}</h2>
          <p className="h6 font-weight-normal line-height-26">
          {t('you-are-able-to-put-as-many-source-urls-as-you-like-here-in-order-to-prevent-them-from-being-too-large-we-advise-adding-them-one-per-channel-the-json-format-is-the-one-we-recommend-using-over-xmltv-because-xml-files-are-often-50-larger-than-json-files')} 
           <br />
            <br />
            {t('you-can-map-your-source-urls-on-the-epg-linking-page-for-radio-stations-and-channels-after-you-have-added-them')} 
          </p>
          <br />
          <p className="h6 font-weight-normal line-height-26">
            {t('support-suppliers')}
            <br />
            <br />{t('tv-profile')}<a href="https://metaprofile.tv/">www.metaprofile.tv</a>{t('json-format')}
            <br />{t('epg-best')}<a href="https://epg.best/">www.epg.best</a>{t('json-format-0')}
            <br />{t('xmltv')}<a href="https://wiki.xmltv.org/index.php/XMLTVFormat">{t('xmltv-wiki')}</a>{t('xmltv-format')}
            <br />
            <br/>
          </p>
          <p className="h6 font-weight-normal line-height-26">{t('please-contact-our-support-if-your-supplier-is-not-on-the-above-list-and-we-will-add-it-as-soon-as-we-can')}</p>
        </div>
        <div className="col-md-6"></div>
      </div>
      <div className="row mt-4">
        <div className="col-md-7 mb-4 mb-md-2 d-flex justify-content-center justify-content-md-start align-items-start"></div>
        <div className="col-md-5 d-flex justify-content-center justify-content-md-end align-items-start">
          <Button className="btn btn-secondary rounded-pill btn-default float-right width-240 height-48" onClick={() => setShowUrlModal(true)}>
            {t('add-source-url')}
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-4">
          {showUrlModal && (
            <EpgUrlModal
              onCancel={() => {
                setShowUrlModal(!showUrlModal);
              }}
              onSubmit={(values: any) => {
                setShowUrlModal(!showUrlModal);
                window.location.reload();
              }}
              visible={showUrlModal}
            />
          )}
          <Table service_name={service_name} clientName={clientName} type={'epgs'} variant={'normal'}></Table>
        </div>
      </div>
    </div>
  );
};

export default Albums;
