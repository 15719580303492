export function STORAGE_ENDPOINTS(clientName: any, deployment: any, service?: any, packageid?: any) {
  return {
    CONTENT: {
      DRM: {
        PEMS: clientName + '/' + deployment + '/drm/pems',
        KEYS: clientName + '/' + deployment + '/drm/keys',
      },
      CHANNELS: {
        IMAGES: clientName + '/' + deployment + '/channels/images',
        BUNDLES: clientName + '/' + deployment + '/channels/bundles',
        LISTS: clientName + '/' + deployment + '/channels/lists',
        EPG: clientName + '/' + deployment + '/channels/epg',
        DETAILS: clientName + '/' + deployment + '/channels/details',
      },
      CAMS: {
        IMAGES: clientName + '/' + deployment + '/cams/images',
        BUNDLES: clientName + '/' + deployment + '/cams/bundles',
        LISTS: clientName + '/' + deployment + '/cams/lists',
      },
      RADIOS: {
        IMAGES: clientName + '/' + deployment + '/radios/images',
        BUNDLES: clientName + '/' + deployment + '/radios/bundles',
        LISTS: clientName + '/' + deployment + '/radios/lists',
        EPG: clientName + '/' + deployment + '/radios/epg',
        DETAILS: clientName + '/' + deployment + '/radios/details',
      },
      MOVIES: {
        IMAGES: clientName + '/' + deployment + '/movies/images',
        BUNDLES: clientName + '/' + deployment + '/movies/bundles',
        LISTS: clientName + '/' + deployment + '/movies/lists',
        DETAILS: clientName + '/' + deployment + '/movies/details',
        STREAMS: clientName + '/' + deployment + '/movies/streams',
      },
      SHORTS: {
        IMAGES: clientName + '/' + deployment + '/shorts/images',
        BUNDLES: clientName + '/' + deployment + '/shorts/bundles',
        LISTS: clientName + '/' + deployment + '/shorts/lists',
        DETAILS: clientName + '/' + deployment + '/shorts/details',
        STREAMS: clientName + '/' + deployment + '/shorts/streams',
      },
      GAMES: {
        IMAGES: clientName + '/' + deployment + '/games/images',
        BUNDLES: clientName + '/' + deployment + '/games/bundles',
        LISTS: clientName + '/' + deployment + '/games/lists',
        DETAILS: clientName + '/' + deployment + '/games/details',
      },
      SERIES: {
        IMAGES: clientName + '/' + deployment + '/series/images',
        BUNDLES: clientName + '/' + deployment + '/series/bundles',
        LISTS: clientName + '/' + deployment + '/series/lists',
        DETAILS: clientName + '/' + deployment + '/series/details',
        STREAMS: clientName + '/' + deployment + '/series/streams',
      },
      PODCASTS: {
        IMAGES: clientName + '/' + deployment + '/podcasts/images',
        BUNDLES: clientName + '/' + deployment + '/podcasts/bundles',
        LISTS: clientName + '/' + deployment + '/podcasts/lists',
        DETAILS: clientName + '/' + deployment + '/podcasts/details',
        STREAMS: clientName + '/' + deployment + '/podcasts/streams',
      },
      ALBUMS: {
        IMAGES: clientName + '/' + deployment + '/albums/images',
        BUNDLES: clientName + '/' + deployment + '/albums/bundles',
        LISTS: clientName + '/' + deployment + '/albums/lists',
        DETAILS: clientName + '/' + deployment + '/albums/details',
        STREAMS: clientName + '/' + deployment + '/albums/streams',
      },
      APPS: {
        IMAGES: clientName + '/' + deployment + '/apps/images',
        BUNDLES: clientName + '/' + deployment + '/apps/bundles',
        LISTS: clientName + '/' + deployment + '/apps/lists',
      },
      COURSES: {
        IMAGES: clientName + '/' + deployment + '/courses/images',
        BUNDLES: clientName + '/' + deployment + '/courses/bundles',
        LISTS: clientName + '/' + deployment + '/courses/lists',
        DETAILS: clientName + '/' + deployment + '/courses/details',
        STREAMS: clientName + '/' + deployment + '/courses/streams',
      },
    },
    PRODUCTS: {
      PRODUCTS: clientName + '/' + deployment + '/' + service + '/products',
    },
    CUSTOMERS: {
      CUSTOMERS: clientName + '/' + deployment + '/users',
    },
    SERVICES: {
      CUSTOMERS: {
        PROFILE: service + '/customers',
        PASSWORD: service + '/customers',
        DVR: service?.name + '/',
      },
      BILLING: {
        IMAGES: clientName + '/' + deployment + '/' + service + '/billing/images',
        PRODUCTS: service + '/billing/products',
        THEME: service + '/billing/theme',
      },
      ADVERTISEMENT: {},
      MARKETING: {},
    },
    APPBUILDER: {
      APP: 'donotremove/tvms/domains/' + packageid,
      IMAGES: clientName + '/' + deployment + '/appbuilder/images',
    },
  };
}
