import React from 'react';
import { useParams } from 'react-router';
import { FormSection, Item, Select, InputNumber, ColorPickerModal } from '../../../../common/components/form';
import { Form } from 'antd';
import { BillingContext } from '../../../../contexts/billingContext';
import { useFonts } from '../../../../services/areas/billing/shoppingCartService';
import { useTranslation } from 'react-i18next';

type DesignFieldsProps = {
  whiteBackgrounds?: boolean;
};

export const DesignFields = ({ whiteBackgrounds }: DesignFieldsProps) => {
  const { clientName } = useParams<{ clientName: string }>();
  const { deployment, service } = React.useContext(BillingContext);
  const { isLoading, data } = useFonts({ instance: clientName, cms: deployment?.name ?? '', crm: service?.name || '' });
  const {t} = useTranslation();
  
  const formInstance = Form.useFormInstance();

  const primaryColor = Form.useWatch(['design', 'globals', 'primary-color']);
  const secondaryColor = Form.useWatch(['design', 'globals', 'secondary-color']);

  const backgroundColor = Form.useWatch(['design', 'globals', 'background-color']);
  const textColor = Form.useWatch(['design', 'globals', 'text-color']);

  const navbarBackgroundColor = Form.useWatch(['design', 'navbar', 'background-color']);
  const navbarTextColor = Form.useWatch(['design', 'navbar', 'text-color']);

  const primaryButtonTextColor = Form.useWatch(['design', 'buttons', 'primary', 'font-color']);
  const primaryButtonBackgroundColor = Form.useWatch(['design', 'buttons', 'primary', 'background-color']);
  const primaryButtonBorderColor = Form.useWatch(['design', 'buttons', 'primary', 'border-color']);

  const secondaryButtonTextColor = Form.useWatch(['design', 'buttons', 'secondary', 'font-color']);
  const secondaryButtonBackgroundColor = Form.useWatch(['design', 'buttons', 'secondary', 'background-color']);
  const secondaryButtonBorderColor = Form.useWatch(['design', 'buttons', 'secondary', 'border-color']);

  const labelFontColor = Form.useWatch(['design', 'forms', 'labels', 'font-color']);

  const inputTextColor = Form.useWatch(['design', 'forms', 'inputs', 'font-color']);
  const inputBackgroundColor = Form.useWatch(['design', 'forms', 'inputs', 'background-color']);
  const inputBorderColor = Form.useWatch(['design', 'forms', 'inputs', 'border-color']);
  return (
    <>
      <FormSection title={t('general')} backgroundWhite={whiteBackgrounds} subtitle={t('general-settings-for-your-design-configure-default-fonts-colors-etc')}>
        <Item name={['design', 'globals', 'font-family']} label={t('font-family')} extra={t('choose-a-default-font-to-be-used-on-your-page')}>
          <Select
            className="default-select"
            loading={isLoading}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => {
              return ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase());
            }}
            options={data ? data.data.items.map((x) => ({ label: x.family, value: x.family })) : []}
          />
        </Item>
        <Item name={['design', 'globals', 'font-size']} label={t('font-size')} extra={t('font-size-used-for-all-body-text-in-px')}>
          <InputNumber />
        </Item>
        <Item name={['design', 'globals', 'primary-color']} label={t('primary-color')} extra={t('select-a-primary-color-to-be-used-on-your-page')}>
          <ColorPickerModal currentColor={primaryColor} onOk={(color) => formInstance.setFieldValue(['design', 'globals', 'primary-color'], color)} />
        </Item>
        <Item name={['design', 'globals', 'secondary-color']} label={t('secondary-color')} extra={t('select-a-secondary-color-to-be-used-on-your-page')}>
          <ColorPickerModal currentColor={secondaryColor} onOk={(color) => formInstance.setFieldValue(['design', 'globals', 'secondary-color'], color)} />
        </Item>
        <Item name={['design', 'globals', 'background-color']} label={t('background-color-3')} extra={t('choose-a-background-color-for-your-page')}>
          <ColorPickerModal currentColor={backgroundColor} onOk={(color) => formInstance.setFieldValue(['design', 'globals', 'background-color'], color)} />
        </Item>
        <Item name={['design', 'globals', 'text-color']} label={t('text-color-0')} extra={t('choose-a-color-for-the-body-text-on-your-page')}>
          <ColorPickerModal currentColor={textColor} onOk={(color) => formInstance.setFieldValue(['design', 'globals', 'text-color'], color)} />
        </Item>
      </FormSection>

      <FormSection title={t('navbar')} subtitle={t('the-navbar-is-shown-on-top-of-your-page-and-is-used-for-customer-to-navigate-the-page')}>
        <Item name={['design', 'navbar', 'background-color']} label={t('background-color-3')} extra={t('choose-the-navbar-background-color')}>
          <ColorPickerModal currentColor={navbarBackgroundColor} onOk={(color) => formInstance.setFieldValue(['design', 'navbar', 'background-color'], color)} />
        </Item>
        <Item name={['design', 'navbar', 'text-color']} label={t('text-color-0')} extra={t('choose-a-text-color-for-the-navbar-make-sure-it-contrasts-the-background-color')}>
          <ColorPickerModal currentColor={navbarTextColor} onOk={(color) => formInstance.setFieldValue(['design', 'navbar', 'text-color'], color)} />
        </Item>
      </FormSection>

      <FormSection title={t('buttons-3')} backgroundWhite={whiteBackgrounds} subtitle={t('configure-button-settings-for-your-page')}>
        <FormSection title={t('primary-button')} backgroundWhite subtitle={t('styling-options-for-the-primary-buttons-on-your-page')}>
          <Item name={['design', 'buttons', 'primary', 'font-size']} label={t('font-size-0')} extra={t('font-size-for-this-button-in-px')}>
            <InputNumber />
          </Item>
          <Item name={['design', 'buttons', 'primary', 'font-color']} label={t('font-color')} extra={t('font-color-for-this-button')}>
            <ColorPickerModal currentColor={primaryButtonTextColor} onOk={(color) => formInstance.setFieldValue(['design', 'buttons', 'primary', 'font-color'], color)} />
          </Item>
          <Item name={['design', 'buttons', 'primary', 'background-color']} label={t('background-color-3')} extra={t('background-color-for-this-button')}>
            <ColorPickerModal currentColor={primaryButtonBackgroundColor} onOk={(color) => formInstance.setFieldValue(['design', 'buttons', 'primary', 'background-color'], color)} />
          </Item>
          <Item name={['design', 'buttons', 'primary', 'border-width']} label={t('border-size')} extra={t('border-width-for-this-button-in-px-set-to-zero-if-you-dont-want-a-border-to-show')}>
            <InputNumber />
          </Item>
          <Item name={['design', 'buttons', 'primary', 'border-color']} label={t('border-color-0')} extra={t('color-of-the-border-for-this-button')}>
            <ColorPickerModal currentColor={primaryButtonBorderColor} onOk={(color) => formInstance.setFieldValue(['design', 'buttons', 'primary', 'border-color'], color)} />
          </Item>
          <Item name={['design', 'buttons', 'primary', 'border-radius']} label={t('border-radius')} extra={t('border-radius-for-this-button-in-px-this-will-determine-for-rounded-the-corners-of-the-button-are')}>
            <InputNumber />
          </Item>
        </FormSection>

        <FormSection title={t('secondary-button')} backgroundWhite subtitle={t('styling-options-for-the-secondary-buttons-on-your-page')}>
          <Item name={['design', 'buttons', 'secondary', 'font-size']} label={t('font-size-1')} extra={t('font-size-for-this-button-in-px-0')}>
            <InputNumber />
          </Item>
          <Item name={['design', 'buttons', 'secondary', 'font-color']} label={t('font-color')} extra={t('font-color-for-this-button-0')}>
            <ColorPickerModal currentColor={secondaryButtonTextColor} onOk={(color) => formInstance.setFieldValue(['design', 'buttons', 'secondary', 'font-color'], color)} />
          </Item>
          <Item name={['design', 'buttons', 'secondary', 'background-color']} label={t('background-color-3')} extra={t('background-color-for-this-button-0')}>
            <ColorPickerModal currentColor={secondaryButtonBackgroundColor} onOk={(color) => formInstance.setFieldValue(['design', 'buttons', 'secondary', 'background-color'], color)} />
          </Item>
          <Item name={['design', 'buttons', 'secondary', 'border-width']} label={t('border-size-0')} extra={t('border-width-for-this-button-in-px-set-to-zero-if-you-dont-want-a-border-to-show-0')}>
            <InputNumber />
          </Item>
          <Item name={['design', 'buttons', 'secondary', 'border-color']} label={t('border-color-0')} extra={t('color-of-the-border-for-this-button-0')}>
            <ColorPickerModal currentColor={secondaryButtonBorderColor} onOk={(color) => formInstance.setFieldValue(['design', 'buttons', 'secondary', 'border-color'], color)} />
          </Item>
          <Item name={['design', 'buttons', 'secondary', 'border-radius']} label={t('border-radius-0')} extra={t('border-radius-for-this-button-in-px-this-will-determine-for-rounded-the-corners-of-the-button-are-0')}>
            <InputNumber />
          </Item>
        </FormSection>
      </FormSection>
      <FormSection title={t('forms')} subtitle={t('styling-of-the-forms-on-your-page')}>
        <FormSection title={t('labels')} backgroundWhite subtitle={t('style-the-labels-used-in-your-forms-labels-are-shown-above-the-input-fields-to-tell-users-which-information-to-add')}>
          <Item name={['design', 'forms', 'labels', 'font-size']} label={t('font-size-2')} extra={t('font-size-for-this-label-in-px')}>
            <InputNumber />
          </Item>
          <Item name={['design', 'forms', 'labels', 'font-color']} label={t('font-color')} extra={t('font-color-for-this-label')}>
            <ColorPickerModal currentColor={labelFontColor} onOk={(color) => formInstance.setFieldValue(['design', 'forms', 'labels', 'font-color'], color)} />
          </Item>
        </FormSection>
        <FormSection title={t('input')} backgroundWhite subtitle={t('style-the-input-fields-of-your-forms-input-fields-are-used-by-the-user-to-input-data')}>
          <Item name={['design', 'forms', 'inputs', 'font-size']} label={t('font-size-3')} extra={t('font-size-for-the-input-fields-in-px')}>
            <InputNumber />
          </Item>
          <Item name={['design', 'forms', 'inputs', 'font-color']} label={t('font-color')} extra={t('font-color-for-the-input-fields')}>
            <ColorPickerModal currentColor={inputTextColor} onOk={(color) => formInstance.setFieldValue(['design', 'forms', 'inputs', 'font-color'], color)} />
          </Item>
          <Item name={['design', 'forms', 'inputs', 'background-color']} label={t('background-color-3')} extra={t('background-color-for-the-input-fields')}>
            <ColorPickerModal currentColor={inputBackgroundColor} onOk={(color) => formInstance.setFieldValue(['design', 'forms', 'inputs', 'background-color'], color)} />
          </Item>
          <Item name={['design', 'forms', 'inputs', 'border-width']} label={t('border-size-1')} extra={t('border-width-for-the-input-fields-in-px-set-to-zero-if-you-dont-want-a-border-to-show')}>
            <InputNumber />
          </Item>
          <Item name={['design', 'forms', 'inputs', 'border-color']} label={t('border-color-0')} extra={t('color-of-the-border-for-the-input-fields')}>
            <ColorPickerModal currentColor={inputBorderColor} onOk={(color) => formInstance.setFieldValue(['design', 'forms', 'inputs', 'border-color'], color)} />
          </Item>
          <Item name={['design', 'forms', 'inputs', 'border-radius']} label={t('border-radius-1')} extra={t('border-radius-for-the-input-fields-in-px-this-will-determine-for-rounded-the-corners-of-the-input-fields-are')}>
            <InputNumber />
          </Item>
        </FormSection>
      </FormSection>
    </>
  );
};
