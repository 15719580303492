import { MW_BASE_DB_LANGUAGE } from '@mwaretv/database/build/backend/models/base/language';
import { useContext, useState } from 'react';
import { AppBuilderContext } from '../../../../contexts/appbuilderContext';
import { Button, Table, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileEdit, faFileExport, faRotateBackward } from '@fortawesome/pro-regular-svg-icons';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import axios from 'axios';
import { IMSNameParams } from '../../../dashboard';
import translationService from '../../../../services/areas/appbuilder/applications/translations/index';
import baseTranslation from './helper/english.json';
import { useTranslation } from 'react-i18next';

const Albums = () => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { t } = useTranslation();
  const { deployment } = useContext(AppBuilderContext);
  const languagesDeployment = (deployment?.languages as unknown as MW_BASE_DB_LANGUAGE[]) || [];
  const { clientName } = useParams<IMSNameParams>();
  const [languages, setLanguages] = useState([] as any);
  const [loading, setLoading] = useState(true);

  const getLanguages = async () => {
    var languagesOut = [] as any;
    try {
      const fetchCarouselPromises = languagesDeployment.map(async (language) => await axios.get('https://cloudtv.akamaized.net/' + clientName + '/' + deployment?.name + '/appbuilder/translations/' + language.name + '.json'));
      Promise.allSettled(fetchCarouselPromises).then((values) => {
        values.forEach((value, index) => {
          if (value.status == 'rejected') {
            languagesOut.push({
              available: false,
              name: languagesDeployment[index].name,
            });
          } else {
            languagesOut.push({
              available: true,
              name: languagesDeployment[index].name,
            });
          }
        });
        setLanguages(languagesOut);
        setLoading(false);
      });
    } catch (err) {
    } finally {
    }
  };

  useEffectOnce(() => {
    getLanguages();
  });

  const instalTranslation = async (language) => {
    setLoading(true);
    await translationService
      .Install_Translation({
        language: language.name,
        base: baseTranslation,
        client: clientName,
        deployment: deployment?.name,
      })
      .then((response) => {
        setTimeout(() => {
          history.push(url);
        }, 5000);
      });
  };
  const resetTranslation = async (language) => {
    setLoading(true);
    await translationService
      .Install_Translation({
        language: language.name,
        base: baseTranslation,
        client: clientName,
        deployment: deployment?.name,
      })
      .then((response) => {
        setTimeout(() => {
          history.push(url);
        }, 5000);
      });
  };

  const LanguagesColumns = [
    {
      title: t('language'),
      dataIndex: 'name',
    },
    {
      title: t('actions'),
      dataIndex: 'action',
      width: 200,
      render: (index: any, row: any) => (
        <span className="justify-content-end" style={{ height: 60 }}>
          <Tooltip placement="top" title={`Edit`}>
            {row.available && (
              <Button className="mx-2 cursor-pointer" onClick={(event) => resetTranslation(row)} type="text">
                <FontAwesomeIcon className="action-icon" icon={faRotateBackward} />
                {t('reload')}
              </Button>
            )}
            {row.available && (
              <Button className="mx-2 cursor-pointer" onClick={() => editTranslation(row)} type="text">
                <FontAwesomeIcon className="action-icon" icon={faFileEdit} />
                {t('edit-0')}
              </Button>
            )}
            {!row.available && (
              <Button className="mx-2 cursor-pointer" onClick={() => instalTranslation(row)} type="text">
                <FontAwesomeIcon className="action-icon" icon={faFileExport} />
                {t('install-translation')}
              </Button>
            )}
          </Tooltip>
        </span>
      ),
    },
  ];

  const editTranslation = (language) => {
    history.push(url + '/edit/' + language.name);
  };

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-6 mb-2">
          <h2 className="page-title">{t('app-translations')}</h2>
          <p className="h6 font-weight-normal line-height-26">{t('app-translations-are-for-all-the-word-phrases-and-other-texts-used-in-the-apps')}</p>
        </div>
        <div className="col-md-6"></div>
      </div>
      <div className="row">
        <div className="col-12 mt-5 card-bg-light p-5">
          <h4 className="text-primary mt-5 mb-3">{t('explanation')}</h4>
          <div className="col-md-9 mb-5" style={{ paddingLeft: 0 }}>
            {t('explanation-on-the-working-of-the-translations')}
            <br />
            <br />
            {t('before-you-can-start-using-them-in-the-apps-or-start-editing-you-first-have-to-install-the-translation-files')}
            <br />
            {t('reset-will-load-the-default-file-from-the-server-and-all-your-edits-will-be-removed')}
            <br />
            {t('edit-gives-you-the-option-to-change-the-language-translation-file-if-it-contains-errors-or-if-you-want-to-rename-things')}
          </div>
          <Table
            rowKey={(languages) => languages.name}
            loading={loading}
            columns={LanguagesColumns}
            dataSource={languages}
            key={JSON.stringify(languages)}
            pagination={{
              position: ['bottomLeft'],
              pageSize: 10,
              hideOnSinglePage: true,
              showTotal: (total, range) => {
                return `${range[0]}-${range[1]} of ${total} items`;
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Albums;
