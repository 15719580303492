import { useTranslation } from 'react-i18next';
import NestedBundles from '../../../../../common/components/Content/Bundles/NestedBundles';

const Bundles = () => {
  const { t } = useTranslation();
  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-6">
          <h2 className="page-title">{t('your-radio-bundles')}</h2>
          <p className="h6 font-weight-normal line-height-26">
            {t('bundles-are-an-easy-way-to-group-your-content-to-be-used-in-your-products-each-bundle-contains-categories-who-in-return-contain-radio-stations-when-publishing-your-bundles-the-mapped-tv-guide-data-is-also-published')}
          </p>
          <p>
            {t('during-the-deployment-setup-you-have-chosen-the-timezone-on-which-the-task-scheduler-should-run-on-this-means-each-night-your-tv-guide-gets-refreshed-and-published-together-with-your-bundles-0')}
          </p>
        </div>
        <div className="col-md-6"></div>
      </div>
      <div className="row">
        <div className="col-12 mt-4">
          <NestedBundles type={'Radios'}></NestedBundles>
        </div>
      </div>
    </div>
  );
};

export default Bundles;
