import { faInboxOut } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Upload, Image } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { StatusCodes } from 'http-status-codes';
import { useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { IFileUpload } from '../../../interfaces/responses/common/IFileUploadResult';
import common from '../../../services/common';
import { checkImageRatio, getCorrectSizing, getImageSizes } from './Helper';

type IUpdateImageUploadModalProps = {
  onSubmit: (values: any) => void;
  initialData: {
    path: string;
    uploadType: string;
    uploadedImage?: any;
    uploadName: string;
    notMandatory?: boolean;
  };
};

const ImageUploader = (props: IUpdateImageUploadModalProps) => {
  const { onSubmit, initialData } = props;
  let [form] = useForm();
  const [upload, setUpload] = useState<IFileUpload>({ name: '' });
  const [error, setError] = useState([] as any);
  const { t } = useTranslation();

  const handleImageUpload = async (options: any, ratio: any) => {
    setError('');
    options.onError('');
    if (initialData.uploadType == 'freeform') {
      await getImageSizes(options.file).then(async (result: any) => {
        let response = await common.UploadImage({
          image: options.file,
          query_params: {
            path: initialData.path,
            name: options.file.name,
            type: options.file.type,
            width: Number(result.width),
            height: Number(result.height),
          },
        });
        if (response && response.status_code === StatusCodes.OK) {
          setUpload(response.data);
          onSubmit(response.data);
          options.onSuccess(null, response.data);
        } else {
          if (response.errors != undefined) {
            const message = response.errors[0] as any;
            setError(message.message);
            options.onError(() => t('an-error-occured') + message.message);
          }
        }
      });
    } else {
      await checkImageRatio(options.file, ratio).then(async (checkRatio: any) => {
        if (checkRatio.status) {
          var sizing = getCorrectSizing(initialData.uploadType);
          let response = await common.UploadImage({
            image: options.file,
            query_params: {
              path: initialData.path,
              name: options.file.name,
              type: options.file.type,
              width: sizing?.width,
              height: sizing?.height,
            },
          });
          if (response && response.status_code === StatusCodes.OK) {
            setUpload(response.data);
            onSubmit(response.data);
            options.onSuccess(null, response.data);
          } else {
            if (response.errors != undefined) {
              const message = response.errors[0] as any;
              setError(message.message);
              options.onError(() => t('an-error-occured') + message.message);
            }
          }
        } else {
          setError(t('the-image-size') + ' ' + getCorrectSizing(initialData.uploadType)?.width + ' * ' + getCorrectSizing(initialData.uploadType)?.height + ' ' +  t('is-not-correct-adjust-your-image-and-try-again'));
          options.onError(() => t('the-image-ratio-is-not-correct-please-use-and-image-with-the-correct-ratio-and-try-again'));
        }
      });
    }
  };

  useEffectOnce(() => {
    form.setFieldsValue(initialData);
  });

  return (
    <div>
      <h4 className="text-primary mt-5 mb-3">{initialData.uploadName}</h4>
      <div className="col-md-12 card-bg-light" style={{ backgroundColor: '#fafafc' }}>
        <div className="row" style={{ alignItems: 'center' }}>
          <div className="col-md-6 row ml-2 p-2" style={{ alignItems: 'center' }}>
            <table>
              <tbody>
                <tr>
                  <td>
                    {initialData.uploadType == 'freeform' && (
                      <div>
                        {(initialData.uploadedImage == undefined || initialData.uploadedImage == '' || typeof initialData.uploadedImage == 'object') && (
                          <div style={{ width: 200, height: '100%', border: '2px dashed #999' }}>
                            <div
                              style={{
                                fontSize: 12,
                                textAlign: 'center',
                                margin: 15,
                              }}
                            >
                              {t('any-size')}
                            </div>
                          </div>
                        )}
                        {initialData.uploadedImage != undefined && initialData.uploadedImage != '' && typeof initialData.uploadedImage != 'object' && (
                          <div style={{ width: 200, height: '100%' }}>
                            {initialData.uploadedImage.indexOf('cloudtv.akamaized.net') < 0 && <Image height="100%" width="200px" src={'https://cloudtv.akamaized.net/' + initialData.uploadedImage}></Image>}
                            {initialData.uploadedImage.indexOf('cloudtv.akamaized.net') > -1 && <Image height="100%" width="200px" src={initialData.uploadedImage}></Image>}
                          </div>
                        )}
                      </div>
                    )}

                    {initialData.uploadType == 'logo' && (
                      <div>
                        {(initialData.uploadedImage == undefined || initialData.uploadedImage == '' || typeof initialData.uploadedImage == 'object') && (
                          <div style={{ height: 100, border: '2px dashed #999' }}>
                            <div
                              style={{
                                fontSize: 12,
                                textAlign: 'center',
                                marginTop: 40,
                              }}
                            >
                              {t('300-x-variable')}
                            </div>
                          </div>
                        )}
                        {initialData.uploadedImage != undefined && initialData.uploadedImage != '' && typeof initialData.uploadedImage != 'object' && (
                          <div style={{ height: 100 }}>
                            {initialData.uploadedImage.indexOf('cloudtv.akamaized.net') < 0 && <Image height="100px" src={'https://cloudtv.akamaized.net/' + initialData.uploadedImage}></Image>}
                            {initialData.uploadedImage.indexOf('cloudtv.akamaized.net') > -1 && <Image height="100px" src={initialData.uploadedImage}></Image>}
                          </div>
                        )}
                      </div>
                    )}
                    {initialData.uploadType == 'app_banner' && (
                      <div>
                        {(initialData.uploadedImage == undefined || initialData.uploadedImage == '' || typeof initialData.uploadedImage == 'object') && (
                          <div style={{ width: 320, height: 56, border: '2px dashed #999' }}>
                            <div
                              style={{
                                fontSize: 12,
                                textAlign: 'center',
                                marginTop: 15,
                              }}
                            >
                              960x168
                            </div>
                          </div>
                        )}
                        {initialData.uploadedImage != undefined && initialData.uploadedImage != '' && typeof initialData.uploadedImage != 'object' && (
                          <div style={{ width: 320, height: 56 }}>
                            {initialData.uploadedImage.indexOf('cloudtv.akamaized.net') < 0 && <Image height="56px" width="320px" src={'https://cloudtv.akamaized.net/' + initialData.uploadedImage}></Image>}
                            {initialData.uploadedImage.indexOf('cloudtv.akamaized.net') > -1 && <Image height="56px" width="320px" src={initialData.uploadedImage}></Image>}
                          </div>
                        )}
                      </div>
                    )}
                    {initialData.uploadType == 'widescreen' && (
                      <div>
                        {(initialData.uploadedImage == undefined || initialData.uploadedImage == '' || typeof initialData.uploadedImage == 'object') && (
                          <div style={{ width: 265.5, height: 150, border: '2px dashed #999' }}>
                            <div
                              style={{
                                fontSize: 12,
                                textAlign: 'center',
                                marginTop: 35,
                              }}
                            >
                              640x360
                            </div>
                          </div>
                        )}
                        {initialData.uploadedImage != undefined && initialData.uploadedImage != '' && typeof initialData.uploadedImage != 'object' && (
                          <div style={{ width: 265.5, height: 150 }}>
                            {initialData.uploadedImage.indexOf('cloudtv.akamaized.net') < 0 && <Image height="150px" width="265.5px" src={'https://cloudtv.akamaized.net/' + initialData.uploadedImage}></Image>}
                            {initialData.uploadedImage.indexOf('cloudtv.akamaized.net') > -1 && <Image height="150px" width="265.5px" src={initialData.uploadedImage}></Image>}
                          </div>
                        )}
                      </div>
                    )}
                    {initialData.uploadType == 'background' && (
                      <div>
                        {(initialData.uploadedImage == undefined || initialData.uploadedImage == '' || typeof initialData.uploadedImage == 'object') && (
                          <div style={{ width: 265.5, height: 150, border: '2px dashed #999' }}>
                            <div
                              style={{
                                fontSize: 12,
                                textAlign: 'center',
                                marginTop: 62,
                              }}
                            >
                              1920x1080
                            </div>
                          </div>
                        )}
                        {initialData.uploadedImage != undefined && initialData.uploadedImage != '' && typeof initialData.uploadedImage != 'object' && (
                          <div style={{ width: 265.5, height: 150 }}>
                            {initialData.uploadedImage.indexOf('cloudtv.akamaized.net') < 0 && <Image height="150px" width="256.5px" src={'https://cloudtv.akamaized.net/' + initialData.uploadedImage}></Image>}
                            {initialData.uploadedImage.indexOf('cloudtv.akamaized.net') > -1 && <Image height="150px" width="256.5px" src={initialData.uploadedImage}></Image>}
                          </div>
                        )}
                      </div>
                    )}
                    {initialData.uploadType == 'tile' && (
                      <div>
                        {(initialData.uploadedImage == undefined || initialData.uploadedImage == '' || typeof initialData.uploadedImage == 'object') && (
                          <div style={{ width: 160, height: 90, border: '2px dashed #999' }}>
                            <div
                              style={{
                                fontSize: 12,
                                textAlign: 'center',
                                marginTop: 32,
                              }}
                            >
                              384x216
                            </div>
                          </div>
                        )}
                        {initialData.uploadedImage != undefined && initialData.uploadedImage != '' && typeof initialData.uploadedImage != 'object' && (
                          <div style={{ width: 160, height: 90 }}>
                            {initialData.uploadedImage.indexOf('cloudtv.akamaized.net') < 0 && <Image height="100px" width="177px" src={'https://cloudtv.akamaized.net/' + initialData.uploadedImage}></Image>}
                            {initialData.uploadedImage.indexOf('cloudtv.akamaized.net') > -1 && <Image height="100px" width="177px" src={initialData.uploadedImage}></Image>}
                          </div>
                        )}
                      </div>
                    )}
                    {initialData.uploadType == 'poster' && (
                      <div>
                        {(initialData.uploadedImage == undefined || initialData.uploadedImage == '' || typeof initialData.uploadedImage == 'object') && (
                          <div style={{ width: 128, height: 200, border: '2px dashed #999' }}>
                            <div
                              style={{
                                fontSize: 12,
                                textAlign: 'center',
                                marginTop: 90,
                              }}
                            >
                              340x500
                            </div>
                          </div>
                        )}
                        {initialData.uploadedImage != undefined && initialData.uploadedImage != '' && typeof initialData.uploadedImage != 'object' && (
                          <div style={{ width: 128, height: 200 }}>
                            {initialData.uploadedImage.indexOf('cloudtv.akamaized.net') < 0 && <Image height="200px" width="128px" src={'https://cloudtv.akamaized.net/' + initialData.uploadedImage}></Image>}
                            {initialData.uploadedImage.indexOf('cloudtv.akamaized.net') > -1 && <Image height="200px" width="128px" src={initialData.uploadedImage}></Image>}
                          </div>
                        )}
                      </div>
                    )}
                    {initialData.uploadType == 'square' && (
                      <div>
                        {(initialData.uploadedImage == undefined || initialData.uploadedImage == '' || typeof initialData.uploadedImage == 'object') && (
                          <div style={{ width: 100, height: 100, border: '2px dashed #999' }}>
                            <div
                              style={{
                                fontSize: 12,
                                textAlign: 'center',
                                marginTop: 40,
                              }}
                            >
                              300x300
                            </div>
                          </div>
                        )}
                        {initialData.uploadedImage != undefined && initialData.uploadedImage != '' && typeof initialData.uploadedImage != 'object' && (
                          <div style={{ width: 100, height: 100 }}>
                            {initialData.uploadedImage.indexOf('cloudtv.akamaized.net') < 0 && <Image height="100px" width="100px" src={'https://cloudtv.akamaized.net/' + initialData.uploadedImage}></Image>}
                            {initialData.uploadedImage.indexOf('cloudtv.akamaized.net') > -1 && <Image height="100px" width="100px" src={initialData.uploadedImage}></Image>}
                          </div>
                        )}
                      </div>
                    )}
                    {initialData.uploadType == 'landscape' && (
                      <div>
                        {(initialData.uploadedImage == undefined || initialData.uploadedImage == '' || typeof initialData.uploadedImage == 'object') && (
                          <div style={{ width: 265.5, height: 150, border: '2px dashed #999' }}>
                            <div
                              style={{
                                fontSize: 12,
                                textAlign: 'center',
                                marginTop: 62,
                              }}
                            >
                              1920x1080
                            </div>
                          </div>
                        )}
                        {initialData.uploadedImage != undefined && initialData.uploadedImage != '' && typeof initialData.uploadedImage != 'object' && (
                          <div style={{ width: 265.5, height: 150 }}>
                            {initialData.uploadedImage.indexOf('cloudtv.akamaized.net') < 0 && <Image height="150px" width="256.5px" src={'https://cloudtv.akamaized.net/' + initialData.uploadedImage}></Image>}
                            {initialData.uploadedImage.indexOf('cloudtv.akamaized.net') > -1 && <Image height="150px" width="256.5px" src={initialData.uploadedImage}></Image>}
                          </div>
                        )}
                      </div>
                    )}
                    {initialData.uploadType == 'portrait' && (
                      <div>
                        {(initialData.uploadedImage == undefined || initialData.uploadedImage == '' || typeof initialData.uploadedImage == 'object') && (
                          <div style={{ width: 150, height: 265.5, border: '2px dashed #999' }}>
                            <div
                              style={{
                                fontSize: 12,
                                textAlign: 'center',
                                marginTop: 120,
                              }}
                            >
                              1080x1920
                            </div>
                          </div>
                        )}
                        {initialData.uploadedImage != undefined && initialData.uploadedImage != '' && typeof initialData.uploadedImage != 'object' && (
                          <div style={{ width: 150, height: 265.5 }}>
                            {initialData.uploadedImage.indexOf('cloudtv.akamaized.net') < 0 && <Image height="265.5px" width="150px" src={'https://cloudtv.akamaized.net/' + initialData.uploadedImage}></Image>}
                            {initialData.uploadedImage.indexOf('cloudtv.akamaized.net') > -1 && <Image height="265.5px" width="150px" src={initialData.uploadedImage}></Image>}
                          </div>
                        )}
                      </div>
                    )}
                    {initialData.uploadType == 'brandlogo' && (
                      <div>
                        {(initialData.uploadedImage == undefined || initialData.uploadedImage == '' || typeof initialData.uploadedImage == 'object') && (
                          <div style={{ width: 200, height: 50, border: '2px dashed #999' }}>
                            <div
                              style={{
                                fontSize: 12,
                                textAlign: 'center',
                                marginTop: 13,
                              }}
                            >
                              200x50
                            </div>
                          </div>
                        )}
                        {initialData.uploadedImage != undefined && initialData.uploadedImage != '' && typeof initialData.uploadedImage != 'object' && (
                          <div style={{ width: 200, height: 50 }}>
                            {initialData.uploadedImage.indexOf('cloudtv.akamaized.net') < 0 && <Image height="50px" width="200px" src={'https://cloudtv.akamaized.net/' + initialData.uploadedImage}></Image>}
                            {initialData.uploadedImage.indexOf('cloudtv.akamaized.net') > -1 && <Image height="50px" width="200px" src={initialData.uploadedImage}></Image>}
                          </div>
                        )}
                      </div>
                    )}
                    {initialData.uploadType == 'brandicon' && (
                      <div>
                        {(initialData.uploadedImage == undefined || initialData.uploadedImage == '' || typeof initialData.uploadedImage == 'object') && (
                          <div style={{ width: 200, height: 200, border: '2px dashed #999' }}>
                            <div
                              style={{
                                fontSize: 12,
                                textAlign: 'center',
                                marginTop: 90,
                              }}
                            >
                              200x200
                            </div>
                          </div>
                        )}
                        {initialData.uploadedImage != undefined && initialData.uploadedImage != '' && typeof initialData.uploadedImage != 'object' && (
                          <div style={{ width: 200, height: 200 }}>
                            {initialData.uploadedImage.indexOf('cloudtv.akamaized.net') < 0 && <Image height="200px" width="200px" src={'https://cloudtv.akamaized.net/' + initialData.uploadedImage}></Image>}
                            {initialData.uploadedImage.indexOf('cloudtv.akamaized.net') > -1 && <Image height="200px" width="200px" src={initialData.uploadedImage}></Image>}
                          </div>
                        )}
                      </div>
                    )}
                    {initialData.uploadType == 'portrait_wide_logo' && (
                      <div>
                        {(initialData.uploadedImage == undefined || initialData.uploadedImage == '' || typeof initialData.uploadedImage == 'object') && (
                          <div style={{ width: 216, height: 20, border: '2px dashed #999' }}>
                            <div
                              style={{
                                fontSize: 12,
                                textAlign: 'center',
                                marginTop: 40,
                              }}
                            >
                              1080x100
                            </div>
                          </div>
                        )}
                        {initialData.uploadedImage != undefined && initialData.uploadedImage != '' && typeof initialData.uploadedImage != 'object' && (
                          <div style={{ width: 216, height: 20 }}>
                            {initialData.uploadedImage.indexOf('cloudtv.akamaized.net') < 0 && <Image height="20px" width="216px" src={'https://cloudtv.akamaized.net/' + initialData.uploadedImage}></Image>}
                            {initialData.uploadedImage.indexOf('cloudtv.akamaized.net') > -1 && <Image height="20px" width="216px" src={initialData.uploadedImage}></Image>}
                          </div>
                        )}
                      </div>
                    )}
                    {initialData.uploadType == 'landscape_wide_logo' && (
                      <div>
                        {(initialData.uploadedImage == undefined || initialData.uploadedImage == '' || typeof initialData.uploadedImage == 'object') && (
                          <div style={{ width: 384, height: 20, border: '2px dashed #999' }}>
                            <div
                              style={{
                                fontSize: 12,
                                textAlign: 'center',
                                marginTop: 40,
                              }}
                            >
                              1920x75
                            </div>
                          </div>
                        )}
                        {initialData.uploadedImage != undefined && initialData.uploadedImage != '' && typeof initialData.uploadedImage != 'object' && (
                          <div style={{ width: 384, height: 20 }}>
                            {initialData.uploadedImage.indexOf('cloudtv.akamaized.net') < 0 && <Image height="15px" width="384px" src={'https://cloudtv.akamaized.net/' + initialData.uploadedImage}></Image>}
                            {initialData.uploadedImage.indexOf('cloudtv.akamaized.net') > -1 && <Image height="15px" width="384px" src={initialData.uploadedImage}></Image>}
                          </div>
                        )}
                      </div>
                    )}
                    {initialData.uploadType == 'brandfavicon' && (
                      <div>
                        {(initialData.uploadedImage == undefined || initialData.uploadedImage == '' || typeof initialData.uploadedImage == 'object') && (
                          <div style={{ width: 100, height: 100, border: '2px dashed #999' }}>
                            <div
                              style={{
                                fontSize: 12,
                                textAlign: 'center',
                                marginTop: 38,
                              }}
                            >
                              50x50
                            </div>
                          </div>
                        )}
                        {initialData.uploadedImage != undefined && initialData.uploadedImage != '' && typeof initialData.uploadedImage != 'object' && (
                          <div style={{ width: 100, height: 100 }}>
                            {initialData.uploadedImage.indexOf('cloudtv.akamaized.net') < 0 && <Image height="100px" width="100px" src={'https://cloudtv.akamaized.net/' + initialData.uploadedImage}></Image>}
                            {initialData.uploadedImage.indexOf('cloudtv.akamaized.net') > -1 && <Image height="100px" width="100px" src={initialData.uploadedImage}></Image>}
                          </div>
                        )}
                      </div>
                    )}
                  </td>
                  <td style={{ paddingLeft: 25 }}></td>
                </tr>
                <tr>
                  <td></td>
                  <td style={{ paddingLeft: 25 }}>
                    <div style={{ color: 'red' }}>{error}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Form.Item
            className="col-md-6 mb-0 mt-4"
            name={'uploaded_cover_file_' + initialData.uploadType}
            valuePropName="fileList"
            getValueFromEvent={(e: any) => {
              if (Array.isArray(e)) {
                return e;
              }
              return e && e.fileList;
            }}
            rules={[
              {
                required: initialData.uploadedImage != undefined ? false : initialData.notMandatory != undefined ? false : true,
                message: initialData.uploadType + t('image-is-required'),
              },
            ]}
          >
            <Upload.Dragger
              listType="picture"
              className="default-upload"
              maxCount={1}
              showUploadList={{
                showPreviewIcon: true,
              }}
              multiple={false}
              accept=".png, .jpg, .jpeg"
              customRequest={(options) => {
                if (initialData.uploadType == 'freeform') {
                  handleImageUpload(options, 0 / 0);
                }
                if (initialData.uploadType == 'landscape_wide_logo') {
                  handleImageUpload(options, 1920 / 75);
                }
                if (initialData.uploadType == 'portrait_wide_logo') {
                  handleImageUpload(options, 1080 / 100);
                }
                if (initialData.uploadType == 'square') {
                  handleImageUpload(options, 1 / 1);
                }
                if (initialData.uploadType == 'widescreen') {
                  handleImageUpload(options, 16 / 9);
                }
                if (initialData.uploadType == 'background') {
                  handleImageUpload(options, 16 / 9);
                }
                if (initialData.uploadType == 'tile') {
                  handleImageUpload(options, 16 / 9);
                }
                if (initialData.uploadType == 'logo') {
                  handleImageUpload(options, 3 / 1);
                }
                if (initialData.uploadType == 'app_banner') {
                  handleImageUpload(options, 960 / 168);
                }
                if (initialData.uploadType == 'poster') {
                  handleImageUpload(options, 0.68 / 1);
                }
                if (initialData.uploadType == 'landscape') {
                  handleImageUpload(options, 16 / 9);
                }
                if (initialData.uploadType == 'portrait') {
                  handleImageUpload(options, 9 / 16);
                }
                if (initialData.uploadType == 'brandlogo') {
                  handleImageUpload(options, 2 / 0.5);
                }
                if (initialData.uploadType == 'brandicon') {
                  handleImageUpload(options, 1 / 1);
                }
                if (initialData.uploadType == 'brandfavicon') {
                  handleImageUpload(options, 1 / 1);
                }
              }}
              onRemove={() => {
                setUpload({
                  name: '',
                });
              }}
            >
              <div className="media d-inline-flex align-items-center">
                <FontAwesomeIcon size="2x" className="mr-3" icon={faInboxOut} color="#1ac884" />
                <div className="media-body text-left my-3">
                  <h6 className="mt-0 text-primary">{t('upload-your-file')}</h6>
                  <p className="mb-0 h6 icon-gray font-weight-normal">
                    {t('drag-files-here-or')} <span className="text-secondary"> {t('browse')}</span>
                  </p>
                  <p className="mb-0 h6 icon-gray font-weight-normal">
                    {t('required-size')} {getCorrectSizing(initialData.uploadType)?.width} * {getCorrectSizing(initialData.uploadType)?.height}
                  </p>
                </div>
              </div>
            </Upload.Dragger>
          </Form.Item>
          <Form.Item name={initialData.uploadType} shouldUpdate={(pre, current) => pre.value !== current.value}>
            <div hidden>{upload.name}</div>
          </Form.Item>
        </div>
      </div>
    </div>
  );
};
export default ImageUploader;
