import { Button, Tabs } from 'antd';

import { useState } from 'react';
import { useParams } from 'react-router-dom';
import EpgUrlModal from '../../../../../common/components/Content/Epg/EpgUrlModal';
import Table from '../../../../../common/components/Content/Table';
import { IMSNameParams } from '../../../../dashboard';
import { useTranslation } from 'react-i18next';

const Albums = () => {
  const [showUrlModal, setShowUrlModal] = useState(false);
  const { TabPane } = Tabs;
  const [activeKey, setActiveKey] = useState<string>('1');
  const { t } = useTranslation();
  let params = useParams<IMSNameParams & { deployment: string }>();
  const { clientName, _id } = useParams<IMSNameParams>();

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-6 mb-2">
          <h2 className="page-title">{t('guide-1')}</h2>
          <p className="h6 font-weight-normal line-height-26">
            {t('once-you-have-setup-the-source-urls-and-mapped-the-data-to-the-channels-via-channel-details-guide-settings-tab-you-can-import-manually-the-data-here')}
            <br />
            {t('also-you-can-use-this-place-to-refresh-any-last-changes-in-the-guide-data')}
            <br />
            <br />
            {t('in-order-to-reflect-your-manual-guide-data-import-in-the-apps-you-need-to-publish-the-channel-and-or-radio-bundles-as-they-are-paired-with-the-guide-data')}
            <br />
            <br />
            {t('the-guide-data-gets-automatically-refreshed-imported-each-night-and-publish-for-usage-in-the-apps-this-process-is-fully-automated-so-you-only-use-manual-refresh-and-publish-if-you-have-last-minute-changes')}
            <br />
            <br />
            {t('if-you-have-no-guide-data-for-a-particular-channel-then-we-generate-blocks-of-one-hour-automatically-to-make-sure-the-apps-have-at-least-something-to-show')}
          </p>
        </div>
        <div className="col-md-6"></div>
      </div>
      <div className="row mt-4">
        <div className="col-md-7 mb-4 mb-md-2 d-flex justify-content-center justify-content-md-start align-items-start"></div>
        <div className="col-md-5 d-flex justify-content-center justify-content-md-end align-items-start">
          <Button className="btn btn-secondary rounded-pill btn-default float-right width-240 height-48" onClick={() => setShowUrlModal(true)}>
            {t('import-all')}
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-4">
          {showUrlModal && (
            <EpgUrlModal
              onCancel={() => {
                setShowUrlModal(!showUrlModal);
              }}
              onSubmit={(values: any) => {
                setShowUrlModal(!showUrlModal);
                window.location.reload();
              }}
              visible={showUrlModal}
            />
          )}
          <Tabs activeKey={activeKey} className="default-tab" onChange={(activeKey) => setActiveKey(activeKey)} defaultActiveKey={'1'}>
            <TabPane tab={t('channels')} key={'1'}>
              <div className="p-4">
                <Table service_name={params.deployment} clientName={clientName} type={'epgs_channels'} variant={'normal'}></Table>
              </div>
            </TabPane>
            <TabPane tab={t('radio-stations')} key={'2'}>
              <div className="p-4">
                <Table service_name={params.deployment} clientName={clientName} type={'epgs_radio'} variant={'normal'}></Table>
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Albums;
