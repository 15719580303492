import { Deployment, DeploymentService } from '@mwaretv/database/build/backend/models/deployment/deployment';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { IMSNameParams } from '../../../..';
import { NewServiceBanner } from '../dashboard/services/new_service';
import CustomerLineGraph from './graphs/customer_line_graph';
import { Deployments } from './deployments/deployments';
import { Spin } from '../../../../../../common/components/spinner';
import axios from 'axios';
import { VisibleForRole, hasAccessToDeployment, hasAccessToService } from '../../../../../../common/components/VisibleForRole';
import { TVMSRoles } from '../../../../../../constants/TVMSRoles';
import type { DocumentType } from '@typegoose/typegoose';
import moment from 'moment-timezone';
import { AuthContext } from '../../../../../../context_api/AuthContext';
import { useQuery } from '@tanstack/react-query';
import * as Deploymentservice from '../../../../../../services/deployments/deployment';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/pro-regular-svg-icons';
import DeploymentSettings from './settings/deploymentSettings';
import { useTranslation } from 'react-i18next';

export const DeploymentDashboard = ({ deployment }: { deployment: Deployment }) => {
  const { clientName } = useParams<IMSNameParams>();
  const [customerCount, setCustomerCount] = useState<number>(0);
  const [isFetchingCustomerCount, setIsFetchingCustomerCount] = useState<boolean>(false);
  const [chartData, setChartData] = useState<any[]>([]);
  const { state: AuthState } = useContext(AuthContext);
  const [languages, setLanguages] = useState([] as any);
  const [countries, setCountries] = useState([] as any);
  const [currencies, setCurrencies] = useState([] as any);
  const [showDeploymentSettings, setShowDeploymentSettings] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const { data, isLoading, isError } = useQuery(['deployment', deployment], async () => {
    if (deployment != undefined && deployment.name != '') {
      return Deploymentservice.find(clientName, { name: deployment.name })
        .then((r) => r.data)
        .catch((e) => Promise.reject(e));
    }
  });

  useEffect(() => {
    if (data != undefined) {
      setCountries(data?.countries);
      setLanguages(data?.languages);
      setCurrencies(data?.currencies);
    }
  }, [data]);

  useEffect(() => {
    const fetchData = async () => {
      setIsFetchingCustomerCount(true);
      const akamaiData = deployment.services.map(async (service) => {
        const path = 'https://cloudtv.akamaized.net/' + clientName + '/' + deployment.name + '/dashboards/dashboard_customers_' + service.name + '.json';
        //const path = 'https://cloudtv.akamaized.net/MwareTV/mwaretvdep/dashboards/dashboard_customers_mwaretvser.json';
        try {
          const { data } = await axios.get(path);
          return {
            response: data.filter((d) => d.month == moment().format('MM')).map((x) => ({ dateOfCount: x.date, activeCustomers: x.active.peak })),
            serviceName: service.name,
          };
        } catch (error) {
          return null;
        }
      });
      const finalData = await Promise.all(akamaiData);
      var totalPeak = 0;
      finalData.forEach((serviceData) => {
        var servicePeak = Math.max(...(serviceData?.response?.map((date) => { return date.activeCustomers}) ?? []));

        if (!isNaN(servicePeak)) {
          totalPeak += servicePeak;
        }

      });
      setCustomerCount(totalPeak);
      setChartData(finalData);
      setIsFetchingCustomerCount(false);
    };

    fetchData();
  }, [clientName, deployment]);

  const filteredChartData = chartData.filter((data) => data !== null);

  const hasAccessToApps = hasAccessToDeployment(AuthState.user!, clientName, deployment.name, 'apps');
  const hasAccessToContent = hasAccessToDeployment(AuthState.user!, clientName, deployment.name, 'content');
  const hasAccessToBilling = (service: string) => hasAccessToService(AuthState.user!, clientName, deployment.name, service, 'billing');
  const hasAccessToCustomers = (service: string) => hasAccessToService(AuthState.user!, clientName, deployment.name, service, 'customers');
  const hasAccessToMarketing = (service: string) => hasAccessToService(AuthState.user!, clientName, deployment.name, service, 'marketing');
  const hasAccessToReporting = (service: string) => false && hasAccessToService(AuthState.user!, clientName, deployment.name, service, 'reporting');

  var hasAccessToAnything = hasAccessToApps || hasAccessToContent;
  deployment.services.forEach((service) => {
    if (hasAccessToCustomers(service.name) || hasAccessToMarketing(service.name) || hasAccessToBilling(service.name)) {
      hasAccessToAnything = true;
    }
  });

  const submitSettings = () => {
    setShowDeploymentSettings(false);
    history.go(0);
  };

  return (
    <>
      {hasAccessToAnything && (
        <div className="row" key={1}>
          <div className="col-12">
            <div className="card mt-4">
              <div className="card-body ant-card-hoverable card-padding py-4 shadow">
                <div className="row">
                  <div className="col-lg-6 pb-3 mt-md-3">
                    <h3 className="text-primary text-truncate">
                      {t('deployment')}: {deployment.name}
                    </h3>
                    <VisibleForRole roles={[TVMSRoles.SuperAdmin, TVMSRoles.Admin]}>
                      <p className="h6 font-weight-normal line-height-26">{t('keep-track-of-the-total-number-of-active-customers')}</p>
                    </VisibleForRole>
                  </div>
                  <VisibleForRole roles={[TVMSRoles.SuperAdmin, TVMSRoles.Admin]}>
                    <div className="col-lg-6 pb-3 mt-md-3 text-right">
                      <h3 className="text-primary text-truncate">
                        {t('peak-active-customers')} ({moment().format('MMMM YYYY')}
                        {t('key')}
                      </h3>
                      <h2 style={{ color: '#1ac884' }}>{customerCount}</h2>
                    </div>
                  </VisibleForRole>
                  <VisibleForRole roles={[TVMSRoles.SuperAdmin, TVMSRoles.Admin]}>
                    <div className="col-12 mb-2 p-0">
                      <Spin spinning={isFetchingCustomerCount}>{filteredChartData.length > 0 ? <CustomerLineGraph chart_data={filteredChartData} totalActiveAccounts={customerCount} /> : <p></p>}</Spin>
                    </div>
                  </VisibleForRole>
                  {deployment != undefined && languages != undefined && (
                    <div className="col-12" style={{ padding: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 0, backgroundColor: '#f9f9f9', border: '1px solid #dfdfdf', borderRadius: 5 }}>
                      <div style={{ display: 'flex', width: '100%', fontSize: 12 }}>
                        <div style={{ width: '33%', paddingRight: 20 }}>
                          <b>{t('languages')}</b>
                          <div>
                            {languages.map((lang, index) => {
                              return (
                                <span key={index} style={{ marginRight: 3 }}>
                                  {lang.name}
                                  {languages.length != index + 1 ? ', ' : ''}
                                </span>
                              );
                            })}
                          </div>
                        </div>
                        <div style={{ width: '33%', paddingRight: 20 }}>
                          <b>{t('countries')}</b>
                          <div>
                            {countries.map((lang, index) => {
                              return (
                                <span key={index} style={{ marginRight: 3 }}>
                                  {lang.name}
                                  {countries.length != index + 1 ? ', ' : ''}
                                </span>
                              );
                            })}
                          </div>
                        </div>

                        <div style={{ width: '33%', paddingRight: 20 }}>
                          <b>{t('currencies')}</b>
                          <div>
                            {currencies.map((cur, index) => {
                              return (
                                <span key={index} style={{ marginRight: 3 }}>
                                  {cur.isoCurrency}
                                  {currencies.length != index + 1 ? ', ' : ''}
                                </span>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <VisibleForRole roles={[TVMSRoles.SuperAdmin, TVMSRoles.Admin, TVMSRoles.Employee]}>
                        <div className="row" style={{ height: '100%', display: 'flex', justifyContent: 'flex-start', alignContent: 'flex-start', paddingRight: 20 }}>
                          <Button className="btn-primary rounded-pill" onClick={() => setShowDeploymentSettings(true)}>
                            <FontAwesomeIcon style={{ marginTop: 4 }} icon={faCog}></FontAwesomeIcon>
                          </Button>
                        </div>
                      </VisibleForRole>
                    </div>
                  )}
                  <DeploymentSettings deployment={deployment} clientName={clientName} visible={showDeploymentSettings} onSubmit={() => submitSettings()} onCancel={() => setShowDeploymentSettings(false)}></DeploymentSettings>
                  <Deployments
                    deployment={deployment}
                    id={deployment._id.toString()}
                    deploymentName={deployment.name}
                    allCmsServices={deployment.services
                      .filter((service) => service.is_deleted !== true)
                      .map((service) => {
                        return service as DocumentType<DeploymentService>;
                      })}
                  />
                  <VisibleForRole roles={[TVMSRoles.SuperAdmin, TVMSRoles.Admin]}>
                    <NewServiceBanner {...{ deployment }} />
                  </VisibleForRole>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
