import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Actors = (params: { setActors: any; data?: any }) => {
  const [actors_, setActors_] = useState([] as any);
  const [actors, setActors] = useState([] as any);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    if (params.data != undefined) {
      setIsLoading(true);
      var actors = [] as any;
      params.data.forEach((element) => {
        actors.push(element.name);
      });
      setActors_(actors);
    } else {
      setIsLoading(false);
    }
  }, [params.data]);

  useEffect(() => {
    if (actors_.length > 0) {
      setIsLoading(false);
    }
  }, [actors_]);

  const onChangeActors = (values) => {
    setActors_(values);
    var actors = [] as any;
    values.forEach((element) => {
      actors.push({ name: element });
    });
    params.setActors(actors);
  };

  return (
    <div>
      <h4 className="text-primary mt-5 mb-3">{t('actors-0')}</h4>
      <div className="">
        <div className="col-md-12 card-bg-light">
          <div className="col-md-12 mb-3 pt-3 mt-0 p-5">
            <div className="row">
              <label className="col-md-3 col-form-label">{t('actors-0')}</label>
              <div className="col-md-9">
                {!isLoading && (
                  <Select
                    style={{ width: '100%', margin: 5 }}
                    showSearch
                    loading={isLoading}
                    mode="tags"
                    allowClear
                    defaultValue={actors_}
                    onChange={onChangeActors}
                    filterOption={(input, option) => {
                      return ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase());
                    }}
                    options={isLoading ? [] : actors}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Actors;
