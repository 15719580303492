import { Form, Input, Modal, Switch } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ColorPicker } from '../../ColorPicker';
import IconPng1 from '../../../../assets/images/appbuilder/ContentList_1.png';
import IconPng2 from '../../../../assets/images/appbuilder/ContentList_2.png';
import IconPng3 from '../../../../assets/images/appbuilder/ContentList_Title.png';
import IconPng4 from '../../../../assets/images/appbuilder/ContentList_SubTitle.png';

export type LayoutProps = {
  visible: boolean;
  onSubmit: (component: [], name: string, index:any) => void;
  onCancel: () => void;
  data: [] | any;
  type: string;
  theme: [] | any;
  index: any;
};

const LayoutModel = (props: LayoutProps) => {
  const { visible, onSubmit, onCancel, data, type, theme } = props;
  const [form] = useForm();
const { t } = useTranslation();

  const [generalBackgroundColor, setGeneralBackgroundColor] = useState('#00000040');
  const [generalBackgroundColorPicker, setGeneralBackgroundColorPicker] = useState(false);

  const [generalBackground1Color, setGeneralBackground1Color] = useState('#00000040');
  const [generalBackground1ColorPicker, setGeneralBackground1ColorPicker] = useState(false);

  const [textColor, setTextColor] = useState('#ffffff');
  const [textColorPicker, setTextColorPicker] = useState(false);

  const [subTextColor, setSubTextColor] = useState('#999999');
  const [subTextColorPicker, setSubTextColorPicker] = useState(false);

  const [textSize, setTextSize] = useState('ExtraLarge');
  const [textSizeSub, setTextSizeSub] = useState("Normal");

  const [textEnabledSub, setTextEnabledSub] = useState(false);
  const [textEnabled, setTextEnabled] = useState(true);

  const [textBold, setTextBold] = useState(false);
  const [textBoldSub, setTextBoldSub] = useState(false);

  useEffect(() => {
    if (data.list != undefined) {
      setGeneralBackgroundColor(data.list.general.background_color_1);
      setGeneralBackgroundColor(data.list.general.background_color_2);
      //VonsetButtonBackgroundColor(data.list.button.background_color);
      // setImageBackgroundColor(data.list.image.background_color);
      // setIconColor(data.list.icons.icon_color);
      setTextColor(data.list.texts.title_color);
      setSubTextColor(data.list.texts.subtext_color);
      setTextSize(data.list.texts.title_size);
      setTextSizeSub(data.list.texts.subtext_size);
      setTextEnabledSub(data.list.texts.show_subtext);
      setTextEnabled(data.list.texts.show_title);
      setTextBold(data.list.texts.title_bold);
      setTextBoldSub(data.list.texts.subtext_bold);
      // setProgressColor(data.list.progress.progress_color);
      // setBaseColor(data.list.progress.base_color);
      //setShowProgress(data.list.progress.show_progress);
      form.setFieldsValue(data.list);
    }
  }, [data, form]);

  const onSubmitLocal = (values: any) => {
    var list = {
      general: {
        background_color_1: generalBackgroundColor,
        background_color_2: generalBackground1Color,
      },

      texts: {
        title_color: textColor,
        title_size: textSize, //Small, Normal, Large, Extra Large
        title_bold: textBold,

        subtext_color: subTextColor,
        subtext_size: textSizeSub, //Small, Normal, Large, Extra Large
        subtext_bold: textBoldSub,

        show_title: textEnabled,
        show_subtext: textEnabledSub,
      },
    };

    let dataOut = {
      ...data,
      list: list,
    };
    onSubmit(dataOut, type, props.index);
  };

  return (
    <Modal visible={visible} style={{  borderRadius:5}} width={'80%'} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="text-primary">{t('edit-content-list')}</h4>
            <p>{t('content-lists-are-used-to-display-channels-episodes-programs-as-an-overlay-on-the-players')}</p>
            <div className="row mt-5">
              <div className="col-12">
                <Form form={form} onFinish={onSubmitLocal} onFinishFailed={(errorInfo) => {}}>
                  <div className="col">
                    <div className="row">
                      <div className="col card-bg-light p-4 mb-4 mr-4" style={{ paddingLeft: 0 }}>
                        <h5 className="text-primary">{t('background-categories')}</h5>
                        <p>{t('background-color-of-the-category-on-screen-menu')}</p>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('background-color-3')}</label>
                          <div className="col" style={{ paddingLeft: 0 }}>
                            <div className="form-group pb-0 col">
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setGeneralBackgroundColorPicker(!generalBackgroundColorPicker);
                                }}
                                style={{
                                  backgroundColor: generalBackgroundColor,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={generalBackgroundColorPicker}
                                color={generalBackgroundColor}
                                onChange={(color) => {
                                  setGeneralBackgroundColor(color);
                                  setGeneralBackgroundColorPicker(!generalBackgroundColorPicker);
                                }}
                                setVisible={setGeneralBackgroundColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                COLOR
                              </div>
                            </div>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                      </div>
                      <div className="col card-bg-light p-4 mb-4 mr-1" style={{ paddingLeft: 0 }}>
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                          <img src={IconPng1} alt={''}></img>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col card-bg-light p-4 mb-4 mr-4" style={{ paddingLeft: 0 }}>
                        <h5 className="text-primary">{t('background-content')}</h5>
                        <p>{t('background-color-of-the-content-on-screen-menu')}</p>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('background-color-3')}</label>
                          <div className="col" style={{ paddingLeft: 0 }}>
                            <div className="form-group pb-0 col">
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setGeneralBackground1ColorPicker(!generalBackground1ColorPicker);
                                }}
                                style={{
                                  backgroundColor: generalBackground1Color,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={generalBackground1ColorPicker}
                                color={generalBackground1Color}
                                onChange={(color) => {
                                  setGeneralBackground1Color(color);
                                  setGeneralBackground1ColorPicker(!generalBackground1ColorPicker);
                                }}
                                setVisible={setGeneralBackground1ColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                COLOR
                              </div>
                            </div>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                      </div>
                      <div className="col card-bg-light p-4 mb-4 mr-1" style={{ paddingLeft: 0 }}>
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                          <img src={IconPng2} alt={''}></img>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col card-bg-light p-4 mb-4 mr-4" style={{ paddingLeft: 0 }}>
                        <h5 className="text-primary">{t('title-text')}</h5>
                        <p>{t('settings-that-define-title-object-of-your-element')}</p>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('text-color-0')}</label>
                          <div className="col" style={{ paddingLeft: 0 }}>
                            <div className="form-group pb-0 col">
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setTextColorPicker(!textColorPicker);
                                }}
                                style={{
                                  backgroundColor: textColor,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={textColorPicker}
                                color={textColor}
                                onChange={(color) => {
                                  setTextColor(color);
                                  setTextColorPicker(!textColorPicker);
                                }}
                                setVisible={setTextColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                COLOR
                              </div>
                            </div>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>

                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('text-bold')}</label>

                          <div className="col">
                            <Form.Item className="form-group pb-0 " name="title_bold">
                              <Switch className="ch-switch" checked={textBold} defaultChecked={false} onChange={() => setTextBold(!textBold)} />
                            </Form.Item>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('show-text-0')}</label>

                          <div className="col">
                            <Form.Item className="form-group pb-0" name="show_subtext">
                              <Switch className="ch-switch" checked={textEnabled} defaultChecked={false} onChange={() => setTextEnabled(!textEnabled)} />
                            </Form.Item>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                      </div>
                      <div className="col card-bg-light p-4 mb-4 mr-1" style={{ paddingLeft: 0 }}>
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                          <img src={IconPng3} alt={''}></img>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col card-bg-light p-4 mb-4 mr-4" style={{ paddingLeft: 0 }}>
                        <h5 className="text-primary">{t('sub-text')}</h5>
                        <p>{t('settings-that-define-title-object-of-your-element')}</p>

                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('text-color-0')}</label>
                          <div className="col" style={{ paddingLeft: 0 }}>
                            <div className="form-group pb-0 col">
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setSubTextColorPicker(!subTextColorPicker);
                                }}
                                style={{
                                  backgroundColor: subTextColor,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={subTextColorPicker}
                                color={subTextColor}
                                onChange={(color) => {
                                  setSubTextColor(color);
                                  setSubTextColorPicker(!subTextColorPicker);
                                }}
                                setVisible={setSubTextColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                COLOR
                              </div>
                            </div>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>

                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('text-bold')}</label>

                          <div className="col">
                            <Form.Item className="form-group pb-0 " name="subtext_bold">
                              <Switch className="ch-switch" checked={textBoldSub} defaultChecked={false} onChange={() => setTextBoldSub(!textBoldSub)} />
                            </Form.Item>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('show-text-0')}</label>

                          <div className="col">
                            <Form.Item className="form-group pb-0" name="show_subtext">
                              <Switch className="ch-switch" checked={textEnabledSub} defaultChecked={false} onChange={() => setTextEnabledSub(!textEnabledSub)} />
                            </Form.Item>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                      </div>
                      <div className="col card-bg-light p-4 mb-4 mr-1" style={{ paddingLeft: 0 }}>
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                          <img src={IconPng4} alt={''}></img>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 p-0 mt-3">
                    <button type="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-240 height-48">
                      {t('update-content-list')}
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LayoutModel;
