import React, { useContext, useState } from 'react'
import { notification,  Tabs } from "antd";
import { IMSNameParams } from '../../../dashboard'
import { useParams, useRouteMatch } from "react-router-dom"
import { useMutation, useQuery } from '@tanstack/react-query'
import * as ServiceService from '../../../../services/areas/billing/serviceService'
import { ContainerWrapper } from '../../../../common/components/containerWrapper'
import { PageTitle } from "../../../../common/components/pageTitle"
import { Service } from '../../../../interfaces/forms/areas/billing/services/service';
import { useForm } from 'antd/lib/form/Form'
import { Form, GeneralFields, ExternalFields } from './shared/form'
import { BillingContext } from '../../../../contexts/billingContext';
import { BackButton } from '../../../../common/components/BackButton';
import { Spin } from '../../../../common/components/spinner';
import { useTranslation } from 'react-i18next';


const ServiceDetails : React.FC = () => {
  const { id } = useParams<IMSNameParams & {id : string}>()
  const { clientName } = useParams<IMSNameParams>();
  const { deployment, service } = useContext(BillingContext)
  const { url } = useRouteMatch()
  const {t} = useTranslation();
  
  const { isLoading, isError, data } = useQuery(
    ["service", id],
    async () => {
      return (await (ServiceService.get({params: {
        cms: deployment?.name || '', 
        crm: service?.name || '',
        instance: clientName,
      }, id }))).data
    },
    { retry: 3 }
  )

  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-12">
          <BackButton text={t('back-to-service-list')} url={url.split('/').slice(0, -1).join('/')} />
        </div>
        <div className="col-md-6 mb-2">
          <PageTitle title={(data) ? t('update-data-name', { dataName: data.name }) : t('update-service')} subtitle={t('update-an-existing-service')} />
        </div>
      </div>
      {isError && <Error />}
      {isLoading && <Loading />}
      {!isLoading && data && <Details {...{ data }} />}
    </ContainerWrapper>
  )
}

const Details = ({ data } : {data : Service}) => {
  const { clientName } = useParams<IMSNameParams>()
  const { deployment, service } = useContext(BillingContext)
  const {t} = useTranslation();

  const { mutate, isLoading } = useMutation(
    (values : Partial<Service>) => {
      return ServiceService.patch({params : { instance: clientName, crm: service?.name || '', cms: deployment?.name || '' }, data: values, id: data._id })
    },
    {
      
      onSuccess: (data) => {
        notification.success({message: t('service-updated')})
      },
      onError: () => {
        notification.error({message: t('something-went-wrong-updating-the-service')})
      }
    }
  )

  const [ generalForm ] = useForm<Partial<Service>>()
  const [ externalForm ] = useForm<Partial<Service>>()

  const [ activeTab, setActiveTab ] = useState<string>('general')

  return (
    <div className="col-12  mt-3">
      <Tabs
        activeKey={activeTab}
        className="default-tab"
        onChange={(activeKey : string) => setActiveTab(activeKey)}
        items={[
          {label: t('general'), key: 'general', children: (
            <Form form={generalForm} disabled={isLoading} onFinish={(values : Partial<Service>) => mutate(values)} initialValues={data}>
              <GeneralFields />
            </Form>
          )},
          {label: t('external'), key: 'external', children: (
            <Form form={externalForm} disabled={isLoading} onFinish={(values : Partial<Service>) => mutate(values)} initialValues={data}>
              <ExternalFields  />
            </Form>
          )}
        ]}
      />
    </div>
  )
}

const Loading = () => {
  return (
    <div className="row text-center h-100 w-100">
      <div className="col-12 align-self-center">
        <Spin size="large" spinning={true} />
      </div>
    </div>
  )
}

const Error = () => {
  const {t} = useTranslation();
  return (
    <div>{t('something-went-wrong-0')}</div>
  )
}

export default ServiceDetails
