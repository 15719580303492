import moment from 'moment';
import { LineChart } from '../../../../../../../common/components/Charts/LineChart';
import { useTranslation } from 'react-i18next';

type CustomerLineGraphProps = {
  chart_data: { serviceName: string; response: { activeCustomers: number; dateOfCount: string }[] }[];
  totalActiveAccounts: number;
};

function CustomerLineGraph(props: CustomerLineGraphProps) {
  const { chart_data } = props;

  const allDates = new Set<string>();
  chart_data.forEach(({ response }) => {
    if (response) {
      response.forEach(({ dateOfCount }) => {
        if (moment(dateOfCount).format('MM') == moment().format('MM')) {
          allDates.add(dateOfCount);
        }
      });
    }
  });

  const {t} = useTranslation();
  
  const categories: string[] = Array.from(allDates);

  const seriesPerService = chart_data.map(({ serviceName, response }) => {
    const serviceData: (number | null)[] = [];

    let peak: number = 0;

    categories.forEach((categoryDate) => {
      if (response) {
        const dataEntry = response.find((entry) => entry.dateOfCount === categoryDate);

        if ((dataEntry?.activeCustomers ?? 0) > peak) {
          peak = dataEntry?.activeCustomers ?? 0;
        }
      }

      serviceData.push(peak);
    });

    return {
      name: serviceName,
      data: serviceData,
    };
  });

  const seriesTotal = {
    name: t('deployment-total'),
    data: new Array(seriesPerService[0].data.length).fill(0).map((value, index) => seriesPerService.map((x) => x.data[index] ?? 0).reduce((a, b) => a + b, 0)),
    color: '#485Fe0',
  };

  return <LineChart categories={categories} series={[...seriesPerService, seriesTotal]} />;
}

export default CustomerLineGraph;
