import { Form, Input, Modal, Select, Switch } from 'antd';
import { Spin } from '../../spinner';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ColorPicker } from '../../ColorPicker';

import TVGuide1 from '../../../../assets/images/appbuilder/TVGuide_1.png';
import TVGuide2 from '../../../../assets/images/appbuilder/TVGuide_2.png';
import TVGuide3 from '../../../../assets/images/appbuilder/TVGuide_3.png';
import TVGuide4 from '../../../../assets/images/appbuilder/TVGuide_4.png';
import TVGuide5 from '../../../../assets/images/appbuilder/TVGuide_5.png';
import TVGuide6 from '../../../../assets/images/appbuilder/TVGuide_6.png';

export type LayoutProps = {
  visible: boolean;
  onSubmit: (component: [], name: string, index:any) => void;
  onCancel: () => void;
  data: [] | any;
  type: string;
  theme: [] | any;
  index: any;
};

const LayoutModel = (props: LayoutProps) => {
  const { visible, onSubmit, onCancel, data, type, theme } = props;
  const [form] = useForm();
const { t } = useTranslation();

  const [generalBackgroundColor, setGeneralBackgroundColor] = useState('#000000');
  const [generalLoaderColor, setGeneralLoaderColor] = useState('#ffffff');
  const [generalBackgroundColorPicker, setGeneralBackgroundColorPicker] = useState(false);
  const [generalLoaderColorPicker, setGeneralLoaderColorPicker] = useState(false);

  const [timesBackgroundColor, setTimesBackgroundColor] = useState('#000000');
  const [timesTextColor, setTimesTextColor] = useState('#ffffff');
  const [timesBorderColor, setTimesBorderColor] = useState('#000000');
  const [timesBackgroundColorPicker, setTimesBackgroundColorPicker] = useState(false);
  const [timesTextColorPicker, setTimesTextColorPicker] = useState(false);
  const [timesBorderColorPicker, setTimesBorderColorPicker] = useState(false);

  const [programBackgroundColor, setProgramBackgroundColor] = useState('#000000');
  const [programLiveBackgroundColor, setProgramLiveBackgroundColor] = useState('#000000');
  const [programTitleColor, setProgramTitleColor] = useState('#ffffff');
  const [programTimeColor, setProgramTimeColor] = useState('#ffffff');
  const [programActiveColor, setProgramActiveColor] = useState('#ffffff');
  const [programsBorderColor, setProgramsBorderColor] = useState('#000000');
  const [programBackgroundColorPicker, setProgramBackgroundColorPicker] = useState(false);
  const [programLiveBackgroundColorPicker, setProgramLiveBackgroundColorPicker] = useState(false);
  const [programTitleColorPicker, setProgramTitleColorPicker] = useState(false);
  const [programTimeColorPicker, setProgramTimeColorPicker] = useState(false);
  const [programActiveColorPicker, setProgramActiveColorPicker] = useState(false);
  const [programsBorderColorPicker, setProgramsBorderColorPicker] = useState(false);

  const [daysBackgroundColor, setDaysBackgroundColor] = useState('#000000');
  const [daysTextColor, setDaysTextColor] = useState('#ffffff');
  const [daysBackgroundColorPicker, setDaysBackgroundColorPicker] = useState(false);
  const [daysTextColorPicker, setDaysTextColorPicker] = useState(false);

  const [channelsBackgroundColor, setChannelsBackgroundColor] = useState('#000000');
  const [channelsBackgroundColorPicker, setChannelsBackgroundColorPicker] = useState(false);

  const [markerBorderColor, setMarkerBorderColor] = useState('#000000');
  const [markerBorderColorPicker, setMarkerBorderColorPicker] = useState(false);

  const [timesTextsize, setTimesTextsize] = useState("Large");
  const [programsTextsize, setProgramsTextsize] = useState("Large");
  const [daysTextsize, setDaysTextsize] = useState("Large");
  const [channelsTextsize, setChannelsTextsize] = useState("Large");

  useEffect(() => {
    if (data.tvguide != undefined) {
      setGeneralBackgroundColor(data.tvguide.backgroundColor);
      //setGeneralLoaderColor(data.tvguide.general.loader_color);

      setTimesBackgroundColor(data.tvguide.timeline.backgroundColor);
      setTimesTextColor(data.tvguide.timeline.textColor);
      setTimesBorderColor(data.tvguide.timeline.borderColor);
      setTimesTextsize(data.tvguide.timeline.textSize);

      setProgramBackgroundColor(data.tvguide.cell.backgroundColor);
      setProgramTitleColor(data.tvguide.cell.textColor);
      setProgramActiveColor(data.tvguide.cell.activeColor);
      setProgramsBorderColor(data.tvguide.cell.borderColor);
      setProgramsTextsize(data.tvguide.cell.textSize);

      setProgramLiveBackgroundColor(data.tvguide.cellLive.backgroundColor);

      // setDaysBackgroundColor(data.tvguide.daySelector.backgroundColor);
      // setDaysTextColor(data.tvguide.daySelector.textColor);
      // setDaysTextsize(data.tvguide.days.textSize);

      setChannelsBackgroundColor(data.tvguide.channelLine.backgroundColor);
      setChannelsTextsize(data.tvguide.channelLine.textSize);

      setMarkerBorderColor(data.tvguide.marker.backgroundColor);

      form.setFieldsValue(data.tvguide);
    }
  }, [data, form]);

  const onSubmitLocal = (values: any) => {
    var guide = {
      backgroundColor: generalBackgroundColor,

      margin_top: values.margin_top ?? 0,
      margin_bottom: values.margin_bottom ?? 0,
      margin_left: values.margin_left ?? 0,
      margin_right: values.margin_right ?? 0,

      timeline: {
        alignItems: 'left',
        marginHorizontal: 5,
        fontSize: timesTextsize,
        backgroundColor: timesBackgroundColor,
        color: timesTextColor,
        borderColor: timesBorderColor,
        padding: 0,
        borderRadius: 0,
        borderLeftWidth: values.timeline.borderLeftWidth,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderRightWidth: 0,
      },
      cell: {
        backgroundColor: programBackgroundColor,
        borderColor: programsBorderColor,
        borderWidth: values.cell.borderWidth,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        titleColor: programTitleColor,
        timeColor: programTitleColor,
        fontSize: programsTextsize,
        borderRadius: values.cell.radius,
        activeColor: programActiveColor,
        padding: values.cell.padding,
      },
      cellLive: {
        backgroundColor: programLiveBackgroundColor,
        borderColor: programsBorderColor,
        borderWidth: values.cell.borderWidth,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        color: programTitleColor,
        fontSize: programsTextsize,
        borderRadius: values.cell.radius,
        activeColor: programActiveColor,
        padding: values.cell.padding,
      },
      daySelector: {
        backgroundColor: daysBackgroundColor,
        color: daysTextColor,
        fontSize: daysTextsize,
      },
      channelLine: {
        backgroundColor: channelsBackgroundColor,
        paddingLeft: 10,
        fontSize: channelsTextsize,
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderBottomWidth: values.cell.borderWidth,
        borderRightWidth: 0,
        textColor: programTitleColor,
      },
      marker: {
        backgroundColor: markerBorderColor,
        width: 2,
      },
    };
    
    let dataOut = {
      ...data,
      tvguide: guide,
    };
    onSubmit(dataOut, type, props.index);
  };

  return (
    <Modal visible={visible} style={{  borderRadius:5}} width={'80%'} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="text-primary">{t('edit-tv-guide')}</h4>

            <div className="row mt-5">
              <div className="col-12">
                <Form form={form} onFinish={onSubmitLocal} onFinishFailed={(errorInfo) => {}}>
                  <div className="col">
                    <div className="row">
                      <div className="col card-bg-light p-4 mb-4 mr-4" style={{ paddingLeft: 0 }}>
                        <h5 className="text-primary">{t('general-settings')}</h5>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('background-color-3')}</label>
                          <div className="col" style={{ paddingLeft: 0 }}>
                            <div className="form-group pb-0 col">
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setGeneralBackgroundColorPicker(!generalBackgroundColorPicker);
                                }}
                                style={{
                                  backgroundColor: generalBackgroundColor,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={generalBackgroundColorPicker}
                                color={generalBackgroundColor}
                                onChange={(color) => {
                                  setGeneralBackgroundColor(color);
                                  setGeneralBackgroundColorPicker(!generalBackgroundColorPicker);
                                }}
                                setVisible={setGeneralBackgroundColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                COLOR
                              </div>
                            </div>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                        <div className="row mt-4">
                          <label className="col-md-4 col-form-label"></label>
                          <div className="col"></div>
                          <div className="col" style={{ paddingLeft: 0 }}>
                            <Form.Item className="form-group pb-0 col" name="margin_top">
                              <Input defaultValue={0} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                            </Form.Item>
                            <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                              {t('top-margin')}
                            </div>
                          </div>

                          <div className="col"></div>
                        </div>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('guide-placement')}</label>

                          <div className="col" style={{ paddingLeft: 0 }}>
                            <Form.Item className="form-group pb-0 col" name="margin_left">
                              <Input defaultValue={0} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                            </Form.Item>
                            <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                              {t('left-margin')}
                            </div>
                          </div>

                          <div className="col"></div>
                          <div className="col" style={{ paddingLeft: 0 }}>
                            <Form.Item className="form-group pb-0 col" name="margin_right">
                              <Input defaultValue={0} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                            </Form.Item>
                            <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                              {t('right-margin')}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <label className="col-md-4 col-form-label"></label>
                          <div className="col"></div>
                          <div className="col" style={{ paddingLeft: 0 }}>
                            <Form.Item className="form-group pb-0 col" name="margin_bottom">
                              <Input defaultValue={0} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                            </Form.Item>
                            <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                              {t('bottom-margin')}
                            </div>
                          </div>

                          <div className="col"></div>
                        </div>
                      </div>
                      <div className="col card-bg-light p-4 mb-4 mr-1" style={{ paddingLeft: 0 }}>
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                          <img src={TVGuide1} alt={''}></img>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col card-bg-light p-4 mb-4 mr-4" style={{ paddingLeft: 0 }}>
                        <h5 className="text-primary">{t('timebar-settings')}</h5>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('background-color-3')}</label>
                          <div className="col" style={{ paddingLeft: 0 }}>
                            <div className="form-group pb-0 col">
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setTimesBackgroundColorPicker(!timesBackgroundColorPicker);
                                }}
                                style={{
                                  backgroundColor: timesBackgroundColor,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={timesBackgroundColorPicker}
                                color={timesBackgroundColor}
                                onChange={(color) => {
                                  setTimesBackgroundColor(color);
                                  setTimesBackgroundColorPicker(!timesBackgroundColorPicker);
                                }}
                                setVisible={setTimesBackgroundColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                COLOR
                              </div>
                            </div>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('text-color-0')}</label>
                          <div className="col" style={{ paddingLeft: 0 }}>
                            <div className="form-group pb-0 col">
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setTimesTextColorPicker(!timesTextColorPicker);
                                }}
                                style={{
                                  backgroundColor: timesTextColor,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={timesTextColorPicker}
                                color={timesTextColor}
                                onChange={(color) => {
                                  setTimesTextColor(color);
                                  setTimesTextColorPicker(!timesTextColorPicker);
                                }}
                                setVisible={setTimesTextColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                COLOR
                              </div>
                            </div>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>

                        {/* <div className='row mb-2'>
                          <label className="col-md-4 col-form-label">Text Type</label>
                          <div className='col' style={{ paddingLeft: 0 }}>
                            <Form.Item
                              className="form-group pb-0 col"
                              name={["title_size"]}>
                              <Select
                                onChange={(value) => setTimesTextsize(value)}
                                className="default-select"
                                placeholder="- Select Type -">
                                <Select.Option value={'Small'} key={'Small'}>Small</Select.Option>
                                <Select.Option value={'Normal'} key={'Normal'}>Normal</Select.Option>
                                <Select.Option value={'Large'} key={'Large'}>Large</Select.Option>
                                <Select.Option value={'ExtraLarge'} key={'ExtraLarge'}>X-Large</Select.Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </div> */}

                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('border-2')}</label>
                          <div className="col" style={{ paddingLeft: 0 }}>
                            <Form.Item className="form-group pb-0 col" name={['timeline', 'borderLeftWidth']}>
                              <Input defaultValue={0} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                WIDTH
                              </div>
                            </Form.Item>
                          </div>
                          <div className="col">
                            <div className="form-group pb-0 col">
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setTimesBorderColorPicker(!timesBorderColorPicker);
                                }}
                                style={{
                                  backgroundColor: timesBorderColor,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={timesBorderColorPicker}
                                color={timesBorderColor}
                                onChange={(color) => {
                                  setTimesBorderColor(color);
                                  setTimesBorderColorPicker(!timesBorderColorPicker);
                                }}
                                setVisible={setTimesBorderColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                COLOR
                              </div>
                            </div>
                          </div>
                          <div className="col"></div>
                        </div>
                      </div>
                      <div className="col card-bg-light p-4 mb-4 mr-1" style={{ paddingLeft: 0 }}>
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                          <img src={TVGuide2} alt={''}></img>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col card-bg-light p-4 mb-4 mr-4" style={{ paddingLeft: 0 }}>
                        <h5 className="text-primary">{t('program-settings')}</h5>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('background-color-3')}</label>
                          <div className="col" style={{ paddingLeft: 0 }}>
                            <div className="form-group pb-0 col">
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setProgramBackgroundColorPicker(!programBackgroundColorPicker);
                                }}
                                style={{
                                  backgroundColor: programBackgroundColor,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={programBackgroundColorPicker}
                                color={programBackgroundColor}
                                onChange={(color) => {
                                  setProgramBackgroundColor(color);
                                  setProgramBackgroundColorPicker(!programBackgroundColorPicker);
                                }}
                                setVisible={setProgramBackgroundColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                COLOR
                              </div>
                            </div>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('focus-color')}</label>
                          <div className="col" style={{ paddingLeft: 0 }}>
                            <div className="form-group pb-0 col">
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setProgramActiveColorPicker(!programActiveColorPicker);
                                }}
                                style={{
                                  backgroundColor: programActiveColor,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={programActiveColorPicker}
                                color={programActiveColor}
                                onChange={(color) => {
                                  setProgramActiveColor(color);
                                  setProgramActiveColorPicker(!programActiveColorPicker);
                                }}
                                setVisible={setProgramActiveColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                COLOR
                              </div>
                            </div>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('program-text-color')}</label>
                          <div className="col" style={{ paddingLeft: 0 }}>
                            <div className="form-group pb-0 col">
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setProgramTitleColorPicker(!programTitleColorPicker);
                                }}
                                style={{
                                  backgroundColor: programTitleColor,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={programTitleColorPicker}
                                color={programTitleColor}
                                onChange={(color) => {
                                  setProgramTitleColor(color);
                                  setProgramTitleColorPicker(!programTitleColorPicker);
                                }}
                                setVisible={setProgramTitleColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                COLOR
                              </div>
                            </div>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('time-text-color')}</label>
                          <div className="col" style={{ paddingLeft: 0 }}>
                            <div className="form-group pb-0 col">
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setProgramTimeColorPicker(!programTimeColorPicker);
                                }}
                                style={{
                                  backgroundColor: programTimeColor,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={programTimeColorPicker}
                                color={programTimeColor}
                                onChange={(color) => {
                                  setProgramTimeColor(color);
                                  setProgramTimeColorPicker(!programTimeColorPicker);
                                }}
                                setVisible={setProgramTimeColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                COLOR
                              </div>
                            </div>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>

                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('border-2')}</label>
                          <div className="col" style={{ paddingLeft: 0 }}>
                            <Form.Item className="form-group pb-0 col" name={['cell', 'borderWidth']}>
                              <Input defaultValue={0} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                WIDTH
                              </div>
                            </Form.Item>
                          </div>
                          <div className="col">
                            <div className="form-group pb-0 col">
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setProgramsBorderColorPicker(!programsBorderColorPicker);
                                }}
                                style={{
                                  backgroundColor: programsBorderColor,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={programsBorderColorPicker}
                                color={programsBorderColor}
                                onChange={(color) => {
                                  setProgramsBorderColor(color);
                                  setProgramsBorderColorPicker(!programsBorderColorPicker);
                                }}
                                setVisible={setProgramsBorderColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                COLOR
                              </div>
                            </div>
                          </div>
                          <div className="col"></div>
                        </div>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('padding')}</label>
                          <div className="col" style={{ paddingLeft: 0 }}>
                            <Form.Item className="form-group pb-0 col" name={['cell', 'padding']}>
                              <Input defaultValue={0} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                PADDING
                              </div>
                            </Form.Item>
                          </div>
                          <div className="col">
                            <Form.Item className="form-group pb-0 col" name={['cell', 'radius']}>
                              <Input defaultValue={0} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                RADIUS
                              </div>
                            </Form.Item>
                          </div>
                          <div className="col"></div>
                        </div>
                      </div>
                      <div className="col card-bg-light p-4 mb-4 mr-1" style={{ paddingLeft: 0 }}>
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                          <img src={TVGuide3} alt={''}></img>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col card-bg-light p-4 mb-4 mr-4" style={{ paddingLeft: 0 }}>
                        <h5 className="text-primary">{t('live-program-settings')}</h5>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('background-color-3')}</label>
                          <div className="col" style={{ paddingLeft: 0 }}>
                            <div className="form-group pb-0 col">
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setProgramLiveBackgroundColorPicker(!programLiveBackgroundColorPicker);
                                }}
                                style={{
                                  backgroundColor: programLiveBackgroundColor,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={programLiveBackgroundColorPicker}
                                color={programLiveBackgroundColor}
                                onChange={(color) => {
                                  setProgramLiveBackgroundColor(color);
                                  setProgramLiveBackgroundColorPicker(!programLiveBackgroundColorPicker);
                                }}
                                setVisible={setProgramLiveBackgroundColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                COLOR
                              </div>
                            </div>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>

                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('program-text-color-0')}</label>
                          <div className="col" style={{ paddingLeft: 0 }}>
                            <div className="form-group pb-0 col">
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setProgramTitleColorPicker(!programTitleColorPicker);
                                }}
                                style={{
                                  backgroundColor: programTitleColor,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={programTitleColorPicker}
                                color={programTitleColor}
                                onChange={(color) => {
                                  setProgramTitleColor(color);
                                  setProgramTitleColorPicker(!programTitleColorPicker);
                                }}
                                setVisible={setProgramTitleColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                COLOR
                              </div>
                            </div>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>

                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('border-2')}</label>
                          <div className="col" style={{ paddingLeft: 0 }}>
                            <Form.Item className="form-group pb-0 col" name={['cell', 'borderWidth']}>
                              <Input defaultValue={0} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                WIDTH
                              </div>
                            </Form.Item>
                          </div>
                          <div className="col">
                            <div className="form-group pb-0 col">
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setProgramsBorderColorPicker(!programsBorderColorPicker);
                                }}
                                style={{
                                  backgroundColor: programsBorderColor,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={programsBorderColorPicker}
                                color={programsBorderColor}
                                onChange={(color) => {
                                  setProgramsBorderColor(color);
                                  setProgramsBorderColorPicker(!programsBorderColorPicker);
                                }}
                                setVisible={setProgramsBorderColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                COLOR
                              </div>
                            </div>
                          </div>
                          <div className="col"></div>
                        </div>
                      </div>
                      <div className="col card-bg-light p-4 mb-4 mr-1" style={{ paddingLeft: 0 }}>
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                          <img src={TVGuide3} alt={''}></img>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col card-bg-light p-4 mb-4 mr-4" style={{ paddingLeft: 0 }}>
                        <h5 className="text-primary">{t('channel-settings')}</h5>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('background-color-3')}</label>
                          <div className="col" style={{ paddingLeft: 0 }}>
                            <div className="form-group pb-0 col">
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setChannelsBackgroundColorPicker(!channelsBackgroundColorPicker);
                                }}
                                style={{
                                  backgroundColor: channelsBackgroundColor,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={channelsBackgroundColorPicker}
                                color={channelsBackgroundColor}
                                onChange={(color) => {
                                  setChannelsBackgroundColor(color);
                                  setChannelsBackgroundColorPicker(!channelsBackgroundColorPicker);
                                }}
                                setVisible={setChannelsBackgroundColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                COLOR
                              </div>
                            </div>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>

                        {/* <div className='row mb-2'>
                          <label className="col-md-4 col-form-label">Text Type</label>
                          <div className='col' style={{ paddingLeft: 0 }}>
                            <Form.Item
                              className="form-group pb-0 col"
                              name={["title_size"]}>
                              <Select
                                onChange={(value) => setChannelsTextsize(value)}
                                className="default-select"
                                placeholder="- Select Type -">
                                <Select.Option value={'Small'} key={'Small'}>Small</Select.Option>
                                <Select.Option value={'Normal'} key={'Normal'}>Normal</Select.Option>
                                <Select.Option value={'Large'} key={'Large'}>Large</Select.Option>
                                <Select.Option value={'ExtraLarge'} key={'ExtraLarge'}>X-Large</Select.Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </div> */}
                      </div>
                      <div className="col card-bg-light p-4 mb-4 mr-1" style={{ paddingLeft: 0 }}>
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                          <img src={TVGuide4} alt={''}></img>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col card-bg-light p-4 mb-4 mr-4" style={{ paddingLeft: 0 }}>
                        <h5 className="text-primary">{t('marker-settings')}</h5>

                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('marker-color')}</label>
                          <div className="col">
                            <div className="form-group pb-0 col">
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setMarkerBorderColorPicker(!markerBorderColorPicker);
                                }}
                                style={{
                                  backgroundColor: markerBorderColor,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={markerBorderColorPicker}
                                color={markerBorderColor}
                                onChange={(color) => {
                                  setMarkerBorderColor(color);
                                  setMarkerBorderColorPicker(!markerBorderColorPicker);
                                }}
                                setVisible={setMarkerBorderColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                COLOR
                              </div>
                            </div>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                      </div>
                      <div className="col card-bg-light p-4 mb-4 mr-1" style={{ paddingLeft: 0 }}>
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                          <img src={TVGuide5} alt={''}></img>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 p-0 mt-3">
                    <button type="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-240 height-48">
                      {t('update-tv-guide')}
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LayoutModel;
