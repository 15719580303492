import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Table, Tooltip } from 'antd';
import { useContext, useEffect, useState } from 'react';
import CurrencyModal from './CurrencyModal';
import { ContentContext } from '../../../../contexts/contentContext';
import { MW_BASE_DB_CURRENCY } from '@mwaretv/database/build/backend/models/base/currency';
import { useTranslation } from 'react-i18next';

const Currencies = (params: { service_name: any; clientName: any; setCurrencies: any; data?: any }) => {
  const [currencyToUpdate, setCurrencyToUpdate] = useState<MW_BASE_DB_CURRENCY>();
  const [showCurrencyUpdateModal, setShowCurrencyUpdateModal] = useState<boolean>(false);
  const { deployment } = useContext(ContentContext);
  const currencies = (deployment?.currencies as unknown as MW_BASE_DB_CURRENCY[]) || [];
  const [currenciesLocal, setCurrenciesLocal] = useState([] as any);
  const {t} = useTranslation();
  
  const CurrenciesColumns = [
    {
      title: t('currency'),
      dataIndex: 'iso3',
      key: 'iso3',
    },
    {
      title: t('credits-rented'),
      dataIndex: 'credits_rented',
      key: 'credits_rented',
    },
    {
      title: t('credits-purchased'),
      dataIndex: 'credits_purchased',
      key: 'credits_purchased',
    },
    {
      title: t('actions'),
      dataIndex: 'action',
      width: 100,
      render: (tags: any, row: MW_BASE_DB_CURRENCY) => (
        <span className="justify-content-end">
          <Tooltip placement="top" title={`Edit`}>
            <Button
              className="mx-2 cursor-pointer"
              onClick={(event) => {
                setCurrencyToUpdate(row);
                setShowCurrencyUpdateModal(!showCurrencyUpdateModal);
              }}
              type="text"
            >
              <FontAwesomeIcon className="action-icon" icon={faPenToSquare} />
            </Button>
          </Tooltip>
        </span>
      ),
    },
  ];
  useEffect(() => {
    if (params.data.length == 0) {
      var curs = [] as any;
      currencies.forEach((element, index) => {
        curs.push({
          iso3: element.isoCurrency,
          credits_rented: 0,
          credits_purchased: 0,
          key: index,
        });
      });
      setCurrenciesLocal(curs);
    } else {
      setCurrenciesLocal(params.data);
    }
  }, [params]);

  return (
    <div>
      {showCurrencyUpdateModal && currencyToUpdate && (
        <CurrencyModal
          initialData={currencyToUpdate}
          onCancel={() => {
            setShowCurrencyUpdateModal(!showCurrencyUpdateModal);
          }}
          onSubmit={(values: any) => {
            let newObj = currenciesLocal.map((currency: any) => {
              if (currency.iso3 === values.iso3) {
                currency.credits_rented = values.credits_rented;
                currency.credits_purchased = values.credits_purchased;
              }
              return currency;
            });
            params.setCurrencies(newObj);
            setShowCurrencyUpdateModal(!showCurrencyUpdateModal);
          }}
          visible={showCurrencyUpdateModal}
        />
      )}
      <Table
        rowKey={(currenciesLocal) => currenciesLocal.isoCurrency}
        columns={CurrenciesColumns}
        dataSource={currenciesLocal}
        key={JSON.stringify(currenciesLocal)}
        pagination={{
          position: ['bottomLeft'],
          pageSize: 10,
          hideOnSinglePage: true,
          showTotal: (total, range) => {
            return `${range[0]}-${range[1]} of ${total} items`;
          },
        }}
      />
    </div>
  );
};
export default Currencies;
