import { useTranslation } from 'react-i18next';
import { useParams, useRouteMatch } from 'react-router-dom';
import { ChartInput } from '@mwaretv/database/build/backend/util/queryGenerator'
import { AreaChart, BarChart, ColumnChart, PieChart, PlainValueChart } from '../../../../common/components/Charts';
import { useContext, useEffect, useState } from 'react';
import React from 'react';
import { ReportingContext } from '../../../../contexts/reportingContext';
import { AvailableChartTypesReporting } from '@mwaretv/database/build/backend/models/reporting/chartdesigns/chartDesignEnums';
import chartDesignService from '../../../../services/areas/reporting/chartdesigns/chartDesignService';
import { StatusCodes } from 'http-status-codes';
import { IconDefinition, faMemo, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { Button, Modal, Tooltip, notification } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { GaugeChart } from '../../../../common/components/Charts/GaugeChart';
import dashboardService from '../../../../services/areas/reporting/dashboards/dashboardService';
import chartInputService from '../../../../services/areas/reporting/chartinputs/chartInputService';
import { AuthContext } from '../../../../context_api/AuthContext';
import { TVMSRoles } from '../../../../constants/TVMSRoles';
import { isInRoles } from '../../../../common/components/VisibleForRole';

const Dashboard = ({ showActionButtons }: { showActionButtons: boolean }) => {
  const { confirm } = Modal;
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { clientName } = useParams<{ clientName: string }>();
  const { deployment, service } = React.useContext(ReportingContext);
  const [chartInputs, setChartInputs] = useState<ChartInput[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const { state: AuthState } = useContext(AuthContext);
  const hasEditPermissions = isInRoles(AuthState.user?.role, [TVMSRoles.SuperAdmin, TVMSRoles.Admin, TVMSRoles.Employee]);

  const fetchData = async () => {
    try {
      const chartInputs = await chartInputService.Get_ChartInputs(clientName, deployment?.name ?? "", service?.name ?? "", "reporting");

      setChartInputs(chartInputs);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRefresh = async (): Promise<void> => {

    setIsLoading(true);

    const response = await dashboardService.Generate({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        crm: service?.name,
        area: "reporting",
      },
    });

    if (response.status_code === StatusCodes.OK) {
      await fetchData();
    }
    else {
      console.log(JSON.stringify(response));
    }

    setIsLoading(false);

    try {

    }
    catch (err) {
      console.log(err);
    }

  }

  const handleDelete = async (id: string): Promise<void> => {

    setIsLoading(true);

    const values = {
      _id: id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        crm: service?.name,
      },
    };
    let chartDesignResponse = await chartDesignService.Delete_ChartDesigns(values as any);
    if (chartDesignResponse.status_code === StatusCodes.OK) {

      const dashboardResponse = await dashboardService.Generate({
        query_params: {
          instance: clientName,
          cms: deployment?._id,
          crm: service?.name,
          area: "reporting",
        },
      });
  
      if (dashboardResponse.status_code === StatusCodes.OK) {
        await fetchData();
      }
      else {
        console.log(JSON.stringify(dashboardResponse));
      }

      notification.success({
        message: t('chart-design-deleted-successfully'),
      });
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }

    setIsLoading(false);

  };

  const getOptions = (chartInput: ChartInput) => {
    var data = [] as any;

    chartInput?.data?.forEach((item) => {
      data.push(item.key);
    });

    return {
      chart: {
        animations: {
          enabled: true,
        },
        background: '',
      },
      dataLabels: {
        enabled: false,
      },
      colors: ['#5868bd'],
      xaxis: {
        categories: data,
      },
    };
  };

  const getSeries = (chartInput: ChartInput) => {
    var data = [] as any;

    chartInput?.data?.forEach((item) => {
      data.push(item.value);
    });

    return [
      {
        name: chartInput.dataLabel,
        data: data,
      },
    ];
  };

  const getPieChartOptions = (chartInput: ChartInput) => {
    var data = [] as any;

    chartInput?.data?.forEach((item) => {
      data.push(item.key);
    });

    return {
      options: {
        chart: {
          type: 'pie',
        },
        responsive: [
          {
            options: {},
          },
        ],
      },
      labels: data,
      tooltip: {
        enabled: true,
        y: {
          title: {
            formatter: (value) => {
              if (chartInput.dataLabel != null) {
                return chartInput.dataLabel + ": " + value + ": ";
              }
              return value + ": ";
            }
          }
        }
      }
    };
  };

  const getPieChartSeries = (chartInput: ChartInput) => {

    var data = [] as any;

    chartInput?.data?.forEach((item) => {
      data.push(item.value);
    });

    return data;

  }

  const getIconDefinitionFromChartInput = (chartInput: ChartInput): IconDefinition | undefined => {

    switch (chartInput.iconName) {
      default: {
        return undefined;
      }
    }

  }

  return (
    <div className="container-fluid" style={{ padding: 30 }}>
      <div className="row">
        <div className="col-md-6 mb-2">
          <h2 className="page-title">{t('dashboard')}</h2>
        </div>
        <div className="col-md-4" />
        <div className="col-md-2 d-flex justify-content-center justify-content-md-end align-items-start">
          <Button disabled={isLoading} className="ant-btn btn btn-secondary rounded-pill width-240 height-48" onClick={() => handleRefresh()}>
            {t('chart-design-refresh-dashboard')}
          </Button>
        </div>
      </div>
      <div className="row mt-4">
      {chartInputs != null && chartInputs.map(chartInput => {

        const columnClassName = `col-md-${chartInput.width12column ?? 4}`;

        return (
          <>
            <div className={columnClassName} style={{ marginBottom: "30px" }}>
              <div style={chartInput.color ? { color: chartInput.color, backgroundImage: chartInput.backgroundImage, border: '1px solid #e9e9e9', borderRadius: 5, padding: 10 } : { backgroundImage: 'linear-gradient(#f4f4f4, #ececec)', border: '1px solid #e9e9e9', borderRadius: 5, padding: 10 }}>
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <div>
                    <p className="h5 font-weight-normal line-height-26" style={{ margin: 10 }}>
                      {chartInput.title}
                    </p>
                  </div>
                  {showActionButtons && hasEditPermissions &&
                  <>
                    <div style={{ marginLeft: "auto" }}>
                      <Tooltip placement="top" title={t('delete')}>
                        <Button type="text" className="cursor-pointer" style={{ height: '100%', color: chartInput.color ?? '#505972' }} onClick={() => {
                          confirm({
                            title: t('chart-design-delete-confirm'),
                            okText: t('yes'),
                            okType: 'danger',
                            cancelText: t('no'),
                            onOk() {
                              handleDelete(chartInput.id);
                            },
                            onCancel() {},
                          });
                        }}>
                          <FontAwesomeIcon icon={faTrashCan} />
                        </Button>
                      </Tooltip>
                    </div>
                    <div>
                      <a href={`${url}/details/${chartInput.id}`}>
                        <Tooltip placement="top" title={t('open-details')}>
                          <Button type="text" className="cursor-pointer" style={{ height: '100%', color: chartInput.color ?? '#505972' }}>
                            <FontAwesomeIcon icon={faMemo} />
                          </Button>
                        </Tooltip>
                      </a>
                    </div>
                  </>
                  }
                </div>
                {chartInput.chartType === AvailableChartTypesReporting.BAR &&
                  <BarChart options={getOptions(chartInput)} series={getSeries(chartInput)} />
                }
                {chartInput.chartType === AvailableChartTypesReporting.COLUMN &&
                  <ColumnChart options={getOptions(chartInput)} series={getSeries(chartInput)} />
                }
                {chartInput.chartType === AvailableChartTypesReporting.LINE &&
                  <AreaChart options={getOptions(chartInput)} series={getSeries(chartInput)} />
                }
                {chartInput.chartType === AvailableChartTypesReporting.PIE &&
                  <PieChart options={getPieChartOptions(chartInput)} series={getPieChartSeries(chartInput)} />
                }
                {chartInput.chartType === AvailableChartTypesReporting.PLAIN_VALUE &&
                  <PlainValueChart data={chartInput.data != null && chartInput.data.length > 0 ? chartInput.data : []} icon={getIconDefinitionFromChartInput(chartInput)} />
                }
                {chartInput.chartType === AvailableChartTypesReporting.GAUGE &&
                  <GaugeChart value={chartInput.data != null && chartInput.data.length > 0 ? chartInput.data[0].value : null} percentageWarning={chartInput.percentageWarning} />
                }
                <span style={{ marginLeft: 10 }}>{t('chart-design-data-as-of') + ": " + dayjs.unix(chartInput.timestamp).format("MMMM D, YYYY HH:mm")}</span>
              </div>
            </div>
          </>
        )
      })}
      </div>
    </div>
  );
};

export default Dashboard;
