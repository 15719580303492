import { Badge, Input } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../context_api/AuthContext';
import { Client } from '../../../../interfaces/forms/clients/clients';
import ClientCard from './components';
import moment from 'moment-timezone';
import { useEffectOnce } from 'react-use';
import axios from 'axios';

const AllClients = () => {
  const { state: AuthState } = useContext(AuthContext);

  const getStatus = (status: String) => {
    switch (status) {
      case 'Live':
        return 'green';
        break;
      case 'Suspended':
        return 'orange';
        break;
      case 'Stopped':
        return 'red';
        break;
      case 'Archived':
        return 'gray';
        break;
      case 'Onboarding':
        return 'blue';
        break;
      case 'Trial':
        return 'pink';
        break;
      default:
        return 'white';
        break;
    }
  };
  const [totalClients, setTotalClients] = useState(0);
  const [totalTraffic, setTotalTraffic] = useState(0);
  const [totalStorage, setTotalStorage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [clients, setClients] = useState([] as any);
  const [baseClients, setBaseClients] = useState([] as any);

  useEffectOnce(() => {
    const fetchData = async () => {
      var clients = 0;
      var traffic = 0;
      var storage = 0;
      var index = 0;
      await AuthState.user?.clients?.forEach(async (client) => {
        try {
          index++;
          const path = 'https://cloudtv.akamaized.net/' + client.name + '/counters/totals.json';
          await axios.get(path).then((data) => {
            var today = data.data.find((t) => t.month == Number(moment().format('MM')) && t.day == Number(moment().format('DD')));
            if (today != undefined) {
              storage += today.totals[0].storage;
              traffic += today.totals[0].traffic;
              clients += today.totals[0].total;
              if (AuthState.user?.clients?.length == index) {
                setTotalClients(clients);
                setTotalStorage(storage);
                setTotalTraffic(traffic);
                setIsLoading(false);
              }
            }
          });
        } catch (err) {
          index++;
        }
      });
    };
    if (totalClients == 0) {
      fetchData();
    }
  });

  useEffect(() => {
    setClients(AuthState.user?.clients?.sort((a, b) => a.name.localeCompare(b.name)));
    setBaseClients(AuthState.user?.clients?.sort((a, b) => a.name.localeCompare(b.name)));
  }, [AuthState]);

  const handleSearchInput = (value: string) => {
    let newClients = baseClients.filter((client) => {
      if (client.name.toLowerCase().startsWith(value.toLowerCase())) return clients;
    });
    setClients(newClients);
  };

  return (
    <section className="content">
      <div className=" m-auto p-0 pt-3">
        <div className="container-fluid">
          {!isLoading && (
            <div className="card-bg-light p-2 mb-3" style={{ backgroundColor: '#ffffff' }}>
              <div className="card-bg-light  rounded m-3" style={{ display: 'flex', padding: 30 }}>
                <div className="row" style={{ width: '100%' }}>
                  <div className="col mb-3" style={{ display: 'flex', color: '#485fe0', fontSize: 20, textAlign: 'start', fontWeight: 'bold' }}>
                    MwareTV {moment().format('MMMM YYYY')}:
                  </div>
                  <div style={{ display: 'flex', width: '100%' }}>
                    <div className="col" style={{ display: 'flex', width: '33,5%', fontSize: 16, color: '#999', textAlign: 'start', fontWeight: 'bold' }}>
                      Total Clients: {Math.round(totalClients)} Peak Active
                    </div>
                    <div className="col" style={{ display: 'flex', width: '33,5%', fontSize: 16, color: '#999', textAlign: 'start', fontWeight: 'bold' }}>
                      Total Traffic: {Math.round(totalTraffic)} GB - {Math.round(totalTraffic / 1000 / 1000)} PB
                    </div>
                    <div className="col" style={{ display: 'flex', width: '33,5%', fontSize: 16, color: '#999', textAlign: 'start', fontWeight: 'bold' }}>
                      Total Storage: {Math.round(totalStorage)} GB
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="card-bg-light p-2" style={{ backgroundColor: '#ffffff' }}>
            <div className="card-bg-light rounded m-3 mt-4" style={{ display: 'flex', padding: 30, border: '#e' }}>
              <div className="col" style={{ width: '100%' }}>
                <div className="row mb-3" style={{ display: 'flex', color: '#485fe0', fontSize: 20, textAlign: 'start', fontWeight: 'bold' }}>
                  Filter Clients
                </div>
                <div className="row">
                  <Input
                    className="form-control bg-white font-size-14 text-default"
                    placeholder="Filter clients..."
                    onChange={(e) => {
                      handleSearchInput(e.target.value);
                    }}
                  ></Input>
                </div>
              </div>
            </div>
            <div className="row p-3">
              {clients
                .filter((c) => c.status != 'Deleted')
                .map((client: Client, index: number) => (
                  <div className="col-4 p-3 btn" key={`${index}`}>
                    {client.onboarding != 'Finished' && client.onboarding != undefined && client.status != 'Live' && (
                      <Badge.Ribbon placement="start" text={client.status} color={getStatus(client.status)} style={{ marginTop: 5 }}>
                        <Badge.Ribbon placement="end" text={'Step: ' + client.onboarding} color={'gray'} style={{ marginTop: 5 }}>
                          <div className="rounded shadow" style={{ padding: 15, background: '#fff' }}>
                            <ClientCard {...client} />
                          </div>
                        </Badge.Ribbon>
                      </Badge.Ribbon>
                    )}
                    {(client.onboarding == 'Finished' || client.onboarding == undefined || client.status == 'Live') && (
                      <Badge.Ribbon placement="start" text={client.status} color={getStatus(client.status)} style={{ marginTop: 5 }}>
                        <div className="rounded shadow" style={{ padding: 15, background: '#fff' }}>
                          <ClientCard {...client} />
                        </div>
                      </Badge.Ribbon>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AllClients;
