import { faEye } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Image, Tooltip } from 'antd';
import { Spin } from '../../spinner';
import { StatusCodes } from 'http-status-codes';
import moment from 'moment';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { IMSNameParams } from '../../../../pages/dashboard';
import cmsService from '../../../../services/areas/content/epg/epg_data';
import ProgramModal from './ProgramModal';
import { ContentContext } from '../../../../contexts/contentContext';
import { useTranslation } from 'react-i18next';

export type TimelineProps = {
  type: string;
};

const Timeline = (props: TimelineProps) => {
  const { clientName, _id } = useParams<IMSNameParams>();
  const { deployment } = useContext(ContentContext);
  const [isLoading, setIsLoading] = useState(true);
  const [timeSlots, setTimeSlots] = useState([] as any);
  const [days, setDays] = useState([] as any);
  const [epgData, setEpgData] = useState([] as any);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([] as any);
  const [selectedDayIndex, setSelectedDayIndex] = useState(0);
  const {t} = useTranslation();
  
  const getEpgData = async (index: any) => {
    setEpgData([]);
    setIsLoading(true);
    let response = await cmsService.Details_Epg_Data({
      day: moment().add('day', index).format('DD'),
      month: moment().add('day', index).month() + 1 + '',
      year: moment().add('day', index).year() + '',
      type: props.type,
      _id: _id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        day: moment().add('day', index).format('DD'),
        month: moment().add('day', index).month() + 1 + '',
        year: moment().add('day', index).year() + '',
        _id: _id,
        type: props.type,
      },
    });
    if (response.status_code === StatusCodes.OK) {
      var data = response.data as any;
      if (data.length > 0) {
        setEpgData(data[0].p);
      } else {
        setEpgData([]);
      }
    }
    setIsLoading(false);
  };

  const getDayTimeSlots = () => {
    var timeSlots_: any[] = [];
    for (let index = 0; index < 24; index++) {
      var prog = {
        i: index,
        start: moment().startOf('day').add('hour', index).format('HH:00'),
        mid: moment().startOf('day').add('hour', index).format('HH:30'),
      };
      timeSlots_.push(prog);
    }
    setTimeSlots(timeSlots_);
  };

  const getDays = () => {
    var days_: any[] = [];
    for (let index = 1; index < 8; index++) {
      var day = {
        i: -index,
        day: moment().startOf('day').add('day', -index).format("ddd DD"),
      };
      days_.push(day);
    }
    days_.reverse();
    for (let index = 0; index < 4; index++) {
      var day = {
        i: index,
        day: moment().startOf('day').add('day', index).format("ddd DD"),
      };
      days_.push(day);
    }
    setDays(days_);
  };

  useEffectOnce(() => {
    getDayTimeSlots();
    getDays();
    (async () => await getEpgData(0))();
  });

  const getHeight = (start: any, end: any) => {
    var dayHeight = 200 * 24;
    var daySeconds = 86400;
    var heightPerSeconds = dayHeight / daySeconds;
    return (end - start) * heightPerSeconds;
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const openModal = (epg: any) => {
    if (props.type != "Imported") {
      return;
    }
    setData(epg);
    setShowModal(true);
  };
  const changeDay = (index: any) => {
    
    setSelectedDayIndex(days[index].i);
    getEpgData(days[index].i);
  };

  const importPublishEpgData = async () => {};
  return (
    <div>
      <h4 className="text-primary mt-5 mb-3">{t('guide-2')}{moment().startOf('day').add('day', selectedDayIndex).format('LL')}{t('key')}</h4>
      <div className="">
        <div className="col-md-12 card-bg-light p-4">
          <ProgramModal
            data={data}
            visible={showModal}
            onSubmit={(values) => closeModal}
            onCancel={() => {
              setShowModal(false);
            }}
          />
          <div className="row mb-5 " style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#fff', border: '1px solid #d6d6d6', borderRadius: 5 }}>
            {days.map((day: any, index: any) => {
              return (
                <div
                  key={index}
                  onClick={() => changeDay(index)}
                  className="cursor-pointer"
                  style={{
                    padding: 10,
                    color: day.i == selectedDayIndex ? '#1ac884' : '#222',
                    border: day.i == selectedDayIndex ? '2px solid #1ac884' : '1px solid #d6d6d6',
                    borderRadius: 5,
                    margin: 10,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {day.day}
                </div>
              );
            })}
          </div>
          <div className="row ml-2 pt-2">
            <div className="col-md-2">
              {timeSlots.map((time: any, index: any) => {
                return (
                  <div key={index}>
                    <div
                      style={{
                        padding: 10,
                        borderRight: '1px solid #ebebeb',
                        paddingLeft: 25,
                        height: 100,
                        borderTop: '1px solid #ebebeb',
                        display: 'flex',
                        alignItems: 'flex-start',
                      }}
                    >
                      {time.start}
                    </div>
                    <div
                      style={{
                        padding: 10,
                        borderRight: '1px solid #ebebeb',
                        paddingLeft: 25,
                        height: 100,
                        borderTop: '1px solid #ebebeb',
                        display: 'flex',
                        alignItems: 'flex-start',
                      }}
                    >
                      {time.mid}
                    </div>
                  </div>
                );
              })}
              <div style={{ padding: 10, borderRight: '1px solid #ebebeb', paddingLeft: 25, borderTop: '1px solid #ebebeb', display: 'flex', alignItems: 'flex-start' }}>00:00</div>
            </div>
            <div className="col-md-8">
              {isLoading && (
                <div className="mt-2 cursor-pointer" style={{ display: 'flex', justifyContent: 'center' }}>
                  <Spin spinning={isLoading}></Spin>
                </div>
              )}
              {epgData.length == 0 && !isLoading && (
                <div className="mt-2 cursor-pointer center-text" style={{ display: 'flex', justifyContent: 'center' }} onClick={() => importPublishEpgData()}>
                  {t('the-guide-is-empty-check-if-you-linked-your-guide-source-in-guide-linking-and-make-sure-to-publish-your-bundles')}
                </div>
              )}
              {epgData.map((epg: any, index: any) => {
           
                return (
                  <div key={index} onClick={() => openModal(epg)} style={{ height: getHeight(epg.s, epg.e) }}>
                    {getHeight(epg.s, epg.e) > 50 && (
                      <div
                        className="cursor-pointer"
                        style={{
                          border: '1px solid #ebebeb',
                          padding: 25,
                          display: 'block',
                          backgroundColor: '#fff',
                          height: getHeight(epg.s, epg.e) - 5,
                          borderRadius: 5,
                          marginBottom: 5,
                        }}
                      >
                        <div style={{ height: getHeight(epg.s, epg.e) - 60, overflow: 'hidden' }}>
                          <div>
                            <h5>
                              <Tooltip
                                placement="top"
                                title={
                                  <>
                                    {props.type == "Imported" && <div>{t('click-to-edit')}</div>}
                                    <div>{epg.n}</div>
                                    <div>{moment.unix(epg.s).tz('GMT').format('HH:mm') + ' - ' + moment.unix(epg.e).tz('GMT').format('HH:mm')}</div>
                                  </>
                                }
                              >
                                {epg.n}
                              </Tooltip>
                              <Tooltip placement="top" title={t('click-to-block-this-program')}>
                                <FontAwesomeIcon color={'#222'} className="align-left, align-center ml-2" icon={faEye} />
                              </Tooltip>
                            </h5>
                          </div>
                          <div className="row">
                            {getHeight(epg.s, epg.e) > 175 && epg.m != undefined && epg.m != '' && (
                              <div className="ml-3 mb-1" style={{ paddingTop: 5 }}>
                                <Image src={epg.m} height="100px" preview={false} />
                              </div>
                            )}
                            <div
                              className={getHeight(epg.s, epg.e) > 175 && epg.m != undefined && epg.m != '' ? 'col-md-10' : 'col-md-12'}
                              style={{
                                overflow: 'scroll',
                                height: getHeight(epg.s, epg.e) - 100,
                                marginLeft: getHeight(epg.s, epg.e) > 175 && epg.m != undefined && epg.m != '' ? 10 : 0,
                              }}
                            >
                              <Tooltip
                                placement="top"
                                title={
                                  <>
                                    {props.type =="Imported" && <div>{t('click-to-edit-0')}</div>}
                                    <div>{epg.n}</div>
                                    <div>{moment.unix(epg.s).tz('GMT').format('HH:mm') + ' - ' + moment.unix(epg.e).tz('GMT').format('HH:mm')}</div>
                                  </>
                                }
                              >
                                {moment.unix(epg.s).tz('GMT').format('dddd')}: {moment.unix(epg.s).tz('GMT').format('HH:mm')} - {moment.unix(epg.e).tz('GMT').format('HH:mm')}
                                <br />
                                {epg.d}
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {getHeight(epg.s, epg.e) <= 50 && (
                      <Tooltip
                        placement="top"
                        title={
                          <>
                            {props.type == "Imported" && <div>{t('click-to-edit-1')}</div>}
                            <div>{epg.n}</div>
                            <div>{moment.unix(epg.s).tz('GMT').format('HH:mm') + ' - ' + moment.unix(epg.e).tz('GMT').format('HH:mm')}</div>
                          </>
                        }
                      >
                        <div
                          className="cursor-pointer"
                          style={{
                            border: '1px solid #ebebeb',
                            paddingLeft: 25,
                            display: 'flex',
                            backgroundColor: '#fff',
                            height: getHeight(epg.s, epg.e) - 5,
                            borderRadius: 5,
                            marginBottom: 5,
                            alignItems: 'center',
                          }}
                        >
                          ...
                        </div>
                      </Tooltip>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="col-md-2">
              {timeSlots.map((time: any, index: any) => {
                return (
                  <div key={index}>
                    <div
                      style={{
                        padding: 10,
                        borderLeft: '1px solid #ebebeb',
                        paddingLeft: 25,
                        height: 100,
                        borderTop: '1px solid #ebebeb',
                        display: 'flex',
                        alignItems: 'flex-start',
                      }}
                    ></div>
                    <div
                      style={{
                        padding: 10,
                        borderLeft: '1px solid #ebebeb',
                        paddingLeft: 25,
                        height: 100,
                        borderTop: '1px solid #ebebeb',
                        display: 'flex',
                        alignItems: 'flex-start',
                      }}
                    ></div>
                  </div>
                );
              })}
              <div style={{ padding: 10, borderLeft: '1px solid #ebebeb', paddingLeft: 25, borderTop: '1px solid #ebebeb', display: 'flex', alignItems: 'flex-start' }}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
