import { QueryBuilderAntD } from '@react-querybuilder/antd';
import QueryBuilder, { RuleGroupType } from 'react-querybuilder';
import { CustomerStatus } from '@mwaretv/database/build/backend/enums/customers/customerStatus';
import { CustomerSubscriptionStatus } from '@mwaretv/database/build/backend/enums/customers/customerSubscriptionStatus';
import { useTranslation } from 'react-i18next';

type CustomerQueryBuilderProps = {
  query: RuleGroupType;
  onQueryChange: (q: any) => void;
};

const CustomerQueryBuilder = ({ query, onQueryChange }: CustomerQueryBuilderProps) => {
  const {t} = useTranslation();
  return (
    <QueryBuilderAntD>
      <QueryBuilder
        {...{ query, onQueryChange }}
        fields={[
          { name: 'first_name', label: t('first-name-0') },
          { name: 'last_name', label: t('last-name-0') },
          { name: 'street', label: t('street') },
          { name: 'city', label: t('city') },
          { name: 'state', label: t('state') },
          { name: 'country', label: t('country') },
          { name: 'email', label: t('email')},
          { name: 'mobile', label: t('mobile-3') },
          { name: 'phone', label: t('phone') },
          { name: 'username', label: t('username') },
          { name: 'status', label: t('account-status'), valueEditorType: 'select', values: Object.values(CustomerStatus).map((x) => ({ name: x, label: x.charAt(0).toUpperCase() + x.slice(1) })) },
          {
            name: 'subscriptionStatus',
            label: t('subscription-status'),
            valueEditorType: 'select',
            values: Object.values(CustomerSubscriptionStatus).map((x) => ({ name: x, label: x.charAt(0).toUpperCase() + x.slice(1) })),
          },
          { name: 'extras', label: t('extra-subscriptions') },
          { name: 'base', label: t('base-subscriptions') },
        ]}
      />
    </QueryBuilderAntD>
  );
};

export default CustomerQueryBuilder;
