import { Image, Modal, Spin } from 'antd';
import { Deployment } from '@mwaretv/database/build/backend/models/deployment/deployment';
import { useEffect, useState } from 'react';
import { S3Client, ListObjectsV2Command } from '@aws-sdk/client-s3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDeleteLeft, faFileLines, faFolder, faImage } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ReactJson from 'react-json-view';
import axios from 'axios';

export type LayoutProps = {
  visible: boolean;
  clientName: string;
  deployment: Deployment;
  service: any;
  onCancel: () => void;
  onSubmit: () => void;
}

const ServiceBrowser = (props: LayoutProps) => {
  const { visible, onCancel, service} = props;
  const [data, setData] = useState([] as any);
  const [isLoading, setLoading] = useState(false);
  const [showFileDetails, setShowFileDetails] = useState(false);
  const [fileDetails, setFileDetails] = useState([] as any);
  const [jsonDetails, setJsonDetails] = useState([] as any);
  const [isFirst, setIsFirst] = useState(true);
  const { t } = useTranslation();

  const client = new S3Client({
    credentials: {
      accessKeyId: 'J493W5XNRYM3UGEESLUH',
      secretAccessKey: '9Ee0dMdfnySpiSyB9rIzZe7vWRr2mhgV342HelUQ',
    },
    endpoint: 'https://' + service.storage?.hostname.replace(service.storage.label + '.', ''),
    region: service.storage?.region,
  });

  useEffect(() => {
    const fetchData = async () => {
      getFilesAndFolders('', '', '');
    };
    fetchData();
  }, [props]);

  const getFilesAndFolders = async (prefix, fileType, file) => {
    if (fileType != '') {
      await getJsonContents('https://' + service.storage?.hostname + '/' + file);
      setFileDetails({
        file: file,
        type: fileType,
      });
      setShowFileDetails(true);
    } else {
      if (prefix === '') {
        setIsFirst(true);
      } else {
        setIsFirst(false);
      }
      setLoading(true);
      const command = new ListObjectsV2Command({
        Bucket: service.storage?.label,
        MaxKeys: 1000,
        Prefix: prefix,
        Delimiter: '/',
      });
      try {
        let isTruncated = true as any;
        let contents = [] as any;
        let hasPrefix = false;
        while (isTruncated) {
          const { Contents, CommonPrefixes, IsTruncated, NextContinuationToken } = await client.send(command);
          if (Contents !== undefined) {
            Contents.forEach(content => {
              contents.push(content);
            });
         
          }
          if (CommonPrefixes !== undefined) {
            hasPrefix = true;
            CommonPrefixes.forEach(prefix => {
              contents.push(prefix);
            });

          }
          isTruncated = IsTruncated;
          command.input.ContinuationToken = NextContinuationToken;
        }
        if (contents != undefined) {
          setData(contents)
        } else {
          setData([{ Prefix: 'back' }]);
        }
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    }
  };
  const getJsonContents = async (url) => {
    var response = await axios.get(url);
    if (response.statusText == 'OK') {
      return setJsonDetails(response.data);
    } else {
      return setJsonDetails('something went wrong');
    }
  };

  const getCorrectBackPath = (path) => {
    if (path.Prefix) {
      var str = path.Prefix?.substring(0, path.Prefix.length - 1);
      var the_arr = str.split('/');
      if (the_arr.length > 2) {
        the_arr.pop();
        return the_arr.join('/');
      } else {
        return '';
      }
    }
    if (path.Key) {
      var str = path.Key?.substring(0, path.Key.length - 1);
      var the_arr = str.split('/');
      if (the_arr.length > 2) {
        the_arr.pop();
        return the_arr.join('/');
      } else {
        return '';
      }
    }
  };
  const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };
  const getLastPartOfUrl = (url) => {
    return url.substring(url.lastIndexOf('/') + 1);
  };

  var totalSize = 0;
  return (
    <Modal visible={visible} style={{ border: '1px solid #ebebeb', borderRadius: 10 }} width={'60%'} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-2 mt-4" style={{ border: '1px solid #ebebeb', height: 495 }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="p-2">
              <h5>Storage Explorer</h5>
            </div>
            <div className="row">
              <div className="col-12">
                <div style={{ width: '100%' }}>
                  {showFileDetails && (
                    <Modal visible={showFileDetails} style={{ border: '1px solid #ebebeb', marginTop: 50, borderRadius: 10 }} width={'50%'} footer={false} closable={true} onCancel={() => setShowFileDetails(false)}>
                      <div>
                        {fileDetails.type == 'image' && (
                          <div style={{ margin: 10 }}>
                            <h5>Image File</h5>
                            <Image width="100%" src={'https://' + service.storage?.hostname + '/' + fileDetails.file} style={{ objectFit: 'cover', padding: '10px' }} />
                          </div>
                        )}
                        {fileDetails.type == 'json' && (
                          <div className="mt-2 pb-1" style={{ height: 400, overflow: 'scroll' }}>
                            <div style={{ margin: 10 }}>
                              <h5>Json File</h5>
                              <div style={{ border: '1px solid #ebebeb', padding: 20 }}>
                                <ReactJson displayDataTypes={false} enableClipboard={false}  src={jsonDetails} />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </Modal>
                  )}
                  {isLoading && (
                    <div className="text-center h-100 w-100" style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', position: 'absolute', zIndex: 9999 }}>
                      <div className="col-md-12 align-self-center" style={{ height: 495, paddingTop: 175 }}>
                        <Spin size="large" spinning={true} />
                      </div>
                    </div>
                  )}
                  {!isLoading && (
                    <div className="col-12" style={{ paddingLeft: 0 }}>
                      <div className="row" style={{ marginLeft: 5, marginRight: 5, width: '100%', padding: 5, border: '1px solid #eaeaea', borderBottom: isFirst ? 'none' : '1px solid #eaeaea' }}>
                        <div style={{ width: '60%', display: 'flex', height: 30, alignItems: 'center', paddingLeft: 5 }}>{t('name')}</div>
                        <div style={{ width: '25%', display: 'flex', height: 30, alignItems: 'center', paddingLeft: 5 }}>{t('date')}</div>
                        <div style={{ width: '10%', display: 'flex', height: 30, alignItems: 'center', paddingLeft: 5 }}>{t('size')}</div>
                      </div>
                      {!isFirst && (
                        <div className="row" style={{ marginLeft: 5, marginRight: 5, width: '100%', padding: 5 }}>
                          <button style={{ width: '100%', border: 'none', background: 'none' }} onClick={() => getFilesAndFolders(getCorrectBackPath(data[0]), '', '')}>
                            <div className="row" style={{ paddingLeft: 10 }}>
                              <div className="mr-2" style={{ width: 20, display: 'flex', height: 30, alignItems: 'center' }}>
                                <FontAwesomeIcon color={'gold'} icon={faDeleteLeft} style={{ fontSize: 18 }}></FontAwesomeIcon>
                              </div>
                              <div style={{ display: 'flex', height: 30, alignItems: 'center', textAlign: 'left' }}>{t('back')}</div>
                            </div>
                          </button>
                        </div>
                      )}
                      <div style={{ height: 315, overflow: 'scroll', width: '100%', marginLeft: 5, marginRight: 5, border: '1px solid #eaeaea' }}>
                        {data?.map((res, index) => {
                          var str = res.Prefix?.substring(0, res.Prefix.length - 1);
                          var fileType = '';
                          if (res.Key?.indexOf('.json') > -1) {
                            fileType = 'json';
                          }
                          if (res.Key?.indexOf('.png') > -1) {
                            fileType = 'image';
                          }
                          if (res.Key?.indexOf('.jpg') > -1) {
                            fileType = 'image';
                          }
                          if (res.Key?.indexOf('.jpeg') > -1) {
                            fileType = 'image';
                          }
                          if (res.Key) {
                            totalSize = totalSize + res.Size;
                          }
                          return (
                            <div key={index} className="row" style={{ width: '100%', marginLeft: 0 }}>
                              <button style={{ width: '100%', border: 'none', background: 'none' }} onClick={() => getFilesAndFolders(res.Prefix, fileType, res.Key)}>
                                {res.Prefix != undefined && (
                                  <div style={{ display: 'flex', width: '100%', borderBottomWidth: 1, borderBottomColor: '#eaeaea', borderBottomStyle: 'solid', padding: 5 }}>
                                    {res.Prefix.indexOf('/') > -1 && (
                                      <>
                                        <div className="mr-2" style={{ width: 20, display: 'flex', height: 30, alignItems: 'center' }}>
                                          <FontAwesomeIcon color={'gold'} icon={faFolder} style={{ fontSize: 18 }}></FontAwesomeIcon>
                                        </div>
                                        <div style={{ width: '60%', display: 'flex', height: 30, alignItems: 'center', textAlign: 'left' }}>{isFirst ? str : getLastPartOfUrl(str)}</div>
                                      </>
                                    )}
                                    {res.Prefix == 'back' && (
                                      <>
                                        <div className="mr-2" style={{ width: 20, display: 'flex', height: 30, alignItems: 'center' }}>
                                          <FontAwesomeIcon color={'gold'} icon={faDeleteLeft} style={{ fontSize: 18 }}></FontAwesomeIcon>
                                        </div>
                                        <div style={{ width: '60%', display: 'flex', height: 30, alignItems: 'center', textAlign: 'left' }}>{t(res.Prefix)}</div>
                                      </>
                                    )}
                                  </div>
                                )}
                                {res.Key != undefined && (
                                  <div style={{ width: '100%', display: 'flex', borderBottomWidth: 1, borderBottomColor: '#eaeaea', borderBottomStyle: 'solid', padding: 5 }}>
                                    <div className="mr-2" style={{ width: 20, display: 'flex', minHeight: 30, alignItems: 'center' }}>
                                      {fileType == 'json' && <FontAwesomeIcon color={'blue'} icon={faFileLines} style={{ fontSize: 18 }}></FontAwesomeIcon>}
                                      {fileType == 'image' && <FontAwesomeIcon color={'blue'} icon={faImage} style={{ fontSize: 18 }}></FontAwesomeIcon>}
                                    </div>
                                    <div style={{ width: '60%', display: 'flex', minHeight: 30, alignItems: 'center', textAlign: 'left' }}>{getLastPartOfUrl(res.Key)}</div>
                                    <div style={{ width: '25%', display: 'flex', minHeight: 30, alignItems: 'center', marginLeft: -20, textAlign: 'left' }}>{moment(res.LastModified).format('llll')}</div>
                                    <div style={{ width: '10%', display: 'flex', minHeight: 30, alignItems: 'center', marginLeft: 2, textAlign: 'left' }}>{formatBytes(res.Size)}</div>
                                  </div>
                                )}
                              </button>
                            </div>
                          );
                        })}
                      </div>
                      {data[0]?.Key && (
                        <div className="row" style={{ marginLeft: 5, width: '100%', padding: 5 }}>
                          <div style={{ paddingLeft: 5, width: '48%', display: 'flex', height: 30, alignItems: 'center', marginRight: 5 }}>
                            {t('number_files')}: {data.length}
                          </div>
                          <div style={{ width: '50%', display: 'flex', height: 30, alignItems: 'center', marginRight: 5, justifyContent: 'flex-end' }}>
                            {t('total_size')}: {formatBytes(totalSize)}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ServiceBrowser;
