import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { ContentContext } from '../../../../contexts/contentContext';
import React from 'react';
import moment from 'moment-timezone';
import clientService from '../../../../services/client';
import { IMSNameParams } from '../../../dashboard';
import { StatusCodes } from 'http-status-codes';
import cmsAlbums from '../../../../services/areas/content/albums/albums';
import cmsMovies from '../../../../services/areas/content/movies/movies';
import cmsShorts from '../../../../services/areas/content/shorts/shorts';
import cmsSeries from '../../../../services/areas/content/series/series';
import cmsPodcasts from '../../../../services/areas/content/podcasts/podcasts';
import cmsChannels from '../../../../services/areas/content/channels/channels';
import cmsRadios from '../../../../services/areas/content/radio/radio';
import cmsCams from '../../../../services/areas/content/cams/cams';
import cmsCourse from '../../../../services/areas/content/courses/courses';
import { ICMS_Lists } from '../../../../interfaces/forms/common/ICMS_Lists';
import { useEffectOnce } from 'react-use';
import { useTranslation } from 'react-i18next';

const Dashboard = () => {
  const { clientName } = useParams<{ clientName: string }>();
  const params = useParams<IMSNameParams>();
  const { deployment } = React.useContext(ContentContext);
  const [dashboardData, setDashboardData] = useState([] as any);
  const [dashboardToday, setDashboardToday] = useState([] as any);
  const [cdnTraffic, setCdnTraffic] = useState([] as any);
  const [cdnTrafficGb, setCdnTrafficGb] = useState(0);
  const [cdnTrafficTb, setCdnTrafficTb] = useState(0);
  const [cdnTrafficPb, setCdnTrafficPb] = useState(0);
  const [cdnStorageGb, setCdnStorageGb] = useState(0);
  const [daysMonth, setDaysMonth] = useState([] as any);
  const [isLoading, setIsLoading] = useState(true);
  const [hasTraffic, setHasTraffic] = useState(false);
  const [hasStorage, setHasStorage] = useState(false);
  const [isLoading1, setIsLoading1] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const {t} = useTranslation();
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const path = 'https://cloudtv.akamaized.net/' + clientName + '/' + deployment?.name + '/dashboards/dashboard_content.json';
        //const path = 'https://cloudtv.akamaized.net/MwareTV/mwaretvdep/dashboards/dashboard_content.json';
        const { data } = await axios.get(path);
        var month = data.filter((t) => t.month == Number(moment().format('MM')));
        setDashboardData(month);
        var today = data.find((t) => t.month == Number(moment().format('MM')) && t.day == Number(moment().format('DD')));
        if (today != undefined) {
          setDashboardToday(today);
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };
    fetchData();
    getDaysInAMonth();
  }, [clientName, deployment?.name]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const path = 'https://cloudtv.akamaized.net/' + clientName + '/counters/cdn_traffic.json';
        //const path = 'https://cloudtv.akamaized.net/MwareTV/counters/cdn_traffic.json';
        const { data } = await axios.get(path);
        var traffic = data.find((t) => t.month == Number(moment().format('MM')) && t.day == Number(moment().format('DD')));
        if (traffic != undefined) {
          var total = traffic.gb;
          setCdnTrafficGb(total);
          setCdnTrafficTb(total / 1000);
          setCdnTrafficPb(total / 1000 / 1000);
          setCdnTraffic(data);
        }
        setIsLoading1(false);
      } catch (err) {}
    };
    fetchData();
  }, [clientName, deployment?.name]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const path = 'https://cloudtv.akamaized.net/' + clientName + '/counters/cdn_storage.json';
        //const path = 'https://cloudtv.akamaized.net/MwareTV/counters/cdn_storage.json';
        const { data } = await axios.get(path);
        var storage = data.find((t) => t.month == moment().format('MM') && t.day == moment().format('DD'));
        if (storage != undefined) {
          var total = storage.gb;
          setCdnStorageGb(total);
        }
        setIsLoading2(false);
      } catch (err) {}
    };
    fetchData();
  }, [clientName, deployment?.name]);

  useEffect(() => {
    const fetchData = async () => {
      let response = await clientService.detailsClient({
        _id: params._id,
      });
      if (response && response.status_code === StatusCodes.OK) {
        var resp = response.data as any;
        if (resp.akamai_cp_code_cdn != '') {
          setHasTraffic(true);
        }
        if (resp.akamai_cp_code_storage != '') {
          setHasStorage(true);
        }
      }
    };
    fetchData();
  }, [clientName, deployment?.name]);

  const getCdnTrafficSeries = (traffic) => {
    var trafficIn = traffic.filter((t) => t.month == moment().format('MM'));
    var data = [] as any;
    trafficIn.forEach((traf) => {
      data.push(traf.gb);
    });
    return [
      {
        name: t('cdn-traffic-in-gbs'),
        data: data,
      },
    ];
  };
  const lineOptionsWithDays = {
    chart: {
      animations: {
        enabled: true,
      },
      background: '',
    },
    dataLabels: {
      enabled: false,
    },

    colors: ['#5868bd'],
    xaxis: {
      categories: daysMonth,
    },
  };

  const getDaysInAMonth = () => {
    var daysThisMonth = moment().daysInMonth();
    var days = [] as any;
    for (let index = 0; index < daysThisMonth; index++) {
      days.push(index + 1 + ' ' + moment().format('MMM'));
    }
    setDaysMonth(days);
  };

  const barOptions = {
    chart: {
      animations: {
        enabled: true,
      },
      background: '',
    },
    dataLabels: {
      enabled: false,
    },

    colors: ['#5868bd'],
  };

  const getPopularChannelsOptions = (dashboard) => {
    var data = [] as any;

    dashboardToday.channels.lists.top10.forEach((item) => {
      data.push(item.title);
    });
    // });
    return {
      barOptions,
      xaxis: {
        categories: data,
      },
    };
  };
  const getPopularChannelsSeries = (dashboard) => {
    var data = [] as any;
    dashboardToday.channels.lists.top10.forEach((item) => {
      data.push(item.count);
    });
    return [
      {
        name: t('total-watched-1'),
        data: data,
      },
    ];
  };
  const getPopularCamsOptions = (dashboard) => {
    var data = [] as any;

    dashboardToday.cams.lists.top10.forEach((item) => {
      data.push(item.title);
    });

    return {
      barOptions,
      xaxis: {
        categories: data,
      },
    };
  };
  const getPopularCamsSeries = (dashboard) => {
    var data = [] as any;

    dashboardToday.cams.lists.top10.forEach((item) => {
      data.push(item.count);
    });

    return [
      {
        name: t('total-watched-1'),
        data: data,
      },
    ];
  };
  const getPopularRadiosOptions = (dashboard) => {
    var data = [] as any;

    dashboardToday.radio.lists.top10.forEach((item) => {
      data.push(item.title);
    });

    return {
      barOptions,
      xaxis: {
        categories: data,
      },
    };
  };
  const getPopularRadiosSeries = (dashboard) => {
    var data = [] as any;

    dashboardToday.radio.lists.top10.forEach((item) => {
      data.push(item.count);
    });

    return [
      {
        name: t('total-watched-1'),
        data: data,
      },
    ];
  };
  const getPopularMoviesOptions = (dashboard) => {
    var data = [] as any;

    dashboardToday.movies.lists.top10.forEach((item) => {
      data.push(item.title);
    });

    return {
      barOptions,
      xaxis: {
        categories: data,
      },
    };
  };
  const getPopularMoviesSeries = (dashboard) => {
    var data = [] as any;

    dashboardToday.movies.lists.top10.forEach((item) => {
      data.push(item.count);
    });

    return [
      {
        name: t('total-watched-1'),
        data: data,
      },
    ];
  };
  const getPopularSeriesOptions = (dashboard) => {
    var data = [] as any;

    dashboardToday.series.lists.top10.forEach((item) => {
      data.push(item.title);
    });

    return {
      barOptions,
      xaxis: {
        categories: data,
      },
    };
  };
  const getPopularSeriesSeries = (dashboard) => {
    var data = [] as any;

    dashboardToday.series.lists.top10.forEach((item) => {
      data.push(item.count);
    });

    return [
      {
        name: t('total-watched-1'),
        data: data,
      },
    ];
  };
  const getPopularAlbumsOptions = (dashboard) => {
    var data = [] as any;

    dashboardToday.albums.lists.top10.forEach((item) => {
      data.push(item.title);
    });

    return {
      barOptions,
      xaxis: {
        categories: data,
      },
    };
  };
  const getPopularAlbumsSeries = (dashboard) => {
    var data = [] as any;

    dashboardToday.albums.lists.top10.forEach((item) => {
      data.push(item.count);
    });

    return [
      {
        name: t('total-watched-1'),
        data: data,
      },
    ];
  };
  const getPopularPodcastsOptions = (dashboard) => {
    var data = [] as any;

    dashboardToday.pods.lists.top10.forEach((item) => {
      data.push(item.title);
    });

    return {
      barOptions,
      xaxis: {
        categories: data,
      },
    };
  };
  const getPopularPodcastsSeries = (dashboard) => {
    var data = [] as any;

    dashboardToday.pods.lists.top10.forEach((item) => {
      data.push(item.count);
    });

    return [
      {
        name: t('total-watched-1'),
        data: data,
      },
    ];
  };
  const getPopularShortsOptions = (dashboard) => {
    var data = [] as any;

    dashboardToday.shorts.lists.top10.forEach((item) => {
      data.push(item.title);
    });

    return {
      barOptions,
      xaxis: {
        categories: data,
      },
    };
  };
  const getPopularShortsSeries = (dashboard) => {
    var data = [] as any;

    dashboardToday.shorts.lists.top10.forEach((item) => {
      data.push(item.count);
    });

    return [
      {
        name: t('total-watched-1'),
        data: data,
      },
    ];
  };
  const getPopularCoursesOptions = (dashboard) => {
    var data = [] as any;

    dashboardToday.courses.lists.top10.forEach((item) => {
      data.push(item.title);
    });

    return {
      barOptions,
      xaxis: {
        categories: data,
      },
    };
  };
  const getPopularCoursesSeries = (dashboard) => {
    var data = [] as any;

    dashboardToday.courses.lists.top10.forEach((item) => {
      data.push(item.count);
    });

    return [
      {
        name: t('total-watched-1'),
        data: data,
      },
    ];
  };
  const [tableSearchState, setTableSearchState] = useState<ICMS_Lists>({
    pageNumber: 1,
    pageSize: 50,
    query_params: {
      instance: params.clientName,
      cms: deployment?.name ?? '',
    },
    filters: [],
  });

  const [showAlbums, setShowAlbums] = useState(false);
  const getAlbums = async (list: ICMS_Lists) => {
    const response = await cmsAlbums.List_Albums(list);
    if (response && response.status_code === StatusCodes.OK) {
      if (response.data.count > 0) {
        setShowAlbums(true);
      }
    }
  };
  const [showCams, setShowCams] = useState(false);
  const getCams = async (list: ICMS_Lists) => {
    const response = await cmsCams.List_Cams(list);
    if (response && response.status_code === StatusCodes.OK) {
      if (response.data.count > 0) {
        setShowCams(true);
      }
    }
  };
  const [showPods, setShowPods] = useState(false);
  const getPods = async (list: ICMS_Lists) => {
    const response = await cmsPodcasts.List_Podcasts(list);
    if (response && response.status_code === StatusCodes.OK) {
      if (response.data.count > 0) {
        setShowPods(true);
      }
    }
  };
  const [showChannels, setShowChannels] = useState(false);
  const getChannels = async (list: ICMS_Lists) => {
    const response = await cmsChannels.List_Channels(list);
    if (response && response.status_code === StatusCodes.OK) {
      if (response.data.count > 0) {
        setShowChannels(true);
      }
    }
  };
  const [showRadio, setShowRadio] = useState(false);
  const getRadio = async (list: ICMS_Lists) => {
    const response = await cmsRadios.List_Radios(list);
    if (response && response.status_code === StatusCodes.OK) {
      if (response.data.count > 0) {
        setShowRadio(true);
      }
    }
  };
  const [showMovies, setShowMovies] = useState(false);
  const getMovies = async (list: ICMS_Lists) => {
    const response = await cmsMovies.List_Movies(list);
    if (response && response.status_code === StatusCodes.OK) {
      if (response.data.count > 0) {
        setShowMovies(true);
      }
    }
  };
  const [showSeries, setShowSeries] = useState(false);
  const getSeries = async (list: ICMS_Lists) => {
    const response = await cmsSeries.List_Seriess(list);
    if (response && response.status_code === StatusCodes.OK) {
      if (response.data.count > 0) {
        setShowSeries(true);
      }
    }
  };
  const [showShorts, setShowShorts] = useState(false);
  const getShorts = async (list: ICMS_Lists) => {
    const response = await cmsShorts.List_Shorts(list);
    if (response && response.status_code === StatusCodes.OK) {
      if (response.data.count > 0) {
        setShowShorts(true);
      }
    }
  };
  const [showCourses, setShowCourses] = useState(false);
  const getCourses = async (list: ICMS_Lists) => {
    const response = await cmsCourse.List_Courses(list);
    if (response && response.status_code === StatusCodes.OK) {
      if (response.data.count > 0) {
        setShowCourses(true);
      }
    }
  };

  useEffectOnce(() => {
    getAlbums(tableSearchState);
    getChannels(tableSearchState);
    getCams(tableSearchState);
    getRadio(tableSearchState);
    getMovies(tableSearchState);
    getSeries(tableSearchState);
    getShorts(tableSearchState);
    getCourses(tableSearchState);
    getPods(tableSearchState);
  });

  const getContentUsageSeries = (dashboard, type) => {
    var seriesChannels = [] as any;
    var seriesCams = [] as any;
    var seriesRadios = [] as any;
    var seriesMovies = [] as any;
    var seriesSeries = [] as any;
    var seriesShorts = [] as any;
    var seriesAlbums = [] as any;
    var seriesPodcasts = [] as any;
    var seriesCourses = [] as any;

    dashboard.forEach((day) => {
      seriesChannels.push(day.channels.counters.total);
      seriesCams.push(day.cams.counters.total);
      seriesRadios.push(day.radio.counters.total);
      seriesMovies.push(day.movies.counters.total);
      seriesSeries.push(day.series.counters.total);
      seriesShorts.push(day.shorts.counters.total);
      seriesAlbums.push(day.albums.counters.total);
      seriesPodcasts.push(day.pods.counters.total);
      seriesCourses.push(day.courses.counters.total);
    });
    switch (type) {
      case "Channels":
        return [
          {
            name: t('channel-plays'),
            data: seriesChannels,
          },
        ];
        break;
      case "Radio":
        return [
          {
            name: t('radio-plays'),
            data: seriesRadios,
          },
        ];
        break;
      case "Cams":
        return [
          {
            name: t('cam-plays'),
            data: seriesCams,
          },
        ];
        break;
      case"Movies":
        return [
          {
            name: t('movie-plays'),
            data: seriesMovies,
          },
        ];
        break;
      case "Series":
        return [
          {
            name: t('series-plays'),
            data: seriesSeries,
          },
        ];
        break;
      case "Shorts":
        return [
          {
            name: t('short-plays'),
            data: seriesShorts,
          },
        ];
        break;
      case "Albums":
        return [
          {
            name: t('album-plays'),
            data: seriesAlbums,
          },
        ];
        case "Podcasts":
        return [
          {
            name: t('podcast-plays'),
            data: seriesPodcasts,
          },
        ];
        break;
      case "Courses":
        return [
          {
            name: t('course-plays'),
            data: seriesCourses,
          },
        ];
        break;

      default:
        return [
          {
            name: t('no-plays'),
            data: [],
          },
        ];
        break;
    }
  };

  const roundUp2Decimal = (value) => {
    return Math.round(value * 100) / 100;
  };

  const refreshDashboard = async () => {
    const projectName = 'tvmsproduction';
    const projectRegion = 'europe-west1';

    var path = `https://${projectRegion}-${projectName}.cloudfunctions.net/reporting?client=` + clientName + '&deployment=' + deployment?.name + '&action=createDeploymentDashboards';
    await axios
      .get(path)
      .then((result) => {})
      .catch((e) => {
       
      });
  };
  const refreshCdnDashboard = async () => {
    const projectName = 'tvmsproduction';
    const projectRegion = 'europe-west1';
    var path3 = `https://${projectRegion}-${projectName}.cloudfunctions.net/reporting?client=` + clientName + '&action=cdnStorage';
    await axios
      .get(path3)
      .then((result) => {})
      .catch((e) => {
       
      });
    var path2 = `https://${projectRegion}-${projectName}.cloudfunctions.net/reporting?client=` + clientName + '&action=cdnTraffic';
    await axios
      .get(path2)
      .then((result) => {})
      .catch((e) => {
       
      });
  };

  return (
    <div className="container-fluid" style={{ padding: 30 }}>
      <div className="row">
        <div className="col-md-6 mb-2">
          <h2 className="page-title">{t('marketing-dashboard')}</h2>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-12 mb-2">
          <Card className="p-0" bodyStyle={{ padding: '0px', backgroundImage: 'linear-gradient(#f4f4f4, #ececec)', border: '1px solid #e9e9e9', borderRadius: 5, borderLeftColor: '#dfdfdf', borderLeft: 'border-secondary' }}>
            <div className="p-2">
              <div className="row">
                <div className="col-md-3 col-lg-2 col-xl-1"></div>
                <div className="col-md-6 col-lg-6 col-xl-8 pl-xl-5 mt-lg-0"></div>
                <div className="col-md-3 d-flex justify-content-lg-end justify-content-center align-items-center mt-lg-0">
                  <Button onClick={refreshDashboard} className="ant-btn btn btn-secondary rounded-pill width-240 height-48">
                    Refresh Dashboard Data
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div> */}
      {/* <div className="row">
        <div className="col-12 mb-2">
          <Card className="p-0" bodyStyle={{ padding: '0px', backgroundImage: 'linear-gradient(#f4f4f4, #ececec)', border: '1px solid #e9e9e9', borderRadius: 5, borderLeftColor: '#dfdfdf', borderLeft: 'border-secondary' }}>
            <div className="p-2">
              <div className="row">
                <div className="col-md-3 col-lg-2 col-xl-1"></div>
                <div className="col-md-6 col-lg-6 col-xl-8 pl-xl-5 mt-lg-0"></div>
                <div className="col-md-3 d-flex justify-content-lg-end justify-content-center align-items-center mt-lg-0">
                  <Button onClick={refreshCdnDashboard} className="ant-btn btn btn-secondary rounded-pill width-240 height-48">
                    Refresh CDN Data
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div> */}
      {/* <div className="row mt-4 ">
        {hasTraffic && cdnTraffic.length > 0 && (
          <div className="col-md-12">
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
              <div style={{ width: '100%' }}>
                {hasTraffic && cdnTraffic.length > 0 && (
                  <div style={{ backgroundImage: 'linear-gradient(#f4f4f4, #ececec)', border: '1px solid #e9e9e9', borderRadius: 5, marginRight: 20, padding: 10 }}>
                    <p className="h4 font-weight-normal line-height-26" style={{ margin: 10 }}>
                      CDN Traffic per Day ({moment().format('MMMM YYYY')})
                    </p>
                    <AreaChart options={lineOptionsWithDays} series={getCdnTrafficSeries(cdnTraffic)} />
                  </div>
                )}
              </div>
              <div style={{ flexDirection: 'column', width: '40%' }}>
                <div style={{ height: '48%', backgroundImage: 'linear-gradient(#f4f4f4, #e2e2e2)', border: '1px solid #e9e9e9', borderRadius: 5, padding: 10 }}>
                  {hasTraffic && !isLoading1 && (
                    <>
                      <p className="h6 line-height-26" style={{ fontWeight: 'bold' }}>
                        CDN Traffic Total ({moment().format('MMMM YYYY')})
                      </p>
                      <div style={{ height: '70%', display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', marginTop: 20, alignItems: 'flex-end' }}>
                        <div style={{ display: 'flex', fontSize: 30, flexDirection: 'column' }}>
                          <p style={{ textAlign: 'end' }}>{Math.round(cdnTrafficGb * 100) / 100} GB</p>
                          <p style={{ textAlign: 'end' }}>{Math.round(cdnTrafficTb * 100) / 100} TB</p>
                          <p style={{ textAlign: 'end' }}>{Math.round(cdnTrafficPb * 100) / 100} PB</p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div style={{ height: '4%' }}></div>
                <div style={{ height: '48%', backgroundImage: 'linear-gradient(#f4f4f4, #e2e2e2)', border: '1px solid #e9e9e9', borderRadius: 5, padding: 10 }}>
                  {hasStorage && !isLoading2 && (
                    <>
                      <p className="h6 line-height-26" style={{ fontWeight: 'bold' }}>
                        CDN Storage Total ({moment().format('MMMM YYYY')})
                      </p>
                      <div style={{ height: '80%', display: 'flex', fontSize: 50, justifyContent: 'center', alignItems: 'center' }}>{Math.round(cdnStorageGb)} GB</div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {!isLoading && dashboardData.length > 0 && (
        <div className="row mt-4 ">
          {showChannels && (
            <div className="col-md-6 mb-4">
              <div style={{ width: '100%' }}>
                <div style={{ backgroundImage: 'linear-gradient(#f4f4f4, #ececec)', border: '1px solid #e9e9e9', borderRadius: 5, padding: 10 }}>
                  <p className="h4 font-weight-normal line-height-26" style={{ margin: 10 }}>
                    Channel Usage per Day ({moment().format('MMMM YYYY')})
                  </p>
                  {!isLoading && <AreaChart options={lineOptionsWithDays} series={getContentUsageSeries(dashboardData, 'Channels')} />}
                </div>
              </div>
            </div>
          )}
          {showChannels && (
            <div className="col-md-6 mb-4">
              <div style={{ width: '100%' }}>
                <div style={{ backgroundImage: 'linear-gradient(#f4f4f4, #ececec)', border: '1px solid #e9e9e9', borderRadius: 5, padding: 10 }}>
                  <p className="h4 font-weight-normal line-height-26" style={{ margin: 10 }}>
                    Radio Usage per Day ({moment().format('MMMM YYYY')})
                  </p>
                  {!isLoading && <AreaChart options={lineOptionsWithDays} series={getContentUsageSeries(dashboardData, 'Radio')} />}
                </div>
              </div>
            </div>
          )}
          {showCams && (
            <div className="col-md-6 mb-4">
              <div style={{ width: '100%' }}>
                <div style={{ backgroundImage: 'linear-gradient(#f4f4f4, #ececec)', border: '1px solid #e9e9e9', borderRadius: 5, padding: 10 }}>
                  <p className="h4 font-weight-normal line-height-26" style={{ margin: 10 }}>
                    Cam Usage per Day ({moment().format('MMMM YYYY')})
                  </p>
                  {!isLoading && <AreaChart options={lineOptionsWithDays} series={getContentUsageSeries(dashboardData, 'Cams')} />}
                </div>
              </div>
            </div>
          )}
          {showMovies && (
            <div className="col-md-6  mb-4">
              <div style={{ width: '100%' }}>
                <div style={{ backgroundImage: 'linear-gradient(#f4f4f4, #ececec)', border: '1px solid #e9e9e9', borderRadius: 5, padding: 10 }}>
                  <p className="h4 font-weight-normal line-height-26" style={{ margin: 10 }}>
                    Movie Usage per Day ({moment().format('MMMM YYYY')})
                  </p>
                  {!isLoading && <AreaChart options={lineOptionsWithDays} series={getContentUsageSeries(dashboardData, 'Movies')} />}
                </div>
              </div>
            </div>
          )}
          {showShorts && (
            <div className="col-md-6 mb-4">
              <div style={{ width: '100%' }}>
                <div style={{ backgroundImage: 'linear-gradient(#f4f4f4, #ececec)', border: '1px solid #e9e9e9', borderRadius: 5, padding: 10 }}>
                  <p className="h4 font-weight-normal line-height-26" style={{ margin: 10 }}>
                    Shorts Usage per Day ({moment().format('MMMM YYYY')})
                  </p>
                  {!isLoading && <AreaChart options={lineOptionsWithDays} series={getContentUsageSeries(dashboardData, 'Shorts')} />}
                </div>
              </div>
            </div>
          )}
          {showSeries && (
            <div className="col-md-6 mb-4">
              <div style={{ width: '100%' }}>
                <div style={{ backgroundImage: 'linear-gradient(#f4f4f4, #ececec)', border: '1px solid #e9e9e9', borderRadius: 5, padding: 10 }}>
                  <p className="h4 font-weight-normal line-height-26" style={{ margin: 10 }}>
                    Series Usage per Day ({moment().format('MMMM YYYY')})
                  </p>
                  {!isLoading && <AreaChart options={lineOptionsWithDays} series={getContentUsageSeries(dashboardData, 'Series')} />}
                </div>
              </div>
            </div>
          )}
          {showCourses && (
            <div className="col-md-6 mb-4">
              <div style={{ width: '100%' }}>
                <div style={{ backgroundImage: 'linear-gradient(#f4f4f4, #ececec)', border: '1px solid #e9e9e9', borderRadius: 5, padding: 10 }}>
                  <p className="h4 font-weight-normal line-height-26" style={{ margin: 10 }}>
                    Course Usage per Day ({moment().format('MMMM YYYY')})
                  </p>
                  {!isLoading && <AreaChart options={lineOptionsWithDays} series={getContentUsageSeries(dashboardData, 'Courses')} />}
                </div>
              </div>
            </div>
          )}
          {showAlbums && (
            <div className="col-md-6 mb-4">
              <div style={{ width: '100%' }}>
                <div style={{ backgroundImage: 'linear-gradient(#f4f4f4, #ececec)', border: '1px solid #e9e9e9', borderRadius: 5, padding: 10 }}>
                  <p className="h4 font-weight-normal line-height-26" style={{ margin: 10 }}>
                    Albums Usage per Day ({moment().format('MMMM YYYY')})
                  </p>
                  {!isLoading && <AreaChart options={lineOptionsWithDays} series={getContentUsageSeries(dashboardData, 'Albums')} />}
                </div>
              </div>
            </div>
          )}
          {showPods && (
            <div className="col-md-6 mb-4">
              <div style={{ width: '100%' }}>
                <div style={{ backgroundImage: 'linear-gradient(#f4f4f4, #ececec)', border: '1px solid #e9e9e9', borderRadius: 5, padding: 10 }}>
                  <p className="h4 font-weight-normal line-height-26" style={{ margin: 10 }}>
                    Podcasts Usage per Day ({moment().format('MMMM YYYY')})
                  </p>
                  {!isLoading && <AreaChart options={lineOptionsWithDays} series={getContentUsageSeries(dashboardData, 'Podcasts')} />}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {!isLoading && dashboardToday.date != undefined && (
        <>
          <div className="row mt-4 " style={{ paddingLeft: 5, paddingRight: 5 }}>
            {showChannels && (
              <div className="col-md-4" style={{ padding: 5 }}>
                <div style={{ color: '#fff', display: 'flex', backgroundImage: 'linear-gradient(#5e6fd0, #465298)', borderRadius: 5, margin: 2, padding: 10 }}>
                  <div style={{ margin: 20 }}>
                    <p className="h5 line-height-26" style={{ fontWeight: 'bold' }}>
                      Channels ({moment().format('MMMM YYYY')})
                    </p>
                    <div>Channel Plays: {dashboardToday.channels.counters.total}</div>
                    <div>Total Hours Watched: {roundUp2Decimal(dashboardToday.channels.duration.duration / 3600)} Hrs</div>
                  </div>
                  <div style={{ position: 'absolute', right: 20, opacity: 0.1 }}>
                    <FontAwesomeIcon icon={faTelevision} color={'#fff'} size={'8x'}></FontAwesomeIcon>
                  </div>
                </div>
              </div>
            )}
            {showRadio && (
              <div className="col-md-4" style={{ padding: 5 }}>
                <div style={{ color: '#fff', display: 'flex', backgroundImage: 'linear-gradient(#5e6fd0, #465298)', borderRadius: 5, margin: 2, padding: 10 }}>
                  <div style={{ margin: 20 }}>
                    <p className="h5 line-height-26" style={{ fontWeight: 'bold' }}>
                      Radio ({moment().format('MMMM YYYY')})
                    </p>
                    <div>Radio Plays: {dashboardToday.radio.counters.total}</div>
                    <div>Total Hours Watched: {roundUp2Decimal(dashboardToday.radio.duration.duration / 3600)} Hrs</div>
                  </div>
                  <div style={{ position: 'absolute', right: 20, opacity: 0.1 }}>
                    <FontAwesomeIcon icon={faRadio} color={'#fff'} size={'8x'}></FontAwesomeIcon>
                  </div>
                </div>
              </div>
            )}
            {showCams && (
              <div className="col-md-4" style={{ padding: 5 }}>
                <div style={{ color: '#fff', display: 'flex', backgroundImage: 'linear-gradient(#5e6fd0, #465298)', borderRadius: 5, margin: 2, padding: 10 }}>
                  <div style={{ margin: 20 }}>
                    <p className="h5 line-height-26" style={{ fontWeight: 'bold' }}>
                      Cams ({moment().format('MMMM YYYY')})
                    </p>
                    <div>Cam Plays: {dashboardToday.cams.counters.total}</div>
                    <div>Total Hours Watched: {roundUp2Decimal(dashboardToday.cams.duration.duration / 3600)} Hrs</div>
                  </div>
                  <div style={{ position: 'absolute', right: 20, opacity: 0.1 }}>
                    <FontAwesomeIcon icon={faCctv} color={'#fff'} size={'8x'}></FontAwesomeIcon>
                  </div>
                </div>
              </div>
            )}
            {showMovies && (
              <div className="col-md-4" style={{ padding: 5 }}>
                <div style={{ color: '#fff', display: 'flex', backgroundImage: 'linear-gradient(#5e6fd0, #465298)', borderRadius: 5, margin: 2, padding: 10 }}>
                  <div style={{ margin: 20 }}>
                    <p className="h5 line-height-26" style={{ fontWeight: 'bold' }}>
                      Movies ({moment().format('MMMM YYYY')})
                    </p>
                    <div>Movie Plays: {dashboardToday.movies.counters.total}</div>
                    <div>Total Hours Watched: {roundUp2Decimal(dashboardToday.movies.duration.duration / 3600)} Hrs</div>
                  </div>
                  <div style={{ position: 'absolute', right: 20, opacity: 0.1 }}>
                    <FontAwesomeIcon icon={faFilm} color={'#fff'} size={'8x'}></FontAwesomeIcon>
                  </div>
                </div>
              </div>
            )}
            {showShorts && (
              <div className="col-md-4" style={{ padding: 5 }}>
                <div style={{ color: '#fff', display: 'flex', backgroundImage: 'linear-gradient(#5e6fd0, #465298)', borderRadius: 5, margin: 2, padding: 10 }}>
                  <div style={{ margin: 20 }}>
                    <p className="h5  line-height-26" style={{ fontWeight: 'bold' }}>
                      Shorts ({moment().format('MMMM YYYY')})
                    </p>
                    <div>Short Plays: {dashboardToday.shorts.counters.total}</div>
                    <div>Total Hours Watched: {roundUp2Decimal(dashboardToday.shorts.duration.duration / 3600)} Hrs</div>
                  </div>
                  <div style={{ position: 'absolute', right: 20, opacity: 0.1 }}>
                    <FontAwesomeIcon icon={faCamcorder} color={'#fff'} size={'8x'}></FontAwesomeIcon>
                  </div>
                </div>
              </div>
            )}
            {showSeries && (
              <div className="col-md-4" style={{ padding: 5 }}>
                <div style={{ color: '#fff', display: 'flex', backgroundImage: 'linear-gradient(#5e6fd0, #465298)', borderRadius: 5, margin: 2, padding: 10 }}>
                  <div style={{ margin: 20 }}>
                    <p className="h5 line-height-26" style={{ fontWeight: 'bold' }}>
                      Series ({moment().format('MMMM YYYY')})
                    </p>
                    <div>Series Plays: {dashboardToday.series.counters.total}</div>
                    <div>Total Hours Watched: {roundUp2Decimal(dashboardToday.series.duration.duration / 3600)} Hrs</div>
                  </div>
                  <div style={{ position: 'absolute', right: 20, opacity: 0.1 }}>
                    <FontAwesomeIcon icon={faVideo} color={'#fff'} size={'8x'}></FontAwesomeIcon>
                  </div>
                </div>
              </div>
            )}
            {showCourses && (
              <div className="col-md-4" style={{ padding: 5 }}>
                <div style={{ color: '#fff', display: 'flex', backgroundImage: 'linear-gradient(#5e6fd0, #465298)', borderRadius: 5, margin: 2, padding: 10 }}>
                  <div style={{ margin: 20 }}>
                    <p className="h5  line-height-26" style={{ fontWeight: 'bold' }}>
                      Courses ({moment().format('MMMM YYYY')})
                    </p>
                    <div>Course Plays: {dashboardToday.courses.counters.total}</div>
                    <div>Total Hours Watched: {roundUp2Decimal(dashboardToday.courses.duration.duration / 3600)} Hrs</div>
                  </div>
                  <div style={{ position: 'absolute', right: 20, opacity: 0.1 }}>
                    <FontAwesomeIcon icon={faSchool} color={'#fff'} size={'8x'}></FontAwesomeIcon>
                  </div>
                </div>
              </div>
            )}
            {showAlbums && (
              <div className="col-md-4" style={{ padding: 5 }}>
                <div style={{ color: '#fff', display: 'flex', backgroundImage: 'linear-gradient(#5e6fd0, #465298)', borderRadius: 5, margin: 2, padding: 10 }}>
                  <div style={{ margin: 20 }}>
                    <p className="h5 line-height-26" style={{ fontWeight: 'bold' }}>
                      Albums ({moment().format('MMMM YYYY')})
                    </p>
                    <div>Album Plays: {dashboardToday.albums.counters.total}</div>
                    <div>Total Hours Watched: {roundUp2Decimal(dashboardToday.albums.duration.duration / 3600)} Hrs</div>
                  </div>
                  <div style={{ position: 'absolute', right: 20, opacity: 0.1 }}>
                    <FontAwesomeIcon icon={faMusic} color={'#fff'} size={'8x'}></FontAwesomeIcon>
                  </div>
                </div>
              </div>
            )}
            {showPods && (
              <div className="col-md-4" style={{ padding: 5 }}>
                <div style={{ color: '#fff', display: 'flex', backgroundImage: 'linear-gradient(#5e6fd0, #465298)', borderRadius: 5, margin: 2, padding: 10 }}>
                  <div style={{ margin: 20 }}>
                    <p className="h5 line-height-26" style={{ fontWeight: 'bold' }}>
                      Podcasts ({moment().format('MMMM YYYY')})
                    </p>
                    <div>Podcast Plays: {dashboardToday.pods.counters.total}</div>
                    <div>Total Hours Watched: {roundUp2Decimal(dashboardToday.pods.duration.duration / 3600)} Hrs</div>
                  </div>
                  <div style={{ position: 'absolute', right: 20, opacity: 0.1 }}>
                    <FontAwesomeIcon icon={faPodcast} color={'#fff'} size={'8x'}></FontAwesomeIcon>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {!isLoading && dashboardToday.date != undefined && (
        <div className="row mt-4">
          {showChannels && (
            <div className="col-md-6 mb-4">
              <div style={{ backgroundImage: 'linear-gradient(#f4f4f4, #ececec)', border: '1px solid #e9e9e9', borderRadius: 5, padding: 10 }}>
                <p className="h5 font-weight-normal line-height-26" style={{ margin: 10 }}>
                  Channels ({moment().format('MMMM YYYY')})
                </p>
                {!isLoading && <BarChart options={getPopularChannelsOptions(dashboardData)} series={getPopularChannelsSeries(dashboardData)} />}
              </div>
            </div>
          )}
          {showRadio && (
            <div className="col-md-6 mb-4">
              <div style={{ backgroundImage: 'linear-gradient(#f4f4f4, #ececec)', border: '1px solid #e9e9e9', borderRadius: 5, padding: 10 }}>
                <p className="h5 font-weight-normal line-height-26" style={{ margin: 10 }}>
                  Radio ({moment().format('MMMM YYYY')})
                </p>
                {!isLoading && <BarChart options={getPopularRadiosOptions(dashboardData)} series={getPopularRadiosSeries(dashboardData)} />}
              </div>
            </div>
          )}
          {showCams && (
            <div className="col-md-6 mb-4">
              <div style={{ backgroundImage: 'linear-gradient(#f4f4f4, #ececec)', border: '1px solid #e9e9e9', borderRadius: 5, padding: 10 }}>
                <p className="h5 font-weight-normal line-height-26" style={{ margin: 10 }}>
                  Cams ({moment().format('MMMM YYYY')})
                </p>
                {!isLoading && <BarChart options={getPopularCamsOptions(dashboardData)} series={getPopularCamsSeries(dashboardData)} />}
              </div>
            </div>
          )}
          {showMovies && (
            <div className="col-md-6 mb-4">
              <div style={{ backgroundImage: 'linear-gradient(#f4f4f4, #ececec)', border: '1px solid #e9e9e9', borderRadius: 5, padding: 10 }}>
                <p className="h5 font-weight-normal line-height-26" style={{ margin: 10 }}>
                  Movies ({moment().format('MMMM YYYY')})
                </p>
                {!isLoading && <BarChart options={getPopularMoviesOptions(dashboardData)} series={getPopularMoviesSeries(dashboardData)} />}
              </div>
            </div>
          )}
          {showShorts && (
            <div className="col-md-6 mb-4">
              <div style={{ backgroundImage: 'linear-gradient(#f4f4f4, #ececec)', border: '1px solid #e9e9e9', borderRadius: 5, padding: 10 }}>
                <p className="h5 font-weight-normal line-height-26" style={{ margin: 10 }}>
                  Shorts ({moment().format('MMMM YYYY')})
                </p>
                {!isLoading && <BarChart options={getPopularShortsOptions(dashboardData)} series={getPopularShortsSeries(dashboardData)} />}
              </div>
            </div>
          )}
          {showSeries && (
            <div className="col-md-6 mb-4">
              <div style={{ backgroundImage: 'linear-gradient(#f4f4f4, #ececec)', border: '1px solid #e9e9e9', borderRadius: 5, padding: 10 }}>
                <p className="h5 font-weight-normal line-height-26" style={{ margin: 10 }}>
                  Series ({moment().format('MMMM YYYY')})
                </p>
                {!isLoading && <BarChart options={getPopularSeriesOptions(dashboardData)} series={getPopularSeriesSeries(dashboardData)} />}
              </div>
            </div>
          )}
          {showCourses && (
            <div className="col-md-6 mb-4">
              <div style={{ backgroundImage: 'linear-gradient(#f4f4f4, #ececec)', border: '1px solid #e9e9e9', borderRadius: 5, padding: 10 }}>
                <p className="h5 font-weight-normal line-height-26" style={{ margin: 10 }}>
                  Courses ({moment().format('MMMM YYYY')})
                </p>
                {!isLoading && <BarChart options={getPopularCoursesOptions(dashboardData)} series={getPopularCoursesSeries(dashboardData)} />}
              </div>
            </div>
          )}
          {showAlbums && (
            <div className="col-md-6 mb-4">
              <div style={{ backgroundImage: 'linear-gradient(#f4f4f4, #ececec)', border: '1px solid #e9e9e9', borderRadius: 5, padding: 10 }}>
                <p className="h5 font-weight-normal line-height-26" style={{ margin: 10 }}>
                  Albums ({moment().format('MMMM YYYY')})
                </p>
                {!isLoading && <BarChart options={getPopularAlbumsOptions(dashboardData)} series={getPopularAlbumsSeries(dashboardData)} />}
              </div>
            </div>
          )}
          {showPods && (
            <div className="col-md-6 mb-4">
              <div style={{ backgroundImage: 'linear-gradient(#f4f4f4, #ececec)', border: '1px solid #e9e9e9', borderRadius: 5, padding: 10 }}>
                <p className="h5 font-weight-normal line-height-26" style={{ margin: 10 }}>
                  Podcasts ({moment().format('MMMM YYYY')})
                </p>
                {!isLoading && <BarChart options={getPopularPodcastsOptions(dashboardData)} series={getPopularPodcastsSeries(dashboardData)} />}
              </div>
            </div>
          )}
        </div>
      )} */}
    </div>
  );
};

export default Dashboard;
