import { Form, Input, Modal, Select, Switch } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ColorPicker } from '../../ColorPicker';
import Input1Png from '../../../../assets/images/appbuilder/Input1.png';
import Input2Png from '../../../../assets/images/appbuilder/Input2.png';

export type LayoutProps = {
  visible: boolean;
  onSubmit: (any, string) => void;
  onCancel: () => void;
  data: [] | any;
  type: string;
  theme: [] | any;
  buttons: [] | any;
  index: any;
};

const LayoutModel = (props: LayoutProps) => {
  const { visible, onSubmit, onCancel, data, type, theme, buttons } = props;
  const [form] = useForm();
const { t } = useTranslation();
  const [borderColor, setBorderColor] = useState('#000000');
  const [showBorderColorPicker, setShowBorderColorPicker] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('#000000');
  const [showBackgroundColorPicker, setShowBackgroundColorPicker] = useState(false);
  const [textColor, setTextColor] = useState('#ffffff');
  const [showTextColorPicker, setShowTextColorPicker] = useState(false);
  const [textType, setTexType] = useState("Normal");
  const [textBold, setTextBold] = useState(false);
  const [placeholderColor, setPlaceholderColor] = useState('#ffffff');
  const [showPlaceholderColorPicker, setShowPlaceholderColorPicker] = useState(false);
  const [iconColor, setIconColor] = useState('#ffffff');
  const [showIconColorPicker, setShowIconColorPicker] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [button, setButton] = useState([] as any);

  useEffect(() => {
    setIsLoading(true);
    if (data.background_color != undefined) {
      setIconColor(data.icon_color);
      setTextColor(data.text_color);
      setTextBold(data.text_bold);
      setTexType(data.text_size);
      setBackgroundColor(data.background_color);
      setBorderColor(data.border_color);
      setPlaceholderColor(data.placeholder_color);
      if (data.components != undefined) {
        setButton(data.components.button._id);
      }
      form.setFieldsValue(data);
    }
    setIsLoading(false);
  }, [data]);

  const onSubmitLocal = (values: any) => {
    var input = {
      ...data,
      name: values.name,
      background_color: backgroundColor,

      border_radius: values.border_radius ?? 0,
      border_width: values.border_width ?? 0,
      border_color: borderColor,

      text_color: textColor,
      text_size: textType, //Small, Normal, Large, Extra Large
      text_bold: textBold,

      placeholder_color: placeholderColor,
      icon_color: iconColor,

      components: {
        button: {
          _id: button,
        },
      },
    };

    onSubmit(input, type);
  };

  return (
    <Modal visible={visible} style={{  borderRadius:5}} width={'80%'} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="text-primary">{t('edit-input-settings')}</h4>

            <div className="row mt-5">
              <div className="col-12">
                {!isLoading && (
                  <Form form={form} onFinish={onSubmitLocal} onFinishFailed={(errorInfo) => {}}>
                    <div className="col" style={{ padding: 15 }}>
                      <div className="row">
                        <div className="col card-bg-light p-4 mb-4 mr-4" style={{ paddingLeft: 0 }}>
                          <h5 className="text-primary">{t('input')}</h5>
                          <p>{t('settings-that-define-the-object-such-as-background-color-borders-etc')}</p>
                          <div className="row mb-3 mt-4">
                            <label className="col-md-4 col-form-label">{t('name')}</label>
                            <div className="col-md-8" style={{ paddingLeft: 0 }}>
                              <Form.Item
                                name="name"
                                className="form-group pb-0 col"
                                rules={[
                                  {
                                    required: true,
                                    message: t('enter-button-name'),
                                  },
                                ]}
                              >
                                <Input className="form-control height-48 bg-white font-size-14 text-default" placeholder={t('give-your-input-a-name')} />
                              </Form.Item>
                            </div>
                            <div className="col"></div>
                            <div className="col"></div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-md-12">
                              <div className="row">
                                <label className="col-md-4 col-form-label">{t('button-1')}</label>
                                <div className="col" style={{ paddingLeft: 0 }}>
                                  <Form.Item className="form-group pb-0 col" name={['components', 'button', '_id']}>
                                    <Select onChange={setButton} className="default-select" placeholder={t('select-a-button-component')}>
                                      {buttons.map((button) => {
                                        return (
                                          <Select.Option value={button._id} key={button._id}>
                                            {button.name}
                                          </Select.Option>
                                        );
                                      })}
                                    </Select>
                                  </Form.Item>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="row">
                            <label className="col-md-4 col-form-label">Background</label>
                            <div className="col" style={{ paddingLeft: 0 }}>
                              <div className="form-group pb-0 col">
                                <div
                                  className="form-control height-48 font-size-14 text-default"
                                  onClick={() => {
                                    setShowBackgroundColorPicker(!showBackgroundColorPicker);
                                  }}
                                  style={{
                                    backgroundColor: backgroundColor,
                                    borderRadius: '5px',
                                    border: '#e9edf4 1px solid',
                                  }}
                                ></div>
                                <ColorPicker
                                  theme={theme}
                                  visible={showBackgroundColorPicker}
                                  color={backgroundColor}
                                  onChange={(color) => {
                                    setBackgroundColor(color);
                                    setShowBackgroundColorPicker(!showBackgroundColorPicker);
                                  }}
                                  setVisible={setShowBackgroundColorPicker}
                                />
                                <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                  COLOR
                                </div>
                              </div>
                            </div>
                            <div className="col"></div>
                            <div className="col"></div>
                          </div> */}
                          {/* <div className="row">
                            <label className="col-md-4 col-form-label">Border</label>

                            <div className="col-md-2" style={{ paddingLeft: 0 }}>
                              <Form.Item className="form-group pb-0 col" name="border_radius">
                                <Input defaultValue={0} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                              </Form.Item>
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                RADIUS
                              </div>
                            </div>
                            <div className="col-md-2">
                              <Form.Item className="form-group pb-0 col" name="border_width">
                                <Input defaultValue={0} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                              </Form.Item>
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                WIDTH
                              </div>
                            </div>
                            <div className="col-md-2" style={{ paddingRight: 0 }}>
                              <div className="form-group pb-0 col">
                                <div
                                  className="form-control height-48 font-size-14 text-default"
                                  onClick={() => {
                                    setShowBorderColorPicker(!showBorderColorPicker);
                                  }}
                                  style={{
                                    backgroundColor: borderColor,
                                    borderRadius: '5px',
                                    border: '#e9edf4 1px solid',
                                  }}
                                ></div>
                                <ColorPicker
                                  theme={theme}
                                  visible={showBorderColorPicker}
                                  color={borderColor}
                                  onChange={(color) => {
                                    setBorderColor(color);
                                    setShowBorderColorPicker(!showBorderColorPicker);
                                  }}
                                  setVisible={setShowBorderColorPicker}
                                />
                                <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                  COLOR
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>
                        <div className="col card-bg-light p-4 mb-4 mr-1" style={{ paddingLeft: 0 }}>
                          <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                            <img src={Input1Png} alt={''}></img>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col card-bg-light p-4 mb-4 mr-4" style={{ paddingLeft: 0 }}>
                          <h5 className="text-primary">{t('texts')}</h5>
                          <p>{t('settings-that-define-title-object-of-your-element')}</p>
                          <div className="row">
                            <label className="col-md-4 col-form-label">{t('text-color-0')}</label>
                            <div className="col" style={{ paddingLeft: 0 }}>
                              <div className="form-group pb-0 col">
                                <div
                                  className="form-control height-48 font-size-14 text-default"
                                  onClick={() => {
                                    setShowTextColorPicker(!showTextColorPicker);
                                  }}
                                  style={{
                                    backgroundColor: textColor,
                                    borderRadius: '5px',
                                    border: '#e9edf4 1px solid',
                                  }}
                                ></div>
                                <ColorPicker
                                  theme={theme}
                                  visible={showTextColorPicker}
                                  color={textColor}
                                  onChange={(color) => {
                                    setTextColor(color);
                                    setShowTextColorPicker(!showTextColorPicker);
                                  }}
                                  setVisible={setShowTextColorPicker}
                                />
                                <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                  COLOR
                                </div>
                              </div>
                            </div>
                            <div className="col"></div>
                            <div className="col"></div>
                          </div>
                          <div className="row">
                            <label className="col-md-4 col-form-label">{t('placeholder-text-color')}</label>
                            <div className="col" style={{ paddingLeft: 0 }}>
                              <div className="form-group pb-0 col">
                                <div
                                  className="form-control height-48 font-size-14 text-default"
                                  onClick={() => {
                                    setShowPlaceholderColorPicker(!showPlaceholderColorPicker);
                                  }}
                                  style={{
                                    backgroundColor: placeholderColor,
                                    borderRadius: '5px',
                                    border: '#e9edf4 1px solid',
                                  }}
                                ></div>
                                <ColorPicker
                                  theme={theme}
                                  visible={showPlaceholderColorPicker}
                                  color={placeholderColor}
                                  onChange={(color) => {
                                    setPlaceholderColor(color);
                                    setShowPlaceholderColorPicker(!showPlaceholderColorPicker);
                                  }}
                                  setVisible={setShowPlaceholderColorPicker}
                                />
                                <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                  COLOR
                                </div>
                              </div>
                            </div>
                            <div className="col"></div>
                            <div className="col"></div>
                          </div>
                          <div className="row">
                            <label className="col-md-4 col-form-label">{t('icon-color')}</label>
                            <div className="col" style={{ paddingLeft: 0 }}>
                              <div className="form-group pb-0 col">
                                <div
                                  className="form-control height-48 font-size-14 text-default"
                                  onClick={() => {
                                    setShowIconColorPicker(!showIconColorPicker);
                                  }}
                                  style={{
                                    backgroundColor: iconColor,
                                    borderRadius: '5px',
                                    border: '#e9edf4 1px solid',
                                  }}
                                ></div>
                                <ColorPicker
                                  theme={theme}
                                  visible={showIconColorPicker}
                                  color={iconColor}
                                  onChange={(color) => {
                                    setIconColor(color);
                                    setShowIconColorPicker(!showIconColorPicker);
                                  }}
                                  setVisible={setShowIconColorPicker}
                                />
                                <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                  COLOR
                                </div>
                              </div>
                            </div>
                            <div className="col"></div>
                            <div className="col"></div>
                          </div>

                          <div className="row">
                            <label className="col-md-4 col-form-label">{t('text-bold')}</label>

                            <div className="col">
                              <Form.Item className="form-group pb-0 " name="text_bold">
                                <Switch className="ch-switch" checked={textBold} defaultChecked={false} onChange={() => setTextBold(!textBold)} />
                              </Form.Item>
                            </div>
                            <div className="col"></div>
                            <div className="col"></div>
                          </div>
                        </div>
                        <div className="col card-bg-light p-4 mb-4 mr-1" style={{ paddingLeft: 0 }}>
                          <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                            <img src={Input2Png} alt={''}></img>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 p-0 mt-3">
                      <button type="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                        {type} {t('input')}
                      </button>
                    </div>
                  </Form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LayoutModel;
