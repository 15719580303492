import { Form, Modal, Select, Switch } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ColorPicker } from '../../ColorPicker';
import Text1Png from '../../../../assets/images/appbuilder/DetailsText.png';
import Text3Png from '../../../../assets/images/appbuilder/Text3.png';

export type LayoutProps = {
  visible: boolean;
  onSubmit: (component: [], name: string, index:any) => void;
  onCancel: () => void;
  data: [] | any;
  type: string;
  theme: [] | any;
  index: any;
};

const LayoutModel = (props: LayoutProps) => {
  const { visible, onSubmit, onCancel, data, type, theme } = props;
  const [form] = useForm();
const { t } = useTranslation();

  const [textNormalColor, setTextNormalColor] = useState('#ffffff');
  const [shoTextNormalColorPicker, setTextNormalColorPicker] = useState(false);

  const [textSubColor, setTextSubColor] = useState('#ffffff');
  const [shoTextSubColorPicker, setTextSubColorPicker] = useState(false);

  const [textNormalType, setTextNormalType] = useState('ExtraExtraLarge');
  const [textSubType, setTextSubType] = useState("Large");

  const [textNormalEnabled, setTextNormalEnabled] = useState(true);
  const [textSubEnabled, setTextSubEnabled] = useState(true);

  const [textNormalBold, setTextNormalBold] = useState(false);
  const [textSubBold, setTextSubBold] = useState(false);
  const [textMetaColor, setTextMetaColor] = useState('#ffffff');
  const [shoTextMetaColorPicker, setTextMetaColorPicker] = useState(false);
  const [textMetaType, setTextMetaType] = useState("Normal");
  const [textMetaBold, setTextMetaBold] = useState(false);
  const [textMetaEnabled, setTextMetaEnabled] = useState(true);
  const [textCrewColor, setTextCrewColor] = useState('#ffffff');
  const [shoTextCrewColorPicker, setTextCrewColorPicker] = useState(false);
  const [textCrewType, setTextCrewType] = useState("Normal");
  const [textCrewBold, setTextCrewBold] = useState(false);
  const [textCrewEnabled, setTextCrewEnabled] = useState(true);
  const [textPlacement, setTextPlacement] = useState("Below");

  useEffect(() => {
    if (data.texts != undefined) {
      setTextSubBold(data.texts.subtext_bold);
      setTextSubColor(data.texts.subtext_color);
      setTextSubType(data.texts.subtext_size);

      setTextNormalBold(data.texts.title_bold);
      setTextNormalColor(data.texts.title_color);
      setTextNormalType(data.texts.title_size);

      setTextNormalEnabled(data.texts.show_title);
      setTextSubEnabled(data.texts.show_subtext);

      setTextMetaColor(data.texts.subsubtext_color);
      setTextMetaType(data.texts.metatext_size);
      setTextMetaBold(data.texts.subsubtext_bold);

      setTextCrewColor(data.texts.subsubsubtext_color);
      setTextCrewType(data.texts.casttext_size);
      setTextCrewBold(data.texts.subsubsubtext_bold);

      setTextCrewEnabled(data.texts.show_subsubtext);
      setTextMetaEnabled(data.texts.show_subsubsubtext);

      setTextPlacement(data.texts.placement_text);

      form.setFieldsValue(data.texts);
    }
  }, [data, form]);

  const onSubmitLocal = (values: any) => {
    var texts = {
      title_color: textNormalColor,
      title_size: textNormalType, //Small, Normal, Large, Extra Large
      title_bold: textNormalBold,

      subtext_color: textSubColor,
      subtext_size: textSubType, //Small, Normal, Large, Extra Large
      subtext_bold: textSubBold,

      subsubtext_color: textMetaColor,
      subsubtext_size: textMetaType, //Small, Normal, Large, Extra Large
      subsubtext_bold: textMetaBold,

      subsubsubtext_color: textCrewColor,
      subsubsubtext_size: textCrewType, //Small, Normal, Large, Extra Large
      subsubsubtext_bold: textCrewBold,

      show_title: textNormalEnabled,
      show_subtext: textSubEnabled,
      show_subsubtext: textMetaEnabled,
      show_subsubsubtext: textCrewEnabled,

      placement_text: textPlacement, //on image, below image
    };
    let dataOut = {
      ...data,
      texts: texts,
    };
    onSubmit(dataOut, type, props.index);
  };

  const setTextPlacementLocal = (value: any) => {
    setTextPlacement(value);
  };

  return (
    <Modal visible={visible} style={{  borderRadius:5}} width={'80%'} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="text-primary">{t('edit-text-settings')}</h4>

            <div className="row mt-5">
              <div className="col-12">
                <Form form={form} onFinish={onSubmitLocal} onFinishFailed={(errorInfo) => {}}>
                  <div className="col">
                    <div className="row">
                      <div className="col card-bg-light p-4 mb-4 mr-4" style={{ paddingLeft: 0 }}>
                        <h5 className="text-primary">{t('title-text')}</h5>
                        <p>{t('settings-that-define-title-object-of-your-element')}</p>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('text-color-0')}</label>
                          <div className="col" style={{ paddingLeft: 0 }}>
                            <div className="form-group pb-0 col">
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setTextNormalColorPicker(!shoTextNormalColorPicker);
                                }}
                                style={{
                                  backgroundColor: textNormalColor,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={shoTextNormalColorPicker}
                                color={textNormalColor}
                                onChange={(color) => {
                                  setTextNormalColor(color);
                                  setTextNormalColorPicker(!shoTextNormalColorPicker);
                                }}
                                setVisible={setTextNormalColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                COLOR
                              </div>
                            </div>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>

                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('text-bold')}</label>

                          <div className="col">
                            <Form.Item className="form-group pb-0 " name="title_bold">
                              <Switch className="ch-texts mt-2" checked={textNormalBold} defaultChecked={false} onChange={() => setTextNormalBold(!textNormalBold)} />
                            </Form.Item>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('show-text-0')}</label>

                          <div className="col">
                            <Form.Item className="form-group pb-0" name="show_title">
                              <Switch className="ch-texts mt-2" checked={textNormalEnabled} defaultChecked={true} onChange={() => setTextNormalEnabled(!textNormalEnabled)} />
                            </Form.Item>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                      </div>
                      <div className="col card-bg-light p-4 mb-4 mr-1" style={{ paddingLeft: 0 }}>
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                          <img src={Text1Png} alt={''}></img>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col card-bg-light p-4 mb-4 mr-4" style={{ paddingLeft: 0 }}>
                        <h5 className="text-primary">{t('description-text')}</h5>
                        <p>{t('settings-that-define-sub-text-object-of-your-element')}</p>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('text-color-0')}</label>
                          <div className="col" style={{ paddingLeft: 0 }}>
                            <div className="form-group pb-0 col">
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setTextSubColorPicker(!shoTextSubColorPicker);
                                }}
                                style={{
                                  backgroundColor: textSubColor,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={shoTextSubColorPicker}
                                color={textSubColor}
                                onChange={(color) => {
                                  setTextSubColor(color);
                                  setTextSubColorPicker(!shoTextSubColorPicker);
                                }}
                                setVisible={setTextSubColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                COLOR
                              </div>
                            </div>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>

                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('text-bold')}</label>

                          <div className="col">
                            <Form.Item className="form-group pb-0" name="subtext_bold">
                              <Switch className="ch-texts mt-2" checked={textSubBold} defaultChecked={false} onChange={() => setTextSubBold(!textSubBold)} />
                            </Form.Item>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('show-text-0')}</label>

                          <div className="col">
                            <Form.Item className="form-group pb-0" name="show_subtext">
                              <Switch className="ch-texts mt-2" checked={textSubEnabled} defaultChecked={true} onChange={() => setTextSubEnabled(!textSubEnabled)} />
                            </Form.Item>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                      </div>
                      <div className="col card-bg-light p-4 mb-4 mr-1" style={{ paddingLeft: 0 }}>
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                          <img src={Text1Png} alt={''}></img>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col card-bg-light p-4 mb-4 mr-4" style={{ paddingLeft: 0 }}>
                        <h5 className="text-primary">{t('meta-text')}</h5>
                        <p>{t('settings-that-define-title-object-of-your-element')}</p>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('text-color-0')}</label>
                          <div className="col" style={{ paddingLeft: 0 }}>
                            <div className="form-group pb-0 col">
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setTextMetaColorPicker(!shoTextMetaColorPicker);
                                }}
                                style={{
                                  backgroundColor: textMetaColor,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={shoTextMetaColorPicker}
                                color={textMetaColor}
                                onChange={(color) => {
                                  setTextMetaColor(color);
                                  setTextMetaColorPicker(!shoTextMetaColorPicker);
                                }}
                                setVisible={setTextMetaColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                COLOR
                              </div>
                            </div>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>

                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('text-bold')}</label>

                          <div className="col">
                            <Form.Item className="form-group pb-0 " name="subsubtext_bold">
                              <Switch className="ch-texts mt-2" checked={textMetaBold} defaultChecked={false} onChange={() => setTextMetaBold(!textMetaBold)} />
                            </Form.Item>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('show-text-0')}</label>

                          <div className="col">
                            <Form.Item className="form-group pb-0" name="show_subsubtext">
                              <Switch className="ch-texts mt-2" checked={textMetaEnabled} defaultChecked={true} onChange={() => setTextMetaEnabled(!textMetaEnabled)} />
                            </Form.Item>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                      </div>
                      <div className="col card-bg-light p-4 mb-4 mr-1" style={{ paddingLeft: 0 }}>
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                          <img src={Text1Png} alt={''}></img>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col card-bg-light p-4 mb-4 mr-4" style={{ paddingLeft: 0 }}>
                        <h5 className="text-primary">{t('cast-crew-teacher-text')}</h5>
                        <p>{t('settings-that-define-sub-text-object-of-your-element')}</p>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('text-color-0')}</label>
                          <div className="col" style={{ paddingLeft: 0 }}>
                            <div className="form-group pb-0 col">
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setTextCrewColorPicker(!shoTextCrewColorPicker);
                                }}
                                style={{
                                  backgroundColor: textCrewColor,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={shoTextCrewColorPicker}
                                color={textSubColor}
                                onChange={(color) => {
                                  setTextCrewColor(color);
                                  setTextCrewColorPicker(!shoTextCrewColorPicker);
                                }}
                                setVisible={setTextCrewColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                COLOR
                              </div>
                            </div>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>

                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('text-bold')}</label>

                          <div className="col">
                            <Form.Item className="form-group pb-0" name="subsubsubtext_bold">
                              <Switch className="ch-texts mt-2" checked={textCrewBold} defaultChecked={false} onChange={() => setTextCrewBold(!textCrewBold)} />
                            </Form.Item>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('show-text-0')}</label>

                          <div className="col">
                            <Form.Item className="form-group pb-0" name="show_subsubsubtext">
                              <Switch className="ch-texts mt-2" checked={textCrewEnabled} defaultChecked={true} onChange={() => setTextCrewEnabled(!textCrewEnabled)} />
                            </Form.Item>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                      </div>
                      <div className="col card-bg-light p-4 mb-4 mr-1" style={{ paddingLeft: 0 }}>
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                          <img src={Text1Png} alt={''}></img>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col card-bg-light p-4 mb-4 mr-4">
                        <h5 className="text-primary">{t('text-placement')}</h5>
                        <p>{t('settings-that-define-sub-text-object-of-your-element')}</p>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('placement')}</label>
                          <div className="col" style={{ paddingLeft: 15 }}>
                            <Form.Item name={['placement']}>
                              <Select defaultValue={textPlacement} onChange={setTextPlacementLocal} className="default-select" placeholder={t('select-type')}>
                                <Select.Option value={"Hide"} key={'Hide'}>
                                  {t('hide-0')}
                                </Select.Option>
                                <Select.Option value={"Below"} key={'Below'}>
                                  {t('below-0')}
                                </Select.Option>
                                <Select.Option value={"Above"} key={'Above'}>
                                  {t('above')}
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div className="col card-bg-light p-4 mb-4 mr-1">
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                          <img src={Text3Png} alt={''}></img>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 p-0 mt-3">
                    <button type="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-240 height-48">
                      {t('update-text')}
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LayoutModel;
