import * as xlsx from 'xlsx';
import { Button, Form, Input, notification, Select, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useContext, useState } from 'react';
import { useAddMutation } from '../../../../../services/areas/customers/customerService';
import { IMSNameParams } from '../../../../dashboard';
import { CustomersContext } from '../../../../../contexts/customersContext';
import { Customer } from '../../../../../interfaces/forms/areas/customers/customers/customer';
import { DownloadLink } from '../../../../dashboard/pages/home/examples/components/downloadLink';
import { useProduct, useProducts } from '../../../../../services/areas/billing/productService';
import { CustomerSubscriptionStatus } from '@mwaretv/database/build/backend/enums/customers/customerSubscriptionStatus';
import { CustomerProductStatus } from '@mwaretv/database/build/backend/enums/customers/customerProductStatus';

const ImporterExcel = () => {
  const [form] = useForm();
  const { t } = useTranslation();
  const { clientName } = useParams<IMSNameParams>();
  const { service, deployment } = useContext(CustomersContext);

  const history = useHistory();
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -2).join('/');

  const [columns, setColumns] = useState([] as string[]);
  const [excel, setExcel] = useState([] as any);

  const customerKeys = [
    { name: 'title', displayName: t('title') },
    { name: 'username', displayName: t('username') },
    { name: 'password', displayName: t('password') },
    { name: 'firstname', displayName: t('first-name-0') },
    { name: 'lastname', displayName: t('last-name-0') },
    { name: 'email', displayName: t('email') },
    { name: 'country', displayName: t('country') },
    { name: 'baseproductid', displayName: t('base-product-id') },
    { name: 'planid', displayName: t('plan-id') },
    { name: 'cartid', displayName: t('cart-id') },
    { name: 'allowlogin', displayName: t('allow-portal-access') },
    { name: 'activationdate', displayName: t('activation-date') },
    { name: 'expirationdate', displayName: t('expiration-date') },
    { name: 'billingtaxrate', displayName: t('billing-taxrate') },
    { name: 'billingcurrency', displayName: t('billing-currency') },
    { name: 'billingcountry', displayName: t('billing-country') },
  ];

  const customerOptions = [
    { name: 'street', displayName: t('street') },
    { name: 'zipcode', displayName: t('zipcode') },
    { name: 'state', displayName: t('state') },
    { name: 'city', displayName: t('city') },
    { name: 'mobile', displayName: t('mobile-3') },
    { name: 'phone', displayName: t('phone') },
  ];

  const { isLoading, mutate } = useAddMutation(
    { params: { cms: deployment?.name ?? '', instance: clientName, crm: service?.name ?? '' } },
    {
      onSuccess: () => {
        notification.success({ message: t('customer-saved') });
      },
      onError: (e) => {
        notification.success({ message: t('customer-could-not-be-saved-please-try-again-later') + ' ' + e });
      },
    }
  );

  const readUploadFile = (file: any) => {
    file.preventDefault();
    if (file.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = (progresEvent: ProgressEvent<FileReader>) => {
        if (progresEvent.target !== null) {
          const data = progresEvent.target.result;
          const workbook = xlsx.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = xlsx.utils.sheet_to_json(worksheet);
          const objectJson: any = json[0];
          const columns = [...Object.keys(objectJson), 'Empty_Value'];
          setColumns(columns);
          setExcel(json);
        }
      };
      reader.readAsArrayBuffer(file.target.files[0]);
    } else {
      setExcel([]);
    }
  };

  const getObjectKeyValue = (row: any, selectedKeyIndex: any, isBoolean?: boolean) => {
    if (selectedKeyIndex !== undefined) {
      if (isBoolean) {
        let value = Object.values(row)[selectedKeyIndex];
        if (typeof value === 'string' && (value.toLowerCase() === 'true' || Number(value) === 1)) {
          value = true;
        } else if (typeof value === 'string' && (value.toLowerCase() === 'false' || Number(value) === 0)) {
          value = false;
        }
        if (typeof value !== 'boolean') {
          value = false;
        }
        return value;
      } else {
        return Object.values(row)[selectedKeyIndex];
      }
    } else {
      return null;
    }
  };
  const { data: products } = useProducts({ params: { instance: clientName, cms: deployment?.name ?? '', crm: service?.name ?? '', limit: 9999, page: 1, filter: { type: 'BASE' } } });

  const handleSubmit = (values: any) => {
    const optionalValues = Object.fromEntries(Object.entries(values).filter(([_, value]) => value !== undefined));
    excel.forEach((row: string) => {
      var product = products?.data.docs.find((p) => p._id === (getObjectKeyValue(row, values.baseproductid) as any).toString());
      var plan = product?.plans?.plans.find((p) => p._id === (getObjectKeyValue(row, values.planid) as any).toString());

      const customer: Customer = {
        title: (getObjectKeyValue(row, values.title) as any).toString(),
        username: (getObjectKeyValue(row, values.username) as any).toString(),
        password: (getObjectKeyValue(row, values.password) as any).toString(),
        password_app: (getObjectKeyValue(row, values.password) as any).toString(),
        first_name: (getObjectKeyValue(row, values.firstname) as any).toString(),
        last_name: (getObjectKeyValue(row, values.lastname) as any).toString(),
        email: (getObjectKeyValue(row, values.email) as any).toString(),
        country: (getObjectKeyValue(row, values.country) as any).toString(),
        subscriptions: {
          base: {
            product: product,
            plan: plan,
            activation_date: Number(getObjectKeyValue(row, values.activationdate) as any),
            expiration_date: Number(getObjectKeyValue(row, values.expirationdate) as any),
          },
        },
        billing_country: (getObjectKeyValue(row, values.billingtaxrate) as any).toString(),
        billing_currency: (getObjectKeyValue(row, values.billingcurrency) as any).toString(),
        billing_taxrate: getObjectKeyValue(row, values.billingtaxrate) as any,
        can_login: Boolean(getObjectKeyValue(row, values.allowlogin) as any),
        is_deleted: false,
        status: 'active',
        account_type: 'personal',
        subscriptionStatus: CustomerSubscriptionStatus.ACTIVE,
        productStatus: CustomerProductStatus.REGULAR,
        shopping_cart: (getObjectKeyValue(row, values.cartid) as any).toString()
      };

      ['street', 'zipcode', 'state', 'city', 'mobile', 'phone'].forEach((key) => (key in optionalValues ? (customer[key] = getObjectKeyValue(row, optionalValues[key])) : null));
    
       mutate(customer)
    });

    history.push(redirectUrl + '/customers/list');
  };


  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-12">
          <h2 className="page-title">{t('excel-import')}</h2>
          <p className="h6 font-weight-normal line-height-26">{t('import-your-customer-via-excel-files-through-an-easy-to-use-mapping-tool')}</p>
        </div>
      </div>

      <Form
        form={form}
        onFinish={(values: any) => {
          handleSubmit(values);
        }}
        onFinishFailed={(errorInfo) => {}}
      >
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <div className="card-bg-light pl-5 pt-4 mt-4">
              <h4 className="text-primary mb-2" style={{ marginTop: 30 }}>
                {t('how-does-it-work')}
              </h4>
              <div className="col mb-5 mt-4" style={{ paddingLeft: 0 }}>
                <div className="col mb-2" style={{ paddingLeft: 0 }}>
                  <div>
                    <b>{t('file-types')}</b>
                  </div>
                  <div>{t('we-only-accept-excel-xlsx-documents-for-importing')}</div>
                </div>
                <div className="col mb-2" style={{ paddingLeft: 0 }}>
                  <div>
                    <b>{t('file-setup')}</b>
                  </div>
                  <div>
                    {t('make-sure-the-first-row-in-your-excel-contains-the-column-names-for-example-username-password-etc')}
                    <br />
                  </div>
                </div>

                <div className="col mb-2" style={{ paddingLeft: 0 }}>
                  <div>
                    <b>{t('easy-to-use-mappings')}</b>
                  </div>
                  <div>
                    {t('the-import-tool-lets-you-map-your-columns-with-the-tvms-required-data-fields-should-your-excel-not-be-able-to-fill')}
                    <br />
                    {t('the-required-data-field-with-a-column-then-its-always-possible-to-add-an-overide-default-value')}
                  </div>
                </div>
              </div>
            </div>
            <div className="card-bg-light pl-5 pr-5 pt-4 mt-4">
              <h4 className="text-primary mb-2" style={{ marginTop: 20 }}>
                {t('upload-your-excel-xlsx')}
              </h4>
              <div className="col mb-4" style={{ paddingLeft: 0 }}>
                {t('download-the-example-excel-to-get-you-started')} <DownloadLink fileName="Customers.xlsx" displayName={t('customers')} />
              </div>
              <div className="row-md-5 mb-5">
                <Input className="form-control bg-white text-default col-md-7 btn-secondary" type="file" name="upload" id="upload" onChange={(e) => readUploadFile(e)} />
              </div>
              {isLoading && (
                <div className="card-bg-light p-5 mt-4">
                  <div className="pl-2 pb-4">
                    <div className="row text-center h-100 w-100">
                      <div className="col-12 align-self-center">
                        <Spin size="large" spinning={true} />
                        <p>{t('do-not-refresh-your-browser-it-would-break-the-import-process-0')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {excel.length > 0 && !isLoading && (
                <div>
                  <h4 className="text-primary mt-4">{t('excel-model-mappings')}</h4>
                  <p>
                    {t('use-the-dropdowns-on-the-left-side-to-map-your-excel-columns-with-our-data-model-or-use-the-overrides')}
                    <br />
                    {t('to-set-the-data-fields-you-are-missing-from-your-excel')}
                  </p>

                  <div className="card-bg-light p-5 mb-3 bg-white">
                    <div className="row ">
                      <div className="col-md-6">
                        <h4 className="text-primary">{t('general')}</h4>
                      </div>
                    </div>

                    {customerKeys.map((key: any, index: number) => {
                      return (
                        <div className="row" key={index + key.name}>
                          <label className="col-md-3 col-form-label">{key.displayName}</label>
                          <div className="col-md-5">
                            <Form.Item
                              name={key.name}
                              rules={[
                                {
                                  required: true,
                                  message: t('mapping-is-required'),
                                },
                              ]}
                            >
                              <Select className="default-select" defaultActiveFirstOption={true}>
                                {columns.map((value: any, index: any) => {
                                  if (value === 'Empty_Value') {
                                    return null;
                                  }
                                  return (
                                    <Select.Option value={index} key={index + key.name}>
                                      {value}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <div className="card-bg-light p-5 mb-3 bg-white">
                    <div className="row ">
                      <div className="col-md-6">
                        <h4 className="text-primary">{t('optional')}</h4>
                      </div>
                    </div>
                    {customerOptions.map((key: any, index: number) => {
                      return (
                        <div className="row" key={index + key.name}>
                          <label className="col-md-3 col-form-label">{key.displayName}</label>
                          <div className="col-md-5">
                            <Form.Item name={key.name}>
                              <Select className="default-select" defaultActiveFirstOption={true}>
                                {columns.map((value: any, index: any) => {
                                  if (value === 'Empty_Value') {
                                    return null;
                                  }
                                  return (
                                    <Select.Option value={index} key={index + key.name}>
                                      {value}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="text-right">
          {excel.length > 0 && (
            <Button htmlType="submit" className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
              {t('import-content')}
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};
export default ImporterExcel;
