import { Form, Input, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ColorPicker } from '../../ColorPicker';
import GeneralPng from '../../../../assets/images/appbuilder/General.png';

export type LayoutProps = {
  visible: boolean;
  onSubmit: (component: [], name: string, index:any) => void;
  onCancel: () => void;
  data: [] | any;
  type: string;
  theme: [] | any;
  index: any;
};

const LayoutModel = (props: LayoutProps) => {
  const { visible, onSubmit, onCancel, data, type, theme } = props;
  const [form2] = useForm();
  const [borderColor, setBorderColor] = useState('#000000');
  const [showBorderColorPicker, setShowBorderColorPicker] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('#000000');
  const [showBackgroundColorPicker, setShowBackgroundColorPicker] = useState(false);
  const { t } = useTranslation();
  
  useEffect(() => {
    if (data.general != undefined) {
      setBackgroundColor(data.general.background_color);
      setBorderColor(data.general.border_color);
      form2.setFieldsValue(data.general);
    }
  }, [data]);

  const onSubmitLocal = (values: any) => {
    var general = {
      background_color: backgroundColor,
      border_radius: values.border_radius ?? 0,
      border_width: values.border_width ?? 0,
      border_color: borderColor,
      margin_top: values.margin_top ?? 0,
      margin_bottom: values.margin_bottom ?? 0,
      margin_left: values.margin_left ?? 0,
      margin_right: values.margin_right ?? 0,
    };
    let dataOut = {
      ...data,
      general: general,
    };
    onSubmit(dataOut, type, props.index);
  };

  return (
    <Modal visible={visible} style={{  borderRadius:5}} width={'80%'} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="text-primary">{t('edit-general-settings')}</h4>
            <p>
              {t('change-the-general-settings-of-this-component-any-changes-will-reflect-on-this-application-only')}
              <br />
              {t('if-you-want-it-to-reflect-on-the-others-to-then-press-publish-from-the-application-list')}{' '}
            </p>
            <div className="row mt-5">
              <div className="col-12">
                <Form form={form2} onFinish={onSubmitLocal} onFinishFailed={(errorInfo) => {}}>
                  <div className="row" style={{ padding: 15 }}>
                    <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                      <h5 className="text-primary">{t('general')}</h5>
                      <p>{t('settings-that-define-the-object-such-as-background-color-borders-etc')}</p>
                      <div className="row">
                        <label className="col-md-4 col-form-label">{t('background-1')}</label>
                        <div className="col" style={{ paddingLeft: 0 }}>
                          <div className="form-group pb-0 col">
                            <div
                              className="form-control height-48 font-size-14 text-default"
                              onClick={() => {
                                setShowBackgroundColorPicker(!showBackgroundColorPicker);
                              }}
                              style={{
                                backgroundColor: backgroundColor,
                                borderRadius: '5px',
                                border: '#e9edf4 1px solid',
                              }}
                            ></div>
                            <ColorPicker
                              theme={theme}
                              visible={showBackgroundColorPicker}
                              color={backgroundColor}
                              onChange={(color) => {
                                setBackgroundColor(color);
                                setShowBackgroundColorPicker(!showBackgroundColorPicker);
                              }}
                              setVisible={setShowBackgroundColorPicker}
                            />
                            <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                              COLOR
                            </div>
                          </div>
                        </div>
                        <div className="col"></div>
                        <div className="col"></div>
                      </div>
                      <div className="row">
                        <label className="col-md-4 col-form-label">{t('border-2')}</label>
                        <div className="col-md-2" style={{ paddingLeft: 0 }}>
                          <Form.Item className="form-group pb-0 col" name="border_radius">
                            <Input defaultValue={0} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                          </Form.Item>
                          <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                            RADIUS
                          </div>
                        </div>
                        <div className="col-md-2">
                          <Form.Item className="form-group pb-0 col" name="border_width">
                            <Input defaultValue={0} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                          </Form.Item>
                          <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                            WIDTH
                          </div>
                        </div>
                        <div className="col-md-2" style={{ paddingRight: 0 }}>
                          <div className="form-group pb-0 col">
                            <div
                              className="form-control height-48 font-size-14 text-default"
                              onClick={() => {
                                setShowBorderColorPicker(!showBorderColorPicker);
                              }}
                              style={{
                                backgroundColor: borderColor,
                                borderRadius: '5px',
                                border: '#e9edf4 1px solid',
                              }}
                            ></div>
                            <ColorPicker
                              theme={theme}
                              visible={showBorderColorPicker}
                              color={borderColor}
                              onChange={(color) => {
                                setBorderColor(color);
                                setShowBorderColorPicker(!showBorderColorPicker);
                              }}
                              setVisible={setShowBorderColorPicker}
                            />
                            <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                              COLOR
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <label className="col-md-4 col-form-label"></label>
                        <div className="col"></div>
                        <div className="col" style={{ paddingLeft: 0 }}>
                          <Form.Item className="form-group pb-0 col" name="margin_top">
                            <Input defaultValue={0} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                          </Form.Item>
                          <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                            {t('top-margin')}
                          </div>
                        </div>

                        <div className="col"></div>
                      </div>
                      <div className="row">
                        <label className="col-md-4 col-form-label">{t('placement')}</label>

                        <div className="col" style={{ paddingLeft: 0 }}>
                          <Form.Item className="form-group pb-0 col" name="margin_left">
                            <Input defaultValue={0} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                          </Form.Item>
                          <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                            {t('left-margin')}
                          </div>
                        </div>

                        <div className="col"></div>
                        <div className="col" style={{ paddingLeft: 0 }}>
                          <Form.Item className="form-group pb-0 col" name="margin_right">
                            <Input defaultValue={0} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                          </Form.Item>
                          <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                            {t('right-margin')}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <label className="col-md-4 col-form-label"></label>
                        <div className="col"></div>
                        <div className="col" style={{ paddingLeft: 0 }}>
                          <Form.Item className="form-group pb-0 col" name="margin_bottom">
                            <Input defaultValue={0} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                          </Form.Item>
                          <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                            {t('bottom-margin')}
                          </div>
                        </div>

                        <div className="col"></div>
                      </div>
                    </div>
                    <div className="col card-bg-light p-4 mb-2 ml-1" style={{ paddingLeft: 0 }}>
                      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                        <img src={GeneralPng} alt={''}></img>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 p-0 mt-3">
                    <button type="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-240 height-48">
                      {t('update-general')}
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LayoutModel;
