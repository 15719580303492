import { Card } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import NewDeployementIcon from '../../../../../../../assets/images/new_dep.png';
import { useTranslation } from 'react-i18next';

export function NewDeployment() {
  const { url } = useRouteMatch();
  const {t} = useTranslation();
  return (
    <div className="row">
      <div className="col-12 mb-2">
        <Card hoverable className="p-0" bodyStyle={{ padding: '0px' }}>
          <div className="py-4 p-4 border-left border-secondary border-width-4">
            <div className="row">
              <div className="col-md-3 col-lg-2 col-xl-1">
                <img src={NewDeployementIcon} title="NewDeployementIcon" alt="NewDeployementIcon" />
              </div>
              <div className="col-md-6 col-lg-6 col-xl-8 pl-xl-5 mt-3 mt-lg-0">
                <h4 className="text-primary">{t('add-deployment-and-service')}</h4>
                <p className="h6 font-weight-normal line-height-26" style={{ color: '#888' }}>
                  {t('adding-a-deployment-content-and-apps-and-a-first-service-customers-billing-marketing-and-reports')}
                </p>
              </div>
              <div className="col-md-3 d-flex justify-content-lg-end justify-content-center align-items-center mt-3 mt-lg-0">
                <Link className="btn btn-secondary rounded-pill btn-default" to={`${url}/new-deployment`}>
                  {t('add-deployment-and-service-0')}
                </Link>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}
