import { Form, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ParentalControl = (params: { setParentalControl: any; data?: any }) => {
  const [enableParental, setEnableParental] = useState(false);
  const [rating, setRating] = useState([] as any);
  const {t} = useTranslation();
  useEffect(() => {
    if (params.data != undefined) {
      setEnableParental(params.data.enabled ?? false);
      setRating(params.data.rating);
    }
  }, [params.data]);

  return (
    <div>
      <h4 className="text-primary mt-5 mb-3">{t('parental-control-6')}</h4>
      <div className="">
        <div className="col-md-12 card-bg-light">
          <div className="col-md-12 p-3 ">
            <div className="">
              <div className="card-body card-col-width rounded">
                <div className="row">
                  <label className="col-md-3 col-form-label">{t('disable-enable-protection')}</label>
                  <Form.Item extra={t('enabling-this-will-make-the-app-ask-for-a-pin-before-access-to-the-content-is-granted')} className="col-md-5 pb-0 mb-0" name={['parental', 'enabled']}>
                    <Switch
                      defaultChecked={false}
                      onChange={(checked) => {
                        setEnableParental(checked);
                        params.setParentalControl({
                          enabled: checked,
                          rating: rating,
                        });
                      }}
                      className="mt-2"
                      checked={enableParental}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParentalControl;
