
import { IPublishBundle } from '../../interfaces/forms/common/IPublishBundle';
import { IUploadImage } from '../../interfaces/forms/common/IUploadImage';
import { ICitiesResponse } from '../../interfaces/responses/common/ICitiesResponse';
import { ICommonMenusListResponse } from '../../interfaces/responses/common/ICommonMenusResponse';
import { ICountriesResponse } from '../../interfaces/responses/common/ICountriesResponse';
import { ICurrenciesResponse } from '../../interfaces/responses/common/ICurrenciesResponse';
import { IFileUploadResponse } from '../../interfaces/responses/common/IFileUploadResult';
import { ILanguageResponse } from '../../interfaces/responses/common/ILanguagesResponse';
import { default as axios, default as axiosInstance } from '../../utils/axios';
import { API_ENDPOINTS } from '../_APIConstants';

class CommonService {
  UploadImage = (data: IUploadImage): Promise<IFileUploadResponse> => {
    let formData = new FormData();
    formData.append('image', data.image, data.query_params.name);
    return axios
      .post(API_ENDPOINTS.V1.Common.UPLOAD_IMAGE, formData, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  UploadFile = (data: IUploadImage): Promise<IFileUploadResponse> => {
    let formData = new FormData();
    formData.append('image', data.image, data.query_params.name);
    return axios
      .post(API_ENDPOINTS.V1.Common.UPLOAD_FILE, formData, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  // UploadObjectStorage = (data: IUploadImage): Promise<IFileUploadResponse> => {
  //   let formData = new FormData();
  //   formData.append('image', data.image, data.query_params.name);
  //   return axios
  //     .post(API_ENDPOINTS.V1.Common.UPLOAD_OBJECTSTORAGE, formData, {
  //       params: data.query_params,
  //     })
  //     .then((response) => {
  //       return response.data;
  //     })
  //     .catch((err) => {
  //       return err;
  //     });
  // };

  PublishBundle = (data: IPublishBundle): Promise<IFileUploadResponse> => {
    return axios
      .post(API_ENDPOINTS.V1.Common.UPLOAD_FILE, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };


  FetchCountries = async (): Promise<ICountriesResponse> => {
    return axios
      .get(API_ENDPOINTS.V1.Common.FETCH_COUNTRIES)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  FetchCitiesForCountry = async (countryIso3: string): Promise<ICitiesResponse> => {
    return axios
      .get(`${API_ENDPOINTS.V1.Common.FETCH_CITIES}/${countryIso3}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  FetchCurrencies = async (): Promise<ICurrenciesResponse> => {
    return axios
      .get(API_ENDPOINTS.V1.Common.FETCH_CURRENCIES)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  FetchLanguages = async (): Promise<ILanguageResponse> => {
    return axios
      .get(API_ENDPOINTS.V1.Common.FETCH_LANGUAGES)
      .then((response) => {
        
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  // TranslateText = async (
  //   data: ITranslateText
  // ): Promise<IGetTranslatedTextReponse> => {
  //   return await axiosInstance
  //     .post(API_ENDPOINTS.V1.Common.TRANSLATE_TEXT, data, {
  //       params: data.query_params,
  //     })
  //     .then((res) => res.data)
  //     .catch((err) => err);
  // };
  GetBaseMenus = async (): Promise<ICommonMenusListResponse> => {
    return await axiosInstance
      .get(API_ENDPOINTS.V1.Common.BASE_MENUS_LIST)
      .then((res) => res.data)
      .catch((error) => error);
  };
  // GetFontsList = async (): Promise<IFontListResponse> => {
  //   return await axiosInstance
  //     .get(API_ENDPOINTS.V1.Common.GET_ALL_FONTS_LIST)
  //     .then((res) => res.data)
  //     .catch((err) => err);
  // };
  // FetchWhiteLabelSettings = async (client_name: string) => {
  //   let response = await ims.getWhiteLabelDetails({
  //     client_name,
  //   });
  //   if (response && response.status_code === StatusCodes.OK) {
  //     return response.data;
  //   }
  //   return null;
  // };
}
export default new CommonService();
