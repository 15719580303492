import { Button, Form, notification, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { StatusCodes } from 'http-status-codes';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { CustomNotification } from '../../Notification';
import { AppBuilderContext } from '../../../../contexts/appbuilderContext';
import { IMSNameParams } from '../../../../pages/dashboard';
import appService from '../../../../services/areas/appbuilder/applications/application';
import appDisclaimer from '../../../../services/areas/appbuilder/applications/disclaimer';
import standard from '../../../../assets/images/appbuilder/Disclaimer.png';
import EditText from '../Edit/Edit_Text';
import EditHeader from '../Edit/Edit_Header';
import EditComponents from '../Edit/Edit_Components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBlockBrick, faHeader } from '@fortawesome/pro-regular-svg-icons';
import { STORAGE_ENDPOINTS } from '../../../../services/storage_paths';

const Authentication = () => {
  const [application, setApplication] = useState([] as any);
  const [theme, setTheme] = useState([] as any);
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(AppBuilderContext);
  const [form] = useForm();
const { t } = useTranslation();
  const { id } = useParams<any>();
  const [formDetails, setFormDetails] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);
  const [template, setTemplate] = useState("Standard");
  const [text, setText] = useState([] as any);
  const [settings, setSettings] = useState({
    type: "Standard", //
    text: '', //in case of text
    header: {
      title_color: '#ffffff',
      title_size: 'ExtraExtraLarge', //Small, Normal, Large, Extra Large
      title_bold: false,

      sub_color: '#ffffff',
      sub_size: "Normal", //Small, Normal, Large, Extra Large
      sub_bold: false,
    },
    components: {
      button: {
        _id: '',
      },
    },
  });
  const [editData, setEditData] = useState([] as any);
  const [editType, setEditType] = useState('');

  const [EditTextMessageModal, setEditTextMessageModal] = useState(false);
  const [EditShowHeaderModal, setEditShowHeaderModal] = useState(false);
  const [editComponentModal, setEditComponentModal] = useState(false);
  const [disclaimers, setDisclaimers] = useState([[] as any]);

  const editComponent = async (data: any) => {
    let response = await appService.Update_Application({
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, '', application.branding.packageid).APPBUILDER.APP,
      },
      _id: id,
      client: clientName,
      deployment: deployment?.name ?? '',
      ...application,
      authentication: {
        ...application.authentication,
        disclaimer: {
          ...data,
        },
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      notification.success({
        message: t('updated-successfully'),
      });
      setSettings(data);
      setEditTextMessageModal(false);
      setEditShowHeaderModal(false);
      setEditComponentModal(false);
    }
  };

  const handleSubmit = async (values: any) => {
    setIsLoading(true);
    let response = await appService.Update_Application({
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, '', application.branding.packageid).APPBUILDER.APP,
      },
      _id: id,
      client: clientName,
      deployment: deployment?.name ?? '',
      ...application,
      authentication: {
        ...application.authentication,
        disclaimer: {
          ...settings,
          type: template,
          translations: text.name != undefined ? text : undefined,
        },
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      notification.success({
        message: t('updated-successfully'),
      });
    } else CustomNotification({ error: response.errors });
    setIsLoading(false);
  };

  const getMenuItems = async () => {
    let response = await appService.Get_Application({
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
      },
      _id: id,
    });
    if (response && response.status_code === StatusCodes.OK) {
      var resp = response.data as any;
      setApplication(resp);
      setTheme(resp.settings.theme);
      if (resp.authentication != undefined) {
        if (resp.authentication.disclaimer != undefined) {
          setTemplate(resp.authentication.disclaimer.type);
          setSettings(resp.authentication.disclaimer);
          setText(resp.authentication.disclaimer.translations);
        }
      }
      form.setFieldsValue(resp.authentication.disclaimer);
    }
    setIsLoading(false);
  };
  const getDisclaimers = async () => {
    setIsLoading(true);
    let response = await appDisclaimer.List_Disclaimers_Texts({
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
      },
      pageNumber: 0,
      pageSize: 10000,
    });
    if (response && response.status_code === StatusCodes.OK) {
      var resp = response.data as any;
      setDisclaimers(resp.data);
    }
  };

  useEffectOnce(() => {
    getDisclaimers();
    getMenuItems();
  });

  const selectText = (value: any) => {
    if (value != 'NoDisclaimer') {
      var text_ = disclaimers.find((d) => d._id == value);
      setText(text_);
    } else {
      setText([]);
    }
  };

  const getEditButtons = (element: any) => {
    return (
      <div className="row w-100 h-100" style={{ marginLeft: 15, marginBottom: 10 }}>
        <div className="row w-100 h-100" style={{ height: '100vh', width: '100vh', backgroundColor: '#333333', borderRadius: 5 }}>
          <div className="col"></div>
          <div style={{ width: 5, height: 45, cursor: 'pointer', borderTopLeftRadius: 5, borderBottomLeftRadius: 5, backgroundColor: '#ffffff', opacity: '1', marginTop: 5, marginBottom: 5 }}></div>

          <div style={{ padding: 0, width: 80, height: 45, borderRight: '1px solid #333333', cursor: 'pointer', backgroundColor: '#ffffff', opacity: '1', marginTop: 5, marginBottom: 5 }}>
            <div
              onClick={() => {
                setEditData(element);
                setEditType(element.type);
                setEditShowHeaderModal(true);
              }}
              className="h-100 d-flex align-items-center justify-content-center text-center"
            >
              <div>
                <FontAwesomeIcon style={{ cursor: 'pointer', color: '#333333' }} size={'1x'} icon={faHeader} />
                <div style={{ color: '#333333', fontSize: 8, fontWeight: 'bold' }}>HEADER</div>
              </div>
            </div>
          </div>

          <div
            className="ant-card-hoverable"
            style={{
              padding: 0,
              width: 70,
              height: 45,

              cursor: 'pointer',
              backgroundColor: '#ffffff',
              opacity: '1',
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            <div
              onClick={() => {
                setEditData(element);
                setEditType('Disclaimer');
                setEditComponentModal(true);
              }}
              className="h-100 d-flex align-items-center justify-content-center text-center"
            >
              <div>
                <FontAwesomeIcon style={{ cursor: 'pointer', color: '#333333' }} size={'1x'} icon={faBlockBrick} />
                <div style={{ color: '#333333', fontSize: 8, fontWeight: 'bold' }}>COMPONENTS</div>
              </div>
            </div>
          </div>

          <div style={{ width: 5, height: 45, cursor: 'pointer', borderTopRightRadius: 5, borderBottomRightRadius: 5, backgroundColor: '#ffffff', opacity: '1', marginTop: 5, marginBottom: 5, marginRight: 5 }}></div>
        </div>
      </div>
    );
  };

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <EditText
          theme={theme}
          visible={EditTextMessageModal}
          onSubmit={editComponent}
          onCancel={() => {
            setEditTextMessageModal(false);
          }}
          data={editData}
          type={editType}
          index={0}
        />
        <EditComponents
          visible={editComponentModal}
          onSubmit={editComponent}
          onCancel={() => {
            setEditComponentModal(false);
          }}
          application={application}
          data={editData}
          type={editType}
          index={0}
        />
        <EditHeader
          theme={theme}
          visible={EditShowHeaderModal}
          onSubmit={editComponent}
          onCancel={() => {
            setEditShowHeaderModal(false);
          }}
          data={editData}
          type={editType}
          index={0}
        />
        <div className="col-md-12 mb-2">
          <div className="container-fluid">
            <Form
              form={form}
              initialValues={{
                ...formDetails,
              }}
              onFinish={async (values) => {
                await handleSubmit(values);
              }}
              onFinishFailed={(errorInfo) => {
                
              }}
            >
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4" style={{ paddingLeft: 0 }}>
                      <div className="card-bg-light p-3">
                        <h5 className="text-primary mt-4" style={{ paddingLeft: 15 }}>
                          {t('disclaimer-texts')}
                        </h5>
                        <div className="col">
                          <Form.Item style={{ padding: 0 }} className="col-md-12" name={['translations', '_id']}>
                            <Select onChange={selectText} className="default-select" placeholder={t('select-type')}>
                              <Select.Option value={"Disabled"} key={'Disabled'}>
                                "Disabled"
                              </Select.Option>
                              {disclaimers.map((disclaimer) => {
                                return (
                                  <Select.Option value={disclaimer._id} key={disclaimer._id}>
                                    {disclaimer.name}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="text-right mr-3 mt-3">
                          <Button loading={isLoading} htmlType="submit" className="mb-4 ant-btn btn btn-secondary rounded-pill width-200 height-48">
                            {t('update')}
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="card-bg-light pl-5 pt-3 mb-4 p-5">
                        <div className="row">
                          {getEditButtons(settings)}

                          <img src={standard} alt={''} width={'100%'}></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Authentication;
