import { Button, Row, Select, Tooltip, Image, Col, Modal } from 'antd';
import { StatusCodes } from 'http-status-codes';
import { useContext, useState } from 'react';
import { ICMS_Lists } from '../../../interfaces/forms/common/ICMS_Lists';
import { IPaginatedAndFilterResponse, PaginatedAndFilterResponseDefaultValues } from '../../../interfaces/responses/common/IPaginatedAndFilterResponse';
import GridDrawer from '../GridDrawer';
import ReactBCTable from '../ReactBCTable';
import { IReactBCTableColumn } from '../ReactBCTable/Interfaces/IReactBCTableColumn.interface';
import { faCirclePlay, faFilePlus, faLinkSlash, faMemo, faSquareCheck, faSquareXmark, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cmsEpgUrls from '../../../services/areas/content/epg/epg_urls';
import cmsAlbums from '../../../services/areas/content/albums/albums';
import cmsMovies from '../../../services/areas/content/movies/movies';
import cmsShorts from '../../../services/areas/content/shorts/shorts';
import cmsSeries from '../../../services/areas/content/series/series';
import cmsGames from '../../../services/areas/content/games/games';
import cmsPodcasts from '../../../services/areas/content/podcasts/podcasts';
import cmsChannels from '../../../services/areas/content/channels/channels';
import cmsRadios from '../../../services/areas/content/radio/radio';
import cmsCams from '../../../services/areas/content/cams/cams';
import cmsCourse from '../../../services/areas/content/courses/courses';
import cmsWorkflows from '../../../services/areas/content/workflows/workflows';
import cmsAutomations from '../../../services/areas/content/automations/automations';
import moment from 'moment-timezone';
import { useRouteMatch } from 'react-router';
import cmsService from '../../../services/areas/content/epg/epg_urls';
import { Epg_Urls } from '../../../interfaces/responses/areas/content/epg/epg_urls';
import { STORAGE_ENDPOINTS } from '../../../services/storage_paths';
import { ContentContext } from '../../../contexts/contentContext';
import EpgEditUrlModal from './Epg/EpgEditUrlModal';
import SeasonAddModal from './Series/SeasonAdd';
import { useEffectOnce } from 'react-use';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../context_api/AuthContext';
import { TVMSRoles } from '../../../constants/TVMSRoles';
import PlayerModal from './Player/PlayerModal';
import RejectModal from './Reject/RejectModal';

const Table = (params: { service_name: any; clientName: any; type: any; variant: string }) => {
  const { url } = useRouteMatch();
  let { confirm } = Modal;
  const { t } = useTranslation();
  const { deployment } = useContext(ContentContext);
  const [selectedRowKeys, setRowSelectionState] = useState<string[]>([]);
  const [data, setData] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);
  const [epgUrls, setEpgUrls] = useState<Epg_Urls['data']['data']>();
  const [showUrlModal, setShowUrlModal] = useState(false);
  const [epg, setEpg] = useState([] as any);
  const [showPlayerModal, setShowPlayerModal] = useState(false);
  const [player, setPlayer] = useState([] as any);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showSeasonAddModel, setShowSeasonAddModel] = useState(false);
  const [rowData, setRowData] = useState([] as any);

  const { state: AuthState } = useContext(AuthContext);
  const isProvider = AuthState.user?.role === TVMSRoles.Provider ? true : false;

  const filterProvider = {
    id: 'provider.name',
    value: { filterValue: AuthState.user?.name ?? 'jasdfklafijaskdflas', filterType: 'text' },
  } as any;

  const filterValidation = {
    id: 'provider_status',
    value: { filterValue: 'validation', filterType: 'text' },
  } as any;

  const filterValidationAccepted = {
    id: 'provider_status',
    value: { filterValue: 'accepted', filterType: 'text' },
  } as any;

  const filterValidationEmpty = {
    id: 'provider_status',
    value: { filterValue: '', filterType: 'text' },
  } as any;

  const filterValidationUndefined = {
    id: 'provider_status',
    value: { filterValue: 'undefined', filterType: 'undefined' },
  } as any;

  const [tableSearchState, setTableSearchState] = useState<ICMS_Lists>({
    pageNumber: 0,
    pageSize: 50,
    filters: isProvider ? [filterProvider] : params.variant === 'providers' ? [filterValidation] : [filterValidationUndefined, filterValidationEmpty, filterValidationAccepted],
    query_params: {
      cms: params.service_name ?? '',
      instance: params.clientName ?? '',
    },
  });
  const [pagination, setPagination] = useState<IPaginatedAndFilterResponse>(PaginatedAndFilterResponseDefaultValues);

  useEffectOnce(() => {
    getContent(tableSearchState);
  });

  const playItem = (item) => {
    setPlayer(item);
    setShowPlayerModal(true);
  };

  const getDeleteMessageItemName = () => {
    return (
      {
        albums: t('albums-2'),
        movies: t('movies-4'),
        series: t('series-4'),
        seasons: t('seasons'),
        podcasts: t('podcasts-4'),
        channels: t('channels'),
        radios: t('radios-0'),
        cams: t('cams'),
        courses: t('courses-4'),
        workflows: t('workflows'),
        automations: t('automations'),
      }[params.type] ?? t('items')
    );
  };

  const deleteItem = async (id: string) => {
    setIsLoading(true);
    switch (params.type) {
      case 'epgs':
        await deleteEpgUrl(id);
        break;
      case 'albums':
        await deleteAlbum(id);
        break;
      case 'movies':
        await deleteMovie(id);
        break;
      case 'shorts':
        await deleteShort(id);
        break;
      case 'games':
        await deleteGame(id);
        break;
      case 'series':
        await deleteSerie(id);
        break;
      case 'seasons':
        await deleteSeason(id);
        break;
      case 'podcasts':
        await deletePodcast(id);
        break;
      case 'channels':
        await deleteChannel(id);
        break;
      case 'radios':
        await deleteRadio(id);
        break;
      case 'cams':
        await deleteCam(id);
        break;
      case 'courses':
        await deleteCourse(id);
        break;
      case 'workflows':
        await deleteWorkflow(id);
        break;
      case 'automations':
        await deleteAutomation(id);
        break;
    }
  };
  const updateItem = async (row, provider_reject_reason, type, rejectoraccept) => {
    setIsLoading(true);
    switch (type) {
      case 'albums':
        await updateAlbum(row, provider_reject_reason, rejectoraccept);
        break;
      case 'movies':
        await updateMovie(row, provider_reject_reason, rejectoraccept);
        break;
      case 'shorts':
        await updateShort(row, provider_reject_reason, rejectoraccept);
        break;
      case 'games':
        await updateGame(row, provider_reject_reason, rejectoraccept);
        break;
      case 'series':
        await updateSerie(row, provider_reject_reason, rejectoraccept);
        break;
      case 'seasons':
        await updateSeason(row, provider_reject_reason, rejectoraccept);
        break;
      case 'podcasts':
        await updatePodcast(row, provider_reject_reason, rejectoraccept);
        break;
      case 'channels':
        await updateChannel(row, provider_reject_reason, rejectoraccept);
        break;
      case 'radios':
        await updateRadio(row, provider_reject_reason, rejectoraccept);
        break;
      case 'cams':
        await updateCam(row, provider_reject_reason, rejectoraccept);
        break;
      case 'courses':
        await updateCourse(row, provider_reject_reason, rejectoraccept);
        break;
    }
    setShowRejectModal(false);
  };
  const deleteItems = async () => {
    setIsLoading(true);
    switch (params.type) {
      case 'albums':
        await deleteAlbums();
        break;
      case 'movies':
        await deleteMovies();
        break;
      case 'series':
        await deleteSeries();
        break;
      case 'seasons':
        await deleteSeasons();
        break;
      case 'podcasts':
        await deletePodcasts();
        break;
      case 'channels':
        await deleteChannels();
        break;
      case 'radios':
        await deleteRadios();
        break;
      case 'cams':
        await deleteCams();
        break;
      // case 'games':
      //   await deleteGame();
      //   break;
      case 'courses':
        await deleteCourses();
        break;
      case 'workflows':
        await deleteWorkflows();
        break;
      case 'automations':
        await deleteAutomations();
        break;
    }
  };
  const clearLink = async (row, index) => {
    if (params.type === 'epgs_channels') {
      setIsLoading(true);
      await clearSelectedTvGuideChannel(row, index);
    } else if (params.type === 'epgs_radios') {
      setIsLoading(true);
      await clearSelectedTvGuideRadio(row, index);
    }
  };
  const openDetails = (_id: string) => {
    if (params.type === 'epgs_channels') {
      const redirectUrl = url.split('/').slice(0, -2).join('/');
      return `${redirectUrl}/channels/details/${_id}`;
    } else if (params.type === 'epgs_radios') {
      const redirectUrl = url.split('/').slice(0, -2).join('/');
      return `${redirectUrl}/radios/details/${_id}`;
    } else if (params.type === 'epgs') {
      setEpg(_id);
      setShowUrlModal(true);
    } else {
      if (isProvider) {
        return `${url.replace('/providers', '')}/details/${_id}`;
      } else {
        return `${url.replace('/providers', '')}/details/${_id}`;
      }
    }
  };

  const getContent = async (tableSearchState) => {
    switch (params.type) {
      case 'epgs_channels':
        await getEpgChannels(tableSearchState);
        await getEpgUrls();
        break;
      case 'epgs_radios':
        await getEpgRadios(tableSearchState);
        await getEpgUrls();
        break;
      case 'epgs':
        await getEpgUrl(tableSearchState);
        break;
      case 'albums':
        await getAlbums(tableSearchState);
        break;
      case 'movies':
        await getMovies(tableSearchState);
        break;
      case 'shorts':
        await getShorts(tableSearchState);
        break;
      case 'games':
        await getGames(tableSearchState);
        break;
      case 'series':
        await getSeries(tableSearchState);
        break;
      case 'seasons':
        await getSeasons(tableSearchState);
        break;
      case 'podcasts':
        await getPodcasts(tableSearchState);
        break;
      case 'channels':
        await getChannels(tableSearchState);
        break;
      case 'radios':
        await getRadios(tableSearchState);
        break;
      case 'cams':
        await getCams(tableSearchState);
        break;
      case 'courses':
        await getCourses(tableSearchState);
        break;
      case 'workflows':
        await getWorkflows(tableSearchState);
        break;
      case 'automations':
        await getAutomations(tableSearchState);
        break;
    }
  };

  //epgs channels
  const getEpgRadios = async (list: ICMS_Lists) => {
    setIsLoading(true);
    const response = await cmsRadios.List_Radios({
      ...list,
      query_params: {
        cms: params.service_name ?? '',
        instance: params.clientName ?? '',
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
  };
  const getEpgChannels = async (list: ICMS_Lists) => {
    setIsLoading(true);
    const response = await cmsChannels.List_Channels({
      ...list,
      query_params: {
        cms: params.service_name ?? '',
        instance: params.clientName ?? '',
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
  };

  //epgs
  const deleteEpgUrl = async (_id: string) => {
    const response = await cmsEpgUrls.Delete_Epg_Urls({
      _id: _id,
      query_params: {
        cms: params.service_name ?? '',
        instance: params.clientName ?? '',
      },
    });
    if (response.status_code === StatusCodes.OK) {
      setRowSelectionState([]);
      await getEpgUrl(tableSearchState);
    }
    setIsLoading(false);
  };
  const getEpgUrl = async (list: ICMS_Lists) => {
    setIsLoading(true);
    const response = await cmsEpgUrls.List_Epg_Urls({
      ...list,
      query_params: {
        cms: params.service_name ?? '',
        instance: params.clientName ?? '',
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };

  //workflows
  const deleteWorkflows = async () => {
    const response = await cmsWorkflows.Delete_Workflow({
      _ids: selectedRowKeys,
      query_params: {
        cms: params.service_name ?? '',
        instance: params.clientName ?? '',
      },
    });
    if (response.status_code === StatusCodes.OK) {
      setRowSelectionState([]);
      await getAlbums(tableSearchState);
    }
    setIsLoading(false);
  };
  const deleteWorkflow = async (_id: string) => {
    const response = await cmsWorkflows.Delete_Workflow({
      _id: _id,
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
        path: STORAGE_ENDPOINTS(params.clientName, deployment?.name, params.service_name).CONTENT.ALBUMS.BUNDLES,
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      getAlbums(tableSearchState);
    }
    setIsLoading(false);
  };
  const getWorkflows = async (list: ICMS_Lists) => {
    const response = await cmsWorkflows.List_Workflows({
      ...list,
      query_params: {
        cms: params.service_name ?? '',
        instance: params.clientName ?? '',
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };

  //automatations
  const deleteAutomations = async () => {
    const response = await cmsAutomations.Delete_Automation({
      _ids: selectedRowKeys,
      query_params: {
        cms: params.service_name ?? '',
        instance: params.clientName ?? '',
      },
    });
    if (response.status_code === StatusCodes.OK) {
      setRowSelectionState([]);
      await getAlbums(tableSearchState);
    }
    setIsLoading(false);
  };
  const deleteAutomation = async (_id: string) => {
    const response = await cmsAutomations.Delete_Automation({
      _id: _id,
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
        path: STORAGE_ENDPOINTS(params.clientName, deployment?.name, params.service_name).CONTENT.ALBUMS.BUNDLES,
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      getAlbums(tableSearchState);
    }
    setIsLoading(false);
  };
  const getAutomations = async (list: ICMS_Lists) => {
    const response = await cmsAutomations.List_Automations({
      ...list,
      query_params: {
        cms: params.service_name ?? '',
        instance: params.clientName ?? '',
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };

  //albums
  const deleteAlbums = async () => {
    const response = await cmsAlbums.Delete_Album({
      _ids: selectedRowKeys,
      query_params: {
        cms: params.service_name ?? '',
        instance: params.clientName ?? '',
      },
    });
    if (response.status_code === StatusCodes.OK) {
      setRowSelectionState([]);
      await getAlbums(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateAlbum = async (row, provider_reject_reason, rejectoraccept) => {
    const response = await cmsAlbums.Update_Album({
      ...row,
      provider_reject_reason: provider_reject_reason,
      provider_status: rejectoraccept,
      query_params: {
        cms: params.service_name ?? '',
        instance: params.clientName ?? '',
      },
    });
    if (response.status_code === StatusCodes.OK) {
      setRowSelectionState([]);
      await getAlbums(tableSearchState);
    }
    setIsLoading(false);
  };
  const deleteAlbum = async (_id: string) => {
    const response = await cmsAlbums.Delete_Album({
      _id: _id,
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
        path: STORAGE_ENDPOINTS(params.clientName, deployment?.name, params.service_name).CONTENT.ALBUMS.BUNDLES,
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      getAlbums(tableSearchState);
    }
    setIsLoading(false);
  };
  const getAlbums = async (list: ICMS_Lists) => {
    const response = await cmsAlbums.List_Albums({
      ...list,
      query_params: {
        cms: params.service_name ?? '',
        instance: params.clientName ?? '',
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };

  //movies
  const deleteMovies = async () => {
    const response = await cmsMovies.Delete_Movies({
      _ids: selectedRowKeys,
      query_params: {
        cms: params.service_name ?? '',
        instance: params.clientName ?? '',
      },
    });
    if (response.status_code === StatusCodes.OK) {
      setRowSelectionState([]);
      await getMovies(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateMovie = async (row, provider_reject_reason, rejectoraccept) => {
    const response = await cmsMovies.Update_Movie({
      ...row,
      provider_reject_reason: provider_reject_reason,
      provider_status: rejectoraccept,
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
        path: STORAGE_ENDPOINTS(params.clientName, deployment?.name, params.service_name).CONTENT.MOVIES.BUNDLES,
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      getMovies(tableSearchState);
    }
    setIsLoading(false);
  };
  const deleteMovie = async (_id: string) => {
    const response = await cmsMovies.Delete_Movie({
      _id: _id,
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
        path: STORAGE_ENDPOINTS(params.clientName, deployment?.name, params.service_name).CONTENT.MOVIES.BUNDLES,
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      getMovies(tableSearchState);
    }
    setIsLoading(false);
  };
  const getMovies = async (list: ICMS_Lists) => {
    setIsLoading(true);

    const response = await cmsMovies.List_Movies({
      ...list,
      query_params: {
        cms: params.service_name ?? '',
        instance: params.clientName ?? '',
      },
    });

    if (response && response.status_code === StatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };

  //shorts
  const deleteShort = async (_id: string) => {
    const response = await cmsShorts.Delete_Shorts({
      _id: _id,
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
        path: STORAGE_ENDPOINTS(params.clientName, deployment?.name, params.service_name).CONTENT.SHORTS.BUNDLES,
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      getShorts(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateShort = async (row, provider_reject_reason, rejectoraccept) => {
    const response = await cmsShorts.Update_Shorts({
      ...row,
      provider_reject_reason: provider_reject_reason,
      provider_status: rejectoraccept,
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
        path: STORAGE_ENDPOINTS(params.clientName, deployment?.name, params.service_name).CONTENT.SHORTS.BUNDLES,
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      getShorts(tableSearchState);
    }
    setIsLoading(false);
  };

  const getShorts = async (list: ICMS_Lists) => {
    setIsLoading(true);

    const response = await cmsShorts.List_Shorts({
      ...list,
      query_params: {
        cms: params.service_name ?? '',
        instance: params.clientName ?? '',
      },
    });

    if (response && response.status_code === StatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };

  //games
  const deleteGame = async (_id: string) => {
    const response = await cmsGames.Delete_Games({
      _id: _id,
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
        path: STORAGE_ENDPOINTS(params.clientName, deployment?.name, params.service_name).CONTENT.SHORTS.BUNDLES,
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      getShorts(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateGame = async (row, provider_reject_reason, rejectoraccept) => {
    const response = await cmsGames.Delete_Games({
      ...row,
      provider_reject_reason: provider_reject_reason,
      provider_status: rejectoraccept,
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
        path: STORAGE_ENDPOINTS(params.clientName, deployment?.name, params.service_name).CONTENT.SHORTS.BUNDLES,
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      getShorts(tableSearchState);
    }
    setIsLoading(false);
  };
  const getGames = async (list: ICMS_Lists) => {
    setIsLoading(true);

    const response = await cmsGames.List_Games({
      ...list,
      query_params: {
        cms: params.service_name ?? '',
        instance: params.clientName ?? '',
      },
    });

    if (response && response.status_code === StatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };

  //series
  const deleteSeries = async () => {
    const response = await cmsSeries.Delete_Seriess({
      _ids: selectedRowKeys,
      query_params: {
        cms: params.service_name ?? '',
        instance: params.clientName ?? '',
      },
    });
    if (response.status_code === StatusCodes.OK) {
      setRowSelectionState([]);
      await getSeries(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateSerie = async (row, provider_reject_reason, rejectoraccept) => {
    const response = await cmsSeries.Update_Series({
      ...row,
      provider_reject_reason: provider_reject_reason,
      provider_status: rejectoraccept,
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
        path: STORAGE_ENDPOINTS(params.clientName, deployment?.name, params.service_name).CONTENT.SERIES.BUNDLES,
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      getSeries(tableSearchState);
    }
    setIsLoading(false);
  };
  const deleteSerie = async (_id: string) => {
    const response = await cmsSeries.Delete_Series({
      _id: _id,
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
        path: STORAGE_ENDPOINTS(params.clientName, deployment?.name, params.service_name).CONTENT.SERIES.BUNDLES,
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      getSeries(tableSearchState);
    }
    setIsLoading(false);
  };
  const getSeries = async (list: ICMS_Lists) => {
    setIsLoading(true);
    const response = await cmsSeries.List_Seriess({
      ...list,
      query_params: {
        cms: params.service_name ?? '',
        instance: params.clientName ?? '',
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };

  const getSeasons = async (list: ICMS_Lists) => {
    setIsLoading(true);
    const response = await cmsSeries.List_Seasons({
      ...list,
      query_params: {
        cms: params.service_name ?? '',
        instance: params.clientName ?? '',
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };
  const updateSeason = async (row, provider_reject_reason, rejectoraccept) => {
    const response = await cmsSeries.Update_Season({
      ...row,
      provider_reject_reason: provider_reject_reason,
      provider_status: rejectoraccept,
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
        path: STORAGE_ENDPOINTS(params.clientName, deployment?.name, params.service_name).CONTENT.SERIES.BUNDLES,
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      getSeasons(tableSearchState);
    }
    setIsLoading(false);
  };
  const deleteSeasons = async () => {
    const response = await cmsSeries.Delete_Season({
      _ids: selectedRowKeys,
      query_params: {
        cms: params.service_name ?? '',
        instance: params.clientName ?? '',
      },
    });
    if (response.status_code === StatusCodes.OK) {
      setRowSelectionState([]);
      await getSeasons(tableSearchState);
    }
    setIsLoading(false);
  };
  const deleteSeason = async (_id: string) => {
    const response = await cmsSeries.Delete_Season({
      _id: _id,
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
        path: STORAGE_ENDPOINTS(params.clientName, deployment?.name, params.service_name).CONTENT.SERIES.BUNDLES,
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      getSeasons(tableSearchState);
    }
    setIsLoading(false);
  };

  //podcasts
  const deletePodcasts = async () => {
    const response = await cmsPodcasts.Delete_Podcasts({
      _ids: selectedRowKeys,
      query_params: {
        cms: params.service_name ?? '',
        instance: params.clientName ?? '',
      },
    });
    if (response.status_code === StatusCodes.OK) {
      setRowSelectionState([]);
      await getPodcasts(tableSearchState);
    }
    setIsLoading(false);
  };
  const updatePodcast = async (row, provider_reject_reason, rejectoraccept) => {
    setIsLoading(true);
    const response = await cmsPodcasts.Update_Podcasts({
      ...row,
      provider_reject_reason: provider_reject_reason,
      provider_status: rejectoraccept,
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
        path: STORAGE_ENDPOINTS(params.clientName, deployment?.name, params.service_name).CONTENT.PODCASTS.BUNDLES,
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      getPodcasts(tableSearchState);
    }
    setIsLoading(false);
  };
  const deletePodcast = async (_id: string) => {
    setIsLoading(true);
    const response = await cmsPodcasts.Delete_Podcast({
      _id: _id,
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
        path: STORAGE_ENDPOINTS(params.clientName, deployment?.name, params.service_name).CONTENT.PODCASTS.BUNDLES,
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      getPodcasts(tableSearchState);
    }
    setIsLoading(false);
  };
  const getPodcasts = async (list: ICMS_Lists) => {
    setIsLoading(true);
    const response = await cmsPodcasts.List_Podcasts({
      ...list,
      query_params: {
        cms: params.service_name ?? '',
        instance: params.clientName ?? '',
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };

  //channels
  const deleteChannels = async () => {
    const response = await cmsChannels.Delete_Channels({
      _ids: selectedRowKeys,
      query_params: {
        cms: params.service_name ?? '',
        instance: params.clientName ?? '',
      },
    });
    if (response.status_code === StatusCodes.OK) {
      setRowSelectionState([]);
      await getChannels(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateChannel = async (row, provider_reject_reason, rejectoraccept) => {
    const response = await cmsChannels.Update_Channel({
      ...row,
      provider_reject_reason: provider_reject_reason,
      provider_status: rejectoraccept,
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
        path: STORAGE_ENDPOINTS(params.clientName, deployment?.name, params.service_name).CONTENT.CHANNELS.BUNDLES,
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      getChannels(tableSearchState);
    }
    setIsLoading(false);
  };
  const deleteChannel = async (_id: string) => {
    const response = await cmsChannels.Delete_Channel({
      _id: _id,
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
        path: STORAGE_ENDPOINTS(params.clientName, deployment?.name, params.service_name).CONTENT.CHANNELS.BUNDLES,
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      getChannels(tableSearchState);
    }
    setIsLoading(false);
  };
  const getChannels = async (list: ICMS_Lists) => {
    setIsLoading(true);
    const response = await cmsChannels.List_Channels({
      ...list,
      query_params: {
        cms: params.service_name ?? '',
        instance: params.clientName ?? '',
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };

  //radios
  const deleteRadios = async () => {
    const response = await cmsRadios.Delete_Radios({
      _ids: selectedRowKeys,
      query_params: {
        cms: params.service_name ?? '',
        instance: params.clientName ?? '',
      },
    });
    if (response.status_code === StatusCodes.OK) {
      setRowSelectionState([]);
      await getChannels(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateRadio = async (row, provider_reject_reason, rejectoraccept) => {
    const response = await cmsRadios.Update_Radio({
      ...row,
      provider_reject_reason: provider_reject_reason,
      provider_status: rejectoraccept,
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
        path: STORAGE_ENDPOINTS(params.clientName, deployment?.name, params.service_name).CONTENT.RADIOS.BUNDLES,
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      getChannels(tableSearchState);
    }
    setIsLoading(false);
  };
  const deleteRadio = async (_id: string) => {
    const response = await cmsRadios.Delete_Radio({
      _id: _id,
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
        path: STORAGE_ENDPOINTS(params.clientName, deployment?.name, params.service_name).CONTENT.RADIOS.BUNDLES,
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      getChannels(tableSearchState);
    }
    setIsLoading(false);
  };
  const getRadios = async (list: ICMS_Lists) => {
    setIsLoading(true);
    const response = await cmsRadios.List_Radios({
      ...list,
      query_params: {
        cms: params.service_name ?? '',
        instance: params.clientName ?? '',
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };

  //cams
  const deleteCams = async () => {
    const response = await cmsCams.Delete_Cams({
      _ids: selectedRowKeys,
      query_params: {
        cms: params.service_name ?? '',
        instance: params.clientName ?? '',
      },
    });
    if (response.status_code === StatusCodes.OK) {
      setRowSelectionState([]);
      await getCams(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateCam = async (row, provider_reject_reason, rejectoraccept) => {
    const response = await cmsCams.Update_Cam({
      ...row,
      provider_reject_reason: provider_reject_reason,
      provider_status: rejectoraccept,
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
        path: STORAGE_ENDPOINTS(params.clientName, deployment?.name, params.service_name).CONTENT.CAMS.BUNDLES,
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      getCams(tableSearchState);
    }
    setIsLoading(false);
  };
  const deleteCam = async (_id: string) => {
    const response = await cmsCams.Delete_Cam({
      _id: _id,
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
        path: STORAGE_ENDPOINTS(params.clientName, deployment?.name, params.service_name).CONTENT.CAMS.BUNDLES,
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      getCams(tableSearchState);
    }
    setIsLoading(false);
  };
  const getCams = async (list: ICMS_Lists) => {
    setIsLoading(true);
    const response = await cmsCams.List_Cams({
      ...list,
      query_params: {
        cms: params.service_name ?? '',
        instance: params.clientName ?? '',
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };

  //courses
  const deleteCourses = async () => {
    const response = await cmsCourse.Delete_Course({
      _ids: selectedRowKeys,
      query_params: {
        cms: params.service_name ?? '',
        instance: params.clientName ?? '',
      },
    });
    if (response.status_code === StatusCodes.OK) {
      setRowSelectionState([]);
      await getCourses(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateCourse = async (row, provider_reject_reason, rejectoraccept) => {
    const response = await cmsCourse.Update_Course({
      ...row,
      provider_reject_reason: provider_reject_reason,
      provider_status: rejectoraccept,
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
        path: STORAGE_ENDPOINTS(params.clientName, deployment?.name, params.service_name).CONTENT.COURSES.BUNDLES,
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      getCourses(tableSearchState);
    }
    setIsLoading(false);
  };
  const deleteCourse = async (_id: string) => {
    const response = await cmsCourse.Delete_Course({
      _id: _id,
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
        path: STORAGE_ENDPOINTS(params.clientName, deployment?.name, params.service_name).CONTENT.COURSES.BUNDLES,
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      getCourses(tableSearchState);
    }
    setIsLoading(false);
  };
  const getCourses = async (list: ICMS_Lists) => {
    setIsLoading(true);
    const response = await cmsCourse.List_Courses({
      ...list,
      query_params: {
        cms: params.service_name ?? '',
        instance: params.clientName ?? '',
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };

  const getEpgUrls = async () => {
    setIsLoading(true);
    const response = await cmsService.List_Epg_Urls({
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
      },
      pageNumber: 0,
      pageSize: 100000,
    });
    if (response.status_code === StatusCodes.OK) {
      setEpgUrls(response.data.data);
      setData((prevState) => prevState.map((el) => ({ ...el, epgURL: response.data.data })));
    }
    setIsLoading(false);
  };

  const setSelectedTvGuideSourceChannels = async (_id: any, index: any) => {
    setIsLoading(true);
    const epgUrl = epgUrls?.find((e: any) => e._id === _id);
    if (epgUrl !== undefined) {
      var test = epgArray.find((f) => f.url == epgUrl.url);
      if (test != undefined) {
        const epgChannels = test.result;
        if (data[index].epg === undefined) {
          const epg = {
            epg_url_id: _id,
            epg_url_channel: '',
          };
          data[index].epg = epg;
        } else {
          data[index].epg.epg_url_id = _id;
        }

        setData((prevState) => {
          const newItems = [...prevState];
          newItems[index].epgChannels = epgChannels;
          newItems[index].epgType = 'JSON';
          newItems[index].epgSupplier = epgUrl.supplier;
          return newItems;
        });
      } else {
        if (epgUrl.supplier === 'TV Profile') {
          const response = await fetch(epgUrl.url.replace('/epg/', '/channels/'));
          if (response.status === 200) {
            const result = await response.json();
            if (result.data.channels !== undefined) {
              const epgChannels = result.data.channels;

              if (data[index].epg === undefined) {
                const epg = {
                  epg_url_id: _id,
                  epg_url_channel: '',
                };
                data[index].epg = epg;
              } else {
                data[index].epg.epg_url_id = _id;
              }
              setEpgArray([
                ...epgArray,
                {
                  url: epgUrl.url,
                  result: epgChannels,
                },
              ]);
              setData((prevState) => {
                const newItems = [...prevState];
                newItems[index].epgChannels = epgChannels;
                newItems[index].epgType = 'JSON';
                newItems[index].epgSupplier = epgUrl.supplier;
                return newItems;
              });
            }
          }
        } else if (epgUrl.supplier === 'EPG Best') {
          var path = 'https://europe-west1-tvmsproduction.cloudfunctions.net/proxy?token=' + epgUrl.apikey + '&action=GET&url=' + encodeURI(epgUrl.url.replace('/programmes', '/channels'));
          const response = await fetch(path, {
            method: 'GET',
          });
          if (response.status === 200) {
            const result = await response.json();
            if (result !== undefined) {
              const epgChannels = result;
              if (data[index].epg === undefined) {
                const epg = {
                  epg_url_id: _id,
                  epg_url_channel: '',
                };
                data[index].epg = epg;
              } else {
                data[index].epg.epg_url_id = _id;
              }
              setEpgArray([
                ...epgArray,
                {
                  url: epgUrl.url,
                  result: epgChannels,
                },
              ]);
              setData((prevState) => {
                const newItems = [...prevState];
                newItems[index].epgChannels = epgChannels;
                newItems[index].epgType = 'JSON';
                newItems[index].epgSupplier = epgUrl.supplier;
                return newItems;
              });
            }
          }
        } else if (epgUrl.supplier === 'XMLTV') {
          const response = await cmsService.Get_XMLTV({
            query_params: {
              cms: params.service_name,
              instance: params.clientName,
              _id: _id,
            },
            _id: _id,
          });
          if (response.status_code === StatusCodes.OK) {
            const epgChannels = response.data;
            console.log(epgChannels);
            if (epgChannels !== undefined) {
              if (data[index].epg === undefined) {
                const epg = {
                  epg_url_id: _id,
                  epg_url_channel: '',
                };
                data[index].epg = epg;
              } else {
                data[index].epg.epg_url_id = _id;
              }
              setEpgArray([
                ...epgArray,
                {
                  url: epgUrl.url,
                  result: epgChannels,
                },
              ]);
              setData((prevState) => {
                const newItems = [...prevState];
                newItems[index].epgChannels = epgChannels;
                newItems[index].epgType = 'XMLTV';
                newItems[index].epgSupplier = epgUrl.supplier;
                return newItems;
              });
            }
          }
        }
      }
    }
    setIsLoading(false);
  };
  const [epgArray, setEpgArray] = useState([] as any);
  const setSelectedTvGuideSourceRadios = async (_id: any, index: any) => {
    setIsLoading(true);
    const epgUrl = epgUrls?.find((e: any) => e._id === _id);
    if (epgUrl !== undefined) {
      var test = epgArray.find((f) => f.url == epgUrl.url);
      if (test != undefined) {
        const epgChannels = test.result;
        if (data[index].epg === undefined) {
          const epg = {
            epg_url_id: _id,
            epg_url_channel: '',
          };
          data[index].epg = epg;
        } else {
          data[index].epg.epg_url_id = _id;
        }
        setData((prevState) => {
          const newItems = [...prevState];
          newItems[index].epgChannels = epgChannels;
          newItems[index].epgType = 'JSON';
          newItems[index].epgSupplier = epgUrl.supplier;
          return newItems;
        });
      } else {
        if (epgUrl.supplier === 'TV Profile') {
          const response = await fetch(epgUrl.url.replace('/epg/', '/channels/'));
          if (response.status === 200) {
            const result = await response.json();
            if (result.data.channels !== undefined) {
              const epgChannels = result.data.channels;
              if (data[index].epg === undefined) {
                const epg = {
                  epg_url_id: _id,
                  epg_url_channel: '',
                };
                data[index].epg = epg;
              } else {
                data[index].epg.epg_url_id = _id;
              }
              setEpgArray([
                ...epgArray,
                {
                  url: epgUrl.url,
                  result: epgChannels,
                },
              ]);
              setData((prevState) => {
                const newItems = [...prevState];
                newItems[index].epgRadios = epgChannels;
                newItems[index].epgType = 'JSON';
                newItems[index].epgSupplier = epgUrl.supplier;
                return newItems;
              });
            }
          }
        } else if (epgUrl.supplier === 'EPG Best') {
          var path = 'https://europe-west1-tvmsproduction.cloudfunctions.net/proxy?token=' + epgUrl.apikey + '&action=GET&url=' + encodeURI(epgUrl.url.replace('/programmes', '/channels'));
          const response = await fetch(path, {
            method: 'GET',
          });
          if (response.status === 200) {
            const result = await response.json();
            if (result !== undefined) {
              const epgChannels = result;
              if (data[index].epg === undefined) {
                const epg = {
                  epg_url_id: _id,
                  epg_url_channel: '',
                };
                data[index].epg = epg;
              } else {
                data[index].epg.epg_url_id = _id;
              }
              setEpgArray([
                ...epgArray,
                {
                  url: epgUrl.url,
                  result: epgChannels,
                },
              ]);
              setData((prevState) => {
                const newItems = [...prevState];
                newItems[index].epgChannels = epgChannels;
                newItems[index].epgType = 'JSON';
                newItems[index].epgSupplier = epgUrl.supplier;
                return newItems;
              });
            }
          }
        } else if (epgUrl.supplier === 'XMLTV') {
          const response = await cmsService.Get_XMLTV({
            query_params: {
              cms: params.service_name,
              instance: params.clientName,
              _id: _id,
            },
            _id: _id,
          });
          if (response.status_code === StatusCodes.OK) {
            const epgChannels = response.data;
            if (epgChannels !== undefined) {
              if (data[index].epg === undefined) {
                const epg = {
                  epg_url_id: _id,
                  epg_url_channel: '',
                };
                data[index].epg = epg;
              } else {
                data[index].epg.epg_url_id = _id;
              }
              setEpgArray([
                ...epgArray,
                {
                  url: epgUrl.url,
                  result: epgChannels,
                },
              ]);
              setData((prevState) => {
                const newItems = [...prevState];
                newItems[index].epgChannels = epgChannels;
                newItems[index].epgType = 'XMLTV';
                newItems[index].epgSupplier = epgUrl.supplier;
                return newItems;
              });
            }
          }
        }
      }
    }
    setIsLoading(false);
  };
  const setSelectedTvGuideChannel = async (value: any, index: any) => {
    // setIsLoading(true);
    data[index].epg.epg_url_channel = value;
    if (data[index].payperview != undefined) {
      if (data[index].payperview.currencies != undefined) {
        if (data[index].payperview.currencies.length > 0) {
          if (data[index].payperview.currencies[0].price != undefined) {
            var curs = [] as any;
            deployment?.currencies.forEach((cur, index) => {
              curs.push({
                iso3: cur.isoCurrency,
                credits_rented: 0,
                credits_purchased: 0,
                key: index,
              });
            });
            data[index].payperview = {
              enable: data[index].payperview.enable,
              rule: '',
              currencies: curs,
            };
          } else {
            var curs = [] as any;
            deployment?.currencies.forEach((cur, index) => {
              curs.push({
                iso3: cur.isoCurrency,
                credits_rented: 0,
                credits_purchased: 0,
                key: index,
              });
            });
            data[index].payperview = {
              enable: data[index].payperview.enable,
              rule: '',
              currencies: curs,
            };
          }
        }
      }
    }
    const values = {
      ...data[index],
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
      },
    };
    await cmsChannels.Update_Channel(values as any);
    // getContent(tableSearchState);
    // setIsLoading(false);
  };
  const setSelectedTvGuideRadio = async (value: any, index: any) => {
    data[index].epg.epg_url_channel = value;
    if (data[index].payperview != undefined) {
      if (data[index].payperview.currencies != undefined) {
        if (data[index].payperview.currencies.length > 0) {
          if (data[index].payperview.currencies[0].price != undefined) {
            var curs = [] as any;
            deployment?.currencies.forEach((cur, index) => {
              curs.push({
                iso3: cur.isoCurrency,
                credits_rented: 0,
                credits_purchased: 0,
                key: index,
              });
            });
            data[index].payperview = {
              enable: data[index].payperview.enable,
              rule: '',
              currencies: curs,
            };
          } else {
            var curs = [] as any;
            deployment?.currencies.forEach((cur, index) => {
              curs.push({
                iso3: cur.isoCurrency,
                credits_rented: 0,
                credits_purchased: 0,
                key: index,
              });
            });
            data[index].payperview = {
              enable: data[index].payperview.enable,
              rule: '',
              currencies: curs,
            };
          }
        }
      }
    }
    const values = {
      ...data[index],
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
      },
    };
    await cmsRadios.Update_Radio(values as any);
    getContent(tableSearchState);
    setIsLoading(false);
  };

  const clearSelectedTvGuideChannel = async (value: any, index: any) => {
    if (data[index].epg != undefined) {
      if (data[index].epg.epg_url_channel == undefined) {
        data[index].epg['epg_url_channel'] = '';
        data[index].epg['epg_url_id'] = '';
        data[index].epg['epg_import_start_date'] = '';
      } else {
        data[index].epg.epg_url_channel = '';
        data[index].epg.epg_url_id = '';
        data[index].epg.epg_import_start_date = '';
      }
    } else {
      data[index]['epg'] = [];
      data[index].epg['epg_url_channel'] = '';
      data[index].epg['epg_url_id'] = '';
      data[index].epg['epg_import_start_date'] = '';
    }
    if (data[index].payperview != undefined) {
      if (data[index].payperview.currencies != undefined) {
        if (data[index].payperview.currencies.length > 0) {
          if (data[index].payperview.currencies[0].price != undefined) {
            var curs = [] as any;
            deployment?.currencies.forEach((cur, index) => {
              curs.push({
                iso3: cur.isoCurrency,
                credits_rented: 0,
                credits_purchased: 0,
                key: index,
              });
            });
            data[index].payperview = {
              enable: data[index].payperview.enable,
              rule: '',
              currencies: curs,
            };
          } else {
            var curs = [] as any;
            deployment?.currencies.forEach((cur, index) => {
              curs.push({
                iso3: cur.isoCurrency,
                credits_rented: 0,
                credits_purchased: 0,
                key: index,
              });
            });
            data[index].payperview = {
              enable: data[index].payperview.enable,
              rule: '',
              currencies: curs,
            };
          }
        }
      }
    }
    const values = {
      ...data[index],
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
      },
    };
    await cmsChannels.Update_Channel(values as any);
    setIsLoading(false);
  };
  const clearSelectedTvGuideRadio = async (value: any, index: any) => {
    if (data[index].epg != undefined) {
      if (data[index].epg.epg_url_channel == undefined) {
        data[index].epg['epg_url_channel'] = '';
        data[index].epg['epg_url_id'] = '';
        data[index].epg['epg_import_start_date'] = '';
      } else {
        data[index].epg.epg_url_channel = '';
        data[index].epg.epg_url_id = '';
        data[index].epg.epg_import_start_date = '';
      }
    } else {
      data[index]['epg'] = [];
      data[index].epg['epg_url_channel'] = '';
      data[index].epg['epg_url_id'] = '';
      data[index].epg['epg_import_start_date'] = '';
    }
    if (data[index].payperview != 'undefined') {
      if (data[index].payperview.currencies != undefined) {
        if (data[index].payperview.currencies.length > 0) {
          if (data[index].payperview.currencies[0].price != undefined) {
            var curs = [] as any;
            deployment?.currencies.forEach((cur, index) => {
              curs.push({
                iso3: cur.isoCurrency,
                credits_rented: 0,
                credits_purchased: 0,
                key: index,
              });
            });
            data[index].payperview = {
              enable: data[index].payperview.enable,
              rule: '',
              currencies: curs,
            };
          } else {
            var curs = [] as any;
            deployment?.currencies.forEach((cur, index) => {
              curs.push({
                iso3: cur.isoCurrency,
                credits_rented: 0,
                credits_purchased: 0,
                key: index,
              });
            });
            data[index].payperview = {
              enable: data[index].payperview.enable,
              rule: '',
              currencies: curs,
            };
          }
        }
      }
    }
    const values = {
      ...data[index],
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
      },
    };
    await cmsRadios.Update_Radio(values as any);
    setIsLoading(false);
  };

  const columnsEpgChannels: IReactBCTableColumn<any>[] = [
    {
      title: t('name'),
      dataKey: 'name',
      width: '15%',
    },
    {
      title: t('tv-guide-source'),
      dataKey: 'epg.epg_url_id',
      width: '20%',
      cell: (row, index) => (
        <div style={{ color: 'green' }}>
          {params.type === 'epgs_channels' && (
            <Select
              disabled={row?.epg?.epg_url_channel != ''}
              showSearch={true}
              loading={isLoading}
              style={{ width: '100%' }}
              defaultValue={row.epg !== undefined ? row.epg.epg_url_id : ''}
              className="default-select"
              onSelect={(value) => {
                setSelectedTvGuideSourceChannels(value, index);
              }}
            >
              {row.epgURL?.map((epgurl) => (
                <Select.Option value={epgurl._id} key={epgurl._id}>
                  {epgurl.name} {t('key-0')}
                  {epgurl.supplier}
                  {t('key')}
                </Select.Option>
              ))}
            </Select>
          )}
          {params.type === 'epgs_radios' && (
            <Select
              disabled={row?.epg?.epg_url_channel != ''}
              showSearch={true}
              loading={isLoading}
              style={{ width: '100%' }}
              defaultValue={row.epg !== undefined ? row.epg.epg_url_id : ''}
              className="default-select"
              onSelect={(value) => {
                setSelectedTvGuideSourceRadios(value, index);
              }}
            >
              {row.epgURL?.map((epgurl) => (
                <Select.Option value={epgurl._id} key={epgurl._id}>
                  {epgurl.name} {t('key-0')}
                  {epgurl.supplier}
                  {t('key')}
                </Select.Option>
              ))}
            </Select>
          )}
        </div>
      ),
    },
    {
      title: t('tv-guide-source-channel'),
      dataKey: 'epg.epg_url_channel',
      width: '20%',
      cell: (row, index) => (
        <div style={{ color: 'green' }}>
          {row.epg?.epg_url_channel != '' && <div>{row.epg?.epg_url_channel}</div>}
          {row.epg?.epg_url_channel == '' && params.type === 'epgs_channels' && row.epgSupplier == 'TV Profile' && (
            <Select showSearch={true} style={{ width: '100%' }} defaultValue={row.epg !== undefined ? row.epg?.epg_url_channel : ''} className="default-select" onSelect={(value) => setSelectedTvGuideChannel(value, index)}>
              {row.epgChannels?.map((ch: any, index: any) => {
                return (
                  <Select.Option value={ch.id} key={index}>
                    {ch.channel}
                  </Select.Option>
                );
              })}
            </Select>
          )}
          {row.epg?.epg_url_channel == '' && params.type === 'epgs_channels' && row.epgSupplier == 'EPG Best' && (
            <Select showSearch={true} style={{ width: '100%' }} defaultValue={row.epg !== undefined ? row.epg?.epg_url_channel : ''} className="default-select" onSelect={(value) => setSelectedTvGuideChannel(value, index)}>
              {row.epgChannels?.map((ch: any, index: any) => {
                return (
                  <Select.Option value={ch.tvg_id} key={index}>
                    {ch.display_name}
                  </Select.Option>
                );
              })}
            </Select>
          )}
          {row.epg?.epg_url_channel == '' && params.type === 'epgs_channels' && row.epgSupplier == 'XMLTV' && (
            <Select showSearch={true} style={{ width: '100%' }} defaultValue={row.epg !== undefined ? row.epg?.epg_url_channel : ''} className="default-select" onSelect={(value) => setSelectedTvGuideChannel(value, index)}>
              {row.epgChannels?.map((ch: any, index: any) => {
                return (
                  <Select.Option value={ch.id} key={index}>
                    {ch.channel}
                  </Select.Option>
                );
              })}
            </Select>
          )}

          {row.epg?.epg_url_channel == '' && params.type === 'epgs_radios' && row.epgSupplier != 'EPG Best' && (
            <Select showSearch={true} style={{ width: '100%' }} defaultValue={row.epg !== undefined ? row.epg?.epg_url_channel : ''} className="default-select" onSelect={(value) => setSelectedTvGuideRadio(value, index)}>
              {row.epgRadios?.map((ch: any, index: any) => {
                return (
                  <Select.Option value={ch.channel} key={index}>
                    {ch.channel}
                  </Select.Option>
                );
              })}
            </Select>
          )}
          {row.epg?.epg_url_channel == '' && params.type === 'epgs_radios' && row.epgSupplier == 'EPG Best' && (
            <Select showSearch={true} style={{ width: '100%' }} defaultValue={row.epg !== undefined ? row.epg?.epg_url_channel : ''} className="default-select" onSelect={(value) => setSelectedTvGuideChannel(value, index)}>
              {row.epgChannels?.map((ch: any, index: any) => {
                return (
                  <Select.Option value={ch.tvg_id} key={index}>
                    {ch.display_name}
                  </Select.Option>
                );
              })}
            </Select>
          )}
        </div>
      ),
    },
    {
      title: t('tv-guide-till'),
      dataKey: 'epgdata',
      width: '20%',
      cell: (row) => (
        <div style={{ color: 'green' }}>
          {row.epg !== undefined && <div>{row.epg.epg_import_end_date !== undefined && <div>{moment.unix(row.epg.epg_import_end_date).format('lll')}</div>}</div>}
          {row.epg !== undefined && (
            <div>
              {row.epg.epg_import_end_date === undefined && row.epg?.epg_url_channel !== '' && (
                <div>
                  <div style={{ color: 'orange' }}>{t('no-data-please-import')}</div>
                  <div style={{ color: 'green' }}>{t('mapping-ok')}</div>
                </div>
              )}
            </div>
          )}
          {row.epg !== undefined && <div>{(row.epg?.epg_url_channel === undefined || row.epg?.epg_url_channel === '') && <>{(row.epg?.epg_url_channel === '' || row.epg?.epg_url_channel === undefined) && <div style={{ color: 'orange' }}>{t('select-a-tv-guide-source-channel')}</div>}</>}</div>}
          {row.epg === undefined && (
            <div>
              <div style={{ color: 'red' }}>{t('select-a-tv-guide-source')}</div>
            </div>
          )}
        </div>
      ),
    },
    {
      dataKey: 'actions',
      title: t('actions'),
      width: '30%',
      cell: (row, index) => {
        return (
          <Col style={{ display: 'flex', flexDirection: 'row' }}>
            <Row className="align-items-center">
              {params.type === 'epgs_channels' && (
                <a href={openDetails(row._id)}>
                  <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                    <FontAwesomeIcon className="action-icon" icon={faMemo} />
                    <div>{t('channel-details-0')}</div>
                  </Button>
                </a>
              )}
              {params.type === 'epgs_radios' && (
                <a href={openDetails(row._id)}>
                  <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                    <FontAwesomeIcon className="action-icon" icon={faMemo} />
                    <div>{t('radio-details-0')}</div>
                  </Button>
                </a>
              )}
              {params.type === 'epgs_channels' && row.epg?.epg_url_channel != '' && (
                <a onClick={() => clearLink(row, index)}>
                  <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                    <FontAwesomeIcon className="action-icon" icon={faLinkSlash} />
                    <div>{t('clear-link')}</div>
                  </Button>
                </a>
              )}
              {params.type === 'epgs_radios' && row.epg?.epg_url_channel != '' && (
                <a onClick={() => clearLink(row, index)}>
                  <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                    <FontAwesomeIcon className="action-icon" icon={faLinkSlash} />
                    <div>{t('clear-link-0')}</div>
                  </Button>
                </a>
              )}
            </Row>
          </Col>
        );
      },
    },
  ];
  const columnsEpg: IReactBCTableColumn<any>[] = [
    {
      title: t('name'),
      dataKey: 'name',
      width: '10%',
    },
    {
      title: t('url'),
      dataKey: 'url',
      width: '60%',
    },
    {
      title: t('supplier'),
      dataKey: 'supplier',
      width: '10%',
    },
    {
      title: t('format'),
      dataKey: 'type',
      width: '10%',
    },
    {
      title: t('status'),
      dataKey: 'status',
      width: '10%',
      cell: (row) => <div>{row.status === t('active-0') && <span style={{ color: 'green' }}>{row.status}</span>}</div>,
    },
  ];

  const columnsProviders: IReactBCTableColumn<any>[] = [
    {
      title: t('image'),
      dataKey: 'images',
      width: '10%',
      cell: (row) => (
        <div className="text-center" style={{ backgroundColor: '#ececec', display: 'flex', padding: 10, justifyContent: 'center' }}>
          {row.images.square != undefined && <Image width="75px" src={'https://cloudtv.akamaized.net/' + row.images.square}></Image>}
          {row.images.poster != undefined && <Image width="100px" src={'https://cloudtv.akamaized.net/' + row.images.poster}></Image>}
          {row.images.widescreen != undefined && <Image width="160px" src={'https://cloudtv.akamaized.net/' + row.images.widescreen}></Image>}
        </div>
      ),
    },

    {
      title: t('name'),
      dataKey: 'name',
      width: '15%',
      filter: 'text',
      hasSort: true,
    },
    {
      title: t('provider'),
      dataKey: 'epgdata',
      width: '20%',
      cell: (row) => <div>{row.provider.name}</div>,
    },
    {
      title: t('reject-reason'),
      dataKey: 'provider_reject_reason',
      width: '20%',
      filter: 'text',
      hasSort: true,
    },
    {
      dataKey: 'actions',
      title: t('actions'),
      cell: (row) => {
        return (
          <Col style={{ display: 'flex', flexDirection: 'row' }}>
            <Row className="align-items-center">
              <Button
                onClick={() => {
                  setRowData(row);
                  setShowRejectModal(true);
                }}
                className="mx-2 cursor-pointer"
                style={{ height: '100%' }}
                type="text"
              >
                <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faSquareXmark} />
                <div>{t('reject')}</div>
              </Button>
            </Row>

            <Row className="align-items-center">
              <Button
                onClick={() => {
                  confirm({
                    title: t('are-you-sure-you-want-to-approve-this-content-item'),
                    okText: 'Yes',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                      updateItem(row, '', params.type, 'accepted');
                    },
                    onCancel() {},
                  });
                }}
                className="mx-2 cursor-pointer"
                style={{ height: '100%' }}
                type="text"
              >
                <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faSquareCheck} />
                <div>{t('approve')}</div>
              </Button>
            </Row>

            <Row className="align-items-center">
              <Button
                onClick={() => {
                  playItem(row);
                }}
                className="mx-2 cursor-pointer"
                style={{ height: '100%' }}
                type="text"
              >
                <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faCirclePlay} />
                <div>{t('play')}</div>
              </Button>
            </Row>

            <Row className="align-items-center">
              <a href={openDetails(row._id)}>
                <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                  <FontAwesomeIcon className="action-icon" icon={faMemo} />
                  <div>{t('open-details')}</div>
                </Button>
              </a>
            </Row>
          </Col>
        );
      },
    },
  ];
  const columnsRTV_Provider: IReactBCTableColumn<any>[] = [
    {
      title: t('image'),
      dataKey: 'images',
      width: '10%',
      cell: (row) => (
        <div className="text-center" style={{ backgroundColor: '#ececec', display: 'flex', padding: 10, justifyContent: 'center' }}>
          <Image width="75px" src={'https://cloudtv.akamaized.net/' + row.images.square}></Image>
        </div>
      ),
    },
    {
      title: t('number-4'),
      dataKey: 'number',
      filter: 'number',
      hasSort: true,
      width: '10%',
      cell: (row) => (
        <div className="text-center" style={{ fontSize: 18 }}>
          {row.number}
        </div>
      ),
    },

    {
      title: t('name'),
      dataKey: 'name',
      width: '15%',
      filter: 'text',
      hasSort: true,
    },
    {
      title: t('tv-guide-till-0'),
      dataKey: 'epgdata',
      width: '20%',
      cell: (row) => (
        <div style={{ color: 'green' }}>
          {row.epg !== undefined && (
            <>
              <div>
                {row.epg.epg_import_end_date !== undefined && (
                  <div>
                    {/* {moment.unix(row.epg.epg_import_start_date).format('lll')}
                    <br /> */}

                    {moment.unix(row.epg.epg_import_end_date).add(1, 'minute').utc().format('lll')}
                  </div>
                )}
              </div>

              <div>
                {row.epg.epg_import_end_date === undefined && (
                  <div>
                    <div style={{ color: 'orange' }}>{t('publish-your-bundles')}</div>
                    <div style={{ color: 'green' }}>{t('linking-source-is-ok')}</div>
                  </div>
                )}
              </div>

              <div>{row.epg?.epg_url_channel === undefined && <div style={{ color: 'red' }}>{t('link-your-source')}</div>}</div>
            </>
          )}
        </div>
      ),
    },
    {
      title: t('reject-reason'),
      dataKey: 'provider_reject_reason',
      width: '20%',
      filter: 'text',
      hasSort: true,
    },
    {
      dataKey: 'actions',
      title: t('actions'),
      cell: (row) => {
        return (
          <Col style={{ display: 'flex', flexDirection: 'row' }}>
            <Row className="align-items-center">
              <Button
                onClick={() => {
                  playItem(row);
                }}
                className="mx-2 cursor-pointer"
                style={{ height: '100%' }}
                type="text"
              >
                <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faCirclePlay} />
                <div>{t('play')}</div>
              </Button>
            </Row>
            <Row className="align-items-center">
              <Button
                onClick={() => {
                  confirm({
                    title: 'Are you sure you want to delete this content item. After deletion you need to publish your bundles and lists to make sure the apps are updated to.',
                    okText: 'Yes',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                      deleteItem(row._id);
                    },
                    onCancel() {},
                  });
                }}
                className="mx-2 cursor-pointer"
                style={{ height: '100%' }}
                type="text"
              >
                <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
                <div>{t('delete')}</div>
              </Button>
            </Row>
            <Row className="align-items-center">
              <a href={openDetails(row._id)}>
                <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                  <FontAwesomeIcon className="action-icon" icon={faMemo} />
                  <div>{t('open-details')}</div>
                </Button>
              </a>
            </Row>
          </Col>
        );
      },
    },
  ];
  const columnsCam_Provider: IReactBCTableColumn<any>[] = [
    {
      title: t('image'),
      dataKey: 'images',
      width: '10%',
      cell: (row) => (
        <div className="text-center" style={{ backgroundColor: '#ececec', display: 'flex', padding: 10, justifyContent: 'center' }}>
          <Image width="75px" src={'https://cloudtv.akamaized.net/' + row.images.square}></Image>
        </div>
      ),
    },
    {
      title: t('number-4'),
      dataKey: 'number',
      width: '10%',
      filter: 'number',
      hasSort: true,
      cell: (row) => (
        <div className="text-center" style={{ fontSize: 18 }}>
          {row.number}
        </div>
      ),
    },
    {
      title: t('name'),
      dataKey: 'name',
      width: '30%',
      filter: 'text',
      hasSort: true,
    },
    {
      title: t('reject-reason'),
      dataKey: 'provider_reject_reason',
      width: '20%',
      filter: 'text',
      hasSort: true,
    },
    {
      dataKey: 'actions',
      title: t('actions'),

      cell: (row) => {
        return (
          <Col style={{ display: 'flex', flexDirection: 'row' }}>
            <Row className="align-items-center">
              <Button
                onClick={() => {
                  playItem(row);
                }}
                className="mx-2 cursor-pointer"
                style={{ height: '100%' }}
                type="text"
              >
                <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faCirclePlay} />
                <div>{t('play')}</div>
              </Button>
            </Row>
            <Row className="align-items-center">
              <Button
                onClick={() => {
                  confirm({
                    title: 'Are you sure you want to delete this content item. After deletion you need to publish your bundles and lists to make sure the apps are updated to.',
                    okText: 'Yes',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                      deleteItem(row._id);
                    },
                    onCancel() {},
                  });
                }}
                className="mx-2 cursor-pointer"
                style={{ height: '100%' }}
                type="text"
              >
                <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
                <div>{t('delete')}</div>
              </Button>
            </Row>
            <Row className="align-items-center">
              <a href={openDetails(row._id)}>
                <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                  <FontAwesomeIcon className="action-icon" icon={faMemo} />
                  <div>{t('open-details')}</div>
                </Button>
              </a>
            </Row>
          </Col>
        );
      },
    },
  ];
  const albumPodcastColumns_Provider: IReactBCTableColumn<any>[] = [
    {
      title: t('image'),
      dataKey: 'images',
      width: '10%',
      cell: (row) => (
        <div className="text-center" style={{ backgroundColor: '#ececec' }}>
          <Image width="75px" src={'https://cloudtv.akamaized.net/' + row.images.square}></Image>
        </div>
      ),
    },
    {
      title: t('name'),
      dataKey: 'name',
      filter: 'text',
      hasSort: true,
      width: '55%',
    },
    {
      title: t('reject-reason'),
      dataKey: 'provider_reject_reason',
      width: '20%',
      filter: 'text',
      hasSort: true,
    },
    {
      dataKey: 'actions',
      title: t('actions'),
      cell: (row) => {
        return (
          <Col style={{ display: 'flex', flexDirection: 'row' }}>
            <Row className="align-items-center">
              <Button
                onClick={() => {
                  confirm({
                    title: 'Are you sure you want to delete this content item. After deletion you need to publish your bundles and lists to make sure the apps are updated to.',
                    okText: 'Yes',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                      deleteItem(row._id);
                    },
                    onCancel() {},
                  });
                }}
                className="mx-2 cursor-pointer"
                style={{ height: '100%' }}
                type="text"
              >
                <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
                <div>{t('delete')}</div>
              </Button>
            </Row>
            <Row className="align-items-center">
              <a href={openDetails(row._id)}>
                <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                  <FontAwesomeIcon className="action-icon" icon={faMemo} />
                  <div>{t('open-details')}</div>
                </Button>
              </a>
            </Row>
          </Col>
        );
      },
    },
  ];
  const columnsMovies_Provider: IReactBCTableColumn<any>[] = [
    {
      title: t('image'),
      dataKey: 'images',
      width: '10%',
      cell: (row) => (
        <div className="text-center" style={{ backgroundColor: '#ececec' }}>
          <Image width="100px" src={'https://cloudtv.akamaized.net/' + row.images.poster}></Image>
        </div>
      ),
    },

    {
      title: t('name'),
      dataKey: 'name',
      width: '55%',
      filter: 'text',
      hasSort: true,
    },
    {
      title: t('reject-reason'),
      dataKey: 'provider_reject_reason',
      width: '20%',
      filter: 'text',
      hasSort: true,
    },
    {
      dataKey: 'actions',
      title: t('actions'),

      cell: (row) => {
        return (
          <Col style={{ display: 'flex', flexDirection: 'row' }}>
            <Row className="align-items-center">
              <Button
                onClick={() => {
                  playItem(row);
                }}
                className="mx-2 cursor-pointer"
                style={{ height: '100%' }}
                type="text"
              >
                <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faCirclePlay} />
                <div>{t('play')}</div>
              </Button>
            </Row>
            <Row className="align-items-center">
              <Button
                onClick={() => {
                  confirm({
                    title: 'Are you sure you want to delete this content item. After deletion you need to publish your bundles and lists to make sure the apps are updated to.',
                    okText: 'Yes',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                      deleteItem(row._id);
                    },
                    onCancel() {},
                  });
                }}
                className="mx-2 cursor-pointer"
                style={{ height: '100%' }}
                type="text"
              >
                <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
                <div>{t('delete')}</div>
              </Button>
            </Row>
            <Row className="align-items-center">
              <a href={openDetails(row._id)}>
                <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                  <FontAwesomeIcon className="action-icon" icon={faMemo} />
                  <div>{t('open-details')}</div>
                </Button>
              </a>
            </Row>
          </Col>
        );
      },
    },
  ];
  const columnsShorts_Provider: IReactBCTableColumn<any>[] = [
    {
      title: t('image'),
      dataKey: 'images',
      width: '10%',
      cell: (row) => (
        <div className="text-center" style={{ backgroundColor: '#ececec' }}>
          <Image width="100px" src={'https://cloudtv.akamaized.net/' + row.images.poster}></Image>
        </div>
      ),
    },

    {
      title: t('name'),
      dataKey: 'name',
      width: '55%',
      filter: 'text',
      hasSort: true,
    },
    {
      title: t('reject-reason'),
      dataKey: 'provider_reject_reason',
      width: '20%',
      filter: 'text',
      hasSort: true,
    },

    {
      dataKey: 'actions',
      title: t('actions'),

      cell: (row) => {
        return (
          <Col style={{ display: 'flex', flexDirection: 'row' }}>
            <Row className="align-items-center">
              <Button
                onClick={() => {
                  playItem(row);
                }}
                className="mx-2 cursor-pointer"
                style={{ height: '100%' }}
                type="text"
              >
                <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faCirclePlay} />
                <div>{t('play')}</div>
              </Button>
            </Row>
            <Row className="align-items-center">
              <Button
                onClick={() => {
                  confirm({
                    title: 'Are you sure you want to delete this content item. After deletion you need to publish your bundles and lists to make sure the apps are updated to.',
                    okText: 'Yes',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                      deleteItem(row._id);
                    },
                    onCancel() {},
                  });
                }}
                className="mx-2 cursor-pointer"
                style={{ height: '100%' }}
                type="text"
              >
                <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
                <div>{t('delete')}</div>
              </Button>
            </Row>
            <Row className="align-items-center">
              <a href={openDetails(row._id)}>
                <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                  <FontAwesomeIcon className="action-icon" icon={faMemo} />
                  <div>{t('open-details')}</div>
                </Button>
              </a>
            </Row>
          </Col>
        );
      },
    },
  ];
  const columnsGames_Provider: IReactBCTableColumn<any>[] = [
    {
      title: t('image'),
      dataKey: 'images',
      width: '10%',
      cell: (row) => (
        <div className="text-center" style={{ backgroundColor: '#ececec' }}>
          <Image width="100px" src={'https://cloudtv.akamaized.net/' + row.images.widescreen}></Image>
        </div>
      ),
    },
    {
      title: t('name'),
      dataKey: 'name',
      width: '40%',
      filter: 'text',
      hasSort: true,
    },
    {
      title: t('reject-reason'),
      dataKey: 'provider_reject_reason',
      width: '20%',
      filter: 'text',
      hasSort: true,
    },

    {
      dataKey: 'actions',
      title: t('actions'),

      cell: (row) => {
        return (
          <Col style={{ display: 'flex', flexDirection: 'row' }}>
            <Row className="align-items-center">
              <Button
                onClick={() => {
                  confirm({
                    title: 'Are you sure you want to delete this content item. After deletion you need to publish your bundles and lists to make sure the apps are updated to.',
                    okText: 'Yes',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                      deleteItem(row._id);
                    },
                    onCancel() {},
                  });
                }}
                className="mx-2 cursor-pointer"
                style={{ height: '100%' }}
                type="text"
              >
                <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
                <div>{t('delete')}</div>
              </Button>
            </Row>
            <Row className="align-items-center">
              <a href={openDetails(row._id)}>
                <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                  <FontAwesomeIcon className="action-icon" icon={faMemo} />
                  <div>{t('open-details')}</div>
                </Button>
              </a>
            </Row>
          </Col>
        );
      },
    },
  ];
  const columnsCourse_Provider: IReactBCTableColumn<any>[] = [
    {
      title: t('image'),
      dataKey: 'images',
      width: '10%',
      cell: (row) => (
        <div className="text-center" style={{ backgroundColor: '#ececec' }}>
          <Image width="160px" height="90px" src={'https://cloudtv.akamaized.net/' + row.images.widescreen}></Image>
        </div>
      ),
    },
    {
      title: t('names'),
      dataKey: 'name',
      width: '65%',
      filter: 'text',
      hasSort: true,
    },
    {
      title: t('reject-reason'),
      dataKey: 'provider_reject_reason',
      width: '20%',
      filter: 'text',
      hasSort: true,
    },
    {
      dataKey: 'actions',
      title: t('actions'),

      cell: (row) => {
        return (
          <Col style={{ display: 'flex', flexDirection: 'row' }}>
            <Row className="align-items-center">
              <Button
                onClick={() => {
                  confirm({
                    title: 'Are you sure you want to delete this content item. After deletion you need to publish your bundles and lists to make sure the apps are updated to.',
                    okText: 'Yes',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                      deleteItem(row._id);
                    },
                    onCancel() {},
                  });
                }}
                className="mx-2 cursor-pointer"
                style={{ height: '100%' }}
                type="text"
              >
                <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
                <div>{t('delete')}</div>
              </Button>
            </Row>
            <Row className="align-items-center">
              <a href={openDetails(row._id)}>
                <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                  <FontAwesomeIcon className="action-icon" icon={faMemo} />
                  <div>{t('open-details')}</div>
                </Button>
              </a>
            </Row>
          </Col>
        );
      },
    },
  ];
  const columnsSeries_Provider: IReactBCTableColumn<any>[] = [
    {
      title: t('image'),
      dataKey: 'images',
      width: '10%',
      cell: (row) => (
        <div className="text-center" style={{ backgroundColor: '#ececec' }}>
          <Image width="160px" height="90px" src={'https://cloudtv.akamaized.net/' + row.images.widescreen}></Image>
        </div>
      ),
    },
    {
      title: t('name'),
      dataKey: 'name',
      width: '65%',
      filter: 'text',
      hasSort: true,
    },
    {
      title: t('reject-reason'),
      dataKey: 'provider_reject_reason',
      width: '20%',
      filter: 'text',
      hasSort: true,
    },
    {
      dataKey: 'actions',
      title: t('actions'),

      cell: (row) => {
        return (
          <Col style={{ display: 'flex', flexDirection: 'row' }}>
            <Row className="align-items-center">
              <Button
                onClick={() => {
                  confirm({
                    title: 'Are you sure you want to delete this content item. After deletion you need to publish your bundles and lists to make sure the apps are updated to.',
                    okText: 'Yes',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                      deleteItem(row._id);
                    },
                    onCancel() {},
                  });
                }}
                className="mx-2 cursor-pointer"
                style={{ height: '100%' }}
                type="text"
              >
                <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
                <div>{t('delete')}</div>
              </Button>
            </Row>
            <Row className="align-items-center">
              <Button
                onClick={() => {
                  setRowData(row);
                  setShowSeasonAddModel(true);
                }}
                type="text"
                className="mx-2 cursor-pointer"
                style={{ height: '100%' }}
              >
                <FontAwesomeIcon className="action-icon" icon={faFilePlus} />
                <div>Add Seasons</div>
              </Button>
            </Row>
            <Row className="align-items-center">
              <a href={openDetails(row._id)}>
                <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                  <FontAwesomeIcon className="action-icon" icon={faMemo} />
                  <div>{t('open-details')}</div>
                </Button>
              </a>
            </Row>
          </Col>
        );
      },
    },
  ];
  const columnsSeasons_Provider: IReactBCTableColumn<any>[] = [
    {
      title: t('image'),
      dataKey: 'images',
      width: '10%',
      cell: (row) => (
        <div className="text-center" style={{ backgroundColor: '#ececec' }}>
          <Image width="100px" src={'https://cloudtv.akamaized.net/' + row.images.poster}></Image>
        </div>
      ),
    },
    {
      title: t('season-name-0'),
      dataKey: 'name',
      filter: 'text',
      hasSort: true,
    },
    {
      title: t('reject-reason'),
      dataKey: 'provider_reject_reason',
      width: '20%',
      filter: 'text',
      hasSort: true,
    },
    {
      dataKey: 'actions',
      title: t('actions'),
      width: '5%',
      cell: (row) => {
        return (
          <Col style={{ display: 'flex', flexDirection: 'row' }}>
            <Row>
              <Row className="align-items-center">
                <a href={openDetails(row._id)}>
                  <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                    <FontAwesomeIcon className="action-icon" icon={faMemo} />
                    <div>{t('open-details')}</div>
                  </Button>
                </a>
              </Row>
            </Row>
          </Col>
        );
      },
    },
  ];

  const columnsRTV: IReactBCTableColumn<any>[] = [
    {
      title: t('image'),
      dataKey: 'images',
      width: '10%',
      cell: (row) => (
        <div className="text-center" style={{ backgroundColor: '#ececec', display: 'flex', padding: 10, justifyContent: 'center' }}>
          <Image width="75px" src={'https://cloudtv.akamaized.net/' + row.images.square}></Image>
        </div>
      ),
    },
    {
      title: t('number-4'),
      dataKey: 'number',
      filter: 'number',
      hasSort: true,
      width: '10%',
      cell: (row) => (
        <div className="text-center" style={{ fontSize: 18 }}>
          {row.number}
        </div>
      ),
    },

    {
      title: t('name'),
      dataKey: 'name',
      width: '15%',
      filter: 'text',
      hasSort: true,
    },
    {
      title: t('tv-guide-till-0'),
      dataKey: 'epgdata',
      width: '20%',
      cell: (row) => (
        <div style={{ color: 'green' }}>
          {row.epg !== undefined && (
            <>
              <div>
                {row.epg.epg_import_end_date !== undefined && (
                  <div>
                    {/* {moment.unix(row.epg.epg_import_start_date).format('lll')}
                    <br /> */}

                    {moment.unix(row.epg.epg_import_end_date).add(1, 'minute').utc().format('lll')}
                  </div>
                )}
              </div>

              <div>
                {row.epg.epg_import_end_date === undefined && (
                  <div>
                    <div style={{ color: 'orange' }}>{t('publish-your-bundles')}</div>
                    <div style={{ color: 'green' }}>{t('linking-source-is-ok')}</div>
                  </div>
                )}
              </div>

              <div>{row.epg?.epg_url_channel === undefined && <div style={{ color: 'red' }}>{t('link-your-source')}</div>}</div>
            </>
          )}
        </div>
      ),
    },
    {
      title: t('notes-1'),
      dataKey: 'meta',
      width: '20%',
      filter: 'text',
      hasSort: true,
    },
    {
      dataKey: 'actions',
      title: t('actions'),
      cell: (row) => {
        return (
          <Col style={{ display: 'flex', flexDirection: 'row' }}>
            <Row className="align-items-center">
              <Button
                onClick={() => {
                  playItem(row);
                }}
                className="mx-2 cursor-pointer"
                style={{ height: '100%' }}
                type="text"
              >
                <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faCirclePlay} />
                <div>{t('play')}</div>
              </Button>
            </Row>
            <Row className="align-items-center">
              <Button
                onClick={() => {
                  confirm({
                    title: 'Are you sure you want to delete this content item. After deletion you need to publish your bundles and lists to make sure the apps are updated to.',
                    okText: 'Yes',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                      deleteItem(row._id);
                    },
                    onCancel() {},
                  });
                }}
                className="mx-2 cursor-pointer"
                style={{ height: '100%' }}
                type="text"
              >
                <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
                <div>{t('delete')}</div>
              </Button>
            </Row>
            <Row className="align-items-center">
              <a href={openDetails(row._id)}>
                <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                  <FontAwesomeIcon className="action-icon" icon={faMemo} />
                  <div>{t('open-details')}</div>
                </Button>
              </a>
            </Row>
          </Col>
        );
      },
    },
  ];
  const columnsCam: IReactBCTableColumn<any>[] = [
    {
      title: t('image'),
      dataKey: 'images',
      width: '10%',
      cell: (row) => (
        <div className="text-center" style={{ backgroundColor: '#ececec', display: 'flex', padding: 10, justifyContent: 'center' }}>
          <Image width="75px" src={'https://cloudtv.akamaized.net/' + row.images.square}></Image>
        </div>
      ),
    },
    {
      title: t('number-4'),
      dataKey: 'number',
      width: '10%',
      filter: 'number',
      hasSort: true,
      cell: (row) => (
        <div className="text-center" style={{ fontSize: 18 }}>
          {row.number}
        </div>
      ),
    },
    {
      title: t('name'),
      dataKey: 'name',
      width: '30%',
      filter: 'text',
      hasSort: true,
    },
    {
      dataKey: 'actions',
      title: t('actions'),

      cell: (row) => {
        return (
          <Col style={{ display: 'flex', flexDirection: 'row' }}>
            <Row className="align-items-center">
              <Button
                onClick={() => {
                  playItem(row);
                }}
                className="mx-2 cursor-pointer"
                style={{ height: '100%' }}
                type="text"
              >
                <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faCirclePlay} />
                <div>{t('play')}</div>
              </Button>
            </Row>
            <Row className="align-items-center">
              <Button
                onClick={() => {
                  confirm({
                    title: 'Are you sure you want to delete this content item. After deletion you need to publish your bundles and lists to make sure the apps are updated to.',
                    okText: 'Yes',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                      deleteItem(row._id);
                    },
                    onCancel() {},
                  });
                }}
                className="mx-2 cursor-pointer"
                style={{ height: '100%' }}
                type="text"
              >
                <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
                <div>{t('delete')}</div>
              </Button>
            </Row>
            <Row className="align-items-center">
              <a href={openDetails(row._id)}>
                <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                  <FontAwesomeIcon className="action-icon" icon={faMemo} />
                  <div>{t('open-details')}</div>
                </Button>
              </a>
            </Row>
          </Col>
        );
      },
    },
  ];
  const albumPodcastColumns: IReactBCTableColumn<any>[] = [
    {
      title: t('image'),
      dataKey: 'images',
      width: '10%',
      cell: (row) => (
        <div className="text-center" style={{ backgroundColor: '#ececec' }}>
          <Image width="75px" src={'https://cloudtv.akamaized.net/' + row.images.square}></Image>
        </div>
      ),
    },
    {
      title: t('name'),
      dataKey: 'name',
      filter: 'text',
      hasSort: true,
      width: '55%',
    },
    {
      dataKey: 'actions',
      title: t('actions'),
      cell: (row) => {
        return (
          <Col style={{ display: 'flex', flexDirection: 'row' }}>
            <Row className="align-items-center">
              <Button
                onClick={() => {
                  confirm({
                    title: 'Are you sure you want to delete this content item. After deletion you need to publish your bundles and lists to make sure the apps are updated to.',
                    okText: 'Yes',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                      deleteItem(row._id);
                    },
                    onCancel() {},
                  });
                }}
                className="mx-2 cursor-pointer"
                style={{ height: '100%' }}
                type="text"
              >
                <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
                <div>{t('delete')}</div>
              </Button>
            </Row>
            <Row className="align-items-center">
              <a href={openDetails(row._id)}>
                <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                  <FontAwesomeIcon className="action-icon" icon={faMemo} />
                  <div>{t('open-details')}</div>
                </Button>
              </a>
            </Row>
          </Col>
        );
      },
    },
  ];
  const columnsMovies: IReactBCTableColumn<any>[] = [
    {
      title: t('image'),
      dataKey: 'images',
      width: '10%',
      cell: (row) => (
        <div className="text-center" style={{ backgroundColor: '#ececec' }}>
          <Image width="100px" src={'https://cloudtv.akamaized.net/' + row.images.poster}></Image>
        </div>
      ),
    },
    {
      title: t('released-1'),
      dataKey: 'released',
      width: '10%',
      filter: 'number',
      hasSort: true,
    },
    {
      title: t('name'),
      dataKey: 'name',
      width: '55%',
      filter: 'text',
      hasSort: true,
    },

    {
      dataKey: 'actions',
      title: t('actions'),

      cell: (row) => {
        return (
          <Col style={{ display: 'flex', flexDirection: 'row' }}>
            <Row className="align-items-center">
              <Button
                onClick={() => {
                  playItem(row);
                }}
                className="mx-2 cursor-pointer"
                style={{ height: '100%' }}
                type="text"
              >
                <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faCirclePlay} />
                <div>{t('play')}</div>
              </Button>
            </Row>
            <Row className="align-items-center">
              <Button
                onClick={() => {
                  confirm({
                    title: 'Are you sure you want to delete this content item. After deletion you need to publish your bundles and lists to make sure the apps are updated to.',
                    okText: 'Yes',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                      deleteItem(row._id);
                    },
                    onCancel() {},
                  });
                }}
                className="mx-2 cursor-pointer"
                style={{ height: '100%' }}
                type="text"
              >
                <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
                <div>{t('delete')}</div>
              </Button>
            </Row>
            <Row className="align-items-center">
              <a href={openDetails(row._id)}>
                <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                  <FontAwesomeIcon className="action-icon" icon={faMemo} />
                  <div>{t('open-details')}</div>
                </Button>
              </a>
            </Row>
          </Col>
        );
      },
    },
  ];
  const columnsShorts: IReactBCTableColumn<any>[] = [
    {
      title: t('image'),
      dataKey: 'images',
      width: '10%',
      cell: (row) => (
        <div className="text-center" style={{ backgroundColor: '#ececec' }}>
          <Image width="100px" src={'https://cloudtv.akamaized.net/' + row.images.poster}></Image>
        </div>
      ),
    },

    {
      title: t('name'),
      dataKey: 'name',
      width: '55%',
      filter: 'text',
      hasSort: true,
    },
    {
      title: t('provider'),
      dataKey: 'provider',
      width: '20%',
      filter: 'text',
      hasSort: true,
    },

    {
      dataKey: 'actions',
      title: t('actions'),

      cell: (row) => {
        return (
          <Col style={{ display: 'flex', flexDirection: 'row' }}>
            <Row className="align-items-center">
              <Button
                onClick={() => {
                  playItem(row);
                }}
                className="mx-2 cursor-pointer"
                style={{ height: '100%' }}
                type="text"
              >
                <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faCirclePlay} />
                <div>{t('play')}</div>
              </Button>
            </Row>
            <Row className="align-items-center">
              <Button
                onClick={() => {
                  confirm({
                    title: 'Are you sure you want to delete this content item. After deletion you need to publish your bundles and lists to make sure the apps are updated to.',
                    okText: 'Yes',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                      deleteItem(row._id);
                    },
                    onCancel() {},
                  });
                }}
                className="mx-2 cursor-pointer"
                style={{ height: '100%' }}
                type="text"
              >
                <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
                <div>{t('delete')}</div>
              </Button>
            </Row>
            <Row className="align-items-center">
              <a href={openDetails(row._id)}>
                <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                  <FontAwesomeIcon className="action-icon" icon={faMemo} />
                  <div>{t('open-details')}</div>
                </Button>
              </a>
            </Row>
          </Col>
        );
      },
    },
  ];
  const columnsGames: IReactBCTableColumn<any>[] = [
    {
      title: t('image'),
      dataKey: 'images',
      width: '10%',
      cell: (row) => (
        <div className="text-center" style={{ backgroundColor: '#ececec' }}>
          <Image width="100px" src={'https://cloudtv.akamaized.net/' + row.images.widescreen}></Image>
        </div>
      ),
    },
    {
      title: t('name'),
      dataKey: 'name',
      width: '40%',
      filter: 'text',
      hasSort: true,
    },

    {
      dataKey: 'actions',
      title: t('actions'),

      cell: (row) => {
        return (
          <Col style={{ display: 'flex', flexDirection: 'row' }}>
            <Row className="align-items-center">
              <Button
                onClick={() => {
                  confirm({
                    title: 'Are you sure you want to delete this content item. After deletion you need to publish your bundles and lists to make sure the apps are updated to.',
                    okText: 'Yes',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                      deleteItem(row._id);
                    },
                    onCancel() {},
                  });
                }}
                className="mx-2 cursor-pointer"
                style={{ height: '100%' }}
                type="text"
              >
                <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
                <div>{t('delete')}</div>
              </Button>
            </Row>
            <Row className="align-items-center">
              <a href={openDetails(row._id)}>
                <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                  <FontAwesomeIcon className="action-icon" icon={faMemo} />
                  <div>{t('open-details')}</div>
                </Button>
              </a>
            </Row>
          </Col>
        );
      },
    },
  ];
  const columnsCourse: IReactBCTableColumn<any>[] = [
    {
      title: t('image'),
      dataKey: 'images',
      width: '10%',
      cell: (row) => (
        <div className="text-center" style={{ backgroundColor: '#ececec' }}>
          <Image width="160px" height="90px" src={'https://cloudtv.akamaized.net/' + row.images.widescreen}></Image>
        </div>
      ),
    },
    {
      title: t('names'),
      dataKey: 'name',
      width: '65%',
      filter: 'text',
      hasSort: true,
    },

    {
      dataKey: 'actions',
      title: t('actions'),

      cell: (row) => {
        return (
          <Col style={{ display: 'flex', flexDirection: 'row' }}>
            <Row className="align-items-center">
              <Button
                onClick={() => {
                  confirm({
                    title: 'Are you sure you want to delete this content item. After deletion you need to publish your bundles and lists to make sure the apps are updated to.',
                    okText: 'Yes',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                      deleteItem(row._id);
                    },
                    onCancel() {},
                  });
                }}
                className="mx-2 cursor-pointer"
                style={{ height: '100%' }}
                type="text"
              >
                <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
                <div>{t('delete')}</div>
              </Button>
            </Row>
            <Row className="align-items-center">
              <a href={openDetails(row._id)}>
                <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                  <FontAwesomeIcon className="action-icon" icon={faMemo} />
                  <div>{t('open-details')}</div>
                </Button>
              </a>
            </Row>
          </Col>
        );
      },
    },
  ];
  const columnsSeries: IReactBCTableColumn<any>[] = [
    {
      title: t('image'),
      dataKey: 'images',
      width: '10%',
      cell: (row) => (
        <div className="text-center" style={{ backgroundColor: '#ececec' }}>
          <Image width="160px" height="90px" src={'https://cloudtv.akamaized.net/' + row.images.widescreen}></Image>
        </div>
      ),
    },
    {
      title: t('name'),
      dataKey: 'name',
      width: '65%',
      filter: 'text',
      hasSort: true,
    },

    {
      dataKey: 'actions',
      title: t('actions'),

      cell: (row) => {
        return (
          <Col style={{ display: 'flex', flexDirection: 'row' }}>
            <Row className="align-items-center">
              <Button
                onClick={() => {
                  confirm({
                    title: 'Are you sure you want to delete this content item. After deletion you need to publish your bundles and lists to make sure the apps are updated to.',
                    okText: 'Yes',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                      deleteItem(row._id);
                    },
                    onCancel() {},
                  });
                }}
                className="mx-2 cursor-pointer"
                style={{ height: '100%' }}
                type="text"
              >
                <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
                <div>{t('delete')}</div>
              </Button>
            </Row>
            <Row className="align-items-center">
              <Button
                onClick={() => {
                  setRowData(row);
                  setShowSeasonAddModel(true);
                }}
                type="text"
                className="mx-2 cursor-pointer"
                style={{ height: '100%' }}
              >
                <FontAwesomeIcon className="action-icon" icon={faFilePlus} />
                <div>Add Seasons</div>
              </Button>
            </Row>
            <Row className="align-items-center">
              <a href={openDetails(row._id)}>
                <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                  <FontAwesomeIcon className="action-icon" icon={faMemo} />
                  <div>{t('open-details')}</div>
                </Button>
              </a>
            </Row>
          </Col>
        );
      },
    },
  ];
  const columnsSeasons: IReactBCTableColumn<any>[] = [
    {
      title: t('image'),
      dataKey: 'images',
      width: '10%',
      cell: (row) => (
        <div className="text-center" style={{ backgroundColor: '#ececec' }}>
          <Image width="100px" src={'https://cloudtv.akamaized.net/' + row.images.poster}></Image>
        </div>
      ),
    },
    {
      title: t('season-name-0'),
      dataKey: 'name',
      filter: 'text',
      hasSort: true,
    },
    {
      dataKey: 'actions',
      title: t('actions'),
      width: '5%',
      cell: (row) => {
        return (
          <Col style={{ display: 'flex', flexDirection: 'row' }}>
            <Row>
              <Row className="align-items-center">
                <a href={openDetails(row._id)}>
                  <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                    <FontAwesomeIcon className="action-icon" icon={faMemo} />
                    <div>{t('open-details')}</div>
                  </Button>
                </a>
              </Row>
            </Row>
          </Col>
        );
      },
    },
  ];

  const columnsWorkflows: IReactBCTableColumn<any>[] = [
    {
      title: t('names'),
      dataKey: 'name',
      width: '75%',
      filter: 'text',
      hasSort: true,
    },
    {
      dataKey: 'actions',
      title: t('actions'),

      cell: (row) => {
        return (
          <Col style={{ display: 'flex', flexDirection: 'row' }}>
            <Row className="align-items-center">
              <a onClick={() => deleteItem(row._id)}>
                <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                  <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
                  <div>{t('delete')}</div>
                </Button>
              </a>
            </Row>
            <Row className="align-items-center">
              <a href={openDetails(row._id)}>
                <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                  <FontAwesomeIcon className="action-icon" icon={faMemo} />
                  <div>{t('open-details')}</div>
                </Button>
              </a>
            </Row>
          </Col>
        );
      },
    },
  ];
  const columnsAutomations: IReactBCTableColumn<any>[] = [
    {
      title: t('names'),
      dataKey: 'name',
      width: '75%',
      filter: 'text',
      hasSort: true,
    },
    {
      dataKey: 'actions',
      title: t('actions'),

      cell: (row) => {
        return (
          <Col style={{ display: 'flex', flexDirection: 'row' }}>
            <Row className="align-items-center">
              <a onClick={() => deleteItem(row._id)}>
                <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                  <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
                  <div>{t('delete')}</div>
                </Button>
              </a>
            </Row>
            <Row className="align-items-center">
              <a href={openDetails(row._id)}>
                <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                  <FontAwesomeIcon className="action-icon" icon={faMemo} />
                  <div>{t('open-details')}</div>
                </Button>
              </a>
            </Row>
          </Col>
        );
      },
    },
  ];

  return (
    <div>
      {showUrlModal && (
        <EpgEditUrlModal
          onCancel={() => {
            setShowUrlModal(!showUrlModal);
          }}
          onSubmit={(values: any) => {
            setShowUrlModal(!showUrlModal);
            getContent(tableSearchState);
          }}
          initialData={epg}
          visible={showUrlModal}
        />
      )}
      {showPlayerModal && (
        <PlayerModal
          onCancel={() => {
            setShowPlayerModal(!showPlayerModal);
          }}
          initialData={player}
          type={params.type}
          visible={showPlayerModal}
        />
      )}
      {showRejectModal && (
        <RejectModal
          onCancel={() => {
            setShowRejectModal(!showRejectModal);
          }}
          initialData={{
            type: params.type,
            content: rowData,
          }}
          visible={showRejectModal}
          onSubmit={updateItem}
        />
      )}
      {showSeasonAddModel && (
        <SeasonAddModal
          onCancel={() => {
            setShowSeasonAddModel(!showSeasonAddModel);
          }}
          dataIn={rowData}
          service_name={params.service_name}
          clientName={params.clientName}
          visible={showSeasonAddModel}
        />
      )}
      {!isLoading && (
        <ReactBCTable
          onChange={(pageNumber, pageSize, pageSort, filters) => getContent({ pageNumber, pageSize, pageSort, filters })}
          isLoading={isLoading}
          totalRecords={pagination.count}
          pageSize={pagination.pageSize}
          currentPage={pagination.pageNumber}
          tableId={'cms_table'}
          columns={
            params.variant === 'providers'
              ? columnsProviders
              : params.type === 'channels' || params.type === 'radios'
              ? isProvider
                ? columnsRTV_Provider
                : columnsRTV
              : params.type === 'cams'
              ? isProvider
                ? columnsCam_Provider
                : columnsCam
              : params.type === 'movies'
              ? isProvider
                ? columnsMovies_Provider
                : columnsMovies
              : params.type === 'epgs'
              ? columnsEpg
              : params.type === 'epgs_channels'
              ? columnsEpgChannels
              : params.type === 'epgs_radios'
              ? columnsEpgChannels
              : params.type === 'series'
              ? isProvider
                ? columnsSeries_Provider
                : columnsSeries
              : params.type === 'seasons'
              ? isProvider
                ? columnsSeasons_Provider
                : columnsSeasons
              : params.type === 'albums' || params.type === 'podcasts'
              ? isProvider
                ? albumPodcastColumns_Provider
                : albumPodcastColumns
              : params.type === 'shorts'
              ? isProvider
                ? columnsShorts_Provider
                : columnsShorts
              : params.type === 'games'
              ? isProvider
                ? columnsGames_Provider
                : columnsGames
              : params.type === 'workflows'
              ? columnsWorkflows
              : params.type === 'automations'
              ? columnsAutomations
              : isProvider
              ? columnsCourse_Provider
              : columnsCourse
          }
          onRowSelectionChange={(rows) => setRowSelectionState(rows.map((x) => x._id))}
          hasSelection={params.type === 'seasons' || params.type === 'epgs' || params.type === 'epgs_channels' || params.type === 'epgs_radios' ? false : params.variant !== 'providers' ? true : false}
          data={data}
        />
      )}
      {selectedRowKeys.length > 0 && params.variant !== 'providers' && params.type !== 'seasons' && params.type !== 'epgs' && params.type !== 'epgs_channels' && params.type !== 'epgs_radios' && (
        <GridDrawer
          selectedRowKeys={selectedRowKeys}
          setRowSelectionState={(data) => {
            setRowSelectionState(data);
            setData((data) => [...data]); // TODO Refactor ReactBCTable and this hack won't be needed
          }}
          deleteSelectedItems={deleteItems}
          deleteItemTitleMsg={`Items Selected`}
          deleteItemButtonMsg={getDeleteMessageItemName()}
        />
      )}
    </div>
  );
};
export default Table;
