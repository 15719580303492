import { Form, Input, Modal, Select, Switch } from 'antd';
import { Spin } from '../../spinner';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ColorPicker } from '../../ColorPicker';
import Text1Png from '../../../../assets/images/appbuilder/Text1.png';

export type LayoutProps = {
  visible: boolean;
  onSubmit: (component: [], name: string, index:any) => void;
  onCancel: () => void;
  data: [] | any;
  type: string;
  theme: [] | any;
  index: any;
};

const LayoutModel = (props: LayoutProps) => {
  const { visible, onSubmit, onCancel, data, type, theme } = props;
  const [form] = useForm();
const { t } = useTranslation();

  const [generalBackgroundColor, setGeneralBackgroundColor] = useState('#000000');
  const [generalBackgroundColorPicker, setGeneralBackgroundColorPicker] = useState(false);

  const [textColor, setTextColor] = useState('#000000');
  const [textColorPicker, setTextColorPicker] = useState(false);

  const [subTextColor, setSubTextColor] = useState('#000000');
  const [subTextColorPicker, setSubTextColorPicker] = useState(false);

  const [textSize, setTextSize] = useState("Large");
  const [textSizeSub, setTextSizeSub] = useState("Normal");

  const [textEnabledSub, setTextEnabledSub] = useState(false);
  const [textEnabled, setTextEnabled] = useState(true);

  const [textBold, setTextBold] = useState(false);
  const [textBoldSub, setTextBoldSub] = useState(false);

  useEffect(() => {
    if (data.infobar != undefined) {
      setGeneralBackgroundColor(data.infobar?.general?.background_color);
      setTextColor(data.infobar?.texts?.title_color);
      setSubTextColor(data.infobar?.texts?.subtext_color);
      setTextSize(data.infobar?.texts?.title_size);
      setTextSizeSub(data.infobar?.texts?.subtext_size);
      setTextEnabledSub(data.infobar?.texts?.show_subtext);
      setTextEnabled(data.infobar?.texts?.show_title);
      setTextBold(data.infobar?.texts?.title_bold);
      setTextBoldSub(data.infobar?.texts?.subtext_bold);
      form.setFieldsValue(data.infobar);
    }
  }, [data, form]);

  const onSubmitLocal = (values: any) => {
    var infobar = {
      general: {
        background_color: generalBackgroundColor,
      },

      texts: {
        title_color: textColor,
        title_size: textSize, //Small, Normal, Large, Extra Large
        title_bold: textBold,

        subtext_color: subTextColor,
        subtext_size: textSizeSub, //Small, Normal, Large, Extra Large
        subtext_bold: textBoldSub,

        show_title: textEnabled,
        show_subtext: textEnabledSub,
      },
    };

    let dataOut = {
      ...data,
      infobar: infobar,
    };
    onSubmit(dataOut, type, props.index);
  };

  return (
    <Modal visible={visible} style={{  borderRadius:5}} width={'80%'} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="text-primary">{t('edit-text-settings')}</h4>

            <div className="row mt-5">
              <div className="col-12">
                <Form form={form} onFinish={onSubmitLocal} onFinishFailed={(errorInfo) => {}}>
                  <div className="col">
                    <div className="row">
                      <div className="col card-bg-light p-4 mb-4 mr-4" style={{ paddingLeft: 0 }}>
                        <h5 className="text-primary">{t('general-settings')}</h5>
                        <p>{t('settings-that-define-title-object-of-your-element')}</p>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('background-color-3')}</label>
                          <div className="col" style={{ paddingLeft: 0 }}>
                            <div className="form-group pb-0 col">
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setGeneralBackgroundColorPicker(!generalBackgroundColorPicker);
                                }}
                                style={{
                                  backgroundColor: generalBackgroundColor,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={generalBackgroundColorPicker}
                                color={generalBackgroundColor}
                                onChange={(color) => {
                                  setGeneralBackgroundColor(color);
                                  setGeneralBackgroundColorPicker(!generalBackgroundColorPicker);
                                }}
                                setVisible={setGeneralBackgroundColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                COLOR
                              </div>
                            </div>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                      </div>
                      <div className="col card-bg-light p-4 mb-4 mr-1" style={{ paddingLeft: 0 }}>
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                          <img src={Text1Png} alt={''}></img>
                        </div>
                      </div>
                    </div>

                    {/* <div className="row">
                      <div className="col card-bg-light p-4 mb-4 mr-4" style={{ paddingLeft: 0 }}>
                        <h5 className="text-primary">Image Settings</h5>
                        <div className="row">
                          <label className="col-md-4 col-form-label">Background Color</label>
                          <div className="col" style={{ paddingLeft: 0 }}>
                            <div className="form-group pb-0 col">
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setImageBackgroundColorPicker(!imageBackgroundColorPicker);
                                }}
                                style={{
                                  backgroundColor: imageBackgroundColor,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={imageBackgroundColorPicker}
                                color={imageBackgroundColor}
                                onChange={(color) => {
                                  setImageBackgroundColor(color);
                                  setImageBackgroundColorPicker(!imageBackgroundColorPicker);
                                }}
                                setVisible={setImageBackgroundColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                COLOR
                              </div>
                            </div>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                        <div className="row">
                          <label className="col-md-4 col-form-label">Icons Color</label>
                          <div className="col" style={{ paddingLeft: 0 }}>
                            <div className="form-group pb-0 col">
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setIconColorPicker(!iconColorPicker);
                                }}
                                style={{
                                  backgroundColor: iconColor,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={iconColorPicker}
                                color={iconColor}
                                onChange={(color) => {
                                  setIconColor(color);
                                  setIconColorPicker(!iconColorPicker);
                                }}
                                setVisible={setIconColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                COLOR
                              </div>
                            </div>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                      </div>
                      <div className="col card-bg-light p-4 mb-4 mr-1" style={{ paddingLeft: 0 }}>
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                          <img src={Text1Png} alt={''}></img>
                        </div>
                      </div>
                    </div> */}
                    <div className="row">
                      <div className="col card-bg-light p-4 mb-4 mr-4" style={{ paddingLeft: 0 }}>
                        <h5 className="text-primary">{t('title-text')}</h5>
                        <p>{t('settings-that-define-title-object-of-your-element')}</p>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('text-color-0')}</label>
                          <div className="col" style={{ paddingLeft: 0 }}>
                            <div className="form-group pb-0 col">
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setTextColorPicker(!textColorPicker);
                                }}
                                style={{
                                  backgroundColor: textColor,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={textColorPicker}
                                color={textColor}
                                onChange={(color) => {
                                  setTextColor(color);
                                  setTextColorPicker(!textColorPicker);
                                }}
                                setVisible={setTextColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                COLOR
                              </div>
                            </div>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                        {/* <div className='row mb-2'>
                          <label className="col-md-4 col-form-label">Text Type</label>
                          <div className='col' style={{ paddingLeft: 0 }}>
                            <Form.Item
                              className="form-group pb-0 col"
                              name={["title_size"]}>
                              <Select
                                onChange={(value) => setTextSize(value)}
                                className="default-select"
                                placeholder="- Select Type -">
                                <Select.Option value={'Small'} key={'Small'}>Small</Select.Option>
                                <Select.Option value={'Normal'} key={'Normal'}>Normal</Select.Option>
                                <Select.Option value={'Large'} key={'Large'}>Large</Select.Option>
                                <Select.Option value={'ExtraLarge'} key={'ExtraLarge'}>X-Large</Select.Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </div> */}
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('text-bold')}</label>

                          <div className="col">
                            <Form.Item className="form-group pb-0 " name="title_bold">
                              <Switch className="ch-switch" checked={textBold} defaultChecked={false} onChange={() => setTextBold(!textBold)} />
                            </Form.Item>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('show-text-0')}</label>

                          <div className="col">
                            <Form.Item className="form-group pb-0" name="show_subtext">
                              <Switch className="ch-switch" checked={textEnabled} defaultChecked={false} onChange={() => setTextEnabled(!textEnabled)} />
                            </Form.Item>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                      </div>
                      <div className="col card-bg-light p-4 mb-4 mr-1" style={{ paddingLeft: 0 }}>
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                          <img src={Text1Png} alt={''}></img>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col card-bg-light p-4 mb-4 mr-4" style={{ paddingLeft: 0 }}>
                        <h5 className="text-primary">{t('sub-text')}</h5>
                        <p>{t('settings-that-define-title-object-of-your-element')}</p>

                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('text-color-0')}</label>
                          <div className="col" style={{ paddingLeft: 0 }}>
                            <div className="form-group pb-0 col">
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setSubTextColorPicker(!subTextColorPicker);
                                }}
                                style={{
                                  backgroundColor: subTextColor,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={subTextColorPicker}
                                color={subTextColor}
                                onChange={(color) => {
                                  setSubTextColor(color);
                                  setSubTextColorPicker(!subTextColorPicker);
                                }}
                                setVisible={setSubTextColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                COLOR
                              </div>
                            </div>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                        {/* <div className='row mb-2'>
                          <label className="col-md-4 col-form-label">Text Type</label>
                          <div className='col' style={{ paddingLeft: 0 }}>
                            <Form.Item
                              className="form-group pb-0 col"
                              name={["subtext_size"]}>
                              <Select
                                onChange={(value) => setTextSizeSub(value)}
                                className="default-select"
                                placeholder="- Select Type -">
                                <Select.Option value={'Small'} key={'Small'}>Small</Select.Option>
                                <Select.Option value={'Normal'} key={'Normal'}>Normal</Select.Option>
                                <Select.Option value={'Large'} key={'Large'}>Large</Select.Option>
                                <Select.Option value={'ExtraLarge'} key={'ExtraLarge'}>X-Large</Select.Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </div> */}
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('text-bold')}</label>

                          <div className="col">
                            <Form.Item className="form-group pb-0 " name="subtext_bold">
                              <Switch className="ch-switch" checked={textBoldSub} defaultChecked={false} onChange={() => setTextBoldSub(!textBoldSub)} />
                            </Form.Item>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('show-text-0')}</label>

                          <div className="col">
                            <Form.Item className="form-group pb-0" name="show_subtext">
                              <Switch className="ch-switch" checked={textEnabledSub} defaultChecked={false} onChange={() => setTextEnabledSub(!textEnabledSub)} />
                            </Form.Item>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                      </div>
                      <div className="col card-bg-light p-4 mb-4 mr-1" style={{ paddingLeft: 0 }}>
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                          <img src={Text1Png} alt={''}></img>
                        </div>
                      </div>
                    </div>
                    {/* <div className="row" style={{ padding: 15 }}>
                      <div className="col card-bg-light p-4 mb-2 mr-4" style={{ paddingLeft: 0 }}>
                        <h5 className="text-primary">Progress</h5>
                        <p>Settings that define the object such as background color, borders, etc...</p>
                        <div className="row">
                          <label className="col-md-4 col-form-label">Base Color</label>
                          <div className="col" style={{ paddingLeft: 0 }}>
                            <div className="form-group pb-0 col">
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setProgressColorPicker(!progressColorPicker);
                                }}
                                style={{
                                  backgroundColor: progressColor,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={progressColorPicker}
                                color={progressColor}
                                onChange={(color) => {
                                  setProgressColor(color);
                                  setProgressColorPicker(!progressColorPicker);
                                }}
                                setVisible={setProgressColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                BASE
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group pb-0 col">
                              <div
                                className="form-control height-48 font-size-14 text-default"
                                onClick={() => {
                                  setBaseColorPicker(!baseColorPicker);
                                }}
                                style={{
                                  backgroundColor: baseColor,
                                  borderRadius: '5px',
                                  border: '#e9edf4 1px solid',
                                }}
                              ></div>
                              <ColorPicker
                                theme={theme}
                                visible={baseColorPicker}
                                color={baseColor}
                                onChange={(color) => {
                                  setBaseColor(color);
                                  setBaseColorPicker(!baseColorPicker);
                                }}
                                setVisible={setBaseColorPicker}
                              />
                              <div className="text-center" style={{ fontSize: 9, fontWeight: 'bold' }}>
                                PROGRESS
                              </div>
                            </div>
                          </div>
                          <div className="col"></div>
                        </div>
                        <div className="row">
                          <label className="col-md-4 col-form-label">Show Progress</label>
                          <div className="col" style={{ paddingLeft: 0 }}>
                            <Form.Item className="form-group pb-0 col" name="show_progress">
                              <Switch className="ch-switch" checked={showProgress} defaultChecked={false} onChange={() => setShowProgress(!showProgress)} />
                            </Form.Item>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                      </div>
                      <div className="col card-bg-light p-4 mb-2 mr-1">
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                          <img src={Text1Png} alt={''}></img>
                        </div>
                      </div>
                    </div> */}
                  </div>

                  <div className="col-md-12 p-0 mt-3">
                    <button type="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-240 height-48">
                      {t('update-infobar')}
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LayoutModel;
