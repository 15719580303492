import { Button, Modal, Tag, notification } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { Client } from '../../../../../interfaces/forms/clients/clients';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import clientService from '../../../../../services/client';
import { CustomNotification } from '../../../../../common/components/Notification';

const Clients = (props: Client) => {
  const { _id, name, products, devices, scope, createdAt } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardToday, setDashboardToday] = useState([] as any);
  const history = useHistory();
  let { confirm } = Modal;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const path = 'https://cloudtv.akamaized.net/' + name + '/counters/totals.json';
        const { data } = await axios.get(path);
        var today = data.find((t) => t.month == Number(moment().format('MM')) && t.day == Number(moment().format('DD')));
        if (today != undefined) {
          setDashboardToday(today);
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [name]);

  const deleteClient = async () => {
    let response = await clientService.updateClient({
      ...props,
      status: 'Deleted',
    });
    if (response.status_code === StatusCodes.OK) {
      notification.success({
        message: 'Success',
        description: 'Client updated successfully',
      });
      history.go(0);
    } else {
      CustomNotification({
        error: response.errors,
      });
    }
  };

  return (
    <div id={`${_id}`}>
      <div className="row-md-12">
        <div className="row-md-12">
          <div style={{ color: '#555', textAlign: 'start', paddingTop: 40 }}>
            <div style={{ fontSize: 10, color: '#999', textAlign: 'start', marginLeft: 5 }}>{moment(createdAt).format('ll')}</div>
            <div style={{ color: '#485fe0', textAlign: 'start', fontSize: 22, marginTop: -4, fontWeight: 'bold', marginLeft: 5, marginBottom: 20 }}>{name.charAt(0).toUpperCase() + name.slice(1)}</div>
          </div>
        </div>
        <div className="card-bg-light row-md-12 text-left mt-2" style={{ height: 160, padding: 10, paddingTop: 10, backgroundImage: 'linear-gradient(#fcfbfb, #f1f1f1)', border: '2px solid #dfdfdf', borderRadius: 5 }}>
          <div style={{ fontSize: 12, color: '#999', fontWeight: 'normal' }}>
            {scope?.target != undefined && scope?.target != '' && (
              <div>
                Expected Target: <b>{scope?.target}</b>
              </div>
            )}
            {scope?.duration != undefined && scope?.duration != '' && (
              <div>
                Expected Deadline for the Target: <b>{scope?.duration}</b>
              </div>
            )}
            {scope?.how != undefined && scope?.how != '' && (
              <div>
                General Business Case: <b>{scope?.how}</b>
              </div>
            )}
            {scope?.softlaunch != undefined && scope?.softlaunch != '' && (
              <div>
                Expected Softlaunch Date: <b>{scope?.softlaunch}</b>
              </div>
            )}
            {scope?.live != undefined && scope?.live != '' && (
              <div>
                Expected Live Date: <b>{scope?.live}</b>
              </div>
            )}
          </div>
        </div>
        <div className="card-bg-light row mt-2" style={{ marginTop: 20, marginLeft: 1, marginRight: 1, padding: 10, paddingTop: 10, backgroundImage: 'linear-gradient(#fcfbfb, #f1f1f1)', border: '2px solid #dfdfdf', borderRadius: 5 }}>
          <div className="col-md-4" style={{ paddingLeft: 0 }}>
            <div style={{ fontSize: 12, color: '#999', textAlign: 'start' }}>Customers:</div>
            {!isLoading && dashboardToday.totals != undefined && <div style={{ color: '#999', textAlign: 'start', marginTop: -4, fontWeight: 'bold' }}>{dashboardToday?.totals[0]?.total}</div>}
            {!isLoading && dashboardToday.totals == undefined && <div style={{ color: '#999', textAlign: 'start', marginTop: -4, fontWeight: 'bold' }}>0</div>}
          </div>
          {products.cdn && (
            <div className="col-md-4" style={{ paddingLeft: 0 }}>
              <div style={{ fontSize: 12, color: '#999', textAlign: 'start' }}>Traffic:</div>
              {!isLoading && dashboardToday.totals != undefined && <div style={{ color: '#999', textAlign: 'start', marginTop: -4, fontWeight: 'bold' }}>{Math.round(dashboardToday?.totals[0]?.traffic)}</div>}
              {!isLoading && dashboardToday.totals == undefined && <div style={{ color: '#999', textAlign: 'start', marginTop: -4, fontWeight: 'bold' }}>0</div>}
            </div>
          )}
          {products.storage && (
            <div className="col-md-4" style={{ paddingLeft: 0 }}>
              <div style={{ fontSize: 12, color: '#999', textAlign: 'start' }}>Storage:</div>
              {!isLoading && dashboardToday.totals != undefined && <div style={{ color: '#999', textAlign: 'start', marginTop: -4, fontWeight: 'bold' }}>{Math.round(dashboardToday?.totals[0]?.storage)}</div>}
              {!isLoading && dashboardToday.totals == undefined && <div style={{ color: '#999', textAlign: 'start', marginTop: -4, fontWeight: 'bold' }}>0</div>}
            </div>
          )}
        </div>
      </div>

      <div className="card-bg-light row-md-12 text-left mt-2" style={{ height: 90, padding: 10, backgroundImage: 'linear-gradient(#fcfbfb, #f1f1f1)', border: '2px solid #dfdfdf', borderRadius: 5 }}>
        {products.cdn && (
          <Tag style={{ fontSize: 11, lineHeight: 1, fontWeight: 'bold' }} color="geekblue">
            CDN
          </Tag>
        )}
        {products.storage && (
          <Tag style={{ fontSize: 11, lineHeight: 1, fontWeight: 'bold' }} color="geekblue">
            Storage
          </Tag>
        )}
        {products.billing && (
          <Tag style={{ fontSize: 11, lineHeight: 1, fontWeight: 'bold' }} color="geekblue">
            Billing
          </Tag>
        )}
        {products.flussonic && (
          <Tag style={{ fontSize: 11, lineHeight: 1, fontWeight: 'bold' }} color="geekblue">
            Flussonic
          </Tag>
        )}
        {products.streaming_support && (
          <Tag style={{ fontSize: 11, lineHeight: 1, fontWeight: 'bold' }} color="geekblue">
            Streaming Support
          </Tag>
        )}
        {products.managed_service && (
          <Tag style={{ fontSize: 11, lineHeight: 1, fontWeight: 'bold' }} color="geekblue">
            Managed Service
          </Tag>
        )}
      </div>
      <div className="card-bg-light row-md-12 text-left mt-2" style={{ height: 155, padding: 10, backgroundImage: 'linear-gradient(#fcfbfb, #f1f1f1)', border: '2px solid #dfdfdf', borderRadius: 5 }}>
        {devices?.ios && (
          <Tag style={{ fontSize: 11, lineHeight: 1, fontWeight: 'bold' }} color="geekblue">
            iPhone & iPad
          </Tag>
        )}
        {devices?.tvos && (
          <Tag style={{ fontSize: 11, lineHeight: 1, fontWeight: 'bold' }} color="geekblue">
            AppleTV
          </Tag>
        )}
        {devices?.android && (
          <Tag style={{ fontSize: 11, lineHeight: 1, fontWeight: 'bold' }} color="geekblue">
            Android Phone & Tablet
          </Tag>
        )}
        {devices?.androidtv && (
          <Tag style={{ fontSize: 11, lineHeight: 1, fontWeight: 'bold' }} color="geekblue">
            AndroidTV
          </Tag>
        )}
        {devices?.firetv && (
          <Tag style={{ fontSize: 11, lineHeight: 1, fontWeight: 'bold' }} color="geekblue">
            FireTV
          </Tag>
        )}
        {devices?.lg && (
          <Tag style={{ fontSize: 11, lineHeight: 1, fontWeight: 'bold' }} color="geekblue">
            LG WebOS
          </Tag>
        )}
        {devices?.tizen && (
          <Tag style={{ fontSize: 11, lineHeight: 1, fontWeight: 'bold' }} color="geekblue">
            Samsung Tizen
          </Tag>
        )}
        {devices?.vidaa && (
          <Tag style={{ fontSize: 11, lineHeight: 1, fontWeight: 'bold' }} color="geekblue">
            Vidaa
          </Tag>
        )}
        {devices?.webtv && (
          <Tag style={{ fontSize: 11, lineHeight: 1, fontWeight: 'bold' }} color="geekblue">
            WebTV
          </Tag>
        )}
        {devices?.kaios && (
          <Tag style={{ fontSize: 11, lineHeight: 1, fontWeight: 'bold' }} color="geekblue">
            KaiOS
          </Tag>
        )}
        {devices?.roku && (
          <Tag style={{ fontSize: 11, lineHeight: 1, fontWeight: 'bold' }} color="geekblue">
            Roku
          </Tag>
        )}
      </div>
      <div className="text-right mt-2">
        <Button
          onClick={() => {
            confirm({
              title: 'Are you sure you want to delete this client?',
              okText: 'Yes',
              okType: 'danger',
              cancelText: 'No',
              onOk() {
                deleteClient();
              },
              onCancel() {},
            });
          }}
          style={{ fontSize: 12, backgroundColor: '#f13131', border: '1px solid #f13131' }}
          className="btn btn-secondary rounded-pill btn-default height-48 mr-1 float-left"
        >
          Delete
        </Button>
        <Link style={{ fontSize: 12, marginRight: 5 }} className="btn btn-secondary rounded-pill btn-default  height-48" to={`/details/${_id}`}>
          Edit
        </Link>
        <Link style={{ fontSize: 12 }} className="btn btn-secondary rounded-pill btn-default  height-48" to={`/client/${name}/${_id}`}>
          Open
        </Link>
      </div>
    </div>
  );
};

export default Clients;
