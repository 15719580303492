import { Button, Form, Input } from 'antd';

import validator from 'validator';
import { ISignInFormValues } from '../../../interfaces/forms/auth/AuthInterfaces';
import { LoginSuccessObj } from '../../../interfaces/responses/auth/ILoginResponse';

type IOTPFormProps = {
  onSubmit: (values: any) => void;
  LoginSuccessDataRef?: LoginSuccessObj;
  GoBackToLogin: () => void;
  ResendOTP: () => void;
  isVerifying: boolean;
  mode: string;
};

const OTPForm = (props: IOTPFormProps) => {
  const { onSubmit, GoBackToLogin, ResendOTP, LoginSuccessDataRef, mode } = props;
  const [otpForm] = Form.useForm();
  const numbersValidator = async (rule: any, value: any) => {
    if (value && !validator.isNumeric(value)) {
      return Promise.reject('Invalid One-Time Password!');
    }
    return Promise.resolve();
  };
  const onFinish = async (values: ISignInFormValues) => {
    onSubmit(values);
  };
  const onFinishFailed = ({ values, errorFields, outOfDate }: any) => {};
  return (
    <Form onFinish={onFinish} onFinishFailed={onFinishFailed} form={otpForm}>
      <h3 className="text-muted my-2">Please enter the One-Time Password to verify your account</h3>
      <p>A One-Time Password has been sent to {mode === 'email' ? LoginSuccessDataRef?.email : LoginSuccessDataRef?.mobile}</p>
      <div className="row">
        <Form.Item className="form-group col-md-12" name="otp" rules={[{ required: true, message: 'One-Time Password is required!' }, { len: 6, message: 'One-Time Password should be 6 digits long.' }, { validator: numbersValidator }]}>
          <Input className="" placeholder="OTP" />
        </Form.Item>
        <Form.Item className="col-md-12 mt-3 align-self-start">
          <div className="btn-group-vertical">
            <Button loading={props.isVerifying} disabled={props.isVerifying} htmlType="submit" className="btn btn-secondary rounded-pill height-48">
              Submit
            </Button>
            <button type="button" className="btn btn-link text-secondary text-decoration-none text-left p-0 pt-2" onClick={() => ResendOTP()}>
              Resend OTP ?
            </button>
            <button type="button" className="btn btn-link text-secondary text-decoration-none text-left p-0 pt-2" onClick={() => GoBackToLogin()}>
              Go Back?
            </button>
          </div>
        </Form.Item>
      </div>
    </Form>
  );
};

export default OTPForm;
