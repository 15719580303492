import { Tabs } from 'antd';
import { StatusCodes } from 'http-status-codes';
import { useContext, useEffect, useState } from 'react';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { IMSNameParams } from '../../../../../dashboard';
import cmsService from '../../../../../../services/areas/content/courses/courses';

import Tab1 from './tabs/details';
import Tab2 from './tabs/images';
import Tab3 from './tabs/lessons';
import Tab4 from './tabs/security';
import Tab5 from './tabs/monetizing';
import { ContentContext } from '../../../../../../contexts/contentContext';
import { Spin } from '../../../../../../common/components/spinner';
import { useTranslation } from 'react-i18next';

enum EnumTabKeys {
  TAB1 = 'tab1',
  TAB2 = 'tab2',
  TAB3 = 'tab3',
  TAB4 = 'tab4',
  TAB5 = 'tab5',
}

const UserInterfaceAppDetails = () => {
  const [activeKey, setActiveKey] = useState<string>(EnumTabKeys.TAB1);
  const { TabPane } = Tabs;
  const { clientName, _id } = useParams<IMSNameParams>();
  const { deployment } = useContext(ContentContext);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([] as any);
const {t} = useTranslation();
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -2).join('/');

  const handleFormFill = async () => {
    setIsLoading(true);
    let response = await cmsService.Details_Course({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      _id: _id,
    });
    if (response && response.status_code === StatusCodes.OK) {
      var resp = response.data as any;
      setData(resp);
      setIsLoading(false);
    }
  };

  useEffectOnce(() => {
    (async () => await handleFormFill())();
  });

  useEffect(() => {
    (async () => await handleFormFill())();
  }, [activeKey]);

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-12 mb-2">
          <Link to={`${redirectUrl}/`}>{t('back-to-course-list')}</Link>
          <div className="row-md-12" style={{ borderBottom: '1px solid #cfcfcf' }}></div>
          {!isLoading && <h2 className="page-title mt-4">{t('course-details-1')}{data.name}{t('key')}</h2>}
          <p className="h6 font-weight-normal line-height-26"></p>
        </div>
      </div>
      <div className="row">
        {isLoading && (
          <div className="row text-center h-100 w-100">
            <div className="col-12 align-self-center">
              <Spin size="large" spinning={true} />
            </div>
          </div>
        )}
        {!isLoading && (
          <div className="col-12  mt-3">
            <Tabs activeKey={activeKey} className="default-tab" onChange={(activeKey) => setActiveKey(activeKey)} defaultActiveKey={EnumTabKeys.TAB1}>
              <TabPane tab={t('details')} key={EnumTabKeys.TAB1}>
                {activeKey === EnumTabKeys.TAB1 && <Tab1 data={data} />}
              </TabPane>
              <TabPane tab={t('images')} key={EnumTabKeys.TAB2}>
                {activeKey === EnumTabKeys.TAB2 && <Tab2 data={data} />}
              </TabPane>
              <TabPane tab={t('monetizing-7')} key={EnumTabKeys.TAB5}>
                {activeKey === EnumTabKeys.TAB5 && <Tab5 data={data} />}
              </TabPane>
              <TabPane tab={t('security-8')} key={EnumTabKeys.TAB4}>
                {activeKey === EnumTabKeys.TAB4 && <Tab4 data={data} />}
              </TabPane>
              <TabPane tab={t('lessons-1')} key={EnumTabKeys.TAB3}>
                {activeKey === EnumTabKeys.TAB3 && <Tab3 data={data} />}
              </TabPane>
            </Tabs>
          </div>
        )}
      </div>
    </div>
  );
};
export default UserInterfaceAppDetails;
