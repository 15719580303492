import React from 'react';
import { Image } from 'antd';
import loader from '../../assets/images/loader1.gif';

type Props = {
  spinning?: boolean;
  size?: string;
  children?: any;
};

export const Spin: React.FC<Props> = ({ children, spinning }: Props) => {
  return (
    <>
      {(spinning || spinning == undefined) && (
        <div className="col row" style={{  height: '100%', position: 'absolute', zIndex: 9999, display: 'flex', flex: 1, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', alignContent: 'center' }}>
          <Image src={loader} width="75px" height="75px" preview={false} />
        </div>
      )}
      {children}
    </>
  );
};
