import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { baseOptions } from './BaseOptions';

export const GaugeChart = ({ value, percentageWarning }: { value: number, percentageWarning?: number }) => {
  
  var standardOptions: any = {
    chart: {
    type: 'radialBar',
    offsetY: -20,
    sparkline: {
      enabled: true
    }
  },
  plotOptions: {
    radialBar: {
      startAngle: -90,
      endAngle: 90,
      track: {
        background: "#e7e7e7",
        strokeWidth: '97%',
        margin: 5, // margin is in pixels
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          color: '#999',
          opacity: 1,
          blur: 2
        }
      },
      dataLabels: {
        name: {
          show: false
        },
        value: {
          offsetY: -2,
          fontSize: '22px',
        }
      }
    }
  },
  grid: {
    padding: {
      top: -10
    }
  },
  fill: {
    colors: percentageWarning && value > percentageWarning ? "#ff0000" : "#485fe0",
    type: 'gradient',
    gradient: {
      shade: 'light',
      shadeIntensity: 0.4,
      inverseColors: false,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 50, 53, 91]
    },
  },
  labels: [''],
  };
  
  const chartOptions: ApexOptions = {
    ...baseOptions,
    ...standardOptions,
  };

  return <ReactApexChart options={chartOptions} series={value ? [value] : []} type="radialBar" width={400} />;
};
