import { Form } from 'antd';
import { Input, Item } from '../../../../../../common/components/form';
import SunEditor from 'suneditor-react';
import { allPlaceholders } from '@mwaretv/communication';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import { useTranslation } from 'react-i18next';

const EmailTemplateForm = () => {
  const formInstance = Form.useFormInstance();
  const {t} = useTranslation();
  return (
    <>
      <Item label={t('name')} name={['name']} rules={[{ required: true, message: t('name-is-required-0') }]}>
        <Input />
      </Item>
      <Item label={t('subject')} name={['subject']} rules={[{ required: true, message: t('subject-is-required')}]}>
        <Input />
      </Item>
      <Form.Item hidden name={['content']} />
      <Item label={t('content')} name={['content']} rules={[{ required: true, message: t('content-is-required')}]}>
        <SunEditor
          onChange={(content) => formInstance.setFieldValue(['content'], content)}
          setOptions={{
            buttonList: [['undo'], ['fontSize', 'formatBlock', 'paragraphStyle', 'blockquote'], ['bold', 'underline', 'italic', 'strike'], ['fontColor', 'hiliteColor', 'textStyle', 'align', 'lineHeight'], ['table', 'link', 'image', 'list', 'horizontalRule'], ['removeFormat', 'showBlocks', 'codeView', 'preview']],
          }}
          setContents={formInstance.getFieldValue(['content']) ?? ''}
          height={'500px'}
        />
      </Item>
      <>
        <p className="mt-5">{t('you-can-use-the-following-placeholders-to-use-data-in-the-template')}</p>
        <br />
        {allPlaceholders.map((p) => (
          <p key={p.placeholder}>
            <b>&#123;&#123; {p.placeholder} &#125;&#125;</b> - {p.description}
          </p>
        ))}
      </>
    </>
  );
};

export default EmailTemplateForm;
