import { faArrowRightToLine, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, Table, Tooltip } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { StatusCodes } from 'http-status-codes';
import { useEffect, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import UserSVG from '../../../assets/images/user.png';
import { User } from '../../../interfaces/responses/users/user';
import { default as userService } from '../../../services/user';
import { TVMSRoles } from '../../../constants/TVMSRoles';
import { IGetUsersList_User } from '../../../interfaces/responses/users/IGetUsersListResponse';
import { useTranslation } from 'react-i18next';

interface TableParams {
  pagination?: TablePaginationConfig;
}

export const ListUsers = () => {
  // If _id is present, fetch client specific table
  const { _id } = useParams<{ _id: string }>();
  const { confirm } = Modal;
  const history = useHistory();
  const { url } = useRouteMatch();
  const [data, setData] = useState([] as any);
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation();
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const deleteUser = async (row) => {
    setLoading(true);
    const response = await userService.DeleteUserById(row._id);
    if (response.status_code === StatusCodes.OK) {
      getUsersList();
    }
    setLoading(false);
  };

  const columns: ColumnsType<IGetUsersList_User> = [
    {
      title: t('username'),
      dataIndex: 'email',
      render: (email) => (
        <span>
          <img className="mr-4" alt="user" style={{ height: '30px' }} src={UserSVG} />
          {email}
        </span>
      ),
    },
    {
      title: t('type'),
      dataIndex: 'type',
      width: '30%',
    },
    {
      title: t('role'),
      dataIndex: 'role',
      // filters: [
      //   {
      //     text: TVMSRoles.SuperAdmin,
      //     value: TVMSRoles.SuperAdmin,
      //   },
      //   {
      //     text: TVMSRoles.Admin,
      //     value: TVMSRoles.Admin,
      //   },
      //   {
      //     text: TVMSRoles.Employee,
      //     value: TVMSRoles.Employee,
      //   },
      //   {
      //     text: TVMSRoles.Vendor,
      //     value: TVMSRoles.Vendor,
      //   },
      //   {
      //     text: TVMSRoles.Auditor,
      //     value: TVMSRoles.Auditor,
      //   },
      // ],
      // onFilter: (value, record) => record.role === value,
      width: '30%',
    },
    {
      title: t('action-0'),
      key: 'action',
      render: (row) => (
        <div className="row" style={{ flexDirection: 'row', height: 60 }}>
          <Tooltip placement="top" title={`Details`}>
            <Button
              className="mx-2 cursor-pointer"
              type="text"
              onClick={() => {
                confirm({
                  title: 'Are you sure you want to delete this user?',
                  okText: 'Yes',
                  okType: 'danger',
                  cancelText: 'No',
                  onOk() {
                    deleteUser(row);
                  },
                  onCancel() {},
                });
              }}
            >
              <FontAwesomeIcon className="action-icon" icon={faTrash} />
              <div>{t('delete')}</div>
            </Button>
          </Tooltip>

          <Tooltip placement="top" title={`Details`}>
            <Button
              className="mx-2 cursor-pointer"
              type="text"
              onClick={() => {
                history.push(`${url.replace(/\/$/, '')}/details/${row._id}`);
              }}
            >
              <FontAwesomeIcon className="action-icon" icon={faArrowRightToLine} />
              <div>{t('details')}</div>
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  const getUsersList = async () => {
    setLoading(true);

    const body = {
      pageNumber: tableParams.pagination?.current,
      pageSize: tableParams.pagination?.pageSize,
    };

    const response: User = await userService.GetUserList(body, {
      client: _id ?? 'tvms',
    });

    setTableParams((prevTableParams) => ({
      pagination: {
        ...prevTableParams.pagination,
        total: response.data.count,
      },
    }));

    if (response.status_code === StatusCodes.OK) {
      setData(response.data.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    getUsersList();
  }, [JSON.stringify(tableParams)]);

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setTableParams({
      pagination,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  return (
    <section className="content">
      <div className=" m-auto p-0">
        <div className="container-fluid wrapper p-3">
          <div className="row">
            <div className="col-md-7 p-3">
              <h2 className="page-title">{_id === undefined ? 'TVMS' : t('client')} {t('users')}</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Button
                type="primary"
                onClick={() => {
                  history.push(`${url.replace(/\/$/, '')}/add`);
                }}
                className="btn btn-secondary rounded-pill width-240 height-54 float-right"
              >
                <span className="px-5 font-weight-bold">{t('add')} {_id === undefined ? 'TVMS' : t('client')} {t('user-0')}</span>
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-4">
              <Table  columns={columns} rowKey={(record) => record._id} dataSource={data} pagination={tableParams.pagination} loading={loading} onChange={handleTableChange} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
