import { PlusOutlined } from '@ant-design/icons';
import { Select, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Paths = (params: { setMappingPath: any; mapping: string; datas?: any; response?: any; path?: any }) => {
  const [inputVisible, setInputVisible] = useState<boolean>(false);
  const [paths, setPaths] = useState([] as any);
  const [add, setAdd] = useState(true);
  const [path, setPath] = useState([] as any);
  const [key, setKey] = useState('');
  const [response, setResponse] = useState([] as any);
  const [originalResponse, setOriginalResponse] = useState([] as any);
  const {t} = useTranslation();
  
  const addPath = (inputValue) => {
    var newTags = [] as any;
    if (inputValue) {
      newTags = [...paths, { name: inputValue }];
      setPaths(newTags);
    }
    var data = response[inputValue];
    var paths_ = Object.keys(data);
    var flattenPath = '';
    newTags.forEach((path) => {
      if (isNumeric(path.name)) {
        flattenPath += '[' + path.name + '].';
      } else {
        flattenPath += path.name + '.';
      }
    });
    if (typeof data === 'string' && data != '.') {
      setAdd(false);
      flattenPath = flattenPath.slice(0, -1);
    } else {
      setPath(paths_);
      setResponse(data);
    }
    params.setMappingPath({ [key]: flattenPath });
    setInputVisible(false);
  };

  const isNumeric = (str) => {
    if (typeof str != 'string') return false; // we only process strings!
    return !isNaN(str as any) && !isNaN(parseFloat(str));
  };

  const removePath = (removedTag: string) => {
    const newTags = paths.filter((tag: any) => tag !== removedTag);
    setPaths(newTags);
    var flattenPath = '';
    var data = originalResponse;
    newTags.forEach((path) => {
      if (isNumeric(path.name)) {
        flattenPath += '[' + path.name + '].';
        data = data[path.name];
      } else {
        flattenPath += path.name + '.';
        data = data[path.name];
      }
    });
    flattenPath = flattenPath.slice(0, -1);
    params.setMappingPath({ [key]: flattenPath });
    var paths_ = Object.keys(data);
    setPath(paths_);
    setResponse(data);
    setAdd(true);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  useEffect(() => {
    if (params.response != undefined) {
      setResponse(params.response);
      setOriginalResponse(params.response);
    }
  }, [params.response]);

  useEffect(() => {
    if (params.path != undefined) {
      setPath(params.path);
    }
  }, [params.path]);

  useEffect(() => {
    if (params.mapping != undefined) {
      setKey(params.mapping);
    }
  }, [params.mapping]);

  useEffect(() => {
    if (params.mapping != undefined && params.datas != undefined && params.path != undefined && params.response != undefined) {
      var elements = params.datas[params.mapping];
      if (elements != undefined) {
        var elementsArray = elements.split('.');
        var newTags = [] as any;
        var data = [] as any;
        var paths_ = params.path;
        var responseLoad = params.response;
        var flattenPath = '';
        elementsArray.forEach((arr) => {
          if (arr != '') {
            if (arr.indexOf('[0]') > -1) {
              newTags = [...newTags, { name: 0 }];
              data = responseLoad[0];
            } else {
              newTags = [...newTags, { name: arr }];
              data = responseLoad[arr];
            }
            if (data != undefined) {
              paths_ = Object.keys(data);
              newTags.forEach((path) => {
                if (isNumeric(path.name)) {
                  flattenPath += '[' + path.name + '].';
                } else {
                  flattenPath += path.name + '.';
                }
              });
              if (typeof data === 'string' && flattenPath != '.') {
                flattenPath = flattenPath.slice(0, -1);
              }
            }
            responseLoad = data;
          }
        });
        //setInputVisible(false);
        //setAdd(false);
        setPath(paths_);
        setPaths(newTags);
        setResponse(responseLoad);
      }
    }
  }, [params.datas, params.path, params.mapping, params.response]);

  return (
    <div className="row pl-3">
      <div>
        {paths != undefined && (
          <>
            {paths.map((tag: any, index: any) => {
              return (
                <Tag
                  key={index}
                  closable
                  onClose={(e) => {
                    e.preventDefault();
                    removePath(tag);
                  }}
                >
                  {tag.name}
                </Tag>
              );
            })}
          </>
        )}
      </div>
      <div>
        {inputVisible && (
          <Select className="default-select" style={{ width: 150 }} onChange={addPath} defaultActiveFirstOption={true}>
            {path.map((path, index) => {
              return (
                <Select.Option value={path} key={index}>
                  {path}
                </Select.Option>
              );
            })}
          </Select>
        )}
        {!inputVisible && add && (
          <Tag onClick={showInput} className="site-tag-plus">
            <PlusOutlined width={150} /> {t('add-path')}
          </Tag>
        )}
      </div>
    </div>
  );
};
export default Paths;
