import { AxiosResponse } from 'axios';
import { Communication } from '../../../interfaces/forms/areas/billing/communication';
import axiosInstance from '../../../utils/axios';
import { API_ENDPOINTS } from "../../_APIConstants";

const { BILLING : BILLING_ENDPONTS } = API_ENDPOINTS
const { COMMUNICATIONS : ENDPOINTS } = BILLING_ENDPONTS

type Params = { instance: string; cms: string; crm: string; path?: string };

type PaginationParams = {
  page?: number;
  limit?: number;
  offset?: number;
};

type ResponseFormat<T> = {
  docs: T;
};

type PaginatedResults<T> = {
  hasNextPage: boolean;
  hasPrevPage: boolean;
  limit: number;
  nextPage: number | null;
  page: number;
  pagingCounter: number;
  prevPage: null | number;
  totalDocs: number;
  totalPages: number;
} & ResponseFormat<T>;

type ListParams = {
  params: Params & PaginationParams;
};

export const list = ({ params }: ListParams): Promise<AxiosResponse<PaginatedResults<Communication[]>>> => {
  return axiosInstance.get<PaginatedResults<Communication[]>>(ENDPOINTS.LIST, { params });
};

type GetParams = { params: Params; id: string };

export const get = ({ params, id }: GetParams): Promise<AxiosResponse<Communication>> => {
  return axiosInstance.get(`${ENDPOINTS.GET}/${id}`, { params });
};
