import { faBrowser, faCopy } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Image } from 'antd';
import { StatusCodes } from 'http-status-codes';
import { useCallback, useContext, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { SortingRule } from 'react-table';
import ReactBCTable from '../../../../common/components/ReactBCTable';
import { IReactBCTableColumn } from '../../../../common/components/ReactBCTable/Interfaces/IReactBCTableColumn.interface';
import { AppBuilderContext } from '../../../../contexts/appbuilderContext';
import { IABP_Lists } from '../../../../interfaces/forms/common/IABP_Lists';
import { IPaginatedAndFilterResponse, PaginatedAndFilterResponseDefaultValues } from '../../../../interfaces/responses/common/IPaginatedAndFilterResponse';
import user_interfaces from '../../../../services/areas/appbuilder/applications/application';
import { IMSNameParams } from '../../../dashboard';
import Install from '../../../../common/components/AppBuilder/Install/Install';
import axios from 'axios';
import { useEffectOnce } from 'react-use';
import { useTranslation } from 'react-i18next';

const UserInterfaces = () => {
  const { url } = useRouteMatch();
  const { t } = useTranslation();
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(AppBuilderContext);
  const [application, setApplication] = useState([] as any);
  const [showCloningModal, setShowCloningModal] = useState(false);
  const [guiTemplates, setGUITemplates] = useState([] as any);
  const [fetchingUserInterfaces, setFetchingUserInterfaces] = useState<boolean>(false);
  const urlPart = url.split('/').slice(0, -1).join('/') + '/applications';
  const history = useHistory();

  const rbcColumns: IReactBCTableColumn<any>[] = [
    {
      title: t('image'),
      dataKey: 'image',
      width: '20%',
      cell: (row) => (
        <div className="text-center">
          <Image width="240px" height="135px" src={row.image}></Image>
        </div>
      ),
    },
    {
      title: t('name'),
      dataKey: 'name',
      width: '20%',
      cell: (row) => {
        return (
          <div>
            {t('app-name-0')} <b>{row.name.replace('.json', '')}</b>
            <br />
            {t('template-type')} <b>{row.type}</b>
            <br />
          </div>
        );
      },
    },
    {
      dataKey: 'manage',
      title: '',
      cell: (row) => {
        return (
          <>
            <Row style={{ minHeight: 60, justifyContent: 'flex-end' }}>
              {/* <div onClick={() => openUserInterface(row)} style={{ cursor: 'pointer', width: 64, border: '2px solid #868686', margin: 5, borderRadius: 5, display: 'inline-table', backgroundColor: '#fff' }}>
                <div className="text-center col-4 justify-content-center" style={{ height: 60, display: 'table-cell', verticalAlign: 'middle', paddingLeft: 0, paddingRight: 0 }}>
                  <div>
                    <div className="text-center">
                      <FontAwesomeIcon style={{ color: '#868686' }} size={'xl'} icon={faBrowser} />
                    </div>
                    <div className="text-center" style={{ color: '#868686', fontSize: 9, fontWeight: 'bold', lineHeight: 0.7 }}>
                      Open WebTV
                    </div>
                  </div>
                </div>
              </div> */}
              <div onClick={() => cloneApplication(row)} style={{ cursor: 'pointer', width: 64, border: '2px solid #868686', margin: 5, borderRadius: 5, display: 'inline-table', backgroundColor: '#fff' }}>
                <div className="text-center col-4 justify-content-center" style={{ height: 60, display: 'table-cell', verticalAlign: 'middle', paddingLeft: 0, paddingRight: 0 }}>
                  <div>
                    <div className="text-center">
                      <FontAwesomeIcon style={{ color: '#868686' }} size={'xl'} icon={faCopy} />
                    </div>
                    <div className="text-center" style={{ color: '#868686', fontSize: 9, fontWeight: 'bold', lineHeight: 0.7 }}>
                      {t('install')}
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </>
        );
      },
    },
  ];

  const openUserInterface = (row) => {};

  const [tableSearchState, setTableSearchState] = useState<IABP_Lists>({
    pageNumber: 1,
    pageSize: 10,
    query_params: {
      cms: deployment?.name ?? '',
      instance: clientName,
    },
  });
  const [pagination, setPagination] = useState<IPaginatedAndFilterResponse>(PaginatedAndFilterResponseDefaultValues);
  const getUserInterfacesList = async (tableSearchState: IABP_Lists) => {
    setFetchingUserInterfaces(true);
    let response = await user_interfaces.List_Application_Jsons(tableSearchState);
    if (response && response.status_code === StatusCodes.OK) {
      
      if (response.data.data) {
        var dataOut = [] as any;
        var dataIn = response.data.data as any;
        dataIn.public.forEach((json, index) => {
          if (json.type == 'file') {
            if (json.name.indexOf('.json') > -1) {
              var jsonIn = json.name.split('_');
              dataOut.push({
                type: 'public',
                name: jsonIn[1],
                json: 'https://cloudtv.akamaized.net/donotremove/tvms/templates/' + json.name,
                image: 'https://cloudtv.akamaized.net/donotremove/tvms/templates/' + jsonIn[0] + '.png',
              });
            }
          }
        });
        dataIn.private.forEach((json, index) => {
          if (json.type == 'file') {
            if (json.name.indexOf('.json') > -1) {
              var jsonIn = json.name.split('_');
              dataOut.push({
                type: 'private',
                name: jsonIn[1],
                json: 'https://cloudtv.akamaized.net/donotremove/tvms/templates/' + clientName + '/' + json.name,
                image: 'https://cloudtv.akamaized.net/donotremove/tvms/templates/' + clientName + '/' + jsonIn[0] + '.png',
              });
            }
          }
        });
        
        setGUITemplates(dataOut);
      }
    }
    setFetchingUserInterfaces(false);
  };
  //React BCTable


  useEffectOnce(() => {
    getUserInterfacesList(tableSearchState);
  });
  const cloneApplication = async (row: any) => {
    await axios.get(row.json).then(async (response: any) => {
      setApplication(response.data);
      setShowCloningModal(true);
    });
  };

  const cloningFinished = () => {
    setShowCloningModal(false);
    history.push(urlPart);
  };

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-6 mb-2">
          <h2 className="page-title">{t('app-templates')}</h2>
          <p> {t('select-a-pre-created-multidevice-app-template-for-ios-tvos-android-androidtv-firetv-tizen-smarttv-lg-smarttv-and-webtv-and-tailor-it-to-your-needs')}</p>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-7 mb-4 mb-md-2 d-flex justify-content-center justify-content-md-start align-items-start">
          <span className="ml-3 ml-md-4 "></span>
        </div>
        <div className="col-md-5 d-flex justify-content-center justify-content-md-end align-items-start"></div>
      </div>
      <div className="row">
        {showCloningModal && <Install application={application} cloningFinished={cloningFinished} visible={showCloningModal} onCancel={() => setShowCloningModal(!showCloningModal)}></Install>}
        <div className="col-12 mt-4">
          <ReactBCTable isLoading={fetchingUserInterfaces} totalRecords={pagination.count} pageSize={pagination.pageSize} tableId={'user_interfaces_tables'} columns={rbcColumns} data={guiTemplates}  />
        </div>
      </div>
    </div>
  );
};

export default UserInterfaces;
