import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { baseOptions } from './BaseOptions';

export const AreaChart = ({ options = {}, series, stacked = false }: { options?: any; series: ApexAxisChartSeries; stacked?: boolean }) => {
  const chartOptions: ApexOptions = {
    ...baseOptions,
    ...options,
    chart: {
      stacked,
      ...options.chart,
      zoom: {
        enabled: false,
        ...options.chart?.zoom,
      },
    },
    stroke: {
      width: [5, 5, 4],
      curve: 'straight',
      ...options.stroke,
    },
    fill: {
      gradient: {
        enabled: true,
        opacityFrom: 0.8,
        opacityTo: 0,
      },
    },
    markers: {
      size: 5,
      colors: ['#919fec'],
      strokeColor: '#485fe0',
      strokeWidth: 3,
    },
    legend: {
      show: false,
      markers: {
        radius: 50,
      },
    },
  };

  return <ReactApexChart options={chartOptions} series={series} type="area" height="350" />;
};
