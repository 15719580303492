import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import React from "react";
import App from "./App";
import "./index.css";
import { createRoot } from 'react-dom/client';

//import reportWebVitals from "./reportWebVitals";


//ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();




Bugsnag.start({
  apiKey: "78536eaf500d67f5852d19bac9ea3e2f",
  plugins: [new BugsnagPluginReact()],
});
let ErrorBoundary = Bugsnag.getPlugin("react")?.createErrorBoundary(React);

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<App />);


