import { Route, Switch, useRouteMatch } from 'react-router';
import BulkEdit from './edit';
import ExcelImporter from './importer/excel';
import Automation from './automations';
import AutomationAdd from './automations/add';
import AutomationDetails from './automations/details';
import Workflows from './workflows';
import WorkflowsAdd from './workflows/add';
import WorkflowsDetails from './workflows/details';

const Index = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/bulk-edit`}>
        <BulkEdit />
      </Route>
      <Route path={`${path}/importer-excel`}>
        <ExcelImporter />
      </Route>
      <Route path={`${path}/automations/add`}>
        <AutomationAdd />
      </Route>
      <Route path={`${path}/automations/details/:_id`}>
        <AutomationDetails />
      </Route>
      <Route path={`${path}/automations`}>
        <Automation />
      </Route>

      <Route path={`${path}/workflows/add`}>
        <WorkflowsAdd />
      </Route>
      <Route path={`${path}/workflows/details/:_id`}>
        <WorkflowsDetails />
      </Route>
      <Route path={`${path}/workflows`}>
        <Workflows />
      </Route>
    </Switch>
  );
};

export default Index;
