import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { baseOptions } from './BaseOptions';

export const BarChart = ({ options = {}, series, horizontal = false, categories = [] }: { options?: any; series: ApexAxisChartSeries; horizontal?: boolean; categories?: string[] }) => {
  const chartOptions: ApexOptions = {
    ...baseOptions,
    ...options,
    grid: {
      show: false,
    },
    plotOptions: {
      ...options.plotOptions,
      bar: {
        ...options.plotOptions?.bar,
        horizontal: true,
        distributed: true,
        barHeight: '10px',
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories,
      ...options.xaxis,
    },
    legend: {
      show: false,
      markers: {
        radius: 50,
      },
    },
  };

  return <ReactApexChart options={chartOptions} series={series} type="bar" height="300" />;
};
