import { Button, Form, Input, Image, Table } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { searchMovies } from '../../../actions/apis/scriptx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket } from '@fortawesome/pro-regular-svg-icons';
import MovieModal from './modal';

const AddNewMusicAlbum = () => {
  const [form] = useForm();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState([] as any);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([] as any);

  const loadMovie = async (values) => {
    setIsLoading(true);
    var result = await searchMovies(values.name);
    console.log(result);
    setResults(result);
    setIsLoading(false);
  };
  const addMovie = (row) => {
    setData(row);
    setShowModal(true);
  };
  const columns = [
    {
      title: t('name'),
      dataIndex: 'name',
    },
    {
      title: t('poster-1'),
      dataIndex: '',
      render: (row) => (
        <div className="text-center">
          <Image width="100px" src={row.poster_url}></Image>
        </div>
      ),
    },
    {
      title: t('trailer'),
      dataIndex: 'trailer_url',
    },
    {
      title: t('released-1'),
      dataIndex: 'release_year_clean',
    },
    {
      title: 'IMDB',
      dataIndex: 'imdb_id',
      render: (row) => <div className="text-center">{row}</div>,
    },
    {
      title: t('action-0'),
      dataIndex: '',
      render: (row) => (
        <Button className="cursor-pointer" onClick={() => addMovie(row)} type="text">
          <FontAwesomeIcon className="action-icon" icon={faArrowRightFromBracket} />
          <div>{t('select')}</div>
        </Button>
      ),
    },
  ];

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-12 mb-2">
          <h2 className="page-title mt-4">{t('search-movie')}</h2>
          <p className="h6 font-weight-normal line-height-26">{t('find-a-movie-via-apis-and-add-it-to-your-movie-collection')}</p>
        </div>
      </div>
      <Form
        form={form}
        onFinish={(values: any) => {
          loadMovie(values);
        }}
      >
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <h4 className="text-primary mt-5 mb-3">{t('search-our-api')}</h4>
            <div className="card-bg-light pl-5 pt-4">
              <div className="row">
                <label className="col-md-3 col-form-label">{t('movie-name')}</label>
                <Form.Item
                  className="col-md-7"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t('movie-name-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('movie-name')} />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="text-right mr-5">
            <Button htmlType="submit" disabled={isLoading} loading={isLoading} className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
              {t('search-movie-0')}
            </Button>
          </div>
        </div>
      </Form>
      <div className="card mt-4 pb-4">
        <div className="card-body card-ch-padding">
          <h4 className="text-primary mt-5 mb-3">{t('search-results')}</h4>
          <div className="card-bg-light p-5">
            <div className="row">
              {showModal && (
                <MovieModal
                  initialData={data}
                  onCancel={() => {
                    setShowModal(!showModal);
                  }}
                  onSubmit={() => {
                    setShowModal(!showModal);
                  }}
                  visible={showModal}
                />
              )}
              <Table
                className="w-100"
                rowClassName="editable-row"
                rowKey={(results) => results.name}
                loading={isLoading}
                columns={columns}
                dataSource={results}
                key={JSON.stringify(results)}
                pagination={{
                  position: ['bottomLeft'],
                  pageSize: 10,
                  hideOnSinglePage: true,
                  showTotal: (total, range) => {
                    return `${range[0]}-${range[1]} of ${total} items`;
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewMusicAlbum;
