import { Button, Col, Form, Input, Row, Select } from 'antd';
import { useContext, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
//import 'react-phone-input-2/lib/style.css';
import ValidatePhoneNumber from '../../utils/Yup/phone-validation';
import { TVMSRoles } from '../../constants/TVMSRoles';
import { AuthContext } from '../../context_api/AuthContext';
import { isInRoles } from '../../common/components/VisibleForRole';
import * as deploymentService from '../../services/deployments/deployment';
import type { Deployment } from '@mwaretv/database/build/backend/models/deployment/deployment';
import { Client } from '../../interfaces/responses/clients/client';
import { Data } from '../../interfaces/responses/users/user';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type SystemUserFormProps = {
  type: 'create' | 'update';
  initialData?: Data;
  onSubmit: (values: any) => void;
};

const fetchDeployments = async (clientName: string) => {
  return await deploymentService.list({
    params: {
      client: clientName,
      page: 1,
      limit: 9999,
    },
  });
};

const SystemUserForm = ({ type, initialData, onSubmit }: SystemUserFormProps) => {
  const { _id } = useParams<{ _id: string }>();
  const { state: AuthState } = useContext(AuthContext);
  const [form] = Form.useForm();
  const roleSelected = Form.useWatch('role', form);
  const { t } = useTranslation();
  const userClients = AuthState.user?.clients!;
  const clientsToUse = _id ? [userClients.find((x) => x._id === _id)!] : userClients;
  const [clientDeployments, setClientDeployments] = useState<Deployment[][]>([]);

  useEffect(() => {
    Promise.all(clientsToUse.map((client) => fetchDeployments(client.name))).then((value) => {
      setClientDeployments(value.map((x) => x.data.docs));
    });
  }, []);

  const handleFinish = (values: any) => {
    onSubmit({ ...values, ...{ clients: AuthState.user?.clients?.filter((item) => values.clients?.includes(item._id)) } });
  };

  const tvmsRoles = {
    [TVMSRoles.SuperAdmin]: 'MwareTV Admin',
    [TVMSRoles.Admin]: 'Client Admin',
  };

  const clientRoles = {
    [TVMSRoles.Employee]: 'Employee',
    [TVMSRoles.Auditor]: 'Auditor',
    [TVMSRoles.Vendor]: 'Vendor',
    [TVMSRoles.Provider]: 'Provider',
    [TVMSRoles.DeploymentAPI]: 'Deployment API',
    [TVMSRoles.ServiceAPI]: 'Service API',
  };

  const rolesToShow = [...(_id === undefined && isInRoles(AuthState.user?.role, [TVMSRoles.SuperAdmin]) ? Object.entries(tvmsRoles) : []), ...(isInRoles(AuthState.user?.role, [TVMSRoles.SuperAdmin, TVMSRoles.Admin]) ? Object.entries(clientRoles) : [])];

  return (
    <Form form={form} initialValues={initialData} onFinish={handleFinish}>
      <Row>
        <Col span={4}>
          <label className="col-form-label">{t('name')}</label>
        </Col>
        <Col span={10} offset={2}>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: t('name-is-required-1'),
              },
            ]}
          >
            <Input className="form-control bg-white font-size-14 text-default" placeholder={t('name')} />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={4}>
          <label className="col-form-label">{t('email')}</label>
        </Col>
        <Col span={10} offset={2}>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: t('email-is-required-0'),
              },
              {
                type: 'email',
                message: t('email-format-is-incorrect'),
              },
            ]}
          >
            <Input autoComplete="off" className="form-control bg-white font-size-14 text-default" placeholder={t('email')} />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={4}>
          <label className="col-form-label">{t('password')}</label>
        </Col>
        <Col span={10} offset={2}>
          <Form.Item
            name="password"
            rules={[
              ...(type !== 'update'
                ? [
                    {
                      required: true,
                      message: t('password-is-required-0'),
                    },
                  ]
                : []),
              {
                pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.{6,12}$)'),
                message: t('minimum-6-12-characters-at-least-one-uppercase-character-one-lowercase-character-one-special-character-and-one-number'),
              },
            ]}
          >
            <Input autoComplete="off" type="password" className="form-control bg-white font-size-14 text-default" placeholder={t('password')} />
          </Form.Item>
          <ul>
            <li>{t('must-be-between-6-to-12-character')}</li>
            <li>{t('must-contain-at-least-one-character-uppercase')}</li>
            <li>{t('must-contain-at-least-one-character-lowercase')}</li>
            <li>{t('must-contain-at-least-one-special-character')}</li>
            <li>{t('must-contain-at-least-a-number')}</li>
          </ul>
        </Col>
      </Row>

      <Row>
        <Col span={4}>
          <label className="col-form-label">{t('mobile-3')}</label>
        </Col>
        <Col span={10} offset={2}>
          <Form.Item
            name="mobile"
            rules={[
              {
                required: true,
                message: t('mobile-is-required'),
              },
              {
                min: 5,
                message: t('mobile-is-not-in-correct-form'),
              },
              {
                validator: async (rule, value) => {
                  if (value && ValidatePhoneNumber('+' + value)) {
                    return await Promise.resolve();
                  } else return await Promise.reject(Error(t('mobile-is-not-in-correct-format')));
                },
              },
            ]}
          >
            <PhoneInput specialLabel='' country={'us'} inputClass="ant-input" inputStyle={{ width: '100%', border: '1px solid #e9edf4', fontSize: '14px' }} buttonStyle={{ border: 'none', backgroundColor: 'transparent' }} />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={4}>
          <label className="col-form-label">{t('roles')}</label>
        </Col>
        <Col span={10} offset={2}>
          <Form.Item name={'role'} rules={[{ required: true, message: t('please-select-a-role') }]}>
            <Select filterOption onChange={() => form.setFieldsValue({ clients: undefined, deployments: undefined, services: undefined })} defaultActiveFirstOption={true} options={rolesToShow.map((role) => ({ label: role[1], value: role[0] }))} />
          </Form.Item>
        </Col>
      </Row>

      {roleSelected && isInRoles(roleSelected, [TVMSRoles.Admin]) && <AdminForm clients={clientsToUse} />}
      {roleSelected && isInRoles(roleSelected, [TVMSRoles.Employee, TVMSRoles.Auditor]) && <EmployeeForm clients={clientsToUse} deployments={clientDeployments} />}
      {roleSelected && isInRoles(roleSelected, [TVMSRoles.ServiceAPI]) && <ServiceRoleForm clients={clientsToUse} deployments={clientDeployments} />}
      {roleSelected && isInRoles(roleSelected, [TVMSRoles.DeploymentAPI]) && <DeploymentRoleForm clients={clientsToUse} deployments={clientDeployments} />}
      {roleSelected && isInRoles(roleSelected, [TVMSRoles.Vendor]) && <CrmRoleForm clients={clientsToUse} deployments={clientDeployments} />}
      {roleSelected && isInRoles(roleSelected, [TVMSRoles.Provider]) && <ProviderForm clients={clientsToUse} deployments={clientDeployments} />}

      <Row>
        <Col span={4}>
          <label className="col-form-label">{t('2-factor-authentication')}</label>
        </Col>
        <Col span={10} offset={2}>
          <Form.Item extra={t('secure-your-account-with-2-factor-authenication-this-assures-only-you-can-login')} initialValue={'None'} name={'mfa'}>
            <Select defaultActiveFirstOption={true}>
              <Select.Option value={'Phone'} key={'Phone'}>
                {t('via-phone')}
              </Select.Option>
              <Select.Option value={'Email'} key={'Email'}>
                {t('via-email')}
              </Select.Option>
              <Select.Option value={'Authenticator'} key={'Authenticator'}>
                {t('google-authenticator-app')}
              </Select.Option>
              <Select.Option value={'None'} key={'None'}>
                {t('disable-2fa')}
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Form.Item style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit" className="btn btn-secondary rounded-pill width-240 height-54">
              <span className="px-5 font-weight-bold">{type !== 'update' ? t('add-user') : t('update-user')}</span>
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

interface EmployeeFormProps {
  clients: Client[];
  deployments: Deployment[][];
}

const EmployeeForm = ({ clients, deployments }: EmployeeFormProps) => {
  const form = Form.useFormInstance();
  const clientsSelected = Form.useWatch('clients', form);
  const deploymentsIndex = clients.map((x) => x._id).indexOf(Array.isArray(clientsSelected) ? clientsSelected[0] : clientsSelected);
  const clientData = clients.find((client) => client._id === (Array.isArray(clientsSelected) ? clientsSelected[0] : clientsSelected));
  const clientDeployments = deployments[deploymentsIndex] ?? [];
  const { t } = useTranslation();
  return (
    <>
      <Row>
        <Col span={4}>
          <label className="col-form-label">{t('assign-to-client')}</label>
        </Col>
        <Col span={10} offset={2}>
          <Form.Item name={'clients'} rules={[{ required: true, message: t('please-select-a-client-for-this-user') }]}>
            <Select showSearch filterOption={(input, option) => (option?.key ?? '').toLowerCase().includes(input.toLowerCase())}  onChange={() => form.setFieldsValue({ deployments: undefined, services: undefined })} placeholder={t('select-one')}>
              {clients.map((client) => (
                <Select.Option value={client._id} key={client.name}>
                  {client.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={4}>
          <label className="col-form-label">{t('assign-to-deployments')}</label>
        </Col>
        <Col span={10} offset={2}>
          <Form.Item name={'deployments'}>
            <Select
              disabled={!clientsSelected}
              mode={'multiple'}
              placeholder={clientsSelected ? t('select-none-one-or-multiple') : t('select-a-client-first')}
              options={clientDeployments
                .filter((f) => f.is_deleted == false)
                .flatMap((deployment) => [
                  {
                    label: `${clientData?.name}_${deployment.name}_content`,
                    value: `${clientData?.name}_${deployment.name}_content`,
                  },
                  {
                    label: `${clientData?.name}_${deployment.name}_apps`,
                    value: `${clientData?.name}_${deployment.name}_apps`,
                  },
                ])}
              // maxTagCount='responsive'
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={4}>
          <label className="col-form-label">{t('assign-to-services')}</label>
        </Col>
        <Col span={10} offset={2}>
          <Form.Item name={'services'}>
            <Select
              disabled={!clientsSelected}
              mode={'multiple'}
              placeholder={clientsSelected ? t('select-none-one-or-multiple-0') : t('select-a-client-first-0')}
              options={clientDeployments
                .flatMap((deployment) => deployment.services.map((service) => ({ ...service, deployment })))
                .flatMap((service) => [
                  {
                    label: `${clientData?.name}_${service.deployment.name}_${service.name}_customers`,
                    value: `${clientData?.name}_${service.deployment.name}_${service.name}_customers`,
                  },
                  {
                    label: `${clientData?.name}_${service.deployment.name}_${service.name}_billing`,
                    value: `${clientData?.name}_${service.deployment.name}_${service.name}_billing`,
                  },
                  {
                    label: `${clientData?.name}_${service.deployment.name}_${service.name}_marketing`,
                    value: `${clientData?.name}_${service.deployment.name}_${service.name}_marketing`,
                  },
                  {
                    label: `${clientData?.name}_${service.deployment.name}_${service.name}_reporting`,
                    value: `${clientData?.name}_${service.deployment.name}_${service.name}_reporting`,
                  },
                ])}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

interface ProviderFormProps {
  clients: Client[];
  deployments: Deployment[][];
}

const ProviderForm = ({ clients, deployments }: ProviderFormProps) => {
  const form = Form.useFormInstance();
  const clientsSelected = Form.useWatch('clients', form);
  const deploymentsIndex = clients.map((x) => x._id).indexOf(Array.isArray(clientsSelected) ? clientsSelected[0] : clientsSelected);
  const clientData = clients.find((client) => client._id === (Array.isArray(clientsSelected) ? clientsSelected[0] : clientsSelected));
  const clientDeployments = deployments[deploymentsIndex] ?? [];
  const { t } = useTranslation();
  return (
    <>
      <Row>
        <Col span={4}>
          <label className="col-form-label">{t('assign-to-client')}</label>
        </Col>
        <Col span={10} offset={2}>
          <Form.Item name={'clients'} rules={[{ required: true, message: t('please-select-a-client-for-this-user') }]}>
            <Select showSearch filterOption={(input, option) => (option?.key ?? '').toLowerCase().includes(input.toLowerCase())}  onChange={() => form.setFieldsValue({ deployments: undefined, services: undefined })} placeholder={t('select-one')}>
              {clients.map((client) => (
                <Select.Option value={client._id} key={client.name}>
                  {client.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={4}>
          <label className="col-form-label">{t('assign-to-deployments')}</label>
        </Col>
        <Col span={10} offset={2}>
          <Form.Item name={'deployments'}>
            <Select
              disabled={!clientsSelected}
              mode={'multiple'}
              placeholder={clientsSelected ? t('select-none-one-or-multiple') : t('select-a-client-first')}
              options={clientDeployments
                .filter((f) => f.is_deleted == false)
                .flatMap((deployment) => [
                  {
                    label: `${clientData?.name}_${deployment.name}_content`,
                    value: `${clientData?.name}_${deployment.name}_content`,
                  },
                  {
                    label: `${clientData?.name}_${deployment.name}_apps`,
                    value: `${clientData?.name}_${deployment.name}_apps`,
                  },
                ])}
              // maxTagCount='responsive'
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};


interface AdminFormProps {
  clients: Client[];
}

const AdminForm = ({ clients }: AdminFormProps) => {
  const form = Form.useFormInstance();
  const { t } = useTranslation();
  return (
    <Row>
      <Col span={4}>
        <label className="col-form-label">{t('assign-to-clients')}</label>
      </Col>
      <Col span={10} offset={2}>
        <Form.Item name={'clients'} rules={[{ required: true, message: t('please-select-one-or-more-clients-for-this-admin') }]}>
          <Select
          showSearch
            filterOption={(input, option) => {
              return ((option?.key as string) ?? '').toLowerCase().includes(input.toLowerCase());
            }}
            onChange={() => form.setFieldsValue({ deployments: undefined, services: undefined })}
            className="default-select"
            mode="multiple"
            placeholder={t('please-select')}
          >
            {clients.map((client) => (
              <Select.Option value={client._id} key={client.name}>
                {client.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
};

interface CrmRoleFormProps {
  clients: Client[];
  deployments: Deployment[][];
}

const CrmRoleForm = ({ clients, deployments }: CrmRoleFormProps) => {
  const form = Form.useFormInstance();
  const clientsSelected = Form.useWatch('clients', form);
  const deploymentsIndex = clients.map((x) => x._id).indexOf(Array.isArray(clientsSelected) ? clientsSelected[0] : clientsSelected);
  const clientData = clients.find((client) => client._id === (Array.isArray(clientsSelected) ? clientsSelected[0] : clientsSelected));
  const clientDeployments = deployments[deploymentsIndex] ?? [];
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Col span={4}>
          <label className="col-form-label">{t('assign-to-client-0')}</label>
        </Col>
        <Col span={10} offset={2}>
          <Form.Item name={'clients'} rules={[{ required: true, message: t('please-select-a-client-for-this-user-0') }]}>
            <Select showSearch filterOption={(input, option) => (option?.key ?? '').toLowerCase().includes(input.toLowerCase())}  onChange={() => form.setFieldsValue({ deployments: undefined, services: undefined })} className="default-select" placeholder={t('select-one-0')}>
              {clients.map((client) => (
                <Select.Option value={client._id} key={client.name}>
                  {client.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={4}>
          <label className="col-form-label">{t('assign-to-service')}</label>
        </Col>
        <Col span={10} offset={2}>
          <Form.Item name={'services'}>
            <Select
              disabled={!clientsSelected}
              className="default-select"
              placeholder={clientsSelected ? t('select-none-one-or-multiple-1') : t('select-a-client-first-1')}
              options={clientDeployments
                .flatMap((deployment) => deployment.services.map((service) => ({ ...service, deployment })))
                .flatMap((service) => [
                  {
                    label: t('service-deployment-name-service-name-customers-0'),
                    value: `${clientData?.name}_${service.deployment.name}_${service.name}_customers`,
                  },
                ])}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

interface ServiceRoleFormProps {
  clients: Client[];
  deployments: Deployment[][];
}

const ServiceRoleForm = ({ clients, deployments }: ServiceRoleFormProps) => {
  const form = Form.useFormInstance();
  const clientsSelected = Form.useWatch('clients', form);
  const deploymentsIndex = clients.map((x) => x._id).indexOf(Array.isArray(clientsSelected) ? clientsSelected[0] : clientsSelected);
  const clientData = clients.find((client) => client._id === (Array.isArray(clientsSelected) ? clientsSelected[0] : clientsSelected));
  const clientDeployments = deployments[deploymentsIndex] ?? [];
  const { t } = useTranslation();
  return (
    <>
      <Row>
        <Col span={4}>
          <label className="col-form-label">{t('api-token')}</label>
        </Col>
        <Col span={10} offset={2}>
          <Form.Item name={'_id'}>
            <Input autoComplete="off" disabled className="form-control bg-white font-size-14 text-default" placeholder={t('api-token-0')} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={4}>
          <label className="col-form-label">{t('assign-to-client-1')}</label>
        </Col>
        <Col span={10} offset={2}>
          <Form.Item name={'clients'} rules={[{ required: true, message: t('please-select-a-client-for-this-user-1') }]}>
            <Select showSearch filterOption={(input, option) => (option?.key ?? '').toLowerCase().includes(input.toLowerCase())}  onChange={() => form.setFieldsValue({ deployments: undefined, services: undefined })} className="default-select" mode="multiple" placeholder={t('select-one-1')}>
              {clients.map((client) => (
                <Select.Option value={client._id} key={client.name}>
                  {client.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={4}>
          <label className="col-form-label">{t('assign')}</label>
        </Col>
        <Col span={10} offset={2}>
          <Form.Item name={'services'}>
            <Select
              disabled={!clientsSelected}
              className="default-select"
              mode="multiple"
              placeholder={clientsSelected ? t('select-none-one-or-multiple-2') : t('select-a-client-first-2')}
              options={clientDeployments
                .flatMap((deployment) => deployment.services.map((service) => ({ ...service, deployment })))
                .flatMap((service) => [
                  {
                    label: t('service-deployment-name-service-name-customers-1'),
                    value: `${clientData?.name}_${service.deployment.name}_${service.name}_customers`,
                  },
                  {
                    label: t('service-deployment-name-service-name-billing-0'),
                    value: `${clientData?.name}_${service.deployment.name}_${service.name}_billing`,
                  },
                  {
                    label: t('service-deployment-name-service-name-marketing-0'),
                    value: `${clientData?.name}_${service.deployment.name}_${service.name}_marketing`,
                  },
                  {
                    label: t('service-deployment-name-service-name-reports-0'),
                    value: `${clientData?.name}_${service.deployment.name}_${service.name}_reporting`,
                  },
                ])}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

interface DeploymentRoleFormProps {
  clients: Client[];
  deployments: Deployment[][];
}

const DeploymentRoleForm = ({ clients, deployments }: DeploymentRoleFormProps) => {
  const form = Form.useFormInstance();
  const clientsSelected = Form.useWatch('clients', form);
  const deploymentsIndex = clients.map((x) => x._id).indexOf(Array.isArray(clientsSelected) ? clientsSelected[0] : clientsSelected);
  const clientData = clients.find((client) => client._id === (Array.isArray(clientsSelected) ? clientsSelected[0] : clientsSelected));
  const clientDeployments = deployments[deploymentsIndex] ?? [];
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Col span={4}>
          <label className="col-form-label">{t('api-token-1')}</label>
        </Col>
        <Col span={10} offset={2}>
          <Form.Item name={'_id'}>
            <Input autoComplete="off" disabled className="form-control bg-white font-size-14 text-default" placeholder={t('api-token-2')} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={4}>
          {/* <label className="col-form-label">{t('assign-to-client-2')}</label> */}
        </Col>
        <Col span={10} offset={2}>
          <Form.Item name={'clients'} rules={[{ required: true, message: t('please-select-a-client-for-this-user-2') }]}>
            <Select showSearch  filterOption={(input, option) => (option?.key ?? '').toLowerCase().includes(input.toLowerCase())} onChange={() => form.setFieldsValue({ deployments: undefined, services: undefined })} className="default-select" mode={undefined} placeholder={t('select-one-2')}>
              {clients.map((client) => (
                <Select.Option value={client._id} key={client.name}>
                  {client.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={4}>
          <label className="col-form-label">{t('assign-to-deployments-0')}</label>
        </Col>
        <Col span={10} offset={2}>
          <Form.Item name={'deployments'}>
            <Select
              disabled={!clientsSelected}
              className="default-select"
              mode={'multiple'}
              placeholder={clientsSelected ? t('select-none-one-or-multiple-3') : t('select-a-client-first-3')}
              options={clientDeployments
                .filter((f) => f.is_deleted == false)
                .flatMap((deployment) => [
                  {
                    label: `${clientData?.name}_${deployment.name}_content`,
                    value: `${clientData?.name}_${deployment.name}_content`,
                  },
                  {
                    label: `${clientData?.name}_${deployment.name}_apps`,
                    value: `${clientData?.name}_${deployment.name}_apps`,
                  },
                ])}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default SystemUserForm;
