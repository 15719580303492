import { Form, Input, Table, Typography } from 'antd';
import { useEffect, useState } from 'react';
import Survey from './Survey';
import { useTranslation } from 'react-i18next';

interface Item {
  _id: string;
  language: string;
  title: string;
}
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'text';
  record: Item;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
  const inputNode = <Input />;
  const {t} = useTranslation();
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0, padding: 0 }}
          rules={[
            {
              required: true,
              message: t('please-input-title-2', { title: title }),
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const Surveys = (params: { setSurveys: any; data?: any }) => {
  const [surveyToUpdate, setSurveyToUpdate] = useState([] as any);
  const [showSurveyUpdateModal, setShowSurveyUpdateModal] = useState<boolean>(false);
  const [isLoadingLanguages, setIsLoadingLanguages] = useState(false);
  const [surveysLocal, setSurveysLocal] = useState([] as any);
  const [surveyToUpdateIndex, setSurveyToUpdateIndex] = useState(-1);
  const {t} = useTranslation();
  ///start inline edit
  const [form] = Form.useForm();

  const columns = [
    {
      title: t('question-0'),
      dataIndex: 'question',
      width: '70%',
      render: (row: any) => {
        return (
          <>
            {row !== undefined && (
              <div>
                {row.map((r, index) => {
                  return (
                    <div key={index}>
                      <b>{r.language}: </b>
                      {r.title}
                    </div>
                  );
                })}
              </div>
            )}
          </>
        );
      },
    },
    {
      title: t('type'),
      dataIndex: 'type',
      width: '15%',
    },
    {
      title: t('action-0'),
      dataIndex: 'operation',
      width: '15%',

      render: (id, record, index) => {
        return (
          <Typography.Link
            onClick={() => {
              setSurveyToUpdate(record);
              setSurveyToUpdateIndex(index);
              setShowSurveyUpdateModal(true);
            }}
          >
            {t('edit-0')}
          </Typography.Link>
        );
      },
    },
  ];

  useEffect(() => {
    setSurveysLocal(params.data);
  }, [params.data]);

  return (
    <div>
      <div className="row mt-3">
        <div className="col-md-12">
          {showSurveyUpdateModal && (
            <Survey
              initialData={surveyToUpdate}
              editQuestionIndex={surveyToUpdateIndex}
              onCancel={() => {
                setShowSurveyUpdateModal(!showSurveyUpdateModal);
              }}
              onSubmit={(values: any) => {
                setIsLoadingLanguages(true);
                setShowSurveyUpdateModal(!showSurveyUpdateModal);
                var current = surveysLocal;
                if (values.editQuestionIndex == -1) {
                  current.push({
                    question: values.question,
                    type: values.type,
                  });
                } else {
                  current[values.editQuestionIndex] = {
                    question: values.question,
                    type: values.type,
                  };
                }
                setSurveysLocal(current);
                params.setSurveys(current);
                setIsLoadingLanguages(false);
              }}
              visible={showSurveyUpdateModal}
            />
          )}

          <div className="col-md-12 p-0">
            <button
              type={'button'}
              onClick={() => {
                setSurveyToUpdateIndex(-1);
                setShowSurveyUpdateModal(true);
              }}
              className="btn btn-secondary float-right rounded-pill mt-1 width-240 height-48 mb-3"
            >
              {t('add-question')}
            </button>
          </div>
          <Form form={form} component={false}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              rowKey={(surveysLocal) => surveysLocal.title}
              loading={isLoadingLanguages}
              columns={columns}
              dataSource={surveysLocal}
              key={JSON.stringify(surveysLocal)}
              pagination={{
                position: ['bottomLeft'],
                pageSize: 10,
                hideOnSinglePage: true,
                showTotal: (total, range) => {
                  return `${range[0]}-${range[1]} of ${total} items`;
                },
              }}
            />
          </Form>
        </div>
      </div>
    </div>
  );
};
export default Surveys;
