import { Button, DatePicker, Form, Input, Modal, Select, Switch } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useRef, useState } from 'react';
import channelList from '../../../../services/areas/content/channels/lists';
import radioList from '../../../../services/areas/content/radio/lists';
import camList from '../../../../services/areas/content/cams/lists';
import movieList from '../../../../services/areas/content/movies/lists';
import seriesList from '../../../../services/areas/content/series/lists';
import musicList from '../../../../services/areas/content/albums/lists';
import podcastList from '../../../../services/areas/content/podcasts/lists';
import courseList from '../../../../services/areas/content/courses/lists';
import gamesList from '../../../../services/areas/content/games/lists';
import { useParams } from 'react-router-dom';
import { AppBuilderContext } from '../../../../contexts/appbuilderContext';
import { IMSNameParams } from '../../../../pages/dashboard';
import Translations from '../Translations/Translations';
import Paths from '../../Content/Paths/Paths';
import ImageUploader from '../../ImageUploader/ImageUploader';
import { STORAGE_ENDPOINTS } from '../../../../services/storage_paths';
import dayjs from 'dayjs';

export type LayoutProps = {
  visible: boolean;
  onSubmit: (component: [], name: string, index:any) => void;
  onCancel: () => void;
  data: [] | any;
  type: string;
  index: any;
};

const LayoutModel = (props: LayoutProps) => {
  const { visible, onSubmit, onCancel, data, type } = props;
  const [form] = useForm();
  const { t } = useTranslation();
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(AppBuilderContext);

  const [translationsTitle, setTranslationsTitle] = useState([] as any);
  const [translationsLocalTitle, setTranslationsLocalTitle] = useState([] as any);

  const [translationsTagline, setTranslationsTagline] = useState([] as any);
  const [translationsLocalTagline, setTranslationsLocalTagline] = useState([] as any);

  const [contentType, setContentType] = useState('');
  const [listType, setListType] = useState('');
  const [list, setList] = useState('');
  const [listName, setListName] = useState('');
  const [contentList, setContentList] = useState([] as any);
  const [showTitle, setShowTitle] = useState(true);
  const [enableOverview, setEnableOverview] = useState(true);
  const apiUrl = Form.useWatch(['mappings', 'api'], form);
  const [linkToHero, setLinkToHero] = useState(false);
  const [directToPlayer, setDirectToPlayer] = useState(false);
  const [pressAndHold, setPressAndHold] = useState(true);
  const [pressAndHoldMessage, setPressAndHoldMessage] = useState(true);
  const [imageUrl, setImageUrl] = useState([] as any);
  const [imageBackgroundLandscapeUrl, setImageBackgroundLandscapeUrl] = useState([] as any);
  const [imageBackgroundPortraitUrl, setImageBackgroundPortraitUrl] = useState([] as any);
  const [enableDeeplinking, setEnableDeeplinking] = useState(false);

  const loadApiForMapping = async (api, base) => {
    let response = await fetch(api, {
      method: 'GET', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.status == 200) {
      let data = await response.json();
      if (base != undefined && base != '') {
        setBasePath(base);
        base = base.slice(0, -1);
        setResponse(data[base]);
        var paths_ = Object.keys(data[base]);
        setPath(paths_);
      } else {
        var paths = Object.keys(data);
        setPath(paths);
        setResponse(data);
      }
      setIsLoading(false);
    }
  };

  const [shortsKeys] = useState([{ name: 'Name' }, { name: 'Studio' }, { name: 'Tags' }]);
  const [shortsOptions] = useState([{ name: 'Monetizing' }, { name: 'Security' }, { name: 'Widescreen & Backdrop Images' }, { name: 'Parental Control' }, { name: 'Placement' }, { name: 'Short Streams' }, { name: 'Descriptions' }]);

  const [movieKeys] = useState([{ name: 'Name' }, { name: 'Released' }, { name: 'Runtime' }, { name: 'Writers' }, { name: 'Language' }, { name: 'Studio' }, { name: 'Rating' }, { name: 'Actors' }, { name: 'Tags' }]);
  const [movieOptions] = useState([{ name: 'Monetizing' }, { name: 'Security' }, { name: 'Poster & Backdrop Images' }, { name: 'Parental Control' }, { name: 'Placement' }, { name: 'Movie Streams' }, { name: 'Descriptions' }]);

  const [courseKeys] = useState([{ name: 'Name' }, { name: 'Released' }, { name: 'Difficulty' }, { name: 'Language' }, { name: 'Teachers' }]);
  const [courseOptions] = useState([{ name: 'Monetizing' }, { name: 'Security' }, { name: 'Parental Control' }, { name: 'Placement' }, { name: 'Poster, Backdrop & Widescreen Images' }, { name: 'Descriptions' }]);
  const [lessonKeys] = useState([{ name: 'Course' }, { name: 'Name' }, { name: 'Number' }, { name: 'Duration' }]);
  const [lessonOptions] = useState([{ name: 'Lesson Stream Url' }, { name: 'Lesson Image' }, { name: 'Descriptions' }]);

  const [albumKeys] = useState([{ name: 'Name' }, { name: 'Artist' }, { name: 'Tags' }]);
  const [albumOptions] = useState([{ name: 'Security' }, { name: 'Icon Images' }, { name: 'Monetizing' }, { name: 'Placement' }, { name: 'Descriptions' }]);
  const [songKeys] = useState([{ name: 'Album' }, { name: 'Name' }, { name: 'Producer' }, { name: 'Writer' }, { name: 'Duration' }]);
  const [songOptions] = useState([{ name: 'Song Stream Url' }]);

  const [podcastKeys] = useState([{ name: 'Name' }, { name: 'Creator' }, { name: 'Tagline' }, { name: 'Release Period' }, { name: 'Average Length' }, { name: 'Language' }, { name: 'Tags' }]);
  const [podcastOptions] = useState([{ name: 'Security' }, { name: 'Icon Images' }, { name: 'Monetizing' }, { name: 'Placement' }, { name: 'Descriptions' }]);
  const [podsKeys] = useState([{ name: 'Podcast' }, { name: 'Name' }, { name: 'Producer' }, { name: 'Writer' }, { name: 'Duration' }]);
  const [podsOptions] = useState([{ name: 'Podcast Stream Url' }]);

  const [columns1, setColumns1] = useState([] as any);
  const [path, setPath] = useState([] as any);
  const [response, setResponse] = useState([] as any);
  const [mappings, setMappings] = useState([] as any);
  const [options, setOptions] = useState([] as any);
  const [options1, setOptions1] = useState([] as any);
  const [keys, setKeys] = useState([] as any);
  const [keys1, setKeys1] = useState([] as any);
  const [basePath, setBasePath] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [iterationPath, setIterationPath] = useState('');
  const [mappingsData, setMappingsData] = useState([] as any);

  const setSelectedContentTypeMappings = (type: any) => {
    switch (type) {
      case 'Movies':
        setKeys(movieKeys);
        setOptions(movieOptions);

        break;
      case 'Shorts':
        setKeys(shortsKeys);
        setOptions(shortsOptions);

        break;
      case 'Albums':
        setKeys(albumKeys);
        setOptions(albumOptions);
        setKeys1(songKeys);
        setOptions1(songOptions);

        break;
      case 'Podcasts':
        setKeys(podcastKeys);
        setOptions(podcastOptions);
        setKeys1(podsKeys);
        setOptions1(podsOptions);

        break;
      case 'Courses':
        setKeys(courseKeys);
        setOptions(courseOptions);
        setKeys1(lessonKeys);
        setOptions1(lessonOptions);

        break;
    }
  };

  const getDefaulValueJsonColumn = (value: any) => {
    // if (data.mappings != undefined) {
    //   // var search = data.mappings.find((m) => m.key == value);
    //   // if (search != undefined) {
    //   //   
    //   //   return search.value;
    //   // }
    // }
  };

  const optionsDivs = (key_: any, index: any, columns: any, addition: string) => {
    switch (key_.name) {
      case 'Guide':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              <div key={index} className="row pb-5">
                <label className="col-md-3 col-form-label"></label>
                <div className="col-md-5">
                  <Form.Item name={'epg_url_id' + addition}>
                    <Select className="default-select" defaultActiveFirstOption={true}>
                      {columns1.map((el: any, index: any) => {
                        return (
                          <Select.Option value={index} key={index}>
                            {el}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div key={index} className="row pb-5">
                <label className="col-md-3 col-form-label">{t('surpress-playing-on-trials')}</label>
                <div className="col-md-5">
                  <Form.Item name={'epg_url_channel' + addition}>
                    <Select className="default-select" defaultActiveFirstOption={true}>
                      {columns1.map((el: any, index: any) => {
                        return (
                          <Select.Option value={index} key={index}>
                            {el}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </>
          </div>
        );

      case 'Placement':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('start-time')}</label>
                <div className="col-md-5">
                  <div>
                    <Form.Item name={'placement_start_overide' + addition} initialValue={getDefaulValueJsonColumn('placement_start_overide')}>
                      <DatePicker value={dayjs()} defaultValue={dayjs()} showToday className="form-control height-48 bg-white font-size-14 text-default" />
                    </Form.Item>
                  </div>
                </div>
              </div>

              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('end-time')}</label>
                <div className="col-md-5">
                  <div>
                    <Form.Item name={'placement_end_overide' + addition} initialValue={getDefaulValueJsonColumn('placement_end_overide')}>
                      <DatePicker value={dayjs().add(99, 'year')} defaultValue={dayjs().add(99, 'year')} showToday className="form-control height-48 bg-white font-size-14 text-default" />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('surpress-playing-on-trials')}</label>
                <div className="col-md-5">
                  <div>
                    <Form.Item name={'placement_supress_on_trial_override' + addition} initialValue={getDefaulValueJsonColumn('placement_supress_on_trial_override')}>
                      <Switch className="ch-switch" defaultChecked={false} />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </>
          </div>
        );
      case 'Monetizing':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              {type != 'Albums' && type != 'Podcasts' && type != 'Radio' && (
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('vast-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'vast_url_overide' + addition} initialValue={getDefaulValueJsonColumn('vast_url_overide')}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com" />
                    </Form.Item>
                  </div>
                </div>
              )}
            </>
          </div>
        );
      case 'Security':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('enable-akamai-token')}</label>
                <div className="col-md-5">
                  <div>
                    <Form.Item name={'akamai_token_override' + addition} initialValue={getDefaulValueJsonColumn('akamai_token_override')}>
                      <Switch className="ch-switch" defaultChecked={false} />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('enable-flussonic-token')}</label>
                <div className="col-md-5">
                  <div>
                    <Form.Item name={'flussonic_token_override' + addition} initialValue={getDefaulValueJsonColumn('flussonic_token_override')}>
                      <Switch className="ch-switch" defaultChecked={false} />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div key={index} className="row pb-5">
                <label className="col-md-3 col-form-label">{t('enable-legacy-token')}</label>
                <div className="col-md-5">
                  <div>
                    <Form.Item name={'legacy_token_override' + addition} initialValue={getDefaulValueJsonColumn('legacy_token_override')}>
                      <Switch className="ch-switch" defaultChecked={false} />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('enable-drm')}</label>
                <div className="col-md-5">
                  <div>
                    <Form.Item name={'drm_enabled_overide'} initialValue={getDefaulValueJsonColumn('drm_enabled_overide')}>
                      <Switch className="ch-switch" defaultChecked={false} />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('drm-provider')}</label>
                <div className="col-md-5">
                  <div>
                    <Form.Item name={'drm_provider_override' + addition} initialValue={getDefaulValueJsonColumn('drm_provider_override')}>
                      <Select className="default-select" placeholder={t('select-provider-0')} defaultActiveFirstOption={true}>
                        <Select.Option key={'buydrm'} value={'buydrm'}>
                          BuyDRM
                        </Select.Option>
                        <Select.Option key={'irdeto'} value={'irdeto'}>
                          Irdeto
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </>
          </div>
        );
      case 'RTV Streams':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('standard')}</label>
                <div className="col-md-9">
                  <Paths setMappingPath={setMappingPathLocal} mapping={'Stream_Standard'} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">iOS/tvOS</label>
                <div className="col-md-9">
                  <Paths setMappingPath={setMappingPathLocal} mapping={'Stream_Apple'} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">Tizen/Webos</label>
                <div className="col-md-9">
                  <Paths setMappingPath={setMappingPathLocal} mapping={'Stream_SmartTV'} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
            </>
          </div>
        );
      case 'Movie Streams':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('stream-url')}</label>
                <div className="col-md-9">
                  <Paths setMappingPath={setMappingPathLocal} mapping={'Stream'} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
            </>
          </div>
        );
      case 'Short Streams':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('stream-url')}</label>
                <div className="col-md-9">
                  <Paths setMappingPath={setMappingPathLocal} mapping={'Stream'} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
            </>
          </div>
        );

      case 'Lesson Stream Url':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <div className="row pb-5">
              <label className="col-md-3 col-form-label">{t('stream-url')}</label>
              <div className="col-md-9">
                <Paths setMappingPath={setMappingPathLocal} mapping={'Stream'} response={response} path={path} datas={mappingsData}></Paths>
              </div>
            </div>
          </div>
        );
      case 'Episode Stream Url':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <div className="row pb-5">
              <label className="col-md-3 col-form-label">{t('stream-url')}</label>
              <div className="col-md-9">
                <Paths setMappingPath={setMappingPathLocal} mapping={'Stream'} response={response} path={path} datas={mappingsData}></Paths>
              </div>
            </div>
          </div>
        );
      case 'Song Stream Url':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <div className="row pb-5">
              <label className="col-md-3 col-form-label">{t('stream-url')}</label>
              <div className="col-md-9">
                <Paths setMappingPath={setMappingPathLocal} mapping={'Stream'} response={response} path={path} datas={mappingsData}></Paths>
              </div>
            </div>
          </div>
        );
      case 'Podcast Stream Url':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <div className="row pb-5">
              <label className="col-md-3 col-form-label">{t('stream-url')}</label>
              <div className="col-md-9">
                <Paths setMappingPath={setMappingPathLocal} mapping={'Stream'} response={response} path={path} datas={mappingsData}></Paths>
              </div>
            </div>
          </div>
        );
      case 'Description Episodes':
        return (
          <>
            <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
              {/* {extraSheets.length == 0 && (
                  <div key={index} className="row pb-5">
                    <div className="row-md-12">
                      <h4 className="text-primary">{key_.name}</h4>
                      <p>Should the offered description in the Excel not match the needed Languages in the Deployment we will use this description and your chosen language to translate to the correct description, this only works if you have the AI Translation option switch on, else we will use the first description and import this.</p>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <label className="col-md-3 col-form-label">Description Episodes</label>
                        <div className="col-md-5">
                          <Form.Item name={'description_episodes' + addition}>
                            <Select className="default-select" defaultActiveFirstOption={true}>
                              {columns1.map((el: any, index: any) => {
                                return (
                                  <Select.Option value={index} key={index}>
                                    {el}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <label className="col-md-3 col-form-label">Description Language</label>
                        <div className="col-md-5">
                          <Form.Item name={'description_language_episodes' + addition}>
                            <Select className="default-select" defaultActiveFirstOption={true}>
                              {languagesDeployment.map((el: any, index: any) => {
                                return (
                                  <Select.Option value={el.name} key={el.name}>
                                    {el.name}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                )} */}
              {/* {extraSheets.length > 0 && (
                  <div className="row">
                    <div className="col-md-9">
                      <h4 className="text-primary">{key_.name}</h4>
                      <p>No mapping needed, the importer will automatically read your second excel sheet for the available languages and import the descriptions accordingly. Should the offered description in the Excel not match the needed languages in the Deployment we will use the first description and translate to the correct language, this is if you have the AI Translation option switch on, else we will use the first description and import this.</p>
                    </div>

                  </div>
                )} */}
            </div>
          </>
        );

      case 'Descriptions':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <div className="row pb-5">
              <label className="col-md-3 col-form-label">{t('descriptions')}</label>
              <div className="col-md-9">
                <Paths setMappingPath={setMappingPathLocal} mapping={'Description'} response={response} path={path} datas={mappingsData}></Paths>
              </div>
            </div>
          </div>
        );
      case 'InteractiveTV':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('enable-catchuptv')}</label>
                <div className="col-md-5">
                  <Form.Item name={'catchuptv_enabled' + addition}>
                    <Select className="default-select" defaultActiveFirstOption={true}>
                      <Select.Option value={'Use Override'} key={999999}>
                        {t('use-override')}
                      </Select.Option>
                      {columns1.map((el: any, index: any) => {
                        return (
                          <Select.Option value={index} key={index}>
                            {el}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                  <div>
                    <Form.Item name={'catchuptv_enabled_overide' + addition}>
                      <Switch className="ch-switch" defaultChecked={false} />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('enable-recordings')}</label>
                <div className="col-md-5">
                  <Form.Item name={'recordings_enabled' + addition}>
                    <Select className="default-select" defaultActiveFirstOption={true}>
                      <Select.Option value={'Use Override'} key={999999}>
                        {t('use-override')}
                      </Select.Option>
                      {columns1.map((el: any, index: any) => {
                        return (
                          <Select.Option value={index} key={index}>
                            {el}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                  <div>
                    <Form.Item name={'recordings_enabled_overide' + addition}>
                      <Switch className="ch-switch" defaultChecked={false} />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('transcoder-url')}</label>
                <div className="col-md-5">
                  <Form.Item name={'transcoder_url' + addition}>
                    <Select className="default-select" defaultActiveFirstOption={true}>
                      <Select.Option value={'Use Override'} key={999999}>
                        {t('use-override')}
                      </Select.Option>
                      {columns1.map((el: any, index: any) => {
                        return (
                          <Select.Option value={index} key={index}>
                            {el}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                  <div>
                    <Form.Item name={'transcoder_url_overide' + addition}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="https://transcoder.com" />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('transcoder-user')}</label>
                <div className="col-md-5">
                  <Form.Item name={'transcoder_user' + addition}>
                    <Select className="default-select" defaultActiveFirstOption={true}>
                      <Select.Option value={'Use Override'} key={999999}>
                        {t('use-override')}
                      </Select.Option>
                      {columns1.map((el: any, index: any) => {
                        return (
                          <Select.Option value={index} key={index}>
                            {el}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                  <div>
                    <Form.Item name={'transcoder_user_overide' + addition}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="" />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('transcoder-pass')}</label>
                <div className="col-md-5">
                  <Form.Item name={'transcoder_pass' + addition}>
                    <Select className="default-select" defaultActiveFirstOption={true}>
                      <Select.Option value={'Use Override'} key={999999}>
                        {t('use-override')}
                      </Select.Option>
                      {columns1.map((el: any, index: any) => {
                        return (
                          <Select.Option value={index} key={index}>
                            {el}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                  <div>
                    <Form.Item name={'transcoder_pass_overide' + addition}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="" />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('transcoder-user')}</label>
                <div className="col-md-5">
                  <Form.Item name={'transcoder_retention' + addition}>
                    <Select className="default-select" defaultActiveFirstOption={true}>
                      <Select.Option value={'Use Override'} key={999999}>
                        {t('use-override')}
                      </Select.Option>
                      {columns1.map((el: any, index: any) => {
                        return (
                          <Select.Option value={index} key={index}>
                            {el}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                  <div>
                    <Form.Item name={'transcoder_retention_overide' + addition}>
                      <Input type={'number'} className="form-control bg-white font-size-14 text-default" placeholder="365" />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('enable-pausetv')}</label>
                <div className="col-md-5">
                  <Form.Item name={'pausetv_enabled' + addition}>
                    <Select className="default-select" defaultActiveFirstOption={true}>
                      <Select.Option value={'Use Override'} key={999999}>
                        {t('use-override')}
                      </Select.Option>
                      {columns1.map((el: any, index: any) => {
                        return (
                          <Select.Option value={index} key={index}>
                            {el}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                  <div>
                    <Form.Item name={'pausetv_enabled_overide' + addition}>
                      <Switch className="ch-switch" defaultChecked={false} />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('alternative-url')}</label>
                <div className="col-md-5">
                  <Form.Item name={'alternative_url' + addition}>
                    <Select className="default-select" defaultActiveFirstOption={true}>
                      <Select.Option value={'Use Override'} key={999999}>
                        {t('use-override')}
                      </Select.Option>
                      {columns1.map((el: any, index: any) => {
                        return (
                          <Select.Option value={index} key={index}>
                            {el}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                  <div>
                    <Form.Item name={'alternative_url_overide' + addition}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com" />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </>
          </div>
        );
      case 'RTV Images':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('square-image')}</label>
                <div className="col-md-9">
                  <Paths setMappingPath={setMappingPathLocal} mapping={'Square'} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('tile-image')}</label>
                <div className="col-md-9">
                  <Paths setMappingPath={setMappingPathLocal} mapping={'Tile'} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('prefix-image-url')}</label>
                <div className="col-md-5">
                  <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                    <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                  </Form.Item>
                </div>
              </div>
            </>
          </div>
        );
      case 'Radio Images':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('square-image')}</label>
                <div className="col-md-9">
                  <Paths setMappingPath={setMappingPathLocal} mapping={'Square'} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('tile-image')}</label>
                <div className="col-md-9">
                  <Paths setMappingPath={setMappingPathLocal} mapping={'Tile'} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('background-image')}</label>
                <div className="col-md-9">
                  <Paths setMappingPath={setMappingPathLocal} mapping={'Background'} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('prefix-image-url')}</label>
                <div className="col-md-5">
                  <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')} initialValue={getDefaulValueJsonColumn('prefix_url')}>
                    <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                  </Form.Item>
                </div>
              </div>
            </>
          </div>
        );
      case 'Poster & Backdrop Images':
        return (
          <>
            <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
              <div className="row">
                <div className="col-md-6">
                  <h4 className="text-primary">{key_.name}</h4>
                </div>
              </div>

              <>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('poster-image')}</label>
                  <div className="col-md-9">
                    <Paths setMappingPath={setMappingPathLocal} mapping={'Poster'} response={response} path={path} datas={mappingsData}></Paths>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('background-image')}</label>
                  <div className="col-md-9">
                    <Paths setMappingPath={setMappingPathLocal} mapping={'Background'} response={response} path={path} datas={mappingsData}></Paths>
                  </div>
                </div>

                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('prefix-image-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                    </Form.Item>
                  </div>
                </div>
              </>
            </div>
          </>
        );

      case 'Widescreen & Backdrop Images':
        return (
          <>
            <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
              <div className="row">
                <div className="col-md-6">
                  <h4 className="text-primary">{key_.name}</h4>
                </div>
              </div>

              <>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('widescreen-image')}</label>
                  <div className="col-md-9">
                    <Paths setMappingPath={setMappingPathLocal} mapping={'Widescreen'} response={response} path={path} datas={mappingsData}></Paths>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('background-image')}</label>
                  <div className="col-md-9">
                    <Paths setMappingPath={setMappingPathLocal} mapping={'Background'} response={response} path={path} datas={mappingsData}></Paths>
                  </div>
                </div>

                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('prefix-image-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')} initialValue={getDefaulValueJsonColumn('prefix_url')}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                    </Form.Item>
                  </div>
                </div>
              </>
            </div>
          </>
        );

      case 'Poster, Backdrop & Widescreen Images':
        return (
          <>
            <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
              <div className="row">
                <div className="col-md-6">
                  <h4 className="text-primary">{key_.name}</h4>
                </div>
              </div>

              <>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('poster-image')}</label>
                  <div className="col-md-9">
                    <Paths setMappingPath={setMappingPathLocal} mapping={'Poster'} response={response} path={path} datas={mappingsData}></Paths>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('background-image')}</label>
                  <div className="col-md-9">
                    <Paths setMappingPath={setMappingPathLocal} mapping={'Background'} response={response} path={path} datas={mappingsData}></Paths>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('widescreen-image')}</label>
                  <div className="col-md-9">
                    <Paths setMappingPath={setMappingPathLocal} mapping={'Widescreen'} response={response} path={path} datas={mappingsData}></Paths>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('prefix-image-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                    </Form.Item>
                  </div>
                </div>
              </>
            </div>
          </>
        );

      case 'Icon Images':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('square-image')}</label>
                <div className="col-md-9">
                  <Paths setMappingPath={setMappingPathLocal} mapping={'Square'} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('prefix-image-url')}</label>
                <div className="col-md-5">
                  <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                    <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                  </Form.Item>
                </div>
              </div>
            </>
          </div>
        );
      case 'Level Image':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('widescreen-image')}</label>
                <div className="col-md-9">
                  <Paths setMappingPath={setMappingPathLocal} mapping={'Widescreen'} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
              <div key={index} className="row pb-5">
                <label className="col-md-3 col-form-label">{t('prefix-image-url')}</label>
                <div className="col-md-5">
                  <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                    <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                  </Form.Item>
                </div>
              </div>
            </>
          </div>
        );
      case 'Year Image':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('widescreen-image')}</label>
                <div className="col-md-9">
                  <Paths setMappingPath={setMappingPathLocal} mapping={'Widescreen'} response={response} path={path} datas={mappingsData}></Paths>
                </div>
              </div>
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('prefix-image-url')}</label>
                <div className="col-md-5">
                  <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                    <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                  </Form.Item>
                </div>
              </div>
            </>
          </div>
        );
      case 'Series Image':
        return (
          <>
            <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
              <div className="row">
                <div className="col-md-6">
                  <h4 className="text-primary">{key_.name}</h4>
                </div>
              </div>

              <>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('widscreen-image')}</label>
                  <div className="col-md-5">
                    <Paths setMappingPath={setMappingPathLocal} mapping={'Widescreen'} response={response} path={path} datas={mappingsData}></Paths>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('poster-image')}</label>
                  <div className="col-md-9">
                    <Paths setMappingPath={setMappingPathLocal} mapping={'Poster'} response={response} path={path} datas={mappingsData}></Paths>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('prefix-image-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                    </Form.Item>
                  </div>
                </div>
              </>
            </div>
          </>
        );
      case 'Lesson Image':
        return (
          <>
            <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
              <div className="row">
                <div className="col-md-6">
                  <h4 className="text-primary">{key_.name}</h4>
                </div>
              </div>

              <>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('image')}</label>
                  <div className="col-md-9">
                    <Paths setMappingPath={setMappingPathLocal} mapping={'Widescreen'} response={response} path={path} datas={mappingsData}></Paths>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('prefix-image-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                    </Form.Item>
                  </div>
                </div>
              </>
            </div>
          </>
        );
      case 'Episode Image':
        return (
          <>
            <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
              <div className="row">
                <div className="col-md-6">
                  <h4 className="text-primary">{key_.name}</h4>
                </div>
              </div>

              <>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('image')}</label>
                  <div className="col-md-5">
                    <Paths setMappingPath={setMappingPathLocal} mapping={'Widescreen'} response={response} path={path} datas={mappingsData}></Paths>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('prefix-image-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                    </Form.Item>
                  </div>
                </div>
              </>
            </div>
          </>
        );
      case 'Parental Control':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
            </div>

            <div className="row pb-5">
              <label className="col-md-3 col-form-label">{t('parental-control')}</label>
              <div className="col-md-5">
                <div>
                  <Form.Item name={'parental_control_overide' + addition} initialValue={getDefaulValueJsonColumn('parental_control_overide')}>
                    <Switch className="ch-switch" defaultChecked={false} />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        break;
    }
  };

  const setMappingPathLocal = (mapping) => {
    setMappings({
      ...mappings,
      ...mapping,
    });
  };

  const setMappingBasePathLocal = (mapping) => {
    setBasePath(mapping.Base);
    var base = mapping.Base;
    base = base.slice(0, -1);
    setMappingsData([]);
    setIterationPath('');
    setResponse(response[base]);
    var paths = Object.keys(response[base]);
    setPath(paths);
  };

  const setMappingIterationPathLocal = (mapping) => {
    setIterationPath(mapping.IterationPath);
  };

  const onSubmitLocal = (values: any) => {
    var translations_ = [] as any;
    translationsLocalTitle.forEach((element: any) => {
      translations_.push({
        language: element.language,
        title: element.title,
      });
    });

    if (translationsLocalTitle[0]?.title == 'Edit to add your text') {
      translationsTitle.forEach((element: any) => {
        translations_.push({
          language: element.language,
          title: element.title,
        });
      });
    }

    var translationsTagline_ = [] as any;
    translationsLocalTagline.forEach((element: any) => {
      translationsTagline_.push({
        language: element.language,
        title: element.title,
      });
    });

    if (translationsLocalTagline[0]?.title == 'Edit to add your text') {
      translationsTagline.forEach((element: any) => {
        translationsTagline_.push({
          language: element.language,
          title: element.title,
        });
      });
    }

    options.forEach((option) => {
      if (option.name == 'Monetizing') {
        setMappings({
          ...mappings,
          vast_url_overide: values.vast_url_overide,
        });
      }
      if (option.name == 'Security') {
        setMappings({
          ...mappings,
          drm_enabled_overide: values.drm_enabled_overide,
        });

        setMappings({
          ...mappings,
          flussonic_token_override: values.flussonic_token_override,
        });

        setMappings({
          ...mappings,
          legacy_token_override: values.legacy_token_override,
        });

        setMappings({
          ...mappings,
          drm_provider_override: values.drm_provider_override,
        });
      }
      if (option.name == 'Widescreen & Backdrop Images') {
        setMappings({
          ...mappings,
          prefix_url: values.prefix_url,
        });
      }
      if (option.name == 'Parental Control') {
        setMappings({
          ...mappings,
          parental_control_overide: values.parental_control_overide,
        });
      }
      if (option.name == 'Placement') {
        setMappings({
          ...mappings,
          placement_end_overide: values.placement_end_overide,
        });

        setMappings({
          ...mappings,
          placement_start_overide: values.placement_start_overide,
        });

        setMappings({
          ...mappings,
          placement_supress_on_trial_override: values.placement_supress_on_trial_override,
        });
      }
    });

    var mapping = {
      api: values.mappings?.api ?? '',
      base: basePath,
      iteration_path: iterationPath,
      mappings: JSON.stringify(mappings),
    };


    var content1 = {
      type: contentType, //Favorites, Watchlist, Lists
      capped: false,
      link_to_hero: data.type == 'Single' || data.type == 'Slider' || data.type == 'Player' ? false : linkToHero,
      play_directly: directToPlayer,
      press_and_hold: pressAndHold,
      press_and_hold_message: pressAndHoldMessage,
      enable_deeplinking: enableDeeplinking,
      list: {
        type: listType, //Channels, Radio, Cams, etc
        list: list,
        name: listName,
        show_title: showTitle,
        enable_overview: enableOverview,
        title: translations_ ?? [],
        tagline: translationsTagline_ ?? [],
        image: imageUrl?.url ?? '',
        background_portrait: imageBackgroundPortraitUrl?.url ?? '',
        background_lanscape: imageBackgroundLandscapeUrl?.url ?? '',
      },
      mappings: contentType == 'External' || contentType == 'Search External' ? mapping : undefined,
    };
    let dataOut = {
      ...data,
      content: content1,
    };

    onSubmit(dataOut, type, props.index);
    //}
  };

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      if (data.content != undefined) {
        setContentTypeLocal(data.content.type);
        setContentType(data.content.type);
        setLinkToHero(data.content.link_to_hero);
        setDirectToPlayer(data.content.play_directly);
        setEnableDeeplinking(data.content.enable_deeplinking ?? false);

        if (data.content.mappings != undefined) {
          if (data.content.mappings.base != undefined) {
            let response = await fetch(data.content.mappings.api, {
              method: 'GET', // or 'PUT'
              headers: {
                'Content-Type': 'application/json',
              },
            });
            if (response.status == 200) {
              let data_ = await response.json();
              if (data_ != undefined && data_ != null) {
                var base = data.content.mappings.base;
                base = base.slice(0, -1);
                
                if (base != '') {
                  setMappingsData([]);
                  setIterationPath('');
                  setResponse(data_[base]);
                  var paths = Object.keys(data_[base]);
                  setPath(paths);
                  setBasePath(data.content.mappings.base);

                  if (data.content.mappings.iteration_path != undefined) {
                    setIterationPath(data.content.mappings.iteration_path);
                  }

                  var mappingsJson = JSON.parse(data.content.mappings.mappings);
                  setMappingsData(mappingsJson);
                }
                setSelectedContentTypeMappings(data.content.list.type);
              }
            }
          }
        }
        if (data.content.list != undefined) {
          setList(data.content.list.list);
          setListName(data.content.list.name);
          setContentListTypeLocal(data.content.list.type);
          setListType(data.content.list.type);
          setTranslationsTitle(data.content.list.title);
          setTranslationsTagline(data.content.list.tagline);
          setShowTitle(data.content.list.show_title);
          setEnableOverview(data.content.list.enable_overview);
          setImageUrl(data.content.list.image);
          setImageBackgroundPortraitUrl(data.content.list.background_portrait);
          setImageBackgroundLandscapeUrl(data.content.list.background_portrait);
        }
        setIsLoading(false);
        form.setFieldsValue(data.content);
      }
      setIsLoading(false);
    }
    fetchData();
  }, [data, form]);

  const setContentTypeLocal = (value: any) => {
    setContentType(value);
  };
  const setContentListTypeLocal = (value: any) => {
    setListType(value);
    getContentList(value);
    setSelectedContentTypeMappings(value);
  };

  const getContentList = (type: any) => {
    switch (type) {
      case 'Channels':
        channelList
          .List_Lists({
            pageNumber: 0,
            pageSize: 1000,
            query_params: {
              cms: deployment?.name ?? '',
              instance: clientName,
              path: undefined,
              remove: undefined,
              overwrite: undefined,
            },
          })
          .then((result: any) => {
            setContentList(result.data.data);
          });
        break;
      case 'Radio':
        radioList
          .List_Lists({
            pageNumber: 0,
            pageSize: 1000,
            query_params: {
              cms: deployment?.name ?? '',
              instance: clientName,
              path: undefined,
              remove: undefined,
              overwrite: undefined,
            },
          })
          .then((result: any) => {
            setContentList(result.data.data);
          });
        break;
      case 'Cams':
        camList
          .List_Lists({
            pageNumber: 0,
            pageSize: 1000,
            query_params: {
              cms: deployment?.name ?? '',
              instance: clientName,
              path: undefined,
              remove: undefined,
              overwrite: undefined,
            },
          })
          .then((result: any) => {
            setContentList(result.data.data);
          });
        break;
      case 'CatchupTV':
        channelList
          .List_Lists({
            pageNumber: 0,
            pageSize: 1000,
            query_params: {
              cms: deployment?.name ?? '',
              instance: clientName,
              path: undefined,
              remove: undefined,
              overwrite: undefined,
            },
          })
          .then((result: any) => {
            setContentList(result.data.data);
          });
        break;
      case 'Movies':
        movieList
          .List_Lists({
            pageNumber: 0,
            pageSize: 1000,
            query_params: {
              cms: deployment?.name ?? '',
              instance: clientName,
              path: undefined,
              remove: undefined,
              overwrite: undefined,
            },
          })
          .then((result: any) => {
            setContentList(result.data.data);
          });
        break;
      case 'Series':
        seriesList
          .List_Lists({
            pageNumber: 0,
            pageSize: 1000,
            query_params: {
              cms: deployment?.name ?? '',
              instance: clientName,
              path: undefined,
              remove: undefined,
              overwrite: undefined,
            },
          })
          .then((result: any) => {
            setContentList(result.data.data);
          });
        break;
      case 'Music':
        musicList
          .List_Lists({
            pageNumber: 0,
            pageSize: 1000,
            query_params: {
              cms: deployment?.name ?? '',
              instance: clientName,
              path: undefined,
              remove: undefined,
              overwrite: undefined,
            },
          })
          .then((result: any) => {
            setContentList(result.data.data);
          });
        break;
      case 'Podcasts':
        podcastList
          .List_Lists({
            pageNumber: 0,
            pageSize: 1000,
            query_params: {
              cms: deployment?.name ?? '',
              instance: clientName,
              path: undefined,
              remove: undefined,
              overwrite: undefined,
            },
          })
          .then((result: any) => {
            setContentList(result.data.data);
          });
        break;
      case 'Courses':
        courseList
          .List_Lists({
            pageNumber: 0,
            pageSize: 1000,
            query_params: {
              cms: deployment?.name ?? '',
              instance: clientName,
              path: undefined,
              remove: undefined,
              overwrite: undefined,
            },
          })
          .then((result: any) => {
            setContentList(result.data.data);
          });
        break;
      case 'Games':
        gamesList
          .List_Lists({
            pageNumber: 0,
            pageSize: 1000,
            query_params: {
              cms: deployment?.name ?? '',
              instance: clientName,
              path: undefined,
              remove: undefined,
              overwrite: undefined,
            },
          })
          .then((result: any) => {
            setContentList(result.data.data);
          });
        break;
      default:
        break;
    }
  };
  const setListLocal = (value: any) => {
    setList(value);
    var test = contentList.find((d) => d._id == value);
    if (test != undefined) {
      setListName(test.name);
    }
  };

  return (
    <Modal visible={visible} style={{ border: '2px solid #ebebeb', borderRadius: 10 }} width={'80%'} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="text-primary">{t('edit-content-settings')}</h4>

            <div className="row mt-5">
              <div className="col-12">
                <Form form={form} onFinish={onSubmitLocal} onFinishFailed={(errorInfo) => {}}>
                  <div className="col">
                    <div className="col" style={{ padding: 15 }}>
                      <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                        <h5 className="text-primary">{t('content')}</h5>
                        <p>{t('select-the-content-type-you-want-to-show-in-the-rails-component')}</p>
                        {data.type != 'Genre Rails' && (
                          <div className="row">
                            <label className="col-md-4 col-form-label">List Type</label>
                            <div className="col" style={{ paddingLeft: 0 }}>
                              <Form.Item name={'type'} className="form-group pb-0">
                                <Select onChange={(value) => setContentTypeLocal(value)} className="default-select" placeholder="- Select Type -">
                                  {type != 'Single' && type != 'Slider' && type != 'Player' && (
                                    <Select.Option value={'Favorites'} key={'Favorites'}>
                                      {t('favorites')}
                                    </Select.Option>
                                  )}
                                  {type != 'Single' && type != 'Slider' && type != 'Player' && (
                                    <Select.Option value={'Watched'} key={'Watched'}>
                                      {t('watched')}
                                    </Select.Option>
                                  )}
                                  {type != 'Single' && type != 'Slider' && type != 'Player' && (
                                    <Select.Option value={'External'} key={'External'}>
                                      {t('external')}
                                    </Select.Option>
                                  )}
                                  {type != 'Single' && type != 'Slider' && type != 'Player' && (
                                    <Select.Option value={'Search External'} key={'Search External'}>
                                      {t('search-external')}
                                    </Select.Option>
                                  )}
                                  {type != 'Single' && type != 'Slider' && type != 'Player' && (
                                    <Select.Option value={'Rented'} key={'Rented'}>
                                      {t('rented')}
                                    </Select.Option>
                                  )}
                                  {type != 'Single' && type != 'Slider' && type != 'Player' && (
                                    <Select.Option value={'Purchased'} key={'Purchased'}>
                                      {t('purchased')}
                                    </Select.Option>
                                  )}
                                  {type != 'Single' && type != 'Slider' && type != 'Player' && (
                                    <Select.Option value={'Downloads'} key={'Downloads'}>
                                      {t('downloads')}
                                    </Select.Option>
                                  )}
                                  {type != 'Single' && type != 'Slider' && type != 'Player' && (
                                    <Select.Option value={'Reminders'} key={'Reminders'}>
                                      {t('reminders')}
                                    </Select.Option>
                                  )}

                                  {/* <Select.Option value={'Recommendation'} key={'Recommendation'}>Recommendation</Select.Option> */}
                                  {type != 'Single' && type != 'Slider' && type != 'Player' && (
                                    <Select.Option value={'Search'} key={'Search'}>
                                      {t('search')}
                                    </Select.Option>
                                  )}
                                  <Select.Option value={'Content List'} key={'Content List'}>
                                    {t('content-list')}
                                  </Select.Option>
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        )}
                        {data.type == 'Genre Rails' && (
                          <div className="row">
                            <label className="col-md-4 col-form-label">{t('content-type')}</label>
                            <div className="col" style={{ paddingLeft: 0 }}>
                              {type != 'Single' && type != 'Slider' && type != 'Player' && (
                                <Form.Item name={['list', 'type']} className="form-group pb-0">
                                  <Select onChange={setContentListTypeLocal} className="default-select" placeholder="- Select Type -">
                                    {contentType != 'Downloads' && type != 'Morphing Rails' && type != 'Rectangle Rails' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Channels'} key={'Channels'}>
                                        {t('channels')}
                                      </Select.Option>
                                    )}
                                    {contentType != 'Downloads' && type != 'Player Rails' && type != 'Morphing Rails' && type != 'Rectangle Rails' && type != 'Wide Rails' && type != 'Player' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Radio'} key={'Radio'}>
                                        {t('radio')}
                                      </Select.Option>
                                    )}
                                    {contentType != 'Downloads' && type != 'Morphing Rails' && type != 'Rectangle Rails' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Cams'} key={'Cams'}>
                                        {t('cams')}
                                      </Select.Option>
                                    )}

                                    {contentType != 'Downloads' && type != 'Player Rails' && data.type != 'Genre Rails' && type != 'Morphing Rails' && type != 'Rectangle Rails' && type != 'Round Rails' && type != 'Rectangle Rails' && type != 'Player' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'CatchupTV'} key={'CatchupTV'}>
                                        CatchupTV
                                      </Select.Option>
                                    )}
                                    {contentType != 'Downloads' && type != 'Player Rails' && data.type != 'Genre Rails' && type != 'Morphing Rails' && type != 'Rectangle Rails' && type != 'Round Rails' && type != 'Rectangle Rails' && type != 'Player' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Recording'} key={'Recordings'}>
                                        {t('recordings')}
                                      </Select.Option>
                                    )}
                                    {type != 'Round Rails' && type != 'Wide Rails' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Movies'} key={'Movies'}>
                                        {t('movies')}
                                      </Select.Option>
                                    )}
                                    {type != 'Round Rails' && type != 'Rectangle Rails' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Series'} key={'Series'}>
                                        {t('series')}
                                      </Select.Option>
                                    )}
                                    {type != 'Morphing Rails' && type != 'Widescreen Rails' && type != 'Player' && (
                                      <Select.Option value={'Shorts'} key={'Shorts'}>
                                        {t('shorts')}
                                      </Select.Option>
                                    )}
                                    {contentType != 'Downloads' && type != 'Player Rails' && type != 'Morphing Rails' && type != 'Rectangle Rails' && type != 'Wide Rails' && type != 'Player' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Music'} key={'Music'}>
                                        {t('music')}
                                      </Select.Option>
                                    )}
                                    {contentType != 'Downloads' && type != 'Player Rails' && type != 'Morphing Rails' && type != 'Rectangle Rails' && type != 'Wide Rails' && type != 'Player' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Podcasts'} key={'Podcasts'}>
                                        {t('podcasts')}
                                      </Select.Option>
                                    )}
                                    {type != 'Player Rails' && type != 'Morphing Rails' && type != 'Round Rails' && type != 'Rectangle Rails' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Courses'} key={'Ecucation'}>
                                        {t('courses')}
                                      </Select.Option>
                                    )}
                                  </Select>
                                </Form.Item>
                              )}
                              {(type == 'Single' || type == 'Slider' || type == 'Player') && (
                                <Form.Item name={['list', 'type']} className="form-group pb-0">
                                  <Select onChange={setContentListTypeLocal} className="default-select" placeholder="- Select Type -">
                                    <Select.Option value={'Channels'} key={'Channels'}>
                                      {t('channels')}
                                    </Select.Option>
                                    <Select.Option value={'Movies'} key={'Movies'}>
                                      {t('movies')}
                                    </Select.Option>
                                    <Select.Option value={'Series'} key={'Series'}>
                                      {t('series')}
                                    </Select.Option>
                                    <Select.Option value={'Courses'} key={'Ecucation'}>
                                      {t('courses')}
                                    </Select.Option>
                                  </Select>
                                </Form.Item>
                              )}
                            </div>
                          </div>
                        )}
                        {contentType != 'InAppSeries' && contentType != 'InAppCourses' && contentType != '' && (
                          <div className="row">
                            <label className="col-md-4 col-form-label">{t('content-type')}</label>
                            <div className="col" style={{ paddingLeft: 0 }}>
                              {type != 'Single' && type != 'Slider' && type != 'Player' && (
                                <Form.Item name={['list', 'type']} className="form-group pb-0">
                                  <Select onChange={setContentListTypeLocal} className="default-select" placeholder="- Select Type -">
                                    {contentType != 'Downloads' && type != 'Morphing Rails' && type != 'Rectangle Rails' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Channels'} key={'Channels'}>
                                        {t('channels')}
                                      </Select.Option>
                                    )}
                                    {contentType != 'Downloads' && type != 'Player Rails' && type != 'Morphing Rails' && type != 'Rectangle Rails' && type != 'Wide Rails' && type != 'Player' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Radio'} key={'Radio'}>
                                        {t('radio')}
                                      </Select.Option>
                                    )}
                                    {contentType != 'Downloads' && type != 'Morphing Rails' && type != 'Rectangle Rails' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Cams'} key={'Cams'}>
                                        {t('cams')}
                                      </Select.Option>
                                    )}

                                    {contentType != 'Downloads' && type != 'Player Rails' && data.type != 'Genre Rails' && type != 'Morphing Rails' && type != 'Rectangle Rails' && type != 'Round Rails' && type != 'Rectangle Rails' && type != 'Player' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'CatchupTV'} key={'CatchupTV'}>
                                        CatchupTV
                                      </Select.Option>
                                    )}
                                    {contentType != 'Downloads' && type != 'Player Rails' && data.type != 'Genre Rails' && type != 'Morphing Rails' && type != 'Rectangle Rails' && type != 'Round Rails' && type != 'Rectangle Rails' && type != 'Player' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Recording'} key={'Recordings'}>
                                        {t('recordings')}
                                      </Select.Option>
                                    )}
                                    {type != 'Round Rails' && type != 'Wide Rails' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Movies'} key={'Movies'}>
                                        {t('movies')}
                                      </Select.Option>
                                    )}
                                    {type != 'Morphing Rails' && type != 'Round Rails' && type != 'Rectangle Rails' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Series'} key={'Series'}>
                                        {t('series')}
                                      </Select.Option>
                                    )}
                                    {type != 'Morphing Rails' && type != 'Widescreen Rails' && type != 'Player' && (
                                      <Select.Option value={'Shorts'} key={'Shorts'}>
                                        {t('shorts')}
                                      </Select.Option>
                                    )}
                                    {contentType != 'Downloads' && type != 'Player Rails' && type != 'Morphing Rails' && type != 'Rectangle Rails' && type != 'Wide Rails' && type != 'Player' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Music'} key={'Music'}>
                                        {t('music')}
                                      </Select.Option>
                                    )}
                                    {contentType != 'Downloads' && type != 'Player Rails' && type != 'Morphing Rails' && type != 'Rectangle Rails' && type != 'Wide Rails' && type != 'Player' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Podcasts'} key={'Podcasts'}>
                                        {t('podcasts')}
                                      </Select.Option>
                                    )}
                                    {type != 'Player Rails' && type != 'Morphing Rails' && type != 'Round Rails' && type != 'Rectangle Rails' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Courses'} key={'Ecucation'}>
                                        {t('courses')}
                                      </Select.Option>
                                    )}
                                    {contentType != 'Downloads' && type != 'Player Rails' && type != 'Morphing Rails' && type != 'Round Rails' && type != 'Rectangle Rails' && contentType != 'External' && contentType != 'Search External' && (
                                      <Select.Option value={'Games'} key={'Games'}>
                                        {t('games')}
                                      </Select.Option>
                                    )}
                                  </Select>
                                </Form.Item>
                              )}
                              {(type == 'Single' || type == 'Slider' || type == 'Player') && (
                                <Form.Item name={['list', 'type']} className="form-group pb-0">
                                  <Select onChange={setContentListTypeLocal} className="default-select" placeholder="- Select Type -">
                                    <Select.Option value={'Channels'} key={'Channels'}>
                                      {t('channels')}
                                    </Select.Option>
                                    <Select.Option value={'Movies'} key={'Movies'}>
                                      {t('movies')}
                                    </Select.Option>
                                    <Select.Option value={'Series'} key={'Series'}>
                                      {t('series')}
                                    </Select.Option>
                                    <Select.Option value={'Courses'} key={'Ecucation'}>
                                      {t('courses')}
                                    </Select.Option>
                                  </Select>
                                </Form.Item>
                              )}
                            </div>
                          </div>
                        )}

                        {contentType == 'Content List' && data.type != 'Genre Rails' && listType != '' && (
                          <div className="row">
                            <label className="col-md-4 col-form-label">{t('content-list')}</label>
                            <div className="col" style={{ paddingLeft: 0 }}>
                              <Form.Item name={['list', 'list']} className="form-group pb-0">
                                <Select onChange={setListLocal} className="default-select" placeholder="- Select Type -">
                                  {contentList.map((list: any) => {
                                    return (
                                      <Select.Option value={list._id} key={list._id}>
                                        {list.name}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        )}
                      </div>

                      {data.type != 'Wide Player Rails' && data.type != 'Single' && data.type != 'Slider' && data.type != 'Player' && contentType == 'Content List' && listType != 'Music' && listType != 'Shorts' && listType != 'Cams' && listType != 'Radio' && listType != 'Podcasts' && listType != 'CatchupTV' && listType != 'Recordings' && (
                        <div className="row" style={{ padding: 15 }}>
                          <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                            <h5 className="text-primary">{t('link-to-hero')}</h5>
                            <p>{t('when-enabled-and-when-the-player-hero-component-is-utilized-the-content-from-this-rails-will-be-employed-to-display-a-preview-within-the-player-hero-component')}</p>
                            <div className="row">
                              <label className="col-md-4 col-form-label">{t('enable-linking')}</label>
                              <div className="col">
                                <Form.Item className="form-group pb-0 " name="content.link_to_hero">
                                  <Switch className="ch-switch" checked={linkToHero} defaultChecked={false} onChange={() => setLinkToHero(!linkToHero)} />
                                </Form.Item>
                              </div>
                              <div className="col"></div>
                              <div className="col"></div>
                            </div>
                          </div>
                        </div>
                      )}

                      {data.type != 'Genre Rails' && data.type != 'Single' && data.type != 'Slider' && data.type != 'Player' && (
                        <div className="row" style={{ padding: 15 }}>
                          <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                            <h5 className="text-primary">{t('press-and-hold')}</h5>
                            <p>{t('enabling-press-and-hold-will-grant-your-customers-the-ability-to-manage-their-watchlist-and-favorites-through-a-compact-popup-window')}</p>
                            <div className="row">
                              <label className="col-md-4 col-form-label">{t('enable-press-and-hold')}</label>
                              <div className="col">
                                <Form.Item className="form-group pb-0 " name="content.link_to_hero">
                                  <Switch className="ch-switch" checked={pressAndHold} defaultChecked={false} onChange={() => setPressAndHold(!pressAndHold)} />
                                </Form.Item>
                              </div>
                              <div className="col"></div>
                              <div className="col"></div>
                            </div>
                            <div className="row">
                              <label className="col-md-4 col-form-label">{t('show-information')}</label>
                              <div className="col">
                                <Form.Item className="form-group pb-0 " name="content.link_to_hero">
                                  <Switch className="ch-switch" checked={pressAndHoldMessage} defaultChecked={false} onChange={() => setPressAndHoldMessage(!pressAndHoldMessage)} />
                                </Form.Item>
                              </div>
                              <div className="col"></div>
                              <div className="col"></div>
                            </div>
                          </div>
                        </div>
                      )}

                      {listType != 'Series' && listType != 'Courses' && data.type != 'Genre Rails' && contentType != 'Search' && listType != '' && (
                        <div className="row" style={{ padding: 15 }}>
                          <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                            <h5 className="text-primary">{t('direct-to-player')}</h5>
                            <p>{t('when-enabled-clicking-the-content-item-within-this-rail-will-directly-take-you-to-the-player-bypassing-the-details-page')}</p>
                            <div className="row">
                              <label className="col-md-4 col-form-label">{t('skip-details')}</label>
                              <div className="col">
                                <Form.Item className="form-group pb-0 " name="content.play_directly)">
                                  <Switch className="ch-switch" checked={directToPlayer} defaultChecked={false} onChange={() => setDirectToPlayer(!directToPlayer)} />
                                </Form.Item>
                              </div>
                              <div className="col"></div>
                              <div className="col"></div>
                            </div>
                          </div>
                        </div>
                      )}
                      {listType != 'Movies' && listType != 'Series' && listType != '' && (
                        <div className="row" style={{ padding: 15 }}>
                          <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                            <h5 className="text-primary">{t('use-deeplinking')}</h5>
                            <p>{t('with-deeplinking-you-can-have-your-app-open-or-play-content-on-a-3rd-party-app')}</p>
                            <div className="row">
                              <label className="col-md-4 col-form-label">{t('enable-deeplinking')}</label>
                              <div className="col">
                                <Form.Item className="form-group pb-0 " name="content.enable_deeplinking)">
                                  <Switch className="ch-switch" checked={enableDeeplinking} defaultChecked={false} onChange={() => setEnableDeeplinking(!enableDeeplinking)} />
                                </Form.Item>
                              </div>
                              <div className="col"></div>
                              <div className="col"></div>
                            </div>
                          </div>
                        </div>
                      )}

                      {(contentType == 'External' || contentType == 'Search External') && (
                        <div className="col card-bg-light p-4 mb-4 mr-1" style={{ paddingLeft: 0 }}>
                          <div>
                            <h5 className="text-primary">{t('external-content')}</h5>

                            <div className="row">
                              <label className="col-md-4 col-form-label">{t('api-url')}</label>
                              <div className="col" style={{ paddingLeft: 0 }}>
                                <Form.Item
                                  className="form-group pb-0"
                                  extra={'The api should return data in JSON format'}
                                  name={['mappings', 'api']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Api url is required !',
                                    },
                                  ]}
                                >
                                  <Input className="form-control bg-white font-size-14 text-default p-3 " placeholder="https://yourcontentapi.com" />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="row mb-5">
                              <label className="col-md-4 col-form-label"></label>
                              <div className="col" style={{ paddingLeft: 0 }}>
                                <Button onClick={() => loadApiForMapping(apiUrl, '')} className="btn btn-secondary rounded-pill width-180 height-48">
                                  {t('load-api')}
                                </Button>
                              </div>
                            </div>
                            {path.length > 0 && (
                              <div className="row mb-5">
                                <label className="col-md-4 col-form-label">{t('api-base-path')}</label>
                                <div className="col" style={{ paddingLeft: 0 }}>
                                  <Paths setMappingPath={setMappingBasePathLocal} mapping={'Base'} response={response} path={path} datas={{ Base: basePath?.slice(0, -1) }}></Paths>
                                </div>
                              </div>
                            )}
                            {basePath != '' && (
                              <div className="row mb-5">
                                <label className="col-md-4 col-form-label">{t('api-iteration-path')}</label>
                                <div className="col" style={{ paddingLeft: 0 }}>
                                  <Paths setMappingPath={setMappingIterationPathLocal} mapping={'IterationPath'} response={response} path={path} datas={{ IterationPath: iterationPath?.slice(0, -1) }}></Paths>
                                </div>
                              </div>
                            )}
                          </div>
                          <div>
                            {!isLoading && basePath != '' && (
                              <div>
                                <div className="card-bg-light p-5 mb-5 bg-white">
                                  <div className="row ">
                                    <div className="col-md-4">
                                      <h4 className="text-primary">{t('general')}</h4>
                                    </div>
                                  </div>
                                  <div>
                                    {keys.map((key: any, index: any) => {
                                      return (
                                        <div className="row" key={key.name}>
                                          <label className="col-md-3 col-form-label">{key.name}</label>
                                          <div className="col-md-9">
                                            <Paths setMappingPath={setMappingPathLocal} mapping={key.name} response={response} path={path} datas={mappingsData}></Paths>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>

                                {options.map((key_: any, index: any) => {
                                  return optionsDivs(key_, index, columns1, '');
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {data.type != 'Single' && data.type != 'Slider' && data.type != 'Player' && (
                        <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                          <h5 className="text-primary">{t('rails-texts-and-image')}</h5>
                          <p> </p>

                          <div className="row pt-2">
                            <label className="col-md-4 col-form-label">{t('show-rails-texts-and-image')}</label>

                            <div className="col">
                              <Form.Item className="form-group pb-0 " name={['list', 'show_title']}>
                                <Switch className="ch-switch" checked={showTitle} defaultChecked={showTitle} onChange={() => setShowTitle(!showTitle)} />
                              </Form.Item>
                            </div>
                            <div className="col"></div>
                            <div className="col"></div>
                          </div>
                          <div className="row pt-2">
                            <label className="col-md-4 col-form-label">{t('enable-overview-page')}</label>

                            <div className="col">
                              <Form.Item className="form-group pb-0 " name={['list', 'enable_overview']}>
                                <Switch className="ch-switch" checked={enableOverview} defaultChecked={enableOverview} onChange={() => setEnableOverview(!enableOverview)} />
                              </Form.Item>
                            </div>
                            <div className="col"></div>
                            <div className="col"></div>
                          </div> 

                          <div className="row p-3">
                            <div className="col" style={{ paddingLeft: 0, paddingRight: 0 }}>
                              <h5 className="text-primary">{t('title')}</h5>
                              <Translations  setTranslations={setTranslationsLocalTitle} data={translationsTitle}></Translations>
                            </div>
                          </div>
                          <div className="row p-3">
                            <div className="col" style={{ paddingLeft: 0, paddingRight: 0 }}>
                              <h5 className="text-primary">{t('tagline')}</h5>
                              <Translations  setTranslations={setTranslationsLocalTagline} data={translationsTagline}></Translations>
                            </div>
                          </div>
                          <div className="row" style={{}}>
                            <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                              <div className="row-md-12">
                                <ImageUploader
                                  onSubmit={(values: any) => {
                                    setImageUrl(values);
                                  }}
                                  initialData={{
                                    notMandatory:true,
                                    uploadedImage: imageUrl,
                                    uploadName: t('rails_square'),
                                    uploadType: 'square',
                                    path: STORAGE_ENDPOINTS(clientName, deployment?.name, '').APPBUILDER.IMAGES,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row" style={{}}>
                            <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                              <div className="row-md-12">
                                <ImageUploader
                                  onSubmit={(values: any) => {
                                    setImageBackgroundLandscapeUrl(values);
                                  }}
                  
                                  initialData={{
                                    uploadedImage: imageBackgroundLandscapeUrl,
                                    uploadName: t('overview_background_landscape'),
                                    uploadType: 'landscape',
                                    notMandatory:true,
                                    path: STORAGE_ENDPOINTS(clientName, deployment?.name, '').APPBUILDER.IMAGES,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row" style={{}}>
                            <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                              <div className="row-md-12">
                                <ImageUploader
                                  onSubmit={(values: any) => {
                                    setImageBackgroundPortraitUrl(values);
                                  }}
                                  initialData={{
                                    notMandatory:true,
                                    uploadedImage: imageBackgroundPortraitUrl,
                                    uploadName: t('overview_background_portrait'),
                                    uploadType: 'portrait',
                                    path: STORAGE_ENDPOINTS(clientName, deployment?.name, '').APPBUILDER.IMAGES,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 p-0 mt-3">
                    <button type="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-240 height-48">
                      {t('update-content')}
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LayoutModel;
