export enum CommunicationSource {
  TVMS = 'TVMS',
  CART = 'Cart',
  PORTAL = 'Portal',
  FUNCTION = 'Function',
  API = 'Api',
}

export enum CommunicationType {
  EMAIL = 'Email',
  SMS = 'SMS',
}

export interface Communication {
  _id: string;
  timestamp: number;
  source: CommunicationSource;
  type: CommunicationType;
  message_type: string;
  template_name: string;
  template_id: string;
  customer_name: string;
  customer_id: string;
  product_name: string;
  product_id: string;
  email?: string;
  mobile?: string;
}
