import React, { useContext } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { notification, Steps as AntdSteps, Form } from 'antd';

import { PrimaryButton } from '../../../../../../../../common/components/form';
import { ContainerWrapper } from '../../../../../../../../common/components/containerWrapper';
import Intro from './intro';
import Congrats from './congrats';
import { useMutation, useQuery } from '@tanstack/react-query';
import * as deploymentService from '../../../../../../../../services/deployments/deployment';
import { useParams, useHistory } from 'react-router';
import { Deployment } from '@mwaretv/database/build/backend/models/deployment/deployment';
import ServiceName from './serviceName';
import ServiceSettings from './serviceSettings';
import commonService from '../../../../../../../../services/common';
import { ICountry } from '../../../../../../../../interfaces/responses/common/ICountriesResponse';
import { ILanguage } from '../../../../../../../../interfaces/responses/common/ILanguagesResponse';
import { CommonContext } from '../../../../../../../../contexts/commonContext';

enum Steps {
  Intro,
  Instance,
  ServiceSettings,
  Congrats,
}


const NewService: React.FC = () => {
  const history = useHistory();
  const { clientName, deployment } = useParams<{ clientName: string; deployment: string }>();
  const [form] = useForm<Omit<Deployment, 'id' & '_id'>>();
  const [currentStep, setCurrentStep] = React.useState(Steps.Intro);
  const {t} = useTranslation();


  const getButtonText = (currentStep: Steps) => {
    switch (currentStep) {
      case Steps.ServiceSettings:
        return t('save');
      case Steps.Congrats:
        return t('ok');
      default:
        return t('next');
    }
  };

  
  const { countries, currencies, languages } = useContext(CommonContext);

  const { data: deploymentData } = useQuery(['deployment', deployment], async () => {
    if (deployment != undefined && deployment != '') {
      return deploymentService
        .find(clientName, { name: deployment })
        .then((r) => r.data)
        .catch((e) => Promise.reject(e));
    }
  });

  const { mutate, isLoading } = useMutation(
    (values: Omit<Deployment, 'id' | '_id'>) => {
      return deploymentService.patch({ params: { client: clientName }, data: values, id: deploymentData?._id.toString() ?? '' });
    },
    {
      
      onSuccess: () => {
        notification.success({ message: t('instance-successfully-added') });
        setCurrentStep(currentStep + 1);
      },
      onError: () => {
        notification.error({ message: t('this-service-already-exists')});
      },
    }
  );

  const handleClick = async (deploymentData) => {
    try {
      await form.validateFields();
    } catch {
      return;
    }

    if (currentStep === Steps.Instance) {
      try {
        const foundExisting = deploymentData?.services.find((x) => x.name === form.getFieldValue('crm_name'));
        if (foundExisting) {
          notification.error({ message: t('a-service-with-that-name-already-exists-please-choose-something-else') });
          return;
        }
      } catch (e) {
       
        notification.error({ message: t('an-unknown-error-occurred-please-try-again-later') });
        return;
      }
    }

    if (currentStep === Steps.ServiceSettings) {
      if (deploymentData && countries && currencies) {
        try {
          const formCountries = (form.getFieldValue('serviceCountries') as string[]).map((id) => countries.find((c) => c._id === id));
          const formCurrencies = [...new Set(formCountries.map((c) => c!.currency))];
          const formIds = formCurrencies.map((c) => currencies.find((d) => d.isoCurrency.toLowerCase() === c.toLowerCase())?._id);

          mutate({
            ...deploymentData,
            services: [
              ...deploymentData.services,
              {
                name: form.getFieldValue('crm_name'),
                timezone: form.getFieldValue('serviceTimezone'),
                is_deleted: false,
                countries: form.getFieldValue('serviceCountries'),
                currencies: formIds as any,
                languages: form.getFieldValue('serviceLanguages'),
              },
            ],
          });
        } catch (e: unknown) {
          notification.error({ message: t('something-went-wrong-saving-the-service-0') });
        }
      }
    } else if (currentStep === Steps.Congrats) {
      history.push('../');
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-12">
          <div className="card mt-4 pb-4">
            <div className="card-body card-ch-padding">
              <Form {...{ form, isLoading }}>
                <AntdSteps
                  current={currentStep}
                  type="navigation"
                  items={[
                    { title: t('important'), disabled: currentStep !== Steps.Intro },
                    { title: t('setup'), disabled: currentStep !== Steps.Instance },
                    { title: t('settings'), disabled: currentStep !== Steps.ServiceSettings },
                    { title: t('congrats'), disabled: currentStep !== Steps.Congrats },
                  ]}
                />
                {currentStep === Steps.Intro && <Intro />}
                {currentStep === Steps.Instance && <ServiceName />}
                {currentStep === Steps.ServiceSettings && deploymentData != null && countries && languages && <ServiceSettings allowedCountries={deploymentData.countries as any} allowedLanguages={deploymentData.languages as any} />}
                {currentStep === Steps.Congrats && <Congrats />}
              </Form>
            </div>
          </div>
          <div className="text-right">
            {currentStep != Steps.Congrats && (
              <PrimaryButton loading={isLoading} onClick={() => handleClick(deploymentData)}>
                {getButtonText(currentStep)}
              </PrimaryButton>
            )}
          </div>
        </div>
      </div>
    </ContainerWrapper>
  );
};

export default NewService;
