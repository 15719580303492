import { Form, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppBuilderContext } from '../../../../contexts/appbuilderContext';
import { IMSNameParams } from '../../../../pages/dashboard';
import ImageUploader from '../../ImageUploader/ImageUploader';
import { STORAGE_ENDPOINTS } from '../../../../services/storage_paths';
import moment from 'moment-timezone';
import Placement from '../../Content/Placement/Placement';

export type LayoutProps = {
  visible: boolean;
  onSubmit: (component: [], name: string, index:any) => void;
  onCancel: () => void;
  data: [] | any;
  type: string;
  index: any;
};

const LayoutModel = (props: LayoutProps) => {
  const { visible, onSubmit, onCancel, data, type } = props;
  const [form] = useForm();
  const { t } = useTranslation();
  const { clientName } = useParams<IMSNameParams>();
  const { deployment, service } = useContext(AppBuilderContext);
  const [imageLandscapeUrl, setImageLandscapeUrl] = useState([] as any);
  const [imagePortraitUrl, setImagePortraitUrl] = useState([] as any);

  const onSubmitLocal = (values: any) => {
    var logos = {
      landscape: imageLandscapeUrl.url,
      portrait: imagePortraitUrl.url,
    };
    let dataOut = {
      ...data,
      ...logos,
    };

    onSubmit(dataOut, type, props.index);
  };

  useEffect(() => {
    if (data != undefined) {
      setImagePortraitUrl(data.portrait);
      setImageLandscapeUrl(data.landscape);
    }
  }, [data, form]);

  return (
    <Modal visible={visible} style={{ border: '2px solid #ebebeb', borderRadius: 10 }} width={'80%'} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="text-primary">{t('edit-ad-settings')}</h4>

            <div className="row mt-5">
              <div className="col-12">
                <Form form={form} onFinish={onSubmitLocal} onFinishFailed={(errorInfo) => {}}>
                  <div className="col">
                    <div className="row" style={{}}>
                      <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                        <div className="row-md-12">
                          <ImageUploader
                            onSubmit={(values: any) => {
                              setImagePortraitUrl(values);
                            }}
                            initialData={{
                              uploadedImage: imagePortraitUrl,
                              uploadName: t('logo-portrait'),
                              uploadType: 'portrait_wide_logo',
                              path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).APPBUILDER.IMAGES,
                            }}
                          />
                        </div>
                        <div className="row-md-12">
                          <ImageUploader
                            onSubmit={(values: any) => {
                              setImageLandscapeUrl(values);
                            }}
                            initialData={{
                              uploadedImage: imageLandscapeUrl,
                              uploadName: t('logo-landscape'),
                              uploadType: 'landscape_wide_logo',
                              path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).APPBUILDER.IMAGES,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 p-0 mt-3">
                    <button type="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-240 height-48">
                      {t('update-ad')}
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LayoutModel;
