import { faArrowRightToLine, faArrowUpArrowDown, faMemo, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TicketSeverity } from '@mwaretv/database/build/backend/enums/customers/ticketSeverity';
import { TicketStatus } from '@mwaretv/database/build/backend/enums/customers/ticketStatus';
import { Button, Col, Form, Modal, notification, Table, Tag, Tooltip } from 'antd';
import React, { useContext } from 'react';
import { useParams, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { ContainerWrapper } from '../../../../common/components/containerWrapper';
import { PageTitle } from '../../../../common/components/pageTitle';
import { PaginatedResults, useDeleteMutation, usePatchMutation, useTickets } from '../../../../services/areas/customers/ticketService';
import confirm from 'antd/lib/modal/confirm';
import { CardForm, FormHeading, Item, ModalFormWrapper, Select } from '../../../../common/components/form';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, UseQueryResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { Ticket } from '../../../../interfaces/forms/areas/customers/tickets/ticket';
import { Spin } from '../../../../common/components/spinner';
import { CustomersContext } from '../../../../contexts/customersContext';
import { useTranslation } from 'react-i18next';

const List = () => {
  const {t} = useTranslation();
  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-md-6 mb-2">
          <PageTitle title={t('tickets-0')} subtitle={t('all-your-customer-tickets')} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-5 offset-md-7">
          <ActionBar />
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-4">
          <TicketTable />
        </div>
      </div>
    </ContainerWrapper>
  );
};

const TicketTable = () => {
  const { deployment } = useContext(CustomersContext);
  const { clientName, service: serviceName } = useParams<{ clientName: string; service: string }>();
  const [pagination, setPagination] = React.useState<{ limit: number; page: number }>({ page: 1, limit: 2 });
  const { isLoading, isError, data, refetch } = useTickets({ params: { instance: clientName, cms: deployment?.name || '', crm: serviceName, ...pagination } });

  return <TicketTableTable {...{ pagination, setPagination, isLoading, isError, data, refetch }} />;
};

type TicketTableTableProps = {
  isLoading: boolean;
  isError: boolean;
  data: AxiosResponse<PaginatedResults<Ticket[]>> | undefined;
  refetch: <TPageData>(options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined) => Promise<QueryObserverResult<AxiosResponse<PaginatedResults<Ticket[]>>, unknown>>;
  pagination: {
    limit: number;
    page: number;
  };
  setPagination: React.Dispatch<
    React.SetStateAction<{
      limit: number;
      page: number;
    }>
  >;
};

export const TicketTableTable = ({ isLoading, isError, data, refetch, pagination, setPagination }: TicketTableTableProps) => {
  const {t} = useTranslation();
  return (
    <>
      {isLoading && <Spin />}
      {isError && <span>{t('something-went-wrong')}</span>}
      {!isLoading && !isError && (
        <Table
          dataSource={data?.data.docs || []}
          columns={[
            { title: t('title'), dataIndex: 'title' },
            { title: t('status'), dataIndex: 'status' },
            {
              title: t('severity'),
              render: (row) => (
                <Tag style={{ border: '1px solid' }} color={getSeverityTagColor(row.severity)}>
                  {row.severity}
                </Tag>
              ),
            },
            { title: t('actions'), render: (row) => <TicketRowActions {...{ row }} onDelete={() => refetch()} onStatusChange={() => refetch()} /> },
          ]}
          pagination={{
            current: pagination.page,
            pageSize: pagination.limit,
            total: data?.data.totalDocs || 0,
            onChange: (page, size) => setPagination({ page, limit: size }),
          }}
        />
      )}
    </>
  );

  function getSeverityTagColor(severity: TicketSeverity) {
    switch (severity) {
      case TicketSeverity.LOW:
        return 'green';
      case TicketSeverity.MODERATE:
        return 'orange';
      case TicketSeverity.HIGH:
        return 'red';
    }
  }
};

type TicketRowActionsProps = {
  onDelete: () => void;
  onStatusChange: () => void;
  row: any;
};

const TicketRowActions = ({ onDelete, onStatusChange, row }: TicketRowActionsProps) => {
  const {t} = useTranslation();
  const { url } = useRouteMatch();
  const { deployment } = useContext(CustomersContext);
  const { clientName, service: serviceName } = useParams<{ clientName: string; service: string }>();
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);

  const { isLoading: deleteIsLoading, mutate } = useDeleteMutation(
    { params: { instance: clientName, cms: deployment?.name || '', crm: serviceName } },
    {
      onSuccess: () => {
        notification.success({ message: t('ticket-deleted')});
        onDelete();
      },
      onError: () => {
        notification.error({ message: t('ticket-could-not-be-deleted') });
      },
    }
  );

  return (
    <Col style={{ display: 'flex', flexDirection: 'row', height: 60 }}>
      <Tooltip placement="top" title={`Delete`}>
        <Button
          loading={deleteIsLoading}
          disabled={deleteIsLoading}
          type="text"
          className="mx-2 cursor-pointer"
          onClick={() =>
            confirm({
              title: `Are you sure to delete selected ticket?`,
              okText: 'Yes',
              okType: 'danger',
              cancelText: 'No',
              async onOk() {
                mutate(row._id);
              },
              onCancel() {},
            })
          }
        >
          <FontAwesomeIcon className="action-icon  fa-trash-icon" icon={faTrashCan} />
          <div>{t('delete')}</div>
        </Button>
      </Tooltip>

      <Tooltip placement="top" title={`Change Status`}>
        <Button type="text" className="mx-2 cursor-pointer" onClick={() => setModalOpen(true)}>
          <FontAwesomeIcon className="action-icon" icon={faArrowUpArrowDown} />
          <div>{t('status')}</div>
        </Button>
      </Tooltip>
      <Tooltip placement="top" title={`Details`}>
        <Button type="text" className="mx-2 cursor-pointer" href={`${url}/${row._id}`}>
          <FontAwesomeIcon className="action-icon" icon={faMemo} />
          <div>{t('open-details')}</div>
        </Button>
      </Tooltip>
      <ChangeStatusModal
        {...{ row }}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onDone={() => {
          onStatusChange();
          setModalOpen(false);
        }}
      />
    </Col>
  );
};

type ChangeStatusModalProps = {
  row: any;
  open: boolean;
  onClose: () => void;
  onDone: () => void;
};

const ChangeStatusModal = ({ open, onClose, onDone, row }: ChangeStatusModalProps) => {
  const { clientName, service: serviceName } = useParams<{ clientName: string; service: string }>();
  const { deployment } = useContext(CustomersContext);
  const [form] = Form.useForm();
  const {t} = useTranslation();

  const { isLoading, mutate } = usePatchMutation(
    { params: { instance: clientName, cms: deployment?.name || '', crm: serviceName }, id: row._id },
    {
      onSuccess: () => {
        notification.success({ message: t('ticket-status-updated')});
        onDone();
      },
      onError: () => {
        notification.error({ message: t('something-went-wrong-updating-the-ticket-status-please-try-again-later') });
      },
    }
  );

  return (
    <Modal
      {...{ open }}
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
    >
      <ModalFormWrapper>
        <CardForm
          {...{ form }}
          layout="vertical"
          onFinish={(values) => {
            mutate(values);
          }}
        >
          <FormHeading>{t('change-ticket-status')}</FormHeading>
          <Item name={['status']} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label={t('status')} initialValue={row.status} extra={t('change-the-status-of-the-ticket-to-either-complete-busy-or-pending')}>
            <Select options={Object.values(TicketStatus).map((s) => ({ label: s, value: s }))} />
          </Item>
        </CardForm>
      </ModalFormWrapper>
    </Modal>
  );
};

const ActionBar = () => {
  const { url } = useRouteMatch();
  const {t} = useTranslation();
  return (
    <div className="d-flex justify-content-center justify-content-md-end align-items-start">
      <Link className="btn btn-secondary rounded-pill btn-default width-240 mt-2 mt-md-0 height-48" to={{ pathname: `${url}/add` }}>
        {t('add-ticket')}
      </Link>
    </div>
  );
};

export default List;
