import { faArrowsUpDown, faList, faPenToSquare, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Tooltip } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { useEffect, useState } from 'react';
import { IReactBCTableColumn } from '../../ReactBCTable/Interfaces/IReactBCTableColumn.interface';
import { IPaginatedAndFilterResponse, PaginatedAndFilterResponseDefaultValues } from '../../../../interfaces/responses/common/IPaginatedAndFilterResponse';
import ReactBCTable from '../../ReactBCTable';
import CategoryModal from './CategoryModal';
import ReactDragListView from 'react-drag-listview';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ExpandedPackageRender = (params: { loading: boolean; package: any; deployment: any; clientName: any; type: any; data: any; onSubmit: any; onDeleteCategory: any }) => {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([] as any);
  const { url } = useRouteMatch();
  const [selectedCategory, setSelectedCategory] = useState([] as any);
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(9999);
  const [pagination, setPagination] = useState<IPaginatedAndFilterResponse>(PaginatedAndFilterResponseDefaultValues);
  const { t } = useTranslation();

  useEffect(() => {
    const data = params.data;
    setData(data);
  }, [params.data]);

  const deleteCategory = (row: any, index: number) => {
    data.categories.splice(index, 1);
    var out = {
      ...data,
    };
    setData(out);
    params.onDeleteCategory(out);
  };

  const onSubmitLocal = (values: any, newCat: boolean) => {
    if (newCat) {
      const trans = values.translations;
      switch (params.type) {
        case 'Channels':
          data.categories.push({
            channels: [],
            translations: trans,
            styling: values.styling,
            nested: values.nested,
          });
          break;
        case 'Radios':
          data.categories.push({
            radios: [],
            translations: trans,
            styling: values.styling,
            nested: values.nested,
          });
          break;
        case 'Movies':
          data.categories.push({
            movies: [],
            translations: trans,
            styling: values.styling,
            nested: values.nested,
          });
          break;
        case 'Cams':
          data.categories.push({
            cams: [],
            translations: trans,
            styling: values.styling,
            nested: values.nested,
          });
          break;
        case 'Games':
          data.categories.push({
            games: [],
            translations: trans,
            styling: values.styling,
            nested: values.nested,
          });
          break;
        case 'Series':
          data.categories.push({
            series: [],
            translations: trans,
            styling: values.styling,
            nested: values.nested,
          });
          break;
        case 'Shorts':
          data.categories.push({
            shorts: [],
            translations: trans,
            styling: values.styling,
            nested: values.nested,
            mappings: values.mappings ?? [],
            external: values.external ?? false,
          });
          break;
        case 'Courses':
          data.categories.push({
            courses: [],
            translations: trans,
            styling: values.styling,
            nested: values.nested,
          });
          break;
        case 'Podcasts':
          data.categories.push({
            podcasts: [],
            translations: trans,
            styling: values.styling,
            nested: values.nested,
          });
          break;
        case 'Albums':
          data.categories.push({
            albums: [],
            translations: trans,
            styling: values.styling,
            nested: values.nested,
          });
          break;

        default:
          break;
      }
    } else {
      switch (params.type) {
        case 'Channels':
          data.categories[selectedCategoryIndex].translations = values.translations;
          data.categories[selectedCategoryIndex].styling = values.styling;
          data.categories[selectedCategoryIndex].nested = values.nested;
          break;
        case 'Radios':
          data.categories[selectedCategoryIndex].translations = values.translations;
          data.categories[selectedCategoryIndex].styling = values.styling;
          data.categories[selectedCategoryIndex].nested = values.nested;
          break;
        case 'Movies':
          data.categories[selectedCategoryIndex].translations = values.translations;
          data.categories[selectedCategoryIndex].styling = values.styling;
          data.categories[selectedCategoryIndex].nested = values.nested;
          break;
        case 'Cams':
          data.categories[selectedCategoryIndex].translations = values.translations;
          data.categories[selectedCategoryIndex].styling = values.styling;
          data.categories[selectedCategoryIndex].nested = values.nested;
          break;
        case 'Games':
          data.categories[selectedCategoryIndex].translations = values.translations;
          data.categories[selectedCategoryIndex].styling = values.styling;
          data.categories[selectedCategoryIndex].nested = values.nested;
          break;
        case 'Series':
          data.categories[selectedCategoryIndex].translations = values.translations;
          data.categories[selectedCategoryIndex].styling = values.styling;
          data.categories[selectedCategoryIndex].nested = values.nested;
          break;
        case 'Shorts':
          data.categories[selectedCategoryIndex].translations = values.translations;
          data.categories[selectedCategoryIndex].styling = values.styling;
          data.categories[selectedCategoryIndex].mappings = values.mappings ?? [];
          data.categories[selectedCategoryIndex].external = values.external ?? false;
          data.categories[selectedCategoryIndex].nested = values.nested;
          break;
        case 'Courses':
          data.categories[selectedCategoryIndex].translations = values.translations;
          data.categories[selectedCategoryIndex].styling = values.styling;
          data.categories[selectedCategoryIndex].nested = values.nested;
          break;
        case 'Podcasts':
          data.categories[selectedCategoryIndex].translations = values.translations;
          data.categories[selectedCategoryIndex].styling = values.styling;
          data.categories[selectedCategoryIndex].nested = values.nested;
          break;

        case 'Albums':
          data.categories[selectedCategoryIndex].translations = values.translations;
          data.categories[selectedCategoryIndex].styling = values.styling;
          data.categories[selectedCategoryIndex].nested = values.nested;
          break;
        default:
          break;
      }
    }

    var newCats = [] as any;
    data.categories.forEach((cat) => {
      if (cat.translations != undefined) {
        newCats.push(cat);
      }
    });
    data.categories = newCats;
    setSelectedCategory(data);
    setShowModal(false);
    setData(data);
    params.onSubmit(data);
  };

  const dragProps = {
    onDragEnd(fromIndex: any, toIndex: any) {
      const data_ = [...data.categories];
      const item = data_.splice(fromIndex, 1)[0];
      data_.splice(toIndex, 0, item);
      const out = {
        ...data,
        categories: data_,
      };
      setData(out);
      params.onSubmit(out);
    },
    lineClassName: 'global-drag-line',
    handleSelector: '.drag-handle',
  };

  const categoryColumns: IReactBCTableColumn<[]>[] = [
    {
      title: t('drag-and-drop'),
      dataKey: 'operate',
      width: '10%',
      cell: (row: any) => (
        <Tooltip placement="top" title={`Drag to Move Row`} key={'move'}>
          <FontAwesomeIcon className="action-icon drag-handle" color={'#999'} size={'xl'} icon={faArrowsUpDown} />
        </Tooltip>
      ),
    },
    {
      title: t('category-name'),
      dataKey: 'name',
      cell: (row: any) => (
        <>
          {row.translations !== undefined && (
            <div>
              {row.translations.map((r, index) => {
                return (
                  <div key={index}>
                    <b>{r.name}: </b>
                    {r.description}
                  </div>
                );
              })}
            </div>
          )}
        </>
      ),
    },
    {
      title: t('actions'),
      dataKey: 'action',
      width: '30%',
      cell: (row: any, index: any) => (
        <div className="justify-content-end" style={{ height: 60 }}>
          <Tooltip placement="top" title={`Delete Category`} key={'Delete'}>
            <Button
              className="mx-2 cursor-pointer"
              onClick={() =>
                confirm({
                  title: `Are you sure to delete selected Category ?`,
                  okText: 'Yes',
                  okType: 'danger',
                  cancelText: 'No',
                  async onOk() {
                    await deleteCategory(row, index);
                  },
                  onCancel() {},
                })
              }
              type="text"
            >
              <FontAwesomeIcon className="action-icon  fa-trash-icon" icon={faTrashCan} />
              <div>{t('delete')}</div>
            </Button>
          </Tooltip>
          <Tooltip placement="top" title={`Edit Category Name`} key={'edit'}>
            <Button
              onClick={() => {
                setSelectedCategory(row);
                setSelectedCategoryIndex(index);
                setShowModal(true);
              }}
              className="mx-2 cursor-pointer"
              type="text"
            >
              <FontAwesomeIcon className="action-icon" icon={faPenToSquare} />
              <div>{t('edit')}</div>
            </Button>
          </Tooltip>
          <Tooltip placement="top" title={`Category Details (Add/Remove/Order Content)`} key={'details'}>
            {row.translations !== undefined && row.translations[0] !== undefined && (
              <a href={`${url}` + '/content/' + params.package.name + '/' + params.package._id + '/' + row.translations[0].description + '/' + index}>
                <Button className="mx-2 cursor-pointer" type="text">
                  <FontAwesomeIcon className="action-icon" icon={faList} />
                  {params.type === 'Channels' && (
                    <div>
                      {params.type} ({row.channels.length})
                    </div>
                  )}
                  {params.type === 'Cams' && (
                    <div>
                      {params.type} ({row.cams.length})
                    </div>
                  )}
                    {params.type === 'Games' && (
                    <div>
                      {params.type} ({row.games.length})
                    </div>
                  )}
                  {params.type === 'Radios' && (
                    <div>
                      {params.type} ({row.radios.length})
                    </div>
                  )}
                  {params.type === 'Movies' && (
                    <div>
                      {params.type} ({row.movies.length})
                    </div>
                  )}
                  {params.type === 'Series' && (
                    <div>
                      {params.type} ({row.series.length})
                    </div>
                  )}
                  {params.type === 'Courses' && (
                    <div>
                      {params.type} ({row.courses.length})
                    </div>
                  )}
                  {params.type === 'Podcasts' && (
                    <div>
                      {params.type} ({row.podcasts.length})
                    </div>
                  )}
                  {params.type === 'Albums' && (
                    <div>
                      {params.type} ({row.albums.length})
                    </div>
                  )}
                  {params.type === 'Shorts' && (
                    <div>
                      {params.type} ({row.shorts.length})
                    </div>
                  )}
                </Button>
              </a>
            )}
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div className="row mt-2 ml-2">
      <div className="col-md-6 d-flex justify-content-center justify-content-md-start">
        <Button onClick={() => setShowModal(!showModal)} className="btn btn-secondary rounded-pill btn-default float-right  height-48">
          {t('add-category')}
        </Button>
        {showModal && (
          <CategoryModal
            initialData={{
              type: params.type,
              name: params.data,
              ...selectedCategory,
            }}
            visible={showModal}
            onSubmit={onSubmitLocal}
            onCancel={() => setShowModal(!showModal)}
          />
        )}
      </div>
      <div className="col-md-12 mt-4">
        {!params.loading && data.categories !== undefined && (
          <ReactDragListView {...dragProps}>
            {data.categories[0].translations !== undefined && data.categories[0].translations[0] !== undefined && (
              <ReactBCTable
                //onChange={(pageNumber, pageSize, pageSort, filters) => getContent({pageNumber, pageSize, pageSort, filters})}
                totalRecords={pagination.count}
                pageSize={pagination.pageSize}
                currentPage={pagination.pageNumber}
                isLoading={params.loading}
                tableId={`tablebundles`}
                data={data.categories}
                columns={categoryColumns}
              />
            )}
          </ReactDragListView>
        )}
      </div>
    </div>
  );
};

export default ExpandedPackageRender;
