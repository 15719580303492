import { Button, Form, Input, Modal } from 'antd';
import { Spin } from '../../spinner';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';
import SunEditor from 'suneditor-react';
import { TextArea } from '../../form';

type IUpdateLanguageModalProps = {
  visible: boolean;
  onSubmit: (values: any, text) => void;
  onCancel: () => void;
  initialData: {
    _id: string;
    name: string;
    description?: string;
  };
  fullEditor: boolean;
};

export const TranslationModal = (props: IUpdateLanguageModalProps) => {
  const { visible, onSubmit, onCancel, initialData, fullEditor } = props;
  const [text, setText] = useState('');
  const [form] = useForm();
const { t } = useTranslation();
  const [isFetchingData] = useState<boolean>(false);

  useEffectOnce(() => {
    form.setFieldsValue(initialData);
  });

  return (
    <Modal open={visible} style={{ border: '1px solid #ebebeb' }} width={640} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4>{t('update-translation')}</h4>
            {!isFetchingData && (
              <Form form={form} onFinish={(values) => onSubmit(values, text)} onFinishFailed={() => {}}>
                <label className="col-form-label">{t('description')}</label>
                {fullEditor && (
                  <SunEditor
                    onChange={(content) => setText(content)}
                    setOptions={{
                      buttonList: [['undo'], ['fontSize', 'formatBlock', 'paragraphStyle', 'blockquote'], ['bold', 'underline', 'italic', 'strike'], ['fontColor', 'hiliteColor', 'textStyle', 'align', 'lineHeight'], ['table', 'link'], ['preview']],
                    }}
                    defaultValue={initialData.description}
                    height={'200px'}
                  />
                )}
                {!fullEditor && (
                  <Form.Item name="description">
                    <TextArea defaultValue={initialData.description} rows={4}></TextArea>
                  </Form.Item>
                )}

                <Form.Item name="_id">
                  <Input value={initialData?._id} hidden />
                </Form.Item>

                <div className="col-md-12 p-0">
                  <Button htmlType="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                    {t('update-translation-0')}
                  </Button>
                </div>
              </Form>
            )}
            {isFetchingData && (
              <div className="row text-center h-100 w-100">
                <div className="col-12 align-self-center">
                  <Spin size="large" spinning={true} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
