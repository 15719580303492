import { Button, Col, notification, Row, Table, Tooltip } from 'antd';
import { StatusCodes } from 'http-status-codes';
import { useState } from 'react';
import { useParams } from 'react-router';
import { CustomNotification } from '../../../../../common/components/Notification';
import tagsService from '../../../../../services/areas/content/tags/tags/index';
import { IMSNameParams } from '../../../../dashboard';
import { useEffectOnce } from 'react-use';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import Tag from '../../../../../common/components/Content/Tag/Tag';
import { useTranslation } from 'react-i18next';

const PpvRules = () => {
  const { clientName, deployment: service_name } = useParams<IMSNameParams & { deployment: string }>();
  const [rowToBeUpdate, setRowToBeUpdate] = useState({
    _id: '',
    createdAt: new Date(),
    name: '',
    key: 'de',
    type: '',
    quantity: 0,
    updatedAt: new Date(),
    __v: 0,
  });
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [data, setData] = useState([] as any);
const {t} = useTranslation();
  const [showAddModal, setShowAddModal] = useState(false);
  const [translations, setTranslations] = useState([] as any);

  const handleSubmit = async (tags: any) => {
    setIsloading(true);
    let response = await tagsService.Add_Tags({
      name: '',
      tag: tags,
      query_params: {
        cms: service_name || '',
        instance: clientName,
      },
    });

    if (response && response.status_code === StatusCodes.OK) {
      setShowAddModal(false);
      getPPV();
      notification.success({
        message: t('success-0'),
        description: t('rule-added-successfully'),
      });
    } else
      CustomNotification({
        error: response.errors,
      });
    setIsloading(false);
  };

  const handleUpdate = async (values: any) => {
    setIsloading(true);
    let response = await tagsService.Update_Tags({
      query_params: {
        cms: service_name || '',
        instance: clientName,
      },
      ...values,
    });

    if (response && response.status_code === StatusCodes.OK) {
      notification.success({
        message: t('success-0'),
        description: t('update-successfully-0'),
      });
      setShowUpdateModal(false);
    }
    setIsloading(false);
  };
  const handleDelete = async (row: any) => {
    setIsloading(true);
    let response = await tagsService.Delete_Tags({
      query_params: {
        cms: service_name || '',
        instance: clientName,
      },
      _id: row._id,
    });

    if (response && response.status_code === StatusCodes.OK) {
      notification.success({
        message: t('success-0'),
        description: t('delete-successfully'),
      });
      getPPV();
      setShowUpdateModal(false);
    }
    setIsloading(false);
  };

  const getPPV = async () => {
    setIsloading(true);
    let response = await tagsService.List_Tags({
      query_params: {
        instance: clientName,
        cms: service_name || '',
      },
      pageNumber: 1,
      pageSize: 10000,
    });
    if (response && response.status_code === StatusCodes.OK) {
      if (response.data != undefined && response.data.data != undefined) {
        setData(response.data.data.sort((a, b) => a.name.localeCompare(b.name)));
      }
    }
    setIsloading(false);
  };

  useEffectOnce(() => {
    getPPV();
  });

  const handleCancel = () => {
    setShowUpdateModal(false);
    setShowAddModal(false);
  };

  const columns = [
    {
      title:t('name'),
      width: '90%',
      render: (row: any) => (
        <div>
          {row.name != '' && <div>{row.name}</div>}
          {row.name == '' && (
            <div>
              {row.tag.map((t) => {
                return (
                  <div className="col">
                    <b>{t.name}: </b>
                    {t.description}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      ),
    },
    {
      title: t('actions'),
      dataIndex: 'action',
      render: (tags: any, row: any) => (
        <span className="justify-content-end" style={{ height: 60 }}>
          <Tooltip placement="top" title={`Edit`}>
            <Button
              type="text"
              className="mx-2 cursor-pointer"
              onClick={() => {
                handleDelete(row);
              }}
            >
              <FontAwesomeIcon className="action-icon" icon={faTrashCan} />
              <div>{t('delete')}</div>
            </Button>
          </Tooltip>
        </span>
      ),
    },
  ];

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-7 mb-2">
          <h2 className="page-title">{t('tags')}</h2>
          <p className="h6 font-weight-normal line-height-26">{t('setup-your-content-tags-you-can-use-them-with-your-content-items-you-can-also-create-them-from-the-content-section-to')}</p>
        </div>
        <div className="col-md-5"></div>
      </div>
      <div className="row mt-4">
        <div className="col-md-7 mb-2"></div>
        <div className="col-md-5">
          <Button className="btn btn-secondary rounded-pill btn-default float-right width-180 height-48" onClick={() => setShowAddModal(true)}>
            {t('add-tag-0')}
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-4">
          <Table columns={columns} dataSource={data} loading={isLoading} />
        </div>
      </div>
      {showAddModal && <Tag service_name={service_name} clientName={clientName} setTranslations={setTranslations} onCancel={() => setShowAddModal(false)} onSubmit={handleSubmit} visible={showAddModal}></Tag>}
      {showUpdateModal && <Tag service_name={service_name} clientName={clientName} setTranslations={setTranslations} onCancel={() => setShowUpdateModal(false)} onSubmit={handleUpdate} visible={showUpdateModal}></Tag>}
    </div>
  );
};

export default PpvRules;
