import { Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';

const Streams = (props: { params: any }) => {
  const { t } = useTranslation();
  return (
    <div>
      <h4 className="text-primary mt-5 mb-3">{t('streams')}</h4>
      <div>
        <div className="col-md-12 card-bg-light p-5">
          <div className="row">
            <label className="col-md-3 col-form-label">Android/FireTV/AndroidTV</label>
            <Form.Item
              className="col-md-8"
              name={['streams', 'channels', 'urls', 'standard_url']}
              extra={t('we-recommend-to-use-dash-hls-or-udp-with-drm-or-token-protection')}
              rules={[
                {
                  required: true,
                  message: t('url-is-required'),
                },
              ]}
            >
              <Input type={'url'} className="form-control bg-white font-size-14 text-default" placeholder="https://stream.com" />
            </Form.Item>
            <label className="col-md-3 col-form-label">iOS/tvOS/KaiOS</label>
            <Form.Item
              className="col-md-8"
              name={['streams', 'channels', 'urls', 'ios_tvos']}
              extra={t('we-recommend-to-use-hls-also-with-with-drm-or-token-protection')}
              rules={[
                {
                  required: true,
                  message: t('url-is-required'),
                },
              ]}
            >
              <Input type={'url'} className="form-control bg-white font-size-14 text-default" placeholder="https://stream.com" />
            </Form.Item>
            <label className="col-md-3 col-form-label">Tizen/WebOS/Vidaa/WebTV</label>
            <Form.Item
              className="col-md-8"
              name={['streams', 'channels', 'urls', 'tizen_webos']}
              extra={t('we-recommend-to-use-dash-with-drm-or-token-protection')}
              rules={[
                {
                  required: true,
                  message: t('url-is-required'),
                },
              ]}
            >
              <Input type={'url'} className="form-control bg-white font-size-14 text-default" placeholder="https://stream.com" />
            </Form.Item>
          </div>
        </div>
        <h4 className="text-primary mt-5 mb-3">{t('querystring-parameters')}</h4>
        <p>{t('add-your-content-parameters-these-are-querystring-additions-that-you-can-add-to-the-streaming-urls-most-fasttv-providers-need-this-extra-infornmation-to-generate-an-ad')}</p>
        <div className="col-md-12 card-bg-light p-5 mt-4">
          <div className="row">
            <label className="col-md-3 col-form-label">{t('params')}</label>
            <Form.Item className="col-md-8" name={['streams', 'params', '_id']}>
              <Select className="default-select row-md-6" labelInValue>
                <Select.Option value={undefined} key={-1}>
                  {t('no-parameters')}
                </Select.Option>
                {props?.params?.map((param) => {
                  return (
                    <Select.Option value={param._id} key={param._id}>
                      {param.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Streams;
