import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';

export const ColumnChart = ({ options = {}, series, horizontal = false, categories = [] }: { options?: any; series: ApexAxisChartSeries; horizontal?: boolean; categories?: string[] }) => {
  const chartOptions: ApexOptions = {
    //...baseOptions,
    ...options,
    grid: {
      show: false,
    },
    // plotOptions: {
    //  // ...options.plotOptions,
    //   bar: {
    //    // ...options.plotOptions?.bar,
    //     //distributed: true,
    //    //barWidth: '10px',
    //    // borderRadius: 5,
    //   },
    // },
    // dataLabels: {
    //   enabled: false,
    // },
    // xaxis: {
    //   categories,
    //   ...options.xaxis,
    // },
    legend: {
      show: false,
    },
  };

  return <ReactApexChart options={chartOptions} series={series} type="bar" height="300" />;
};
