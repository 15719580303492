import { Workflows } from '../../../../../interfaces/forms/areas/content/workflows/workflows';
import { IGetForm } from '../../../../../interfaces/forms/common/ICMSExtraQuerytParams';
import { ICMS_Lists } from '../../../../../interfaces/forms/common/ICMS_Lists';
import { Workflows as Response } from '../../../../../interfaces/responses/areas/content/workflows/workflows';

import { IBaseResponse } from '../../../../../interfaces/responses/IBaseResponse';
import axiosInstance from '../../../../../utils/axios';
import { API_ENDPOINTS } from '../../../../_APIConstants';
import { TimeStamps } from '@typegoose/typegoose/lib/defaultClasses';

class Workflow_Service {
  Add_Workflow = async (data: Workflows): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.WORKFLOWS.WORKFLOWS.ADD, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  List_Workflows = async (data: ICMS_Lists): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.WORKFLOWS.WORKFLOWS.LIST, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Details_Workflow = async (data: IGetForm): Promise<Response> => {
    return await axiosInstance
      .get(`${API_ENDPOINTS.V1.CMS.WORKFLOWS.WORKFLOWS.DETAILS}/${data._id}`, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Test_Workflow = async (data: IGetForm): Promise<Response> => {
    return await axiosInstance
      .get(`${API_ENDPOINTS.V1.CMS.WORKFLOWS.WORKFLOWS.TEST}/${data._id}`, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Update_Workflow = async (data: Workflows): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(`${API_ENDPOINTS.V1.CMS.WORKFLOWS.WORKFLOWS.UPDATE}/${data._id}`, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Delete_Workflow = async (data: IGetForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .delete(`${API_ENDPOINTS.V1.CMS.WORKFLOWS.WORKFLOWS.DELETE}/${data._id}`, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
          path: data.query_params.path,
        },
        data,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
}

export default new Workflow_Service();
