import { Button, Col, Row, Tooltip } from 'antd';
import { StatusCodes } from 'http-status-codes';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { ICMS_Lists } from '../../../interfaces/forms/common/ICMS_Lists';
import { IPaginatedAndFilterResponse, PaginatedAndFilterResponseDefaultValues } from '../../../interfaces/responses/common/IPaginatedAndFilterResponse';
import ReactBCTable from '../ReactBCTable';
import { IReactBCTableColumn } from '../ReactBCTable/Interfaces/IReactBCTableColumn.interface';
import { faMemo, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cmsApiBuilder from '../../../services/areas/billing/apibuilder';
import { useRouteMatch } from 'react-router';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Table = (params: { deployment: any; service: any; clientName: any; type: any }) => {
  const { url } = useRouteMatch();
  const [data, setData] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);
  const {t} = useTranslation();
  const [tableSearchState, setTableSearchState] = useState<ICMS_Lists>({
    pageNumber: 1,
    pageSize: 50,
    query_params: {
      instance: params.clientName,
      crm: params.service.name ?? '',
      cms: params.deployment.name,
    },
  });
  const [pagination, setPagination] = useState<IPaginatedAndFilterResponse>(PaginatedAndFilterResponseDefaultValues);

  useEffectOnce(() => {
    getContent(tableSearchState);
  });

  const deleteItem = (id: string, app_id?: string) => {
    switch (params.type) {
      case 'apibuilder':
        (async () => await deleteApiBuilder(id))();
        break;
    }
  };

  const openDetails = (_id: string) => {
    return `${url}/details/${_id}`;
  };

  const getContent = (tableSearchState) => {
    switch (params.type) {
      case 'apibuilder':
        (async () => await getApiBuilder(tableSearchState))();
        break;
    }
  };

  useEffectOnce(() => {
    getContent(tableSearchState);
  });

  //channels
  const deleteApiBuilder = async (_id: string) => {
    setIsLoading(true);
    let response = await cmsApiBuilder.Delete_ApiBuilder({
      _id: _id,
      query_params: {
        crm: params.service.name,
        cms: params.deployment.name,
        instance: params.clientName,
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      getApiBuilder(tableSearchState);
    }
    setIsLoading(false);
  };
  const getApiBuilder = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsApiBuilder.List_ApiBuilder(list);
    if (response && response.status_code === StatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    setIsLoading(false);
  };

  const columns: IReactBCTableColumn<any>[] = [
    {
      title: 'Name',
      dataKey: 'name',
      width: '50%',
    },
    {
      title: 'Token',
      dataKey: '_id',
      width: '50%',
    },
    {
      dataKey: 'actions',
      title: 'Actions',

      cell: (row) => {
        return (
          <Col style={{ display: 'flex', flexDirection: 'row', height: 60 }}>
            <Row className="align-items-center">
              <div onClick={() => deleteItem(row._id, params.type == 'appupdates' ? row.app_id : '')}>
                <Tooltip placement="top" title={`Details`}>
                  <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                    <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
                    <div>{t('delete')}</div>
                  </Button>
                </Tooltip>
              </div>
            </Row>
            <Row className="align-items-center">
              <a href={openDetails(row._id)}>
                <Tooltip placement="top" title={`Details`}>
                  <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                    <FontAwesomeIcon className="action-icon" icon={faMemo} />
                    <div>{t('open-details')}</div>
                  </Button>
                </Tooltip>
              </a>
            </Row>
          </Col>
        );
      },
    },
  ];

  return (
    <div>
      <ReactBCTable onChange={(pageNumber, pageSize, pageSort, filters) => getContent({ pageNumber, pageSize, pageSort, filters })} isLoading={isLoading} totalRecords={pagination.count} pageSize={pagination.pageSize} currentPage={pagination.pageNumber} tableId={'cms_table'} columns={columns} data={data} />
    </div>
  );
};
export default Table;
