import { Button, Form, Input, Modal } from 'antd';
import { Spin } from '../../spinner';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/lib/input/TextArea';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';
type IUpdateLanguageModalProps = {
  visible: boolean;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  initialData: any;
};

const TranslationModal = (props: IUpdateLanguageModalProps) => {
  const { visible, onSubmit, onCancel, initialData } = props;
  let [form] = useForm();
  const [isFetchingData] = useState<boolean>(false);
  const {t} = useTranslation();

  useEffectOnce(() => {
    
    form.setFieldsValue(initialData);
  });
  return (
    <Modal visible={visible} style={{  borderRadius:5}} width={640} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4>{t('update-translation-0')}</h4>
            {!isFetchingData && (
              <Form form={form} onFinish={(values) => onSubmit(values)} onFinishFailed={(errorInfo) => {}}>
                <label className="col-form-label">{t('language')}</label>
                <Form.Item name="name">
                  <Input disabled></Input>
                </Form.Item>
                <Form.Item
                  name="description"
                  className="form-group mb-0"
                  rules={[
                    {
                      required: true,
                      message: t('description-is-required-1'),
                    },
                  ]}
                >
                  <TextArea className="form-control bg-white font-size-14 text-default" rows={4} placeholder={t('your-description-here')} />
                </Form.Item>

                <div className="col-md-12 p-0">
                  <Button htmlType="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                    {t('update-translation-0')}
                  </Button>
                </div>
              </Form>
            )}
            {isFetchingData && (
              <div className="row text-center h-100 w-100">
                <div className="col-12 align-self-center">
                  <Spin size="large" spinning={true} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TranslationModal;
