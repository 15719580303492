import { Button, Form, Modal } from 'antd';
import { useEffect, useState } from 'react';
import Params from '../../../../../../common/components/Content/Params/Params';
import { useTranslation } from 'react-i18next';
type UpdateModalProps = {
  visible: boolean;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  row: any;
  isUpdating: boolean;
};
const UpdateModal = (props: UpdateModalProps) => {
  const { visible, onSubmit, onCancel, row, isUpdating } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const handleSubmit = (e: any) => {
    onSubmit({
      ...row,
      ...e,
    });
  };

  useEffect(() => {
    if (row.name != undefined) {
      form.setFieldsValue(row);
      setIsLoaded(true);
    }
  }, [row]);

  return (
    <Modal visible={visible} style={{  borderRadius:5}} width={960} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="text-primary mb-3">{t('update-parameters')}</h4>
            {isLoaded && (
              <Form form={form} onFinish={(values) => handleSubmit(values)} onFinishFailed={(errorInfo) => {}}>
                <Params />

                <div className="col-md-12 p-0 pt-4">
                  <Button loading={isUpdating} disabled={isUpdating} htmlType="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                    {t('update-parameters-0')}
                  </Button>
                </div>
              </Form>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateModal;
