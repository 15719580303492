import { Button, Col, Tooltip } from 'antd';
import { StatusCodes } from 'http-status-codes';
import { useContext, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { ICMS_Lists } from '../../../../interfaces/forms/common/ICMS_Lists';
import { IPaginatedAndFilterResponse, PaginatedAndFilterResponseDefaultValues } from '../../../../interfaces/responses/common/IPaginatedAndFilterResponse';
import ReactBCTable from '../../ReactBCTable';
import { IReactBCTableColumn } from '../../ReactBCTable/Interfaces/IReactBCTableColumn.interface';
import { faList, faUpload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NestedListContent from './NestedListContent';

import cmsAlbums from '../../../../services/areas/content/albums/lists';
import cmsPodcasts from '../../../../services/areas/content/podcasts/lists';
import cmsMovies from '../../../../services/areas/content/movies/lists';
import cmsSeries from '../../../../services/areas/content/series/lists';
import cmsShorts from '../../../../services/areas/content/shorts/lists';
import cmsChannels from '../../../../services/areas/content/channels/lists';
import cmsRadios from '../../../../services/areas/content/radio/lists';
import cmsCams from '../../../../services/areas/content/cams/lists';
import cmsCourses from '../../../../services/areas/content/courses/lists';
import cmsGames from '../../../../services/areas/content/games/lists';

import moment from 'moment';
import { STORAGE_ENDPOINTS } from '../../../../services/storage_paths';
import { ContentContext } from '../../../../contexts/contentContext';
import { IMSNameParams } from '../../../../pages/dashboard';
import { useParams, useRouteMatch } from 'react-router-dom';
import ListModal from './ListModal';
import { useTranslation } from 'react-i18next';

const NestedLists = (params: { type: any }) => {
  const { clientName } = useParams<IMSNameParams>();
  const { service, deployment } = useContext(ContentContext);
  const { url } = useRouteMatch();
  const [data, setData] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedList, setSelectedList] = useState([] as any);
  const { t } = useTranslation();

  const filterValidationAccepted = {
    id: 'provider_status',
    value: { filterValue: 'accepted', filterType: 'text' },
  } as any;

  const filterValidationEmpty = {
    id: 'provider_status',
    value: { filterValue: '', filterType: 'text' },
  } as any;

  const filterValidationUndefined = {
    id: 'provider_status',
    value: { filterValue: 'undefined', filterType: 'undefined' },
  } as any;

  const [tableSearchState, setTableSearchState] = useState<ICMS_Lists>({
    pageNumber: 1,
    pageSize: 250,
    filters: [ filterValidationUndefined, filterValidationEmpty, filterValidationAccepted],
    query_params: {
      instance: clientName,
      cms: deployment?.name ?? '',
    },
  });
  const [pagination, setPagination] = useState<IPaginatedAndFilterResponse>(PaginatedAndFilterResponseDefaultValues);

  useEffectOnce(() => {
    switch (params.type) {
      case 'Albums':
        (async () => await getAlbumLists(tableSearchState))();
        break;
      case 'Podcasts':
        (async () => await getPodcastLists(tableSearchState))();
        break;
      case 'Channels':
        (async () => await getChannelLists(tableSearchState))();
        break;
      case 'Radios':
        (async () => await getRadioLists(tableSearchState))();
        break;
      case 'Movies':
        (async () => await getMovieLists(tableSearchState))();
        break;
      case 'Shorts':
        (async () => await getShortsLists(tableSearchState))();
        break;
      case 'Courses':
        (async () => await getCoursesLists(tableSearchState))();
        break;
      case 'Series':
        (async () => await getSeriesLists(tableSearchState))();
        break;
      case 'Cams':
        (async () => await getCamLists(tableSearchState))();
        break;
        case 'Games':
          (async () => await getGamesLists(tableSearchState))();
          break;
    }
  });

  const addAlbumList = async (values: any) => {
    setIsLoading(true);
    let response = await cmsAlbums.Add_List({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...values,
      publish: moment().format('llll'),
    });
    if (response && response.status_code === StatusCodes.OK) {
      getAlbumLists(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateAlbumList = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsAlbums.Update_List({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
    });
    if (response && response.status_code === StatusCodes.OK) {
      getAlbumLists(tableSearchState);
    }
    setIsLoading(false);
  };
  const deleteAlbumList = async (row: any) => {
    setIsLoading(true);
    let response = await cmsAlbums.Delete_List({
      _id: row._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      getAlbumLists(tableSearchState);
    }
    setIsLoading(false);
  };
  const getAlbumLists = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsAlbums.List_Lists(list);
    if (response && response.status_code === StatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };
  const publishAlbumList = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsAlbums.Publish_List({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.ALBUMS.LISTS,
      },
      ...package_,
    });
    if (response && response.status_code === StatusCodes.OK) {
      getAlbumLists(tableSearchState);
    }
    setIsLoading(false);
  };

  const addPodcastList = async (values: any) => {
    setIsLoading(true);
    let response = await cmsPodcasts.Add_List({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...values,
      publish: moment().format('llll'),
    });
    if (response && response.status_code === StatusCodes.OK) {
      getPodcastLists(tableSearchState);
    }
    setIsLoading(false);
  };
  const updatePodcastList = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsPodcasts.Update_List({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
    });
    if (response && response.status_code === StatusCodes.OK) {
      getPodcastLists(tableSearchState);
    }
    setIsLoading(false);
  };
  const deletePodcastList = async (row: any) => {
    setIsLoading(true);
    let response = await cmsPodcasts.Delete_List({
      _id: row._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      getPodcastLists(tableSearchState);
    }
    setIsLoading(false);
  };
  const getPodcastLists = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsPodcasts.List_Lists(list);
    if (response && response.status_code === StatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };
  const publishPodcastList = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsPodcasts.Publish_List({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.PODCASTS.LISTS,
      },
      ...package_,
    });
    if (response && response.status_code === StatusCodes.OK) {
      getPodcastLists(tableSearchState);
    }
    setIsLoading(false);
  };

  const addCamList = async (values: any) => {
    setIsLoading(true);
    let response = await cmsCams.Add_List({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...values,
      publish: moment().format('llll'),
    });
    if (response && response.status_code === StatusCodes.OK) {
      getCamLists(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateCamList = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsCams.Update_List({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
    });
    if (response && response.status_code === StatusCodes.OK) {
      getCamLists(tableSearchState);
    }
    setIsLoading(false);
  };
  const deleteCamList = async (row: any) => {
    setIsLoading(true);
    let response = await cmsCams.Delete_List({
      _id: row._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      getCamLists(tableSearchState);
    }
    setIsLoading(false);
  };
  const getCamLists = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsCams.List_Lists(list);
    if (response && response.status_code === StatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };
  const publishCamList = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsCams.Publish_List({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.CAMS.LISTS,
      },
      ...package_,
    });
    if (response && response.status_code === StatusCodes.OK) {
      getCamLists(tableSearchState);
    }
    setIsLoading(false);
  };

  // const addAppList = async (values: any) => {
  //     setIsLoading(true);
  //     let response = await cmsApps.Add_App_Lists({
  //         query_params: {
  //             instance: clientName,
  //             cms: deployment?.name ?? '',
  //         },
  //         ...values,
  //         publish: moment().format('llll')
  //     });
  //     if (response && response.status_code === StatusCodes.OK) {
  //         getAppLists(tableSearchState);
  //     }
  //     setIsLoading(false);
  // };
  // const updateAppList = async (package_: any) => {
  //     setIsLoading(true);
  //     let response = await cmsApps.Update_App_Lists({
  //         query_params: {
  //             instance: clientName,
  //             cms: deployment?.name ?? '',
  //         },
  //         ...package_
  //     });
  //     if (response && response.status_code === StatusCodes.OK) {
  //         getAppLists(tableSearchState);
  //     }
  //     setIsLoading(false);
  // };
  // const deleteAppList = async (row: any) => {
  //     setIsLoading(true);
  //     let response = await cmsApps.Delete_App_Lists({
  //         _id: row._id,
  //         query_params: {
  //             instance: clientName,
  //             cms: deployment?.name ?? '',
  //         },
  //     });
  //     if (response && response.status_code === StatusCodes.OK) {
  //         getAppLists(tableSearchState);
  //     }
  //     setIsLoading(false);
  // };
  // const getAppLists = async (list: ICMS_Lists) => {
  //     setIsLoading(true);
  //     let response = await cmsApps.List_App_Listss(list);
  //     if (response && response.status_code === StatusCodes.OK) {
  //         setData(response.data.data);
  //         setPagination(response.data);
  //     }
  //     setIsLoading(false);
  // };
  // const publishAppList = async (package_: any) => {
  //     setIsLoading(true);
  //     let response = await cmsApps.Publish_App_Lists({
  //         query_params: {
  //             instance: clientName,
  //             cms: deployment?.name ?? '',
  //         },
  //         ...package_
  //     });
  //     if (response && response.status_code === StatusCodes.OK) {
  //         getAppLists(tableSearchState);
  //     }
  //     setIsLoading(false);
  // };

  const addChannelList = async (values: any) => {
    setIsLoading(true);
    let response = await cmsChannels.Add_List({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...values,
      publish: moment().format('llll'),
    });
    if (response && response.status_code === StatusCodes.OK) {
      getChannelLists(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateChannelList = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsChannels.Update_List({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
    });
    if (response && response.status_code === StatusCodes.OK) {
      getChannelLists(tableSearchState);
    }
    setIsLoading(false);
  };
  const deleteChannelList = async (row: any) => {
    setIsLoading(true);
    let response = await cmsChannels.Delete_List({
      _id: row._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      getChannelLists(tableSearchState);
    }
    setIsLoading(false);
  };
  const getChannelLists = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsChannels.List_Lists(list);
    if (response && response.status_code === StatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };
  const publishChannelList = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsChannels.Publish_List({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.CHANNELS.LISTS,
      },
      ...package_,
    });
    if (response && response.status_code === StatusCodes.OK) {
      getChannelLists(tableSearchState);
    }
    setIsLoading(false);
  };

  const addRadioList = async (values: any) => {
    setIsLoading(true);
    let response = await cmsRadios.Add_List({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...values,
      publish: moment().format('llll'),
    });
    if (response && response.status_code === StatusCodes.OK) {
      getRadioLists(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateRadioList = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsRadios.Update_List({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
    });
    if (response && response.status_code === StatusCodes.OK) {
      getRadioLists(tableSearchState);
    }
    setIsLoading(false);
  };
  const deleteRadioList = async (row: any) => {
    setIsLoading(true);
    let response = await cmsRadios.Delete_List({
      _id: row._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      getRadioLists(tableSearchState);
    }
    setIsLoading(false);
  };
  const getRadioLists = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsRadios.List_Lists(list);
    if (response && response.status_code === StatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };
  const publishRadioList = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsRadios.Publish_List({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.RADIOS.LISTS,
      },
      ...package_,
    });
    if (response && response.status_code === StatusCodes.OK) {
      getRadioLists(tableSearchState);
    }
    setIsLoading(false);
  };

  const addMovieList = async (values: any) => {
    setIsLoading(true);
    let response = await cmsMovies.Add_List({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...values,
      publish: moment().format('llll'),
    });
    if (response && response.status_code === StatusCodes.OK) {
      getMovieLists(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateMovieList = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsMovies.Update_List({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
    });
    if (response && response.status_code === StatusCodes.OK) {
      getMovieLists(tableSearchState);
    }
    setIsLoading(false);
  };
  const deleteMovieList = async (row: any) => {
    setIsLoading(true);
    let response = await cmsMovies.Delete_List({
      _id: row._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      getMovieLists(tableSearchState);
    }
    setIsLoading(false);
  };
  const getMovieLists = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsMovies.List_Lists(list);
    if (response && response.status_code === StatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };
  const publishMovieList = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsMovies.Publish_List({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.MOVIES.LISTS,
      },
      ...package_,
    });
    if (response && response.status_code === StatusCodes.OK) {
      getMovieLists(tableSearchState);
    }
    setIsLoading(false);
  };

  const addShortsList = async (values: any) => {
    setIsLoading(true);
    let response = await cmsShorts.Add_List({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...values,
      publish: moment().format('llll'),
    });
    if (response && response.status_code === StatusCodes.OK) {
      getShortsLists(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateShortsList = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsShorts.Update_List({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
    });
    if (response && response.status_code === StatusCodes.OK) {
      getShortsLists(tableSearchState);
    }
    setIsLoading(false);
  };
  const deleteShortsList = async (row: any) => {
    setIsLoading(true);
    let response = await cmsShorts.Delete_List({
      _id: row._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      getShortsLists(tableSearchState);
    }
    setIsLoading(false);
  };
  const getShortsLists = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsShorts.List_Lists(list);
    if (response && response.status_code === StatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };
  const publishShortsList = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsShorts.Publish_List({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.SHORTS.LISTS,
      },
      ...package_,
    });
    if (response && response.status_code === StatusCodes.OK) {
      getShortsLists(tableSearchState);
    }
    setIsLoading(false);
  };

  const addSeriesList = async (values: any) => {
    setIsLoading(true);
    let response = await cmsSeries.Add_List({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...values,
      publish: moment().format('llll'),
    });
    if (response && response.status_code === StatusCodes.OK) {
      getSeriesLists(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateSeriesList = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsSeries.Update_List({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
    });
    if (response && response.status_code === StatusCodes.OK) {
      getSeriesLists(tableSearchState);
    }
    setIsLoading(false);
  };
  const deleteSeriesList = async (row: any) => {
    setIsLoading(true);
    let response = await cmsSeries.Delete_List({
      _id: row._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      getSeriesLists(tableSearchState);
    }
    setIsLoading(false);
  };
  const getSeriesLists = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsSeries.List_Lists(list);
    if (response && response.status_code === StatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };
  const publishSeriesList = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsSeries.Publish_List({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.SERIES.LISTS,
      },
      ...package_,
    });
    if (response && response.status_code === StatusCodes.OK) {
      getSeriesLists(tableSearchState);
    }
    setIsLoading(false);
  };

  const addCoursesList = async (values: any) => {
    setIsLoading(true);
    let response = await cmsCourses.Add_List({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...values,
      publish: moment().format('llll'),
    });
    if (response && response.status_code === StatusCodes.OK) {
      getCoursesLists(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateCoursesList = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsCourses.Update_List({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
    });
    if (response && response.status_code === StatusCodes.OK) {
      getCoursesLists(tableSearchState);
    }
    setIsLoading(false);
  };
  const deleteCoursesList = async (row: any) => {
    setIsLoading(true);
    let response = await cmsCourses.Delete_List({
      _id: row._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      getCoursesLists(tableSearchState);
    }
    setIsLoading(false);
  };
  const getCoursesLists = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsCourses.List_Lists(list);
    if (response && response.status_code === StatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };
  const publishCoursesList = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsCourses.Publish_List({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.COURSES.LISTS,
      },
      ...package_,
    });
    if (response && response.status_code === StatusCodes.OK) {
      getCoursesLists(tableSearchState);
    }
    setIsLoading(false);
  };

  const addGamesList = async (values: any) => {
    setIsLoading(true);
    let response = await cmsGames.Add_List({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...values,
      publish: moment().format('llll'),
    });
    if (response && response.status_code === StatusCodes.OK) {
      getGamesLists(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateGamesList = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsGames.Update_List({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
    });
    if (response && response.status_code === StatusCodes.OK) {
      getGamesLists(tableSearchState);
    }
    setIsLoading(false);
  };
  const deleteGamesList = async (row: any) => {
    setIsLoading(true);
    let response = await cmsGames.Delete_List({
      _id: row._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === StatusCodes.OK) {
      getGamesLists(tableSearchState);
    }
    setIsLoading(false);
  };
  const getGamesLists = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsGames.List_Lists(list);
    if (response && response.status_code === StatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };
  const publishGamesList = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsGames.Publish_List({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.GAMES.LISTS,
      },
      ...package_,
    });
    if (response && response.status_code === StatusCodes.OK) {
      getGamesLists(tableSearchState);
    }
    setIsLoading(false);
  };

  // const deleteList = (row: any) => {
  //   switch (params.type) {
  //     case 'Albums':
  //       (async () => await deleteAlbumList(row))();
  //       break;
  //     case 'Cams':
  //       (async () => await deleteCamList(row))();
  //       break;
  //     case 'Channels':
  //       (async () => await deleteChannelList(row))();
  //       break;
  //     case 'Radios':
  //       (async () => await deleteRadioList(row))();
  //       break;
  //     case 'Podcasts':
  //       (async () => await deletePodcastList(row))();
  //       break;
  //     case 'Movies':
  //       (async () => await deleteMovieList(row))();
  //       break;
  //     case 'Shorts':
  //       (async () => await deleteShortsList(row))();
  //       break;
  //     case 'Series':
  //       (async () => await deleteSeriesList(row))();
  //       break;
  //     case 'Courses':
  //       (async () => await deleteCoursesList(row))();
  //       break;
  //       case 'Games':
  //         (async () => await deleteGamesList(row))();
  //         break;
  //   }
  // };

  const onSubmitModal = (values: any) => {
    setSelectedList({
      ...selectedList,
      name: values.name,
    });
    switch (params.type) {
      case 'Albums':
        if (selectedList._id != undefined) {
          (async () => await updateAlbumList(selectedList))();
          setSelectedList(undefined);
        } else {
          (async () => await addAlbumList(values))();
        }
        break;
      case 'Cams':
        if (selectedList._id != undefined) {
          (async () => await updateCamList(selectedList))();
          setSelectedList(undefined);
        } else {
          (async () => await addCamList(values))();
        }
        break;
        case 'Games':
          if (selectedList._id != undefined) {
            (async () => await updateGamesList(selectedList))();
            setSelectedList(undefined);
          } else {
            (async () => await addGamesList(values))();
          }
          break;
      case 'Channels':
        if (selectedList._id != undefined) {
          (async () => await updateChannelList(selectedList))();
          setSelectedList(undefined);
        } else {
          (async () => await addChannelList(values))();
        }
        break;
      case 'Radios':
        if (selectedList._id != undefined) {
          (async () => await updateRadioList(selectedList))();
          setSelectedList(undefined);
        } else {
          (async () => await addRadioList(values))();
        }
        break;
      case 'Shorts':
        if (selectedList._id != undefined) {
          (async () => await updateShortsList(selectedList))();
          setSelectedList(undefined);
        } else {
          (async () => await addShortsList(values))();
        }
        break;
      case 'Movies':
        if (selectedList._id != undefined) {
          (async () => await updateMovieList(selectedList))();
          setSelectedList(undefined);
        } else {
          (async () => await addMovieList(values))();
        }
        break;
      case 'Series':
        if (selectedList._id != undefined) {
          (async () => await updateSeriesList(selectedList))();
          setSelectedList(undefined);
        } else {
          (async () => await addSeriesList(values))();
        }
        break;
      case 'Courses':
        if (selectedList._id != undefined) {
          (async () => await updateCoursesList(selectedList))();
          setSelectedList(undefined);
        } else {
          (async () => await addCoursesList(values))();
        }
        break;
      case 'Podcasts':
        if (selectedList._id != undefined) {
          (async () => await updatePodcastList(selectedList))();
          setSelectedList(undefined);
        } else {
          (async () => await addPodcastList(values))();
        }
        break;
    }
    setShowModal(false);
  };

  const onSubmitCategories = (package_: any) => {
    switch (params.type) {
      case 'Albums':
        (async () => await updateAlbumList(package_))();
        break;
      case 'Cams':
        (async () => await updateCamList(package_))();
        break;
      case 'Channels':
        (async () => await updateChannelList(package_))();
        break;
      case 'Radios':
        (async () => await updateRadioList(package_))();
        break;
      case 'Movies':
        (async () => await updateMovieList(package_))();
        break;
      case 'Series':
        (async () => await updateSeriesList(package_))();
        break;
      case 'Shorts':
        (async () => await updateShortsList(package_))();
        break;
      case 'Podcasts':
        (async () => await updatePodcastList(package_))();
        break;
      case 'Courses':
        (async () => await updateCoursesList(package_))();
        break;
        case 'Games':
          (async () => await updateGamesList(package_))();
          break;
    }
    setShowModal(false);
  };

  const refreshList = () => {
    switch (params.type) {
      case 'Albums':
        (async () => await getAlbumLists(tableSearchState))();
        break;
      case 'Cams':
        (async () => await getCamLists(tableSearchState))();
        break;
      case 'Channels':
        (async () => await getChannelLists(tableSearchState))();
        break;
      case 'Radios':
        (async () => await getRadioLists(tableSearchState))();
        break;
      case 'Movies':
        (async () => await getMovieLists(tableSearchState))();
        break;
      case 'Series':
        (async () => await getSeriesLists(tableSearchState))();
        break;
      case 'Shorts':
        (async () => await getShortsLists(tableSearchState))();
        break;
      case 'Podcasts':
        (async () => await getPodcastLists(tableSearchState))();
        break;
      case 'Courses':
        (async () => await getCoursesLists(tableSearchState))();
        break;
        case 'Games':
          (async () => await getGamesLists(tableSearchState))();
          break;
    }
    setShowModal(false);
  };

  const publishList = (row: any) => {
    switch (params.type) {
      case 'Albums':
        (async () => await publishAlbumList(row))();
        break;
      case 'Cams':
        (async () => await publishCamList(row))();
        break;
      case 'Channels':
        (async () => await publishChannelList(row))();
        break;
      case 'Radios':
        (async () => await publishRadioList(row))();
        break;
      case 'EpgTv':
        (async () => await publishChannelList(row))();
        break;
      case 'EpgRadio':
        (async () => await publishRadioList(row))();
        break;
      case 'Movies':
        (async () => await publishMovieList(row))();
        break;
      case 'Series':
        (async () => await publishSeriesList(row))();
        break;
      case 'Shorts':
        (async () => await publishShortsList(row))();
        break;
      case 'Podcasts':
        (async () => await publishPodcastList(row))();
        break;
      case 'Courses':
        (async () => await publishCoursesList(row))();
        break;
        case 'Games':
          (async () => await publishGamesList(row))();
          break;
    }
    setShowModal(false);
  };

  // const publishLists = async () => {
  //   setIsLoading(true);
  //   data.forEach((item: any) => {
  //     let response = common.PublishBundle({
  //       query_params: {
  //         type: params.type,
  //         _id: item._id,
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //       },
  //     });
  //   });
  //   refreshList();
  //   setIsLoading(false);
  // };

  const packageColumns: IReactBCTableColumn<[]>[] = [
    {
      title: t('list-name'),
      dataKey: 'name',
      width: '50%',
      cell: (row: any) => <span>{row.name}</span>,
    },
    {
      title: t('publish-date'),
      dataKey: 'publish',
      width: '50%',
      cell: (row: any) => (
        <div>
          <div style={{ alignSelf: 'center' }}>{row.publish}</div>
        </div>
      ),
    },
    {
      title: t('actions'),
      dataKey: 'action',

      cell: (row: any, index) => (
        <Col style={{ display: 'flex', flexDirection: 'row', height: 60 }}>

          <Tooltip placement="top" title={`Publish List`}>
            <Button className="cursor-pointer" onClick={() => publishList(row)} type="text">
              <FontAwesomeIcon className="action-icon" icon={faUpload} />
              <div>{t('publish')}</div>
            </Button>
          </Tooltip>
          <Tooltip placement="top" title={`List Details (Add/Remove/Order Content)`} key={'details'}>
            <a href={`${url}` + '/content/' + row.name + '/' + row._id + '/' + index}>
              <Button className="mx-2 cursor-pointer" type="text">
                <FontAwesomeIcon className="action-icon" icon={faList} />
                <div>
                  {params.type} ({row.items.length})
                </div>
              </Button>
            </a>
          </Tooltip>
        </Col>
      ),
    },
  ];

  const [showModal, setShowModal] = useState(false);
  return (
    <div>
      <div className="row mb-4">
        <div className="col-md-7 mb-4 mb-md-2 d-flex justify-content-center justify-content-md-start align-items-start">
          <span className="ml-3 ml-md-4 "></span>
        </div>
        <div className="col-md-12">
          <div className="row float-right">
            {/* <Button loading={isLoading} disabled={isLoading} className="btn btn-secondary rounded-pill btn-default float-right  height-48 mr-2" onClick={() => publishLists()}>
              {t('publish-all-lists')}
            </Button> */}
            <Button disabled={isLoading} className="btn btn-secondary rounded-pill btn-default float-right  height-48 mr-5" onClick={() => setShowModal(!showModal)}>
              {t('add-list')}
            </Button>
          </div>
        </div>
      </div>
      {showModal && (
        <ListModal
          initialData={{
            type: params.type,
            ...selectedList,
          }}
          visible={showModal}
          onSubmit={onSubmitModal}
          onCancel={() => setShowModal(!showModal)}
        />
      )}
      <ReactBCTable
        key={JSON.stringify(data)}
        tableId={'user_interfaces_tables'}
        columns={packageColumns}
        data={data}
        isLoading={isLoading}
        totalRecords={pagination.count}
        pageSize={pagination.pageSize}
        currentPage={pagination.pageNumber}
        hasChild={true}
        childComponent={(row, index) => {
          return <NestedListContent package={data[index]} deployment={deployment} clientName={clientName} type={params.type} data={row} isLoading={isLoading} onSubmit={onSubmitCategories} />;
        }}
      />
    </div>
  );
};
export default NestedLists;
