import { Button, Form, Input, Select, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { StatusCodes } from 'http-status-codes';
import { useContext, useState } from 'react';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import cmsService from '../../../../../../services/areas/content/workflows/workflows';
import { IMSNameParams } from '../../../../../dashboard';
import { ContentContext } from '../../../../../../contexts/contentContext';
import { Timezone } from '../../../../../../common/components/form';

const AddAlbum = () => {
  const history = useHistory();
  const { clientName } = useParams<IMSNameParams>();
  const [isAdding, setIsAdding] = useState(false);
  const [form] = useForm();
const { t } = useTranslation();
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -1).join('/');
  const { deployment } = useContext(ContentContext);
  const [selectedContentType, setSelectedContentType] = useState('');
  const [selectedWorkflowType, setSelectedWorkflowType] = useState('');

  const handleSubmit = async (values: any) => {
    setIsAdding(true);
    values = {
      name: values.name,
      schedule: values.schedule,
      api: values.api,
      path: values.path,
      content: selectedContentType,
      workflow_type: selectedWorkflowType, //hour, day, week, month
      workflow_value: values.workflow_value, //1, 4
      workflow_timezone: values.timezone,
      mappings: [],
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    };

    let response = await cmsService.Add_Workflow(values as any);
    if (response.status_code === StatusCodes.OK) {
      notification.success({
        message: t('added-successfully'),
      });
      var resp = response.data as any;
      history.push(redirectUrl + '/details/' + resp._id);
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
    setIsAdding(false);
  };

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-12 mb-2">
          <Link to={`${redirectUrl}/`}>{t('back-to-workflow-list')}</Link>
          <div className="row-md-12" style={{ borderBottom: '1px solid #cfcfcf' }}></div>
          <h2 className="page-title mt-4">{t('add-a-workflow')}</h2>
          <p className="h6 font-weight-normal line-height-26">{t('setup-a-workflow-to-automate-content-importing')}</p>
        </div>
      </div>
      <Form
        form={form}
        onFinish={(values: any) => {
          handleSubmit(values);
        }}
        onFinishFailed={(errorInfo) => {
          
        }}
      >
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <h4 className="text-primary mt-5 mb-3">{t('workflow-0')}</h4>
            <div className="card-bg-light pl-5 pt-3">
              <div className="row">
                <label className="col-md-3 col-form-label">{t('name')}</label>
                <Form.Item
                  className="col-md-6"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t('name-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('workflow-name')} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-3 col-form-label">{t('api-url')}</label>
                <Form.Item
                  className="col-md-6"
                  extra={t('the-api-should-return-data-in-json-format')}
                  name="api"
                  rules={[
                    {
                      required: true,
                      message: t('api-url-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder="https://yourcontentapi.com" />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-3 col-form-label">{t('content-type')}</label>
                <Form.Item extra={t('set-the-content-type-for-your-workflow-1')} className="col-md-6" name={'content'}>
                  <Select className="default-select" onSelect={setSelectedContentType} defaultActiveFirstOption={true}>
                    <Select.Option value={"Albums"} key={9}>
                      {t('albums-2')}
                    </Select.Option>
                    <Select.Option value={"Cams"} key={3}>
                      {t('cams')}
                    </Select.Option>
                    <Select.Option value={"Channels"} key={1}>
                      {t('channels')}
                    </Select.Option>
                    <Select.Option value={"Courses"} key={7}>
                      {t('courses-4')}
                    </Select.Option>
                    <Select.Option value={"Movies"} key={4}>
                      {t('movies-4')}
                    </Select.Option>
                    <Select.Option value={"Shorts"} key={4}>
                      {t('shorts-4')}
                    </Select.Option>
                    <Select.Option value={"Podcasts"} key={10}>
                      {t('podcasts-4')}
                    </Select.Option>
                    <Select.Option value={"Radio"} key={2}>
                      {t('radio')}
                    </Select.Option>
                    <Select.Option value={"Series"} key={5}>
                      {t('series-4')}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-3 col-form-label">{t('workflow-scheduler')}</label>
                <Form.Item extra={t('workflow-type')} className="col-md-6" name={'workflow_type'}>
                  <Select className="default-select" onSelect={setSelectedWorkflowType} defaultActiveFirstOption={true}>
                    <Select.Option value={"Day"} key={'Day'}>
                      {t('every-day-of-the-week')}
                    </Select.Option>
                    <Select.Option value={"Week"} key={'Week'}>
                      {t('every-week')}
                    </Select.Option>
                    <Select.Option value={"Month"} key={'Month'}>
                      {t('each-month')}
                    </Select.Option>
                  </Select>
                </Form.Item>
                {selectedWorkflowType == "Week" && (
                  <Form.Item extra={t('which-day-in-the-week-should-the-workflow-run')} className="col-md-6" name={'workflow_value'}>
                    <Select className="default-select" onSelect={setSelectedWorkflowType} defaultActiveFirstOption={true}>
                      <Select.Option value={'1'} key={'Sunday'}>
                        {t('sunday')}
                      </Select.Option>
                      <Select.Option value={'2'} key={'Monday'}>
                        {t('monday')}
                      </Select.Option>
                      <Select.Option value={'3'} key={'Tuesday'}>
                        {t('tuesday')}
                      </Select.Option>
                      <Select.Option value={'4'} key={'Wednesday'}>
                        {t('wednesday')}
                      </Select.Option>
                      <Select.Option value={'5'} key={'Thursday'}>
                        {t('thursday')}
                      </Select.Option>
                      <Select.Option value={'6'} key={'Friday'}>
                        {t('friday')}
                      </Select.Option>
                      <Select.Option value={'7'} key={'Saturday'}>
                        {t('saturday')}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                )}
                {selectedWorkflowType =="Month" && (
                  <Form.Item
                    extra={t('which-day-in-the-month-should-the-workflow-run')}
                    className="col-md-6"
                    name="workflow_value"
                    rules={[
                      {
                        required: true,
                        message: t('day-is-required'),
                      },
                    ]}
                  >
                    <Input type={'number'} min={1} max={31} className="form-control bg-white font-size-14 text-default" placeholder="1-31" />
                  </Form.Item>
                )}
              </div>
              <div className="row">
                <label className="col-md-3 col-form-label">{t('workflow-timezone')}</label>
                <div className="col-md-6">
                  <Timezone name={['timezone']} extra={t('select-the-timezone-you-want-to-use-to-run-this-workflow')} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-right">
          <Button htmlType="submit" disabled={isAdding} loading={isAdding} className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
            {t('add-workflow-and-start-mapping')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddAlbum;
