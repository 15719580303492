import { useContext, useState } from 'react';
import { Button, Form, Input, InputNumber, Table, Typography } from 'antd';
import { IMSNameParams } from '../../../dashboard';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { AppBuilderContext } from '../../../../contexts/appbuilderContext';
import axios from 'axios';
import translationService from '../../../../services/areas/appbuilder/applications/translations/index';
import TranslationOverrideModel from '../../../../common/components/Content/Translations/TranslationOverrideModel';
import { StatusCodes } from 'http-status-codes';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

interface Item {
  id: string;
  key: string;
  value: string;
}
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text';
  record: Item;
  index: number;
  children: React.ReactNode;
}



const Albums = () => {
  const [translations, setTranslations] = useState([] as any);
  const { _id } = useParams<IMSNameParams>();
  const { url } = useRouteMatch();
  const { t } = useTranslation();
  const redirectUrl = url.split('/').slice(0, -2).join('/');
  const { deployment } = useContext(AppBuilderContext);
  const { clientName } = useParams<IMSNameParams>();
  const [loading, setLoading] = useState(true);
  const [showOverride, setShowOverride] = useState(false);
  const [overrideText, setOverrideText] = useState('');
  const [overrides, setOverrides] = useState([] as any);
  const [form] = Form.useForm();
  const history = useHistory();

  const EditableCell: React.FC<EditableCellProps> = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: t('please-input-title', { title: title }),
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const LanguagesColumns = [
    {
      title: t('original'),
      dataIndex: 'key',
      width: '50%',
    },
    {
      title: t('overide'),
      dataIndex: 'value',
      width: '50%',
    },
    {
      title: t('action-0'),
      dataIndex: 'value',
      width: 100,
      render: (row: any, record: any, index: any) => {
        return (
          <>
            {record.overridden == false && index != 0 && (
              <Button
                className="mx-2 cursor-pointer"
                onClick={() => {
                  setOverrideText(record.key);
                  setShowOverride(true);
                }}
                type="text"
              >
                {t('add-override')}
              </Button>
            )}
          </>
        );
      },
    },
  ];

  const OverideColumns = [
    {
      title: t('original-0'),
      dataIndex: 'original',
      width: '50%',
    },
    {
      title: t('override'),
      dataIndex: 'overide',
      width: '50%',
    },
    {
      title: t('overridden'),
      dataIndex: 'overridden',
      width: 100,
      render: (row: any, record: any, index: any) => {
        return (
          <>
            <div className="row">
              <Button
                onClick={() => {
                  deleteOveride(record);
                }}
                className="mx-2 cursor-pointer"
                type="text"
              >
                {t('delete-override')}
              </Button>
            </div>
          </>
        );
      },
    },
  ];

  const deleteOveride = async (record) => {
    setLoading(true);
    setShowOverride(false);
    await translationService
      .Delete_Translations_Overide({
        query_params: {
          instance: clientName,
          cms: deployment?.name ?? '',
        },
        _id: record._id,
      })
      .then((response) => {
        history.go(0);
      });
  };

  useEffectOnce(() => {
    getOverrides();
  });

  const getLanguageFileFromServer = async () => {
    setLoading(true);
    await axios.get('https://cloudtv.akamaized.net/' + clientName + '/' + deployment?.name + '/appbuilder/translations/' + _id + '.json?t=' + moment().unix()).then((response: any) => {
      var translations_ = [] as any;
      var base = response.data.language;
      Object.keys(base).forEach(function (key, index) {
        translations_.push({
          id: index,
          key: key,
          value: base[key],
          overridden: overrides.find((o) => o.language == _id && o.original == base[key]) != undefined ? true : false,
        });
      });
      translations_.sort((a, b) => a.key.localeCompare(b.key));
      setTranslations(translations_);
      setLoading(false);
    });
  };



  const safeOverride = async (values: any) => {
    setLoading(true);
    setShowOverride(false);
    await translationService
      .Add_Translations_Overide({
        language: _id ?? '',
        original: overrideText,
        overide: values.overide,
        query_params: {
          instance: clientName,
          cms: deployment?.name ?? '',
        },
      })
      .then((response) => {
        history.go(0);
      });
  };

  const getOverrides = async () => {
    setLoading(true);
    let response = await translationService.List_Translations_Overide({
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
      },
      pageNumber: 0,
      pageSize: 10000,
    });
    if (response && response.status_code === StatusCodes.OK) {
      var resp = response.data as any;
      setLoading(false);
      setOverrides(resp.data);
      getLanguageFileFromServer();
    }
  };

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-12 mb-2">
          <Link to={`${redirectUrl}`}>{t('back-to-translations')}</Link>
          <div className="row-md-12" style={{ borderBottom: '1px solid #cfcfcf' }}></div>
          <h2 className="page-title mt-5">{t('translations-for')} {_id} </h2>
          <p className="h6 font-weight-normal line-height-26">{t('manage-your-translations-click-edit-to-make-changes-your-changes-are-auto-saved-and-ready-to-be-used-in-the-apps-reload-your-app')}</p>
        </div>
        <div className="col-md-6"></div>
      </div>
      <div className="row">
        <div className="col-12 mt-5 card-bg-light p-5">
          <TranslationOverrideModel visible={showOverride} onSubmit={safeOverride} onCancel={() => setShowOverride(false)} initialData={overrideText} />
          <Form form={form} component={false}>
            <div className="col-12 card-bg-light p-5">
              <h4 className="page-title">{t('overides')}</h4>
              <Table
                rowKey={(overide) => overide._id}
                loading={loading}
                columns={OverideColumns}
                dataSource={overrides}
                key={JSON.stringify(overrides)}
                pagination={{
                  position: ['bottomLeft'],
                  pageSize: 1000,
                  hideOnSinglePage: true,
                }}
              />
            </div>
            <div className="col-12 card-bg-light p-5  mt-2">
              <h4 className="page-title">{t('translations')}</h4>
              <p>{t('to-fix-incorrect-translations-use-the-override-option-once-you-have-finished-click-reload-to-return-to-the-translation-overview-page')}</p>
              {!loading && (
                <Table
                  rowKey={(translations) => translations._id}
                  loading={loading}
                  columns={LanguagesColumns}
                  dataSource={translations}
                  key={JSON.stringify(translations)}
                  pagination={{
                    position: ['bottomLeft'],
                    pageSize: 1000,
                    hideOnSinglePage: true,
                  }}
                />
              )}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Albums;
