import { faBuilding, faBuildings, faTelevision, faUsers } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Layout, Menu } from 'antd';
import { useContext } from 'react';
import { Link, Switch, Route, Redirect } from 'react-router-dom';
import MWare_Logo_Blue from '../../assets/images/logo-blue.png';
import HomeDashboardHeader from '../../common/components/HomeDashboardHeader';
import PageNotFound from '../../common/components/PageNotFound';
import PrivateRoute from '../../common/routes/PrivateRoute';
import AddNewClient from './pages/clients/add';
import DetailsClient from './pages/clients/details';
import { Users } from '../users';
import AllClients from './pages/clients';
import { AuthContext } from '../../context_api/AuthContext';
import { TVMSRoles } from '../../constants/TVMSRoles';
import { isInRoles, VisibleForRole } from '../../common/components/VisibleForRole';

const HomeDashboard = () => {
  const { state: AuthState } = useContext(AuthContext);

  if (AuthState.user!.clients![0] != undefined && !isInRoles(AuthState.user?.role, [TVMSRoles.SuperAdmin, TVMSRoles.Admin]) || (isInRoles(AuthState.user?.role, [TVMSRoles.Admin]) && AuthState.user?.clients?.length === 1)) {
    return <Redirect to={`/client/${AuthState.user!.clients![0]?.name}/${AuthState.user!.clients![0]?._id}`} />;
  }

  return (
    <Layout>
      <div className="row">
        <div className="col-md-2 bg-white shadow">
          <div className="m-4 pb-5 pt-3" style={{ borderBottom: '1px solid #ebebeb' }}>
            <Link to={`/`}>
              <img src={MWare_Logo_Blue} title="Mware" alt="Mware" style={{ width: 150, height: 37.5 }} />
            </Link>
          </div>
          <div className="mt-2 ml-3" style={{ color: '#ebebeb', fontSize: 16 }}></div>
          <VisibleForRole roles={[TVMSRoles.SuperAdmin, TVMSRoles.Admin]}>
            <Menu mode="inline" className="mt-2 ml-1" style={{ height: '100vh', borderRight: 0 }} defaultSelectedKeys={['1']} defaultOpenKeys={['1']}>
              <Menu.Item className="height-60 d-flex align-items-center" key="clients">
                <Link to="/">
                  <div className="row">
                    <div className="col-md-2" style={{ display: 'flex', alignItems: 'center' }}>
                      <FontAwesomeIcon color={'#999'} size={'xl'} icon={faBuildings} />
                    </div>
                    <div className="col-md-10" style={{ fontSize: 14, fontWeight: 100, color: '#999' }}>
                      Client Overview
                    </div>
                  </div>
                </Link>
              </Menu.Item>
              <VisibleForRole roles={[TVMSRoles.SuperAdmin]}>
                <Menu.Item className="height-60 d-flex align-items-center" key="addclients">
                  <Link to="/add-new-client">
                    <div className="row">
                      <div className="col-md-2" style={{ display: 'flex', alignItems: 'center' }}>
                        <FontAwesomeIcon color={'#999'} size={'xl'} icon={faBuilding} />
                      </div>
                      <div className="col-md-10" style={{ fontSize: 14, fontWeight: 100, color: '#999' }}>
                        Add Client
                      </div>
                    </div>
                  </Link>
                </Menu.Item>
              </VisibleForRole>
  
              <VisibleForRole roles={[TVMSRoles.SuperAdmin]}>
                <Menu.Item className="height-60 d-flex align-items-center" key="users">
                  <Link to="/users">
                    <div className="row">
                      <div className="col-md-2" style={{ display: 'flex', alignItems: 'center' }}>
                        <FontAwesomeIcon color={'#999'} size={'xl'} icon={faUsers} />
                      </div>
                      <div className="col-md-10" style={{ fontSize: 14, fontWeight: 100, color: '#999' }}>
                        TVMS Users
                      </div>
                    </div>
                  </Link>
                </Menu.Item>
              </VisibleForRole>
              {/* <Menu.Item className="height-60 d-flex align-items-center" key="settings">
                <div className="row">
                  <div className="col-md-2" style={{ display: 'flex', alignItems: 'center' }}>
                    <FontAwesomeIcon color={'#999'} size={'xl'} icon={faCog} />
                  </div>
                  <div className="col-md-10" style={{ fontSize: 14, fontWeight: 100, color: '#999' }}>
                    Settings
                  </div>
                </div>
              </Menu.Item> */}
              {/* <Menu.Item className="height-60 d-flex align-items-center" key="billing">
                <div className="row">
                  <div className="col-md-2" style={{ display: 'flex', alignItems: 'center' }}>
                    <FontAwesomeIcon color={'#999'} size={'xl'} icon={faCreditCard} />
                  </div>
                  <div className="col-md-10">
                    <Badge count={'!'} size={'small'}>
                      <div style={{ fontSize: 14, fontWeight: 100, color: '#999' }}>
                        Billing
                      </div>
                    </Badge>
                  </div>
                </div>
              </Menu.Item> */}
            </Menu>
          </VisibleForRole>
        </div>
        <div className="col-md-10">
          <HomeDashboardHeader />
          <Layout>
            <Switch>
              <PrivateRoute path={`/add-new-client`} allowedRoles={[TVMSRoles.SuperAdmin]} component={AddNewClient} />
              <Route path={`/details/:_id`} component={DetailsClient} />
              <Route path={`/users`} component={Users} />
              <Route path={`/clients`} component={AllClients} />
              <Route path={`/`} component={() => <Redirect to={`clients`} />} />
              <Route path={`/*`} component={() => <PageNotFound path="" />} />
            </Switch>
          </Layout>
        </div>
      </div>
    </Layout>
  );
};

export default HomeDashboard;
