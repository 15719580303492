import { Button, Form, Input, Modal } from 'antd';
import Params from '../../../../../../common/components/Content/Params/Params';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { IMSNameParams } from '../../../../../dashboard';
import { useTranslation } from 'react-i18next';
type AddModalProps = {
  visible: boolean;
  onSubmit: (params: any) => void;
  onCancel: () => void;
  isLoading: boolean;
};
const AddModal = (props: AddModalProps) => {
  const { visible, onSubmit, onCancel, isLoading } = props;
  const { clientName, deployment } = useParams<IMSNameParams & { deployment: string }>();
  const { t } = useTranslation();
  const handleSubmit = (e: any) => {
    onSubmit(e);
  };

  return (
    <Modal visible={visible} style={{  borderRadius:5}} width={960} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="text-primary mb-3">{t('add-parameters')}</h4>
            <Form onFinish={handleSubmit} onFinishFailed={(errorInfo) => {}}>
              <Params />
              <div className="col-md-12 p-0 pt-4">
                <Button loading={isLoading} disabled={isLoading} htmlType="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                  {t('add-parameters-0')}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddModal;
