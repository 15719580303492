import { useContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { IMSNameParams } from '../../../dashboard';
import { Service } from '../../../../interfaces/forms/areas/billing/services/service';
import { Button, Col, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMemo } from '@fortawesome/pro-regular-svg-icons';
import * as TransactionService from '../../../../services/areas/billing/transactionService';
import { ContainerWrapper } from '../../../../common/components/containerWrapper';
import { PageTitle } from '../../../../common/components/pageTitle';
import ReactBCTable from '../../../../common/components/ReactBCTable';
import { BillingContext } from '../../../../contexts/billingContext';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const TransactionList = () => {
  const { url } = useRouteMatch();
  const { clientName } = useParams<IMSNameParams>();
  const { deployment, service } = useContext(BillingContext);
  const history = useHistory();
  const [pagination, setPagination] = useState<{ limit: number; page: number }>({ limit: 50, page: 1 });
  const {t} = useTranslation();
  
  const query = useQuery(
    ['transactions', pagination.page, pagination.limit],
    async () => {
      return (
        await TransactionService.list({
          params: {
            cms: deployment?.name || '',
            crm: service?.name || '',
            instance: clientName,
            page: pagination.page,
            limit: pagination.limit,
          },
        })
      ).data;
    },
    { retry: 3 }
  );

  const { isLoading, isError, data } = query;

  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-md-6 mb-2">
          <PageTitle title={t('transactions')} subtitle={t('manage-transaction-list-for-audit-or-check')} />
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-4">
          {isError && <div>{t('something-went-wrong-0')}</div>}
          {!isError && data && (
            <ReactBCTable
              isLoading={isLoading}
              totalRecords={data.totalDocs}
              pageSize={data.limit}
              tableId={'billing_services_table'}
              columns={[
                {
                  title: t('date'),
                  dataKey: 'date_unix',
                  width: '25%',
                  cell: (row) => {
                    return <div>{moment.unix(row.date_unix).format('llll')}</div>;
                  },
                },
                {
                  title: t('type'),
                  dataKey: 'transaction_type',
                  width: '5%',
                  cell: (row) => {
                    return <div>{row.transaction_type}</div>;
                  },
                },
                {
                  title: t('source'),
                  dataKey: 'source',
                  width: '5%',
                  cell: (row) => {
                    return <div>{row.source}</div>;
                  },
                },
                {
                  title: t('currency'),
                  dataKey: 'currency',
                  width: '5%',
                  cell: (row) => {
                    return <div>{row.currency}</div>;
                  },
                },
                {
                  title: t('amount-inc-vat'),
                  dataKey: 'amount_inc_vat',
                  width: '15%',
                  cell: (row) => {
                    return <div>{row.amount_inc_vat?.toFixed(2)}</div>;
                  },
                },
                {
                  title: t('amount-vat-0'),
                  dataKey: 'amount_vat',
                  width: '15%',
                  cell: (row) => {
                    return <div>{row.amount_vat?.toFixed(2)}</div>;
                  },
                },
                {
                  title: t('service'),
                  dataKey: 'service',
                  width: '15%',
                  cell: (row) => {
                    return <div>{row.service}</div>;
                  },
                },
                {
                  title: t('actions'),
                  dataKey: 'actions',
                  cell: (row) => {
                    return (
                      <Col style={{ display: 'flex', flexDirection: 'row', height: 60 }}>
                        <Tooltip placement="top" title={t('details')}>
                          <Button
                            type="text"
                            className="mx-2"
                            onClick={() => {
                              history.push(`${url}/${row._id}`);
                            }}
                          >
                            <FontAwesomeIcon className="action-icon" icon={faMemo} />
                            <div>{t('open-details')}</div>
                          </Button>
                        </Tooltip>
                      </Col>
                    );
                  },
                },
              ]}
              data={data.docs || []}
              currentPage={data.page}
              onChange={(page) => {
                setPagination({ ...pagination, page });
              }}
            />
          )}
        </div>
      </div>
    </ContainerWrapper>
  );
};
