import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CustomersContext } from '../../../../../contexts/customersContext';
import { message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const RecordingTable = ({ initialData }) => {
  const { clientName } = useParams<{ clientName: string }>();
  const { deployment, service } = useContext(CustomersContext);
  const [recordings, setRecordings] = useState([] as any);
  const [messageApi, contextHolder] = message.useMessage();
  const [times, setTimestamp] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        fetch(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/api/v1/customers/recordings?client=${clientName}&deployment=${deployment?.name}&service=${service?.name}&customer=${initialData?.username}`)
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
            throw new Error('Something went wrong');
          })
          .then((responseJson) => {
            setRecordings(responseJson);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {}
    };

    fetchData();
  }, [clientName, deployment]);

  const deleteRecording = async (index, index_) => {
    recordings[index].recordings.splice(index_, 1);
    setRecordings(recordings);
    setTimestamp(moment().unix().toLocaleString());
    updateJson(recordings);
  };
  const toAlphaNumeric = (input) => {
    if (input == '' || input == undefined || input == null) {
      return '';
    }
    input = input.toString().replace(/\s/g, '');
    return input.toString().replace(/[^A-Za-z0-9]/g, '');
  };

  const updateJson = (json) => {
    let path = clientName + '/' + deployment?.name + '/' + service?.name + '/users/' + toAlphaNumeric(initialData?.username).split('').join('/');
    fetch('https://jsonstorage.io/upload?path=' + encodeURIComponent(path) + '&name=recordings.json', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    }).catch((error) => {});
  };

  return (
    <>
      {contextHolder}
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column', border: '1px solid #d1d1d1', borderRadius: 5, padding: 10 }}>
        {recordings.length == 0 && <div style={{ color: '#999' }}>{t('there-are-no-recordings')}</div>}
        {recordings.map((profile: any, index) => {
          return (
            <>
              <div style={{ backgroundColor: '#d1d1d1', padding: 10, borderRadius: 5, color: '#555', marginBottom: 5 }}>
                <b>Profile: {profile.name}</b>
              </div>
              <div
                key={index}
                style={{
                  borderBottom: index < profile.recordings.length - 1 ? '1px solid #d1d1d1' : 'none',
                  display: 'flex',
                  width: '100%',
                  borderRadius: 5,
                  flexDirection: 'column',
                }}
              >
                {profile.recordings != undefined && profile.recordings.length > 0 && (
                  <div style={{ margin: 5, marginTop: 0, marginLeft: 20, padding: 10, backgroundColor: '#f5f5f5', borderRadius: 5 }}>
                    {profile.recordings.map((recording: any, index_) => {
                      return (
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div style={{ display: 'flex', alignItems: 'center', padding: 20, width: '100%', flexDirection: 'row', borderBottom: index_ < profile.recordings.length - 1 ? '2px solid #d1d1d1' : 'none' }}>
                            <div style={{ marginRight: 35 }}>
                              <img src={recording.channel.images.square} alt="" style={{ width: 75, height: 75, border: '2px solid #d1d1d1', borderRadius: 5 }}></img>
                            </div>
                            <div>
                              <b>
                                {recording.channel.number}. {recording.channel.name}
                              </b>
                              <div style={{ marginTop: 5 }}>{recording.program.n}</div>
                              <div style={{ marginBottom: 5 }}>
                                {moment.unix(recording.program.s).format('lll')} - {moment.unix(recording.program.e).format('lll')}
                              </div>
                              <div style={{ fontSize: 12 }}>{recording.program.d}</div>
                            </div>
                          </div>
                          <div style={{ display: 'flex', padding: 20, justifyContent: 'center', alignItems: 'center', borderBottom: index_ < profile.recordings.length - 1 ? '2px solid #d1d1d1' : 'none' }}>
                            <div onClick={() => deleteRecording(index, index_)}>
                              <FontAwesomeIcon color={'#999'} size={'2x'} icon={faTrash} />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default RecordingTable;
