import { Form, notification } from 'antd';
import { useContext, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { BackButton } from '../../../../common/components/BackButton';
import { ContainerWrapper } from '../../../../common/components/containerWrapper';
import { Button } from '../../../../common/components/form';
import { PageTitle } from '../../../../common/components/pageTitle';
import { ErrorModal } from '../../../../common/components/ErrorModal/ErrorModal';
import { useTranslation } from 'react-i18next';
import { StatusCodes } from 'http-status-codes';
import chartDesignService from '../../../../services/areas/reporting/chartdesigns/chartDesignService';
import { ChartDesignFormFields } from './shared/chartdesignform';
import { IMSNameParams } from '../../../dashboard';
import { MapFormValuesToDomainValues } from './shared/mapper';
import { CommonContext } from '../../../../contexts/commonContext';
import { ChartDesignAvailability } from '../_ChartDesignConstants';
import dashboardService from '../../../../services/areas/reporting/dashboards/dashboardService';

const AddChartDesign = () => {
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -1).join('/');
  const history = useHistory();
  const { clientName, deployment, service } = useParams<IMSNameParams>();
  const { countries } = useContext(CommonContext);
  const {t} = useTranslation();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorModalData, setErrorModalData] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values: any) => {
    setIsLoading(true);
    values = {
      ...values,
      query_params: {
        instance: clientName,
        cms: deployment ?? '',
        crm: service,
      },
    };
    let chartDesignResponse = await chartDesignService.Add_ChartDesigns(values as any);
    if (chartDesignResponse.status_code === StatusCodes.OK) {

      const dashboardResponse = await dashboardService.Generate({
        query_params: {
          instance: clientName,
          cms: deployment,
          crm: service,
          area: "reporting",
        },
      });
  
      if (dashboardResponse.status_code !== StatusCodes.OK) {
        console.log(JSON.stringify(dashboardResponse));
      }
  
      notification.success({
        message: t('added-successfully'),
      });
      history.push(redirectUrl);
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
    setIsLoading(false);
  };

  const [form] = Form.useForm();

  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-12">
          <BackButton text={redirectUrl.indexOf('designer') > 0 ? t('chart-design-back-to-designer') : t('chart-design-back-to-dashboard')} url={redirectUrl} />
        </div>
        <div className="col-md-6 mb-2">
          <PageTitle title={t('chart-design-add-chart')} subtitle={t('chart-design-add-a-new-chart')} />
        </div>
      </div>
      <ErrorModal
        isOpenModal={openErrorModal}
        data={errorModalData}
        setIsOpen={(isOpen) => {
          setOpenErrorModal(isOpen);
        }}
      />
      <div className="row">
        <div className="col-12">
          <Form
            {...{ form }}
            initialValues={{
              dashboard: "reporting",
            }}
            onFinish={(values) => {

              const domainChartDesign = MapFormValuesToDomainValues(values);

              handleSubmit(domainChartDesign);
            }}
            onFinishFailed={(errorInfo) => {
              setErrorModalData(errorInfo?.errorFields);
              setOpenErrorModal(true);
            }}
            layout="vertical"
          >
            {countries &&
            <ChartDesignFormFields availableChartDesignEntities={ChartDesignAvailability.getEntities(countries)} />
            }

            <div className="text-right">
              <Button htmlType="submit" loading={isLoading} disabled={isLoading} className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
                {t('chart-design-add-chart')}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </ContainerWrapper>
  );
};

export default AddChartDesign;
