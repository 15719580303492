import { Button, Form, notification } from 'antd';

import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { StatusCodes } from 'http-status-codes';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import cmsService from '../../../../../../../services/areas/content/channels/channels';
import { IMSNameParams } from '../../../../../../dashboard';
import InteractiveTV from '../../../../../../../common/components/Content/InteractiveTV/InteractiveTV';
import ChannelStreams from '../../../../../../../common/components/Content/ChannelStreams/ChannelStreams';
import { ContentContext } from '../../../../../../../contexts/contentContext';
import transcoderService from '../../../../../../../services/areas/content/transcoders/transcoders/index';
import { useEffectOnce } from 'react-use';
import paramsService from '../../../../../../../services/areas/content/params/index';
import channels from '../../../../../../../services/areas/content/channels/channels';

const AddNewMusicAlbum = (data: any) => {
  const params = useParams<IMSNameParams & { deployment: string }>();
  const [isAdding, setIsAdding] = useState(false);
  const [security, setSecurity] = useState([] as any);
  const [interactivetv, setInteractivetv] = useState([] as any);
  const [form] = useForm();
  const { t } = useTranslation();
  const { clientName, _id } = useParams<IMSNameParams>();
  const { deployment } = useContext(ContentContext);
  const [transcoders, setTranscoders] = useState([] as any);
  const [params_, setParams_] = useState([] as any);

  useEffect(() => {
    getTranscoders();
    setInteractivetv(data.data.interactivetv);
    setSecurity(data.data.security);
    form.setFieldsValue(data.data);
  }, [data]);

  const getParams = async () => {
    let response = await paramsService.List_Params({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 10000,
    });
    if (response && response.status_code === StatusCodes.OK) {
      if (response.data != undefined && response.data.data != undefined) {
        setParams_(response.data.data);
      }
    }
  };

  useEffectOnce(() => {
    getParams();
  });

  const handleSubmit = async (values: any) => {
    setIsAdding(true);
    if (data.data.payperview != undefined) {
      if (data.data.payperview.currencies != undefined) {
        if (data.data.payperview.currencies.length > 0) {
          if (data.data.payperview.currencies[0].price != undefined) {
            var curs = [] as any;
            deployment?.currencies.forEach((cur, index) => {
              curs.push({
                iso3: cur.isoCurrency,
                credits_rented: 0,
                credits_purchased: 0,
                key: index,
              });
            });
            data.data.payperview = {
              enable: data.data.payperview.enable,
              rule: '',
              currencies: curs,
            };
          } else {
            var curs = [] as any;
            deployment?.currencies.forEach((cur, index) => {
              curs.push({
                iso3: cur.isoCurrency,
                credits_rented: 0,
                credits_purchased: 0,
                key: index,
              });
            });
            data.data.payperview = {
              enable: data.data.payperview.enable,
              rule: '',
              currencies: curs,
            };
          }
        }
      }
    }
    var transcoder = undefined;

    if (interactivetv.transcoder != undefined) {
      var test = transcoders.find((t) => t._id == interactivetv.transcoder);
      if (test != undefined) {
        transcoder = test;
      }
    }

    var paramsIn = undefined;

    var par = params_.find((p) => p._id == values?.streams?.params?._id);
    if (par != undefined) {
      paramsIn = par;
    } else {
      par = params_.find((p) => p._id == values?.streams?.params?._id?.value);
      if (par != undefined) {
        paramsIn = par;
      }
    }

    values = {
      _id: _id,
      ...data.data,
      interactivetv: {
        catchuptv_enabled: interactivetv.catchuptv_enabled,
        recordings_enabled: interactivetv.recordings_enabled,
        pausetv_enabled: interactivetv.pausetv_enabled,
        alternative_url: values.interactivetv.alternative_url,
        catchuptv_days: values.interactivetv.catchuptv_days,
        transcoder: transcoder,
      },
      streams: {
        channels: values?.streams?.channels,
        params: paramsIn,
      },

      query_params: {
        instance: clientName,
        cms: params.deployment ?? '',
      },
    };
    let response = await cmsService.Update_Channel(values as any);
    if (response.status_code === StatusCodes.OK) {
      notification.success({
        message: t('updated-successfully'),
      });
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
    setIsAdding(false);
  };

  const getTranscoders = async () => {
    let response = await transcoderService.List_Transcoders({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 10000,
    });
    if (response && response.status_code === StatusCodes.OK) {
      setTranscoders(response.data.data);
    }
  };

  return (
    <div className="container-fluid ">
      <Form
        form={form}
        onFinish={(values: any) => {
          handleSubmit(values);
        }}
        onFinishFailed={(errorInfo) => {}}
      >
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <InteractiveTV data={interactivetv} setInteractiveTV={setInteractivetv} />
            <ChannelStreams params={params_} />
          </div>
        </div>
        <div className="text-right">
          <Button htmlType="submit" disabled={isAdding} loading={isAdding} className="mt-4 mb-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
            {t('update-channel')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddNewMusicAlbum;
