import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { baseOptions } from './BaseOptions';

export const LineChart = ({ options = {}, series, horizontal = false, categories = [] }: { options?: any; series: ApexAxisChartSeries; horizontal?: boolean; categories?: string[] }) => {
  const chartOptions: ApexOptions = {
    ...baseOptions,
    ...options,

    grid: {
      show: true,
    },
    plotOptions: {
      ...options.plotOptions,
      bar: {
        horizontal: true,
        columnWidth: '70%',
        distributed: true,
        barHeight: '10px',
        borderRadius: 5,
      },
    },
    stroke: {
      width: [5, 5, 4],
      curve: 'straight',
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      gradient: {
        enabled: true,
        opacityFrom: 0.8,
        opacityTo: 0,
      },
    },
    markers: {
      size: 5,
      colors: ['#919fec'],
      strokeColor: '#485fe0',
      strokeWidth: 3,
    },
    xaxis: {
      categories: categories,
    },
    legend: {
      show: false,
      markers: {
        radius: 50,
      },
    },
  };

  return <ReactApexChart options={chartOptions} series={series} type="area" height="400" />;
};
