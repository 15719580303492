import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StringKeyValue } from "@mwaretv/database/build/backend/util/queryGenerator";

export const PlainValueChart = ({ data, icon }: { data: StringKeyValue[], icon?: IconDefinition }) => {

  const fontSize = data?.length === 3 ? '1rem' : data?.length === 2 ? '1.5rem' : '3rem';

  return (
    <div style={{ display: 'flex', marginBottom: '10px', marginLeft: '10px', fontSize: fontSize }}>
      <div>
        {data?.map(data => {
          return (
            <>
              <span style={{ lineHeight: "normal" }}>{data.key}: {data.value != null ? data.value.toString() : ""}</span><br />
            </>
          );
        })}
      </div>
      {icon &&
      <div style={{ position: 'absolute', marginRight: '10px', right: 20, opacity: 0.5, fontSize: '5rem' }}>
        <FontAwesomeIcon icon={icon} color={'#505972'}></FontAwesomeIcon>
      </div>
      }
    </div>
  );

};
