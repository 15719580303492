import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Form, Table, Tooltip, Button, notification, Col } from 'antd';
import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { ContainerWrapper } from '../../../../../common/components/containerWrapper';
import { CardForm, FormHeadingWithAddNewForm, Input, Item, InputNumber, Select, FormHeading } from '../../../../../common/components/form';
import { PageTitle } from '../../../../../common/components/pageTitle';
import { BillingContext } from '../../../../../contexts/billingContext';
import { TaxRate, Rate } from '../../../../../interfaces/forms/areas/billing/taxRate';
import * as TaxService from '../../../../../services/areas/billing/taxService';
import { Spin } from '../../../../../common/components/spinner';
import { useTranslation } from 'react-i18next';
import { CommonContext } from '../../../../../contexts/commonContext';

const Details = () => {
  const { id, clientName } = useParams<{ id: string; clientName: string }>();
  const { deployment, service } = React.useContext(BillingContext);
  const {t} = useTranslation();
  const { isLoading, isError, data } = useQuery(['taxes', id], async () => {
    return (
      await TaxService.get({
        params: {
          cms: deployment?.name || '',
          crm: service?.name || '',
          instance: clientName,
        },
        id,
      })
    ).data;
  });

  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-md-6 mb-2">
          <PageTitle title={data ? t('update-data-name-0', { dataName: data.name }) : t('update-vat-rate')} subtitle={t('set-rates-for-different-countries-states-and-zip-postalcodes')} />
        </div>
      </div>
      {isError && <span>{t('something-went-wrong')}</span>}
      {isLoading && <Spin />}
      {!isLoading && data && <TaxForm {...{ data }} />}
    </ContainerWrapper>
  );
};

const TaxForm: React.FC<{ data: TaxRate }> = ({ data }) => {
  const [form] = Form.useForm<TaxRate>();
  const [rates, setRates] = React.useState<Rate[]>(data.rates);
  const { id, clientName } = useParams<{ id: string; clientName: string }>();
  const { deployment, service } = React.useContext(BillingContext);
  const {t} = useTranslation();
  const { mutate, isLoading } = useMutation(
    (values: TaxRate) => {
      return TaxService.put({ params: { instance: clientName, cms: deployment?.name || '', crm: service?.name || '' }, data: { ...values, rates }, id });
    },
    {
      onSuccess: (data) => {
        notification.success({ message: t('vat-rate-updated') });
      },
      onError: () => {
        notification.error({ message: t('something-went-wrong-updating-the-vat-rate') });
      },
    }
  );

  return (
    <CardForm
      {...{ form }}
      initialValues={{ ...data, ...{ rates } }}
      disabled={isLoading}
      onFinish={(values) => {
        mutate(values);
      }}
    >
      <Item hidden name="_id" />
      {/* <FormSection title='General'>
        <Item name='name' label='Name'>
          <Input />
        </Item>
      </FormSection> */}
      <div className="mt-4 mb-4">
        <FormHeadingWithAddNewForm
          title={t('taxes')}
          buttonText={t('add-tax-rule-country')}
          onOk={(form) => {
            // TODO show country name
            setRates([...rates, ...[form.getFieldValue('rate')]]);
          }}
        >
          <TaxRateFormFields filter={rates.map((x) => x.country)} />
        </FormHeadingWithAddNewForm>
      </div>
      <Table
        dataSource={rates.map((rate, index) => ({ ...rate, index }))}
        columns={[
          {
            key: 'rate_name',
            title: t('rate-name'),
            dataIndex: 'rate_name',
            width: '25%',
          },
          {
            key: 'country',
            title: t('country-code'),
            dataIndex: 'country',
            width: '25%',
          },
          {
            key: 'state',
            title: t('state-code'),
            dataIndex: 'state',
            width: '25%',
          },
          {
            key: 'percentage',
            title: t('percentage-0'),
            dataIndex: 'percentage',
            width: '25%',
          },

          {
            key: 'actions',
            title: t('actions'),
            render: (row) => {
              return (
                <Col style={{ display: 'flex', flexDirection: 'row', height: 60 }}>
                  <Tooltip placement="top" title={t('delete')}>
                    <Button
                      type="text"
                      className="mx-2"
                      onClick={() => {
                        rates.splice(row.index, 1);
                        setRates([...rates]);
                      }}
                    >
                      <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
                      <div>{t('delete')}</div>
                    </Button>
                  </Tooltip>
                </Col>
              );
            },
          },
        ]}
      />
    </CardForm>
  );
};

const TaxRateFormFields: React.FC<{ filter: string[] }> = ({ filter }) => {
  const { countries } = useContext(CommonContext);
  const {t} = useTranslation();

  return (
    <>
      <FormHeading>{t('tax')}</FormHeading>
      <Item name={['rate', 'rate_name']} label={t('rate-name-0')} wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} rules={[{ required: true, message: t('tax-name-is-required') }]} extra={t('the-name-for-this-tax-rate-this-name-will-be-shown-on-invoices')}>
        <Input />
      </Item>
      <Item name={['rate', 'country']} label={t('country')} wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} rules={[{ required: true, message: t('country-is-required') }]} extra={t('the-country-for-which-this-tax-is-applicable')}>
        <Select
          className="default-select"
          showSearch
          loading={false}
          optionFilterProp="children"
          filterOption={(input, option) => {
            return ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase());
          }}
          options={!countries ? [] : countries.map((country) => ({ value: country.iso3, label: country.name }))}
        />
      </Item>
      <Item name={['rate', 'state']} label={t('state-code-0')} initialValue={t('key-1')} wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} rules={[{ required: true, message: t('state-code-is-required') }]} extra={t('the-state-for-which-this-tax-is-applicable-you-can-use-for-a-wildcard')}>
        <Input />
      </Item>
      <Item name={['rate', 'zipcode']} label={t('zipcode')} initialValue={t('key-2')} wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} rules={[{ required: true, message: t('zipcode-is-required') }]} extra={t('the-zipcode-s-for-which-this-tax-rate-is-applicable-you-can-use-for-a-wildcard-for-example-32-will-target-all-zipcodes-starting-with-32')}>
        <Input />
      </Item>
      <Item name={['rate', 'percentage']} label={t('percentage-1')} wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} rules={[{ required: true, message: t('percentage-is-required') }]} extra={t('the-percentage-that-will-be-applied-as-tax-rate')}>
        <InputNumber min={0} />
      </Item>
    </>
  );
};

export default Details;
