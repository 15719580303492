import { Button, Form, Input, Modal, notification, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { StatusCodes } from 'http-status-codes';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { IMSNameParams } from '../../../../pages/dashboard';
import cmsService from '../../../../services/areas/content/epg/epg_urls';
import { ContentContext } from '../../../../contexts/contentContext';
import { useContext } from 'react';

type IUpdateLanguageModalProps = {
  visible: boolean;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  initialData: any;
};

const EpgUrlModal = (props: IUpdateLanguageModalProps) => {
  const { visible, onSubmit, onCancel, initialData } = props;
  let [form] = useForm();
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(ContentContext);
  const {t} = useTranslation();

  const onSubmitLocal = async (values: any) => {
    values = {
      ...initialData,
      ...values,
      added: moment().date().toLocaleString(),
      status: "Active",
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    };
    let response = await cmsService.Update_Epg_Urls(values as any);
    if (response.status_code === StatusCodes.OK) {
      notification.success({
        message: t('added-successfully'),
      });
      onSubmit(values);
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
  };
  useEffectOnce(() => {
    if (initialData != undefined) {
      form.setFieldsValue(initialData);
    }
  });

  return (
    <Modal visible={visible} style={{  borderRadius:5}} width={640} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4>{t('edit-egp-url')}</h4>
            <Form form={form} onFinish={(values) => onSubmitLocal(values)} onFinishFailed={(errorInfo) => {}}>
              <label className="col-form-label">{t('name')}</label>
              <Form.Item
                name="name"
                className="form-group mb-0"
                rules={[
                  {
                    required: true,
                    message: t('name-is-required-1'),
                  },
                ]}
              >
                <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder={t('e-g-usa-channels-guide')} />
              </Form.Item>

              <label className="col-form-label">{t('source-url')}</label>
              <Form.Item
                name="url"
                className="form-group mb-0"
                rules={[
                  {
                    required: true,
                    message: t('url-is-required-0')
                  },
                ]}
              >
                <Input type={'url'} className="form-control bg-white font-size-14 text-default p-3rem" placeholder="https://epgurl.com" />
              </Form.Item>
              <label className="col-form-label">{t('source-supplier')}</label>
              <Form.Item initialValue={"TV Profile"} name={'supplier'}>
                <Select className="default-select" defaultActiveFirstOption={true} defaultValue={"TV Profile"}>
                  <Select.Option value={"TV Profile"} key={'TV Profile'}>
                    {t('tv-profile-3')}
                  </Select.Option>
                  <Select.Option value={"Mobibase"} key={'Mobibase'}>
                    {t('mobibase-0')}
                  </Select.Option>
                </Select>
              </Form.Item>
              <label className="col-form-label">{t('source-format')}</label>
              <Form.Item initialValue={'JSON'} name={'type'}>
                <Select className="default-select" defaultActiveFirstOption={true} defaultValue={'JSON'}>
                  <Select.Option value={'JSON'} key={'JSON'}>
                    JSON
                  </Select.Option>
                  <Select.Option value={'XML'} key={'XML'}>
                    XML
                  </Select.Option>
                </Select>
              </Form.Item>
              <div className="col-md-12 p-0">
                <Button htmlType="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                  {t('update-source-url')}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EpgUrlModal;
