import { Link, useParams, useRouteMatch } from 'react-router-dom';
import Table from "../../../../../common/components/AppBuilder/Table";
import { IMSNameParams } from '../../../../dashboard';
import { useTranslation } from 'react-i18next';

const Albums = () => {
  const { clientName, deployment: service_name } = useParams<IMSNameParams & { deployment: string }>();
  const { url } = useRouteMatch();
  const { t } = useTranslation();
  return (
    <div className="container-fluid wrapper">
      <div className="row">

        <div className="col-md-6 mb-2">
     
          <h2 className="page-title pt-4">{t('registration-texts')}</h2>

        </div>
        <div className="col-md-6 text-right">

        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-7 mb-4 mb-md-2 d-flex justify-content-center justify-content-md-start align-items-start"></div>
        <div className="col-md-5 d-flex justify-content-center justify-content-md-end align-items-start">
          <Link className="btn btn-secondary rounded-pill btn-default float-right width-240 height-48" to={`${url}/add`}>
            {t('add-registration-text-0')}
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-4">
          <Table service_name={service_name} clientName={clientName} type={'registrations'}></Table>
        </div>
      </div>
    </div>
  );
};

export default Albums;
