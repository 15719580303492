import { notification } from 'antd';
import * as HttpStatus from 'http-status-codes';
import { useContext, useState } from 'react';
import MWare_Logo from '../../../assets/images/logo.png';
import { CustomNotification } from '../../../common/components/Notification';
import { AuthContext } from '../../../context_api/AuthContext';
import { AuthActionTypes } from '../../../context_api/AuthContext/action-types';
import { IOTPFormValues, IOTPSubmitValues, ISignInFormValues } from '../../../interfaces/forms/auth/AuthInterfaces';
import { IBaseResponse } from '../../../interfaces/responses/IBaseResponse';
import { IUserInfo } from '../../../interfaces/responses/users/IUserInfoResponse';
import AuthService from '../../../services/auth';
import LoginForm from './LoginForm';
import OTPForm from './OTPForm';
import TOTPForm from './TOTPForm';

export interface OTPVerifySuccessResponse extends IBaseResponse {
  data: {
    user: IUserInfo;
    token: string;
    refresh_token: string;
  };
}
const Login = (props: any) => {
  const [loginFormValues, setloginFormValues] = useState<ISignInFormValues>();
  const [LoginSuccessData, setLoginSuccessObj] = useState([] as any);

  const { dispatch: AuthDispatch } = useContext(AuthContext);
  const [step, setStep] = useState<number>(1);
  const [isVerifyingOTP, setIsVerifying] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const Login = async (values: ISignInFormValues): Promise<void> => {
    setIsSubmitting(true);
    setloginFormValues(values);
    let response = await AuthService.Login(values);
    if (response.status_code === HttpStatus.StatusCodes.OK) {
      let successResponse = response.data as any;
      setLoginSuccessObj(successResponse);
      if (successResponse.mfa === 'Phone') {
        notification.success({
          message: 'OTP Sent',
          description: `OTP sent to your registered mobile number ${successResponse.mobile}`,
        });
        setStep(2);
      } else if (successResponse.mfa === 'Email') {
        notification.success({
          message: 'OTP Sent',
          description: `OTP sent to your registered email ${successResponse.email}`,
        });
        setStep(2);
      } else if (successResponse.mfa === 'Authenticator') {
        setStep(3);
      } else {
        let successRes: OTPVerifySuccessResponse = response as OTPVerifySuccessResponse;
        AuthDispatch({
          type: AuthActionTypes.LOGIN_SUCCESS,
          auth: {
            is_remember: true,
            isAuthenticated: true,
            is_userinfo_loading: false,
            user: successResponse,
            refresh_token: successRes?.data.refresh_token,
            token: successRes?.data.token,
          },
        });
      }
    } else {
      CustomNotification({ error: response.errors });
    }
    setIsSubmitting(false);
  };

  const GoBackToLogin = () => setStep(1);

  const ResendOTP = async () => {
    if (loginFormValues) {
      let response = await AuthService.Login(loginFormValues);
      if (response.status_code === HttpStatus.StatusCodes.OK) {
        let successResponse = response.data as any;
        notification.success({
          message: 'OTP Sent',
          description: `A One-Time Password has been sent to ${successResponse.mobile}`,
        });
      } else CustomNotification({ error: response.errors });
    }
  };

  const HandleOTPSubmit = async (values: IOTPFormValues): Promise<void> => {
    setIsVerifying(true);
    let { otp } = values;
    let verifyValues: IOTPSubmitValues = {
      otp,
      ...(LoginSuccessData?.mfa === 'Authenticator' ? {} : { otp_id: LoginSuccessData?.otp_id }),
      mode: LoginSuccessData?.mfa ?? '',
      user_id: LoginSuccessData?.user_id ?? '',
    };
    let response = await AuthService.OTPVerify(verifyValues);
    if (response.status_code === HttpStatus.StatusCodes.OK) {
      notification.success({
        message: 'Login Successful',
        description: `Logged in successfully.`,
      });
      let successResponse: OTPVerifySuccessResponse = response as OTPVerifySuccessResponse;
      AuthDispatch({
        type: AuthActionTypes.LOGIN_SUCCESS,
        auth: {
          is_remember: true,
          isAuthenticated: true,
          is_userinfo_loading: false,
          user: LoginSuccessData, //not sure if this is ok
          refresh_token: successResponse?.data.refresh_token,
          token: successResponse?.data.token,
        },
      });
    } else CustomNotification({ error: response.errors });

    setIsVerifying(false);
  };
  return (
    <div className="auth auth-background d-md-flex align-items-center">
      <div className="container">
        <div className="row justify-content-center shadow">
          <div className="col-md-4  auth-left text-center">

          </div>
          <div className="col-md-8 auth-right bg-white">
            <h2 className="text-primary h4">Login to TVMS</h2>
            <p className="mb-5">Enter your login details below</p>
            <div className="animated fadeIn" style={{ display: step === 1 ? 'block' : 'none' }}>
              <LoginForm isLogingIn={isSubmitting} onSubmit={Login} />
            </div>
            <div className="animated fadeIn" style={{ display: step === 2 ? 'block' : 'none' }}>
              {LoginSuccessData && <OTPForm mode={LoginSuccessData.mode} isVerifying={isVerifyingOTP} onSubmit={HandleOTPSubmit} LoginSuccessDataRef={{ ...LoginSuccessData }} GoBackToLogin={GoBackToLogin} ResendOTP={ResendOTP} />}
            </div>
            <div className="animated fadeIn" style={{ display: step === 3 ? 'block' : 'none' }}>
              {LoginSuccessData && <TOTPForm isVerifying={isVerifyingOTP} onSubmit={HandleOTPSubmit} LoginSuccessDataRef={{ ...LoginSuccessData }} GoBackToLogin={GoBackToLogin} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
