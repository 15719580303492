import { Form, Input, Select } from 'antd';
import { StatusCodes } from 'http-status-codes';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { ContentContext } from '../../../../contexts/contentContext';
import { IMSNameParams } from '../../../../pages/dashboard';
import cmsService from '../../../../services/areas/content/epg/epg_urls';
import { useTranslation } from 'react-i18next';

const Epg = (params_: { data?: any }) => {
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(ContentContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [epgUrls, setEpgUrls] = useState([] as any);
  const [epgChannels, setEpgChannels] = useState([] as any);
  const { t } = useTranslation();

  const getEpgUrls = async () => {
    setIsLoading(true);
    let response = await cmsService.List_Epg_Urls({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 0,
      pageSize: 100000,
    });
    if (response.status_code === StatusCodes.OK) {
      setEpgUrls(response.data.data);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    (async () => await getEpgUrls())();
  });

  useEffect(() => {
    if (params_.data != undefined) {
      if (params_.data != undefined) {
        if (params_.data.epg_url_id != undefined) {
          setSelectedEpgUrl(params_.data.epg_url_id);
        } else {
          setIsLoading2(true);
        }
      } else {
        setIsLoading2(true);
      }
    }
  }, [epgUrls, params_.data]);

  const setSelectedEpgUrl = async (_id: any) => {
    var epgUrl = epgUrls.find((e: any) => e._id == _id);
    if (epgUrl != undefined) {
      setIsLoading2(true);
      const response = await fetch(epgUrl.url.replace('/epg/', '/channels/'));
      const result = await response.json();
      if (result.data.channels != undefined) {
        setEpgChannels(result.data.channels);
      }
      setIsLoading2(false);
    }
  };

  return (
    <div>
      <h4 className="text-primary mt-5 mb-3">{t('guide-1')}</h4>
      <div className="">
        <div className="col-md-12 card-bg-light p-4">
          <div className="row">
            <label className="col-md-3 col-form-label">{t('offset')}</label>
            <div className="col-md-5">
              <Form.Item initialValue={params_.data?.epg_data_offset} name={['epg', 'epg_data_offset']} extra={t('offset_set_time_in_hours')}>
                <Input type="number" min={0} className="form-control bg-white font-size-14 text-default" placeholder={'0'} />
              </Form.Item>
            </div>
          </div>
          <div className="row">
            <label className="col-md-3 col-form-label">{t('source-url')}</label>
            <div className="col-md-5">
              <Form.Item initialValue={params_.data?.epg_url_id} name={['epg', 'epg_url_id']} extra={t('go-to-guide-greater-than-linking-to-modify-the-source-url')}>
                <Select disabled placeholder={t('select-an-source-url')} loading={isLoading} onChange={setSelectedEpgUrl} className="default-select" defaultActiveFirstOption={true}>
                  <Select.Option value={''} key={''}>
                    {t('no-provider-8')}
                  </Select.Option>
                  {epgUrls.map((url: any, index: any) => {
                    return (
                      <Select.Option value={url._id} key={index}>
                        {url.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="row">
            <label className="col-md-3 col-form-label">{t('source-channel')}</label>
            <div className="col-md-5">
              <Form.Item name={['epg', 'epg_url_channel']} extra={t('go-to-guide-greater-than-linking-to-modify-the-channel-selection')}>
                <Select disabled loading={isLoading2} className="default-select" placeholder={t('select-a-channel')} defaultActiveFirstOption={true}>
                  <Select.Option value={''} key={''}>
                    {t('no-channel')}
                  </Select.Option>
                  {epgChannels.map((channel: any, index: any) => {
                    return (
                      <Select.Option value={channel.channel} key={index}>
                        {channel.channel}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Epg;
