import { faBoxes, faChartLine, faCog, faCoins, faCopy, faFileArchive, faMagicWandSparkles, faMessages, faTv, faUsers } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Input, Modal, Pagination, Row, Switch, notification } from 'antd';
import { useContext, useState } from 'react';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { IMSNameParams } from '../../../../..';
import { AuthContext } from '../../../../../../../context_api/AuthContext';
import { NotVisibleForRole, VisibleForRole, hasAccessToDeployment, hasAccessToService } from '../../../../../../../common/components/VisibleForRole';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { softDelete, serviceSoftDelete } from '../../../../../../../services/deployments/deployment';
import { DeleteOutlined } from '@ant-design/icons';
import { TVMSRoles } from '../../../../../../../constants/TVMSRoles';
import { useTranslation } from 'react-i18next';
import DeploymentStorage from '../storages/deploymentStorage';
import { Deployment, DeploymentService } from '@mwaretv/database/build/backend/models/deployment/deployment';
import DeploymentBrowser from '../storages/deploymentBrowser';
import ServiceBrowser from '../storages/serviceBrowser';
import ServiceStorage from '../storages/serviceStorage';
import type { DocumentType } from '@typegoose/typegoose';

type IPaginateObj<T> = {
  page: number;
  per_page: number;
  pre_page: number | null;
  next_page: number | null;
  total: number;
  total_pages: number;
  data: T[];
};

interface IDeploymentInfo {
  id: string;
  deployment: Deployment;
  deploymentName: string;
  allCmsServices: DocumentType<DeploymentService>[];
}

export const Deployments = (data: IDeploymentInfo) => {
  const { id, deploymentName, allCmsServices, deployment } = data;
  const perPageItems = 20;
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { state: AuthState } = useContext(AuthContext);
  const [paginationObject, setPaginationObject] = useState<{
    next_page: number | null;
    current_page: number;
    total_pages: number;
    prev_page: number | null;
    page_size: number;
  }>({
    current_page: 1,
    next_page: null,
    total_pages: 1,
    page_size: perPageItems,
    prev_page: null,
  });

  const [filteredCmsServices, setCMSServices] = useState(() => {
    const paginateObj = handlePaginate<DocumentType<DeploymentService>>(allCmsServices, 1, perPageItems);
    setPaginationObject({
      current_page: paginateObj.page,
      next_page: paginateObj.next_page,
      page_size: paginateObj.per_page,
      prev_page: paginateObj.pre_page,
      total_pages: paginateObj.total_pages,
    });

    return paginateObj.data;
  });

  // const [cmsState, setCMS] = useState<IDatastore | undefined>(cms);

  const { clientName } = useParams<IMSNameParams>();
  const { url } = useRouteMatch();

  const handleSearchInput = async (value: string) => {
    if (value)
      setCMSServices(() => {
        const newArr = [...allCmsServices];
        const newState = newArr.filter((service) => service.name.startsWith(value));
        const paginateObj = handlePaginate<DocumentType<DeploymentService>>(newState, value && paginationObject.current_page > 1 ? 1 : paginationObject.current_page, paginationObject.page_size);

        setPaginationObject({
          current_page: paginateObj.page,
          next_page: paginateObj.next_page,
          page_size: paginateObj.per_page,
          prev_page: paginateObj.pre_page,
          total_pages: paginateObj.total_pages,
        });

        return paginateObj.data;
      });
    else {
      setCMSServices(() => {
        let paginateObj = handlePaginate<DocumentType<DeploymentService>>(allCmsServices, 1, paginationObject.page_size);
        setPaginationObject({
          current_page: 1,
          next_page: paginateObj.next_page,
          page_size: paginateObj.per_page,
          prev_page: paginateObj.pre_page,
          total_pages: paginateObj.total_pages,
        });

        return paginateObj.data;
      });
      // setCMS(cms);
    }
  };
  function handlePaginate<T>(items: T[], current_page: number, per_page_items: number): IPaginateObj<T> {
    const page = current_page || 1;
    const per_page = per_page_items || perPageItems;
    const offset = (page - 1) * per_page;
    const paginatedItems = items.slice(offset).slice(0, per_page_items);
    const total_pages = Math.ceil(items.length / per_page);

    return {
      page: page,
      per_page: per_page,
      pre_page: page - 1 ? page - 1 : null,
      next_page: total_pages > page ? page + 1 : null,
      total: items.length,
      total_pages: total_pages,
      data: paginatedItems,
    };
  }

  const hasAccessToApps = hasAccessToDeployment(AuthState.user!, clientName, deploymentName, 'apps');
  const hasAccessToContent = hasAccessToDeployment(AuthState.user!, clientName, deploymentName, 'content');
  const hasAccessToBilling = (service: string) => hasAccessToService(AuthState.user!, clientName, deploymentName, service, 'billing');
  const hasAccessToCustomers = (service: string) => hasAccessToService(AuthState.user!, clientName, deploymentName, service, 'customers');
  const hasAccessToMarketing = (service: string) => hasAccessToService(AuthState.user!, clientName, deploymentName, service, 'marketing');
  const hasAccessToReporting = (service: string) => false && hasAccessToService(AuthState.user!, clientName, deploymentName, service, 'reporting');

  const DeploymentElementCard = ({ type }: { type: 'content' | 'apps' }) => {
    const elementData = {
      content: {
        hasAccess: hasAccessToContent,
        title: t('content'),
        link: 'content',
        icon: faTv,
        iconColor: '#688fcf',
        borderColor: '#688fcf',
        mainColor: '#F2F7FF',
        offColor: '#E6EFFF',
      },
      apps: {
        hasAccess: hasAccessToApps,
        title: t('apps'),
        link: 'appbuilder',
        icon: faMagicWandSparkles,
        iconColor: '#7260a6',
        borderColor: '#7260a6',
        mainColor: '#F7F4FF',
        offColor: '#ECE6FF',
      },
    };

    const { hasAccess, title, icon, iconColor, mainColor, borderColor, link } = elementData[type];

    const InnerComponent = () => (
      <>
        <FontAwesomeIcon color={iconColor} size={'3x'} icon={icon} />
        <p style={{ color: '#777' }} className=" mb-0 mt-3 font-size-18">
          <b>{title}</b>
        </p>
        <p style={{ color: '#cbcbcb', wordBreak: 'break-all', wordWrap: 'break-word', whiteSpace: 'normal', overflow: 'hidden', height: 20 }} className="mb-0 text-truncate">
          {deploymentName}
        </p>
      </>
    );

    return hasAccess ? (
      <div className="card card-shadow-hover shadow-none h-100" style={{ background: mainColor, borderColor: borderColor, borderWidth: 2, borderStyle: 'solid' }}>
        <div className="card-body px-4 py-3 border rounded text-center">
          <Link className="d-flex flex-column justify-content-center align-items-center h-100" to={`${url}/${deploymentName}/${link}`}>
            <InnerComponent />
          </Link>
        </div>
      </div>
    ) : (
      <div className="card shadow-none h-100" style={{ background: '#fafafa' }}>
        <div className="card-body px-4 py-3 border rounded text-center">
          <div className="d-flex flex-column justify-content-center align-items-center h-100">{/* <InnerComponent /> */}</div>
        </div>
      </div>
    );
  };

  const DeploymentElementCardExternal = ({ type }: { type: 'content' | 'apps' }) => {
    const elementData = {
      content: {
        hasAccess: hasAccessToContent,
        title: t('content'),
        link: 'content',
        icon: faTv,
        iconColor: '#688fcf',
        borderColor: '#688fcf',
        mainColor: '#F2F7FF',
        offColor: '#E6EFFF',
      },
      apps: {
        hasAccess: hasAccessToApps,
        title: t('apps'),
        link: 'appbuilder',
        icon: faMagicWandSparkles,
        iconColor: '#7260a6',
        borderColor: '#7260a6',
        mainColor: '#F7F4FF',
        offColor: '#ECE6FF',
      },
    };

    const { hasAccess, title, icon, iconColor, mainColor, borderColor, link } = elementData[type];

    const InnerComponent = () => (
      <div className="text-left" style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <FontAwesomeIcon color={iconColor} size={'3x'} icon={icon} />
        </div>
        <div style={{ marginLeft: 20, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <div>
            <div style={{ color: '#777' }} className="font-size-18">
              <b>{title}</b>
            </div>
            <div style={{ color: '#777', wordBreak: 'break-all', wordWrap: 'break-word', whiteSpace: 'normal', overflow: 'hidden', height: 20 }} className="mb-0 text-truncate">
              {deploymentName}
            </div>
          </div>
        </div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button>Content Manager</Button>
        </div>
      </div>
    );

    return (
      <div className="card card-shadow-hover shadow-none h-100" style={{ background: mainColor, borderColor: borderColor, borderWidth: 2, borderStyle: 'solid' }}>
        <div className="card-body px-4 py-3 border rounded text-center">
          <Link className="d-flex flex-column h-100" to={`${url}/${deploymentName}/${link}`}>
            <InnerComponent />
          </Link>
        </div>
      </div>
    );
  };

  const ServiceElementCard = ({ service, type }: { service: string; type: 'customers' | 'billing' | 'marketing' | 'reporting' | 'inventory' }) => {
    const elementData = {
      customers: {
        hasAccess: hasAccessToCustomers(service),
        title: t('customers'),
        icon: faUsers,
        iconColor: '#FFCB00',
        borderColor: '#FFCB00',
        mainColor: '#FFFCF1',
        offColor: '#efe9e6',
      },
      inventory: {
        hasAccess: hasAccessToBilling(service),
        title: 'Inventory',
        icon: faBoxes,
        iconColor: '#C340B5',
        borderColor: '#C340B5',
        mainColor: '#FFF1FE',
        offColor: '#efeee6',
      },
      billing: {
        hasAccess: hasAccessToBilling(service),
        title: t('billing'),
        icon: faCoins,
        iconColor: '#FFAF86',
        borderColor: '#FFAF86',
        mainColor: '#FFF5F0',
        offColor: '#efeee6',
      },
      marketing: {
        hasAccess: hasAccessToMarketing(service),
        title: t('marketing'),
        icon: faMessages,
        iconColor: '#14C6CC',
        borderColor: '#14C6CC',
        mainColor: '#EBFAFB',
        offColor: '#e8eaeb',
      },
      reporting: {
        hasAccess: hasAccessToMarketing(service),
        title: t('reports'),
        icon: faChartLine,
        iconColor: '#3cb451',
        borderColor: '#3cb451',
        mainColor: '#EBFDEE',
        offColor: '#e7edeb',
      },
    };

    const { hasAccess, title, icon, iconColor, mainColor, borderColor } = elementData[type];

    const InnerComponent = () => (
      <>
        <FontAwesomeIcon color={iconColor} size={'2x'} icon={icon} />
        <p style={{ color: '#777' }} className=" mb-0 mt-1 font-size-16">
          <b>{title}</b>
        </p>
        <p style={{ fontSize: 12, color: '#b0b0b0', wordBreak: 'break-all', wordWrap: 'break-word', whiteSpace: 'normal', overflow: 'hidden', height: 20 }} className="mb-0 text-truncate">
          {service}
        </p>
      </>
    );

    const component = hasAccess ? (
      <div className="card card-shadow-hover shadow-none h-100" style={{ background: mainColor, minHeight: 134, borderColor: borderColor, borderWidth: 2, borderStyle: 'solid' }}>
        <div className="card-body px-4 py-3 border rounded text-center" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Link className="d-flex flex-column justify-content-center align-items-center h-100" to={`${url}/${deploymentName}/${service}/${type}`}>
            <InnerComponent />
          </Link>
        </div>
      </div>
    ) : (
      <div></div>
      // <div className="card shadow-none h-100" style={{ background: '#fafafa', minHeight: 130 }}>
      //   <div className="card-body px-4 py-3 border rounded text-center">
      //     <div className="d-flex flex-column justify-content-center align-items-center h-100">{/* <InnerComponent /> */}</div>
      //   </div>
      // </div>
    );

    return <div key={`datastore_${type}_${service}`}>{component}</div>;
  };

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [shouldDeleteDeployment, setShouldDeleteDeployment] = useState(false);

  const showDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteService = (deploymentId: string, serviceId: string) => {
    servSoftDelete.mutate({ id: deploymentId, serviceId });

    if (shouldDeleteDeployment && !servSoftDelete.isError) {
      deploymentSoftDelete.mutate({ id: deploymentId });
    }

    setIsDeleteModalOpen(false);
  };

  const toggleDeleteDeployment = (checked) => {
    setShouldDeleteDeployment(checked);
  };

  const servSoftDelete = useMutation(
    (values: { id: string; serviceId: string }) => {
      return serviceSoftDelete({ id: values.id, serviceId: values.serviceId, params: { client: clientName } });
    },
    {
      onSuccess: () => {
        notification.success({ message: t('service-is-deleted') });
        queryClient.invalidateQueries(['deployments']); // required to refresh the page
      },
      onError: () => {
        notification.error({ message: t('delete-failed') });
      },
    }
  );

  const deploymentSoftDelete = useMutation(
    (values: { id: string }) => {
      return softDelete({ id: values.id, params: { client: clientName } });
    },
    {
      onSuccess: () => {
        notification.success({ message: t('deployment-is-deleted') });
        queryClient.invalidateQueries(['deployments']); // required to refresh the page
      },
      onError: () => {
        notification.error({ message: t('delete-failed') });
      },
    }
  );
  const [showDeploymentStorage, setShowDeploymentStorage] = useState(false);
  const [showDeploymentBrowser, setShowDeploymentBrowser] = useState(false);
  const [showServiceStorage, setShowServiceStorage] = useState(false);
  const [showServiceBrowser, setShowServiceBrowser] = useState(false);

  const [deploymentStorage, setDeploymentStorage] = useState([] as any);
  const [serviceStorage, setserviceStorage] = useState([] as any);

  const history = useHistory();

  const submitStorage = () => {
    setShowDeploymentStorage(false);
    setShowServiceStorage(false);
    setShowDeploymentBrowser(false);
    setShowDeploymentStorage(false);
    history.go(0);
  };

  return (
    <>
      {showDeploymentStorage && <DeploymentStorage deployment={deploymentStorage} clientName={clientName} visible={showDeploymentStorage} onSubmit={() => submitStorage()} onCancel={() => setShowDeploymentStorage(false)}></DeploymentStorage>}
      {showDeploymentBrowser && <DeploymentBrowser deployment={deploymentStorage} clientName={clientName} visible={showDeploymentBrowser} onSubmit={() => submitStorage()} onCancel={() => setShowDeploymentBrowser(false)}></DeploymentBrowser>}
      {showServiceStorage && <ServiceStorage deployment={deploymentStorage} serviceName={serviceStorage.name} clientName={clientName} visible={showServiceStorage} onSubmit={() => submitStorage()} onCancel={() => setShowServiceStorage(false)}></ServiceStorage>}
      {showServiceBrowser && <ServiceBrowser deployment={deploymentStorage} service={serviceStorage} clientName={clientName} visible={showServiceBrowser} onSubmit={() => submitStorage()} onCancel={() => setShowServiceBrowser(false)}></ServiceBrowser>}

      {deployment.storage == undefined && (
        <div style={{ width: '100%' }}>
          <Alert className="mt-3" message={t('setup_storage')} showIcon description={t('there_is_no_storage_setup_dep')} type="warning" />
        </div>
      )}

      <div className="col-12 mb-4 slider">
        <div className="row mt-3">
          {hasAccessToContent && (
            <NotVisibleForRole roles={[TVMSRoles.Provider]}>
              <div className="col-2" style={{ paddingLeft: 0 }}>
                <VisibleForRole roles={[TVMSRoles.SuperAdmin, TVMSRoles.Admin]}>
                  <div style={{ height: 145, borderRadius: 5, marginBottom: 6, backgroundColor: '#f9f9f9', border: '1px solid #dfdfdf' }}>
                    <div className="row" style={{ position: 'absolute', top: 90, right: 15, fontSize: 12, justifyContent: 'flex-end', margin: 10, marginRight: 25 }}>
                      <div className="row" style={{ justifyContent: 'flex-end', alignContent: 'flex-end' }}>
                        {deployment.storage != undefined && (
                          <Button
                            className="btn-primary rounded-pill"
                            style={{ marginRight: 5 }}
                            onClick={() => {
                              setDeploymentStorage(deployment);
                              setShowDeploymentBrowser(true);
                            }}
                          >
                            <FontAwesomeIcon style={{ marginTop: 4 }} icon={faFileArchive}></FontAwesomeIcon>
                          </Button>
                        )}

                        <Button
                          className="btn-primary rounded-pill"
                          onClick={() => {
                            setDeploymentStorage(deployment);
                            setShowDeploymentStorage(true);
                          }}
                        >
                          <FontAwesomeIcon style={{ marginTop: 4 }} icon={faCog}></FontAwesomeIcon>
                        </Button>
                      </div>
                    </div>
                    <div className="row" style={{ fontSize: 12, padding: 20 }}>
                      <div className="col">
                        <b>{t('deployment_storage')}</b>
                        <div>{deployment.storage?.label}</div>
                        <b>{t('location')}</b>
                        <div>{deployment.storage?.region}</div>
                      </div>
                    </div>
                  </div>
                </VisibleForRole>
                <div style={{ height: filteredCmsServices.length > 1 ? '100%' : 'auto', paddingBottom: filteredCmsServices.length > 1 ? 150 : 0 }}>
                  <DeploymentElementCard type="content" />
                </div>
              </div>
            </NotVisibleForRole>
          )}
          {hasAccessToContent && (
            <VisibleForRole roles={[TVMSRoles.Provider]}>
              <div className="col-12" style={{ padding: 0 }}>
                <div style={{ height: filteredCmsServices.length > 1 ? '100%' : 'auto', paddingBottom: filteredCmsServices.length > 1 ? 150 : 0 }}>
                  <DeploymentElementCardExternal type="content" />
                </div>
              </div>
            </VisibleForRole>
          )}
          {!hasAccessToContent && <div style={{ paddingLeft: 15 }}></div>}

          <NotVisibleForRole roles={[TVMSRoles.Provider]}>
            <div className="col">
              <VisibleForRole roles={[TVMSRoles.SuperAdmin, TVMSRoles.Admin]}>
                <div className="row mb-3">
                  <Input
                    className="form-control bg-white font-size-14 text-default"
                    placeholder={t('filter-your-services')}
                    onChange={(e) => {
                      handleSearchInput(e.target.value);
                    }}
                  />
                </div>
              </VisibleForRole>
              {filteredCmsServices.map((service, index) => {
                const isLastElement = index === filteredCmsServices.length - 1;

                return (
                  <div className={`row ${filteredCmsServices.length > 1 && !isLastElement ? 'mb-3' : ''}`} key={service._id}>
                    {service.storage == undefined && <Alert className="mb-3 col-12" message={t('setup_storage')} showIcon description={t('there_is_no_storage_setup_ser')} type="warning" />}
                    <VisibleForRole roles={[TVMSRoles.SuperAdmin, TVMSRoles.Admin]}>
                      <div className="col-12" style={{ borderRadius: 5, padding: 11.5, display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginBottom: 5, backgroundColor: '#f9f9f9', border: '1px solid #dfdfdf' }}>
                        <div style={{ display: 'flex', width: '100%', fontSize: 12 }}>
                          <div style={{ width: '100%', paddingRight: 20 }}>
                            <b>{t('service_storage')}</b>
                            <p>{service?.storage?.label}</p>
                          </div>
                        </div>
                        <div style={{ display: 'flex', width: '100%', fontSize: 12 }}>
                          <div style={{ width: '100%', paddingRight: 20 }}>
                            <b>{t('location')}</b>
                            <p>{service?.storage?.region}</p>
                          </div>
                        </div>
                        {service.storage !== undefined && (
                          <div className="row" style={{ marginRight: 5, height: '100%', display: 'flex', justifyContent: 'flex-start', alignContent: 'center', paddingRight: 20 }}>
                            <Button
                              className="btn-primary rounded-pill"
                              onClick={() => {
                                setserviceStorage(service);
                                setDeploymentStorage(deployment);
                                setShowServiceBrowser(true);
                              }}
                            >
                              <FontAwesomeIcon style={{ marginTop: 4 }} icon={faFileArchive}></FontAwesomeIcon>
                            </Button>
                          </div>
                        )}
                        <div className="row" style={{ height: '100%', display: 'flex', justifyContent: 'flex-start', alignContent: 'center', paddingRight: 20 }}>
                          <Button
                            className="btn-primary rounded-pill"
                            onClick={() => {
                              setserviceStorage(service);
                              setDeploymentStorage(deployment);
                              setShowServiceStorage(true);
                            }}
                          >
                            <FontAwesomeIcon style={{ marginTop: 4 }} icon={faCog}></FontAwesomeIcon>
                          </Button>
                        </div>
                      </div>
                    </VisibleForRole>
                    <div className="col-12">
                      <div className="row">
                        {hasAccessToBilling(service.name) && (
                          <div className="col px-0">
                            <ServiceElementCard service={service.name} type="inventory" />
                          </div>
                        )}
                        {hasAccessToBilling(service.name) && (
                          <div className="col pl-0" style={{ paddingRight: 0, marginLeft: 15 }}>
                            <ServiceElementCard service={service.name} type="billing" />
                          </div>
                        )}
                        {hasAccessToCustomers(service.name) && (
                          <div className="col pl-0" style={{ paddingRight: 0, marginLeft: 15 }}>
                            <ServiceElementCard service={service.name} type="customers" />
                          </div>
                        )}

                        {hasAccessToMarketing(service.name) && (
                          <div className="col pl-0" style={{ paddingRight: 0, marginLeft: 15 }}>
                            <ServiceElementCard service={service.name} type="marketing" />
                          </div>
                        )}

                        <div className="col px-0" style={{ paddingRight: 0, marginLeft: 15 }}>
                          <ServiceElementCard service={service.name} type="reporting" />
                        </div>

                        <VisibleForRole roles={[TVMSRoles.SuperAdmin, TVMSRoles.Admin]}>
                          <div style={{ width: 50, paddingLeft: 15, marginRight: 15 }}>
                            <Button className="card card-shadow-hover shadow-none" onClick={showDeleteModal} style={{ width: 50, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f7f7f7', borderRadius: 5, borderColor: '#dfdfdf', borderWidth: 1, borderStyle: 'solid' }} icon={<DeleteOutlined style={{ fontSize: '20px', color: 'crimson' }} />}></Button>
                            <Modal title={t('confirm-deletion')} open={isDeleteModalOpen} onOk={() => handleDeleteService(id, service._id)} onCancel={() => setIsDeleteModalOpen(false)}>
                              {filteredCmsServices.length === 1 && (
                                <div>
                                  <p>{t('are-you-sure-you-want-to-delete-this-service')}</p>
                                  <div>
                                    {t('delete-the-deployment-as-well')}
                                    <div className="text-left">
                                      <Switch checked={shouldDeleteDeployment} onChange={toggleDeleteDeployment} />
                                    </div>
                                  </div>
                                </div>
                              )}
                              {filteredCmsServices.length > 1 && <p>{t('are-you-sure-you-want-to-delete-this-service-0')}</p>}
                            </Modal>
                          </div>
                        </VisibleForRole>
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className="col-12 ">
                {paginationObject && (
                  <Pagination
                    hideOnSinglePage
                    onChange={(page, pageSize) => {
                      const paginationObjResult = handlePaginate<DocumentType<DeploymentService>>(filteredCmsServices, page, pageSize);
                      setCMSServices(paginationObjResult.data);
                      setPaginationObject((prevState) => {
                        prevState.current_page = paginationObjResult.page;
                        prevState.next_page = paginationObjResult.next_page;
                        prevState.page_size = paginationObjResult.per_page;
                        prevState.prev_page = paginationObjResult.pre_page;
                        prevState.total_pages = paginationObjResult.total_pages;
                        return prevState;
                      });
                    }}
                    defaultCurrent={paginationObject.current_page}
                    pageSize={paginationObject.page_size}
                    total={paginationObject.total_pages * paginationObject.page_size}
                  />
                )}
              </div>
            </div>
          </NotVisibleForRole>
          {hasAccessToApps && (
            <div className="col-2" style={{ paddingRight: 0 }}>
              <VisibleForRole roles={[TVMSRoles.SuperAdmin, TVMSRoles.Admin]}>
                <div style={{ height: 145, borderRadius: 5, marginBottom: 6, backgroundColor: '#f9f9f9', border: '1px solid #dfdfdf' }}>
                  <div className="row" style={{ position: 'absolute', right: 0, top: 90, fontSize: 12, justifyContent: 'flex-end', margin: 10, marginRight: 25 }}>
                    <div className="row" style={{ justifyContent: 'flex-end', alignContent: 'flex-end' }}>
                      {deployment.storage != undefined && (
                        <Button
                          className="btn-primary rounded-pill"
                          style={{ marginRight: 5 }}
                          onClick={() => {
                            setDeploymentStorage(deployment);
                            setShowDeploymentBrowser(true);
                          }}
                        >
                          <FontAwesomeIcon style={{ marginTop: 4 }} icon={faFileArchive}></FontAwesomeIcon>
                        </Button>
                      )}

                      <Button
                        className="btn-primary rounded-pill"
                        onClick={() => {
                          setDeploymentStorage(deployment);
                          setShowDeploymentStorage(true);
                        }}
                      >
                        <FontAwesomeIcon style={{ marginTop: 4 }} icon={faCog}></FontAwesomeIcon>
                      </Button>
                    </div>
                  </div>
                  <div className="row" style={{ fontSize: 12, padding: 20 }}>
                    <div className="col">
                      <b>{t('deployment_storage')}</b>
                      <div>{deployment.storage?.label}</div>
                      <b>{t('location')}</b>
                      <div>{deployment.storage?.region}</div>
                    </div>
                  </div>
                </div>
              </VisibleForRole>
              <div style={{ height: filteredCmsServices.length > 1 ? '100%' : 'auto', paddingBottom: filteredCmsServices.length > 1 ? 150 : 0 }}>
                <DeploymentElementCard type="apps" />
              </div>
            </div>
          )}
          <NotVisibleForRole roles={[TVMSRoles.Provider]}>
            <>{!hasAccessToApps && <div style={{ marginRight: 15 }}></div>}</>
          </NotVisibleForRole>
        </div>
      </div>
    </>
  );
};
