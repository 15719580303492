import { Skeleton, Image } from 'antd';
import { useEffect, useState } from 'react';
import { Application } from '../../../../../interfaces/forms/areas/appbuilder/applications/application/application';
import { Rectangle_Rails } from '../../../../../interfaces/forms/areas/appbuilder/builder/elements/rails/rectangle/rectangle';
import moment from 'moment';
import axios from 'axios';
import { GetRailsElements, ImageRails, getCorrectImage, getTitle } from './helpers/helper';

export type LayoutProps = {
  data: Rectangle_Rails;
  application: Application;
};

const WideRails = (props: LayoutProps) => {
  useEffect(() => {}, [props.data]);
  const [items, setItems] = useState([] as any);
  const [isActive, setIsActive] = useState(true);
  const [color, setColor] = useState('#999999');

  useEffect(() => {
    async function fetchMyAPI() {
      if (props.data.type != undefined) {
        if (props.data.content.type === 'Content List') {
          try {
            var path = 'https://cloudtv.akamaized.net/' + props.application.client + '/' + props.application.deployment + '/' + props.data.content.list?.type?.toLocaleLowerCase() + '/lists/' + props.data.content.list?.list + '_list.json?t=' + moment().unix();
            var response = await axios.get(path);
            if (response.statusText == 'OK') {
              setItems(response.data[0].items);
            }
          } catch (e) {
            setColor('#bf8c8c');
          }
        } else {
          setIsActive(false);
        }
      } else {
        setIsActive(false);
      }
    }
    fetchMyAPI();
  }, [props.data]);

  return (
    <div style={{ backgroundColor: props.data.placement.background_color }}>
      <div
        className="container remove-hor-padding"
        style={{
          paddingTop: props.application.theme?.rails_top_margin ?? 5,
          paddingBottom: props.application.theme?.rails_bottom_margin ?? 5,
        }}
      >
        {props.data.content.list?.show_title != false && (
          <div className="text-left" style={{ marginLeft: 5 }}>
            {props.data.content.list?.title[0] && props.data.content.list?.title[0].title != 'Edit to add your text' && <>{getTitle(props.data)}</>}
            {(!props.data.content.list?.title[0] || props.data.content.list?.title[0].title == 'Edit to add your text') && <Skeleton.Input active={true} size={'large'} style={{ width: 200, height: 30, backgroundColor: '#bf8c8c' }} />}
          </div>
        )}
        {color == '#bf8c8c' && <div style={{zIndex:100, position: 'absolute', bottom: 0, right: 0, backgroundColor: 'crimson', color: '#ffffff', padding: 5, opacity: 0.85, fontSize: 12, fontWeight: 'bold', borderRadius: 5, marginRight: 10, marginBottom: 5, paddingLeft: 10, paddingRight: 10 }}>Publish Your List</div>}
        <div style={{ gridAutoColumns: '100%', display: 'grid' }}>
          <div
            style={{
              display: 'grid',
              gridTemplateRows: 'min-content',
              gridAutoFlow: 'column',
              gridAutoColumns: 355,
              gap: 5,
              overflowX: 'auto',
              overscrollBehaviorInline: 'contain',
              marginTop: 10,
              marginLeft: 5,
              marginRight: 5,
            }}
          >
            {(items == undefined || items.length === 0) && (
              <>
                <GetRailsElements form={'wide'}  type={props.data.content.list?.type ?? ''} props={props} color={color} isActive={isActive} height={200} width={355}></GetRailsElements>
              </>
            )}
            {items != undefined && items.length > 0 && (
              <>
                {items.map((item, index) => {
                  return <ImageRails index={index} form={'wide'} type={props.data.content.list?.type ?? ''} key={index} props={props} height={200} width={355} item={item} image={getCorrectImage(item, props.data.content.list?.type)}></ImageRails>;
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WideRails;
