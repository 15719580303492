import { Button, Form, Input, notification, Rate } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { StatusCodes } from 'http-status-codes';
import { useContext, useState } from 'react';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import Security from '../../../../../../common/components/Content/Security/Security';
import PayPerView from '../../../../../../common/components/Content/PayPerView/PayPerView';
import Translations from '../../../../../../common/components/Content/Translations/Translations';
import ImageUploader from '../../../../../../common/components/ImageUploader/ImageUploader';
import { Translations as TRLNS } from '../../../../../../interfaces/responses/areas/content/shared/translations';
import cmsService from '../../../../../../services/areas/content/movies/movies';
import { IMSNameParams } from '../../../../../dashboard';
import Tags from '../../../../../../common/components/Content/Tags/Tags';
import Actors from '../../../../../../common/components/Content/Actors/Actors';
import Ads from '../../../../../../common/components/Content/Ads/Ads';
import ParentalControl from '../../../../../../common/components/Content/Parental/Parental';
import MovieStreams from '../../../../../../common/components/Content/MovieStreams/MovieStreams';
import { ContentContext } from '../../../../../../contexts/contentContext';
import { STORAGE_ENDPOINTS } from '../../../../../../services/storage_paths';
import { Movies } from '../../../../../../interfaces/forms/areas/content/movies/movies';
import Placement from '../../../../../../common/components/Content/Placement/Placement';
import Providers from '../../../../../../common/components/Content/Providers/Providers';
import { ErrorModal } from '../../../../../../common/components/ErrorModal/ErrorModal';
import { AuthContext } from '../../../../../../context_api/AuthContext';
import { TVMSRoles } from '../../../../../../constants/TVMSRoles';

const AddNewMusicAlbum = () => {
  const history = useHistory();
  const params = useParams<IMSNameParams & { deployment: string }>();
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -1).join('/');
  const [isAdding, setIsAdding] = useState(false);
  const [translations, setTranslations] = useState<TRLNS[]>([]);
  const [security, setSecurity] = useState([] as any);
  const [payperview, setPayPerView] = useState([] as any);
  const [logo, setLogo] = useState([] as any);
  const [poster, setPoster] = useState([] as any);
  const [backdrop, setBackdrop] = useState([] as any);
  const [actors, setActors] = useState([] as any);
  const [placement, setPlacement] = useState([] as any);
  const [parental, setParental] = useState({
    enabled: false,
    rating: [] as any,
  });
  const [tags, setTags] = useState([] as any);
  const [ads, setAds] = useState([] as any);
  const [form] = useForm();
const { t } = useTranslation();
  const [provider, setProvider] = useState('No Provider');
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [modalData, setModalData] = useState([] as any);
  const { clientName, _id } = useParams<IMSNameParams>();
  const { service, deployment } = useContext(ContentContext);
  const { state: AuthState } = useContext(AuthContext);
  const isProvider = AuthState.user?.role === TVMSRoles.Provider ? true : false;
  const providerName = AuthState.user?.name;

  const handleSubmit = async (values: Movies) => {
    setIsAdding(true);
    var payperview_ = [] as any;
    if (payperview.rule?.access_length == undefined) {
      var curs = [] as any;
      deployment?.currencies.forEach((cur, index) => {
        curs.push({
          iso3: cur.isoCurrency,
          credits_rented: 0,
          credits_purchased: 0,
          key: index,
        });
      });
      payperview_ = {
        enable: payperview.enable,
        rule: '',
        currencies: curs,
      };
    } else {
      payperview_ = payperview;
    }
    values = {
      name: values.name,
      imdbid: values.imdbid ?? '',
      tmdbid: values.tmdbid ?? '',
      released: values.released,
      placement: placement,
      runtime: values.runtime,
      duration:values.duration ?? 0,
      writers: values.writers,
      provider: {
        name: isProvider ? AuthState?.user?.name : provider,
      },
      provider_status: isProvider ? 'validation' : '',
      language: values.language,
      studio: values.studio,
      rating: values.rating != undefined ? values.rating : 1,
      parental: parental,
      actors: actors,
      security: security,
      translations: translations,
      payperview: payperview_,
      tags: tags,
      ads: {
        vast_url: values.ads.vast_url,
      },

      streams: values.streams,

      images: {
        logo: logo?.url?.replace('https://cloudtv.akamaized.net', ''),
        background: backdrop?.url?.replace('https://cloudtv.akamaized.net', ''),
        poster: poster?.url?.replace('https://cloudtv.akamaized.net', ''),
      },
      query_params: {
        instance: clientName,
        cms: params.deployment ?? '',
      },
    };
    
    let response = await cmsService.Add_Movie(values as any);
    if (response.status_code === StatusCodes.OK) {
      notification.success({
        message: t('added-successfully'),
      });
      history.push(redirectUrl);
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
    setIsAdding(false);
  };

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-12 mb-2">
          <Link to={`${redirectUrl}/`}>{t('back-to-movies-list')}</Link>
          <div className="row-md-12" style={{ borderBottom: '1px solid #cfcfcf' }}></div>
          <h2 className="page-title mt-4">{t('add-movie')}</h2>
          <p className="h6 font-weight-normal line-height-26">{t('create-your-movie-collection-and-offer-it-bundled-in-packages-to-your-customers')}</p>
        </div>
      </div>
      <ErrorModal
        isOpenModal={openErrorModal}
        data={modalData}
        setIsOpen={(isOpen) => {
          setOpenErrorModal(isOpen);
        }}
      />
      <Form
        form={form}
        onFinish={(values: any) => {
          handleSubmit(values);
        }}
        onFinishFailed={(errorInfo) => {
          setModalData(errorInfo?.errorFields);
          setOpenErrorModal(true);
        }}
      >
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <h4 className="text-primary mt-5 mb-3">{t('movie')}</h4>
            <div className="card-bg-light pl-5 pt-3">
              <div className="row">
                <label className="col-md-3 col-form-label">{t('name')}</label>
                <Form.Item
                  className="col-md-7"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t('name-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('movie-name')} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-3 col-form-label">{t('imdb-id')}</label>
                <Form.Item className="col-md-7" name="imdbid">
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder="tt2923kjd" />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-3 col-form-label">{t('tmbd-id')}</label>
                <Form.Item className="col-md-7" name="tmdbid">
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder="1234123" />
                </Form.Item>
              </div>

              <div className="row">
                <label className="col-md-3 col-form-label">{t('released-1')}</label>
                <Form.Item className="col-md-7" name="released">
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder="1999" />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-3 col-form-label">{t('runtime-mins')}</label>
                <Form.Item className="col-md-7" name="runtime">
                  <Input defaultValue={0} type={'number'} className="form-control bg-white font-size-14 text-default p-3rem" placeholder="" />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-3 col-form-label">{t('duration-secs')}</label>
                <Form.Item className="col-md-7" name="duration">
                  <Input defaultValue={0} type={'number'} className="form-control bg-white font-size-14 text-default p-3rem" placeholder="" />
                </Form.Item>
              </div>

              <div className="row">
                <label className="col-md-3 col-form-label">Writer(s)</label>
                <Form.Item className="col-md-7" name="writers">
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder={t('mel-brook')} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-3 col-form-label">{t('language')}</label>
                <Form.Item className="col-md-7" name="language">
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder={t('english-4')} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-3 col-form-label">{t('studio-1')}</label>
                <Form.Item className="col-md-7" name="studio">
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder={t('marvel-studios')} />
                </Form.Item>
              </div>

              <div className="row">
                <label className="col-md-3 col-form-label">{t('star-rating')}</label>
                <Form.Item className="col-md-7 mt-2" name="rating">
                  <Rate defaultValue={1} />
                </Form.Item>
              </div>
            </div>

{!isProvider &&
            <Providers setProvider={setProvider} />
}
            <MovieStreams />

            <Translations data={translations} service_name={service?.name ?? ''} clientName={clientName} setTranslations={setTranslations} />

            <Tags setTags={setTags} />

            <Actors setActors={setActors} />

            <ParentalControl setParentalControl={setParental} />

            <Placement setPlacement={setPlacement} />

            <Security setSecurity={setSecurity} />

            <Ads setAds={setAds} />

            <PayPerView service_name={service?.name ?? ''} clientName={clientName} setPayPerView={setPayPerView} />

            <ImageUploader
              onSubmit={(values: any) => {
                setPoster(values);
              }}
              initialData={{
                uploadName: t('movie-poster'),
                uploadType: 'poster',
                path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.MOVIES.IMAGES,
              }}
            />
            <ImageUploader
              onSubmit={(values: any) => {
                setBackdrop(values);
              }}
              initialData={{
                uploadName: t('movie-background'),
                uploadType: 'background',
                path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.MOVIES.IMAGES,
              }}
            />
            <ImageUploader
              onSubmit={(values: any) => {
                setLogo(values);
              }}
              initialData={{
                notMandatory: true,
                uploadName: t('movie-logo'),
                uploadType: 'logo',
                path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.MOVIES.IMAGES,
              }}
            />
          </div>
        </div>
        <div className="text-right">
          <Button htmlType="submit" disabled={isAdding} loading={isAdding} className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
            {t('add-movie-0')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddNewMusicAlbum;
